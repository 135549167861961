import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

type props = Partial<Record<'headerName' | 'description' | 'content', string>>

const DocumentTitle = ({
  headerName = '',
  description = ' ',
  content = '',
}: props) => {
  const [t] = useTranslation()
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t(headerName)}</title>
      <meta name={description} content={content} />
    </Helmet>
  )
}

export default DocumentTitle
