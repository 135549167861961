import {
  CLEAR_EMPLOYEE_EDUCATION,
  CREATE_EMPLOYEE_EDUCATION,
  DELETE_EMPLOYEE_EDUCATION,
  FETCH_EMPLOYEE_EDUCATIONS_FAILURE,
  FETCH_EMPLOYEE_EDUCATIONS_REQUEST,
  FETCH_EMPLOYEE_EDUCATIONS_SUCCESS,
  FETCH_EMPLOYEE_EDUCATION_FAILURE,
  FETCH_EMPLOYEE_EDUCATION_REQUEST,
  FETCH_EMPLOYEE_EDUCATION_SUCCESS,
  UPDATE_EMPLOYEE_EDUCATION,
} from '@app/store/types/employee-education'

import employeeEducationRepository from '@app/api/repositories/employee-education-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import { IEmployeeEducationActions } from '@app/interfaces/employee/employee-education'
import axios from 'axios'

export const getEmployeeEducation =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeeEducationRequest())
    await employeeEducationRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchEmployeeEducationSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeeEducationFailure(error?.message, error?.code))
        }
      })
  }

export const getEmployeeEducations =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeeEducationsRequest())
    await employeeEducationRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchEmployeeEducationsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeeEducationsFailure(error?.message, error?.code))
        }
      })
  }

const fetchEmployeeEducationsRequest = (): IEmployeeEducationActions => {
  return {
    type: FETCH_EMPLOYEE_EDUCATIONS_REQUEST,
  }
}

const fetchEmployeeEducationsSuccess = (
  employeeEducations,
  count,
): IEmployeeEducationActions => {
  return {
    type: FETCH_EMPLOYEE_EDUCATIONS_SUCCESS,
    payload: { employeeEducations, count },
  }
}

const fetchEmployeeEducationsFailure = (
  message,
  code,
): IEmployeeEducationActions => {
  return {
    type: FETCH_EMPLOYEE_EDUCATIONS_FAILURE,
    payload: { message, code },
  }
}

const fetchEmployeeEducationRequest = (): IEmployeeEducationActions => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_REQUEST,
  }
}

const fetchEmployeeEducationSuccess = (
  employeeEducation,
): IEmployeeEducationActions => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_SUCCESS,
    payload: employeeEducation,
  }
}

const fetchEmployeeEducationFailure = (
  message,
  code,
): IEmployeeEducationActions => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_FAILURE,
    payload: { message, code },
  }
}

export function clearEmployeeEducations() {
  return {
    type: CLEAR_EMPLOYEE_EDUCATION,
    payload: null,
  }
}

export function createEmployeeEducation(
  employeeEducation,
): IEmployeeEducationActions {
  return {
    type: CREATE_EMPLOYEE_EDUCATION,
    payload: employeeEducation,
  }
}

export function updateEmployeeEducation(
  employeeEducation,
): IEmployeeEducationActions {
  return {
    type: UPDATE_EMPLOYEE_EDUCATION,
    payload: employeeEducation,
  }
}

export function deleteEmployeeEducation(
  employeeEducation,
): IEmployeeEducationActions {
  return {
    type: DELETE_EMPLOYEE_EDUCATION,
    payload: employeeEducation,
  }
}
