import { DownOutlined } from '@ant-design/icons'
import OrganizationPositionRepository from '@app/api/repositories/organization-position-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import ClosePositionModal from '@app/pages/organizations/components/organization-positions/components/accept-close-position-modal/accept-close-position-modal'
import OrganizationPositionForm from '@app/pages/organizations/components/organization-positions/components/organization-position-form/organization-position-form'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import Pagination from '@app/shared/pagination/pagination'
import { closeOrganizationPosition } from '@app/store/actions/organization-position-actions'
import { useGetEmployeePositionsDictionaryQuery } from '@app/store/rtk/api/employee/employee-position-dictionary-api'
import { useGetEmployeeRatesQuery } from '@app/store/rtk/api/employee/employee-rate-api'
import { useGetOrganizationPositionsQuery } from '@app/store/rtk/api/organization/organization-position-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Button, Card, Dropdown, Menu, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_EMPLOYEE_CURRENT_EDIT,
  AUTHORITIES.ROLE_EMPLOYEE_ALL_EDIT,
  AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_EDIT,
  AUTHORITIES.ROLE_EMPLOYEE_REGION_EDIT,
]

const EmployeePositionsTable: React.FC = () => {
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const [update, setUpdate] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { data: employeePositions } = useGetEmployeePositionsDictionaryQuery('')
  const { data: rates } = useGetEmployeeRatesQuery('')
  const { searchParams, requestFunction, pageAndSize, setPage } = usePagination(
    `organizationId.equals=${params.id}&status.equals=OPEN&page=0&size=10`,
  )
  const {
    data: organizationPositions,
    isLoading,
    isFetching,
    refetch,
  } = useGetOrganizationPositionsQuery(searchParams.toString())
  const { localize, localizeData } = useLocalization()

  const changeUpdate = () => {
    setUpdate(!update)
  }

  useEffect(() => {
    refetch()
  }, [searchParams])

  const closeOrganization = (id) => {
    const values = {
      id: id,
      organizationId: Number(params.id),
    }
    OrganizationPositionRepository.closeOrganizationPosition(values)
      .then((response) => {
        if (response.data.status === 'CLOSE') {
          dispatch(closeOrganizationPosition(response.data))
          openNotificationWithIcon(
            'success',
            t('employeePosition.successClosePosition'),
          )
        }
      })
      .catch(() => {
        openNotificationWithIcon(
          'error',
          t('employeePosition.errorClosePosition'),
        )
      })
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => (
        <Text>{(pageAndSize.page || 0) * pageAndSize.size + index + 1}</Text>
      ),
    },
    {
      title: t('employeePosition.employeePosition'),
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: t('employeePosition.dateOfCreate'),
      dataIndex: 'dateOfCreate',
      key: 'dateOfCreate',
    },
    {
      title: t('employeeRate.rate'),
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: t('employeePosition.vacant'),
      dataIndex: 'vacant',
      key: 'vacant',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => {
        if (record.isCloseVacant && rolesChecker(permissions)) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={() => (
                <Menu>
                  <Menu.Item key="assignAnEmployee">
                    <Link
                      className={'ant-btn ant-btn-text'}
                      to={`/organization/${params.id}/employees-positions/${record.id}/assign-employee`}
                    >
                      {t('employeePosition.assignAnEmployee')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="closePosition">
                    <ClosePositionModal
                      id={record.id}
                      closePosition={closeOrganization}
                    />
                  </Menu.Item>
                </Menu>
              )}
            >
              <Button>
                {t('general.action')} <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
        return null
      },
    },
  ]

  const data = organizationPositions?.response?.length
    ? organizationPositions?.response?.map((organizationPosition) => {
        return {
          key: organizationPosition.id,
          id: organizationPosition.id,
          position: localizeData(
            organizationPosition.employeePositionDictionary.data,
          )?.name,
          rate: localize(rates?.response)?.find(
            (rate) => rate.id === organizationPosition.employeeRateDictionaryId,
          )?.data.name,
          dateOfCreate: (
            <Moment format={'DD.MM.YYYY'}>
              {organizationPosition.createdDate}
            </Moment>
          ),
          vacant: organizationPosition.vacant
            ? t('general.yes')
            : t('general.no'),
          isCloseVacant: organizationPosition.vacant,
          organizationPosition: organizationPosition,
        }
      })
    : []

  return (
    <div>
      <Card
        extra={
          rolesChecker(permissions) ? (
            <OrganizationPositionForm
              setUpdate={changeUpdate}
              employeePositions={employeePositions?.response}
              employeeRates={rates?.response}
            />
          ) : null
        }
        title={t('employeePosition.title')}
      >
        <Table
          dataSource={data}
          pagination={false}
          loading={isFetching}
          columns={columns}
          rowClassName="table-row"
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={organizationPositions?.meta['x-total-count'] ?? 0}
              setPage={setPage}
              searchParams={searchParams}
              isSuccess={!isFetching}
              isLegacy={false}
            />
          )}
        />
      </Card>
    </div>
  )
}

export default EmployeePositionsTable
