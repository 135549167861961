import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import organizationMenuRepository from '@app/api/repositories/organization-menu-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import { FormType } from '@app/constants/formType'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  getMenuMealType,
  getMenuMealTypes,
  removeMenuMealType,
  toggleMenuRefresh,
} from '@app/store/actions/organization-menus-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Dropdown, Menu, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuMealTypesForm from './components/menu-meal-types-form'
import './meal-types.less'

const MealTypes: React.FC = () => {
  const [t] = useTranslation()
  const { selectors } = useCreateSelector()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const { mealTypes, state, refresh, formType } = useSelector(
    selectors.menus.menusState,
  )

  setDocumentTitle(t('routes.menu'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(getMenuMealTypes({}, t('organizationMenu.errors.mealTypes')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  const addMealType = (values: any, cb: () => void) => {
    const newValues = {
      ...values,
      data: values.data.filter((item) => !isEmpty(item)),
    }
    const apiCall =
      formType === FormType.ADD
        ? organizationMenuRepository.addMenuMealType(newValues)
        : organizationMenuRepository.updateMenuMealType(values.id, newValues)
    apiCall
      .then((response) => {
        cb()
        dispatch(toggleMenuRefresh())
        openNotificationWithIcon(
          'success',
          t(
            `organizationMenu.mealTypes.${
              formType === FormType.EDIT ? 'successEdit' : 'successAdd'
            }`,
          ),
        )
      })
      .catch((error) =>
        openNotificationWithIcon(
          'error',
          t('organizationMenu.mealTypes.errorAdd'),
        ),
      )
  }

  const onMealTypeEditHandler = (id: number) => {
    dispatch(
      getMenuMealType({ data: id }, t('organizationMenu.mealTypes.errorEdit')),
    )
  }

  const onMealTypeRemoveHandler = (id: number) => {
    dispatch(
      removeMenuMealType(
        { data: id },
        t('organizationMenu.mealTypes.successRemove'),
        t('organizationMenu.mealTypes.errorRemove'),
      ),
    )
  }

  const permissions = [
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_MENU_ALL_EDIT,
    AUTHORITIES.ROLE_MENU_REGION_EDIT,
    AUTHORITIES.ROLE_MENU_DISTRICT_EDIT,
    AUTHORITIES.ROLE_MENU_CURRENT_EDIT,
  ]

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => <Text>{index + 1}</Text>,
    },

    {
      title: t('organizationMenu.mealTypes.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('organizationMenu.mealTypes.calculationStrategy'),
      dataIndex: 'calculationStrategy',
      key: 'calculationStrategy',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (record) =>
        rolesChecker(permissions) && record ? (
          <Dropdown
            trigger={['click']}
            placement={'bottomCenter'}
            overlay={() => (
              <Menu>
                <Menu.Item key="edit">
                  <Button
                    type="text"
                    onClick={() => onMealTypeEditHandler(record.id)}
                  >
                    <EditOutlined /> {t('general.edit')}
                  </Button>
                </Menu.Item>
                <Menu.Item key="remove">
                  <Button
                    type="text"
                    onClick={() => onMealTypeRemoveHandler(record.id)}
                  >
                    <DeleteOutlined /> {t('general.delete')}
                  </Button>
                </Menu.Item>
              </Menu>
            )}
          >
            <Button>
              {t('general.action')} <DownOutlined />
            </Button>
          </Dropdown>
        ) : null,
    },
  ]

  const data = !isEmpty(mealTypes)
    ? mealTypes.map((mealType) => {
        return {
          key: mealType.id,
          id: mealType.id,
          calculationStrategy: mealType.calculationStrategy,
          name: localizeData(mealType.data)?.name,
        }
      })
    : []

  return (
    <div className="meal-types">
      <div className="meal-types__header">
        {rolesChecker(permissions) && (
          <MenuMealTypesForm addMealType={addMealType} />
        )}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName="table-row"
        loading={state === LOADING}
        pagination={false}
        // onChange={handleTableChange}
        // footer={() => (
        //   <Pagination
        //     request={requestFunction}
        //     totalCount={count}
        //     setPage={setPage}
        //   />
        // )}
      />
    </div>
  )
}

export default MealTypes
