import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationPosition,
  IOrganizationPositionState,
} from '@app/interfaces/employee/organization-position'
import {
  CLEAR_ORGANIZATION_POSITION,
  CLEAR_ORGANIZATION_POSITIONS,
  CLOSE_ORGANIZATION_POSITION,
  CREATE_ORGANIZATION_POSITION,
  FETCH_ORGANIZATION_POSITIONS_CLEANUP,
  FETCH_ORGANIZATION_POSITIONS_FAILURE,
  FETCH_ORGANIZATION_POSITIONS_REQUEST,
  FETCH_ORGANIZATION_POSITIONS_SUCCESS,
  FETCH_ORGANIZATION_POSITION_FAILURE,
  FETCH_ORGANIZATION_POSITION_REQUEST,
  FETCH_ORGANIZATION_POSITION_SUCCESS,
  GET_ORGANIZATION_POSITION,
  GET_ORGANIZATION_POSITIONS,
} from '@app/store/types/organization-position'

const initialState: IOrganizationPositionState = {
  organizationPositions: [],
  organizationPosition: {} as IOrganizationPosition,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationPositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_POSITION:
      return { ...state, organizationPosition: action.payload }
    case GET_ORGANIZATION_POSITIONS:
      return { ...state, organizationPositions: action.payload }
    case CREATE_ORGANIZATION_POSITION:
      return {
        ...state,
        organizationPositions: [...state.organizationPositions, action.payload],
      }
    case CLEAR_ORGANIZATION_POSITION:
      return { ...state, organizationPosition: {}, state: IDLE }
    case CLEAR_ORGANIZATION_POSITIONS:
      return { ...state, organizationPositions: [], state: IDLE }
    case CLOSE_ORGANIZATION_POSITION:
      const editedOrganizationPositions = state.organizationPositions.filter(
        function (organizationPosition) {
          if (action.payload.id === organizationPosition.id) {
            return false
          }
          return organizationPosition
        },
      )
      return { ...state, organizationPositions: editedOrganizationPositions }
    case FETCH_ORGANIZATION_POSITIONS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_POSITIONS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationPositions: action.payload.organizationPositions,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_POSITIONS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationPositions: [],
      }
    case FETCH_ORGANIZATION_POSITIONS_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_ORGANIZATION_POSITION_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_POSITION_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationPosition: action.payload,
      }
    case FETCH_ORGANIZATION_POSITION_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationPosition: {},
      }
    default:
      return state
  }
}
