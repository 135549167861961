import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import usePagination from '@app/hooks/usePagination'
import { IOrganizationPupilsState } from '@app/interfaces/pupil/organization-pupils'
import OrganizationPupilsQueueAccept from '@app/pages/organizations/components/organization-pupils-queue/components/organization-pupils-queue-accept-form/organization-pupils-queue-accept-form'
import OrganizationPupilsQueueDecline from '@app/pages/organizations/components/organization-pupils-queue/components/organization-pupils-queue-decline-form/organization-pupils-queue-decline-form'
import Pagination from '@app/shared/pagination/pagination'
import { getOrganizationPupilsQueue } from '@app/store/actions/organization-pupils-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

interface props {
  _pupilsState: IOrganizationPupilsState
}

interface params {
  id?: string
}

const OrganizationPupilsQueuePage: React.FC<props> = ({ _pupilsState }) => {
  const { state, count, pupilsInQueue } = _pupilsState
  const params: params = useParams()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const {
    searchParams,
    requestFunction,
    pageAndSize,
    handleTableChange,
    setPage,
  } = usePagination(
    `organizationId.equals=${params.id}&status.equals=WAITING`,
    {
      page: 1,
      size: 10,
    },
  )
  setDocumentTitle(t('pupils.accept'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getOrganizationPupilsQueue(
        { params: searchParams, source },
        t('pupils.error'),
      ),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const approvedPermissions = [
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_PUPIL_ALL_APPROVED,
    AUTHORITIES.ROLE_PUPIL_CURRENT_APPROVED,
    AUTHORITIES.ROLE_PUPIL_DISTRICT_APPROVED,
    AUTHORITIES.ROLE_PUPIL_REGION_APPROVED,
  ]
  const quashedPermissions = [AUTHORITIES.ADMIN]

  const columns = [
    {
      title: t('pupils.queueId'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('educationGroup.educationGroup'),
      dataIndex: 'educationGroup',
      key: 'educationGroup',
      sorter: {
        compare: (a, b) => a.educationGroup?.localeCompare(b?.totalDays),
        multiple: 1,
      },
    },
    {
      title: t('pupils.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName?.localeCompare(b.fullName),
        multiple: 2,
      },
      render: (text, record) => (
        <Link
          className={'ant-btn, ant-btn-link'}
          to={`/organization/${params.id}/pupils/pupil/${record.id}`}
          state={{
            pupil: pupilsInQueue.find(
              (pupil) => pupil.id === Number(record.id),
            ),
          }}
        >
          {text.toUpperCase()}
        </Link>
      ),
    },
    {
      title: t('pupils.metric'),
      dataIndex: 'metric',
      key: 'metric',
      sorter: {
        compare: (a, b) => a.metric?.localeCompare(b.metric),
        multiple: 3,
      },
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => {
        return (
          <>
            {rolesChecker(approvedPermissions) ? (
              <OrganizationPupilsQueueAccept pupil={record.pupil} />
            ) : null}
            {rolesChecker(quashedPermissions) ? (
              <OrganizationPupilsQueueDecline pupil={record.pupil} />
            ) : null}
          </>
        )
      },
    },
  ]

  const data =
    pupilsInQueue && pupilsInQueue.length
      ? pupilsInQueue.map((pupil) => {
          return {
            key: pupil.id,
            id: pupil.id,
            educationGroup: pupil.educationGroup.name,
            fullName: pupil.pupil.fullName,
            metric: pupil.pupil.metric,
            pupil: pupil,
          }
        })
      : []

  return (
    <>
      <Card title={t('pupils.title')}>
        <Table
          columns={columns}
          dataSource={data}
          loading={state === LOADING}
          pagination={false}
          rowClassName="table-row"
          onChange={handleTableChange}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              searchParams={searchParams}
            />
          )}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _pupilsState: state.organizationPupils,
  }
}

export default connect(mapStateToProps)(OrganizationPupilsQueuePage)
