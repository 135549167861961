import { IEmployeeCount } from '@app/interfaces/employee/employee'
import { IOrganization } from '@app/interfaces/organization/organization'
import { IQueuePupilsCount } from '@app/interfaces/pupil/organization-pupils'
import { IUserInfo } from '@app/interfaces/user-info'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import IndicatorsPupils from './components/indicators-pupils/indicators-pupils'
import EmployeeStaffCard from './components/employee-staff-card/employee-staff-card'
import './indicators.less'

interface props {
  userInfo: IUserInfo
  employeesCount: IEmployeeCount
  pupilsCount: IQueuePupilsCount[]
  _organization: IOrganization
}

const Indicators: React.FC<props> = (...props) => {
  const { t } = useTranslation()
  setDocumentTitle(t('routes.indicators'))

  return (
    <>
      <Typography.Title level={3}>
        {t('organization.indicators')}
      </Typography.Title>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <IndicatorsPupils />
        </Col>
        <Col span={12}>
          <EmployeeStaffCard />
        </Col>
      </Row>
    </>
  )
}

export default Indicators
