import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import StayDurationForm from '@app/pages/reference-book-page-details/stay-duration/components/stay-duration-form/stay-duration-form'
import { useGetStayDurationsQuery } from '@app/store/rtk/api/organization-passport/stay-duration-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ReferenceBookStayDurations: React.FC = () => {
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const { data, isFetching } = useGetStayDurationsQuery('')
  setDocumentTitle(t('childrenStayDuration.title'))

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('childrenStayDuration.childrenStayDuration'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <StayDurationForm edit={true} stayDuration={record.stayDuration} />
        ) : null,
    },
  ]

  const dataSource = data?.response.length
    ? data.response.map((stayDuration) => {
        return {
          key: stayDuration.id,
          id: stayDuration.id,
          name: localizeData(stayDuration?.data)?.name,
          status: [stayDuration.status],
          stayDuration: stayDuration,
        }
      })
    : []

  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <StayDurationForm />
          ) : null
        }
        title={t('childrenStayDuration.title')}
      >
        <Table
          pagination={false}
          loading={isFetching}
          dataSource={dataSource}
          columns={columns}
        />
      </Card>
    </>
  )
}

export default ReferenceBookStayDurations
