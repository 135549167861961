import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationRepository from '@app/api/repositories/organization-repository'
import { IOrganizationActions } from '@app/interfaces/organization/organization'
import {
  CLEAR_ORGANIZATION,
  CLEAR_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATION_CLEANUP,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  REFRESH_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from '@app/store/types/organization'
import axios from 'axios'

export const getOrganizations =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationsRequest())
    await organizationRepository
      .getAll(args)
      .then((response) => {
        dispatch(
          fetchOrganizationsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationsFailure(error?.message, error?.code))
        }
      })
    fetchOrganizationCleanup()
  }

export const getOrganization =
  (args: numberArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationRequest())
    await organizationRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationFailure(error?.message, error?.code))
        }
      })
    fetchOrganizationCleanup()
  }

const fetchOrganizationsRequest = () => {
  return {
    type: FETCH_ORGANIZATIONS_REQUEST,
  }
}

const fetchOrganizationsSuccess = (organizations, count) => {
  return {
    type: FETCH_ORGANIZATIONS_SUCCESS,
    payload: { organizations, count },
  }
}

const fetchOrganizationsFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATIONS_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationRequest = () => {
  return {
    type: FETCH_ORGANIZATION_REQUEST,
  }
}

const fetchOrganizationSuccess = (organization) => {
  return {
    type: FETCH_ORGANIZATION_SUCCESS,
    payload: organization,
  }
}

const fetchOrganizationFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationCleanup = () => {
  return {
    type: FETCH_ORGANIZATION_CLEANUP,
  }
}

export function clearOrganization() {
  return {
    type: CLEAR_ORGANIZATION,
  }
}

export function clearOrganizations() {
  return {
    type: CLEAR_ORGANIZATIONS,
    payload: null,
  }
}

export function refreshOrganization() {
  return {
    type: REFRESH_ORGANIZATION,
    payload: null,
  }
}

export function updateOrganization(organization): IOrganizationActions {
  return {
    type: UPDATE_ORGANIZATION,
    payload: organization,
  }
}

export function createOrganization(organization): IOrganizationActions {
  return {
    type: CREATE_ORGANIZATION,
    payload: organization,
  }
}

export function deleteOrganization(organization): IOrganizationActions {
  return {
    type: DELETE_ORGANIZATION,
    payload: organization,
  }
}
