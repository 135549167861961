import {
  DELETE_HOLIDAY,
  UPDATE_HOLIDAY,
  CREATE_HOLIDAY,
  GET_HOLIDAY,
  GET_HOLIDAYS,
} from '@app/store/types/holiday'
import { IHoliday, IHolidayState } from '@app/interfaces/holidays'

const initialState: IHolidayState = {
  holiday: {} as IHoliday,
  holidays: [],
}

export const holidaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOLIDAY:
      return { ...state, holiday: action.payload }
    case GET_HOLIDAYS:
      return { ...state, holidays: action.payload }
    case CREATE_HOLIDAY:
      return {
        ...state,
        holidays: [...state.holidays, action.payload],
      }
    case UPDATE_HOLIDAY:
      const updatedHeatingSystemSources = state.holidays.map(function (
        holiday,
      ) {
        if (action.payload.id === holiday.id) {
          return action.payload
        }
        return holiday
      })
      return { ...state, holidays: updatedHeatingSystemSources }
    case DELETE_HOLIDAY:
      const editedHeatingSystemSources = state.holidays.filter(function (
        holiday,
      ) {
        if (action.payload.id === holiday.id) {
          return false
        }
        return holiday
      })
      return { ...state, holidays: editedHeatingSystemSources }
    default:
      return state
  }
}
