import { ApiKeys } from '@app/constants/api-keys'
import { IEmployeeRate } from '@app/interfaces/employee/employee-rate'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import localforage from 'localforage'
import { emptyEmployeeApi } from '.'
import { IRtkResponse } from '../../types'

export const employeeRateApi = emptyEmployeeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getEmployeeRates: build.query<
      IRtkResponse<IEmployeeRate[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'employee/api/v1/employee-rate-dictionary',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IEmployeeRate[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.employeeRate,
                id,
              })),
              { type: ApiKeys.employeeRate, id: 'LIST' },
            ]
          : [{ type: ApiKeys.employeeRate, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.employeeRate, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getEmployeeRate: build.query<IEmployeeRate, number>({
      query: (id) => ({
        url: `employee/api/v1/employee-rate-dictionary/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.employeeRate, id }],
    }),
    createEmployeeRate: build.mutation<
      IRtkResponse<IEmployeeRate>,
      Required<IEmployeeRate>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'employee/api/v1/employee-rate-dictionary',
          data,
        }
      },
      transformResponse: (response: IEmployeeRate, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.employeeRate, id: 'LIST' }],
    }),
    updateEmployeeRate: build.mutation<
      IRtkResponse<IEmployeeRate>,
      Required<IEmployeeRate>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/employee-rate-dictionary/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployeeRate, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employeeRate, id: arg.id },
      ],
    }),
    partialUpdateEmployeeRate: build.mutation<
      IRtkResponse<IEmployeeRate>,
      Subset<IEmployeeRate>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/employee-rate-dictionary/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployeeRate, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employeeRate, id: arg.id },
      ],
    }),
  }),
})

const useStateGetEmployeeRateQuery =
  employeeRateApi.endpoints.getEmployeeRate.useQueryState
const useStateGetEmployeeRatesQuery =
  employeeRateApi.endpoints.getEmployeeRates.useQueryState

export const {
  useGetEmployeeRatesQuery,
  useGetEmployeeRateQuery,
  useCreateEmployeeRateMutation,
  useUpdateEmployeeRateMutation,
  usePartialUpdateEmployeeRateMutation,
} = employeeRateApi

export { useStateGetEmployeeRateQuery, useStateGetEmployeeRatesQuery }
