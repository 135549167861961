export const UPDATE_EDUCATION_DEGREE = 'UPDATE_EDUCATION_DEGREE'
export const CREATE_EDUCATION_DEGREE = 'CREATE_EDUCATION_DEGREE'

export const FETCH_EDUCATION_DEGREES_REQUEST = 'FETCH_EDUCATION_DEGREES_REQUEST'
export const FETCH_EDUCATION_DEGREES_SUCCESS = 'FETCH_EDUCATION_DEGREES_SUCCESS'
export const FETCH_EDUCATION_DEGREES_FAILURE = 'FETCH_EDUCATION_DEGREES_FAILURE'

export const FETCH_EDUCATION_DEGREE_REQUEST = 'FETCH_EDUCATION_DEGREE_REQUEST'
export const FETCH_EDUCATION_DEGREE_SUCCESS = 'FETCH_EDUCATION_DEGREE_SUCCESS'
export const FETCH_EDUCATION_DEGREE_FAILURE = 'FETCH_EDUCATION_DEGREE_FAILURE'
