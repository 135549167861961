export const GET_EDUCATION_GROUP = 'GET_EDUCATION_GROUP'
export const GET_EDUCATION_GROUPS = 'GET_EDUCATION_GROUPS'
export const CREATE_EDUCATION_GROUP = 'CREATE_EDUCATION_GROUP'
export const UPDATE_EDUCATION_GROUP = 'UPDATE_EDUCATION_GROUP'
export const DELETE_EDUCATION_GROUP = 'DELETE_EDUCATION_GROUP'
export const CLEAR_EDUCATION_GROUPS = 'CLEAR_EDUCATION_GROUPS'
export const REFRESH_EDUCATION_GROUPS = 'REFRESH_EDUCATION_GROUPS'

export const FETCH_EDUCATION_GROUPS_REQUEST = 'FETCH_EDUCATION_GROUPS_REQUEST'
export const FETCH_EDUCATION_GROUPS_SUCCESS = 'FETCH_EDUCATION_GROUPS_SUCCESS'
export const FETCH_EDUCATION_GROUPS_FAILURE = 'FETCH_EDUCATION_GROUPS_FAILURE'

export const FETCH_EDUCATION_GROUP_REQUEST = 'FETCH_EDUCATION_GROUP_REQUEST'
export const FETCH_EDUCATION_GROUP_SUCCESS = 'FETCH_EDUCATION_GROUP_SUCCESS'
export const FETCH_EDUCATION_GROUP_FAILURE = 'FETCH_EDUCATION_GROUP_FAILURE'
export const FETCH_EDUCATION_GROUP_CLEANUP = 'FETCH_EDUCATION_GROUP_CLEANUP'
