import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationTypeRepository from '@app/api/repositories/organization-type-repository'
import { IOrganizationTypeActions } from '@app/interfaces/organization/organization-type'
import {
  CLEAR_ORGANIZATION_TYPE,
  CREATE_ORGANIZATION_TYPE,
  DELETE_ORGANIZATION_TYPE,
  FETCH_ORGANIZATION_TYPES_FAILURE,
  FETCH_ORGANIZATION_TYPES_REQUEST,
  FETCH_ORGANIZATION_TYPES_SUCCESS,
  FETCH_ORGANIZATION_TYPE_FAILURE,
  FETCH_ORGANIZATION_TYPE_REQUEST,
  FETCH_ORGANIZATION_TYPE_SUCCESS,
  UPDATE_ORGANIZATION_TYPE,
} from '@app/store/types/organization-type'
import axios from 'axios'

export const getOrganizationType =
  (args: numberArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationTypeRequest())
    await organizationTypeRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationTypeSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationTypeFailure(error?.message, error?.code))
        }
      })
  }

export const getOrganizationTypes =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationTypesRequest())
    await organizationTypeRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchOrganizationTypesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationTypesFailure(error?.message, error?.code))
        }
      })
  }

const fetchOrganizationTypesRequest = (): IOrganizationTypeActions => {
  return {
    type: FETCH_ORGANIZATION_TYPES_REQUEST,
  }
}

const fetchOrganizationTypesSuccess = (
  organizationTypes,
  count,
): IOrganizationTypeActions => {
  return {
    type: FETCH_ORGANIZATION_TYPES_SUCCESS,
    payload: { organizationTypes, count },
  }
}

const fetchOrganizationTypesFailure = (
  message,
  code,
): IOrganizationTypeActions => {
  return {
    type: FETCH_ORGANIZATION_TYPES_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationTypeRequest = (): IOrganizationTypeActions => {
  return {
    type: FETCH_ORGANIZATION_TYPE_REQUEST,
  }
}

const fetchOrganizationTypeSuccess = (
  organizationType,
): IOrganizationTypeActions => {
  return {
    type: FETCH_ORGANIZATION_TYPE_SUCCESS,
    payload: organizationType,
  }
}

const fetchOrganizationTypeFailure = (
  message,
  code,
): IOrganizationTypeActions => {
  return {
    type: FETCH_ORGANIZATION_TYPE_FAILURE,
    payload: { message, code },
  }
}

export function clearOrganizationTypes() {
  return {
    type: CLEAR_ORGANIZATION_TYPE,
    payload: null,
  }
}

export function updateOrganizationType(
  organizationType,
): IOrganizationTypeActions {
  return {
    type: UPDATE_ORGANIZATION_TYPE,
    payload: organizationType,
  }
}

export function createOrganizationType(
  organizationType,
): IOrganizationTypeActions {
  return {
    type: CREATE_ORGANIZATION_TYPE,
    payload: organizationType,
  }
}

export function deleteOrganizationType(
  organizationType,
): IOrganizationTypeActions {
  return {
    type: DELETE_ORGANIZATION_TYPE,
    payload: organizationType,
  }
}
