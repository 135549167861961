export const metricSerials = [
  'I-AN',
  'II-AN',
  'III-AN',
  'I-BH',
  'II-BH',
  'III-BH',
  'I-FR',
  'II-FR',
  'III-FR',
  'I-GZ',
  'II-GZ',
  'III-GZ',
  'I-HR',
  'II-HR',
  'III-HR',
  'I-NA',
  'II-NA',
  'III-NA',
  'I-NV',
  'II-NV',
  'III-NV',
  'I-QD',
  'II-QD',
  'III-QD',
  'I-QQ',
  'II-QQ',
  'III-QQ',
  'I-SM',
  'II-SM',
  'III-SM',
  'I-SU',
  'II-SU',
  'III-SU',
  'I-SR',
  'II-SR',
  'III-SR',
  'I-TN',
  'II-TN',
  'III-TN',
  'I-TV',
  'II-TV',
  'III-TV',
  'T',
  'TA',
]
