import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import OrganizationContactsForm from '@app/pages/organizations/components/organization-contacts/organization-contacts-form/organization-contacts-form'
import { useGetOrganizationContactQuery } from '@app/store/rtk/api/organization/organization-contact-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Descriptions } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_SYSTEM,
  AUTHORITIES.SUSER,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_ALL_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_REGION_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_DISTRICT_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_CURRENT_EDIT,
]

const OrganizationContactsPage: React.FC = () => {
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const { data } = useGetOrganizationContactQuery(Number(params.id))
  setDocumentTitle(t('organizationContacts.title'))

  return (
    <Card
      extra={
        rolesChecker(permissions) ? (
          <OrganizationContactsForm organizationContacts={data} />
        ) : null
      }
      title={t('organizationContacts.title')}
    >
      <Descriptions size={'middle'} column={1} bordered>
        <Descriptions.Item label={t('organizationContacts.address')}>
          {data?.address}
        </Descriptions.Item>
        <Descriptions.Item label={t('organizationContacts.postalCode')}>
          {data?.postalCode}
        </Descriptions.Item>
        <Descriptions.Item label={t('organizationContacts.email')}>
          {data?.email}
        </Descriptions.Item>
        <Descriptions.Item label={t('organizationContacts.site')}>
          {data?.site}
        </Descriptions.Item>
        <Descriptions.Item label={t('organizationContacts.phone')}>
          {data?.phone}
        </Descriptions.Item>
        <Descriptions.Item label={t('organizationContacts.latitude')}>
          {data?.latitude}
        </Descriptions.Item>
        <Descriptions.Item label={t('organizationContacts.longitude')}>
          {data?.longitude}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default OrganizationContactsPage
