import { ApiKeys } from '@app/constants/api-keys'
import { IToiletTypes } from '@app/interfaces/organization-passport/toilet-types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const toiletTypesApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getToiletTypes: build.query<
      IRtkResponse<IToiletTypes[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/toilet-types',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IToiletTypes[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.toiletType,
                id,
              })),
              { type: ApiKeys.toiletType, id: 'LIST' },
            ]
          : [{ type: ApiKeys.toiletType, id: 'LIST' }],
    }),
    getToiletType: build.query<IToiletTypes, number>({
      query: (id) => ({
        url: `dictionary/api/v1/toilet-types/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.toiletType, id }],
    }),
    createToiletType: build.mutation<IRtkResponse<IToiletTypes>, IToiletTypes>({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/toilet-types',
          data,
        }
      },
      transformResponse: (response: IToiletTypes, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.toiletType,
          id: args.id,
        },
        { type: ApiKeys.toiletType, id: 'LIST' },
      ],
    }),
    updateToiletType: build.mutation<IRtkResponse<IToiletTypes>, IToiletTypes>({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/toilet-types/${data.id}`,
        data,
      }),
      transformResponse: (response: IToiletTypes, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.toiletType, id: args.id },
        { type: ApiKeys.toiletType, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetToiletTypes =
  toiletTypesApi.endpoints.getToiletTypes.useQueryState
const useStateGetToiletType =
  toiletTypesApi.endpoints.getToiletType.useQueryState

export const {
  useGetToiletTypesQuery,
  useLazyGetToiletTypesQuery,
  useCreateToiletTypeMutation,
  useUpdateToiletTypeMutation,
  util,
  usePrefetch,
} = toiletTypesApi

export { useStateGetToiletTypes, useStateGetToiletType }
