import { ILang } from '@app/interfaces/languages'
import { Col, Form, InputNumber, Row } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  language: ILang | undefined
}

const EnterPin: React.FC<props> = ({ language }) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation()

  return (
    <div className="vertical_padding">
      <Row>
        <Col span={12} offset={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={'pin'}
            label={t('contracts.pin')}
          >
            <InputNumber className="max_width" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default EnterPin
