import { ApiKeys } from '@app/constants/api-keys'
import { IStayDuration } from '@app/interfaces/organization-passport/stay-duration'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const stayDurationsApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getStayDurations: build.query<
      IRtkResponse<IStayDuration[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/children-stay-durations',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IStayDuration[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.stayDuration,
                id,
              })),
              { type: ApiKeys.stayDuration, id: 'LIST' },
            ]
          : [{ type: ApiKeys.stayDuration, id: 'LIST' }],
    }),
    getStayDuration: build.query<IStayDuration, number>({
      query: (id) => ({
        url: `dictionary/api/v1/children-stay-durations/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.stayDuration, id }],
    }),
    createStayDuration: build.mutation<
      IRtkResponse<IStayDuration>,
      IStayDuration
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/children-stay-durations',
          data,
        }
      },
      transformResponse: (response: IStayDuration, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.stayDuration,
          id: args.id,
        },
        { type: ApiKeys.stayDuration, id: 'LIST' },
      ],
    }),
    updateStayDuration: build.mutation<
      IRtkResponse<IStayDuration>,
      IStayDuration
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/children-stay-durations/${data.id}`,
        data,
      }),
      transformResponse: (response: IStayDuration, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.stayDuration, id: args.id },
        { type: ApiKeys.stayDuration, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetStayDurations =
  stayDurationsApi.endpoints.getStayDurations.useQueryState
const useStateGetStayDuration =
  stayDurationsApi.endpoints.getStayDuration.useQueryState

export const {
  useGetStayDurationsQuery,
  useLazyGetStayDurationsQuery,
  useCreateStayDurationMutation,
  useUpdateStayDurationMutation,
  util,
  usePrefetch,
} = stayDurationsApi

export { useStateGetStayDurations, useStateGetStayDuration }
