import {
  GET_NATIONALITIES,
  GET_NATIONALITY,
  DELETE_NATIONALITY,
  CREATE_NATIONALITY,
  UPDATE_NATIONALITY,
  CLEAR_NATIONALITY,
} from '@app/store/types/nationality'
import { INationality, INationalityState } from '@app/interfaces/nationality'

const initialState: INationalityState = {
  nationalities: [],
  nationality: {} as INationality,
}

export const nationalityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NATIONALITY:
      return { ...state, nationality: action.payload }
    case GET_NATIONALITIES:
      return { ...state, nationalities: action.payload }
    case CLEAR_NATIONALITY:
      return { ...state, nationalities: [] }
    case CREATE_NATIONALITY:
      return {
        ...state,
        nationalities: [...state.nationalities, action.payload],
      }
    case UPDATE_NATIONALITY:
      const updatedNationalities = state.nationalities.map(function (
        nationality,
      ) {
        if (action.payload.id === nationality.id) {
          return action.payload
        }
        return nationality
      })
      return { ...state, nationalities: updatedNationalities }
    case DELETE_NATIONALITY:
      const editedNationalities = state.nationalities.filter(function (
        nationality,
      ) {
        if (action.payload.id === nationality.id) {
          return false
        }
        return nationality
      })
      return { ...state, nationalities: editedNationalities }
    default:
      return state
  }
}
