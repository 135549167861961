import React, { useEffect, useState } from 'react'
import { Card, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { getHolidays } from '@app/store/actions/holiday-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { AUTHORITIES } from '@app/constants/authorities'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import HolidayRepository from '@app/api/repositories/holidays-repository'
import { IHoliday } from '@app/interfaces/holidays'
import HolidaysForm from '@app/pages/reference-book-page-details/holidays/components/holidays-form/holidays-form'
import HolidayRemove from '@app/pages/reference-book-page-details/holidays/components/holiday-remove-form/holiday-remove-form'
import axios from 'axios'

interface props {
  _holidays: IHoliday[]
}

export interface IMonth {
  id: number
  name: string
}

const HolidaysPage: React.FC<props> = ({ _holidays }) => {
  const [loaded, setLoaded] = useState<boolean>()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  setDocumentTitle(t('holiday.title'))
  const months: IMonth[] = [
    {
      id: 1,
      name: t('holiday.months.january'),
    },
    {
      id: 2,
      name: t('holiday.months.february'),
    },
    {
      id: 3,
      name: t('holiday.months.march'),
    },
    {
      id: 4,
      name: t('holiday.months.april'),
    },
    {
      id: 5,
      name: t('holiday.months.may'),
    },
    {
      id: 6,
      name: t('holiday.months.june'),
    },
    {
      id: 7,
      name: t('holiday.months.jule'),
    },
    {
      id: 8,
      name: t('holiday.months.august'),
    },
    {
      id: 9,
      name: t('holiday.months.september'),
    },
    {
      id: 10,
      name: t('holiday.months.october'),
    },
    {
      id: 11,
      name: t('holiday.months.november'),
    },
    {
      id: 12,
      name: t('holiday.months.december'),
    },
  ]

  useEffect(() => {
    const source = axios.CancelToken.source()
    setLoaded(false)
    HolidayRepository.getAll({ source })
      .then(function (response) {
        dispatch(getHolidays(response.data))
        setLoaded(true)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          openNotificationWithIcon('error', t('holidays.error'))
          setLoaded(true)
        }
      })
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('holiday.month'),
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: t('holiday.day'),
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: t('holiday.note'),
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <>
            <HolidaysForm
              months={months}
              edit={true}
              holiday={record.holiday}
            />
            <HolidayRemove holiday={record.holiday} />
          </>
        ) : null,
    },
  ]

  const data =
    _holidays && _holidays.length
      ? _holidays.map((holiday) => {
          return {
            key: holiday.id,
            id: holiday.id,
            note: holiday.note,
            day: holiday.day,
            month: months.find((month) => month.id === holiday.month)?.name,
            holiday: holiday,
          }
        })
      : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <HolidaysForm months={months} />
          ) : null
        }
        title={t('holiday.title')}
      >
        <Table
          pagination={false}
          loading={!loaded}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _holidays: state.holidays.holidays,
  }
}

export default connect(mapStateToProps, null)(HolidaysPage)
