// LANG

export const GET_LANG_ID = 'GET_LANG'
export const GET_LANGS = 'GET_LANGS'
export const CREATE_LANG = 'CREATE_LANG'
export const UPDATE_LANG = 'UPDATE_LANG'
export const DELETE_LANG = 'DELETE_LANG'
export const CLEAR_LANG = 'CLEAR_LANG'

export const FETCH_LANGS_REQUEST = 'FETCH_LANGS_REQUEST'
export const FETCH_LANGS_SUCCESS = 'FETCH_LANGS_SUCCESS'
export const FETCH_LANGS_FAILURE = 'FETCH_LANGS_FAILURE'
export const FETCH_LANGS_CLEANUP = 'FETCH_LANGS_CLEANUP'
