import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationContractSubsidy,
  IOrganizationContractSubsidyState,
} from '@app/interfaces/organization-contract-subsidy'
import {
  CLEAR_ORGANIZATION_CONTRACT_SUBSIDIES,
  CLEAR_ORGANIZATION_CONTRACT_SUBSIDY,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_FAILURE,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_REQUEST,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_SUCCESS,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_CLEANUP,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_FAILURE,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_REQUEST,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_SUCCESS,
  ORGANIZATION_CONTRACT_SUBSIDY_ADD_MODAL_SET,
  REFRESH_ORGANIZATION_CONTRACT_SUBSIDY,
} from '@app/store/types/organization-contract-subsidy'

const initialState: IOrganizationContractSubsidyState = {
  organizationContractSubsidies: [],
  organizationContractSubsidy: {} as IOrganizationContractSubsidy,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
  addModal: {
    isOpen: false,
  },
}

export const organizationContractSubsidyReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CLEAR_ORGANIZATION_CONTRACT_SUBSIDY:
      return { ...state, organizationContractSubsidy: {}, state: IDLE }
    case CLEAR_ORGANIZATION_CONTRACT_SUBSIDIES:
      return { ...state, organizationContractSubsidies: [], state: IDLE }
    case REFRESH_ORGANIZATION_CONTRACT_SUBSIDY:
      return { ...state, refresh: !state.refresh }
    case ORGANIZATION_CONTRACT_SUBSIDY_ADD_MODAL_SET:
      return { ...state, addModal: action.payload }
    case FETCH_ORGANIZATION_CONTRACT_SUBSIDY_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationContractSubsidies:
          action.payload.organizationContractSubsidies,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationContractSubsidies: [],
      }
    case FETCH_ORGANIZATION_CONTRACT_SUBSIDY_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_CONTRACT_SUBSIDY_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationContractSubsidy: action.payload,
      }
    case FETCH_ORGANIZATION_CONTRACT_SUBSIDY_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationContractSubsidy: {},
      }
    default:
      return state
  }
}
