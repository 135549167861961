import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import usePagination from '@app/hooks/usePagination'
import { IOrganizationContractSubsidyAddModalArgs } from '@app/interfaces/organization-contract-subsidy'
import Pagination from '@app/shared/pagination/pagination'
import {
  getOrganizationContractSubsidies,
  setOrganizationContractSubsidyAddModal,
} from '@app/store/actions/organization-contract-subsidy-actions'
import { Button, Card, Space, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import OrganizationContractSubsidyAddForm from './components/organization-contract-subsidy-add-form/organization-contract-subsidy-add-form'

interface IPathParams {
  id?: string
}

const OrganizationContractSubsidy: React.FC = () => {
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const dispatch = useDispatch()
  const { selectors } = useCreateSelector()
  const { organizationContractSubsidies, state, count, refresh } = useSelector(
    selectors.organizationContractSubsidy.organizationContractSubsidyState,
  )
  const { searchParams, requestFunction, handleTableChange } = usePagination(
    `page=0&size=10&organizationId=${params.id}`,
    {
      page: 1,
      size: 10,
    },
  )

  const permissions = [
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_CONTRACT_SUBSIDY_ALL_EDIT,
    AUTHORITIES.ROLE_CONTRACT_SUBSIDY_REGION_EDIT,
    AUTHORITIES.ROLE_CONTRACT_SUBSIDY_DISTRICT_EDIT,
    AUTHORITIES.ROLE_CONTRACT_SUBSIDY_CURRENT_EDIT,
  ]

  const openModal = (args: IOrganizationContractSubsidyAddModalArgs) => {
    dispatch(setOrganizationContractSubsidyAddModal(args))
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getOrganizationContractSubsidies(
        { params: searchParams, source },
        t('contractSubsidy.errors.load'),
      ),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  const columns = [
    {
      title: t('contractSubsidy.contractNo'),
      dataIndex: 'contractNumber',
      key: 'contractNumber',
    },
    {
      title: t('general.fullName'),
      key: 'founderFullName',
      dataIndex: 'founderFullName',
    },
    {
      title: t('contractSubsidy.contractNo'),
      dataIndex: 'contractNumber',
      key: 'contractNumber',
    },
    {
      title: t('contractSubsidy.startDate'),
      key: 'contractStartDate',
      dataIndex: 'contractStartDate',
      render: (text) => <div>{moment(text).format('DD-MM-yyyy')}</div>,
    },
    {
      title: t('contractSubsidy.period'),
      key: 'contractPeriod',
      dataIndex: 'contractPeriod',
      render: (text) => <div>{`${text} ${t('general.yearsShort')}`}</div>,
    },
    {
      title: t('contractSubsidy.asuRegistrationDate'),
      key: 'governmentCertDate',
      render: (text) => <div>{moment(text).format('DD-MM-yyyy')}</div>,
    },
    {
      title: t('contractSubsidy.asuRegistrationNo'),
      key: 'governmentCertNumber',
      dataIndex: 'governmentCertNumber',
    },
    {
      title: t('contractSubsidy.cadastrDocumentDate'),
      key: 'cadastrDocDate',
      render: (text) => <div>{moment(text).format('DD-MM-yyyy')}</div>,
    },
    {
      title: t('contractSubsidy.cadastrDocumentNo'),
      key: 'cadastrDocNumber',
      dataIndex: 'cadastrDocNumber',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {permissions && (
            <Button
              onClick={() =>
                openModal({ isOpen: true, edit: true, contract: record })
              }
            >
              {t('general.edit')}
            </Button>
          )}
        </Space>
      ),
    },
  ]

  return (
    <>
      <Card
        title={t('contractSubsidy.title')}
        extra={
          permissions && (
            <div style={{ margin: '16px' }}>
              <Button
                type="primary"
                onClick={() => openModal({ isOpen: true, edit: false })}
              >
                {t('general.add')}
              </Button>
            </div>
          )
        }
      >
        <Table
          pagination={false}
          onChange={handleTableChange}
          dataSource={organizationContractSubsidies.map((item) => ({
            ...item,
            key: item.id,
          }))}
          loading={state === LOADING}
          rowClassName="table-row"
          columns={columns}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              searchParams={searchParams}
            />
          )}
        />
        <OrganizationContractSubsidyAddForm />
      </Card>
    </>
  )
}

export default OrganizationContractSubsidy
