import { AxiosResponse } from 'axios'

export const exportToXLSX = (response: AxiosResponse<any>, name: string) => {
  const blob = response.data
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url

  const date = new Date(
    Date.parse(response.headers['last-modified']),
  ).toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  link.setAttribute('download', `${name}${date}.xlsx`)

  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
