import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import OwnershipForm from '@app/pages/reference-book-page-details/ownerships/components/ownership-form'
import { useGetOwnershipsQuery } from '@app/store/rtk/api/organization-passport/ownership-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ReferenceBookOwnerships: React.FC = () => {
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const { data, isFetching } = useGetOwnershipsQuery('')
  setDocumentTitle(t('ownership.title'))

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('ownership.ownership'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <OwnershipForm edit={true} ownership={record.ownership} />
        ) : null,
    },
  ]

  const dataSource = data?.response.length
    ? data.response.map((ownership) => {
        return {
          key: ownership.id,
          id: ownership.id,
          name: localizeData(ownership?.data)?.name,
          status: [ownership.status],
          ownership: ownership,
        }
      })
    : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <OwnershipForm />
          ) : null
        }
        title={t('ownership.title')}
      >
        <Table
          pagination={false}
          loading={isFetching}
          dataSource={dataSource}
          columns={columns}
        />
      </Card>
    </>
  )
}

export default ReferenceBookOwnerships
