import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import {
  IGrowthStatistics,
  IOrganizationGrowthBulkPupil,
  IOrganizationGrowthPupil,
  TOrganizationGrowthPupilCreate,
} from '@app/interfaces/organization-growth'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IRtkResponse } from '../types'

export const organizationGrowthApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'organizationGrowthApi',
  tagTypes: [ApiKeys.organizationGrowth],
  endpoints: (build) => ({
    getOrganizationGrowthStatistics: build.query<
      IRtkResponse<IGrowthStatistics[]>,
      string
    >({
      query: (args) => ({
        url: 'pupil/api/v1/pupil-growth/statistic',
        method: 'get',
        params: { ...resolveTParams(args) },
      }),
      transformResponse: (response: IGrowthStatistics[], meta) => {
        return { response, meta }
      },
      providesTags: () => [
        { type: ApiKeys.organizationGrowth, id: 'LIST-STATISTIC' },
      ],
    }),
    getOrganizationGrowthBulkPupil: build.query<
      IRtkResponse<IOrganizationGrowthBulkPupil[]>,
      string
    >({
      query: (args) => ({
        url: 'pupil/api/v1/pupil-growth',
        method: 'get',
        params: { ...resolveTParams(args) },
      }),
      transformResponse: (response: IOrganizationGrowthBulkPupil[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ pupilBodyInformation: { id } }) => ({
                type: ApiKeys.organizationGrowth,
                id: `bulk-id-${id}`,
              })),
              { type: ApiKeys.organizationGrowth, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organizationGrowth, id: 'LIST' }],
    }),
    getOrganizationGrowthPupil: build.query<IOrganizationGrowthPupil, number>({
      query: (id) => ({
        url: `pupil/api/v1/pupil-growth/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.organizationGrowth, id },
      ],
    }),
    updateOrganizationGrowthBulkPupil: build.mutation<
      IRtkResponse<Omit<IOrganizationGrowthPupil, 'pupilId'>[]>,
      IOrganizationGrowthPupil[]
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'pupil/api/v1/pupil-growth/all',
          data,
        }
      },
      transformResponse: (
        response: Omit<IOrganizationGrowthPupil, 'pupilId'>[],
        meta,
      ) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              ...result.response.map(({ id }) => ({
                type: ApiKeys.organizationGrowth,
                id,
              })),
              ...result.response.map(({ id }) => ({
                type: ApiKeys.organizationGrowth,
                id: `bulk-id-${id}`,
              })),
              { type: ApiKeys.organizationGrowth, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organizationGrowth, id: 'LIST' }],
    }),
    createOrganizationGrowthPupil: build.mutation<
      IRtkResponse<TOrganizationGrowthPupilCreate>,
      Subset<IOrganizationGrowthPupil>
    >({
      query: (data) => ({
        method: 'POST',
        url: 'pupil/api/v1/pupil-growth',
        data,
      }),
      transformResponse: (response: IOrganizationGrowthPupil, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationGrowth, id: 'LIST' },
        { type: ApiKeys.organizationGrowth, id: `bulk-id-${arg.id}` },
        { type: ApiKeys.organizationGrowth, id: arg.id },
      ],
    }),
  }),
})

const useStateGetOrganizationGrowthBulkPupilQuery =
  organizationGrowthApi.endpoints.getOrganizationGrowthBulkPupil.useQueryState
const useStateGetOrganizationGrowthPupilQuery =
  organizationGrowthApi.endpoints.getOrganizationGrowthPupil.useQueryState

export const {
  useGetOrganizationGrowthBulkPupilQuery,
  useGetOrganizationGrowthPupilQuery,
  useGetOrganizationGrowthStatisticsQuery,
  useCreateOrganizationGrowthPupilMutation,
  useUpdateOrganizationGrowthBulkPupilMutation,
} = organizationGrowthApi

export {
  useStateGetOrganizationGrowthBulkPupilQuery,
  useStateGetOrganizationGrowthPupilQuery,
}
