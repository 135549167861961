import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationPassport,
  IOrganizationPassportState,
} from '@app/interfaces/organization-passport/organization-passport'
import {
  CLEAR_INSTITUTION_PASSPORT,
  CREATE_INSTITUTION_PASSPORT,
  FETCH_INSTITUTION_PASSPORT_FAILURE,
  FETCH_INSTITUTION_PASSPORT_REQUEST,
  FETCH_INSTITUTION_PASSPORT_SUCCESS,
  REFRESH_INSTITUTION_PASSPORT,
  UPDATE_INSTITUTION_PASSPORT,
} from '@app/store/types/institution-passport'

const initialState: IOrganizationPassportState = {
  institutionPassport: {} as IOrganizationPassport,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const institutionPassportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTITUTION_PASSPORT_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_INSTITUTION_PASSPORT_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        institutionPassport: action.payload,
      }
    case FETCH_INSTITUTION_PASSPORT_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        institutionPassport: {},
      }
    case REFRESH_INSTITUTION_PASSPORT:
      return { ...state, refresh: !state.refresh }
    case CREATE_INSTITUTION_PASSPORT:
      return { ...state, institutionPassport: action.payload }
    case UPDATE_INSTITUTION_PASSPORT:
      return { ...state, institutionPassport: action.payload }
    case CLEAR_INSTITUTION_PASSPORT:
      return { ...state, institutionPassport: {} }
    default:
      return state
  }
}
