import useLocalization from '@app/hooks/useLocalization'
import { IWaterCleaningMethods } from '@app/interfaces/organization-passport/water-cleaning-methods'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  waterTreatment: IWaterCleaningMethods[]
}

const WaterTreatmentItem: React.FC<props> = ({ waterTreatment }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('drinkingWaterTreatment.drinkingWaterTreatment')}
        name={'drinkingWaterTreatment'}
      >
        <Select
          placeholder={t('institutionPlaceholders.drinkingWaterTreatment')}
        >
          {sortByName(localize(waterTreatment)).map((waterTreatment, index) => {
            if (waterTreatment.status) {
              return (
                <Select.Option
                  key={index}
                  value={waterTreatment.id!.toString()}
                >
                  {waterTreatment?.data?.name}
                </Select.Option>
              )
            }
            return null
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default WaterTreatmentItem
