import { FilterOutlined } from '@ant-design/icons'
import educationGroupsRepository from '@app/api/repositories/education-groups-repository'
import useQuery from '@app/hooks/useQuery'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { Button, Col, Form, Input, Row, Select, SelectProps } from 'antd'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  PUPIL_FILTER_STATUSES,
  usePupilStatuses,
} from '../../../../../../hooks/usePupilStatuses'

interface props {
  request: (string) => void
  load: boolean
  searchParams: URLSearchParams
}

interface params {
  id?: string
}

const OrganizationPupilsFilterForm: React.FC<props> = ({
  request,
  load,
  searchParams,
}) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const pupilStatuses = usePupilStatuses()
  const params: params = useParams()
  const [groups, setGroups] = useState<IEducationGroup[]>([])
  const { serializeQuery } = useQuery()

  useEffect(() => {
    const source = axios.CancelToken.source()
    educationGroupsRepository
      .getAll({
        params: `organizationId.equals=${params.id}&size=100`,
        source,
      })
      .then((response) => {
        setGroups(response.data)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          openNotificationWithIcon('error', t('educationGroup.error'))
        }
      })
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const onFinish = (values: any) => {
    const newValues = {
      ...(values.status
        ? { 'status.equals': values.status }
        : { 'status.equals': PUPIL_FILTER_STATUSES.APPROVED }),
      'id.equals': values.id,
      'pin.equals': values.pin,
      'educationGroupId.equals': values.educationGroup,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
  }
  const onReset = (values: any) => {
    const newValues = {
      'id.equals': null,
      'status.equals': PUPIL_FILTER_STATUSES.APPROVED,
      'educationGroupId.equals': null,
    }

    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
    form.resetFields()
  }

  return (
    <Form
      form={form}
      initialValues={{ status: PUPIL_FILTER_STATUSES.APPROVED }}
      onFinish={onFinish}
      layout={'vertical'}
      onReset={() => form.resetFields(['id', 'pin', 'status'])}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label={t('pupils.pupilId')} name={'id'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('pupils.pupilStatus')} name={'status'}>
            <Select allowClear={true} options={pupilStatuses} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('educationGroup.educationGroup')}
            name={'educationGroup'}>
            <Select allowClear={true}>
              {groups?.map((group) => {
                if (group.status === 'OPEN') {
                  return (
                    <Select.Option value={group.id} key={group.id}>
                      {`#${group.id}. ${group.name}(${group.groupChildren})`}
                    </Select.Option>
                  )
                }
                return null
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div>
        <Button disabled={!load} type="default" htmlType="submit">
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          disabled={!load}
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onReset}>
          {t('general.reset')}
        </Button>
      </div>
    </Form>
  )
}

const mapStateToProps = (state) => {
  return {
    districtsById: state.districts.districtsByRegionId,
  }
}

export default connect(mapStateToProps)(OrganizationPupilsFilterForm)
