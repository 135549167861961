import { ISchedulerHistory } from '@app/interfaces/scheduler/history'
import { ISchedulerSettings } from '@app/interfaces/scheduler/scheduler-settings'
import { ISchedulerStats } from '@app/interfaces/scheduler/stats'
import { AxiosResponse } from 'axios'
import bbitApi from '../make-request'

class StatsRepository {
  getStats(source?): Promise<AxiosResponse<ISchedulerStats>> {
    return bbitApi.get(`/emis-api/api/v1/scheduler/stats`, {
      cancelToken: source?.token,
    })
  }

  getHistory(source?): Promise<AxiosResponse<ISchedulerHistory[]>> {
    return bbitApi.get(`/emis-reports/api/v1/reports/emis-api-history`, {
      cancelToken: source?.token,
    })
    /** old version */
    //     return bbitApi.get(`/emis-api/api/v1/scheduler/history`, {
    //       cancelToken: source?.token,
    //     })
  }

  getSettings(source?): Promise<AxiosResponse<ISchedulerSettings>> {
    return bbitApi.get(`/emis-api/api/v1/scheduler/settings`, {
      cancelToken: source?.token,
    })
  }

  updateExpired(data?: any): Promise<AxiosResponse<string>> {
    return bbitApi.patch(`/emis-api/api/v1/scheduler/settings/expired`, data)
  }

  updateWaiting(data?: any): Promise<AxiosResponse<string>> {
    return bbitApi.patch(`/emis-api/api/v1/scheduler/settings/waiting`, data)
  }

  updateCheckDiff(data?: any): Promise<AxiosResponse<string>> {
    return bbitApi.patch(`/emis-api/api/v1/scheduler/settings/check-diff`, data)
  }

  run(): Promise<AxiosResponse<boolean>> {
    return bbitApi.get(`/emis-api/api/v1/scheduler/run`)
  }

  stop(): Promise<AxiosResponse<boolean>> {
    return bbitApi.get(`/emis-api/api/v1/scheduler/stop`)
  }

  isRunning(): Promise<AxiosResponse<boolean>> {
    return bbitApi.get(`/emis-api/api/v1/scheduler/is-running`)
  }
}

export default new StatsRepository()
