import useLocalization from '@app/hooks/useLocalization'
import { IInstitutionType } from '@app/interfaces/organization-passport/institution-type'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  institutionType: IInstitutionType[]
}

const InstitutionTypeItem: React.FC<props> = ({ institutionType }) => {
  const [t, i18n] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('institutionType.institutionType')}
        name={'institutionType'}
      >
        <Select placeholder={t('institutionPlaceholders.institutionType')}>
          {sortByName(localize(institutionType)).map(
            (institutionType, index) => {
              if (institutionType.status) {
                return (
                  <Select.Option
                    key={index}
                    value={institutionType.id!.toString()}
                  >
                    {institutionType?.data?.name}
                  </Select.Option>
                )
              }
              return null
            },
          )}
        </Select>
      </Form.Item>
    </>
  )
}

export default InstitutionTypeItem
