import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { IUserInfo } from '@app/interfaces/user-info'
import { createApi } from '@reduxjs/toolkit/query/react'

export const userInfoApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'userInfoApi',
  tagTypes: [ApiKeys.userInfo],
  endpoints: (build) => ({
    getUserInfo: build.query<IUserInfo, void>({
      query: () => ({
        url: 'api/v1/account/info',
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.userInfo, id: result?.id },
      ],
    }),
  }),
})

const useStateGetUserInfoQuery = userInfoApi.endpoints.getUserInfo.useQueryState

export const { useGetUserInfoQuery } = userInfoApi

export { useStateGetUserInfoQuery }
