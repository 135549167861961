import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import MenuBodyItem from '@app/pages/menu/components/menu-calendar/components/menu-calendar-by-period/components/menu-body-item'
import { getMenuByPeriod } from '@app/store/actions/organization-menus-actions'
import { DatePicker, Spin } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuBodyDay from './components/menu-body-day'
import './menu-calendar-by-period.less'

const MenuCalendarByPeriod: React.FC = () => {
  const { selectors } = useCreateSelector()
  const [t] = useTranslation()
  const [tableDate, setTableDate] = useState<moment.Moment>(moment())
  const dispatch = useDispatch()
  const { menuByPeriod, state } = useSelector(selectors.menus.menusState)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getMenuByPeriod(
        {
          params: `workDateFrom=${tableDate
            .startOf('month')
            .format('yyyy-MM-DD')}&workDateTo=${tableDate
            .endOf('month')
            .format('yyyy-MM-DD')}`,
          source: source,
        },
        t('organizationMenu.calendar.errors.byPeriod'),
      ),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [tableDate])

  const onChangeTableDateHandler = (date: moment.Moment | null) => {
    setTableDate(date ?? moment())
  }

  const onDayClickHandler = (date: string) => {
    const index = menuByPeriod.findIndex((menu) => menu.workDate === date)
    setSelectedIndex(index)
  }

  return (
    <div className="menu-calendar-by-period">
      <div className="menu-calendar-by-period__header">
        <div className="menu-calendar-by-period__header--content">
          <p className="title">{tableDate.format('MMMM, yyyy')}</p>
          <DatePicker
            onChange={onChangeTableDateHandler}
            value={tableDate}
            picker="month"
            locale={locale}
          />
        </div>
      </div>
      {state !== LOADING ? (
        <div className="menu-calendar-by-period__body">
          <div className="menu-calendar-by-period__body--left">
            {menuByPeriod.map((day, index) => (
              <MenuBodyDay
                key={index}
                day={day.workDate}
                selected={index === selectedIndex}
                clickHandler={onDayClickHandler}
              />
            ))}
          </div>
          <div className="menu-calendar-by-period__body--right">
            {cloneDeep(menuByPeriod[selectedIndex])
              ?.simpleContent?.sort((a, b) => a.mealType.id - b.mealType.id)
              .map((content, index) => {
                return <MenuBodyItem menuContent={content} key={index} />
              })}
          </div>
        </div>
      ) : (
        <Spin size={'large'} style={{ marginTop: '100px' }} />
      )}
    </div>
  )
}

export default MenuCalendarByPeriod
