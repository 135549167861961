import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { createApi } from '@reduxjs/toolkit/query/react'

export const emptyEmployeeApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'employeeApi',
  tagTypes: [
    ApiKeys.employee,
    ApiKeys.employeePosition,
    ApiKeys.employeePositionDictionary,
    ApiKeys.employeeRate,
    ApiKeys.employeeStatus,
  ],
  endpoints: () => ({}),
})
