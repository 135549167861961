import { IOrganizationTimesheetEmployee } from '@app/interfaces/organization-timesheet'

export enum borderType {
  ALL_ROUND,
  CUSTOM,
  NONE,
}

const useXlsx = () => {
  const formatHours = (time: string): string => {
    if (!time) return '-'
    // let hours = Number(time.split(':')[0])
    // const minutes = time.split(':')[1]
    // if (!(Number(minutes) < 30)) hours++
    const hoursAndMinutes = time.substring(0, 5)
    if (hoursAndMinutes === '00:00') return '+'
    return hoursAndMinutes
  }

  const formatDate = (date: string): string => {
    if (date.startsWith('-')) date = date.substring(1)
    if (date === '00:00:00') return '0'
    if (date.match('00:00:((?!00).)*')) {
      return '0'
    }
    return date.substring(0, 5)
    // date = date.substring(0, 5)
    // let hour = Number(date.substring(0, 2).replace(/^0+/, ''))
    // const minutes = date.substring(3, 5).replace(/^0+/, '')
    // if (!(Number(minutes) < 30)) hour++
    // return hour.toString()
  }

  const setWidthForCells = (number: number) => {
    const arr: any[] = []
    for (let i = 0; i < number; i++) {
      arr.push({
        width: 5,
      })
    }
    return arr
  }

  const addBlankStyledCellsForEmployee = (number: number) => {
    const result: any[] = []
    for (let i = 0; i < number - 1; i++) {
      result.push({
        v: '',
        t: 's',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          border: border,
        },
      })
    }
    return result
  }

  const getMinMaxDays = (data: IOrganizationTimesheetEmployee[]) => {
    let minDate = 0
    let maxDate = 0
    if (!data.length) return minDate
    data.forEach((item, itemIndex) => {
      item.data.forEach((workDay, index) => {
        let dateDay = workDay.workDate.split('-')[2]
        dateDay = dateDay.replace(/^0+/, '')
        if (index === 0 && itemIndex === 0) {
          minDate = Number(dateDay)
          maxDate = Number(dateDay)
        } else {
          if (Number(dateDay) < minDate) minDate = Number(dateDay)
          if (maxDate < Number(dateDay)) maxDate = Number(dateDay)
        }
      })
    })
    return [minDate, maxDate]
  }

  const createHeaderCell = (
    title: string,
    args?: { textRotation?: number },
  ) => {
    return [
      {
        v: title,
        t: 's',
        s: {
          alignment: {
            wrapText: true,
            vertical: 'center',
            horizontal: 'center',
            textRotation: args?.textRotation,
          },
          font: { bold: true },
          border: border,
        },
      },
    ]
  }

  const createHolidayCell = (title: string) => {
    return {
      v: title,
      t: 's',
      s: {
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'center',
        },
        font: { bold: true },
        border: border,
        fill: { fgColor: { rgb: 'B2BEB5' } },
      },
    }
  }

  const createEmptyObjects = (number: number) => {
    const arr: any[] = []
    for (let i = 0; i < number; i++) {
      arr.push({})
    }
    return arr
  }

  const createUnderlineObjects = (number: number) => {
    const arr: any[] = []
    for (let i = 0; i < number; i++) {
      arr.push(
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
      )
    }
    return arr
  }

  const createEmptyStrings = (number: number) => {
    const arr: any[] = []
    for (let i = 0; i < number; i++) {
      arr.push('')
    }
    return arr
  }

  const createCell = (args: {
    title?: string
    font?: { bold?: boolean; size?: number }
    alignment?: {
      horizontal?: string
      vertical?: string
      wrapText?: boolean
      textRotation?: number
    }
    border?: {
      right?: boolean
      left?: boolean
      top?: boolean
      bottom?: boolean
    }
    borderType?: borderType
  }) => {
    return {
      v: args.title,
      t: 's',
      s: {
        alignment: {
          wrapText: args.alignment?.wrapText,
          vertical: args.alignment?.vertical,
          horizontal: args.alignment?.horizontal,
          textRotation: args.alignment?.textRotation,
        },
        font: { bold: args.font?.bold, sz: args.font?.size },
        border:
          args.borderType === borderType.ALL_ROUND
            ? border
            : args.borderType === borderType.CUSTOM
            ? {
                right: args?.border?.right && {
                  style: 'thin',
                  color: { rgb: '000000' },
                },
                left: args?.border?.left && {
                  style: 'thin',
                  color: { rgb: '000000' },
                },
                top: args?.border?.top && {
                  style: 'thin',
                  color: { rgb: '000000' },
                },
                bottom: args?.border?.bottom && {
                  style: 'thin',
                  color: { rgb: '000000' },
                },
              }
            : '',
      },
    }
  }

  const border = {
    right: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
  }

  const blankBorderedCell = [
    {
      v: '',
      t: 's',
      s: { border: border },
    },
  ]

  return {
    addBlankStyledCellsForEmployee,
    formatDate,
    getMinMaxDays,
    border,
    blankBorderedCell,
    createHeaderCell,
    createHolidayCell,
    formatHours,
    setWidthForCells,
    createCell,
    createEmptyObjects,
    createUnderlineObjects,
    createEmptyStrings,
  }
}

export default useXlsx
