import { IData } from '@app/interfaces'
import { IOrganization } from '@app/interfaces/organization/organization'
import { IPupil, IPupilEducation } from '@app/interfaces/pupil/pupils'
import ChildDevelopmentView from '@app/pages/child-stats/components/child-developement-view'
import { QRCodeGen } from '@app/ui/atoms/qr-code-gen'
import { Button, Card } from 'antd'
import axios from 'axios'
import { useRef } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

export const getPupilReadnessPrintableUrl = ({
  pupilId,
  pupilReadinessMapId,
}: {
  pupilId: number
  pupilReadinessMapId: number | null
}): string => {
  return `${window.location.href}/pupils-readiness-map?pupilReadinessMapId=${pupilReadinessMapId}&pupilId=${pupilId}`
}

interface IPupilReadnessMapQR {
  id: number
  pupil: Pick<IPupil, 'fullName' | 'gender' | 'birthDate'>
  details: Detail[]
  completionDate: Date
  directorFIO: string
  organization: IOrganization
}

export interface Detail {
  id: number
  grade: string
  subtitle: Subtitle
  comment: string
}

export interface Subtitle {
  id: number
}

const ChildDevelopmentPrint = () => {
  const componentRef = useRef<any>()
  const [searchParams] = useSearchParams()
  const pupilId = searchParams.get('pupilId')
  const pupilReadinessMapId = searchParams.get('pupilReadinessMapId')

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const { data: pupilReadnessMapQR } = useQuery(
    'pupil/api/v1/pupils-readiness-map/QR',
    async () => {
      const { data } = await axios.get<IPupilReadnessMapQR>(
        `/pupil/api/v1/pupils-readiness-map/QR/${pupilReadinessMapId}`,
      )
      return data
    },
    { enabled: !!pupilReadinessMapId },
  )

  if (!pupilId) return <h1>PupilId isEmpty</h1>
  return (
    <Card bordered={true} style={{ margin: 20, padding: 20 }}>
      <QRCodeGen
        value={getPupilReadnessPrintableUrl({
          pupilId: Number(pupilId),
          pupilReadinessMapId: Number(pupilReadinessMapId),
        })}
      />
      <section ref={componentRef}>
        <ChildDevelopmentView
          pupil={pupilReadnessMapQR?.pupil}
          pupilReadinessMapId={Number(pupilReadinessMapId)}
          edit={false}
        />
      </section>
      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={() => handlePrint()}>
        Print
      </Button>
    </Card>
  )
}

export default ChildDevelopmentPrint
