import useLocalization from '@app/hooks/useLocalization'
import { IOwnership } from '@app/interfaces/organization-passport/ownerships'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  ownership: IOwnership[]
}

const OwnershipsItem: React.FC<props> = ({ ownership }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item label={t('ownership.ownership')} name={'ownership'}>
        <Select placeholder={t('institutionPlaceholders.ownership')}>
          {sortByName(localize(ownership)).map((ownership, index) => {
            if (ownership.status) {
              return (
                <Select.Option key={index} value={ownership.id!.toString()}>
                  {ownership?.data?.name}
                </Select.Option>
              )
            }
            return null
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default OwnershipsItem
