import { PlusOutlined } from '@ant-design/icons'
import OrganizationPositionRepository from '@app/api/repositories/organization-position-repository'
import { IPathParams } from '@app/constants/path-params'
import useLocalization from '@app/hooks/useLocalization'
import { IEmployeePosition } from '@app/interfaces/employee/employee-position'
import { IEmployeeRate } from '@app/interfaces/employee/employee-rate'
import {
  IOrganizationPosition,
  IOrganizationPositionCreate,
} from '@app/interfaces/employee/organization-position'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { createOrganizationPosition } from '@app/store/actions/organization-position-actions'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Form, Input, Modal, Select, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

interface props {
  edit?: boolean
  employeePositions?: IEmployeePosition[]
  employeeRates?: IEmployeeRate[]
  organizationPosition?: IOrganizationPosition
  setUpdate: () => void
}

const OrganizationPositionForm: React.FC<props> = ({
  edit,
  employeePositions,
  employeeRates,
  setUpdate,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  const dispatch = useDispatch()
  const { localize } = useLocalization()

  const onFinish = async (values: any) => {
    const newValues: IOrganizationPositionCreate = {
      organizationId: Number(params.id),
      employeeRateDictionaryId: Number(values.employeeRateDictionaryId),
      employeePositionDictionaryId: Number(values.employeePositionDictionaryId),
    }

    setConfirmLoading(true)
    await OrganizationPositionRepository.create(newValues)
      .then((response: AxiosResponse<IOrganizationPosition>) => {
        if (response.status === 201 && response.data.id) {
          dispatch(createOrganizationPosition(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
          setUpdate()
          setConfirmLoading(false)
          hideModal()
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('errors.error'))
        setConfirmLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        <PlusOutlined />
        {t('employeePosition.add')}
      </Button>

      <Modal
        open={visible}
        title={t('employeePosition.add')}
        onCancel={hideModal}
        destroyOnClose={true}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}>
        <Spin spinning={confirmLoading}>
          <Form form={form} onFinish={onFinish} layout={'vertical'}>
            <Form.Item
              hidden={true}
              name={'id'}
              initialValue={Number(params.id)}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              label={t('employeePosition.employeePosition')}
              name={'employeePositionDictionaryId'}>
              <Select disabled={confirmLoading} allowClear={true}>
                {sortByName(localize(employeePositions))?.map(
                  (employeePosition) => {
                    if (employeePosition.status)
                      return (
                        <Select.Option
                          key={employeePosition.id}
                          value={employeePosition.id}>
                          {employeePosition?.data?.name}
                        </Select.Option>
                      )
                  },
                )}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              label={t('employeeRate.employeeRate')}
              name={'employeeRateDictionaryId'}>
              <Select disabled={confirmLoading} allowClear={true}>
                {sortByName(localize(employeeRates))?.map((employeeRate) => {
                  if (employeeRate.status) {
                    return (
                      <Select.Option
                        key={employeeRate.id}
                        value={employeeRate.id}>
                        {employeeRate.data.name}
                      </Select.Option>
                    )
                  }
                })}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default OrganizationPositionForm
