// institutions

export const GET_INSTITUTION_PASSPORT = 'GET_INSTITUTION_PASSPORT'
export const CREATE_INSTITUTION_PASSPORT = 'CREATE_INSTITUTION'
export const UPDATE_INSTITUTION_PASSPORT = 'UPDATE_INSTITUTION'
export const DELETE_INSTITUTION_PASSPORT = 'DELETE_INSTITUTION'
export const REFRESH_INSTITUTION_PASSPORT = 'REFRESH_INSTITUTION_PASSPORT'
export const CLEAR_INSTITUTION_PASSPORT = 'CLEAR_INSTITUTION_PASSPORT'

export const FETCH_INSTITUTION_PASSPORT_REQUEST =
  'FETCH_INSTITUTION_PASSPORT_REQUEST'
export const FETCH_INSTITUTION_PASSPORT_SUCCESS =
  'FETCH_INSTITUTION_PASSPORT_SUCCESS'
export const FETCH_INSTITUTION_PASSPORT_FAILURE =
  'FETCH_INSTITUTION_PASSPORT_FAILURE'
