import { ApiKeys } from '@app/constants/api-keys'
import { LOADING } from '@app/constants/redux-state'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import {
  IAttendanceMonitoringByDistrict,
  IAttendanceMonitoringByGroup,
  IAttendanceMonitoringByOrg,
  IAttendanceMonitoringByRegion,
} from '@app/interfaces/organization-attendance-monitoring'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import Pagination from '@app/shared/pagination/pagination'
import {
  clearAttendanceMonitoringDistrict,
  clearAttendanceMonitoringGroup,
  clearAttendanceMonitoringOrg,
  clearAttendanceMonitoringRegion,
  getAttendanceMonitoringDistricts,
  getAttendanceMonitoringGroup,
  getAttendanceMonitoringOrg,
  getAttendanceMonitoringRegions,
} from '@app/store/actions/organization-attendance-monitoring-actions'
import { RootState } from '@app/store/store'
import { findById } from '@app/utils/find-by-id/find-by-id'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Col, DatePicker, Form, Row, Table } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import OrganizationAttendanceMonitoringFilterForm from './components/organization-attendance-monitoring-filter-form'
import './organization-attendance-monitoring.less'

interface Props {
  attendanceMonitoringByOrg: IAttendanceMonitoringByOrg[]
  attendanceMonitoringByGroup: IAttendanceMonitoringByGroup[]
  attendanceMonitoringByRegion: IAttendanceMonitoringByRegion[]
  attendanceMonitoringByDistrict: IAttendanceMonitoringByDistrict[]
  attendanceMonitoringState: string
}

enum DisplayType {
  DOO,
  GROUP,
  DISTRICT,
  REGION,
}

const OrganizationAttendanceMonitoring: React.FC<Props> = ({
  attendanceMonitoringByOrg,
  attendanceMonitoringByRegion,
  attendanceMonitoringByDistrict,
  attendanceMonitoringByGroup,
  attendanceMonitoringState,
}) => {
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { count } = useSelector(
    (state: RootState) => state.organizationAttendanceMonitoring,
  )
  const [tableDate, setTableDate] = useState<moment.Moment>(moment())
  const [tableData, setTableData] = useState<any[]>([])
  const [displayType, setDisplayType] = useState<DisplayType>(
    DisplayType.REGION,
  )
  const { localize } = useLocalization()
  const [showTable, setShowTable] = useState<boolean>(false)
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts } = useCache<IDistrict>(ApiKeys.district)
  const { searchParams, requestFunction, handleTableChange, setSearchParams } =
    usePagination(`page=0&size=10`, {
      page: 1,
      size: 10,
    })
  setDocumentTitle(t('organizationAttendance.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    searchParams.set('year', tableDate.format('YYYY'))
    searchParams.set('month', tableDate.format('MM'))
    if (showTable) {
      if (searchParams.has('summary')) {
        if (searchParams.has('regionId')) {
          setDisplayType(DisplayType.DISTRICT)
          dispatch(
            getAttendanceMonitoringDistricts(
              { params: searchParams },
              t('organizationMonitoring.errors.load'),
            ),
          )
        } else {
          setDisplayType(DisplayType.REGION)
          dispatch(
            getAttendanceMonitoringRegions(
              { params: searchParams },
              t('organizationMonitoring.errors.load'),
            ),
          )
        }
      } else {
        if (searchParams.has('organizationId')) {
          setDisplayType(DisplayType.GROUP)
          dispatch(
            getAttendanceMonitoringGroup(
              { params: searchParams },
              searchParams.get('organizationId')!,
              t('organizationMonitoring.errors.load'),
            ),
          )
        } else {
          setDisplayType(DisplayType.DOO)
          dispatch(
            getAttendanceMonitoringOrg(
              { params: searchParams },
              t('organizationMonitoring.errors.load'),
            ),
          )
        }
      }
    }
    setShowTable(true)
    return () => {
      source.cancel('Component got unmounted')
      dispatch(clearAttendanceMonitoringOrg())
      dispatch(clearAttendanceMonitoringGroup())
      dispatch(clearAttendanceMonitoringRegion())
      dispatch(clearAttendanceMonitoringDistrict())
    }
  }, [searchParams, tableDate])

  useEffect(() => {
    if (displayType === DisplayType.REGION) {
      !isEmpty(attendanceMonitoringByRegion) &&
        setTableData(
          attendanceMonitoringByRegion.map((item, index) => ({
            ...item,
            key: `region-attendance-${index}`,
            ...item.workDays,
          })),
        )
    }
  }, [attendanceMonitoringByRegion])

  useEffect(() => {
    if (displayType === DisplayType.DISTRICT) {
      !isEmpty(attendanceMonitoringByDistrict) &&
        setTableData(
          attendanceMonitoringByDistrict.map((item, index) => ({
            ...item,
            key: `district-attendance-${index}`,
            ...item.workDays,
          })),
        )
    }
  }, [attendanceMonitoringByDistrict])

  useEffect(() => {
    if (displayType === DisplayType.DOO) {
      !isEmpty(attendanceMonitoringByOrg) &&
        setTableData(
          attendanceMonitoringByOrg.map((item, index) => ({
            ...item,
            key: `doo-attendance-${index}`,
            ...item.workDays,
          })),
        )
    }
  }, [attendanceMonitoringByOrg])

  useEffect(() => {
    if (displayType === DisplayType.GROUP) {
      !isEmpty(attendanceMonitoringByGroup) &&
        setTableData(
          attendanceMonitoringByGroup.map((item, index) => ({
            ...item,
            key: `group-attendance-${index}`,
            ...item.workDays,
          })),
        )
    }
  }, [attendanceMonitoringByGroup])

  const onElementClicked = (key: string, value: string, type: DisplayType) => {
    searchParams.set(key, value)
    key === 'regionId'
      ? searchParams.set('summary', 'true')
      : searchParams.delete('summary')
    setDisplayType(type)
    setSearchParams(new URLSearchParams(searchParams.toString()))
    // form.setFieldsValue({ [key]: Number(value) })
  }

  const onChangeTableDateHandler = (date: moment.Moment | null) => {
    setTableData([])
    setTableDate(date ?? moment())
  }

  const getColumns = (tableData) => {
    const initColumns = [
      displayType === DisplayType.GROUP
        ? {
            title: t('educationGroup.title'),
            dataIndex: 'groupName',
            key: 'groupName',
            width: 200,
            fixed: 'left',
            render: (groupName) => (
              <p style={{ width: 200 }} className="ellipsis-cell">
                {groupName}
              </p>
            ),
          }
        : displayType === DisplayType.DOO
        ? {
            title: t('organizationMonitoring.table.organizationName'),
            dataIndex: 'organizationName',
            key: 'organizationName',
            width: 200,
            fixed: 'left',
            render: (organizationName, record) => (
              <p
                style={{
                  width: 200,
                  color: 'var(--primary-color)',
                  cursor: 'pointer',
                }}
                className="ellipsis-cell"
                onClick={() =>
                  onElementClicked(
                    'organizationId',
                    record.organizationId,
                    DisplayType.GROUP,
                  )
                }
              >
                {organizationName}
              </p>
            ),
          }
        : displayType === DisplayType.REGION
        ? {
            title: t('region.title'),
            key: 'regionName',
            width: 200,
            fixed: 'left',
            render: (cell) => (
              <p
                style={{
                  width: 200,
                  color: 'var(--primary-color)',
                  cursor: 'pointer',
                }}
                className="ellipsis-cell"
                onClick={() =>
                  onElementClicked(
                    'regionId',
                    cell.region.id,
                    DisplayType.DISTRICT,
                  )
                }
              >
                {cell.region?.id &&
                  findById(localize(regions), cell.region.id)?.data.name}
              </p>
            ),
          }
        : {
            title: t('district.district'),
            key: 'districtName',
            width: 200,
            fixed: 'left',
            render: (cell) => (
              <p
                style={{
                  width: 200,
                  color: 'var(--primary-color)',
                  cursor: 'pointer',
                }}
                className="ellipsis-cell"
                onClick={() =>
                  onElementClicked(
                    'districtId',
                    cell.district.id,
                    DisplayType.DOO,
                  )
                }
              >
                {cell.district?.id &&
                  findById(localize(districts), cell.district.id)?.data.name}
              </p>
            ),
          },
      {
        title: t('organizationMonitoring.table.capacity'),
        dataIndex: 'capacity',
        key: 'capacity',
        width: 120,
        defaultSortOrder: 'descend',
        sorter: {
          compare: (a, b) => a.capacity - b.capacity,
          multiple: 3,
        },
      },
      {
        title: t('organizationMonitoring.table.pupilCount'),
        dataIndex: 'pupilsQuantity',
        width: 110,
        key: 'pupilsQuantity',
        defaultSortOrder: 'descend',
        sorter: {
          compare: (a, b) => a.pupilsQuantity - b.pupilsQuantity,
          multiple: 4,
        },
      },
    ]
    const dayData = computeDays(tableData)
    return [...initColumns, ...dayData]
  }

  const computeDays = (data: any) => {
    const arr: any[] = []
    const currentDate = tableDate.clone().startOf('month')
    const daysCount = currentDate.daysInMonth()
    for (let i = 0; i < daysCount; i++) {
      const today = currentDate.format('yyyy-MM-DD')

      arr.push({
        title: (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span key={today}>
              {today.split('-')[1]}.{today.split('-')[2]}
            </span>
            <span>{t('organizationMonitoring.presentTitle')}</span>
          </div>
        ),
        dataIndex: i,
        key: i,
        render: (cell) => {
          return {
            children: (
              <div key={cell?.organizationId} className="day-cell">
                <div className="day-cell__row">
                  <div className="day-cell__row--present">
                    {cell?.presentPupils}
                  </div>
                  <div className="day-cell__row--present">
                    {cell?.presentPupilsPercentage}%
                  </div>
                </div>
                {/* <div className="day-cell__row">
                  <div className="day-cell__row--absent">
                    {cell?.absentPupils}
                  </div>
                  <div className="day-cell__row--absent">
                    {cell?.absentPupilPercentage}%
                  </div>
                </div> */}
              </div>
            ),
          }
        },
      })
      currentDate.add(1, 'd')
    }
    return arr
  }

  return (
    <>
      <div className="header-form">
        <OrganizationAttendanceMonitoringFilterForm
          request={requestFunction}
          searchParams={searchParams}
          form={form}
        />
      </div>
      <Card>
        <Row justify="space-around" align="middle">
          <Col flex={10}>
            <DatePicker
              onChange={onChangeTableDateHandler}
              value={tableDate}
              picker="month"
              style={{ margin: '20px' }}
            />
          </Col>
        </Row>
        {!isEmpty(tableData) && showTable && (
          <Table
            loading={attendanceMonitoringState === LOADING}
            className="attendance-monitoring-table"
            pagination={false}
            onChange={handleTableChange}
            dataSource={tableData}
            columns={getColumns(tableData)}
            scroll={{ x: 'max-content' }}
            style={{ margin: '0 24px 24px 24px' }}
            footer={() =>
              displayType === DisplayType.DOO && (
                <Pagination
                  request={requestFunction}
                  totalCount={count}
                  searchParams={searchParams}
                />
              )
            }
          />
        )}
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    attendanceMonitoringByOrg:
      state.organizationAttendanceMonitoring.monitoringByOrg,
    attendanceMonitoringByGroup:
      state.organizationAttendanceMonitoring.monitoringByGroup,
    attendanceMonitoringByRegion:
      state.organizationAttendanceMonitoring.monitoringByRegion,
    attendanceMonitoringByDistrict:
      state.organizationAttendanceMonitoring.monitoringByDistrict,
    attendanceMonitoringState: state.organizationAttendanceMonitoring.state,
  }
}

export default connect(mapStateToProps, null)(OrganizationAttendanceMonitoring)
