import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IPupil,
  IPupilsState,
  IRegistryPupil,
} from '@app/interfaces/pupil/pupils'
import {
  CLEAR_PUPIL,
  CLEAR_PUPILS,
  CREATE_PUPIL,
  DELETE_PUPIL,
  FETCH_PUPILS_FAILURE,
  FETCH_PUPILS_REQUEST,
  FETCH_PUPILS_SUCCESS,
  FETCH_PUPIL_CLEANUP,
  FETCH_PUPIL_FAILURE,
  FETCH_PUPIL_REQUEST,
  FETCH_PUPIL_SUCCESS,
  GET_REGISTRY_PUPIL,
  UPDATE_PUPIL,
} from '@app/store/types/pupils'

const initialState: IPupilsState = {
  pupils: [],
  pupil: {} as IPupil,
  registryPupil: {} as IRegistryPupil,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const pupilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGISTRY_PUPIL:
      return { ...state, registryPupil: action.payload }
    case CLEAR_PUPILS:
      return { ...state, pupils: [], state: IDLE }
    case CLEAR_PUPIL:
      return { ...state, pupil: {}, state: IDLE }
    case CREATE_PUPIL:
      return { ...state, pupils: [...state.pupils, action.payload] }
    case UPDATE_PUPIL:
      const updatedPupils = state.pupils.map(function (pupil) {
        if (action.payload.id === pupil.id) {
          return action.payload
        }
        return pupil
      })
      return { ...state, pupils: updatedPupils }
    case DELETE_PUPIL:
      const editedPupils = state.pupils.filter(function (pupil) {
        if (action.payload.id === pupil.id) {
          return false
        }
        return pupil
      })
      return { ...state, pupils: editedPupils }
    case FETCH_PUPILS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_PUPILS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        pupils: action.payload.pupils,
        count: action.payload.count,
      }
    case FETCH_PUPILS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        pupils: [],
      }
    case FETCH_PUPIL_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_PUPIL_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_PUPIL_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        pupil: action.payload,
      }
    case FETCH_PUPIL_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        pupil: {},
      }
    default:
      return state
  }
}
