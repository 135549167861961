import OrganizationStatusRepository from '@api/repositories/organization-status-repository'
import { IOrganizationStatus } from '@app/interfaces/organization/organization-status'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  createOrganizationStatus,
  updateOrganizationStatus,
} from '@app/store/actions/organization-status-actions'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Switch,
} from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  organizationStatus?: IOrganizationStatus
  edit?: boolean
}

const OrganizationStatusForm: React.FC<props> = ({
  organizationStatus,
  edit,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()

  const institutionStatusTypes = [
    'ACTIVE',
    'RECONSTRUCTION',
    'CLOSED',
    'REORGANIZATION',
  ]

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues: IOrganizationStatus = {
      id: values.id,
      sorting: values.sorting,
      status: values.status,
      data: languages,
      type: values.type,
    }
    setConfirmLoading(true)
    const organizationStatusSubmitApi = organizationStatus
      ? OrganizationStatusRepository.update
      : OrganizationStatusRepository.create

    await organizationStatusSubmitApi(newValues)
      .then((response: AxiosResponse<IOrganizationStatus>) => {
        if (organizationStatus && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateOrganizationStatus(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!organizationStatus && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createOrganizationStatus(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={
          edit ? t('organizationStatus.edit') : t('organizationStatus.add')
        }
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: organizationStatus?.id,
              sorting: organizationStatus?.sorting || 0,
              status: edit ? organizationStatus?.status : true,
              type: organizationStatus?.type,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={organizationStatus?.status === true}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -2147483648,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label={t('organizationStatus.types.type')} name={'type'}>
              <Select disabled={confirmLoading}>
                {institutionStatusTypes.map((institutionStatusTypes, index) => {
                  return (
                    <Select.Option key={index} value={institutionStatusTypes}>
                      {t(`organizationStatus.types.${institutionStatusTypes}`)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <FormList
              confirmLoading={confirmLoading}
              object={organizationStatus}
            />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default OrganizationStatusForm
