import React from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, InputNumber, Row, Select } from 'antd'
import { useSelector } from 'react-redux'
import useQuery from '@app/hooks/useQuery'
import useLocalization from '@app/hooks/useLocalization'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { AUTHORITIES } from '@app/constants/authorities'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { RootState } from '@app/store/store'
import { findById } from '@app/utils/find-by-id/find-by-id'

interface props {
  request: (string) => void
  load: boolean
}

const CamerasFilter: React.FC<props> = ({ request, load }) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { Option, OptGroup } = Select
  const { localize } = useLocalization()
  const regions = useSelector((state: RootState) =>
    localize(state.regions.regions),
  )
  const districts = useSelector((state: RootState) =>
    localize(state.districts.districts),
  )
  const { serializeQuery } = useQuery()

  const cameraStatuses = [
    {
      value: 'ACTIVE',
      message: t('general.active'),
    },
    {
      value: 'STOPPED',
      message: t('general.inActive'),
    },
  ]

  const onFinish = (values: any) => {
    const newValues = {
      organizationId: values.id,
      status: values.status,
      regionId: values.regionId,
      districtId: values.districtId,
    }
    request(serializeQuery({ query: newValues }))
  }

  const onReset = (values: any) => {
    const newValues = {
      organizationId: values.id,
      status: values.status,
      regionId: values.regionId,
      districtId: values.districtId,
    }
    request(serializeQuery({ query: newValues }))
    form.resetFields()
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      onReset={() => form.resetFields()}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string' ? value.trim() : undefined,
              },
            ]}
            name={'id'}
            label={t('cameras.organizationId')}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string' ? value.trim() : undefined,
              },
            ]}
            name={'status'}
            label={t('general.status')}>
            <Select>
              {cameraStatuses.map((status) => (
                <Select.Option key={status.value} value={status.value}>
                  {status.message}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'regionId'} label={t('region.region')}>
            <Select
              disabled={
                !rolesChecker([
                  AUTHORITIES.ADMIN,
                  AUTHORITIES.ROLE_DISTRICT_IKT,
                ])
              }
              allowClear={true}
              showSearch={true}
              filterOption={(input, option: any) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  ?.toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }>
              {sortByName(localize(regions)).map((region, i) => {
                if (region.status) {
                  return (
                    <Select.Option key={i} value={region.id!.toString()}>
                      {region.data?.name}
                    </Select.Option>
                  )
                }
                return null
              })}
            </Select>
          </Form.Item>
        </Col>

        <>
          <Col span={8}>
            <Form.Item name={'districtId'} label={t('district.district')}>
              <Select
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    ?.toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }>
                {sortByName(districts).map((district) => {
                  if (district.status) {
                    return (
                      <OptGroup
                        label={
                          findById(districts, district.regionId)?.data.name
                        }
                        key={district.id}>
                        <Option value={district.id}>
                          {district.data.name}
                        </Option>
                      </OptGroup>
                    )
                  }
                  return null
                })}
              </Select>
            </Form.Item>
          </Col>
        </>
      </Row>
      <div>
        <Button disabled={!load} type="primary" htmlType="submit">
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          disabled={!load}
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onReset}>
          {t('general.reset')}
        </Button>
      </div>
    </Form>
  )
}

export default CamerasFilter
