import QRCode from 'react-qr-code'

type props = { value: string }
export const QRCodeGen = ({ value }: props) => {
  return (
    <div>
      <span>Scan</span>
      <div
        style={{
          maxWidth: 300,
        }}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={value}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  )
}
