import {
  IAttendanceMonitoringByDistrict,
  IAttendanceMonitoringByGroup,
  IAttendanceMonitoringByOrg,
  IAttendanceMonitoringByRegion,
} from '@app/interfaces/organization-attendance-monitoring'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import bbitApi from '../make-request'
import { IPathParamsArgs } from './i-repository'

class OrganizationAttendanceMonitoringRepository {
  getByGroups(
    args: IPathParamsArgs,
    orgId: string,
  ): Promise<AxiosResponse<IAttendanceMonitoringByGroup>> {
    return bbitApi.get(`/attendance/api/v1/attendance/${orgId}/groups`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getByOrganizations(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IAttendanceMonitoringByOrg>> {
    return bbitApi.get(`/attendance/api/v1/attendance/organizations`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getByRegions(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IAttendanceMonitoringByRegion>> {
    return bbitApi.get(`/attendance/api/v1/attendance/organizations`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getByDistricts(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IAttendanceMonitoringByDistrict>> {
    return bbitApi.get(`/attendance/api/v1/attendance/organizations`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }
}

export default new OrganizationAttendanceMonitoringRepository()
