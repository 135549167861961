import { ApiKeys } from '@app/constants/api-keys'
import { IHotWaterSource } from '@app/interfaces/organization-passport/hot-water-source'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const hotWaterSourcesApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getHotWaterSources: build.query<
      IRtkResponse<IHotWaterSource[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/hot-water-sources',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IHotWaterSource[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.hotWaterSource,
                id,
              })),
              { type: ApiKeys.hotWaterSource, id: 'LIST' },
            ]
          : [{ type: ApiKeys.hotWaterSource, id: 'LIST' }],
    }),
    getHotWaterSource: build.query<IHotWaterSource, number>({
      query: (id) => ({
        url: `dictionary/api/v1/hot-water-sources/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.hotWaterSource, id },
      ],
    }),
    createHotWaterSource: build.mutation<
      IRtkResponse<IHotWaterSource>,
      IHotWaterSource
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/hot-water-sources',
          data,
        }
      },
      transformResponse: (response: IHotWaterSource, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.hotWaterSource,
          id: args.id,
        },
        { type: ApiKeys.hotWaterSource, id: 'LIST' },
      ],
    }),
    updateHotWaterSource: build.mutation<
      IRtkResponse<IHotWaterSource>,
      IHotWaterSource
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/hot-water-sources/${data.id}`,
        data,
      }),
      transformResponse: (response: IHotWaterSource, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.hotWaterSource, id: args.id },
        { type: ApiKeys.hotWaterSource, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetHotWaterSources =
  hotWaterSourcesApi.endpoints.getHotWaterSources.useQueryState
const useStateGetHotWaterSource =
  hotWaterSourcesApi.endpoints.getHotWaterSource.useQueryState

export const {
  useGetHotWaterSourcesQuery,
  useLazyGetHotWaterSourcesQuery,
  useCreateHotWaterSourceMutation,
  useUpdateHotWaterSourceMutation,
  util,
  usePrefetch,
} = hotWaterSourcesApi

export { useStateGetHotWaterSources, useStateGetHotWaterSource }
