import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { INationality } from '@app/interfaces/nationality'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { createApi } from '@reduxjs/toolkit/query/react'
import localforage from 'localforage'
import { IRtkResponse } from '../types'

export const nationalityApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'nationalityApi',
  tagTypes: [ApiKeys.nationality],
  endpoints: (build) => ({
    getNationalities: build.query<
      IRtkResponse<INationality[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/nationality',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: INationality[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.nationality,
                id,
              })),
              { type: ApiKeys.nationality, id: 'LIST' },
            ]
          : [{ type: ApiKeys.nationality, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.nationality, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getNationality: build.query<INationality, number>({
      query: (id) => ({
        url: `dictionary/api/v1/nationality/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.nationality, id }],
    }),
    createNationality: build.mutation<
      IRtkResponse<INationality>,
      Required<INationality>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/nationality',
          data,
        }
      },
      transformResponse: (response: INationality, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.nationality, id: 'LIST' }],
    }),
    updateNationality: build.mutation<
      IRtkResponse<INationality>,
      Required<INationality>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/nationality/${data.id}`,
        data,
      }),
      transformResponse: (response: INationality, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.nationality, id: 'LIST' },
        { type: ApiKeys.nationality, id: arg.id },
      ],
    }),
    partialUpdateNationality: build.mutation<
      IRtkResponse<INationality>,
      Subset<INationality>
    >({
      query: (data) => ({
        method: 'PATCH',
        url: `dictionary/api/v1/nationality/${data.id}`,
        data,
      }),
      transformResponse: (response: INationality, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.nationality, id: 'LIST' },
        { type: ApiKeys.nationality, id: arg.id },
      ],
    }),
  }),
})

const useStateGetOrganizationPassportsQuery =
  nationalityApi.endpoints.getNationalities.useQueryState
const useStateGetOrganizationPassportQuery =
  nationalityApi.endpoints.getNationality.useQueryState

export const {
  useGetNationalitiesQuery,
  useGetNationalityQuery,
  useCreateNationalityMutation,
  useUpdateNationalityMutation,
  usePartialUpdateNationalityMutation,
} = nationalityApi

export {
  useStateGetOrganizationPassportsQuery,
  useStateGetOrganizationPassportQuery,
}
