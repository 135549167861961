import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { IWarehouseState } from '@app/interfaces/organization-warehouse'
import {
  CLEAR_WAREHOUSE_DATA,
  CLEAR_WAREHOUSE_INVOICE,
  FETCH_WAREHOUSE_CLEANUP,
  FETCH_WAREHOUSE_DATA_FAILURE,
  FETCH_WAREHOUSE_DATA_REQUEST,
  FETCH_WAREHOUSE_DATA_SUCCESS,
  FETCH_WAREHOUSE_INVOICE_FAILURE,
  FETCH_WAREHOUSE_INVOICE_REQUEST,
  FETCH_WAREHOUSE_INVOICE_SUCCESS,
  TOGGLE_WAREHOUSE_ADD_INVOICE_MODAL,
} from '../types/organization-warehouse'

const initialState: IWarehouseState = {
  warehouseData: [],
  warehouseInvoice: [],
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
  toggleAddInvoiceModal: false,
}

export const organizationWarehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_WAREHOUSE_DATA:
      return { ...state, warehouseData: [], state: IDLE }
    case CLEAR_WAREHOUSE_INVOICE:
      return { ...state, warehouseInvoice: [], state: IDLE }
    case FETCH_WAREHOUSE_DATA_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_WAREHOUSE_DATA_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        warehouseData: action.payload.warehouseData,
        count: action.payload.count,
      }
    case FETCH_WAREHOUSE_DATA_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        warehouseData: [],
      }
    case FETCH_WAREHOUSE_INVOICE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_WAREHOUSE_INVOICE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        warehouseInvoice: action.payload.warehouseInvoice,
        count: action.payload.count,
      }
    case FETCH_WAREHOUSE_INVOICE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        warehouseInvoice: [],
      }
    case FETCH_WAREHOUSE_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case TOGGLE_WAREHOUSE_ADD_INVOICE_MODAL:
      return {
        ...state,
        toggleAddInvoiceModal: action.payload,
      }
    default:
      return state
  }
}
