import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { useGetEducationLanguagesQuery } from '@app/store/rtk/api/education/education-language-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Col, Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  isOpen: boolean
  edit?: boolean
}

const EducationLanguageItem: React.FC<props> = ({ isOpen, edit }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data } = useGetEducationLanguagesQuery('')

  return (
    <>
      <Col span={12}>
        <Form.Item
          name={'educationLanguageId'}
          label={t('educationLanguage.educationLanguage')}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
        >
          <Select
            disabled={
              !isOpen ||
              (edit &&
                !rolesChecker([AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER]))
            }
          >
            {sortByName(localize(data?.response))?.map((language) =>
              language.status ? (
                <Select.Option key={language.id} value={language.id}>
                  {language.data?.name}
                </Select.Option>
              ) : null,
            )}
          </Select>
        </Form.Item>
      </Col>
    </>
  )
}

export default EducationLanguageItem
