import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IEmployeeRate } from '@app/interfaces/employee/employee-rate'
import { AxiosResponse } from 'axios'

class EmployeeRateRepository implements IRepository<IEmployeeRate> {
  create(data: IEmployeeRate): Promise<AxiosResponse<IEmployeeRate>> {
    return bbitApi.post('/employee/api/v1/employee-rate-dictionary', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEmployeeRate[]>> {
    return bbitApi.get('/employee/api/v1/employee-rate-dictionary', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEmployeeRate>> {
    return bbitApi.get(
      `/employee/api/v1/employee-rate-dictionary/${args.data}`,
      { cancelToken: args.source?.token },
    )
  }

  update(data: IEmployeeRate): Promise<AxiosResponse<IEmployeeRate>> {
    return bbitApi.put(
      `/employee/api/v1/employee-rate-dictionary/${data.id}`,
      data,
    )
  }
}

export default new EmployeeRateRepository()
