import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IEmployeeRate } from '@app/interfaces/employee/employee-rate'
import EmployeeRateForm from '@app/pages/reference-book-page-details/employee-rate/components/employee-rate-form/employee-rate-form'
import { getEmployeeRates } from '@app/store/actions/employee-rate-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _employeeRates: IEmployeeRate[]
  _state: string
}

const ReferenceBookEmployeeRate: React.FC<props> = ({
  _employeeRates,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('employeeRate.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (!isEmpty(_employeeRates))
      dispatch(getEmployeeRates({ source }, t('employeeRate.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('employeeRate.employeeRate'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <EmployeeRateForm edit={true} employeeRate={record.employeeRate} />
        ) : null,
    },
  ]

  const data =
    _employeeRates && _employeeRates.length
      ? _employeeRates.map((employeeRate) => {
          return {
            key: employeeRate.id,
            id: employeeRate.id,
            name: localizeData(employeeRate?.data)?.name,
            status: [employeeRate.status],
            employeeRate: employeeRate,
          }
        })
      : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <EmployeeRateForm />
          ) : null
        }
        title={t('employeeRate.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _employeeRates: state.employeeRate.employeeRates,
    _state: state.employeeRate.state,
  }
}

export default connect(mapStateToProps)(ReferenceBookEmployeeRate)
