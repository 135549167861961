import bbitApi from '@api/make-request'
import { IPathParamsArgs, numberArgs } from '@api/repositories/i-repository'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse, Method } from 'axios'

class EducationGroupsRepository {
  create(data: any): Promise<AxiosResponse<IEducationGroup>> {
    return bbitApi.post('/education/api/v1/education-group', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEducationGroup[]>> {
    return bbitApi.get(`/education/api/v1/education-group`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEducationGroup>> {
    return bbitApi.get(`/education/api/v1/education-group/${args.data}/full`, {
      cancelToken: args.source?.token,
    })
  }

  update(
    data: any,
    method: Method = 'PUT',
  ): Promise<AxiosResponse<IEducationGroup>> {
    return bbitApi({
      method: method,
      url: `/education/api/v1/education-group/${data.id}`,
      data: data,
    })
  }
}

export default new EducationGroupsRepository()
