import { ApiKeys } from '@app/constants/api-keys'
import { IDrinkingWaterAvailableDuringYears } from '@app/interfaces/organization-passport/drinking-water-availables-during-years'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const drinkingWaterApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getDrinkingWaters: build.query<
      IRtkResponse<IDrinkingWaterAvailableDuringYears[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/drinking-water-availables',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (
        response: IDrinkingWaterAvailableDuringYears[],
        meta,
      ) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.drinkingWater,
                id,
              })),
              { type: ApiKeys.drinkingWater, id: 'LIST' },
            ]
          : [{ type: ApiKeys.drinkingWater, id: 'LIST' }],
    }),
    getDrinkingWater: build.query<IDrinkingWaterAvailableDuringYears, number>({
      query: (id) => ({
        url: `dictionary/api/v1/drinking-water-availables/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.drinkingWater, id },
      ],
    }),
    createDrinkingWater: build.mutation<
      IRtkResponse<IDrinkingWaterAvailableDuringYears>,
      IDrinkingWaterAvailableDuringYears
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/drinking-water-availables',
          data,
        }
      },
      transformResponse: (
        response: IDrinkingWaterAvailableDuringYears,
        meta,
      ) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.drinkingWater,
          id: args.id,
        },
        { type: ApiKeys.drinkingWater, id: 'LIST' },
      ],
    }),
    updateDrinkingWater: build.mutation<
      IRtkResponse<IDrinkingWaterAvailableDuringYears>,
      IDrinkingWaterAvailableDuringYears
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/drinking-water-availables/${data.id}`,
        data,
      }),
      transformResponse: (
        response: IDrinkingWaterAvailableDuringYears,
        meta,
      ) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.drinkingWater, id: args.id },
        { type: ApiKeys.drinkingWater, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetDrinkingWaters =
  drinkingWaterApi.endpoints.getDrinkingWaters.useQueryState
const useStateGetDrinkingWater =
  drinkingWaterApi.endpoints.getDrinkingWater.useQueryState

export const {
  useGetDrinkingWatersQuery,
  useLazyGetDrinkingWaterQuery,
  useCreateDrinkingWaterMutation,
  useUpdateDrinkingWaterMutation,
  util,
  usePrefetch,
} = drinkingWaterApi

export { useStateGetDrinkingWaters, useStateGetDrinkingWater }
