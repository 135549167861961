import { ApiKeys } from '@app/constants/api-keys'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import { INationality } from '@app/interfaces/nationality'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import { Card, Descriptions } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  _pupils: IOrganizationPupils
}

const OrganizationPupilsParents: React.FC<props> = ({ _pupils }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data: nationalities } = useCache<INationality[]>(ApiKeys.nationality)

  return (
    <Card style={{ marginTop: 20 }} title={t('pupils.parents')}>
      {_pupils.pupil?.parents?.map((data) => {
        return (
          <div key={data.id}>
            <Text className="pupil-parent-name">{t(data.fullName)}</Text>
            <Descriptions size={'small'} column={1} bordered>
              <Descriptions.Item label={t('pupils.id')}>
                {data.id}
              </Descriptions.Item>
              <Descriptions.Item label={t('pupils.pin')}>
                {data.pin}
              </Descriptions.Item>
              <Descriptions.Item label={t('pupils.fullName')}>
                {data.fullName}
              </Descriptions.Item>
              <Descriptions.Item label={t('parents.phone')}>
                {data.phone}
              </Descriptions.Item>
              <Descriptions.Item label={t('nationality.title')}>
                {nationalities &&
                  localize(nationalities).find((nationality) => {
                    return nationality.id === data.nationalityId
                  })?.data.name}
              </Descriptions.Item>
            </Descriptions>
          </div>
        )
      })}
    </Card>
  )
}

export default OrganizationPupilsParents
