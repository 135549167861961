import { IPathParamsArgs } from '@app/api/repositories/i-repository'
import pupilApplicationRepository from '@app/api/repositories/pupil-application-repository'
import axios from 'axios'
import {
  FETCH_QUEUE_APPLICATIONS_CLEANUP,
  FETCH_QUEUE_APPLICATIONS_FAILURE,
  FETCH_QUEUE_APPLICATIONS_REQUEST,
  FETCH_QUEUE_APPLICATIONS_SUCCESS,
} from '../types/application'

export const getQueueApplications =
  (args: IPathParamsArgs, data: number) => async (dispatch) => {
    dispatch(fetchQueueApplicationsRequest())
    await pupilApplicationRepository
      .getAllQueue(args, data)
      .then((response) => {
        const newApps = response.data.filter((app) => app.status === 'NEW')
        dispatch(fetchQueueApplicationsSuccess(newApps, newApps.length))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchQueueApplicationsFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchQueueApplicationsCleanup())
  }

const fetchQueueApplicationsRequest = () => {
  return {
    type: FETCH_QUEUE_APPLICATIONS_REQUEST,
  }
}

const fetchQueueApplicationsSuccess = (queueApplications, count) => {
  return {
    type: FETCH_QUEUE_APPLICATIONS_SUCCESS,
    payload: { queueApplications, count },
  }
}

const fetchQueueApplicationsFailure = (message, code) => {
  return {
    type: FETCH_QUEUE_APPLICATIONS_FAILURE,
    payload: { message, code },
  }
}

const fetchQueueApplicationsCleanup = () => {
  return {
    type: FETCH_QUEUE_APPLICATIONS_CLEANUP,
  }
}
