export const workList = {
  DIRECTOR: 'DIRECTOR',
  PSYCHOLOGIST: 'PSYCHOLOGIST',
  MENTOR_METHODOLOG: 'MENTOR_METHODOLOG',
  MENTOR_PREPARATORY: 'MENTOR_PREPARATORY',
  MENTOR: 'MENTOR',
  MENTOR_ASSISTANT: 'MENTOR_ASSISTANT',
  PHYSICAL_EDUCATION_INSTRUCTOR: 'PHYSICAL_EDUCATION_INSTRUCTOR',
  PHYSICAL_EDUCATION_INSTRUCTOR_SPECIAL:
    'PHYSICAL_EDUCATION_INSTRUCTOR_SPECIAL',
  MUSIC_WORKER: 'MUSIC_WORKER',
  CHOREOGRAPHER: 'CHOREOGRAPHER',
  MUSIC_TEACHER_SPECIAL: 'MUSIC_TEACHER_SPECIAL',
  CHOREOGRAPHER_SPECIAL: 'CHOREOGRAPHER_SPECIAL',
  FOREIGN_LANGUAGE_TEACHER: 'FOREIGN_LANGUAGE_TEACHER',
  FOREIGN_LANGUAGE_TEACHER_SPECIAL: 'FOREIGN_LANGUAGE_TEACHER_SPECIAL',
  HOUSEHOLD_MANAGER: 'HOUSEHOLD_MANAGER',
  HOUSEHOLD_MANAGER_SPECIAL: 'HOUSEHOLD_MANAGER_SPECIAL',
  CHEF: 'CHEF',
  COOK: 'COOK',
  COOK_SPECIAL: 'COOK_SPECIAL',
  KITCHEN_WORKER: 'KITCHEN_WORKER',
  KITCHEN_WORKER_SPECIAL: 'KITCHEN_WORKER_SPECIAL',
  LAUNDRY_OPERATOR_SEMI_AUTOMATIC_OR_MANUAL:
    'LAUNDRY_OPERATOR_SEMI_AUTOMATIC_OR_MANUAL',
  LAUNDRY_OPERATOR_SEMI_AUTOMATIC_OR_MANUAL_SPECIAL:
    'LAUNDRY_OPERATOR_SEMI_AUTOMATIC_OR_MANUAL_SPECIAL',
  WASHING_MACHINE_AUTOMATIC: 'WASHING_MACHINE_AUTOMATIC',
  WASHING_MACHINE_AUTOMATIC_SPECIAL: 'WASHING_MACHINE_AUTOMATIC_SPECIAL',
  SWIMMING_INSTRUCTOR: 'SWIMMING_INSTRUCTOR',
  WORKER_SERVING_BUILDINGS_AND_STRUCTURES:
    'WORKER_SERVING_BUILDINGS_AND_STRUCTURES',
  WORKER_SERVING_BUILDINGS_AND_STRUCTURES_SPECIAL:
    'WORKER_SERVING_BUILDINGS_AND_STRUCTURES_SPECIAL',
  OFFICE_CLEANER: 'OFFICE_CLEANER',
  GARDENER: 'GARDENER',
  SECURITY_GUARD: 'SECURITY_GUARD',
  GAS_OPERATOR_SEASONAL: 'GAS_OPERATOR_SEASONAL',
  ELECTRICIAN: 'ELECTRICIAN',
  PLUMBER_WELDER: 'PLUMBER_WELDER',
  STREET_CLEANER: 'STREET_CLEANER',
  NURSE: 'NURSE',
  NURSE_NIGHT_SHIFT: 'NURSE_NIGHT_SHIFT',
  MENTOR_ASSISTANT_NIGHT_SHIFT: 'MENTOR_ASSISTANT_NIGHT_SHIFT',
  NURSE_LFK_SPECIAL: 'NURSE_LFK_SPECIAL',
  MASSEUR_SPECIAL: 'MASSEUR_SPECIAL',
  SPEECH_THERAPIST: 'SPEECH_THERAPIST',
  SPEECH_THERAPIST_SPECIAL: 'SPEECH_THERAPIST_SPECIAL',
  TEACHER_DEFECTOLOGIST_SPECIAL: 'TEACHER_DEFECTOLOGIST_SPECIAL',
  PEDIATRICIAN_SPECIALIST: 'PEDIATRICIAN_SPECIALIST',
  OTOLARYNGOLOGIST_ENT_DOCTOR_SPECIAL: 'OTOLARYNGOLOGIST_ENT_DOCTOR_SPECIAL',
  OPHTHALMOLOGIST_SPECIAL: 'OPHTHALMOLOGIST_SPECIAL',
  NEUROPSYCHIATRIST_NEUROLOGIST_SPECIAL:
    'NEUROPSYCHIATRIST_NEUROLOGIST_SPECIAL',
  ORTHOPEDIST_SPECIAL: 'ORTHOPEDIST_SPECIAL',
  PHYSIOTHERAPIST_REHABILITOLOGIST_SPECIAL:
    'PHYSIOTHERAPIST_REHABILITOLOGIST_SPECIAL',
  TOOL_AND_EQUIPMENT_REPAIRMAN_SPECIAL: 'TOOL_AND_EQUIPMENT_REPAIRMAN_SPECIAL',
  MENTOR_SHORT_TERM_PREPARATORY: 'MENTOR_SHORT_TERM_PREPARATORY',
  MENTOR_ASSISTANT_SHORT_TERM_PREPARATORY:
    'MENTOR_ASSISTANT_SHORT_TERM_PREPARATORY',
  MENTOR_SHORT_TERM_SHORT_TERM: 'MENTOR_SHORT_TERM_SHORT_TERM',
  MENTOR_ASSISTANT_SHORT_TERM_SHORT_TERM:
    'MENTOR_ASSISTANT_SHORT_TERM_SHORT_TERM',
  NURSE_SHORT_TERM: 'NURSE_SHORT_TERM',
  MUSIC_WORKER_SHORT_TERM: 'MUSIC_WORKER_SHORT_TERM',
  MENTOR_SHORT_TERM_MOBILE: 'MENTOR_SHORT_TERM_MOBILE',
  MENTOR_ASSISTANT_SHORT_TERM_MOBILE: 'MENTOR_ASSISTANT_SHORT_TERM_MOBILE',
  BUS_DRIVER: 'BUS_DRIVER',
}
