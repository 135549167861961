import {
  REMOVE_IMAGE,
  PRELOAD_IMAGE,
  FETCH_FILE_REQUEST,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
} from '@app/store/types/files'
import { IFile, IFileState } from '@app/interfaces/files'

const initialState: IFileState = {
  preUploadedPhoto: {} as IFile,
  photo: {} as IFile,
  refresh: false,
  loading: false,
  message: '',
  code: -1,
  count: '',
}

export const filesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRELOAD_IMAGE:
      return { ...state, preUploadedPhoto: action.payload }
    case REMOVE_IMAGE:
      return { ...state, photo: {}, preUploadedPhoto: {} }
    case FETCH_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: '',
        code: -1,
        photo: action.payload,
      }
    case FETCH_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        code: action.payload.code,
        photo: {},
      }
    default:
      return state
  }
}
