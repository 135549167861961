import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IOrganizationStatus } from '@app/interfaces/organization/organization-status'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class OrganizationStatusRepository implements IRepository<IOrganizationStatus> {
  create(
    data: IOrganizationStatus,
  ): Promise<AxiosResponse<IOrganizationStatus>> {
    return bbitApi.post('/dictionary/api/v1/organization-status', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IOrganizationStatus[]>> {
    return bbitApi.get('/dictionary/api/v1/organization-status', {
      cancelToken: args.source?.token,
      params: resolveParams(args),
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IOrganizationStatus>> {
    return bbitApi.get(`/dictionary/api/v1/organization-status/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(
    data: IOrganizationStatus,
  ): Promise<AxiosResponse<IOrganizationStatus>> {
    return bbitApi.put(
      `/dictionary/api/v1/organization-status/${data.id}`,
      data,
    )
  }
}

export default new OrganizationStatusRepository()
