import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import bbitApi from '@app/api/make-request'
import organizationMenuRepository from '@app/api/repositories/organization-menu-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import { AUTH_TOKEN } from '@app/constants/authToken'
import { host } from '@app/constants/env'
import { FormType } from '@app/constants/formType'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import { IFile } from '@app/interfaces/files'
import { IProducts } from '@app/interfaces/organization-menu'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import Pagination from '@app/shared/pagination/pagination'
import {
  clearProducts,
  getMenuMeal,
  getMenuMeals,
  getMenuMeasurements,
  getMenuProducts,
  removeMenuMeal,
  toggleMenuRefresh,
} from '@app/store/actions/organization-menus-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Dropdown, Menu, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import { UploadFile } from 'antd/lib/upload/interface'
import axios, { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuMealImage from '../../menu-calendar/components/menu-meal-image/menu-body-item-image'
import MenuMealForm from './components/menu-meal-form'
import './menu-meals.less'

interface PopupProduct {
  name: string
  weight: number
  products: IProducts[]
}

const MenuMeals: React.FC = () => {
  const [t] = useTranslation()
  const { selectors } = useCreateSelector()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const [popupProduct, setPopupProduct] = useState<PopupProduct>()
  const [popupVisible, setPopupVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const {
    products,
    measurements,
    state,
    mealsCount,
    refresh,
    meals,
    formType,
  } = useSelector(selectors.menus.menusState)
  const {
    searchParams,
    setSearchParams,
    requestFunction,
    setPage,
    pageAndSize,
  } = usePagination(`page=0&size=10`, {
    page: 1,
    size: 10,
  })
  setDocumentTitle(t('routes.menu'))

  useEffect(() => {
    setSearchParams(new URLSearchParams('page=0&size=10'))
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getMenuMeals(
        { params: searchParams, source },
        t('organizationMenu.errors.meals'),
      ),
    )
    dispatch(
      getMenuProducts(
        { params: new URLSearchParams('page=0&size=1000'), source },
        t('organizationMenu.errors.products'),
      ),
    )
    if (isEmpty(measurements))
      dispatch(
        getMenuMeasurements(
          { params: new URLSearchParams('page=0&size=100') },
          t('organizationMenu.errors.measurements'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
      dispatch(clearProducts())
    }
  }, [searchParams, refresh])

  const addMeal = (
    values: any,
    mealProducts: IProducts[],
    fileList: UploadFile[],
    cb: () => void,
  ) => {
    setLoading(true)
    const newValues = {
      ...values,
      data: values.data.filter((item) => !isEmpty(item)),
      products: mealProducts.map((product) => ({
        productId: product.id,
        amount: product.amount,
      })),
    }
    if (
      fileList.length &&
      fileList[0].originFileObj &&
      fileList[0].originFileObj?.uid !== values.fileId
    ) {
      const token = localStorage.getItem(AUTH_TOKEN)
      const data = new FormData()
      data.append('file', fileList[0].originFileObj)
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'content-type': 'multipart/form-data',
        },
      }
      bbitApi
        .post<AxiosResponse<IFile>>(`${host}files/api/v1/files`, data, config)
        .then((res: any) => {
          setLoading(false)
          const apiCall =
            formType === FormType.ADD
              ? organizationMenuRepository.addMenuMeal({
                  ...newValues,
                  fileId: res.data.id,
                })
              : organizationMenuRepository.updateMenuMeal(values.id, {
                  ...newValues,
                  fileId: res.data.id,
                })
          apiCall
            .then((response) => {
              setLoading(false)
              cb()
              dispatch(toggleMenuRefresh())
              openNotificationWithIcon(
                'success',
                t(
                  `organizationMenu.meals.${
                    formType === FormType.EDIT ? 'successEdit' : 'successAdd'
                  }`,
                ),
              )
            })
            .catch((error) => {
              setLoading(false)
              openNotificationWithIcon(
                'error',
                t('organizationMenu.meals.errorAdd'),
              )
            })
        })
        .catch((err: Error) => {
          openNotificationWithIcon('error', t('users.avatarError'))
        })
    } else {
      const apiCall =
        formType === FormType.ADD
          ? organizationMenuRepository.addMenuMeal({
              ...newValues,
              fileId: !fileList.length ? null : newValues.fileId,
            })
          : organizationMenuRepository.updateMenuMeal(values.id, {
              ...newValues,
              fileId: !fileList.length ? null : newValues.fileId,
            })
      apiCall
        .then((response) => {
          setLoading(false)
          cb()
          dispatch(toggleMenuRefresh())
          openNotificationWithIcon(
            'success',
            t(
              `organizationMenu.meals.${
                formType === FormType.EDIT ? 'successEdit' : 'successAdd'
              }`,
            ),
          )
        })
        .catch((error) => {
          setLoading(false)
          openNotificationWithIcon(
            'error',
            t('organizationMenu.meals.errorAdd'),
          )
        })
    }
  }

  const onMealEditHandler = (id: number) => {
    dispatch(getMenuMeal({ data: id }, t('organizationMenu.meals.errorEdit')))
  }

  const onMealRemoveHandler = (id: number) => {
    dispatch(
      removeMenuMeal(
        { data: id },
        t('organizationMenu.meals.successRemove'),
        t('organizationMenu.meals.errorRemove'),
      ),
    )
  }

  const onMealMouseOverHandler = (meal, event) => {
    document.documentElement.style.setProperty(
      '--popup-x',
      `${event.pageX - 250}px`,
    )
    document.documentElement.style.setProperty(
      '--popup-y',
      `${event.pageY - 50}px`,
    )
    setPopupVisible(true)
    setPopupProduct(meal)
  }

  const onMealMouseOutHandler = () => {
    setPopupVisible(false)
  }

  const permissions = [
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_MENU_ALL_EDIT,
    AUTHORITIES.ROLE_MENU_REGION_EDIT,
    AUTHORITIES.ROLE_MENU_DISTRICT_EDIT,
    AUTHORITIES.ROLE_MENU_CURRENT_EDIT,
  ]

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => (
        <Text>{(pageAndSize.page - 1) * pageAndSize.size + index + 1}</Text>
      ),
    },
    {
      title: t('organizationMenu.meals.photo'),
      dataIndex: 'fileId',
      render: (text, record, index) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MenuMealImage fileId={text} width={100} height={100} />
        </div>
      ),
    },
    {
      title: t('organizationMenu.meals.name'),
      key: 'name',
      render: (record) => (
        <div
          className="meal-row"
          onMouseEnter={(event) => onMealMouseOverHandler(record, event)}
          onMouseLeave={onMealMouseOutHandler}
        >
          {record.name}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1,
      },
    },
    {
      title: t('organizationMenu.meals.weight'),
      dataIndex: 'weight',
      key: 'weight',
      sorter: {
        compare: (a, b) => a.weight - b.weight,
        multiple: 2,
      },
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (record) =>
        rolesChecker(permissions) && record ? (
          <Dropdown
            trigger={['click']}
            placement={'bottomCenter'}
            overlay={() => (
              <Menu>
                <Menu.Item key="edit">
                  <Button
                    type="text"
                    onClick={() => onMealEditHandler(record.id)}
                  >
                    <EditOutlined /> {t('general.edit')}
                  </Button>
                </Menu.Item>
                <Menu.Item key="remove">
                  <Button
                    type="text"
                    onClick={() => onMealRemoveHandler(record.id)}
                  >
                    <DeleteOutlined /> {t('general.delete')}
                  </Button>
                </Menu.Item>
              </Menu>
            )}
          >
            <Button>
              {t('general.action')} <DownOutlined />
            </Button>
          </Dropdown>
        ) : null,
    },
  ]

  const data = !isEmpty(meals)
    ? meals.map((meal) => {
        return {
          key: meal.id,
          id: meal.id,
          name: localizeData(meal.data)?.name,
          weight: meal.weight,
          products: meal.products,
          fileId: meal.fileId,
        }
      })
    : []

  return (
    <div className="meals">
      <div className="meals__header">
        {rolesChecker(permissions) && (
          <MenuMealForm addMeal={addMeal} loading={loading} />
        )}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName="table-row"
        loading={state === LOADING}
        pagination={false}
        footer={() => (
          <Pagination
            request={requestFunction}
            totalCount={mealsCount}
            setPage={setPage}
            searchParams={searchParams}
          />
        )}
      />
      <div className={`meals__popup ${popupVisible ? 'visible' : 'hidden'}`}>
        <p className="meals__popup--title">{`${popupProduct?.name} / ${popupProduct?.weight}`}</p>
        {popupProduct?.products.map((product) => (
          <div key={product.id} className="meals__popup--product">
            <p>{`${localizeData(product.data)?.name} / ${product.amount}${
              localizeData(product?.measurement.data)?.name
            }`}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MenuMeals
