import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationBuildingType,
  IOrganizationBuildingTypeState,
} from '@app/interfaces/organization/organization-building-type'
import {
  CLEAR_ORGANIZATION_BUILDING_TYPE,
  CLEAR_ORGANIZATION_BUILDING_TYPES,
  FETCH_ORGANIZATION_BUILDING_TYPES_FAILURE,
  FETCH_ORGANIZATION_BUILDING_TYPES_REQUEST,
  FETCH_ORGANIZATION_BUILDING_TYPES_SUCCESS,
  FETCH_ORGANIZATION_BUILDING_TYPE_CLEANUP,
  FETCH_ORGANIZATION_BUILDING_TYPE_FAILURE,
  FETCH_ORGANIZATION_BUILDING_TYPE_REQUEST,
  FETCH_ORGANIZATION_BUILDING_TYPE_SUCCESS,
  REFRESH_ORGANIZATION_BUILDING_TYPE,
} from '@app/store/types/organization-building-type'

const initialState: IOrganizationBuildingTypeState = {
  organizationBuildingTypes: [],
  organizationBuildingType: {} as IOrganizationBuildingType,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationBuildingTypeReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CLEAR_ORGANIZATION_BUILDING_TYPE:
      return { ...state, organizationBuildingType: {}, state: IDLE }
    case CLEAR_ORGANIZATION_BUILDING_TYPES:
      return { ...state, organizationBuildingTypes: [], state: IDLE }
    case REFRESH_ORGANIZATION_BUILDING_TYPE:
      return { ...state, refresh: !state.refresh }
    case FETCH_ORGANIZATION_BUILDING_TYPE_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_ORGANIZATION_BUILDING_TYPES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_BUILDING_TYPES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationBuildingTypes: action.payload.organizationBuildingTypes,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_BUILDING_TYPES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationBuildingTypes: [],
      }
    case FETCH_ORGANIZATION_BUILDING_TYPE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_BUILDING_TYPE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationBuildingType: action.payload,
      }
    case FETCH_ORGANIZATION_BUILDING_TYPE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationBuildingType: {},
      }
    default:
      return state
  }
}
