import { ApiKeys } from '@app/constants/api-keys'
import { IOwnership } from '@app/interfaces/organization-passport/ownerships'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const ownershipsApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOwnerships: build.query<
      IRtkResponse<IOwnership[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/ownerships',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOwnership[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.ownership,
                id,
              })),
              { type: ApiKeys.ownership, id: 'LIST' },
            ]
          : [{ type: ApiKeys.ownership, id: 'LIST' }],
    }),
    getOwnership: build.query<IOwnership, number>({
      query: (id) => ({
        url: `dictionary/api/v1/ownerships/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.ownership, id }],
    }),
    createOwnership: build.mutation<IRtkResponse<IOwnership>, IOwnership>({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/ownerships',
          data,
        }
      },
      transformResponse: (response: IOwnership, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.ownership,
          id: args.id,
        },
        { type: ApiKeys.ownership, id: 'LIST' },
      ],
    }),
    updateOwnership: build.mutation<IRtkResponse<IOwnership>, IOwnership>({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/ownerships/${data.id}`,
        data,
      }),
      transformResponse: (response: IOwnership, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.ownership, id: args.id },
        { type: ApiKeys.ownership, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetOwnerships =
  ownershipsApi.endpoints.getOwnerships.useQueryState
const useStateGetOwnership = ownershipsApi.endpoints.getOwnership.useQueryState

export const {
  useGetOwnershipsQuery,
  useLazyGetOwnershipsQuery,
  useCreateOwnershipMutation,
  useUpdateOwnershipMutation,
  util,
  usePrefetch,
} = ownershipsApi

export { useStateGetOwnerships, useStateGetOwnership }
