import { LOADING } from '@app/constants/redux-state'
import usePagination from '@app/hooks/usePagination'
import { IOrganizationPupilsState } from '@app/interfaces/pupil/organization-pupils'
import GroupPupilsFilterForm from '@app/pages/organizations/components/organization-groups/components/group-page/components/group-pupils/components/group-pupils-filter-form/group-pupils-filter-form'
import Pagination from '@app/shared/pagination/pagination'
import { getOrganizationPupils } from '@app/store/actions/organization-pupils-actions'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { connect, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

interface props {
  _pupilsState: IOrganizationPupilsState
}

interface params {
  id?: string
  groupId?: string
}

const GroupPupils: React.FC<props> = ({ _pupilsState }) => {
  const { state, count, pupils } = _pupilsState
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const params: params = useParams()
  const { searchParams, requestFunction } = usePagination(
    `organizationId.equals=${params.id}&educationGroupId.equals=${params.groupId}&status.in=APPROVED`,
    {
      page: 1,
      size: 10,
    },
  )

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getOrganizationPupils(
        { params: searchParams, source },
        t('pupils.error'),
      ),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Link
          className={'ant-btn, ant-btn-link'}
          to={`/organization/${params.id}/pupils/pupil/${record.id}`}
          state={{
            pupil: pupils.find((pupil) => pupil.id === Number(record.id)),
          }}>
          {text}
        </Link>
      ),
      sorter: true,
    },
    {
      title: t('pupils.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <Link
          className={'ant-btn, ant-btn-link'}
          to={`/organization/${params.id}/pupils/pupil/${record.id}`}
          state={{
            pupil: pupils.find((pupil) => pupil.id === Number(record.id)),
          }}>
          {text}
        </Link>
      ),
    },
    {
      title: t('educationGroup.educationGroup'),
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: t('pupils.metric'),
      dataIndex: 'metric',
      key: 'metric',
    },
    {
      title: t('pupils.birthDate'),
      dataIndex: 'birthDate',
      key: 'birthDate',
    },
    {
      title: t('pupils.applicationNo'),
      dataIndex: 'applicationNo',
      key: 'applicationNo',
    },
    {
      title: t('general.status'),
      key: 'status',
      dataIndex: 'status',
      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === 'APPROVED' ? 'green' : 'volcano'
            const text =
              tag === 'APPROVED' ? t('general.active') : t('general.screenOut')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('pupils.gender'),
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: t('pupils.governmentStake'),
      dataIndex: 'governmentStake',
      render: (bool: boolean) => (
        <Tag color={bool ? 'green' : 'volcano'}>
          {bool ? t('general.yes') : t('general.no')}
        </Tag>
      ),
      key: 'governmentStake',
    },
  ]

  const data = useMemo(
    () =>
      pupils?.map((pupil) => {
        return {
          key: pupil.id,
          id: pupil.id,
          metric: pupil.pupil?.metric,
          fullName: pupil.pupil?.fullName,
          birthDate: (
            <Moment format={'DD.MM.YYYY'}>{pupil.pupil?.birthDate}</Moment>
          ),
          status: [pupil.status],
          gender:
            pupil.pupil?.gender === 'MALE'
              ? t('pupils.male')
              : t('pupils.female'),
          pupil: pupil,
          groupName: pupil.educationGroup?.name,
          groupId: pupil.educationGroup?.id,
          applicationNo: pupil.application?.applicationNo,
          governmentStake: pupil?.governmentStake,
        }
      }),
    [pupils, t],
  )

  return (
    <>
      <GroupPupilsFilterForm
        load={state !== LOADING}
        request={requestFunction}
      />
      <Card title={t('pupils.title')}>
        <Table
          columns={columns}
          dataSource={data}
          loading={state === LOADING}
          rowClassName="table-row"
          pagination={false}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              searchParams={searchParams}
            />
          )}
        />
      </Card>
    </>
  )
}

function mapStateToProps(state) {
  return {
    _pupilsState: state.organizationPupils,
  }
}

export default connect(mapStateToProps)(GroupPupils)
