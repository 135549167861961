// pupils

export const GET_ALL_PUPILS = 'GET_ALL_PUPILS'
export const GET_PUPIL = 'GET_PUPIL'
export const CREATE_PUPIL = 'CREATE_PUPIL'
export const UPDATE_PUPIL = 'UPDATE_PUPIL'
export const DELETE_PUPIL = 'DELETE_PUPIL'
export const CLEAR_PUPILS = 'CLEAR_PUPILS'
export const CLEAR_PUPIL = 'CLEAR_PUPIL'
export const GET_REGISTRY_PUPIL = 'GET_REGISTRY_PUPIL'

export const FETCH_PUPILS_REQUEST = 'FETCH_PUPILS_REQUEST'
export const FETCH_PUPILS_SUCCESS = 'FETCH_PUPILS_SUCCESS'
export const FETCH_PUPILS_FAILURE = 'FETCH_PUPILS_FAILURE'

export const FETCH_PUPIL_REQUEST = 'FETCH_PUPIL_REQUEST'
export const FETCH_PUPIL_SUCCESS = 'FETCH_PUPIL_SUCCESS'
export const FETCH_PUPIL_FAILURE = 'FETCH_PUPIL_FAILURE'

export const FETCH_REGISTRY_PUPIL_REQUEST = 'FETCH_REGISTRY_PUPIL_REQUEST'
export const FETCH_REGISTRY_PUPIL_SUCCESS = 'FETCH_REGISTRY_PUPIL_SUCCESS'
export const FETCH_REGISTRY_PUPIL_FAILURE = 'FETCH_REGISTRY_PUPIL_FAILURE'

export const FETCH_PUPIL_CLEANUP = 'FETCH_PUPIL_CLEANUP'
