import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEmployeeEducation,
  IEmployeeEducationState,
} from '@app/interfaces/employee/employee-education'
import {
  CLEAR_EMPLOYEE_EDUCATION,
  CREATE_EMPLOYEE_EDUCATION,
  DELETE_EMPLOYEE_EDUCATION,
  FETCH_EMPLOYEE_EDUCATIONS_FAILURE,
  FETCH_EMPLOYEE_EDUCATIONS_REQUEST,
  FETCH_EMPLOYEE_EDUCATIONS_SUCCESS,
  FETCH_EMPLOYEE_EDUCATION_FAILURE,
  FETCH_EMPLOYEE_EDUCATION_REQUEST,
  FETCH_EMPLOYEE_EDUCATION_SUCCESS,
  UPDATE_EMPLOYEE_EDUCATION,
} from '@app/store/types/employee-education'

const initialState: IEmployeeEducationState = {
  employeeEducation: {} as IEmployeeEducation,
  employeeEducations: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const employeeEducationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_EDUCATIONS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_EDUCATIONS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeeEducations: action.payload.employeeEducations,
        count: action.payload.count,
      }
    case FETCH_EMPLOYEE_EDUCATIONS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeeEducations: [],
      }
    case FETCH_EMPLOYEE_EDUCATION_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_EDUCATION_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeeEducation: action.payload,
      }
    case FETCH_EMPLOYEE_EDUCATION_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeeEducation: {},
      }
    case CLEAR_EMPLOYEE_EDUCATION:
      return { ...state, employeeEducations: [] }
    case CREATE_EMPLOYEE_EDUCATION:
      return {
        ...state,
        employeeEducations: [...state.employeeEducations, action.payload],
      }
    case UPDATE_EMPLOYEE_EDUCATION:
      const updatedEmployeeEducations = state.employeeEducations.map(function (
        employeeEducation,
        index,
      ) {
        if (action.payload.id === employeeEducation.id) {
          return action.payload
        }
        return employeeEducation
      })
      return { ...state, employeeEducations: updatedEmployeeEducations }
    case DELETE_EMPLOYEE_EDUCATION:
      const editedEmployeeEducations = state.employeeEducations.filter(
        function (employeeEducation) {
          if (action.payload.id === employeeEducation.id) {
            return false
          }
          return employeeEducation
        },
      )
      return { ...state, employeeEducations: editedEmployeeEducations }
    default:
      return state
  }
}
