import { ApiKeys } from '@app/constants/api-keys'
import { IEmployee } from '@app/interfaces/employee/employee'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyEmployeeApi } from '.'
import { IRtkResponse } from '../../types'

export const employeeApi = emptyEmployeeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getEmployees: build.query<
      IRtkResponse<IEmployee[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'employee/api/v1/employee',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IEmployee[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.employee,
                id,
              })),
              { type: ApiKeys.employee, id: 'LIST' },
            ]
          : [{ type: ApiKeys.employee, id: 'LIST' }],
    }),
    getEmployee: build.query<IEmployee, number>({
      query: (id) => ({
        url: `employee/api/v1/employee/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.employee, id }],
    }),
    createEmployee: build.mutation<
      IRtkResponse<IEmployee>,
      Required<IEmployee>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'employee/api/v1/employee',
          data,
        }
      },
      transformResponse: (response: IEmployee, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.employee, id: 'LIST' }],
    }),
    updateEmployee: build.mutation<
      IRtkResponse<IEmployee>,
      Required<IEmployee>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/employee/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployee, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employee, id: arg.id },
      ],
    }),
    partialUpdateEmployee: build.mutation<
      IRtkResponse<IEmployee>,
      Subset<IEmployee>
    >({
      query: (data) => ({
        method: 'PATCH',
        url: `employee/api/v1/employee/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployee, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employee, id: arg.id },
      ],
    }),
  }),
})

const useStateGetEmployeeQuery = employeeApi.endpoints.getEmployee.useQueryState
const useStateGetEmployeesQuery =
  employeeApi.endpoints.getEmployees.useQueryState

export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  usePartialUpdateEmployeeMutation,
} = employeeApi

export { useStateGetEmployeeQuery, useStateGetEmployeesQuery }
