import { ApiKeys } from '@app/constants/api-keys'
import { ISolidWasteRemovings } from '@app/interfaces/organization-passport/solid-waste-removings'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const solidWasteRemovingsApi =
  emptyOrganizationPassportApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getSolidWasteRemovings: build.query<
        IRtkResponse<ISolidWasteRemovings[]>,
        URLSearchParams | string
      >({
        query: (args = '') => ({
          url: 'dictionary/api/v1/solid-waste-removings',
          method: 'get',
          params: {
            ...resolveTParams(args),
          },
        }),
        transformResponse: (response: ISolidWasteRemovings[], meta) => {
          return { response, meta }
        },
        providesTags: (data) =>
          data
            ? [
                ...data.response.map(({ id }) => ({
                  type: ApiKeys.solidWasteRemovings,
                  id,
                })),
                { type: ApiKeys.solidWasteRemovings, id: 'LIST' },
              ]
            : [{ type: ApiKeys.solidWasteRemovings, id: 'LIST' }],
      }),
      getSolidWasteRemoving: build.query<ISolidWasteRemovings, number>({
        query: (id) => ({
          url: `dictionary/api/v1/solid-waste-removings/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          { type: ApiKeys.solidWasteRemovings, id },
        ],
      }),
      createSolidWasteRemoving: build.mutation<
        IRtkResponse<ISolidWasteRemovings>,
        ISolidWasteRemovings
      >({
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/solid-waste-removings',
            data,
          }
        },
        transformResponse: (response: ISolidWasteRemovings, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          {
            type: ApiKeys.solidWasteRemovings,
            id: args.id,
          },
          { type: ApiKeys.solidWasteRemovings, id: 'LIST' },
        ],
      }),
      updateSolidWasteRemoving: build.mutation<
        IRtkResponse<ISolidWasteRemovings>,
        ISolidWasteRemovings
      >({
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/solid-waste-removings/${data.id}`,
          data,
        }),
        transformResponse: (response: ISolidWasteRemovings, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.solidWasteRemovings, id: args.id },
          { type: ApiKeys.solidWasteRemovings, id: 'LIST' },
        ],
      }),
    }),
  })

const useStateGetSolidWasteRemovings =
  solidWasteRemovingsApi.endpoints.getSolidWasteRemovings.useQueryState
const useStateGetSolidWasteRemoving =
  solidWasteRemovingsApi.endpoints.getSolidWasteRemoving.useQueryState

export const {
  useGetSolidWasteRemovingsQuery,
  useLazyGetSolidWasteRemovingsQuery,
  useCreateSolidWasteRemovingMutation,
  useUpdateSolidWasteRemovingMutation,
  util,
  usePrefetch,
} = solidWasteRemovingsApi

export { useStateGetSolidWasteRemovings, useStateGetSolidWasteRemoving }
