import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  edit?: boolean
  onSubmit: () => any
  onCancel: () => any
  confirm: boolean
  remove?: boolean
}

const ModalFooter: React.FC<props> = ({
  edit,
  onSubmit,
  onCancel,
  confirm,
  remove,
}) => {
  const [t, i18n] = useTranslation()
  return (
    <React.Fragment>
      {remove ? (
        <Button disabled={confirm} onClick={onSubmit} type={'primary'}>
          {t('general.delete')}
        </Button>
      ) : (
        <Button disabled={confirm} type={'primary'} onClick={onSubmit}>
          {edit ? t('general.edit') : t('general.add')}
        </Button>
      )}
      <Button
        disabled={confirm}
        type={'default'}
        onClick={onCancel}
        style={{ marginLeft: '10px' }}
      >
        {t('general.cancel')}
      </Button>
    </React.Fragment>
  )
}

export default ModalFooter
