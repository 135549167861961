import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { getBenefitTypes } from '@app/store/actions/benefit-type-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BenefitTypeForm from './components/benefit-type-form/benefit-type-form'

const ReferenceBookBenefitType: React.FC = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const { selectors } = useCreateSelector()
  const { state, benefitTypes } = useSelector(
    selectors.benefitType.benefitTypeState,
  )
  setDocumentTitle(t('pupils.benefitType'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(benefitTypes)) dispatch(getBenefitTypes({ source }))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('pupils.benefitType'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <BenefitTypeForm edit={true} benefitType={record.benefitType} />
        ) : null,
    },
  ]

  const data = !isEmpty(benefitTypes)
    ? benefitTypes.map((benefitType) => {
        return {
          key: benefitType.id,
          id: benefitType.id,
          name: localizeData(benefitType?.data)?.name,
          status: [benefitType.status],
          benefitType: benefitType,
        }
      })
    : []

  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <BenefitTypeForm />
          ) : null
        }
        title={t('pupils.benefitType')}
      >
        <Table
          pagination={false}
          loading={state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

export default ReferenceBookBenefitType
