import bbitApi from '@api/make-request'
import {
  IOrganizationPositionRepository,
  IPathParamsArgs,
  numberArgs,
} from '@api/repositories/i-repository'
import {
  IOrganizationPosition,
  IOrganizationPositionCreate,
} from '@app/interfaces/employee/organization-position'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class OrganizationPositionRepository
  implements IOrganizationPositionRepository<IOrganizationPosition>
{
  closeOrganizationPosition(
    data,
  ): Promise<AxiosResponse<IOrganizationPosition>> {
    return bbitApi.put(
      `/employee/api/v1/organization-position/close-organization-position/${data.id}`,
      data,
    )
  }

  create(
    data: IOrganizationPositionCreate,
  ): Promise<AxiosResponse<IOrganizationPosition>> {
    return bbitApi.post('/employee/api/v1/organization-position', data)
  }

  getAll(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IOrganizationPosition[]>> {
    return bbitApi.get(`/employee/api/v1/organization-position`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IOrganizationPosition>> {
    return bbitApi.get(`/employee/api/v1/organization-position/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }
}

export default new OrganizationPositionRepository()
