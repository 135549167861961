import { ApiKeys } from '@app/constants/api-keys'
import { IWorkforce } from '@app/interfaces/organization-passport/workforce'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from '../../types'

export const workforceApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getWorkforces: build.query<
      IRtkResponse<IWorkforce[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/workforce',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IWorkforce[], meta) => {
        return {
          response: response.map((type, index) => ({
            ...type,
            key: index,
            type: type.employeePosition.type,
          })),
          meta,
        }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ employeePosition: { id } }) => ({
                type: ApiKeys.workforce,
                id,
              })),
              { type: ApiKeys.workforce, id: 'LIST' },
            ]
          : [{ type: ApiKeys.workforce, id: 'LIST' }],
    }),
    getWorkforce: build.query<IWorkforce, number>({
      query: (id) => ({
        url: `dictionary/api/v1/workforce/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.workforce, id },
        { type: ApiKeys.workforce, id: 'LIST' },
      ],
    }),
    createWorkforce: build.mutation<
      IRtkResponse<IWorkforce>,
      Omit<IWorkforce, 'id'>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/workforce',
          data,
        }
      },
      transformResponse: (response: IWorkforce, meta) => {
        return { response, meta }
      },
      invalidatesTags: () => [{ type: ApiKeys.workforce, id: 'LIST' }],
    }),
    updateWorkforce: build.mutation<IRtkResponse<IWorkforce>, IWorkforce>({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/workforce/${data.employeePosition.id}`,
        data,
      }),
      transformResponse: (response: IWorkforce, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.workforce, id: args.employeePosition.id },
        { type: ApiKeys.workforce, id: 'LIST' },
      ],
    }),
    partialUpdateWorkforce: build.mutation<
      IRtkResponse<IWorkforce>,
      Partial<IWorkforce>
    >({
      query: (data) => ({
        method: 'PATCH',
        url: `dictionary/api/v1/workforce/${data?.employeePosition?.id}`,
        type: 'patch',
        data,
      }),
      transformResponse: (response: IWorkforce, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.workforce, id: args?.employeePosition?.id },
        { type: ApiKeys.workforce, id: 'LIST' },
      ],
    }),
    downloadWorkforceExcel: build.query<void, URLSearchParams | string>({
      query: (args = '') => ({
        url: 'emis-reports/api/v1/workforce/excel',
        method: 'get',
        type: 'octet',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: any) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel;charset=UTF-8',
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Штатное расписание')
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
      },
    }),
  }),
})

const useStateGetWorkforces = workforceApi.endpoints.getWorkforces.useQueryState
const useStateGetWorkforce = workforceApi.endpoints.getWorkforce.useQueryState

export const {
  useGetWorkforcesQuery,
  useGetWorkforceQuery,
  useCreateWorkforceMutation,
  useUpdateWorkforceMutation,
  useDownloadWorkforceExcelQuery,
  usePartialUpdateWorkforceMutation,
  util,
  usePrefetch,
} = workforceApi

export { useStateGetWorkforces, useStateGetWorkforce }
