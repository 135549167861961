import EmployeeRepository from '@api/repositories/employee-repository'
import {
  IEmployee,
  IOrganizationEmployee,
} from '@app/interfaces/employee/employee'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  refreshEmployee,
  updateEmployee,
} from '@app/store/actions/employee-actions'
import { Button, Form, Input, Modal, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  employee: IOrganizationEmployee
}

const EmployeeDecreeForm: React.FC<props> = ({ employee }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const isEmployeeStatusDecree = employee.status.type === 'DECREE'

  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      employeePositionId: employee.id ? employee.id : undefined,
    }

    setConfirmLoading(true)
    const employeeDecreeApi = isEmployeeStatusDecree
      ? EmployeeRepository.fromDecree
      : EmployeeRepository.toDecree

    employeeDecreeApi(newValues)
      .then((response: AxiosResponse<IEmployee>) => {
        if (response.status === 201) {
          dispatch(updateEmployee(response.data))
          dispatch(refreshEmployee())
          openNotificationWithIcon('success', t('success.updated'))
          setConfirmLoading(false)
          hideModal()
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('employee.decreeError'))
        setConfirmLoading(false)
      })
  }

  function getActualDate() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return day + '.' + month + '.' + year
  }

  const date = getActualDate()
  return (
    <>
      <React.Fragment>
        <Button onClick={showModal} type={'link'}>
          {isEmployeeStatusDecree
            ? t('employee.outOfDecree')
            : t('employee.decree')}
        </Button>

        <Modal
          open={visible}
          title={t('employee.decree')}
          onCancel={hideModal}
          footer={[
            <React.Fragment key={1}>
              <ModalFooter
                edit={true}
                confirm={confirmLoading}
                onCancel={hideModal}
                onSubmit={form.submit}
              />
            </React.Fragment>,
          ]}
        >
          <Spin spinning={confirmLoading}>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{ employeeId: employee.employee?.id }}
              layout={'vertical'}
            >
              <Form.Item hidden={true} name={'employeeId'}>
                <Input />
              </Form.Item>
              <Form.Item
                label={t('pupils.orderNo')}
                name={'orderNo'}
                rules={[
                  {
                    transform: (value) =>
                      typeof value === 'string' ? value.trim() : undefined,
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                ]}
              >
                <Input disabled={confirmLoading} />
              </Form.Item>
              <Form.Item
                initialValue={date}
                name={'orderDate'}
                label={
                  isEmployeeStatusDecree
                    ? t('employee.decreeOutDate')
                    : t('employee.decreeDate')
                }
              >
                <Input disabled={true} />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    </>
  )
}

export default EmployeeDecreeForm
