import { AUTHORITIES } from '@app/constants/authorities'
import { IReason } from '@app/interfaces/reasons'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  useCreateReasonMutation,
  useUpdateReasonMutation,
} from '@app/store/rtk/api/reasons-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Button, Form, Input, InputNumber, Modal, Spin, Switch } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  reason?: IReason
  edit?: boolean
}

const ReasonsForm: React.FC<props> = ({ reason, edit }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const [create, { isLoading: createLoading, isSuccess: isCreateSuccess }] =
    useCreateReasonMutation()
  const [update, { isLoading: updateLoading, isSuccess: isUpdateSuccess }] =
    useUpdateReasonMutation()
  const confirmLoading = useMemo(
    () => createLoading || updateLoading,
    [createLoading, update],
  )
  const success = useMemo(
    () => isCreateSuccess || isUpdateSuccess,
    [isCreateSuccess, isUpdateSuccess],
  )

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues: IReason = {
      id: values.id,
      sorting: values.sorting,
      status: values.status,
      data: languages,
      shortName: values.code,
    }
    const submitApi = reason ? update : create
    submitApi(newValues)
  }

  useEffect(() => {
    if (success) {
      openNotificationWithIcon('success', t('success.updated'))
      form.resetFields()
      hideModal()
    }
  }, [success])

  return (
    <React.Fragment>
      <Button
        disabled={!rolesChecker([AUTHORITIES.SUSER])}
        onClick={showModal}
        type={'primary'}
      >
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('reason.edit') : t('reason.add')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: reason?.id,
              sorting: reason?.sorting || 0,
              status: edit ? reason?.status : true,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={reason?.status === true}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -2147483648,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              label={t('general.code')}
              name={'code'}
            >
              <Input disabled={confirmLoading} style={{ width: '100%' }} />
            </Form.Item>
            <FormList confirmLoading={confirmLoading} object={reason} />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default ReasonsForm
