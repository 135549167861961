import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import Pagination from '@app/shared/pagination/pagination'
import {
  clearWarehouseData,
  clearWarehouseInvoice,
  getWarehouseData,
} from '@app/store/actions/organization-warehouse-actions'
import { Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

interface props {
  organizationId?: string
}

interface IPathParams {
  id?: string
}

const OrganizationWarehouseData: React.FC<props> = ({ organizationId }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const { selectors } = useCreateSelector()
  const { warehouseData, state, count, setPage } = useSelector(
    selectors.warehouse.warehouseState,
  )
  const params: IPathParams = useParams()
  const { searchParams, requestFunction, handleTableChange, pageAndSize } =
    usePagination(`page=0&size=10`, {
      page: 1,
      size: 10,
    })

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (params.id || organizationId)
      dispatch(
        getWarehouseData(
          params.id || organizationId || '',
          { params: searchParams, source },
          t('organizationWarehouse.errors.loadError'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
      dispatch(clearWarehouseData())
      dispatch(clearWarehouseInvoice())
    }
  }, [organizationId])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => (
        <Text>{(pageAndSize.page - 1) * pageAndSize.size + index + 1}</Text>
      ),
    },
    {
      title: t('organizationMenu.products.name'),
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: t('organizationMenu.meals.measurement'),
      key: 'measurements',
      render: () => <div>{t('general.gram')}</div>,
    },
    {
      title: t('organizationWarehouse.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ]

  const data = !isEmpty(warehouseData)
    ? warehouseData.map((data) => {
        return {
          key: data.id,
          id: data.id,
          organizationId: data.organizationId,
          productName: localizeData(data.product.data)?.name,
          quantity: data.quantity,
        }
      })
    : []

  return (
    <div className="warehouse-data">
      {(params.id || organizationId) && (
        <Table
          columns={columns}
          dataSource={data}
          rowClassName="table-row"
          loading={state === LOADING}
          pagination={false}
          onChange={handleTableChange}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              setPage={setPage}
              searchParams={searchParams}
            />
          )}
        />
      )}
    </div>
  )
}

export default OrganizationWarehouseData
