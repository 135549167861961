import { AUTHORITIES } from '@app/constants/authorities'

export const referenceViewPermissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW,
]
export const referenceEditPermissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
]
