import DistrictRepository from '@api/repositories/district-repository'
import useLocalization from '@app/hooks/useLocalization'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  createDistrict,
  updateDistrict,
} from '@app/store/actions/districts-actions'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Switch,
} from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import './districts-form.less'

interface props {
  district?: IDistrict
  edit?: boolean
  reg?: IRegion[]
}

const DistrictsForm: React.FC<props> = ({ edit, district, reg }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const newRegions = localize(reg)

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
  }
  const dispatch = useDispatch()
  const onFinish = async (values: any) => {
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const region = newRegions?.find(
      (region) => region.data?.name === values.regionId,
    )?.id
    const newValues: IDistrict = {
      id: values.id,
      soato: values.soato,
      sorting: values.sorting,
      regionId: Number(values.regionId) || region,
      status: edit ? values.status : true,
      data: languages,
    }

    setConfirmLoading(true)
    const districtSubmitApi = district
      ? DistrictRepository.update
      : DistrictRepository.create

    await districtSubmitApi(newValues)
      .then((response: AxiosResponse<IDistrict>) => {
        if (district && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateDistrict(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!district && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createDistrict(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('district.editDistrict') : t('district.addDistrict')}
        onCancel={hideModal}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: district?.id,
              soato: district?.soato || 0,
              sorting: district?.sorting || 0,
              status: edit ? district?.status : true,
              regionId: newRegions?.find(
                (region) => region.id === district?.regionId,
              )?.data?.name,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item label={t('region.title')} name={'regionId'}>
              <Select disabled={confirmLoading}>
                {!reg ? (
                  <Select.Option value={'empty'}>Пусто</Select.Option>
                ) : (
                  newRegions?.map(function (region, index) {
                    return (
                      <Select.Option key={index} value={region.id!.toString()}>
                        {region.data?.name}
                      </Select.Option>
                    )
                  })
                )}
              </Select>
            </Form.Item>
            <Form.Item label={'soato'} name={'soato'}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -32768,
                  max: 32768,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch disabled={confirmLoading} />
            </Form.Item>

            <FormList confirmLoading={confirmLoading} object={district} />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    reg: state.regions.regions,
  }
}

export default connect(mapStateToProps)(DistrictsForm)
