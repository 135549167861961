export const GET_ORGANIZATION_STATUS = 'GET_ORGANIZATION_STATUS'
export const GET_ORGANIZATION_STATUSES = 'GET_ORGANIZATION_STATUSES'
export const CREATE_ORGANIZATION_STATUS = 'CREATE_ORGANIZATION_STATUS'
export const UPDATE_ORGANIZATION_STATUS = 'UPDATE_ORGANIZATION_STATUS'
export const DELETE_ORGANIZATION_STATUS = 'DELETE_ORGANIZATION_STATUS'
export const CLEAR_ORGANIZATION_STATUS = 'CLEAR_ORGANIZATION_STATUS'

export const FETCH_ORGANIZATION_STATUSES_REQUEST =
  'FETCH_ORGANIZATION_STATUSES_REQUEST'
export const FETCH_ORGANIZATION_STATUSES_SUCCESS =
  'FETCH_ORGANIZATION_STATUSES_SUCCESS'
export const FETCH_ORGANIZATION_STATUSES_FAILURE =
  'FETCH_ORGANIZATION_STATUSES_FAILURE'

export const FETCH_ORGANIZATION_STATUS_REQUEST =
  'FETCH_ORGANIZATION_STATUS_REQUEST'
export const FETCH_ORGANIZATION_STATUS_SUCCESS =
  'FETCH_ORGANIZATION_STATUS_SUCCESS'
export const FETCH_ORGANIZATION_STATUS_FAILURE =
  'FETCH_ORGANIZATION_STATUS_FAILURE'
