import { LOADING } from '@app/constants/redux-state'
import usePagination from '@app/hooks/usePagination'
import { ICameraState } from '@app/interfaces/cameras'
import { IOrganization } from '@app/interfaces/organization/organization'
import CameraForm from '@app/pages/organizations/components/organization-cameras/components/organization-camera-form/organization-camera-form'
import Pagination from '@app/shared/pagination/pagination'
import { getCameras } from '@app/store/actions/cameras-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

interface props {
  _organization: IOrganization
  _camerasState: ICameraState
}

interface params {
  id?: string
}

const OrganizationCameras: React.FC<props> = ({
  _camerasState,
  _organization,
}) => {
  const { state, count, cameras } = _camerasState
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const params: params = useParams()
  const { searchParams, requestFunction } = usePagination(
    `organizationId=${params.id}`,
    {
      page: 1,
      size: 10,
    },
  )
  setDocumentTitle(t('cameras.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getCameras({ params: searchParams, source: source }, t('cameras.error')),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('cameras.protocol'),
      dataIndex: 'protocol',
      key: 'protocol',
    },
    {
      title: t('cameras.IP'),
      dataIndex: 'remoteAddress',
      key: 'remoteAddress',
    },
    {
      title: t('cameras.port'),
      dataIndex: 'port',
      key: 'port',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('cameras.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('cameras.login'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('cameras.password'),
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <CameraForm
          _organization={_organization}
          camera={record.camera}
          edit={true}
        />
      ),
    },
  ]

  const data =
    cameras && cameras.length
      ? cameras.map((camera) => {
          return {
            id: camera.id,
            key: camera.id,
            remoteAddress: camera.remoteAddress,
            port: camera.port,
            status:
              camera.status === 'ACTIVE'
                ? t('general.active')
                : t('general.inActive'),
            type:
              camera.type === 'ENTRANCE'
                ? t('cameras.input')
                : t('cameras.output'),
            username: camera.username,
            password: camera.password,
            camera: camera,
            protocol: camera.protocol,
          }
        })
      : []

  return (
    <>
      <Card
        extra={<CameraForm _organization={_organization} />}
        title={t('cameras.title')}
      >
        <Table
          pagination={false}
          dataSource={data}
          loading={state === LOADING}
          columns={columns}
          rowClassName="table-row"
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              searchParams={searchParams}
            />
          )}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _camerasState: state.organizationCameras,
    _organization: state.organizations.organization,
  }
}

export default connect(mapStateToProps)(OrganizationCameras)
