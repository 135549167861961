import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import institutionsTypeRepository from '@app/api/repositories/institutions-type-repository'
import {
  CLEAR_CURRENT_INSTITUTION_TYPE_ID,
  CLEAR_INSTITUTIONS_TYPE,
  CREATE_INSTITUTIONS_TYPE,
  DELETE_INSTITUTIONS_TYPE,
  FETCH_INSTITUTION_TYPES_FAILURE,
  FETCH_INSTITUTION_TYPES_REQUEST,
  FETCH_INSTITUTION_TYPES_SUCCESS,
  FETCH_INSTITUTION_TYPE_FAILURE,
  FETCH_INSTITUTION_TYPE_REQUEST,
  FETCH_INSTITUTION_TYPE_SUCCESS,
  SET_CURRENT_INSTITUTION_TYPE_ID,
  UPDATE_INSTITUTIONS_TYPE,
} from '@app/store/types/institutions-type'
import axios from 'axios'

export const getInstitutionType =
  (args: numberArgs, message) => async (dispatch) => {
    dispatch(fetchInstitutionTypeRequest())
    await institutionsTypeRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchInstitutionTypeSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchInstitutionTypeFailure(error?.message, error?.code))
        }
      })
  }

export const getInstitutionTypes =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchInstitutionTypesRequest())
    await institutionsTypeRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchInstitutionTypesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchInstitutionTypesFailure(error?.message, error?.code))
        }
      })
  }

const fetchInstitutionTypesRequest = () => {
  return {
    type: FETCH_INSTITUTION_TYPES_REQUEST,
  }
}

const fetchInstitutionTypesSuccess = (institutionsTypes, count) => {
  return {
    type: FETCH_INSTITUTION_TYPES_SUCCESS,
    payload: { institutionsTypes, count },
  }
}

const fetchInstitutionTypesFailure = (message, code) => {
  return {
    type: FETCH_INSTITUTION_TYPES_FAILURE,
    payload: { message, code },
  }
}

const fetchInstitutionTypeRequest = () => {
  return {
    type: FETCH_INSTITUTION_TYPE_REQUEST,
  }
}

const fetchInstitutionTypeSuccess = (institutionsType) => {
  return {
    type: FETCH_INSTITUTION_TYPE_SUCCESS,
    payload: institutionsType,
  }
}

const fetchInstitutionTypeFailure = (message, code) => {
  return {
    type: FETCH_INSTITUTION_TYPE_FAILURE,
    payload: { message, code },
  }
}

export function clearInstitutionTypes() {
  return {
    type: CLEAR_INSTITUTIONS_TYPE,
    payload: null,
  }
}

export function setCurrentInstitutionTypeId(id: number) {
  return {
    type: SET_CURRENT_INSTITUTION_TYPE_ID,
    payload: id,
  }
}

export function clearCurrentInstitutionTypeId() {
  return {
    type: CLEAR_CURRENT_INSTITUTION_TYPE_ID,
  }
}

export function updateInstitutionType(institutionType) {
  return {
    type: UPDATE_INSTITUTIONS_TYPE,
    payload: institutionType,
  }
}

export function createInstitutionType(institutionType) {
  return {
    type: CREATE_INSTITUTIONS_TYPE,
    payload: institutionType,
  }
}

export function deleteInstitutionType(institutionType) {
  return {
    type: DELETE_INSTITUTIONS_TYPE,
    payload: institutionType,
  }
}
