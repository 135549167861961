import { numberArgs } from '@app/api/repositories/i-repository'
import institutionPassportRepository from '@app/api/repositories/institution-passport-repository'
import { IOrganizationPassportActions } from '@app/interfaces/organization-passport/organization-passport'
import {
  CLEAR_INSTITUTION_PASSPORT,
  CREATE_INSTITUTION_PASSPORT,
  DELETE_INSTITUTION_PASSPORT,
  FETCH_INSTITUTION_PASSPORT_FAILURE,
  FETCH_INSTITUTION_PASSPORT_REQUEST,
  FETCH_INSTITUTION_PASSPORT_SUCCESS,
  REFRESH_INSTITUTION_PASSPORT,
  UPDATE_INSTITUTION_PASSPORT,
} from '@app/store/types/institution-passport'
import axios from 'axios'

export const getInstitutionPassport =
  (args: numberArgs, message) => async (dispatch) => {
    dispatch(fetchInstitutionPassportRequest())
    await institutionPassportRepository
      .getOneByOrganizationId(args)
      .then((response) => {
        dispatch(fetchInstitutionPassportSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchInstitutionPassportFailure(error?.message, error?.code))
        }
      })
  }

const fetchInstitutionPassportRequest = (): IOrganizationPassportActions => {
  return {
    type: FETCH_INSTITUTION_PASSPORT_REQUEST,
  }
}

const fetchInstitutionPassportSuccess = (
  institutionPassport,
): IOrganizationPassportActions => {
  return {
    type: FETCH_INSTITUTION_PASSPORT_SUCCESS,
    payload: institutionPassport,
  }
}

const fetchInstitutionPassportFailure = (
  message,
  code,
): IOrganizationPassportActions => {
  return {
    type: FETCH_INSTITUTION_PASSPORT_FAILURE,
    payload: { message, code },
  }
}

export function refreshInstitutionPassport() {
  return {
    type: REFRESH_INSTITUTION_PASSPORT,
    payload: null,
  }
}

export function updateInstitutionPassport(
  institutionPassport,
): IOrganizationPassportActions {
  return {
    type: UPDATE_INSTITUTION_PASSPORT,
    payload: institutionPassport,
  }
}

export function createInstitutionPassport(
  institutionPassport,
): IOrganizationPassportActions {
  return {
    type: CREATE_INSTITUTION_PASSPORT,
    payload: institutionPassport,
  }
}

export function deleteInstitutionPassport(
  institutionPassport,
): IOrganizationPassportActions {
  return {
    type: DELETE_INSTITUTION_PASSPORT,
    payload: institutionPassport,
  }
}

export function clearInstitutionPassport(): IOrganizationPassportActions {
  return {
    type: CLEAR_INSTITUTION_PASSPORT,
  }
}
