import React, { useEffect } from 'react'
import { Button } from 'antd'
import Title from 'antd/es/typography/Title'
import { useTranslation } from 'react-i18next'
import {
  clearAllFields,
  getCitizenship,
} from '@app/store/actions/pupil-manual-add-actions'
import { useDispatch } from 'react-redux'
import { AUTHORITIES } from '@app/constants/authorities'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'

interface props {
  next: () => void
  back: () => void
}

const CitizenChooseForm: React.FC<props> = ({ next, back }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const permissions = [AUTHORITIES.ADMIN]

  useEffect(() => {
    dispatch(clearAllFields())
  }, [dispatch])

  const setUzbekCitizenship = () => {
    dispatch(getCitizenship('native'))
    next()
  }
  const setForeignCitizenship = () => {
    dispatch(getCitizenship('foreign'))
    next()
  }

  return (
    <div className="citizenship">
      <Title level={4}>{t('pupils.form.chooseCitizenship')}</Title>
      <div className="citizenship__btn">
        <Button onClick={setUzbekCitizenship} type={'primary'}>
          {t('pupils.form.uzbekCitizenship')}
        </Button>
        {rolesChecker(permissions) && (
          <Button onClick={setForeignCitizenship} type={'primary'}>
            {t('pupils.form.foreigner')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CitizenChooseForm
