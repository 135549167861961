import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons'
import { ButtonTitleEdit, ButtonTitleView } from '@app/ui/atoms'
import { useQuery } from 'react-query'
import bbitApi from '@app/api/make-request'
import ChildDevelopmentModalView from '@app/pages/child-stats/components/child-development-modal-view'
import { useParams } from 'react-router'
import { PUPIL_FILTER_STATUSES } from '@app/hooks/usePupilStatuses'
import { SelectEducationGroups } from '@app/atoms'
import { IPupil, IPupilEducation } from '@app/interfaces/pupil/pupils'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { AUTHORITIES } from '@app/constants/authorities'
import { getPupilReadnessPrintableUrl } from '@app/pages/child-stats/components/child-development-print'
const { Paragraph } = Typography

const ChildDevelopment = () => {
  const [t] = useTranslation()
  let { id: organizationId } = useParams()
  const [form] = Form.useForm()
  const [currPupil, setCurrPupil] = useState<IPupil>()
  const {
    data: pupilEducation,
    isLoading,
    refetch,
  } = useQuery(
    ['/education/api/v1/pupil-education/graduates', organizationId],
    async () => {
      const { data } = await bbitApi.get<IPupilEducation[]>(
        '/education/api/v1/pupil-education/graduates',
        {
          params: {
            'status.equals': PUPIL_FILTER_STATUSES.APPROVED,
            'organizationId.equals': organizationId,
            ...Object.entries(form.getFieldsValue()).reduce(
              (prev, [key, value]) => {
                if (!!value) {
                  prev[`${key}.contains`] = value
                }
                return prev
              },
              {},
            ),
          },
        },
      )
      return data
    },
    {
      enabled: !!organizationId,
    },
  )

  const closeModal = useCallback(() => setCurrPupil(undefined), [])
  const onEditClick = useCallback((record) => setCurrPupil(record), [])

  const columns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id',
        render: (_, __, index) => <>{index + 1}</>,
        key: 'id',
      },
      {
        title: t('general.fullName'),
        dataIndex: 'pupil',
        key: 'pupil.fullName',
        render: (pupil) => <Paragraph>{pupil.fullName}</Paragraph>,
      },
      {
        title: t('pupils.birthDate'),
        dataIndex: 'pupil',
        key: 'pupil.birthDate',
        render: (pupil: IPupil) => <>{pupil.birthDate}</>,
      },
      {
        title: t('educationGroup.educationGroup'),
        dataIndex: 'educationGroup',
        key: 'pupil.groupName',
        render: (group) => <>{group.name}</>,
      },
      ...(rolesChecker([
        AUTHORITIES.ADMIN,
        AUTHORITIES.ROLE_PUPIL_READINESS_CREATE,
      ])
        ? [
            {
              title: t('general.edit'),
              key: 'action',
              dataIndex: 'pupil',
              render: (pupil: IPupil) => (
                <Space size="middle">
                  <EditOutlined />
                  <ButtonTitleEdit onClick={() => onEditClick(pupil)} />
                </Space>
              ),
            },
          ]
        : []),
      {
        title: t('general.view'),
        key: 'view',
        dataIndex: 'pupil',
        render: (pupil: IPupil, t) => (
          <Space size="middle">
            <EyeOutlined />
            <ButtonTitleView
              onClick={() => {
                window.open(
                  getPupilReadnessPrintableUrl({
                    pupilId: pupil.id,
                    pupilReadinessMapId: pupil.pupilReadinessMapId,
                  }),
                )
              }}
            />
          </Space>
        ),
      },
    ],
    [],
  )
  const dataSource = useMemo(
    () =>
      pupilEducation?.map((i) => ({
        ...i,
        key: i.id,
      })),
    [pupilEducation],
  )
  return (
    <>
      <div className="header-form">
        <Spin spinning={false}>
          <Form
            form={form}
            onFinish={() => refetch()}
            onReset={() => form.resetFields()}
            layout="vertical">
            <Row gutter={8}>
              <Col span={5}>
                <Form.Item name={'groupId'} label={t('educationGroup.title')}>
                  <SelectEducationGroups />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="fullName" label={t('general.fullName')}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Button type="primary" htmlType="submit">
                <FilterOutlined />
                {t('general.filter')}
              </Button>
              <Button
                htmlType={'reset'}
                style={{ margin: '0 8px' }}
                onClick={() => refetch()}>
                {t('general.reset')}
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
      <Card>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
        />
      </Card>
      {currPupil && (
        <ChildDevelopmentModalView pupil={currPupil} closeModal={closeModal} />
      )}
    </>
  )
}

export default ChildDevelopment
