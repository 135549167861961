import NationalityRepository from '@api/repositories/nationality-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { INationality, INationalityActions } from '@app/interfaces/nationality'
import NationalityForm from '@app/pages/reference-book-page-details/nationality/components/nationality-form/nationality-form'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  clearNationalities,
  getNationalities,
} from '@app/store/actions/nationality-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _nationalities: INationality[]
  getNationalities: (data: INationality[]) => INationalityActions
}

const ReferenceBookNationality: React.FC<props> = ({
  _nationalities,
  getNationalities,
}) => {
  const [loaded, setLoaded] = useState<boolean>()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('nationality.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    setLoaded(false)
    dispatch(clearNationalities())
    NationalityRepository.getAll({ source })
      .then(function (response) {
        getNationalities(response.data)
        setLoaded(true)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          openNotificationWithIcon('error', t('nationality.error'))
          setLoaded(true)
        }
      })
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('nationality.title'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
      sorter: (a, b) => Number(a.status[0]) - Number(b.status[0]),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <NationalityForm edit={true} nationality={record.nationality} />
        ) : null,
    },
  ]

  const data =
    _nationalities && _nationalities.length
      ? _nationalities.map((nationality) => {
          return {
            key: nationality.id,
            id: nationality.id,
            name: localizeData(nationality?.data)?.name,
            status: [nationality.status],
            nationality: nationality,
          }
        })
      : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <NationalityForm />
          ) : null
        }
        title={t('nationality.title')}
      >
        <Table
          pagination={false}
          loading={!loaded}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _nationalities: state.nationality.nationalities,
  }
}

const mapDispatchToProps = {
  getNationalities,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferenceBookNationality)
