import RolesRepository from '@app/api/repositories/roles-repository'
import { IPathParams } from '@app/constants/path-params'
import { positionRoles, pseudoRoles, roles } from '@app/constants/roles'
import { IRole, IUserGroupRoles } from '@app/interfaces/roles'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import RolesItem from '@app/pages/roles-page/components/roles-form/components/roles-item/roles-item'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  clearAllRole,
  createGroupRole,
  getGroupRole,
  getRoles,
  updateGroupRole,
} from '@app/store/actions/roles-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Form, Input, Switch } from 'antd'
import axios, { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

interface props {
  _role?: IUserGroupRoles
  _roles?: IRole[]
  _state: string
}

const RolesForm: React.FC<props> = ({ _role, _roles }) => {
  const navigate = useNavigate()
  const params: IPathParams = useParams()
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  setDocumentTitle(
    params.roleId ? t('userRoles.editRole') : t('userRoles.addRole'),
  )
  const activeRoles = params.roleId ? _role : undefined
  useEffect(() => {
    const source = axios.CancelToken.source()
    if (params.roleId)
      dispatch(
        getGroupRole(
          { data: Number(params.roleId), source },
          t('userRoles.error'),
        ),
      )
    if (isEmpty(_roles))
      dispatch(getRoles({ source }, t('userRoles.rolesError')))
    return () => {
      dispatch(clearAllRole())
      source.cancel('Component got unmounted')
    }
  }, [])

  const mergeArray = (...arr): string[] => {
    const result: string[] = []
    arr.forEach((role) => {
      if (typeof role === 'undefined') return
      if (typeof role === 'string') {
        result.push(role)
        return
      }
      role.forEach((role) => typeof role === 'string' && result.push(role))
    })
    return result
  }

  const onFinish = async (values: any) => {
    const superRoles = mergeArray(values.superRoles)
    const pseudoRoles = mergeArray(values.pseudoRoles)
    const manageRoles = mergeArray(
      values.manageReferences,
      values.manageOrganizations,
      values.manageEmployees,
      values.managePupils,
      values.manageGroups,
      values.manageReports,
      values.manageAttendance,
      values.manageReasons,
      values.manageMonitoring,
      values.manageMenu,
      values.manageSubsidies,
      values.manageGrowth,
      values.manageFurniture,
      values.managePassportization,
      values.manageContacts,
      values.manageStaff,
      values.manageAdmin,
      values.manageDashboard,
    )
    const all = mergeArray(superRoles, pseudoRoles, manageRoles)
    const roles = all.includes('ROLE_ADMIN') ? superRoles : all
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues = {
      id: values.id,
      status: values.status,
      sorting: values.sorting,
      data: languages,
      roles: _roles!.filter((role) => roles.includes(role.name)),
    }
    setConfirmLoading(true)
    const roleSubmitApi = params.roleId
      ? RolesRepository.update
      : RolesRepository.create

    await roleSubmitApi(newValues)
      .then((response: AxiosResponse<IUserGroupRoles>) => {
        if (params.roleId && response.status === 200) {
          dispatch(updateGroupRole(response.data))
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.updated'))
          navigate('/manage-users/roles')
        }
        if (!params.roleId && response.status === 201) {
          dispatch(createGroupRole(response.data))
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.created'))
          navigate('/manage-users/roles')
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('errors.error'))
        setConfirmLoading(false)
      })
  }

  const setInitialValuesByIndex = (index: number) =>
    params.roleId
      ? roles[index].roles
          .filter((singleRole) =>
            _role?.roles.map((role) => role.name).includes(singleRole.name),
          )
          .map((role) => role.name)
      : undefined

  return (
    <React.Fragment>
      <Card
        extra={
          <>
            <Button
              disabled={confirmLoading}
              type={'primary'}
              onClick={form.submit}>
              {params.roleId ? t('general.save') : t('general.add')}
            </Button>
            <Link
              to={`/manage-users/roles`}
              className={'ant-btn ant-btn-default'}
              style={{ marginLeft: '10px' }}>
              {t('general.cancel')}
            </Link>
          </>
        }
        title={params.roleId ? t('general.edit') : t('general.add')}>
        {!isEmpty(_roles) && !isEmpty(_role) && (
          <Form
            className={'roles-form'}
            style={{ padding: '24px' }}
            form={form}
            onFinish={onFinish}
            initialValues={{
              manageReferences: setInitialValuesByIndex(0),
              manageOrganizations: setInitialValuesByIndex(1),
              manageEmployees: setInitialValuesByIndex(2),
              managePupils: setInitialValuesByIndex(3),
              manageGroups: setInitialValuesByIndex(4),
              manageReports: setInitialValuesByIndex(5),
              manageAttendance: setInitialValuesByIndex(6),
              manageReasons: setInitialValuesByIndex(7),
              manageMonitoring: setInitialValuesByIndex(8),
              manageMenu: setInitialValuesByIndex(9),
              manageSubsidies: setInitialValuesByIndex(10),
              manageGrowth: setInitialValuesByIndex(11),
              manageFurniture: setInitialValuesByIndex(12),
              managePassportization: setInitialValuesByIndex(13),
              manageContacts: setInitialValuesByIndex(14),
              manageStaff: setInitialValuesByIndex(15),
              manageAdmin: setInitialValuesByIndex(16),
              manageDashboard: setInitialValuesByIndex(17),
              pseudoRoles: params.roleId
                ? pseudoRoles[0].roles.find((pseudoRole) =>
                    _role?.roles.find((role) => role.name === pseudoRole.name),
                  )?.name
                : undefined,
              superRoles: params.roleId
                ? positionRoles[0].roles
                    .filter((superRole) =>
                      _role?.roles
                        .map((role) => role.name)
                        .includes(superRole.name),
                    )
                    .map((role) => role.name)
                : undefined,
            }}
            layout={'vertical'}>
            <Form.Item hidden={true} name={'id'} initialValue={_role?.id}>
              <Input />
            </Form.Item>
            <Form.Item
              hidden={true}
              name={'sorting'}
              initialValue={_role?.sorting || 0}>
              <Input />
            </Form.Item>
            <FormList confirmLoading={confirmLoading} object={activeRoles} />
            <Form.Item
              rules={[
                {
                  type: 'boolean',
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
              initialValue={params.roleId ? _role?.status : true}>
              <Switch defaultChecked={params.roleId ? _role?.status : true} />
            </Form.Item>
            <RolesItem role={_role} />

            <Form.Item style={{ marginTop: '10px' }}>
              <Button
                disabled={confirmLoading}
                type={'primary'}
                htmlType={'submit'}>
                {params.roleId ? t('general.save') : t('general.add')}
              </Button>
              <Link
                to={`/manage-users/roles`}
                className={'ant-btn ant-btn-default'}
                style={{ marginLeft: '10px' }}>
                {t('general.cancel')}
              </Link>
            </Form.Item>
          </Form>
        )}
      </Card>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    _roles: state.roles.roles,
    _role: state.roles.rolesGroup,
  }
}

export default connect(mapStateToProps)(RolesForm)
