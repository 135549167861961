import statsRepository from '@app/api/repositories/stats-repository'
import { ISchedulerStats } from '@app/interfaces/scheduler/stats'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Spin } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  refresh: boolean
}

const SchedulerInfo: React.FC<props> = ({ refresh }) => {
  const [stats, setStats] = useState<ISchedulerStats>()
  const [t] = useTranslation()
  const [loaded, setLoaded] = useState<boolean>(false)
  setDocumentTitle(t('scheduler.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    setLoaded(false)
    const getData = async () => {
      await statsRepository
        .getStats()
        .then((response) => {
          setStats(response.data)
          setLoaded(true)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            openNotificationWithIcon('error', t('scheduler.error'))
            setLoaded(true)
          }
        })
    }
    getData()
    return function cleanup() {
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  const calculateCompletionState = (
    totalOrganizations: number | undefined,
    processedOrganizations: number | undefined,
  ) => {
    if (!totalOrganizations || !processedOrganizations) {
      return 0
    }
    return Math.floor((100 * processedOrganizations) / totalOrganizations)
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
  }

  function resolveConsumedTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60
    hours = hours % 24
    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds,
    )}`
  }

  return (
    <Spin spinning={!loaded}>
      <div className="info">
        <div className="info__left">
          <h4>
            {`${t('scheduler.isRunning')}: `}
            <span
              className={`${
                stats?.running
                  ? 'info__left--span-active'
                  : 'info__left--span-inactive'
              }`}
            >
              {stats?.running ? t('scheduler.active') : t('scheduler.inactive')}
            </span>
          </h4>
          {stats?.running && (
            <>
              <h4>{`${t('scheduler.appsUpdated')}: ${stats?.appsUpdated}`}</h4>
              <h4>{`${t('scheduler.appsExpired')}: ${stats?.appsExpired}`}</h4>
              <h4>{`${t('scheduler.groupsUpdated')}: ${
                stats?.groupsUpdated
              }`}</h4>
              <h4>{`${t('scheduler.organizationsUpdated')}: ${
                stats?.allOrganizations
              }/${stats?.organizationsProcessed}`}</h4>
            </>
          )}
        </div>
        <div className="info__right">
          <h4>{t('scheduler.completionState')}</h4>
          <h1>
            {calculateCompletionState(
              stats?.allOrganizations,
              stats?.organizationsProcessed,
            )}
            %
          </h1>
          {stats && (
            <h3>{`${t('scheduler.timeConsumed')}: ${resolveConsumedTime(
              stats?.timeConsumed,
            )}`}</h3>
          )}
        </div>
      </div>
    </Spin>
  )
}

export default SchedulerInfo
