import React, { useEffect } from 'react'
import { Card, Table, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { clearLangs, getLangs } from '@app/store/actions/language-actions'
import { ILangState } from '@app/interfaces/languages'
import LangForm from '@app/pages/reference-book-page-details/languages/components/lang-form/lang-form'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { AUTHORITIES } from '@app/constants/authorities'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import axios from 'axios'
import { LOADING } from '@app/constants/redux-state'

interface props {
  _languagesState: ILangState
}

const ReferenceLanguages: React.FC<props> = ({ _languagesState }) => {
  const { message, langs, state } = _languagesState
  const [t] = useTranslation()
  const dispatch = useDispatch()
  setDocumentTitle(t('language.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(clearLangs())
    dispatch(getLangs({ source }))
    if (message) openNotificationWithIcon('error', t('language.error'))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('language.language'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <LangForm edit={true} lang={record.lang} />
        ) : null,
    },
  ]

  const data =
    langs && langs.length
      ? langs.map((lang) => {
          return {
            key: lang.id,
            id: lang.id,
            name: lang.name,
            status: [lang.status],
            lang: lang,
          }
        })
      : []

  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <LangForm />
          ) : null
        }
        title={t('language.title')}
      >
        <Table
          pagination={false}
          loading={state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _languagesState: state.langs,
  }
}

export default connect(mapStateToProps)(ReferenceLanguages)
