import { INSTITUTION } from '@app/constants/organizationType'
import { IPathParams } from '@app/constants/path-params'
import MainOrganizationForm from '@app/pages/organizations/components/organization-form/components/organization-form-main/organization-form-main'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { usePartialUpdateOrganizationPassportMutation } from '@app/store/rtk/api/organization-passport/organization-passport-api'
import {
  useCreateOrganizationMutation,
  useCreateOrganizationWithPassportMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from '@app/store/rtk/api/organization/organization-api'
import { useGetOrganizationTypesQuery } from '@app/store/rtk/api/organization/organization-type-api'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Form } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const OrganizationForm: React.FC = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const { data: organization } = useGetOrganizationQuery(Number(params?.id), {
    skip: Boolean(!params?.edit),
  })
  const { data: organizationTypes } = useGetOrganizationTypesQuery('')
  const [partialUpdatePassport] = usePartialUpdateOrganizationPassportMutation()
  const [createOrganization] = useCreateOrganizationMutation()
  const [updateOrganization] = useUpdateOrganizationMutation()
  const [createOrganizationWithPassport] =
    useCreateOrganizationWithPassportMutation()
  const navigate = useNavigate()
  setDocumentTitle(
    params.addInstitution || params.addGov
      ? t('organization.add')
      : t('organization.edit'),
  )

  const getType = () => {
    if (params.addInstitution === 'add')
      return organizationTypes?.response?.find(
        (type) => type.type === 'INSTITUTION',
      )
    if (params.addGov === 'add')
      return organizationTypes?.response?.find(
        (type) => type.type === 'GOVERNMENT_AGENCY',
      )
    return organizationTypes?.response?.find(
      (type) => type.id === organization?.type.id,
    )
  }

  const getCardTitle = () =>
    params.addInstitution === 'add'
      ? t('organization.addInstitution')
      : params.addGov === 'add'
      ? t('organization.addGovAgency')
      : t('organization.edit')

  const onFinish = async (values: any) => {
    setConfirmLoading(true)
    const addPassport = () => ({
      institutionType: {
        id: values.institutionType,
      },
      queuePercentage: values.queuePercentage,
      plannedCapacity: values.plannedCapacity,
    })

    const editPassport = {
      id: organization?.passport?.id,
      organization: { id: organization?.id },
      queuePercentage: values.queuePercentage,
      plannedCapacity: values.plannedCapacity,
      institutionType: {
        id: !isNaN(values.institutionType)
          ? Number(values.institutionType)
          : organization?.passport?.institutionType.id,
      },
    }

    const institutionValues = {
      passport: addPassport(),
      organizationBuildingType: {
        id: Number(values.organizationBuildingType),
      },
      organizationSchedule: {
        id: Number(values.organizationSchedule),
      },
      institutionType: Number(values.institutionType),
      institutionSubType: {
        id: Number(values.institutionSubType),
      },
    }

    let newValues = {
      ...values,
      type: getType(),
      region: { id: Number(values.region) },
      status: { id: Number(values.status) },
      locality: { id: Number(values.locality) },
      district: { id: Number(values.district) },
      neighborhood: { id: Number(values.neighborhood) },
      organizationSector: { id: Number(values.organizationSector) },
    }

    if (getType()?.id === INSTITUTION)
      newValues = { ...newValues, ...institutionValues }

    const organizationCreate = params.addInstitution
      ? createOrganizationWithPassport
      : createOrganization

    const userSubmitApi = params.id ? updateOrganization : organizationCreate
    params?.edit &&
      getType()?.id === INSTITUTION &&
      (await partialUpdatePassport(editPassport))
    userSubmitApi(newValues)
      .unwrap()
      .then((response) => {
        openNotificationWithIcon('success', t('success.updated'))
        navigate(`/organization/${response.response.id}/page`)
      })
      .catch(() => {
        openNotificationWithIcon('error', t('errors.error'))
        setConfirmLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Card title={getCardTitle()}>
        <MainOrganizationForm
          form={form}
          params={params}
          confirmLoading={confirmLoading}
          type={getType()}
          onFinish={onFinish}
          organization={organization}
        />
      </Card>
    </React.Fragment>
  )
}

export default OrganizationForm
