import { host } from '@app/constants/env'
import { IFile } from '@app/interfaces/files'
import { IRegistryPupil } from '@app/interfaces/pupil/pupils'
import PhotoItem from '@app/shared/photo-item/photo-item'
import { IManualPupilAddState } from '@app/store/reducers/pupil-manual-add-reducer'
import {
  DatePicker,
  Descriptions,
  Form,
  Image,
  Input,
  Radio,
  Switch,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import Title from 'antd/lib/typography/Title'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  pupil: IRegistryPupil
  confirmLoading: boolean
  pupilInfo: IManualPupilAddState
  setLoading: (data: boolean) => void
  avatar: IFile
}

const PupilItem: React.FC<props> = ({
  pupil,
  confirmLoading,
  pupilInfo,
  setLoading,
  avatar,
}) => {
  const [t, i18n] = useTranslation()
  const dateFormat = 'DD.MM.YYYY'
  const genders = [
    {
      key: 'MALE',
      value: t('general.male'),
    },
    {
      key: 'FEMALE',
      value: t('general.female'),
    },
  ]

  return (
    <div>
      <Title level={5}>{t('pupils.pupil')}</Title>
      <Form.Item hidden={true} name={'benefit'} valuePropName="checked">
        <Switch disabled={confirmLoading} />
      </Form.Item>
      <Form.Item hidden={true} name={'governmentStake'} valuePropName="checked">
        <Switch disabled={confirmLoading} />
      </Form.Item>
      <Form.Item hidden={true} name={'id'}>
        <Input />
      </Form.Item>
      <div className="pupil-info__avatar">
        <p>{t('pupils.photo')}</p>
        {pupil.pupil?.fileId && avatar.id ? (
          <Image
            width={200}
            src={`${host}/files${avatar?.baseURL}/750x750-${avatar?.filePath}`}
          />
        ) : (
          <PhotoItem isLoading={setLoading} />
        )}
      </div>
      <Descriptions size={'middle'} column={1} bordered>
        <Descriptions.Item label={t('pupils.id')}>
          {pupil.pupil?.id ? pupil.pupil.id : ''}
        </Descriptions.Item>
        {pupilInfo.citizenship === 'native' ? (
          <Descriptions.Item label={t('pupils.pin')}>
            <Form.Item
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'pin'}
            >
              <Input disabled={!!pupil.child?.pin || !!pupil.pupil?.pin} />
            </Form.Item>
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label={t('pupils.fullName')}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string'
                    ? value.trim().toUpperCase()
                    : undefined,
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={'fullName'}
          >
            <Input
              style={{ textTransform: 'uppercase' }}
              disabled={!!pupil.child?.name || !!pupil.pupil?.fullName}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.gender')}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={'gender'}
          >
            <Radio.Group
              disabled={!!pupil.child?.gender || !!pupil.pupil?.gender}
            >
              {genders.map((gender, index) => (
                <Radio key={index} value={gender.key}>
                  {gender.value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.metric')}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string' ? value.trim() : undefined,
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={'metric'}
          >
            <Input disabled={true} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.birthDate')}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={'birthDate'}
          >
            <DatePicker
              disabled={true}
              style={{ width: '100%' }}
              locale={locale}
              format={dateFormat}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.phone')}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string' ? value.trim() : undefined,
                required: true,
                message: t('errors.requiredMessage'),
              },
              {
                validator: async (_, phone) => {
                  if (phone?.trim().length) {
                    if (!phone?.match(/^[0-9]*$/)) {
                      return Promise.reject(
                        new Error(t('errors.phoneRegularError')),
                      )
                    }
                  }
                },
              },
            ]}
            name={'phone'}
          >
            <Input />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.address')}>
          <Form.Item name={'address'}>
            <Input />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.socialProtection')}>
          <Form.Item name={'socialProtection'} valuePropName="checked">
            <Switch
              disabled={confirmLoading}
              defaultChecked={pupil.pupil?.socialProtection === true}
            />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default PupilItem
