import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import ThreeDots from '@app/shared/loader/ThreeDots'
import React from 'react'
interface IProps {
  apiKey: string
  id?: number
  nhood?: object
}

const DescriptionContent: React.FC<IProps> = ({ apiKey, id, nhood }) => {
  const { localize, localizeItem } = useLocalization()
  const { data } = useCache(apiKey)
  return (
    <>
      {!data ? (
        <ThreeDots style="dot-pulse" height={22} padding={0} />
      ) : nhood ? (
        localizeItem(nhood as any)?.data?.name
      ) : (
        localize(data)?.find((item) => item.id === id)?.data.name
      )}
    </>
  )
}

export default DescriptionContent
