import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IRegion } from '@app/interfaces/organization/region'
import RegionForm from '@app/pages/reference-book-page-details/regions/components/region-form/region-form'
import { getRegions } from '@app/store/actions/regions-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _regions: IRegion[]
  _state: string
}

const Regions: React.FC<props> = ({ _regions, _state }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('region.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_regions)) dispatch(getRegions({ source }, t('region.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('region.region'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.sort - b.sort,
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
      sorter: (a, b) => Number(a.status[0]) - Number(b.status[0]),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <RegionForm edit={true} region={record.region} />
        ) : null,
    },
  ]

  const data = !isEmpty(_regions)
    ? _regions.map((region) => {
        return {
          key: region.id,
          id: region.id,
          name: localizeData(region?.data)?.name,
          status: [region.status],
          sort: region.sorting,
          region: region,
        }
      })
    : []

  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <RegionForm />
          ) : null
        }
        title={t('region.title')}
      >
        <Table
          loading={_state === LOADING}
          pagination={false}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _regions: state.regions.regions,
    _state: state.regions.state,
  }
}

export default connect(mapStateToProps)(Regions)
