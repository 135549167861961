import {
  CLEAR_EMPLOYEE_POSITION,
  CREATE_EMPLOYEE_POSITION,
  DELETE_EMPLOYEE_POSITION,
  FETCH_EMPLOYEE_POSITIONS_FAILURE,
  FETCH_EMPLOYEE_POSITIONS_REQUEST,
  FETCH_EMPLOYEE_POSITIONS_SUCCESS,
  FETCH_EMPLOYEE_POSITION_FAILURE,
  FETCH_EMPLOYEE_POSITION_REQUEST,
  FETCH_EMPLOYEE_POSITION_SUCCESS,
  UPDATE_EMPLOYEE_POSITION,
} from '@app/store/types/employee-position'

import employeePositionRepository from '@app/api/repositories/employee-position-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import { IEmployeePositionActions } from '@app/interfaces/employee/employee-position'
import axios from 'axios'

export const getEmployeePosition =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeePositionRequest())
    await employeePositionRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchEmployeePositionSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeePositionFailure(error?.message, error?.code))
        }
      })
  }

export const getEmployeePositions =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeePositionsRequest())
    await employeePositionRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchEmployeePositionsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeePositionsFailure(error?.message, error?.code))
        }
      })
  }

const fetchEmployeePositionsRequest = (): IEmployeePositionActions => {
  return {
    type: FETCH_EMPLOYEE_POSITIONS_REQUEST,
  }
}

const fetchEmployeePositionsSuccess = (
  employeePositions,
  count,
): IEmployeePositionActions => {
  return {
    type: FETCH_EMPLOYEE_POSITIONS_SUCCESS,
    payload: { employeePositions, count },
  }
}

const fetchEmployeePositionsFailure = (
  message,
  code,
): IEmployeePositionActions => {
  return {
    type: FETCH_EMPLOYEE_POSITIONS_FAILURE,
    payload: { message, code },
  }
}

const fetchEmployeePositionRequest = (): IEmployeePositionActions => {
  return {
    type: FETCH_EMPLOYEE_POSITION_REQUEST,
  }
}

const fetchEmployeePositionSuccess = (
  employeePosition,
): IEmployeePositionActions => {
  return {
    type: FETCH_EMPLOYEE_POSITION_SUCCESS,
    payload: employeePosition,
  }
}

const fetchEmployeePositionFailure = (
  message,
  code,
): IEmployeePositionActions => {
  return {
    type: FETCH_EMPLOYEE_POSITION_FAILURE,
    payload: { message, code },
  }
}

export function clearEmployeePositions() {
  return {
    type: CLEAR_EMPLOYEE_POSITION,
    payload: null,
  }
}

export function createEmployeePosition(
  employeePosition,
): IEmployeePositionActions {
  return {
    type: CREATE_EMPLOYEE_POSITION,
    payload: employeePosition,
  }
}

export function updateEmployeePosition(
  employeePosition,
): IEmployeePositionActions {
  return {
    type: UPDATE_EMPLOYEE_POSITION,
    payload: employeePosition,
  }
}

export function deleteEmployeePosition(
  employeePosition,
): IEmployeePositionActions {
  return {
    type: DELETE_EMPLOYEE_POSITION,
    payload: employeePosition,
  }
}
