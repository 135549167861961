import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import rolesRepository from '@app/api/repositories/roles-repository'
import { getAllRoles } from '@app/api/rest/rolesApi'
import { IGroupRolesActions, IRoleActions } from '@app/interfaces/roles'
import {
  CLEAR_ALL_ROLES,
  CLEAR_GROUP_ROLE,
  CLEAR_GROUP_ROLES,
  CREATE_GROUP_ROLE,
  FETCH_GROUP_ROLES_FAILURE,
  FETCH_GROUP_ROLES_REQUEST,
  FETCH_GROUP_ROLES_SUCCESS,
  FETCH_GROUP_ROLE_FAILURE,
  FETCH_GROUP_ROLE_REQUEST,
  FETCH_GROUP_ROLE_SUCCESS,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  REMOVE_GROUP_ROLE,
  UPDATE_GROUP_ROLE,
} from '@app/store/types/roles'
import axios from 'axios'

export const getGroupRole =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchGroupRoleRequest())
    await rolesRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchGroupRoleSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchGroupRoleFailure(error?.message, error?.code))
        }
      })
  }

export const getGroupRoles =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchGroupRolesRequest())
    await rolesRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchGroupRolesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchGroupRolesFailure(error?.message, error?.code))
        }
      })
  }

export const getRoles =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchRolesRequest())
    await getAllRoles({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchRolesSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchRolesFailure(error?.message, error?.code))
        }
      })
  }

const fetchGroupRolesRequest = (): IGroupRolesActions => {
  return {
    type: FETCH_GROUP_ROLES_REQUEST,
  }
}

const fetchGroupRolesSuccess = (rolesGroups, count): IGroupRolesActions => {
  return {
    type: FETCH_GROUP_ROLES_SUCCESS,
    payload: { rolesGroups, count },
  }
}

const fetchGroupRolesFailure = (message, code): IGroupRolesActions => {
  return {
    type: FETCH_GROUP_ROLES_FAILURE,
    payload: { message, code },
  }
}

const fetchRolesRequest = (): IRoleActions => {
  return {
    type: FETCH_ROLES_REQUEST,
  }
}

const fetchRolesSuccess = (roles, count): IRoleActions => {
  return {
    type: FETCH_ROLES_SUCCESS,
    payload: { roles, count },
  }
}

const fetchRolesFailure = (message, code): IRoleActions => {
  return {
    type: FETCH_ROLES_FAILURE,
    payload: { message, code },
  }
}

const fetchGroupRoleRequest = (): IGroupRolesActions => {
  return {
    type: FETCH_GROUP_ROLE_REQUEST,
  }
}

const fetchGroupRoleSuccess = (rolesGroup): IGroupRolesActions => {
  return {
    type: FETCH_GROUP_ROLE_SUCCESS,
    payload: rolesGroup,
  }
}

const fetchGroupRoleFailure = (message, code): IGroupRolesActions => {
  return {
    type: FETCH_GROUP_ROLE_FAILURE,
    payload: { message, code },
  }
}

export function clearAllGroupRoles() {
  return {
    type: CLEAR_GROUP_ROLES,
    payload: null,
  }
}

export function clearAllRoles() {
  return {
    type: CLEAR_ALL_ROLES,
    payload: null,
  }
}
export function clearAllRole() {
  return {
    type: CLEAR_GROUP_ROLE,
    payload: null,
  }
}

export function updateGroupRole(groupRole): IGroupRolesActions {
  return {
    type: UPDATE_GROUP_ROLE,
    payload: groupRole,
  }
}

export function createGroupRole(groupRole): IGroupRolesActions {
  return {
    type: CREATE_GROUP_ROLE,
    payload: groupRole,
  }
}

export function deleteGroupRole(groupRole): IGroupRolesActions {
  return {
    type: REMOVE_GROUP_ROLE,
    payload: groupRole,
  }
}
