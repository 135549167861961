import useLocalization from '@app/hooks/useLocalization'
import { IMenuSeason } from '@app/interfaces/organization-menu'
import { getSeason } from '@app/store/actions/organization-menus-actions'
import { Button } from 'antd'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import 'moment/locale/ru'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import './menu-season-info.less'

interface props {
  season: IMenuSeason
}

const MenuSeasonInfo: React.FC<props> = ({ season }) => {
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const dispatch = useDispatch()

  const onSeasonEditHandler = () => {
    dispatch(
      getSeason(
        { data: season.id },
        t('organizationMenu.menuSeasons.errorEdit'),
      ),
    )
  }

  return (
    <div className="menu-seasons__info" key={season.id}>
      <p className="menu-seasons__info--name">
        {localizeData(season.data)?.name}
      </p>
      <div className="menu-seasons__info--range">
        <div className="point">
          <p>{t('organizationMenu.menuSeasons.fromDate')}</p>
          <p>{moment(season.fromDate).locale('ru').format('D MMMM, yyyy')}</p>
        </div>
        <div className="point">
          <p>{t('organizationMenu.menuSeasons.toDate')}</p>
          <p>{moment(season.toDate).locale('ru').format('D MMMM, yyyy')}</p>
        </div>
      </div>
      <div className="menu-seasons__info--footer">
        <div className="menus">
          <p className="menus__title">{t('organizationMenu.types.menus')}:</p>
          <div className="menus__items">
            {cloneDeep(season.menus)
              .sort((a, b) => a.queue - b.queue)
              .map((menu) => {
                return (
                  <p key={menu.id} className="menus__item">
                    {localizeData(menu.menu.data)?.name}
                  </p>
                )
              })}
          </div>
        </div>
        <Button onClick={onSeasonEditHandler}>{t('general.edit')}</Button>
      </div>
    </div>
  )
}

export default MenuSeasonInfo
