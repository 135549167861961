import EmployeeRateMehnatRepository from '@app/api/repositories/employee-rate-mehnat-repository'
import Employee from '@app/api/repositories/employee-repository'
import { DATE_FORMAT } from '@app/constants/date'
import {
  MEHNAT_ERROR_API,
  MEHNAT_ERROR_LIMIT,
} from '@app/constants/MehnatErrorCause'
import { WORK_RATE_FULL, WORK_RATE_PART } from '@app/constants/workRates'
import {
  IEmployee,
  IEmployeeAppointment,
} from '@app/interfaces/employee/employee'
import { IEmployeeRateMehnat } from '@app/interfaces/employee/employee-rate-mehnat'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { fetchEmployeeSuccess } from '@app/store/actions/employee-actions'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Row,
  Spin,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

interface params {
  id?: string
  posId?: string
}

interface props {
  params: params
  reset: () => void
  employee: IEmployee
  rateId: number
  pin: string
}

const AssignEmployeeWithPositionForm: React.FC<props> = ({
  params,
  reset,
  employee,
  rateId,
  pin,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [totalRate, setTotalRate] = useState<number>(0)
  const [currentRate, setCurrentRate] = useState<number>(0)
  const navigate = useNavigate()
  const [rates, setRates] = useState<IEmployeeRateMehnat[]>([])
  // const [permit, setPermit] = useState<boolean>(false);
  const [errorCause, setErrorCause] = useState<number>(0)
  useEffect(() => {
    let current = 0
    if (rateId === WORK_RATE_FULL) current = 1
    else if (rateId === WORK_RATE_PART) current = 0.5
    setCurrentRate(current)
    EmployeeRateMehnatRepository.getAll(pin)
      .then((response) => {
        let total = 0
        response.data.forEach((rate) => (total += parseFloat(rate.rate)))
        setTotalRate(total)
        setRates(response.data)
        if (current + total > 1.5) {
          // setPermit(false);
          setErrorCause(MEHNAT_ERROR_LIMIT)
        }
        // setPermit(true);
      })
      .catch((error) => {
        // setPermit(false);
        setErrorCause(MEHNAT_ERROR_API)
      })
  }, [])

  const onFinish = (values: any) => {
    const newValues: IEmployeeAppointment = {
      orderNo: values.orderNo,
      orderDate: values['orderDate'].format(DATE_FORMAT),
      employeeId: employee.id!,
      organizationPositionId: Number(params.posId),
    }

    setConfirmLoading(true)
    Employee.appointmentEmployeeOrganizationPosition(newValues)
      .then((response) => {
        dispatch(fetchEmployeeSuccess(response.data))
        setConfirmLoading(false)
        navigate(`/organization/${params.id}/employees`)
        openNotificationWithIcon('success', t('employee.successAssign'))
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('employee.notVacant'))
      })
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Card title={t('employee.assign')} bordered={false}>
            <Spin spinning={confirmLoading}>
              <Form
                className={'employee-add-form'}
                onFinish={onFinish}
                layout={'vertical'}
              >
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.orderNo')}
                  name={'orderNo'}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.orderDate')}
                  name={'orderDate'}
                >
                  <DatePicker
                    locale={locale}
                    format={DATE_FORMAT}
                    // disabled={confirmLoading || !permit}
                    disabled={confirmLoading}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type={'primary'}
                    disabled={confirmLoading}
                    // disabled={confirmLoading || !permit}
                    htmlType={'submit'}
                  >
                    {t('employee.assign')}
                  </Button>
                  <Button
                    type={'primary'}
                    onClick={reset}
                    style={{ marginLeft: '10px' }}
                    disabled={confirmLoading}
                  >
                    {t('general.cancel')}
                  </Button>
                </Form.Item>
                {/* <Row>
                  {!permit && (
                    <Typography.Text style={{ color: "red" }}>
                      {(errorCause === MEHNAT_ERROR_API &&
                        t("employeeRate.error")) ||
                        (errorCause === MEHNAT_ERROR_LIMIT &&
                          t("employeeRate.limitError") +
                            ` (${currentRate} + ${totalRate} > 1.5)`)}
                    </Typography.Text>
                  )}
                </Row> */}
              </Form>
            </Spin>
          </Card>
        </Col>
        <Col span={12}>
          <Card title={t('employee.employeeInfo')} bordered={false}>
            <Descriptions size={'middle'} column={1} bordered>
              <Descriptions.Item label={t('employeeFields.fullName')}>
                {`${employee.lastName ?? ''} ${employee.firstName ?? ''} ${
                  employee.middleName ?? ''
                }`}
              </Descriptions.Item>
              <Descriptions.Item label={t('employeeFields.passportNumber')}>
                {employee.passportNumber}
              </Descriptions.Item>
              <Descriptions.Item label={t('employeeFields.pin')}>
                {employee.pin}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Card title={t('employeeRate.rates')} bordered={false}>
            <Descriptions size={'middle'} column={1} bordered>
              <Descriptions.Item label={t('employeeRate.selected')}>
                {currentRate}
              </Descriptions.Item>
              <Descriptions.Item label={t('employeeRate.total')}>
                {totalRate}
              </Descriptions.Item>
              {rates &&
                rates.map((rate, index) => {
                  return (
                    <Descriptions.Item
                      key={index}
                      label={`${t('employeeRate.rate')} ${index + 1}`}
                    >
                      {rate.rate}
                    </Descriptions.Item>
                  )
                })}
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AssignEmployeeWithPositionForm
