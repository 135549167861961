import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEducationLanguage,
  IEducationLanguageState,
} from '@app/interfaces/education/education-language'
import {
  CLEAR_EDUCATION_LANGUAGE,
  CREATE_EDUCATION_LANGUAGE,
  DELETE_EDUCATION_LANGUAGE,
  FETCH_EDUCATION_LANGUAGES_FAILURE,
  FETCH_EDUCATION_LANGUAGES_REQUEST,
  FETCH_EDUCATION_LANGUAGES_SUCCESS,
  FETCH_EDUCATION_LANGUAGE_FAILURE,
  FETCH_EDUCATION_LANGUAGE_REQUEST,
  FETCH_EDUCATION_LANGUAGE_SUCCESS,
  UPDATE_EDUCATION_LANGUAGE,
} from '@app/store/types/education-language'

const initialState: IEducationLanguageState = {
  educationLanguage: {} as IEducationLanguage,
  educationLanguages: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const educationLanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EDUCATION_LANGUAGES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_LANGUAGES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationLanguages: action.payload.educationLanguages,
        count: action.payload.count,
      }
    case FETCH_EDUCATION_LANGUAGES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationLanguages: [],
      }
    case FETCH_EDUCATION_LANGUAGE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_LANGUAGE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationLanguage: action.payload,
      }
    case FETCH_EDUCATION_LANGUAGE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationLanguage: {},
      }
    case CLEAR_EDUCATION_LANGUAGE:
      return { ...state, educationLanguages: [] }
    case CREATE_EDUCATION_LANGUAGE:
      return {
        ...state,
        educationLanguages: [...state.educationLanguages, action.payload],
      }
    case UPDATE_EDUCATION_LANGUAGE:
      const updatedEducationLanguage = state.educationLanguages.map(function (
        educationLanguage,
        index,
      ) {
        if (action.payload.id === educationLanguage.id) {
          return action.payload
        }
        return educationLanguage
      })
      return { ...state, educationLanguages: updatedEducationLanguage }
    case DELETE_EDUCATION_LANGUAGE:
      const editedEducationLanguage = state.educationLanguages.filter(function (
        educationLanguage,
      ) {
        if (action.payload.id === educationLanguage.id) {
          return false
        }
        return educationLanguage
      })
      return { ...state, educationLanguages: editedEducationLanguage }
    default:
      return state
  }
}
