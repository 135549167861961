import {
  UPDATE_GROUP_ROLE,
  REMOVE_GROUP_ROLE,
  CREATE_GROUP_ROLE,
  CLEAR_GROUP_ROLES,
  CLEAR_GROUP_ROLE,
  FETCH_GROUP_ROLES_FAILURE,
  FETCH_GROUP_ROLES_REQUEST,
  FETCH_GROUP_ROLES_SUCCESS,
  FETCH_GROUP_ROLE_FAILURE,
  FETCH_GROUP_ROLE_REQUEST,
  FETCH_GROUP_ROLE_SUCCESS,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
} from '@app/store/types/roles'
import { IUserGroupRoles, IUserRolesState } from '@app/interfaces/roles'
import { IDLE, LOADING, SUCCEEDED, FAILED } from '@app/constants/redux-state'

const initialState: IUserRolesState = {
  roles: [],
  rolesGroups: [],
  rolesGroup: {} as IUserGroupRoles,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUP_ROLES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_GROUP_ROLES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        rolesGroups: action.payload.rolesGroups,
        count: action.payload.count,
      }
    case FETCH_GROUP_ROLES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        rolesGroups: [],
      }
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        roles: action.payload.roles,
        count: action.payload.count,
      }
    case FETCH_ROLES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        roles: [],
      }
    case FETCH_GROUP_ROLE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_GROUP_ROLE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        rolesGroup: action.payload,
      }
    case FETCH_GROUP_ROLE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        rolesGroup: {},
      }
    case CLEAR_GROUP_ROLES:
      return { ...state, rolesGroups: [] }
    case CLEAR_GROUP_ROLE:
      return { ...state, rolesGroup: {} }
    case CREATE_GROUP_ROLE:
      return { ...state, rolesGroups: [...state.rolesGroups, action.payload] }
    case UPDATE_GROUP_ROLE:
      const updatedRolesGroups = state.rolesGroups.map(function (roleGroup) {
        if (action.payload.id === roleGroup.id) {
          return action.payload
        }
        return roleGroup
      })
      return { ...state, rolesGroups: updatedRolesGroups }
    case REMOVE_GROUP_ROLE:
      const editedRolesGroups = state.rolesGroups.filter(function (roleGroup) {
        if (action.payload.id === roleGroup.id) {
          return false
        }
        return roleGroup
      })
      return { ...state, rolesGroups: editedRolesGroups }
    default:
      return state
  }
}
