import React from 'react'
import { Link } from 'react-router-dom'
import './logo.less'
import logo from '@assets/images/Logosvg-light.svg'

const Logo: React.FC = () => {
  return (
    <Link className={'logo'} to={'/profile'}>
      <img className={'logo__img'} src={logo} height={50} alt={''} />
    </Link>
  )
}

export default Logo
