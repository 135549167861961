import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import { CLOSE, OPEN } from '@app/constants/educationGroupStatus'
import { IPathParams } from '@app/constants/path-params'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import EducationGradeItem from '@app/pages/organizations/components/organization-groups/components/groups-page-form/components/education-grade-item/education-grade-item'
import EducationLanguageItem from '@app/pages/organizations/components/organization-groups/components/groups-page-form/components/education-language-item/education-language-item'
import InstitutionScheduleItem from '@app/pages/organizations/components/organization-groups/components/groups-page-form/components/institution-schedule-item/institution-schedule-item'
import TutorItem from '@app/pages/organizations/components/organization-groups/components/groups-page-form/components/tutor-item/tutor-item'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { useGetEducationDailySchedulesQuery } from '@app/store/rtk/api/education/education-daily-schedule-api'
import {
  useCreateEducationGroupMutation,
  usePartialUpdateEducationGroupMutation,
  useUpdateEducationGroupMutation,
} from '@app/store/rtk/api/education/education-group-api'
import { useGetEducationGroupLocationsQuery } from '@app/store/rtk/api/education/education-group-location-api'
import { useGetEducationGroupTypesQuery } from '@app/store/rtk/api/education/education-group-type-api'
import { useGetEducationWeeklySchedulesQuery } from '@app/store/rtk/api/education/education-weekly-schedule-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import EducationDailyScheduleItem from './components/education-daily-schedule-item/education-daily-schedule-item'
import EducationGroupLocationItem from './components/education-group-location-item/education-group-location-item'
import EducationGroupTypeItem from './components/education-group-type-item/education-group-type-item'
import EducationWeeklyScheduleItem from './components/education-weekly-schedule-item/education-weekly-schedule-item'

interface props {
  educationGroup?: IEducationGroup
  edit?: boolean
}

const statuses = [OPEN, CLOSE]

const GroupsPageForm: React.FC<props> = ({ educationGroup, edit }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const params: IPathParams = useParams()
  const maxGroupMembers = rolesChecker([AUTHORITIES.ADMIN]) ? 40 : 30
  const minGroupMembers = rolesChecker([AUTHORITIES.ADMIN]) ? 0 : 12
  const [visible, setVisible] = useState<boolean>(false)
  const { data: schedules } = useGetEducationDailySchedulesQuery('')
  const { data: locations } = useGetEducationGroupLocationsQuery('')
  const { data: types } = useGetEducationGroupTypesQuery('')
  const { data: weeklySchedules } = useGetEducationWeeklySchedulesQuery('')
  const [createGroup] = useCreateEducationGroupMutation()
  const [patchGroup] = usePartialUpdateEducationGroupMutation()
  const [updateGroup] = useUpdateEducationGroupMutation()

  useEffect(() => {
    edit && educationGroup?.status === CLOSE && setIsOpen(false)
  }, [educationGroup])

  const onStatusChangeHandler = (e) => setIsOpen(e === OPEN)
  const showDrawer = () => setVisible(true)
  const onClose = () => {
    setIsOpen(true)
    setVisible(false)
  }

  useEffect(() => {
    if (form) {
      educationGroup
        ? form.setFieldsValue({
            ...educationGroup,
            status: educationGroup?.status ?? OPEN,
            type: educationGroup?.type && educationGroup?.type?.id,
            location: educationGroup?.location && educationGroup?.location?.id,
            weeklyWorkSchedule:
              educationGroup?.weeklyWorkSchedule &&
              educationGroup?.weeklyWorkSchedule?.id,
            dailyRoutine:
              educationGroup?.dailyRoutine && educationGroup?.dailyRoutine?.id,
          })
        : form.setFieldsValue({
            organizationId: params?.id,
          })
    }
  }, [educationGroup])

  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      type: types?.response?.find((item) => item.id === values.type),
      location: locations?.response?.find(
        (item) => item.id === values.location,
      ),
      weeklyWorkSchedule: weeklySchedules?.response?.find(
        (item) => item.id === values.weeklyWorkSchedule,
      ),
      dailyRoutine: schedules?.response?.find(
        (item) => item.id === values.dailyRoutine,
      ),
    }
    setConfirmLoading(true)
    const educationGroupApi = educationGroup ? updateGroup : createGroup
    !educationGroup
      ? await educationGroupApi(newValues)
          .then(() => {
            openNotificationWithIcon('success', t('success.updated'))
            setConfirmLoading(false)
            onClose()
          })
          .catch(() => {
            setConfirmLoading(false)
            onClose()
          })
      : (rolesChecker([AUTHORITIES.ADMIN])
          ? educationGroupApi(newValues)
          : patchGroup(newValues)
        )
          .then(() => {
            openNotificationWithIcon('success', t('success.updated'))
            setConfirmLoading(false)
            onClose()
          })
          .catch(() => {
            setConfirmLoading(false)
            onClose()
          })
  }

  return (
    <>
      {rolesChecker([
        AUTHORITIES.ADMIN,
        AUTHORITIES.ROLE_EDUCATION_GROUP_CURRENT_EDIT,
      ]) && (
        <Button
          className={edit ? '' : 'institutions__add'}
          onClick={showDrawer}
          type={edit ? 'link' : 'primary'}>
          {' '}
          {edit ? (
            <i className="far fa-edit"> {t('general.edit')} </i>
          ) : (
            <span>
              {' '}
              <PlusOutlined /> {t('general.add')}
            </span>
          )}
        </Button>
      )}
      <Drawer
        title={edit ? t('educationGroup.edit') : t('educationGroup.add')}
        width={720}
        open={visible}
        destroyOnClose={true}
        footer={[
          <React.Fragment key={1}>
            <Button
              onClick={form.submit}
              disabled={confirmLoading}
              htmlType={'submit'}
              type="primary">
              {edit ? t('general.edit') : t('general.add')}
            </Button>
            <Button
              disabled={confirmLoading}
              onClick={onClose}
              style={{ marginLeft: 8 }}>
              {t('general.back')}
            </Button>
          </React.Fragment>,
        ]}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<CloseOutlined onClick={onClose} />}>
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            layout="vertical"
            requiredMark
            onFinish={onFinish}
            style={{ position: 'relative' }}>
            <Col span={12}>
              <Form.Item name={'organizationId'} hidden={true}>
                <Input />
              </Form.Item>
            </Col>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={'name'}
                  label={t('educationGroup.name')}
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                    {
                      min: 0,
                      max: 180,
                      message: t('educationGroup.nameRequiredMessage'),
                    },
                  ]}>
                  <Input disabled={confirmLoading || !isOpen} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('educationGroup.groupCapacity')}
                  name={'groupCapacity'}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      min: minGroupMembers,
                      max: maxGroupMembers,
                      message: rolesChecker([AUTHORITIES.ADMIN])
                        ? t(
                            'educationGroup.groupAdminCapacityMinMaxErrorMessage',
                          )
                        : t('educationGroup.groupCapacityMinMaxErrorMessage'),
                    },
                    {
                      validator: (_, value) => {
                        if (edit) {
                          if (educationGroup) {
                            if (value - educationGroup?.groupChildren < 0) {
                              return Promise.reject(
                                new Error(t('educationGroup.capacityError')),
                              )
                            }
                          }
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}>
                  <InputNumber
                    disabled={
                      confirmLoading ||
                      !isOpen ||
                      (edit &&
                        !rolesChecker([
                          AUTHORITIES.ADMIN,
                          AUTHORITIES.ROLE_MANAGER,
                        ]))
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <EducationGroupTypeItem isOpen={isOpen} edit={edit} />
              <EducationGradeItem isOpen={isOpen} edit={edit} />
            </Row>
            <Row gutter={8}>
              <EducationLanguageItem isOpen={isOpen} edit={edit} />
              <InstitutionScheduleItem isOpen={isOpen} edit={edit} />
            </Row>
            <Row gutter={8}>
              <EducationDailyScheduleItem isOpen={isOpen} edit={edit} />
              <EducationWeeklyScheduleItem isOpen={isOpen} edit={edit} />
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={'status'}
                  label={t('educationGroup.status')}
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}>
                  <Select
                    disabled={
                      confirmLoading ||
                      (edit && !rolesChecker([AUTHORITIES.ADMIN]))
                    }
                    onChange={onStatusChangeHandler}>
                    {statuses.map((status) => (
                      <Select.Option key={status} value={status}>
                        {status}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <EducationGroupLocationItem isOpen={isOpen} edit={edit} />
            </Row>
            <Row gutter={8}>
              <TutorItem isOpen={isOpen} />
            </Row>
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default GroupsPageForm
