import { FilterOutlined } from '@ant-design/icons'
import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import useCache from '@app/hooks/useCache'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import useQuery from '@app/hooks/useQuery'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import { getOrganizations } from '@app/store/actions/organization-actions'
import { findById } from '@app/utils/find-by-id/find-by-id'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Form, FormInstance, Select, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

interface props {
  request: (string) => void
  searchParams: URLSearchParams
  form: FormInstance<any>
}

const OrganizationAttendanceMonitoringFilterForm: React.FC<props> = ({
  request,
  searchParams,
  form,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [regionId, setRegionId] = useState<number>(0)
  const { serializeQuery } = useQuery()
  const { selectors } = useCreateSelector()
  const { localize } = useLocalization()
  const userInfo = useSelector(selectors.userInfo.userInfo)
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts, isLoading } = useCache<IDistrict>(ApiKeys.district)
  const organizations = useSelector(selectors.organization.organizations)

  useEffect(() => {
    rolesChecker([
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
    ]) &&
      !isEmpty(userInfo.organizations) &&
      setRegionId(userInfo.organizations[0].regionId)
    if (rolesChecker([AUTHORITIES.ROLE_DISTRICT_IKT])) {
      dispatch(
        getOrganizations(
          {
            params: `page=0&size=500&regionId.equals=${userInfo.organizations[0].regionId}&districtId.equals=${userInfo.organizations[0].districtId}`,
          },
          t('organization.error'),
        ),
      )
    }
  }, [])

  const onRegionChangeHandler = (value) => {
    setRegionId(value)
    form.setFieldsValue({ districtId: undefined })
    form.setFieldsValue({ organizationId: undefined })
  }

  const onDistrictChangeHandler = (value) => {
    dispatch(
      getOrganizations(
        {
          params: `page=0&size=500&regionId.equals=${regionId}&districtId.equals=${value}`,
        },
        t('organization.error'),
      ),
    )
    form.setFieldsValue({ organizationId: undefined })
  }

  const getRegionName = (regionId) => {
    return findById(regions, regionId)?.data.name
  }

  const findDistricts = (id) => {
    return isEmpty(districts)
      ? []
      : districts.filter((district) => district.regionId === id)
  }

  const onFinish = (values: any) => {
    const newValues = {
      summary: values.districtId ? false : true,
      regionId: values.regionId,
      districtId: values.districtId,
      organizationId: values.organizationId,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
  }

  const onReset = (values: any) => {
    const newValues = {
      regionId: null,
      districtId: null,
      organizationId: null,
      summary: true,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
    form.resetFields()
  }

  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        <div className="attendance-monitoring__header-row">
          <Form.Item
            name={'regionId'}
            label={t('region.region')}
            initialValue={
              rolesChecker([
                AUTHORITIES.ROLE_REGION_IKT,
                AUTHORITIES.ROLE_DISTRICT_IKT,
              ]) &&
              !isEmpty(userInfo.organizations) &&
              userInfo.organizations[0].regionId
            }
          >
            <Select
              disabled={
                !rolesChecker([
                  AUTHORITIES.ADMIN,
                  AUTHORITIES.ROLE_MONITOR_ATTENDANCE_ALL_VIEW,
                ])
              }
              showSearch={true}
              onChange={onRegionChangeHandler}
            >
              {sortByName(localize(regions))?.map(({ data: { name }, id }) => {
                return (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={'districtId'}
            label={t('district.district')}
            initialValue={
              rolesChecker([AUTHORITIES.ROLE_DISTRICT_IKT]) &&
              !isEmpty(userInfo.organizations) &&
              userInfo.organizations[0].districtId
            }
          >
            <Select
              disabled={
                !rolesChecker([
                  AUTHORITIES.ADMIN,
                  AUTHORITIES.ROLE_MONITOR_ATTENDANCE_ALL_VIEW,
                  AUTHORITIES.ROLE_MONITOR_ATTENDANCE_REGION_VIEW,
                ])
              }
              showSearch={true}
              onChange={onDistrictChangeHandler}
            >
              {sortByName(localize(findDistricts(regionId)))
                ?.filter((district) => {
                  return district.data.name !== getRegionName(regionId)
                })
                .map((district) => {
                  return (
                    <Select.Option key={district.id} value={district.id}>
                      {district.data.name}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item name={'organizationId'} label={t('organization.title')}>
            <Select showSearch={true}>
              {sortByName(organizations)?.map((organization) => {
                return (
                  <Select.Option key={organization.id} value={organization.id}>
                    {organization.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Button type="primary" htmlType="submit">
            <FilterOutlined />
            {t('general.filter')}
          </Button>
          {rolesChecker([AUTHORITIES.ADMIN]) && (
            <Button
              htmlType={'reset'}
              style={{ margin: '0 8px' }}
              onClick={onReset}
            >
              {t('general.reset')}
            </Button>
          )}
        </div>
      </Form>
    </Spin>
  )
}

export default OrganizationAttendanceMonitoringFilterForm
