import useLocalization from '@app/hooks/useLocalization'
import { IToiletLocation } from '@app/interfaces/organization-passport/toilet-location'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  toiletLocations: IToiletLocation[]
}

const ToiletLocationsItem: React.FC<props> = ({ toiletLocations }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()

  return (
    <>
      <Form.Item
        label={t('toiletLocation.toiletLocation')}
        name={'toiletLocation'}
      >
        <Select placeholder={t('institutionPlaceholders.toiletLocation')}>
          {sortByName(localize(toiletLocations)).map(
            (toiletLocation, index) => {
              if (toiletLocation.status) {
                return (
                  <Select.Option
                    key={index}
                    value={toiletLocation.id!.toString()}
                  >
                    {toiletLocation?.data?.name}
                  </Select.Option>
                )
              }
              return null
            },
          )}
        </Select>
      </Form.Item>
    </>
  )
}

export default ToiletLocationsItem
