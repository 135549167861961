import { ApiKeys } from '@app/constants/api-keys'
import { IEmployeeStatus } from '@app/interfaces/employee/employee-status'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import localforage from 'localforage'
import { emptyEmployeeApi } from '.'
import { IRtkResponse } from '../../types'

export const employeeStatusApi = emptyEmployeeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getEmployeeStatuses: build.query<
      IRtkResponse<IEmployeeStatus[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'employee/api/v1/employee-status-dictionary',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IEmployeeStatus[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.employeeStatus,
                id,
              })),
              { type: ApiKeys.employeeStatus, id: 'LIST' },
            ]
          : [{ type: ApiKeys.employeeStatus, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.employeeStatus, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getEmployeeStatus: build.query<IEmployeeStatus, number>({
      query: (id) => ({
        url: `employee/api/v1/employee-status-dictionary/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.employeeStatus, id },
      ],
    }),
    createEmployeeStatus: build.mutation<
      IRtkResponse<IEmployeeStatus>,
      Required<IEmployeeStatus>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'employee/api/v1/employee-status-dictionary',
          data,
        }
      },
      transformResponse: (response: IEmployeeStatus, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.employeeStatus, id: 'LIST' }],
    }),
    updateEmployeeStatus: build.mutation<
      IRtkResponse<IEmployeeStatus>,
      Required<IEmployeeStatus>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/employee-status-dictionary/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployeeStatus, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employeeStatus, id: arg.id },
      ],
    }),
    partialUpdateEmployeeStatus: build.mutation<
      IRtkResponse<IEmployeeStatus>,
      Subset<IEmployeeStatus>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/employee-status-dictionary/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployeeStatus, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employeeStatus, id: arg.id },
      ],
    }),
  }),
})

const useStateGetEmployeeStatusQuery =
  employeeStatusApi.endpoints.getEmployeeStatus.useQueryState
const useStateGetEmployeeStatusesQuery =
  employeeStatusApi.endpoints.getEmployeeStatuses.useQueryState

export const {
  useGetEmployeeStatusesQuery,
  useGetEmployeeStatusQuery,
  useCreateEmployeeStatusMutation,
  useUpdateEmployeeStatusMutation,
  usePartialUpdateEmployeeStatusMutation,
} = employeeStatusApi

export { useStateGetEmployeeStatusQuery, useStateGetEmployeeStatusesQuery }
