import { PlusOutlined } from '@ant-design/icons'
import useLocalization from '@app/hooks/useLocalization'
import { IFormState } from '@app/interfaces/formState'
import { IUserGroupRoles } from '@app/interfaces/roles'
import { getGroupRoles } from '@app/store/actions/roles-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import './roles-page.less'

interface props {
  _rolesGroups: IUserGroupRoles[]
  state: IFormState
  edit: () => void
}

const RolesPage: React.FC<props> = ({ _rolesGroups }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeUserData } = useLocalization()
  setDocumentTitle(t('users.groupRoles'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_rolesGroups))
      dispatch(getGroupRoles({ source }, t('userRoles.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('userRoles.role'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.sort - b.sort,
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
      sorter: (a, b) => Number(a.status[0]) - Number(b.status[0]),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/manage-users/roles/${record.id}/edit`}>
          {t('general.edit')}
        </Link>
      ),
    },
  ]

  const data = !isEmpty(_rolesGroups)
    ? _rolesGroups.map((role) => {
        return {
          key: role.id,
          id: role.id,
          name: localizeUserData(role.data)?.name,
          status: [role.status],
          sort: role.sorting,
          role: role,
        }
      })
    : []

  return (
    <>
      <Card
        extra={
          <Link
            to={'/manage-users/roles/add'}
            className={'institutions__add ant-btn ant-btn-primary'}
            type={'primary'}>
            <PlusOutlined />
            {t('general.add')}
          </Link>
        }
        title={t('userRoles.title')}>
        <Table
          pagination={false}
          dataSource={data}
          columns={columns}
          rowClassName="table-row"
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _rolesGroups: state.roles.rolesGroups,
  }
}

export default connect(mapStateToProps)(RolesPage)
