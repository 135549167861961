import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import usersRepository from '@app/api/repositories/users-repository'
import { IUserActions } from '@app/interfaces/users'
import {
  CLEAR_USER,
  CLEAR_USERS,
  COUNT_USERS,
  CREATE_USER,
  DELETE_USER,
  FETCH_USERS_CLEANUP,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USER_CLEANUP,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  UPDATE_USER,
} from '@app/store/types/users'
import axios from 'axios'

export const getUser =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchUserRequest())
    await usersRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchUserSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchUserFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchUserCleanup())
  }

export const getUsers =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchUsersRequest())
    await usersRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchUsersSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchUsersFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchUsersCleanup())
  }

export function getCountUsers(countUsers): IUserActions {
  return {
    type: COUNT_USERS,
    payload: countUsers,
  }
}

export function updateUser(user): IUserActions {
  return {
    type: UPDATE_USER,
    payload: user,
  }
}

export function createUser(user): IUserActions {
  return {
    type: CREATE_USER,
    payload: user,
  }
}

export function deleteUser(user): IUserActions {
  return {
    type: DELETE_USER,
    payload: user,
  }
}

export function clearUser(): IUserActions {
  return {
    type: CLEAR_USER,
    payload: {},
  }
}

export function clearUsers(): IUserActions {
  return {
    type: CLEAR_USERS,
    payload: [],
  }
}

const fetchUsersRequest = (): IUserActions => {
  return {
    type: FETCH_USERS_REQUEST,
  }
}

const fetchUsersSuccess = (users, count): IUserActions => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: { users, count },
  }
}

const fetchUsersFailure = (message, code): IUserActions => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: { message, code },
  }
}

const fetchUsersCleanup = (): IUserActions => {
  return {
    type: FETCH_USERS_CLEANUP,
  }
}

const fetchUserRequest = (): IUserActions => {
  return {
    type: FETCH_USER_REQUEST,
  }
}

const fetchUserSuccess = (user): IUserActions => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user,
  }
}

const fetchUserFailure = (message, code): IUserActions => {
  return {
    type: FETCH_USER_FAILURE,
    payload: { message, code },
  }
}

const fetchUserCleanup = (): IUserActions => {
  return {
    type: FETCH_USER_CLEANUP,
  }
}
