import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import institutionSchedulesRepository from '@app/api/repositories/institution-schedules-repository'
import {
  CLEAR_INSTITUTION_SCHEDULE,
  CREATE_INSTITUTION_SCHEDULE,
  DELETE_INSTITUTION_SCHEDULE,
  FETCH_INSTITUTION_SCHEDULES_FAILURE,
  FETCH_INSTITUTION_SCHEDULES_REQUEST,
  FETCH_INSTITUTION_SCHEDULES_SUCCESS,
  FETCH_INSTITUTION_SCHEDULE_FAILURE,
  FETCH_INSTITUTION_SCHEDULE_REQUEST,
  FETCH_INSTITUTION_SCHEDULE_SUCCESS,
  UPDATE_INSTITUTION_SCHEDULE,
} from '@app/store/types/institutions-schedule'
import axios from 'axios'

export const getInstitutionSchedule =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchInstitutionScheduleRequest())
    await institutionSchedulesRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchInstitutionScheduleSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchInstitutionScheduleFailure(error?.message, error?.code))
        }
      })
  }

export const getInstitutionSchedules =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchInstitutionSchedulesRequest())
    await institutionSchedulesRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchInstitutionSchedulesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchInstitutionSchedulesFailure(error?.message, error?.code),
          )
        }
      })
  }

const fetchInstitutionSchedulesRequest = () => {
  return {
    type: FETCH_INSTITUTION_SCHEDULES_REQUEST,
  }
}

const fetchInstitutionSchedulesSuccess = (institutionSchedules, count) => {
  return {
    type: FETCH_INSTITUTION_SCHEDULES_SUCCESS,
    payload: { institutionSchedules, count },
  }
}

const fetchInstitutionSchedulesFailure = (message, code) => {
  return {
    type: FETCH_INSTITUTION_SCHEDULES_FAILURE,
    payload: { message, code },
  }
}

const fetchInstitutionScheduleRequest = () => {
  return {
    type: FETCH_INSTITUTION_SCHEDULE_REQUEST,
  }
}

const fetchInstitutionScheduleSuccess = (institutionSchedule) => {
  return {
    type: FETCH_INSTITUTION_SCHEDULE_SUCCESS,
    payload: institutionSchedule,
  }
}

const fetchInstitutionScheduleFailure = (message, code) => {
  return {
    type: FETCH_INSTITUTION_SCHEDULE_FAILURE,
    payload: { message, code },
  }
}

export function clearInstitutionSchedules() {
  return {
    type: CLEAR_INSTITUTION_SCHEDULE,
    payload: null,
  }
}

export function updateInstitutionSchedule(InstitutionSchedule) {
  return {
    type: UPDATE_INSTITUTION_SCHEDULE,
    payload: InstitutionSchedule,
  }
}

export function createInstitutionSchedule(InstitutionSchedule) {
  return {
    type: CREATE_INSTITUTION_SCHEDULE,
    payload: InstitutionSchedule,
  }
}

export function deleteInstitutionSchedule(InstitutionSchedule) {
  return {
    type: DELETE_INSTITUTION_SCHEDULE,
    payload: InstitutionSchedule,
  }
}
