import useLocalization from '@app/hooks/useLocalization'
import { IGetOneEmployee } from '@app/interfaces/employee/employee'
import { Card, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface params {
  id?: string
  posId?: string
}

interface props {
  employee: IGetOneEmployee
}

const EmployeePositionsInfo: React.FC<props> = ({ employee }) => {
  const [t] = useTranslation()
  const { localizeData } = useLocalization()

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('organization.organization'),
      dataIndex: 'organization',
      key: 'organization',
      render: (text, record) => (
        <Link to={`/organization/${record.organizationId}/page`}>{text}</Link>
      ),
    },
    {
      title: t('employeePosition.employeePosition'),
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: t('employeeRate.employeeRate'),
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: t('employeeStatus.employeeStatus'),
      dataIndex: 'status',
      key: 'status',
    },
  ]

  const data = employee.employeePositions?.map((employeePosition) => {
    return {
      key: employeePosition.id,
      id: employeePosition.id,
      organization: employeePosition.organizationPosition.organizationName,
      organizationId: employeePosition.organizationPosition.organizationId,
      position: localizeData(employeePosition.position.data)?.name,
      rate: localizeData(employeePosition.rate.data)?.name,
      status: localizeData(employeePosition.status.data)?.name,
    }
  })

  return (
    <>
      <Card style={{ marginTop: '20px' }} title={t('employee.positions')}>
        <Table
          pagination={false}
          dataSource={data}
          columns={columns}
          rowClassName="table-row"
        />
      </Card>
    </>
  )
}

export default EmployeePositionsInfo
