import {
  GET_CAMERAS,
  CREATE_CAMERAS,
  UPDATE_CAMERAS,
  FETCH_CAMERAS_CLEANUP,
  FETCH_CAMERAS_FAILURE,
  FETCH_CAMERAS_REQUEST,
  FETCH_CAMERAS_SUCCESS,
} from '@app/store/types/camera'
import { ICameraState } from '@app/interfaces/cameras'
import { IDLE, LOADING, SUCCEEDED, FAILED } from '@app/constants/redux-state'

const initialState: ICameraState = {
  cameras: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const camerasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMERAS:
      return { ...state, cameras: action.payload }
    case CREATE_CAMERAS:
      return { ...state, cameras: [...state.cameras, action.payload] }
    case UPDATE_CAMERAS:
      const updatedCameras = state.cameras.map(function (camera, index) {
        if (action.payload.id === camera.id) {
          return action.payload
        }
        return camera
      })
      return { ...state, cameras: updatedCameras }
    case FETCH_CAMERAS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_CAMERAS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        cameras: action.payload.cameras,
        count: action.payload.count,
      }
    case FETCH_CAMERAS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        cameras: [],
      }
    case FETCH_CAMERAS_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    default:
      return state
  }
}
