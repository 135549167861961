import organizationMenuRepository from '@app/api/repositories/organization-menu-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import { FormType } from '@app/constants/formType'
import useCreateSelector from '@app/hooks/useCreateSelector'
import { IMenuSeasonMenu } from '@app/interfaces/organization-menu'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  getMenus,
  getSeasons,
  toggleMenuRefresh,
} from '@app/store/actions/organization-menus-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuSeasonForm from './components/menu-season-form'
import MenuSeasonInfo from './components/menu-season-info'
import './menu-seasons.less'

const MenuSeasons: React.FC = () => {
  const [t] = useTranslation()
  const { selectors } = useCreateSelector()
  const dispatch = useDispatch()
  const { menus, seasons, formType, refresh } = useSelector(
    selectors.menus.menusState,
  )

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getSeasons({ params: '', source }, t('organizationMenu.errors.seasons')),
    )
    if (isEmpty(menus))
      dispatch(
        getMenus(
          { params: 'page=0&size=100', source },
          t('organizationMenu.errors.menus'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  const addSeason = (
    values: any,
    seasonMenus: IMenuSeasonMenu[],
    cb: () => void,
  ) => {
    const newValues = {
      ...values,
      fromDate: values.fromDate.format('YYYY-MM-DD'),
      toDate: values.toDate.format('YYYY-MM-DD'),
      data: values.data.filter((item) => !isEmpty(item)),
      menus: seasonMenus.map((menu, index) => ({
        menuId: menu.menu.id,
        queue: index + 1,
      })),
    }
    const apiCall =
      formType === FormType.ADD
        ? organizationMenuRepository.addSeason(newValues)
        : organizationMenuRepository.updateSeason(values.id, newValues)
    apiCall
      .then((response) => {
        cb()
        dispatch(toggleMenuRefresh())
        openNotificationWithIcon(
          'success',
          t(
            `organizationMenu.menuSeasons.${
              formType === FormType.EDIT ? 'successEdit' : 'successAdd'
            }`,
          ),
        )
      })
      .catch((error) =>
        openNotificationWithIcon(
          'error',
          t('organizationMenu.menuSeasons.errorAdd'),
        ),
      )
  }

  const permissions = [
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_MENU_ALL_EDIT,
    AUTHORITIES.ROLE_MENU_REGION_EDIT,
    AUTHORITIES.ROLE_MENU_DISTRICT_EDIT,
    AUTHORITIES.ROLE_MENU_CURRENT_EDIT,
  ]

  return (
    <div className="menu-seasons">
      <div className="menu-seasons__header">
        {rolesChecker(permissions) && <MenuSeasonForm addSeason={addSeason} />}
      </div>
      {seasons.map((season) => {
        return (
          <div key={season.id}>
            <MenuSeasonInfo season={season} />
          </div>
        )
      })}
    </div>
  )
}

export default MenuSeasons
