export function sortByName(arr) {
  if (arr && arr.length) {
    return arr.sort((a, b) =>
      a.sorting > b.sorting
        ? -1
        : 1 && a.data?.name?.localeCompare(b.data?.name),
    )
  }
  return arr
}
