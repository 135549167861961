import statsRepository from '@app/api/repositories/stats-repository'
import { ISchedulerHistory } from '@app/interfaces/scheduler/history'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'

interface props {
  refresh: boolean
}

const SchedulerHistory: React.FC<props> = ({ refresh }) => {
  const [history, setHistory] = useState<ISchedulerHistory[]>()
  const [t] = useTranslation()
  const [loaded, setLoaded] = useState<boolean>(false)
  setDocumentTitle(t('scheduler.title'))

  const columns = [
    {
      title: t('scheduler.history.timeConsumed'),
      dataIndex: 'timeConsumed',
      key: 'timeConsumed',
      render: (text, record) => <p>{record.timeConsumed}м.</p>,
    },
    {
      title: t('scheduler.history.startTime'),
      dataIndex: 'executionStartTime',
      key: 'executionStartTime',
    },
    {
      title: t('scheduler.history.endTime'),
      dataIndex: 'executionFinishTime',
      key: 'executionFinishTime',
    },
    {
      title: t('scheduler.history.organizationProcessed'),
      dataIndex: 'organizationProcessed',
      key: 'organizationProcessed',
    },
    {
      title: t('scheduler.history.groupsUpdated'),
      dataIndex: 'groupsUpdated',
      key: 'groupsUpdated',
    },
    {
      title: t('scheduler.history.appsUpdated'),
      dataIndex: 'appsUpdated',
      key: 'appsUpdated',
    },
    {
      title: t('scheduler.history.appExpired'),
      dataIndex: 'appsExpired',
      key: 'appsExpired',
    },
    {
      title: t('scheduler.history.wasSuccessful'),
      dataIndex: 'wasSuccessful',
      key: 'wasSuccessful',
      render: (text, record) => (
        <p>{record.wasSuccessful ? '\u2705' : '\u274C'}</p>
      ),
    },
  ]

  const data =
    history && history.length
      ? history
          .map((history) => {
            const start = new Date(history.executionStartTime)
            const end = new Date(history.executionFinishTime)
            return {
              key: history.id,
              id: history.id,
              timeConsumed: Math.floor(history.timeConsumed / 1000 / 60),
              executionStartTime: start.toLocaleString().substring(0, 17),
              start: history.executionStartTime,
              executionFinishTime: end.toLocaleString().substring(0, 17),
              organizationProcessed: history.organizationsProcessed,
              groupsUpdated: history.groupsUpdated,
              appsUpdated: history.appsUpdated,
              appsExpired: history.appsUpdatedExpired,
              wasSuccessful: history.wasSuccessful,
            }
          })
          .sort((start, end) => end.start - start.start)
      : []

  useEffect(() => {
    const source = axios.CancelToken.source()
    setLoaded(false)
    const getData = async () => {
      await statsRepository
        .getHistory()
        .then((response) => {
          setHistory(response.data)
          setLoaded(true)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            openNotificationWithIcon('error', t('scheduler.error'))
            setLoaded(true)
          }
        })
    }
    getData()
    return function cleanup() {
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        loading={!loaded}
        rowClassName="table-row"
        pagination={{ position: ['bottomRight'] }}
      />
    </div>
  )
}

export default SchedulerHistory
