export const GET_ORGANIZATION_POSITIONS = 'GET_ORGANIZATION_POSITIONS'
export const GET_ORGANIZATION_POSITION = 'GET_ORGANIZATION_POSITION'
export const CREATE_ORGANIZATION_POSITION = 'CREATE_ORGANIZATION_POSITION'
export const CLOSE_ORGANIZATION_POSITION = 'CLOSE_ORGANIZATION_POSITION'
export const CLEAR_ORGANIZATION_POSITIONS = 'CLEAR_ORGANIZATION_POSITIONS'
export const CLEAR_ORGANIZATION_POSITION = 'CLEAR_ORGANIZATION_POSITION'

export const FETCH_ORGANIZATION_POSITIONS_REQUEST =
  'FETCH_ORGANIZATION_POSITIONS_REQUEST'
export const FETCH_ORGANIZATION_POSITIONS_SUCCESS =
  'FETCH_ORGANIZATION_POSITIONS_SUCCESS'
export const FETCH_ORGANIZATION_POSITIONS_FAILURE =
  'FETCH_ORGANIZATION_POSITIONS_FAILURE'
export const FETCH_ORGANIZATION_POSITIONS_CLEANUP =
  'FETCH_ORGANIZATION_POSITIONS_CLEANUP'

export const FETCH_ORGANIZATION_POSITION_REQUEST =
  'FETCH_ORGANIZATION_POSITION_REQUEST'
export const FETCH_ORGANIZATION_POSITION_SUCCESS =
  'FETCH_ORGANIZATION_POSITION_SUCCESS'
export const FETCH_ORGANIZATION_POSITION_FAILURE =
  'FETCH_ORGANIZATION_POSITION_FAILURE'
