import { IPathParamsArgs } from '@app/api/repositories/i-repository'
import publicDictionaries from '@app/api/repositories/public-dictionaries'
import { IPublicDictionariesActions } from '@app/interfaces/public-dictionaries'
import {
  FETCH_PUBLIC_DICTIONARY_FAILURE,
  FETCH_PUBLIC_DICTIONARY_REQUEST,
  FETCH_PUBLIC_DICTIONARY_SUCCESS,
} from '@app/store/types/public-dictionary'
import axios from 'axios'

export const getPublicDictionaries =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchPublicDictionaryRequest())
    await publicDictionaries
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(fetchPublicDictionarySuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchPublicDictionaryFailure(error?.message, error?.code))
        }
      })
  }

const fetchPublicDictionaryRequest = (): IPublicDictionariesActions => {
  return {
    type: FETCH_PUBLIC_DICTIONARY_REQUEST,
  }
}

const fetchPublicDictionarySuccess = (
  allDictionaries,
): IPublicDictionariesActions => {
  return {
    type: FETCH_PUBLIC_DICTIONARY_SUCCESS,
    payload: allDictionaries,
  }
}

const fetchPublicDictionaryFailure = (
  message,
  code,
): IPublicDictionariesActions => {
  return {
    type: FETCH_PUBLIC_DICTIONARY_FAILURE,
    payload: { message, code },
  }
}
