// DOO Types

export const GET_INSTITUTIONS_TYPE = 'GET_INSTITUTIONS_TYPE'
export const GET_INSTITUTIONS_TYPES = 'GET_INSTITUTIONS_TYPES'
export const CREATE_INSTITUTIONS_TYPE = 'CREATE_INSTITUTIONS_TYPE'
export const UPDATE_INSTITUTIONS_TYPE = 'UPDATE_INSTITUTIONS_TYPE'
export const DELETE_INSTITUTIONS_TYPE = 'DELETE_INSTITUTIONS_TYPE'
export const CLEAR_INSTITUTIONS_TYPE = 'CLEAR_INSTITUTIONS_TYPE'

export const FETCH_INSTITUTION_TYPES_REQUEST =
  'FETCH_EDUCATION_INSTITUTION_TYPES_REQUEST'
export const FETCH_INSTITUTION_TYPES_SUCCESS =
  'FETCH_EDUCATION_INSTITUTION_TYPES_SUCCESS'
export const FETCH_INSTITUTION_TYPES_FAILURE =
  'FETCH_EDUCATION_INSTITUTION_TYPES_FAILURE'

export const FETCH_INSTITUTION_TYPE_REQUEST =
  'FETCH_EDUCATION_INSTITUTION_TYPE_REQUEST'
export const FETCH_INSTITUTION_TYPE_SUCCESS =
  'FETCH_EDUCATION_INSTITUTION_TYPE_SUCCESS'
export const FETCH_INSTITUTION_TYPE_FAILURE =
  'FETCH_EDUCATION_INSTITUTION_TYPE_FAILURE'

export const SET_CURRENT_INSTITUTION_TYPE_ID = 'SET_CURRENT_INSTITUTION_TYPE_ID'
export const CLEAR_CURRENT_INSTITUTION_TYPE_ID =
  'CLEAR_CURRENT_INSTITUTION_TYPE_ID'
