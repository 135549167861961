import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { createApi } from '@reduxjs/toolkit/query/react'

export const emptyOrganizationApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'organizationApi',
  tagTypes: [
    ApiKeys.organization,
    ApiKeys.district,
    ApiKeys.region,
    ApiKeys.institutionSchedule,
    ApiKeys.institutionSubType,
    ApiKeys.organizationBuildingType,
    ApiKeys.organizationContact,
    ApiKeys.organizationLocality,
    ApiKeys.organizationPosition,
    ApiKeys.organizationSchedule,
    ApiKeys.organizationSector,
    ApiKeys.organizationsStatus,
    ApiKeys.organizationType,
  ],
  endpoints: () => ({}),
})
