import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { IRegion, IRegionState } from '@app/interfaces/organization/region'
import {
  CLEAR_REGION,
  CREATE_REGION,
  DELETE_REGION,
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_REQUEST,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGION_FAILURE,
  FETCH_REGION_REQUEST,
  FETCH_REGION_SUCCESS,
  UPDATE_REGION,
} from '@app/store/types/regions'

const initialState: IRegionState = {
  regions: [],
  region: {} as IRegion,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGIONS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        regions: action.payload.regions,
        count: action.payload.count,
      }
    case FETCH_REGIONS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        regions: [],
      }
    case FETCH_REGION_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_REGION_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        region: action.payload,
      }
    case FETCH_REGION_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        region: {},
      }
    case CLEAR_REGION:
      return { ...state, regions: [] }
    case CREATE_REGION:
      return { ...state, regions: [...state.regions, action.payload] }
    case UPDATE_REGION:
      const updatedRegions = state.regions.map(function (region) {
        if (action.payload.id === region.id) {
          return action.payload
        }
        return region
      })
      return { ...state, regions: updatedRegions }
    case DELETE_REGION:
      const editedRegions = state.regions.filter(function (region) {
        if (action.payload.id === region.id) {
          return false
        }
        return region
      })
      return { ...state, regions: editedRegions }
    default:
      return state
  }
}
