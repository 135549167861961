import { store } from '@app/store/store'

export function rolesChecker(arr: string[]) {
  const { userInfo } = store.getState()
  let access = false
  arr.forEach((role) => {
    if (userInfo?.userInfo?.authorities?.includes(role)) {
      access = true
    }
  })
  return access
}
