import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function useSuperRoles() {
  const [t, i18n] = useTranslation()
  const [superRoles, setSuperRoles] = useState([
    {
      id: 1,
      name: t('userRoles.superRoles'),
      roles: [
        { id: 1, name: 'ROLE_ADMIN' },
        { id: 1, name: 'ROLE_SUSER' },
      ],
    },
  ])

  return superRoles
}

export default useSuperRoles
