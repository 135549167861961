export const GET_EDUCATION_LANGUAGE = 'GET_EDUCATION_LANGUAGE'
export const GET_EDUCATION_LANGUAGES = 'GET_EDUCATION_LANGUAGES'
export const CREATE_EDUCATION_LANGUAGE = 'CREATE_EDUCATION_LANGUAGE'
export const UPDATE_EDUCATION_LANGUAGE = 'UPDATE_EDUCATION_LANGUAGE'
export const DELETE_EDUCATION_LANGUAGE = 'DELETE_EDUCATION_LANGUAGE'
export const CLEAR_EDUCATION_LANGUAGE = 'CLEAR_EDUCATION_LANGUAGE'

export const FETCH_EDUCATION_LANGUAGES_REQUEST =
  'FETCH_EDUCATION_LANGUAGES_REQUEST'
export const FETCH_EDUCATION_LANGUAGES_SUCCESS =
  'FETCH_EDUCATION_LANGUAGES_SUCCESS'
export const FETCH_EDUCATION_LANGUAGES_FAILURE =
  'FETCH_EDUCATION_LANGUAGES_FAILURE'

export const FETCH_EDUCATION_LANGUAGE_REQUEST =
  'FETCH_EDUCATION_LANGUAGE_REQUEST'
export const FETCH_EDUCATION_LANGUAGE_SUCCESS =
  'FETCH_EDUCATION_LANGUAGE_SUCCESS'
export const FETCH_EDUCATION_LANGUAGE_FAILURE =
  'FETCH_EDUCATION_LANGUAGE_FAILURE'
