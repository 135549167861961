import i18n from '@app/i18n'
import { IData } from '@app/interfaces'
import { ILocationData } from '@app/interfaces/education/education-group-location'
import { IWeeklyScheduleData } from '@app/interfaces/education/education-weekly-schedule'
import { ILang } from '@app/interfaces/languages'
import { IUserRolesData } from '@app/interfaces/roles'
import { getLangId } from '@app/store/actions/language-actions'
import { RootState } from '@app/store/store'
import { useDispatch, useSelector } from 'react-redux'

const idsByCode = {
  ru: 1,
  uz: 2,
  en: 3,
}

const useLocalization = () => {
  const languageId = useSelector((state: RootState) => state.langs.languageId)
  const langs = useSelector((state: RootState) => state.langs.langs)
  const code = langs?.find((lang: { id: any }) => lang.id === languageId)?.code
    ? langs?.find((lang: { id: any }) => lang.id === languageId).code
    : 'ru'
  const dispatch = useDispatch()

  const setLanguage = (code: string | number) => {
    const id = idsByCode[code]
    dispatch(getLangId(id))
  }

  const getFormattedCode = (language?: string) => {
    if (!language) return 'ru'
    if (language === 'ru-RU') return 'ru'
    if (language === 'en-US') return 'en'
    if (language === 'uz-UZ') return 'uz'
    return language
  }

  const localize = (arr: any[]) => {
    if (arr && arr.length) {
      return arr.map((obj) => {
        return {
          ...obj,
          data: obj.data.length
            ? obj.data.find((data: { languageId: any }) => {
                if (data.languageId === languageId) {
                  return data
                }
                return false
              }) || obj.data[0]
            : obj.data[0],
        }
      })
    }
    return arr
  }

  const localizeItem = (object: { data: any[] }) => {
    return {
      ...object,
      data: object.data.length
        ? object.data.find((data) => {
            if (data.languageId === languageId) {
              return data
            }
            return false
          }) || object.data[0]
        : object.data[0],
    }
  }

  const localizeData = (data: IData[] | undefined): IData | undefined => {
    return data?.find((item) => item.languageId === languageId)
  }

  const localizeLocationData = (
    data: ILocationData[] | undefined,
  ): ILocationData | undefined => {
    return data?.find((item) => item.languageId === languageId)
  }

  const localizeWeeklyData = (
    data: IWeeklyScheduleData[] | undefined,
  ): IWeeklyScheduleData | undefined => {
    return data?.find((item) => item.languageId === languageId)
  }

  const localizeUserData = (
    data: IUserRolesData[] | undefined,
  ): IUserRolesData | undefined => {
    return data?.find((item) => item.languageId === languageId)
  }

  const localizeGroupRoles = (arr: any[]) => {
    if (arr && arr.length) {
      return arr.map((obj) => {
        return {
          id: obj.id,
          sorting: obj.sorting,
          status: obj.status,
          roles: obj.roles,
          data:
            obj.data.find((data: { languageId: any }) => {
              if (data.languageId === languageId) {
                return data
              }
              return false
            }) || obj.data[0],
        }
      })
    }
    return arr
  }

  const localizeStrapi = (array: any[]) => {
    return array.filter((item) => item.attributes.locale === code)
  }

  const findLanguage = (languages: ILang[] | undefined): ILang | undefined => {
    return languages?.find(function (lang) {
      if (lang.code === i18n.language) {
        return lang.id
      }
      return false
    })
  }
  return {
    localize,
    localizeGroupRoles,
    localizeData,
    localizeUserData,
    localizeItem,
    localizeStrapi,
    localizeLocationData,
    localizeWeeklyData,
    findLanguage,
    getFormattedCode,
    setLanguage,
    languageId,
    code,
  }
}

export default useLocalization
