import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  closePosition: (id: number) => any
  id: number
}

const ClosePositionModal: React.FC<props> = ({ closePosition, id }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [t] = useTranslation()

  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  return (
    <React.Fragment>
      <Button className={``} onClick={showModal} type={'text'}>
        {t('employeePosition.closePosition')}
      </Button>
      <Modal
        open={visible}
        title={t('employeePosition.closePosition')}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <Button type={'primary'} onClick={() => closePosition(id)}>
              {t('employeePosition.acceptBtn')}
            </Button>
            <Button
              type={'default'}
              onClick={hideModal}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Button>
          </React.Fragment>,
        ]}
      >
        <h2>{t('employeePosition.accept')}</h2>
      </Modal>
    </React.Fragment>
  )
}

export default ClosePositionModal
