import React from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import useLocalization from '@app/hooks/useLocalization'
import { IUserGroupRoles } from '@app/interfaces/roles'

interface group {
  id: number
  name: string
}

interface props {
  groupRoles: IUserGroupRoles[]
}

const GroupRolesItem: React.FC<props> = ({ groupRoles }) => {
  const [t] = useTranslation()
  const { localizeGroupRoles } = useLocalization()
  return (
    <Form.Item name={'groupRoles'} label={t('users.groupRoles')}>
      <Select allowClear={true}>
        {localizeGroupRoles(groupRoles).map((groupRole, index) => {
          return (
            <Select.Option key={index} value={groupRole.id!.toString()}>
              {groupRole?.data?.name}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default GroupRolesItem
