import { FormType } from '@app/constants/formType'
import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IMeals,
  IMealType,
  IMeasurement,
  IMenu,
  IMenuSeason,
  IOrganizationMenuState,
  IProducts,
} from '@app/interfaces/organization-menu'
import {
  CLEAR_MENU_BY_PERIOD,
  CLEAR_MENU_BY_PUPIL,
  CLEAR_PRODUCTS,
  FETCH_MENUS_FAIL,
  FETCH_MENUS_FULFILLED,
  FETCH_MENUS_REQUEST,
  FETCH_MENU_BY_PERIOD_FAIL,
  FETCH_MENU_BY_PERIOD_FULFILLED,
  FETCH_MENU_BY_PERIOD_REQUEST,
  FETCH_MENU_BY_PUPIL_EXTRA_FULFILLED,
  FETCH_MENU_BY_PUPIL_FAIL,
  FETCH_MENU_BY_PUPIL_FULFILLED,
  FETCH_MENU_BY_PUPIL_REQUEST,
  FETCH_MENU_CLEANUP,
  FETCH_MENU_FAIL,
  FETCH_MENU_FULFILLED,
  FETCH_MENU_MEALS_FAIL,
  FETCH_MENU_MEALS_FULFILLED,
  FETCH_MENU_MEALS_REQUEST,
  FETCH_MENU_MEAL_FAIL,
  FETCH_MENU_MEAL_FULFILLED,
  FETCH_MENU_MEAL_REQUEST,
  FETCH_MENU_MEAL_TYPES_FAIL,
  FETCH_MENU_MEAL_TYPES_FULFILLED,
  FETCH_MENU_MEAL_TYPES_REQUEST,
  FETCH_MENU_MEAL_TYPE_FAIL,
  FETCH_MENU_MEAL_TYPE_FULFILLED,
  FETCH_MENU_MEAL_TYPE_REQUEST,
  FETCH_MENU_MEASUREMENTS_FAIL,
  FETCH_MENU_MEASUREMENTS_REQUEST,
  FETCH_MENU_MEASUREMENTS_SUCCESS,
  FETCH_MENU_MEASUREMENT_FAIL,
  FETCH_MENU_MEASUREMENT_FULFILLED,
  FETCH_MENU_MEASUREMENT_REQUEST,
  FETCH_MENU_PRODUCTS_FAIL,
  FETCH_MENU_PRODUCTS_FULFILLED,
  FETCH_MENU_PRODUCTS_REQUEST,
  FETCH_MENU_PRODUCT_FAIL,
  FETCH_MENU_PRODUCT_FULFILLED,
  FETCH_MENU_PRODUCT_REQUEST,
  FETCH_MENU_REQUEST,
  FETCH_SEASONS_FAIL,
  FETCH_SEASONS_FULFILLED,
  FETCH_SEASONS_REQUEST,
  FETCH_SEASON_FAIL,
  FETCH_SEASON_FULFILLED,
  FETCH_SEASON_REQUEST,
  GET_TODAYS_ORGANIZATION_MENUS,
  REMOVE_MENU_FAIL,
  REMOVE_MENU_FULFILLED,
  REMOVE_MENU_MEAL_FAIL,
  REMOVE_MENU_MEAL_FULFILLED,
  REMOVE_MENU_MEAL_REQUEST,
  REMOVE_MENU_MEAL_TYPE_FAIL,
  REMOVE_MENU_MEAL_TYPE_FULFILLED,
  REMOVE_MENU_MEAL_TYPE_REQUEST,
  REMOVE_MENU_MEASUREMENT_FAIL,
  REMOVE_MENU_MEASUREMENT_FULFILLED,
  REMOVE_MENU_MEASUREMENT_REQUEST,
  REMOVE_MENU_PRODUCT_FAIL,
  REMOVE_MENU_PRODUCT_FULFILLED,
  REMOVE_MENU_PRODUCT_REQUEST,
  REMOVE_MENU_REQUEST,
  TOGGLE_MENU_FORM_TYPE,
  TOGGLE_MENU_REFRESH,
} from '../types/organization-menus'

const initialState: IOrganizationMenuState = {
  menuByPeriod: [],
  menuByPupil: [],
  extraMenu: [],
  seasons: [],
  season: {} as IMenuSeason,
  menus: [],
  menu: {} as IMenu,
  mealTypes: [],
  mealType: {} as IMealType,
  meals: [],
  meal: {} as IMeals,
  products: [],
  product: {} as IProducts,
  measurements: [],
  measurement: {} as IMeasurement,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  menusCount: '',
  mealTypesCount: '',
  mealsCount: '',
  productsCount: '',
  measurementsCount: '',
  seasonsCount: '',
  formType: FormType.NONE,
}

export const organizationMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TODAYS_ORGANIZATION_MENUS:
      return { ...state, organization_menus: action.payload }
    case FETCH_MENU_BY_PERIOD_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_BY_PERIOD_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        menuByPeriod: action.payload.menuByPeriod,
        count: action.payload.count,
      }
    case FETCH_MENU_BY_PERIOD_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        menuByPeriod: [],
      }
    case FETCH_MENU_BY_PUPIL_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_BY_PUPIL_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        menuByPupil: action.payload.menuByPupil,
        count: action.payload.count,
      }
    case FETCH_MENU_BY_PUPIL_EXTRA_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        extraMenu: action.payload,
      }
    case FETCH_MENU_BY_PUPIL_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        menuByPupil: [],
      }
    case FETCH_SEASONS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_SEASONS_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        seasons: action.payload.seasons,
        count: action.payload.count,
      }
    case FETCH_SEASONS_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        seasons: [],
      }
    case FETCH_SEASON_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_SEASON_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        season: action.payload.season,
        formType: FormType.EDIT,
      }
    case FETCH_SEASON_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        season: {},
      }
    case FETCH_MENU_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        menu: action.payload.menu,
        formType: FormType.EDIT,
      }
    case FETCH_MENU_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        menu: {},
      }
    case FETCH_MENUS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENUS_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        menus: action.payload.menus,
        menusCount: action.payload.count,
      }
    case FETCH_MENUS_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        menus: [],
      }
    case FETCH_MENU_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        menu: action.payload.menu,
        formType: FormType.EDIT,
      }
    case FETCH_MENU_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        menu: {},
      }
    case REMOVE_MENU_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case REMOVE_MENU_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        menu: {},
        refresh: !state.refresh,
      }
    case REMOVE_MENU_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        menu: {},
      }
    case FETCH_MENU_MEAL_TYPES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_MEAL_TYPES_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        mealTypes: action.payload.mealTypes,
        MealTypesCount: action.payload.count,
      }
    case FETCH_MENU_MEAL_TYPES_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        mealTypes: [],
      }
    case FETCH_MENU_MEAL_TYPE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_MEAL_TYPE_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        mealType: action.payload.mealType,
        formType: FormType.EDIT,
      }
    case FETCH_MENU_MEAL_TYPE_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        mealType: {},
      }
    case REMOVE_MENU_MEAL_TYPE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case REMOVE_MENU_MEAL_TYPE_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        mealType: {},
        refresh: !state.refresh,
      }
    case REMOVE_MENU_MEAL_TYPE_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        mealType: {},
      }
    case FETCH_MENU_MEALS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_MEALS_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        meals: action.payload.meals,
        mealsCount: action.payload.count,
      }
    case FETCH_MENU_MEALS_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        meals: [],
      }
    case FETCH_MENU_MEAL_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_MEAL_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        meal: action.payload.meal,
        formType: FormType.EDIT,
      }
    case FETCH_MENU_MEAL_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        meal: {},
      }
    case REMOVE_MENU_MEAL_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case REMOVE_MENU_MEAL_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        meal: {},
        refresh: !state.refresh,
      }
    case REMOVE_MENU_MEAL_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        meal: {},
      }
    case FETCH_MENU_PRODUCTS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_PRODUCTS_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        products: action.payload.products,
        productsCount: action.payload.count,
      }
    case FETCH_MENU_PRODUCTS_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        products: [],
      }
    case FETCH_MENU_PRODUCT_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_PRODUCT_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        product: action.payload.product,
        formType: FormType.EDIT,
      }
    case FETCH_MENU_PRODUCT_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        product: {},
      }
    case REMOVE_MENU_PRODUCT_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case REMOVE_MENU_PRODUCT_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        product: {},
        refresh: !state.refresh,
      }
    case REMOVE_MENU_PRODUCT_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        product: {},
      }
    case FETCH_MENU_MEASUREMENTS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        measurements: action.payload.measurements,
        measurementsCount: action.payload.count,
      }
    case FETCH_MENU_MEASUREMENTS_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        measurements: [],
      }
    case FETCH_MENU_MEASUREMENT_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_MENU_MEASUREMENT_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        measurement: action.payload.measurement,
        formType: FormType.EDIT,
      }
    case FETCH_MENU_MEASUREMENT_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        measurement: {},
      }
    case REMOVE_MENU_MEASUREMENT_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case REMOVE_MENU_MEASUREMENT_FULFILLED:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        measurement: {},
        refresh: !state.refresh,
      }
    case REMOVE_MENU_MEASUREMENT_FAIL:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        measurement: {},
      }
    case FETCH_MENU_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case TOGGLE_MENU_FORM_TYPE:
      if (action.payload === FormType.NONE)
        return {
          ...state,
          formType: FormType.NONE,
          product: {},
          meal: {},
          measurement: {},
          menu: {},
          season: {},
        }
      return {
        ...state,
        formType: action.payload,
      }
    case TOGGLE_MENU_REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      }
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: [],
      }
    case CLEAR_MENU_BY_PERIOD:
      return {
        ...state,
        menuByPeriod: [],
      }
    case CLEAR_MENU_BY_PUPIL:
      return {
        ...state,
        menuByPupil: [],
      }
    default:
      return state
  }
}
