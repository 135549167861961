import { ApiKeys } from '@app/constants/api-keys'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Select } from 'antd'
import { isEmpty } from 'lodash'
import { useMemo, useState } from 'react'

const useRegionDestricts = () => {
  const [regionId, setRegionId] = useState<number>(0)
  const [districtId, setDistrictId] = useState<number>(0)
  const { localize } = useLocalization()
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts } = useCache<IDistrict>(ApiKeys.district)
  const getRegionName = (regionId: number) => {
    return localize(regions)?.find(
      (region: { id: any }) => region.id === regionId,
    )?.data.name
  }

  const _regions = useMemo(() => {
    if (isEmpty(regions)) return null
    return sortByName(localize(regions))?.map(({ data: { name }, id }) => {
      return (
        <Select.Option key={id} value={id}>
          {name}
        </Select.Option>
      )
    })
  }, [regions, localize])

  const _districts = useMemo(() => {
    if (isEmpty(districts)) return null
    return localize(districts)
      ?.filter(
        (district: { regionId; data: { name } }) =>
          district.regionId === regionId &&
          district.data.name !== getRegionName(regionId),
      )
      .map((district: { id: React.Key | null | undefined; data }) => {
        return (
          <Select.Option key={district.id} value={district.id}>
            {district.data.name}
          </Select.Option>
        )
      })
  }, [regionId, districts, localize])

  return {
    regionId,
    districtId,
    setRegionId,
    setDistrictId,
    Region: (
      <Select showSearch={true} onChange={(value) => setRegionId(value)}>
        {_regions}
      </Select>
    ),
    District: (
      <Select
        showSearch={true}
        onChange={(value) => setDistrictId(value)}
        disabled={!regionId}>
        {_districts}
      </Select>
    ),
  }
}

export default useRegionDestricts
