import useCreateSelector from '@app/hooks/useCreateSelector'
import { IOrganizationGrowthBulkPupil } from '@app/interfaces/organization-growth'
import Loader from '@app/shared/loader/loader'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { useCreateOrganizationGrowthPupilMutation } from '@app/store/rtk/api/organization-growth-api'
import { toggleEditModal } from '@app/store/slices/organization-growth-slice'
import { Button, Form, Input, InputNumber, Modal } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

interface props {
  pupil?: IOrganizationGrowthBulkPupil
}

const OrganizationGrowthEditForm: React.FC<props> = ({ pupil }) => {
  const { selectors } = useCreateSelector()
  const { editModal } = useSelector(selectors.growth.growthState)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const [updatePupil, { isLoading }] =
    useCreateOrganizationGrowthPupilMutation()
  const hideModal = () => {
    dispatch(toggleEditModal(false))
  }
  useEffect(() => {
    if (pupil)
      form.setFieldsValue({
        pupilId: pupil.pupilId,
        weight: pupil.pupilBodyInformation.weight,
        height: pupil.pupilBodyInformation.height,
      })
  }, [pupil])
  const onFinish = (values) => {
    if (!values.pupilId) {
      openNotificationWithIcon('error', t('growth.errors.add'))
      return
    }
    updatePupil({
      pupilId: values.pupilId,
      height: values.height,
      weight: values.weight,
    })
      .unwrap()
      .then(() => {
        openNotificationWithIcon('success', t('growth.successAdd'))
        hideModal()
      })
  }

  return (
    <Modal
      open={editModal}
      getContainer={false}
      onCancel={hideModal}
      title={t('growth.modalTitle')}
      footer={
        isLoading ? (
          <Loader />
        ) : (
          [
            <React.Fragment key={1}>
              <Button type={'primary'} onClick={form.submit}>
                {t('general.accept')}
              </Button>
              <Button
                type={'default'}
                onClick={hideModal}
                style={{ marginLeft: '10px' }}
              >
                {t('general.cancel')}
              </Button>
            </React.Fragment>,
          ]
        )
      }
    >
      <p style={{ marginBottom: '24px' }}>{pupil?.fullName}</p>
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="pupilId" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="height"
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
            {
              type: 'number',
              min: 0,
              max: 200,
              message: t('errors.minMaxMessage', { min: 0, max: 200 }),
            },
          ]}
          label={t('growth.height')}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="weight"
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
            {
              type: 'number',
              min: 0,
              max: 200,
              message: t('errors.minMaxMessage', { min: 0, max: 200 }),
            },
          ]}
          label={t('growth.weight')}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OrganizationGrowthEditForm
