import EducationLanguageRepository from '@api/repositories/education-language-repository'
import { IEducationLanguage } from '@app/interfaces/education/education-language'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  createEducationLanguages,
  updateEducationLanguages,
} from '@app/store/actions/education-language-actions'
import { Button, Form, Input, InputNumber, Modal, Spin, Switch } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  educationLanguage?: IEducationLanguage
  edit?: boolean
}

const EducationLangaugeForm: React.FC<props> = ({
  educationLanguage,
  edit,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues: IEducationLanguage = {
      id: values.id,
      sorting: values.sorting,
      status: values.status,
      data: languages,
    }
    setConfirmLoading(true)
    const employeeEducationSubmitApi = educationLanguage
      ? EducationLanguageRepository.update
      : EducationLanguageRepository.create

    await employeeEducationSubmitApi(newValues)
      .then((response: AxiosResponse<IEducationLanguage>) => {
        if (educationLanguage && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateEducationLanguages(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!educationLanguage && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createEducationLanguages(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('educationLanguage.edit') : t('educationLanguage.add')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: educationLanguage?.id,
              sorting: educationLanguage?.sorting || 0,
              status: edit ? educationLanguage?.status : true,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={educationLanguage?.status === true}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -2147483648,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <FormList
              confirmLoading={confirmLoading}
              object={educationLanguage}
            />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default EducationLangaugeForm
