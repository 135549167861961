import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IInstitutionSchedule } from '@app/interfaces/organization/institution-schedule'
import InstitutionSchedulesForm from '@app/pages/reference-book-page-details/institution-schedule/components/institution-schedule-form/institution-schedule-form'
import { getInstitutionSchedules } from '@app/store/actions/institution-schedules-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _institutionSchedules: IInstitutionSchedule[]
  _state: string
}

const ReferenceBookInstitutionSchedules: React.FC<props> = ({
  _institutionSchedules,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('institutionSchedule.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_institutionSchedules))
      dispatch(
        getInstitutionSchedules({ source }, t('institutionSchedule.error')),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('institutionSchedule.institutionSchedule'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <InstitutionSchedulesForm
            edit={true}
            institutionSchedule={record.institutionSchedule}
          />
        ) : null,
    },
  ]

  const data = !isEmpty(_institutionSchedules)
    ? _institutionSchedules.map((institutionSchedule) => {
        return {
          key: institutionSchedule.id,
          id: institutionSchedule.id,
          name: localizeData(institutionSchedule?.data)?.name,
          status: [institutionSchedule.status],
          institutionSchedule: institutionSchedule,
        }
      })
    : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <InstitutionSchedulesForm />
          ) : null
        }
        title={t('institutionSchedule.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _institutionSchedules: state.institutionSchedules.institutionSchedules,
    _state: state.institutionSchedules.state,
  }
}

export default connect(mapStateToProps)(ReferenceBookInstitutionSchedules)
