import { IOrganizationAttendanceActions } from '@app/interfaces/organization-attendance'
import {
  CLEAR_ORGANIZATION_ATTENDANCE_EMPLOYEE,
  CLEAR_ORGANIZATION_ATTENDANCE_PUPIL,
  GET_ORGANIZATION_ATTENDANCE,
  GET_ORGANIZATION_ATTENDANCE_BY_PUPIL,
} from '@app/store/types/organization-attendance'

export function getOrganizationAttendance(
  organizationAttendanceEmployee,
): IOrganizationAttendanceActions {
  return {
    type: GET_ORGANIZATION_ATTENDANCE,
    payload: organizationAttendanceEmployee,
  }
}

export function getOrganizationAttendanceByPupil(
  organizationAttendancePupil,
): IOrganizationAttendanceActions {
  return {
    type: GET_ORGANIZATION_ATTENDANCE_BY_PUPIL,
    payload: organizationAttendancePupil,
  }
}

export const clearOrganizationAttendanceEmployee =
  (): IOrganizationAttendanceActions => {
    return {
      type: CLEAR_ORGANIZATION_ATTENDANCE_EMPLOYEE,
    }
  }

export const clearOrganizationAttendancePupil =
  (): IOrganizationAttendanceActions => {
    return {
      type: CLEAR_ORGANIZATION_ATTENDANCE_PUPIL,
    }
  }
