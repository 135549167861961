import {
  AppstoreOutlined,
  BarChartOutlined,
  BulbOutlined,
  CarryOutOutlined,
  CheckSquareOutlined,
  ClusterOutlined,
  CoffeeOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  FilePptOutlined,
  FormOutlined,
  GroupOutlined,
  HddOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  ReadOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SendOutlined,
  SolutionOutlined,
  SplitCellsOutlined,
  TeamOutlined,
  UploadOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { IPathParams } from '@app/constants/path-params'
import useCreateSelector from '@app/hooks/useCreateSelector'
import { ROUTE_NAMES, routes } from '@app/routes'
import { useStateGetOrganizationQuery } from '@app/store/rtk/api/organization/organization-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Button, Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import './layout-menu.less'

const ORGANIZATIONS = 'organizations'

const { SubMenu } = Menu
interface IMenuItem {
  path: string
  title: string
  roles: string[]
  icon?: any
  children?: IMenuItem[]
}

const LayoutMenu: React.FC = () => {
  const [t] = useTranslation()
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [id, setId] = useState<string>('')
  const location = useLocation()
  const navigate = useNavigate()
  const params: IPathParams = useParams()
  const { selectors } = useCreateSelector()
  const userInfo = useSelector(selectors.userInfo.userInfo)
  const { data: organization } = useStateGetOrganizationQuery(Number(id), {
    skip: !id,
  })

  useEffect(() => {
    params?.id && setId(params.id)
  }, [params?.id])

  useEffect(() => {
    setSelectedKeys([location.pathname])
    if (params?.id) {
      setOpenKeys([...openKeys, ORGANIZATIONS])
      return
    }
    setOpenKeys([...openKeys.filter((value) => value !== ORGANIZATIONS)])
  }, [params?.id])

  const menuItems: IMenuItem[] = useMemo(
    () => [
      {
        path: routes.organizations.path,
        title: t('organization.title'),
        roles: routes.organizations.roles,
        icon: <HomeOutlined />,
        children: !isEmpty(organization)
          ? [
              {
                path: `/organization/${organization?.id}/indicators`,
                title: t('organization.indicators'),
                roles: routes.organization.children!.indicators.roles,
                icon: <AppstoreOutlined />,
              },
              {
                path: `/organization/${organization?.id}/page`,
                title: t('organization.info'),
                roles: routes.organization.children!.organization_view.roles,
                icon: <InfoCircleOutlined />,
              },
              {
                path: `/organization/${organization?.id}/contacts`,
                title: t('organizationContacts.title'),
                roles:
                  routes.organization.children!.organization_contacts.roles,
                icon: <EnvironmentOutlined />,
              },
              {
                path: `/organization/${organization?.id}/passport`,
                title: t('institutionPassport.title'),
                roles: routes.organization.children!.institution_passport.roles,
                icon: <FilePptOutlined />,
              },
              {
                path: `/organization/${organization?.id}/passportization`,
                title: t('passportization.title'),
                roles:
                  routes.organization.children!.organization_passportization
                    .roles,
                icon: <FilePptOutlined />,
              },
              {
                path: `/organization/${organization?.id}/workforce`,
                title: t('employeeWorkRateList.title'),
                roles:
                  routes.organization.children!.organization_workforce.roles,
                icon: <BulbOutlined />,
              },
              {
                path: `/organization/${organization?.id}/employees`,
                title: t('employee.title'),
                roles:
                  routes.organization.children!.organization_employees.roles,
                icon: <UserOutlined />,
              },
              {
                path: `/organization/${organization?.id}/employees-positions`,
                title: t('employeePosition.title'),
                roles:
                  routes.organization.children!.organization_employees_positions
                    .roles,
                icon: <UserSwitchOutlined />,
              },
              {
                path: `/organization/${organization?.id}/groups`,
                title: t('educationGroup.title'),
                roles: routes.organization.children!.organization_groups.roles,
                icon: <SplitCellsOutlined />,
              },
              {
                path: `/organization/${organization?.id}/pupils`,
                title: t('pupils.title'),
                roles: routes.organization.children!.organization_pupils.roles,
                icon: <SolutionOutlined />,
              },
              {
                path: `/organization/${organization?.id}/pupils-queue`,
                title: t('pupils.accept'),
                roles:
                  routes.organization.children!.organization_pupils_queue.roles,
                icon: <ScheduleOutlined />,
              },
              {
                path: `/organization/${organization?.id}/queue-list`,
                title: t('routes.pupilQueueList'),
                roles:
                  routes.organization.children!.organization_queue_list.roles,
                icon: <ContactsOutlined />,
              },
              // {
              //   path: `/organizations/organization/${organization?.id}/contracts`,
              //   title: t("contracts.title"),
              //   isHasNotId: !organization?.id,
              //   roles: routes.organization_contracts.roles,
              //   icon: <ProfileOutlined />,
              // },
              // {
              //   path: `/organization/${organization?.id}/rooms`,
              //   title: t('routes.rooms'),
              //   roles: routes.organization.children!.organization_rooms.roles,
              //   icon: <BorderOutlined />,
              // },
              // {
              {
                path: `/organization/${organization?.id}/cameras`,
                title: t('cameras.title'),
                roles: routes.organization.children!.organization_cameras.roles,
                icon: <VideoCameraOutlined />,
              },
              {
                path: `/organization/${organization?.id}/${ROUTE_NAMES.child_stats}`,
                title: t('routes.childStats'),
                roles: routes.organization.children.child_stats.roles,
                icon: <HddOutlined />,
              },
              {
                path: `/organization/${organization?.id}/contract-subsidy`,
                title: t('routes.contractSubsidy'),
                roles:
                  routes.organization.children!.organization_contract_subsidy
                    .roles,
                icon: <FormOutlined />,
              },
              {
                path: `/organization/${organization?.id}/attendance`,
                title: t('organization.attendance'),
                roles:
                  routes.organization.children!.organization_attendance.roles,
                icon: <CheckSquareOutlined />,
              },
              {
                path: `/organization/${organization?.id}/timesheet`,
                title: t('organization.timesheet'),
                roles:
                  routes.organization.children!.organization_timesheet.roles,
                icon: <CarryOutOutlined />,
              },
              {
                path: `organization/${organization?.id}/menu-calendar`,
                title: t('organizationMenu.calendar.title'),
                roles:
                  routes.organization.children!.organization_menu_calendar
                    .roles,
                icon: <CoffeeOutlined />,
              },
              {
                path: `organization/${organization?.id}/warehouse`,
                title: t('organizationWarehouse.title'),
                roles:
                  routes.organization.children!.organization_warehouse.roles,
                icon: <DatabaseOutlined />,
              },
            ]
          : undefined,
      },
      {
        path: routes.workforce.path,
        title: t('employeeWorkRateList.title'),
        roles: routes.workforce.roles,
        icon: <BulbOutlined />,
      },
      {
        path: routes.monitoring.path,
        title: t('organization.monitoring'),
        roles: routes.monitoring.roles,
        icon: <CheckSquareOutlined />,
      },
      {
        path: routes.growth_statistics.path,
        title: t('routes.growthStatistics'),
        roles: routes.growth_statistics.roles,
        icon: <BarChartOutlined />,
      },
      {
        path: routes.furniture.path,
        title: t('routes.furniture'),
        roles: routes.furniture.roles,
        icon: <GroupOutlined />,
      },
      {
        path: routes.pupils_search.path,
        title: t('routes.pupilSearch'),
        roles: routes.pupils_search.roles,
        icon: <SearchOutlined />,
      },
      {
        path: routes.pupils.path,
        title: t('routes.pupils'),
        roles: routes.pupils.roles,
        icon: <SolutionOutlined />,
      },
      {
        path: routes.reports.path,
        title: t('reports.title'),
        roles: routes.reports.roles,
        icon: <FileDoneOutlined />,
      },
      {
        path: routes.cameras_page.path,
        title: t('cameras.title'),
        roles: routes.cameras_page.roles,
        icon: <VideoCameraOutlined />,
      },
      {
        path: routes.warehouse.path,
        title: t('organizationWarehouse.title'),
        roles: routes.warehouse.roles,
        icon: <DatabaseOutlined />,
      },
      {
        path: `menu`,
        title: t('routes.menu'),
        roles: routes.menu.roles,
        icon: <CoffeeOutlined />,
        children: [
          {
            path: `menu/menu-organizer`,
            title: t('organizationMenu.organizer'),
            roles: routes.menu_organizer.roles,
            icon: <EditOutlined />,
          },
          {
            path: `menu/menu-calendar`,
            title: t('organizationMenu.calendar.title'),
            roles: routes.menu_calendar.roles,
            icon: <ProfileOutlined />,
          },
        ],
      },
      {
        path: routes.reference_book.path,
        title: t('routes.references'),
        roles: routes.reference_book.roles,
        icon: <ReadOutlined />,
      },
      {
        path: routes.manage_users.path,
        title: t('routes.manage-users'),
        roles: routes.manage_users.roles,
        icon: <UsergroupAddOutlined />,
        children: [
          {
            path: routes.users_roles.path,
            title: t('users.groupRoles'),
            roles: routes.users_roles.roles,
            icon: <ClusterOutlined />,
          },
          {
            path: routes.users.path,
            title: t('users.title'),
            roles: routes.users.roles,
            icon: <TeamOutlined />,
          },
        ],
      },
      {
        path: routes.admin.path,
        title: t('routes.admin'),
        roles: routes.admin.roles,
        icon: <LockOutlined />,
        children: [
          {
            path: routes.pupil_reject.path,
            title: t('routes.rejectPupil'),
            roles: routes.pupil_reject.roles,
            icon: <UserDeleteOutlined />,
          },
          {
            path: routes.scheduler.path,
            title: t('routes.scheduler'),
            roles: routes.scheduler.roles,
            icon: <ScheduleOutlined />,
          },
        ],
      },
    ],
    [organization, t],
  )
  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev)
  }

  const onSubmenuClicked = ({ key }) => {
    if (key === ORGANIZATIONS) navigate('/organizations')
  }

  const onSubmenuOpenedHandler = (keys) => {
    setOpenKeys(keys)
  }

  const onMenuItemClickedHandler = ({ key }) => {
    setSelectedKeys([key])
  }

  const mapMenu = (menuItems: IMenuItem[]) => {
    return menuItems.map(({ title, path, children, roles, icon }) => {
      if (rolesChecker(roles)) {
        if (children)
          return (
            <SubMenu
              key={path}
              title={title}
              icon={icon}
              onTitleClick={onSubmenuClicked}>
              {children && mapMenu(children)}
            </SubMenu>
          )
        return (
          <Menu.Item icon={icon} key={path}>
            <Link to={path}>{title}</Link>
          </Menu.Item>
        )
      }
    })
  }

  return (
    <Sider width={300} trigger={null} collapsible collapsed={collapsed}>
      <Button type="default" onClick={toggleCollapsed} className="menu-button">
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        theme="dark"
        mode="inline"
        onOpenChange={onSubmenuOpenedHandler}
        onSelect={onMenuItemClickedHandler}
        openKeys={openKeys}
        selectedKeys={selectedKeys}>
        {userInfo && mapMenu(menuItems)}
      </Menu>
      <div className="version">
        <p>{`${new Date().getFullYear()}© BOLALAR BOG'CHASI`}</p>
        <small>Build: {process.env.REACT_APP_ACTUAL_VERSION}</small>
      </div>
    </Sider>
  )
}

export default LayoutMenu
