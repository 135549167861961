import { getPupil } from '@api/rest/registry-office/get-pupil'
import { ApiKeys } from '@app/constants/api-keys'
import { metricSerials } from '@app/constants/metricSerial'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import { IRegistryPupil } from '@app/interfaces/pupil/pupils'
import StepsButtons from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/steps-buttons'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { removeFile } from '@app/store/actions/files-actions'
import {
  getMetricNumber,
  getMetricSerial,
  getPupilBirthdate,
} from '@app/store/actions/pupil-manual-add-actions'
import { getRegistryPupils } from '@app/store/actions/pupils-actions'
import { getPupilAge } from '@app/utils/get-pupil-age/get-pupil-age'
import { DatePicker, Form, Input, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  current: number
  next: () => void
  prev: () => void
  metricNumber: string
  metricSerial: string
  pupilBirthday: string
  setConfirmLoading: (data: boolean) => void
  loading: boolean
}

const FindPupilForm: React.FC<props> = ({
  current,
  prev,
  next,
  metricNumber,
  metricSerial,
  pupilBirthday,
  setConfirmLoading,
  loading,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { localize } = useLocalization()
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts } = useCache<IDistrict>(ApiKeys.district)

  const onFinish = (values: any) => {
    dispatch(getMetricSerial(values.metricSerial))
    dispatch(getMetricNumber(values.metricNumber))
    dispatch(getPupilBirthdate(values['birthDate'].format(dateFormat)))
    const newValues = {
      ...values,
      birthDate: values['birthDate'].format(dateFormat),
    }
    setConfirmLoading(true)
    getPupil(newValues)
      .then((response: AxiosResponse<IRegistryPupil>) => {
        if (response.data.study) {
          const organization = response.data.organization
          openNotificationWithIcon(
            'error',
            `${t('pupils.alreadyStudied')} ${
              response.data.pupilEducation?.educationGroup?.name
            }, ${t('pupils.alreadyStudiedOrg')} ${organization?.name} ${t(
              'pupils.alreadyStudiedRegion',
            )} ${
              localize(regions)?.find(
                (region) => region?.id === organization?.region.id,
              )?.data.name
            } ${t('pupils.alreadyStudiedDistrict')} ${
              localize(districts)?.find(
                (district) => district?.id === organization?.district.id,
              )?.data.name
            }`,
          )
          setConfirmLoading(false)
        } else {
          dispatch(removeFile())
          dispatch(getRegistryPupils(response.data))
          setConfirmLoading(false)
          next()
        }
      })
      .catch(() => {
        setConfirmLoading(false)
      })
  }
  const dateFormat = 'DD.MM.YYYY'
  return (
    <div className="pupil-search-native">
      <Form
        className="pupil-search-native__form"
        form={form}
        initialValues={{
          metricSerial: metricSerial,
          metricNumber: metricNumber,
          birthDate: pupilBirthday ? moment(pupilBirthday, dateFormat) : null,
        }}
        onFinish={onFinish}
        layout={'vertical'}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name={'metricSerial'}
          label={t('pupils.form.metricSeries')}
        >
          <Select allowClear={true}>
            {metricSerials.map((serial) => (
              <Select.Option key={serial} value={serial}>
                {serial}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
            {
              min: 7,
              max: 8,
              message: t('errors.min7Max8Message'),
            },
          ]}
          name={'metricNumber'}
          label={t('pupils.form.metricNumber')}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              validator: async (_, date) => {
                if (getPupilAge(date) > 7) {
                  return Promise.reject(
                    new Error(t('pupils.form.errors.minAge')),
                  )
                }
              },
            },
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          label={t('pupils.birthDate')}
          name={'birthDate'}
        >
          <DatePicker format={dateFormat} locale={locale} />
        </Form.Item>
      </Form>
      <StepsButtons
        confirmLoading={loading}
        form={form}
        prev={prev}
        current={current}
      />
    </div>
  )
}

export default FindPupilForm
