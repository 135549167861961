import { EditOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import useLocalization from '@app/hooks/useLocalization'
import { useGetFoodPreparingPlacesQuery } from '@app/store/rtk/api/organization-passport/food-preparing-places-api'
import { useGetOrganizationPassportizationQuery } from '@app/store/rtk/api/organization-passport/organization-passportization-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Descriptions, Typography } from 'antd'
import { sum } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

const permissions = [
  AUTHORITIES.ROLE_PASSPORTIZATION_ALL_VIEW,
  AUTHORITIES.ROLE_PASSPORTIZATION_REGION_VIEW,
  AUTHORITIES.ROLE_PASSPORTIZATION_DISTRICT_VIEW,
  AUTHORITIES.ROLE_PASSPORTIZATION_CURRENT_VIEW,
  AUTHORITIES.ADMIN,
]

export enum EFoodOrganizationPlaceType {
  SELF_ORGANIZATION = 'SELF_ORGANIZATION',
  OUTSOURCING = 'OUTSOURCING',
  CATERING = 'CATERING',
}
const OrganizationPassportization: React.FC = () => {
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const { localize } = useLocalization()
  const { data: passport } = useGetOrganizationPassportizationQuery(
    Number(params?.id),
    { skip: !params?.id },
  )
  const { data: foodPlaces } = useGetFoodPreparingPlacesQuery('')
  setDocumentTitle(t('institutionPassport.title'))
  return (
    <Card
      extra={
        rolesChecker(permissions) ? (
          <Link
            to={
              passport?.id
                ? `/organization/${params.id}/passportization/${passport?.id}/edit`
                : `/organization/${params.id}/passportization/add`
            }
            className="ant-btn ant-btn-default"
            type={'primary'}
          >
            <EditOutlined />
            {passport?.id ? t('general.edit') : t('general.add')}
          </Link>
        ) : null
      }
      title={t('passportization.title')}
    >
      <Descriptions size={'small'} column={1} bordered>
        <Descriptions.Item label={t('institutionPassport.id')}>
          {passport?.id}
        </Descriptions.Item>
        <Descriptions.Item label={t('foodPreparingPlace.title')}>
          {
            localize(foodPlaces?.response)?.find(
              (place) => place.id === passport?.foodPreparingPlace?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        {passport?.foodPreparingPlace?.type ===
          EFoodOrganizationPlaceType.OUTSOURCING && (
          <Descriptions.Item label={t('passportization.outsourceCompany')}>
            {passport?.foodOutsourcingCompany?.name}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t('passportization.closePoolCount')}>
          {passport?.closePoolCount}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.physiotherapyRoomsCount')}>
          {passport?.physiotherapyRoomsCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('passportization.ophthalmicAndAuditoryRoomsCount')}
        >
          {passport?.ophthalmicAndAuditoryRoomsCount}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.organizationBranchCount')}>
          {passport?.organizationBranchCount}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Title level={5} style={{ marginLeft: 16, marginTop: 16 }}>
        {t('passportization.washingTitle')}
      </Typography.Title>
      <Descriptions size="small" column={1} bordered>
        <Descriptions.Item
          label={t('passportization.automaticWashingMachineCount')}
        >
          {passport?.automaticWashingMachineCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('passportization.semiAutomaticWashingMachineCount')}
        >
          {passport?.semiAutomaticWashingMachineCount}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.handWashingCount')}>
          {passport?.handWashingCount}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('passportization.privateContractWashingCount')}
        >
          {passport?.privateContractWashingCount}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Title level={5} style={{ marginLeft: 16, marginTop: 16 }}>
        {t('passportization.heatingTitle')}
      </Typography.Title>
      <Descriptions size="small" column={1} bordered>
        <Descriptions.Item
          label={t('passportization.cityCentralHeatingNetwork')}
        >
          {passport?.cityCentralHeatingNetwork
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.boilerRoomsCount')}>
          {!passport?.cityCentralHeatingNetwork
            ? passport?.boilerRoomsCount
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.smallBoilerRoomsCount')}>
          {!passport?.cityCentralHeatingNetwork
            ? passport?.smallBoilerRoomsCount
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.ovensCount')}>
          {!passport?.cityCentralHeatingNetwork ? passport?.ovensCount : ''}
        </Descriptions.Item>
      </Descriptions>

      <Typography.Title level={5} style={{ marginLeft: 16, marginTop: 16 }}>
        {`${t('passportization.totalArea')} (${sum([
          passport?.flowerGardenArea,
          passport?.yardArea,
          passport?.officeSpaceCleanArea,
          passport?.buildingsAndStructuresArea,
        ])} ${t('passportization.squareMetres')})`}
      </Typography.Title>
      <Descriptions size="small" column={1} bordered>
        <Descriptions.Item label={t('passportization.flowerGardenArea')}>
          {passport?.flowerGardenArea}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.yardArea')}>
          {passport?.yardArea}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.officeSpaceCleanArea')}>
          {passport?.officeSpaceCleanArea}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('passportization.buildingsAndStructuresArea')}
        >
          {passport?.buildingsAndStructuresArea}
        </Descriptions.Item>
      </Descriptions>

      <Typography.Title level={5} style={{ marginLeft: 16, marginTop: 16 }}>
        {t('passportization.exploitationTitle')}
      </Typography.Title>
      <Descriptions size="small" column={1} bordered>
        <Descriptions.Item
          label={t('passportization.availabilityExploitation')}
        >
          {passport?.availabilityExploitation
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('passportization.waterSupplySewerageHeatingArea')}
        >
          {!passport?.availabilityExploitation
            ? passport?.waterSupplySewerageHeatingArea
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('passportization.lightingPointsCount')}>
          {!passport?.availabilityExploitation
            ? passport?.lightingPointsCount
            : ''}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default OrganizationPassportization
