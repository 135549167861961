import { createSelector } from '@reduxjs/toolkit'
import useLocalization from './useLocalization'

function useCreateSelector() {
  const { localize, localizeData } = useLocalization()

  const lSelectors = {
    organization: {
      organizations: createSelector(
        (state) => state.organizations.organizations,
        (organizations) => localize(organizations),
      ),
      organization: createSelector(
        (state) => state.organizations.organization,
        (organization) => localizeData(organization),
      ),
    },
    organizationTypes: {
      organizationTypes: createSelector(
        (state) => state.organizationTypes.organizationTypes,
        (organizationTypes) => localize(organizationTypes),
      ),
      organizationType: createSelector(
        (state) => state.organizationTypes.organizationType,
        (organizationType) => localizeData(organizationType),
      ),
    },
    institutionTypes: {
      institutionTypes: createSelector(
        (state) => state.institutionTypes.institutionsTypes,
        (institutionTypes) => localize(institutionTypes),
      ),
      institutionType: createSelector(
        (state) => state.institutionTypes.institutionsType,
        (institutionType) => localizeData(institutionType),
      ),
    },
    regions: {
      regions: createSelector(
        (state) => state.regions.regions,
        (regions) => localize(regions),
      ),
      region: createSelector(
        (state) => state.regions.region,
        (region) => localizeData(region),
      ),
    },
    districts: {
      districts: createSelector(
        (state) => state.districts.districts,
        (districts) => localize(districts),
      ),
      district: createSelector(
        (state) => state.districts.district,
        (district) => localizeData(district),
      ),
    },
    employeeStatuses: {
      employeeStatuses: createSelector(
        (state) => state.employeeStatus.employeeStatuses,
        (employeeStatuses) => localize(employeeStatuses),
      ),
      employeeStatus: createSelector(
        (state) => state.employeeStatus.employeeStatus,
        (employeeStatus) => localizeData(employeeStatus),
      ),
    },
    employeeRates: {
      employeeRates: createSelector(
        (state) => state.employeeRate.employeeRates,
        (employeeRates) => localize(employeeRates),
      ),
      employeeRate: createSelector(
        (state) => state.employeeRate.employeeRate,
        (employeeRate) => localizeData(employeeRate),
      ),
    },
    employeePositions: {
      employeePositions: createSelector(
        (state) => state.employeePosition.employeePositions,
        (employeePositions) => localize(employeePositions),
      ),
      employeePosition: createSelector(
        (state) => state.employeePosition.employeePosition,
        (employeePosition) => localizeData(employeePosition),
      ),
    },
    menuSeasons: {
      menuSeasons: createSelector(
        (state) => state.organizationMenus.seasons,
        (seasons) => localize(seasons),
      ),
      season: createSelector(
        (state) => state.organizationMenus.season,
        (season) => localizeData(season),
      ),
    },
    menus: {
      menus: createSelector(
        (state) => state.organizationMenus.menus,
        (menus) => localize(menus),
      ),
      menu: createSelector(
        (state) => state.organizationMenus.menu,
        (menu) => localizeData(menu),
      ),
    },
    menuMealTypes: {
      menuMealTypes: createSelector(
        (state) => state.organizationMenus.mealTypes,
        (mealTypes) => localize(mealTypes),
      ),
      menuMealType: createSelector(
        (state) => state.organizationMenus.mealType,
        (mealType) => localizeData(mealType),
      ),
    },
    menuMeals: {
      menuMeals: createSelector(
        (state) => state.organizationMenus.meals,
        (meals) => localize(meals),
      ),
      menuMeal: createSelector(
        (state) => state.organizationMenus.meal,
        (meal) => localizeData(meal),
      ),
    },
    menuProducts: {
      menuProducts: createSelector(
        (state) => state.organizationMenus.products,
        (products) => localize(products),
      ),
      menuProduct: createSelector(
        (state) => state.organizationMenus.product,
        (product) => localizeData(product),
      ),
    },
    menuMeasurements: {
      menuMeasurements: createSelector(
        (state) => state.organizationMenus.measurements,
        (measurements) => localize(measurements),
      ),
      menuMeasurement: createSelector(
        (state) => state.organizationMenus.measurement,
        (measurement) => localizeData(measurement),
      ),
    },
    warehouse: {
      warehouseData: createSelector(
        (state) => state.organizationWarehouse.warehouseData,
        (warehouseData) => localize(warehouseData),
      ),
      warehouseInvoice: createSelector(
        (state) => state.organizationWarehouse.warehouseInvoice,
        (warehouseInvoice) => localizeData(warehouseInvoice),
      ),
    },
  }

  const selectors = {
    organization: {
      organizationsState: createSelector(
        (state) => state.organizations,
        (organizations) => organizations,
      ),
      organizations: createSelector(
        (state) => state.organizations.organizations,
        (organizations) => organizations,
      ),
      organization: createSelector(
        (state) => state.organizations.organization,
        (organization) => organization,
      ),
    },
    organizationSector: {
      organizationsSectorState: createSelector(
        (state) => state.organizationSector,
        (organizationSector) => organizationSector,
      ),
      organizationSectors: createSelector(
        (state) => state.organizationSector.organizationSectors,
        (organizationSectors) => organizationSectors,
      ),
      organizationSector: createSelector(
        (state) => state.organizationSector.organizationSector,
        (organizationSector) => organizationSector,
      ),
    },
    organizationSchedule: {
      organizationsSectorState: createSelector(
        (state) => state.organizationSchedule,
        (organizationSchedule) => organizationSchedule,
      ),
      organizationSchedules: createSelector(
        (state) => state.organizationSchedule.organizationSchedules,
        (organizationSchedules) => organizationSchedules,
      ),
      organizationSchedule: createSelector(
        (state) => state.organizationSchedule.organizationSchedule,
        (organizationSchedule) => organizationSchedule,
      ),
    },
    organizationBuildingType: {
      organizationsSectorState: createSelector(
        (state) => state.organizationBuildingType,
        (organizationBuildingType) => organizationBuildingType,
      ),
      organizationBuildingTypes: createSelector(
        (state) => state.organizationBuildingType.organizationBuildingTypes,
        (organizationBuildingTypes) => organizationBuildingTypes,
      ),
      organizationBuildingType: createSelector(
        (state) => state.organizationBuildingType.organizationBuildingType,
        (organizationBuildingType) => organizationBuildingType,
      ),
    },
    institutionSubType: {
      institutionSubTypeState: createSelector(
        (state) => state.institutionSubType,
        (institutionSubType) => institutionSubType,
      ),
      institutionSubTypes: createSelector(
        (state) => state.institutionSubType.institutionSubTypes,
        (institutionSubTypes) => institutionSubTypes,
      ),
      institutionSubType: createSelector(
        (state) => state.institutionSubType.institutionSubType,
        (institutionSubType) => institutionSubType,
      ),
    },
    organizationTypes: {
      organizationTypesState: createSelector(
        (state) => state.organizationTypes,
        (organizationTypes) => organizationTypes,
      ),
      organizationTypes: createSelector(
        (state) => state.organizationTypes.organizationTypes,
        (organizationTypes) => organizationTypes,
      ),
      organizationType: createSelector(
        (state) => state.organizationTypes.organizationType,
        (organizationType) => organizationType,
      ),
    },
    organizationContractSubsidy: {
      organizationContractSubsidyState: createSelector(
        (state) => state.organizationContractSubsidy,
        (organizationContractSubsidy) => organizationContractSubsidy,
      ),
      organizationContractSubsidies: createSelector(
        (state) =>
          state.organizationContractSubsidy.organizationContractSubsidies,
        (organizationContractSubsidies) => organizationContractSubsidies,
      ),
      organizationContractSubsidy: createSelector(
        (state) =>
          state.organizationContractSubsidy.organizationContractSubsidy,
        (organizationContractSubsidy) => organizationContractSubsidy,
      ),
    },
    institutionTypes: {
      institutionTypesState: createSelector(
        (state) => state.institutionTypes,
        (institutionTypes) => institutionTypes,
      ),
      institutionTypes: createSelector(
        (state) => state.institutionTypes.institutionsTypes,
        (institutionTypes) => institutionTypes,
      ),
      institutionType: createSelector(
        (state) => state.institutionTypes.institutionsType,
        (institutionType) => institutionType,
      ),
    },
    regions: {
      regionsState: createSelector(
        (state) => state.regions,
        (regions) => regions,
      ),
      regions: createSelector(
        (state) => state.regions.regions,
        (regions) => regions,
      ),
      region: createSelector(
        (state) => state.regions.region,
        (region) => region,
      ),
    },
    districts: {
      districtsState: createSelector(
        (state) => state.districts,
        (districts) => districts,
      ),
      districts: createSelector(
        (state) => state.districts.districts,
        (districts) => districts,
      ),
      district: createSelector(
        (state) => state.districts.district,
        (district) => district,
      ),
    },
    employeeStatuses: {
      employeeStatusesState: createSelector(
        (state) => state.employeeStatus,
        (employeeStatus) => employeeStatus,
      ),
      employeeStatuses: createSelector(
        (state) => state.employeeStatus.employeeStatuses,
        (employeeStatuses) => employeeStatuses,
      ),
      employeeStatus: createSelector(
        (state) => state.employeeStatus.employeeStatus,
        (employeeStatus) => employeeStatus,
      ),
    },
    employeeRates: {
      employeeRatesState: createSelector(
        (state) => state.employeeRate,
        (employeeRate) => employeeRate,
      ),
      employeeRates: createSelector(
        (state) => state.employeeRate.employeeRates,
        (employeeRates) => employeeRates,
      ),
      employeeRate: createSelector(
        (state) => state.employeeRate.employeeRate,
        (employeeRate) => employeeRate,
      ),
    },
    employeePositions: {
      employeePositionsState: createSelector(
        (state) => state.employeePosition,
        (employeePosition) => employeePosition,
      ),
      employeePositions: createSelector(
        (state) => state.employeePosition.employeePositions,
        (employeePositions) => employeePositions,
      ),
      employeePosition: createSelector(
        (state) => state.employeePosition.employeePosition,
        (employeePosition) => employeePosition,
      ),
    },
    menuSeasons: {
      menuSeasons: createSelector(
        (state) => state.organizationMenus.seasons,
        (seasons) => seasons,
      ),
      season: createSelector(
        (state) => state.organizationMenus.season,
        (season) => season,
      ),
    },
    menus: {
      menusState: createSelector(
        (state) => state.organizationMenus,
        (organizationMenus) => organizationMenus,
      ),
      menus: createSelector(
        (state) => state.organizationMenus.menus,
        (menus) => menus,
      ),
      menu: createSelector(
        (state) => state.organizationMenus.menu,
        (menu) => menu,
      ),
      menuByPupil: createSelector(
        (state) =>
          state.organizationMenus.menuByPupil.sort((a, b) =>
            a.workDate?.localeCompare(b.workDate),
          ),
        (menuByPupil) => menuByPupil,
      ),
      menuByPeriod: createSelector(
        (state) =>
          state.organizationMenus.menuByPeriod.sort((a, b) =>
            a.workDate?.localeCompare(b.workDate),
          ),
        (menuByPeriod) => menuByPeriod,
      ),
    },
    menuMealTypes: {
      menuMealTypes: createSelector(
        (state) => state.organizationMenus.mealTypes,
        (mealTypes) => mealTypes,
      ),
      menuMealType: createSelector(
        (state) => state.organizationMenus.mealType,
        (mealType) => mealType,
      ),
    },
    menuMeals: {
      menuMeals: createSelector(
        (state) => state.organizationMenus.meals,
        (meals) => meals,
      ),
      menuMeal: createSelector(
        (state) => state.organizationMenus.meal,
        (meal) => meal,
      ),
    },
    menuProducts: {
      menuProducts: createSelector(
        (state) => state.organizationMenus.products,
        (products) => products,
      ),
      menuProduct: createSelector(
        (state) => state.organizationMenus.product,
        (product) => product,
      ),
    },
    menuMeasurements: {
      menuMeasurements: createSelector(
        (state) => state.organizationMenus.measurements,
        (measurements) => measurements,
      ),
      menuMeasurement: createSelector(
        (state) => state.organizationMenus.measurement,
        (measurement) => measurement,
      ),
    },
    warehouse: {
      warehouseState: createSelector(
        (state) => state.organizationWarehouse,
        (organizationWarehouse) => organizationWarehouse,
      ),
      warehouseData: createSelector(
        (state) => state.organizationWarehouse.warehouseData,
        (warehouseData) => warehouseData,
      ),
      warehouseInvoice: createSelector(
        (state) => state.organizationWarehouse.warehouseInvoice,
        (warehouseInvoice) => warehouseInvoice,
      ),
    },
    growth: {
      growthState: createSelector(
        (state) => state.organizationGrowth,
        (organizationGrowth) => organizationGrowth,
      ),
      growthStatistics: createSelector(
        (state) => state.organizationGrowth.growthStatistics,
        (growthStatistics) => growthStatistics,
      ),
      growthPupil: createSelector(
        (state) => state.organizationGrowth.growthPupil,
        (growthPupil) => growthPupil,
      ),
      growthPupils: createSelector(
        (state) => state.organizationGrowth.growthPupils,
        (growthPupils) =>
          growthPupils.map((pupil) => ({
            ...pupil,
            id: pupil.pupilId,
            key: pupil.pupilId,
          })),
      ),
    },
    educationGroupRoom: {
      educationGroupRoomState: createSelector(
        (state) => state.educationGroupRoom,
        (educationGroupRoom) => educationGroupRoom,
      ),
      educationGroupRooms: createSelector(
        (state) => state.educationGroupRoom.educationGroupRooms,
        (educationGroupRooms) => educationGroupRooms,
      ),
      educationGroupRoom: createSelector(
        (state) => state.educationGroupRoom.educationGroupRoom,
        (educationGroupRoom) => educationGroupRoom,
      ),
    },
    benefitType: {
      benefitTypeState: createSelector(
        (state) => state.benefitType,
        (benefitType) => benefitType,
      ),
      benefitTypes: createSelector(
        (state) => state.benefitType.benefitTypes,
        (benefitTypes) => benefitTypes,
      ),
      benefitType: createSelector(
        (state) => state.benefitType.benefitType,
        (benefitType) => benefitType,
      ),
    },
    userInfo: {
      userInfoState: createSelector(
        (state) => state.userInfo,
        (userInfo) => userInfo,
      ),
      userInfo: createSelector(
        (state) => state.userInfo.userInfo,
        (userInfo) => userInfo,
      ),
    },
  }

  return {
    lSelectors,
    selectors,
  }
}

export default useCreateSelector
