export const CREATE_EMPLOYEE_RATE = 'CREATE_EMPLOYEE_RATE'
export const UPDATE_EMPLOYEE_RATE = 'UPDATE_EMPLOYEE_RATE'
export const DELETE_EMPLOYEE_RATE = 'DELETE_EMPLOYEE_RATE'
export const CLEAR_EMPLOYEE_RATE = 'CLEAR_EMPLOYEE_RATE'

export const FETCH_EMPLOYEE_RATES_REQUEST = 'FETCH_EMPLOYEE_RATES_REQUEST'
export const FETCH_EMPLOYEE_RATES_SUCCESS = 'FETCH_EMPLOYEE_RATES_SUCCESS'
export const FETCH_EMPLOYEE_RATES_FAILURE = 'FETCH_EMPLOYEE_RATES_FAILURE'

export const FETCH_EMPLOYEE_RATE_REQUEST = 'FETCH_EMPLOYEE_RATE_REQUEST'
export const FETCH_EMPLOYEE_RATE_SUCCESS = 'FETCH_EMPLOYEE_RATE_SUCCESS'
export const FETCH_EMPLOYEE_RATE_FAILURE = 'FETCH_EMPLOYEE_RATE_FAILURE'
