import bbitApi from '@app/api/make-request'
import { IPupil } from '@app/interfaces/pupil/pupils'
import ChildDevelopmentView from '@app/pages/child-stats/components/child-developement-view'
import { Button, Form, Modal } from 'antd'
import { groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

type props = {
  pupil: IPupil
  closeModal: () => void
}

type TCreateReadnessMap = {
  pupilId: number
  details: {
    grade?: string
    comment?: string
    subtitle: { id: number }
  }[]
}
type TUpdateReadnessMap = Omit<TCreateReadnessMap, 'pupilId'> & { id: number }

const ChildDevelopmentModalView = ({ pupil, closeModal }: props) => {
  const { mutate: mutateReadnessMap } = useMutation(
    async (payload: TCreateReadnessMap | TUpdateReadnessMap) => {
      if (pupil.pupilReadinessMapId) {
        await bbitApi.put(
          `/pupil/api/v1/pupils-readiness-map/${pupil.pupilReadinessMapId}`,
          payload,
        )
      } else {
        await bbitApi.post('/pupil/api/v1/pupils-readiness-map', payload)
      }
    },
    {},
  )
  const [form] = Form.useForm()
  const [t] = useTranslation()

  const onCklickSave = async () => {
    const details = Object.values(
      groupBy(
        Object.entries(form.getFieldsValue()).map(([key, value]) => ({
          ...JSON.parse(key),
          value,
        })),
        (item) => item.subtitleId,
      ),
    )
      .map((grouped) =>
        grouped.reduce((prev, curr) => {
          return {
            ...prev,
            ...(curr.grade ? { grade: curr.value } : {}),
            ...(curr.comment ? { comment: curr.value } : {}),
            ...(curr.detailId ? { id: curr.detailId } : {}),
            subtitle: {
              id: curr.subtitleId,
            },
          }
        }, {}),
      )
      .filter((item) => typeof item.grade === 'string')

    mutateReadnessMap(
      {
        details,
        ...(pupil.pupilReadinessMapId
          ? { id: pupil.pupilReadinessMapId }
          : { pupilId: pupil.id }),
      },
      {
        onSuccess: () => {
          closeModal()
        },
      },
    )
  }

  return (
    <Modal
      width="90%"
      open={!!pupil}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          {t('general.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={false}
          onClick={onCklickSave}>
          {t('general.save')}
        </Button>,
      ]}>
      <>
        <Form form={form}>
          <ChildDevelopmentView
            deleteGrade={(formId) => form.setFieldValue(formId, undefined)}
            pupil={pupil}
            edit={true}
          />
        </Form>
      </>
    </Modal>
  )
}

export default ChildDevelopmentModalView
