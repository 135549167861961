import React from 'react'
import './navbar.less'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { AUTH_TOKEN } from '@app/constants/authToken'
import {
  AreaChartOutlined,
  EyeOutlined,
  LogoutOutlined,
  ReadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Logo } from '@app/shared'
import { connect, useDispatch } from 'react-redux'
import { clearUserInfo } from '@app/store/actions/user-info-actions'
import { langs } from '@app/constants/languages'
import { Link, useNavigate } from 'react-router-dom'
import { clearOrganization } from '@app/store/actions/organization-actions'
import { IUserInfo } from '@app/interfaces/user-info'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'

interface props {
  _userInfo?: IUserInfo
}

const Navbar: React.FC<props> = ({ _userInfo }) => {
  const [t, i18n] = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { setLanguage, getFormattedCode } = useLocalization()

  function handleChange(value) {
    i18n.changeLanguage(value)
    setLanguage(value)
  }

  function logOut() {
    localStorage.removeItem(AUTH_TOKEN)
    dispatch(clearUserInfo())
    dispatch(clearOrganization())
    navigate('/auth')
  }

  return (
    <div className="navbar">
      <div className="navbar__logo">
        <Logo />
      </div>
      <div className="navbar__items">
        <div className="item">
          <Select
            onChange={handleChange}
            value={getFormattedCode(i18n.language)}
            defaultValue={getFormattedCode(i18n.language)}
          >
            {langs.map(function (lang) {
              return (
                <Select.Option key={lang.id} value={lang.code}>
                  {lang.name}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        {rolesChecker([AUTHORITIES.ROLE_MANAGER]) && (
          <Link
            to={`/organization/${_userInfo?.organizations[0]?.organizationId}/manager`}
            className="item"
          >
            <p className="item__link">{t('routes.manager')}</p>
            <div className="item__icon">
              <EyeOutlined />
            </div>
          </Link>
        )}
        <Link to="/profile" className="item">
          <p className="item__link">{t('routes.profile')}</p>
          <div className="item__icon">
            <UserOutlined />
          </div>
        </Link>
        {/* <Link to={'/indicators'} className="item">
          <p className="item__link">{t('routes.indicators')}</p>
          <div className="item__icon">
            <HomeOutlined />
          </div>
        </Link> */}
        <Link to={'/statistics'} className="item">
          <p className="item__link">{t('routes.statistics')}</p>
          <div className="item__icon">
            <AreaChartOutlined />
          </div>
        </Link>
        <Link to={'/manual'} className="item">
          <p className="item__link">{t('routes.manual')}</p>
          <div className="item__icon">
            <ReadOutlined />
          </div>
        </Link>
        <div className="item" onClick={logOut}>
          <div className="item__link">{t('general.logout')}</div>
          <div className="item__icon">
            <LogoutOutlined />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    _userInfo: state.userInfo.userInfo,
  }
}

export default connect(mapStateToProps)(Navbar)
