import { Result } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

function NotFound() {
  return (
    <>
      <Result
        status="500"
        title="500"
        subTitle="Извините, произошла ошибка на сервере, обратитесь к администратору или попробуйте позже"
        extra={<Link to={'/profile'}>Перейти в профиль</Link>}
      />
    </>
  )
}

export default NotFound
