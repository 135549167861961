import { ButtonLinkTitle } from '@app/ui/atoms'
import { MouseEventHandler } from 'react'

type props = {
  onClick: MouseEventHandler<HTMLElement> | undefined
}

export const ButtonTitleView = ({ onClick }: props) => (
  <ButtonLinkTitle title="general.view" onClick={onClick} />
)
