export const FETCH_ORGANIZATION_SCHEDULES_REQUEST =
  'FETCH_ORGANIZATION_SCHEDULES_REQUEST'
export const FETCH_ORGANIZATION_SCHEDULES_SUCCESS =
  'FETCH_ORGANIZATION_SCHEDULES_SUCCESS'
export const FETCH_ORGANIZATION_SCHEDULES_FAILURE =
  'FETCH_ORGANIZATION_SCHEDULES_FAILURE'

export const FETCH_ORGANIZATION_SCHEDULE_REQUEST =
  'FETCH_ORGANIZATION_SCHEDULE_REQUEST'
export const FETCH_ORGANIZATION_SCHEDULE_SUCCESS =
  'FETCH_ORGANIZATION_SCHEDULE_SUCCESS'
export const FETCH_ORGANIZATION_SCHEDULE_FAILURE =
  'FETCH_ORGANIZATION_SCHEDULE_FAILURE'

export const FETCH_ORGANIZATION_SCHEDULE_CLEANUP =
  'FETCH_ORGANIZATION_SCHEDULE_CLEANUP'
export const CLEAR_ORGANIZATION_SCHEDULES = 'CLEAR_ORGANIZATION_SCHEDULES'
export const CLEAR_ORGANIZATION_SCHEDULE = 'CLEAR_ORGANIZATION_SCHEDULE'
export const REFRESH_ORGANIZATION_SCHEDULE = 'REFRESH_ORGANIZATION_SCHEDULE'
