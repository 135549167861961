import React from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Form, Row, Col, Input, Select } from 'antd'
import useQuery from '@app/hooks/useQuery'

interface props {
  request: (string) => void
  load: boolean
  searchParams: URLSearchParams
}

const OrganizationsGroupsFilterForm: React.FC<props> = ({
  request,
  load,
  searchParams,
}) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { serializeQuery } = useQuery()

  const onFinish = (values: any) => {
    const newValues = {
      'id.equals': values.id,
      'status.equals': values.status,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
  }
  const onReset = (values: any) => {
    const newValues = {
      'id.equals': values.id,
      'status.equals': values.status,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
    form.resetFields()
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      onReset={() => form.resetFields()}
      className="header-form"
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name={'id'} label={t('educationGroup.groupId')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'status'} label={t('educationGroup.status')}>
            <Select>
              <Select.Option value={'OPEN'}>
                {t('general.active')}
              </Select.Option>
              <Select.Option value={'CLOSE'}>
                {t('general.inActive')}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div>
        <Button disabled={!load} type="default" htmlType="submit">
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          disabled={!load}
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onReset}
        >
          {t('general.reset')}
        </Button>
      </div>
    </Form>
  )
}

export default OrganizationsGroupsFilterForm
