import { FilterOutlined } from '@ant-design/icons'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import {
  getWarehouseInvoice,
  toggleWarehouseAddInvoiceModal,
} from '@app/store/actions/organization-warehouse-actions'
import { Button, DatePicker, Table } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import Text from 'antd/lib/typography/Text'
import axios from 'axios'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import OrganizationWarehouseInvoiceAddForm from './organization-warehouse-invoice-add-form'
import './organization-warehouse-invoice.less'

interface props {
  organizationId?: string
}

interface IPathParams {
  id?: string
}

const OrganizationWarehouseInvoice: React.FC<props> = ({ organizationId }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const { selectors } = useCreateSelector()
  const [showTable, setShowTable] = useState<boolean>(false)
  const [tableDate, setTableDate] = useState<any>([moment(), moment()])
  const { warehouseInvoice, state } = useSelector(
    selectors.warehouse.warehouseState,
  )
  const { RangePicker } = DatePicker
  const params: IPathParams = useParams()
  const { searchParams, handleTableChange } = usePagination()

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (showTable) {
      if (params.id || organizationId) {
        searchParams.set('fromDate', tableDate[0]?.format('yyyy-MM-DD'))
        searchParams.set('toDate', tableDate[1]?.format('yyyy-MM-DD'))
        dispatch(
          getWarehouseInvoice(
            params.id || organizationId || '',
            { params: searchParams, source },
            t('organizationWarehouse.errors.loadError'),
          ),
        )
      }
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [tableDate, showTable])

  const onShowHandler = () => {
    setShowTable(true)
  }

  const onAddHandler = () => {
    dispatch(toggleWarehouseAddInvoiceModal(true))
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: t('organizationWarehouse.invoiceNumber'),
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: t('organizationMenu.products.name'),
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: t('organizationMenu.meals.measurement'),
      key: 'measurements',
      render: () => <div>{t('general.gram')}</div>,
    },
    {
      title: t('organizationWarehouse.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ]

  const data = !isEmpty(warehouseInvoice)
    ? warehouseInvoice.map((invoice) => {
        return {
          key: invoice.id,
          id: invoice.id,
          organizationId: invoice.organizationId,
          productName: localizeData(invoice.product.data)?.name,
          quantity: invoice.quantity,
          invoiceNumber: invoice.invoiceNumber,
        }
      })
    : []

  return (
    <div className="warehouse-invoice">
      <div className="warehouse-invoice__header">
        <RangePicker
          onCalendarChange={setTableDate}
          value={tableDate}
          locale={locale}
        />
        <Button type="primary" onClick={onShowHandler}>
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          type="primary"
          onClick={onAddHandler}
          disabled={!organizationId && !params.id}
        >
          {t('general.add')}
        </Button>
        <OrganizationWarehouseInvoiceAddForm organizationId={organizationId} />
      </div>
      {showTable && (
        <Table
          columns={columns}
          dataSource={data}
          rowClassName="table-row"
          loading={state === LOADING}
          pagination={false}
          onChange={handleTableChange}
        />
      )}
    </div>
  )
}

export default OrganizationWarehouseInvoice
