import EmployeeRepository from '@app/api/repositories/employee-repository'
import { IEmployee } from '@app/interfaces/employee/employee'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import PhotoItem from '@app/shared/photo-item/photo-item'
import {
  refreshEmployee,
  updateEmployee,
} from '@app/store/actions/employee-actions'
import { Button, Form, Modal, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  employee?: IEmployee
  edit?: boolean
}

const AddEmployeeAvatarForm: React.FC<props> = ({ employee, edit }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t, i18n] = useTranslation()

  const showModal = () => {
    setVisible(true)
  }
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()
  const onFinish = async (id: number) => {
    const newValues = {
      id: employee?.id,
      fileId: id,
    }
    setConfirmLoading(true)
    const avatarApi = EmployeeRepository.partialUpdate
    await avatarApi(newValues)
      .then((response: AxiosResponse<IEmployee>) => {
        if (response.status === 200) {
          dispatch(updateEmployee(response.data))
          openNotificationWithIcon('success', t('success.updated'))
          setConfirmLoading(false)
          dispatch(refreshEmployee())
          hideModal()
        }
      })
      .catch(() => {
        setConfirmLoading(false)
        hideModal()
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} size={'small'} type={'dashed'}>
        {edit ? t('users.changeAvatar') : t('users.addAvatar')}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('users.changeAvatar') : t('users.addAvatar')}
        onCancel={confirmLoading ? undefined : hideModal}
        width={400}
        confirmLoading={confirmLoading}
        footer={[<React.Fragment key={1}></React.Fragment>]}
      >
        <Spin spinning={confirmLoading}>
          <Form form={form} onFinish={onFinish} layout={'vertical'}>
            <Form.Item className={'avatar__item'} name={'fileId'}>
              <PhotoItem
                upload={true}
                onSubmit={onFinish}
                closeModal={hideModal}
                isLoading={setConfirmLoading}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}
export default connect(null, null)(AddEmployeeAvatarForm)
