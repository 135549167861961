import { Card, Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'

type Props = Record<
  'fullName' | 'completionDate' | 'gender' | 'birthDate',
  string | undefined
>

const ChildInfoComponent = ({
  fullName,
  birthDate,
  gender,
  completionDate,
}: Props) => {
  const [t] = useTranslation()
  return (
    <Card style={{ margin: '10px 0' }}>
      <Descriptions size="small" column={1} bordered>
        <Descriptions.Item label={t('general.fullName')}>
          {fullName}
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.birthDate')}>
          {birthDate}
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.gender')}>
          {gender}
        </Descriptions.Item>
        <Descriptions.Item label={t('contracts.contractDate')}>
          {completionDate ? completionDate : ''}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default ChildInfoComponent
