import bbitApi from '@app/api/make-request'
import { IPathParamsArgs } from '@app/api/repositories/i-repository'
import { INeighborhood } from '@app/interfaces/neighborhood/neighborhood.types'
import { TNeighborhoodActionType } from '@app/store/types/neighborhood'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import axios from 'axios'
import { isError } from 'lodash'

export const getNeighborhoods =
  (args: IPathParamsArgs) =>
  async (
    dispatch: (args: { payload?: any; type: TNeighborhoodActionType }) => void,
  ) => {
    dispatch({ type: 'GET_NEIGHBORHOODS_REQUEST' })
    try {
      const { data } = await bbitApi.get<INeighborhood[]>(
        '/dictionary/api/v1/neighborhood',
        {
          cancelToken: args.source?.token,
          params: resolveParams(args),
        },
      )
      dispatch({ type: 'GET_NEIGHBORHOODS_SUCCESS', payload: data })
    } catch (error) {
      if (!axios.isCancel(error) && isError(error)) {
        dispatch({
          type: 'GET_NEIGHBORHOODS_FAILURE',
          payload: { message: error.message },
        })
      }
    }
  }
