import {
  FIVE_SIX,
  FOUR_FIVE,
  MOBILE,
  SHORT_TERM,
  SIX_PLUS,
  SPECIALIZED,
  THREE_FOUR,
  TWO_THREE,
  YEARLY_TRAINING,
} from '@app/constants/educationGrade'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import { differenceInCalendarMonths } from 'date-fns'

const useMatchGroup = () => {
  // Diff years by present time
  function diffYearsByTime(dt2, dt1) {
    // let diff = (dt2.getTime() - dt1.getTime()) / 1000
    // diff /= 60 * 60 * 24
    // return Math.abs(Math.floor(diff / 365.25))
    const ageDifMs = dt2.getTime() - dt1.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  // Diff years by year
  function diffYears(dt2, dt1) {
    return diffYearsByTime(dt2, dt1)
  }

  function diffMonths(dt2, dt1) {
    return differenceInCalendarMonths(dt2.getTime(), dt1.getTime())
  }

  const filterGroups = (
    groups: IEducationGroup[],
    orgPupil: IOrganizationPupils,
  ): IEducationGroup[] => {
    const years = diffYears(new Date(), new Date(orgPupil.pupil.birthDate))
    const pupilGroupGrade = orgPupil.educationGroup.educationGradeId
    return groups.filter((group) => {
      const groupGradeId = group.educationGradeId
      if (group.groupFreeSeats <= 0) return false
      if (
        [YEARLY_TRAINING, SHORT_TERM, SPECIALIZED, MOBILE].includes(
          pupilGroupGrade,
        ) ||
        [YEARLY_TRAINING, SHORT_TERM, SPECIALIZED, MOBILE].includes(
          groupGradeId,
        )
      ) {
        if (orgPupil.educationGroup.id === group.id) return false
        return groupGradeId === pupilGroupGrade
      }
      if ([TWO_THREE, THREE_FOUR].includes(groupGradeId)) return years === 3
      if (FOUR_FIVE === groupGradeId) return years === 4
      if (FIVE_SIX === groupGradeId) return years === 5
      if (SIX_PLUS === groupGradeId) return years === 6
      return true
    })
  }

  return { filterGroups, diffYears, diffMonths, diffYearsByTime }
}

export default useMatchGroup
