import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { createApi } from '@reduxjs/toolkit/query/react'

export const emptyEducationApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'educationApi',
  tagTypes: [
    ApiKeys.educationDailySchedule,
    ApiKeys.educationGrade,
    ApiKeys.educationGroup,
    ApiKeys.educationGroupLocation,
    ApiKeys.educationGroupType,
    ApiKeys.educationLanguage,
    ApiKeys.educationWeeklySchedule,
  ],
  endpoints: () => ({}),
})
