import { FormType } from '@app/constants/formType'
import { langs } from '@app/constants/languages'
import useLocalization from '@app/hooks/useLocalization'
import { IMenuSeasonMenu } from '@app/interfaces/organization-menu'
import { toggleMenuFormType } from '@app/store/actions/organization-menus-actions'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { Button, DatePicker, Form, Input, Modal } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuSeasonMenus from './menu-season-menus'
import moment from 'moment'
import useCreateSelector from '@app/hooks/useCreateSelector'

interface props {
  addSeason: (
    values: any,
    seasonMenus: IMenuSeasonMenu[],
    cb: () => void,
  ) => void
}

const MenuSeasonForm: React.FC<props> = ({ addSeason }) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { selectors } = useCreateSelector()
  const dispatch = useDispatch()
  const [asd, setAsd] = useState('asd')
  const { localize, localizeData } = useLocalization()
  const [childForm, setChildForm] = useState<FormInstance<any>>()
  const [seasonMenus, setSeasonMenus] = useState<IMenuSeasonMenu[]>([])
  const { formType, season } = useSelector(selectors.menus.menusState)

  const showModal = () => {
    dispatch(toggleMenuFormType(FormType.ADD))
  }

  const hideModal = () => {
    dispatch(toggleMenuFormType(FormType.NONE))
    form.resetFields()
    setSeasonMenus([])
    childForm?.resetFields()
  }

  useEffect(() => {
    if (formType === FormType.EDIT) {
      const newData = season.data?.sort((a, b) => {
        return a.languageId - b.languageId
      })
      newData.unshift(undefined)
      form.setFieldsValue({
        id: season.id,
        key: season.id,
        fromDate: moment(season.fromDate),
        toDate: moment(season.toDate),
        data: season.data,
        menus: season.menus,
      })
      setSeasonMenus(cloneDeep(season.menus).sort((a, b) => a.queue - b.queue))
    }
  }, [season])

  const onFormSubmitHandler = (values: any) => {
    addSeason(values, seasonMenus, hideModal)
  }

  return (
    <>
      <Button className={``} onClick={showModal} type={'primary'}>
        {t('general.add')}
      </Button>
      <Modal
        open={formType !== FormType.NONE}
        title={t('organizationMenu.menuSeasons.add')}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <Button type={'primary'} onClick={form.submit}>
              {t('general.accept')}
            </Button>
            <Button
              type={'default'}
              onClick={hideModal}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Button>
          </React.Fragment>,
        ]}
      >
        <Form form={form} onFinish={onFormSubmitHandler} layout={'vertical'}>
          <Form.Item hidden={true} name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            label={t('general.name')}
          >
            <Form.List name="data">
              {() =>
                langs
                  .sort((a, b) => {
                    return a.languageId - b.languageId
                  })
                  .map(({ id, languageId, toShowCode }) => (
                    <React.Fragment key={id}>
                      <Form.Item hidden={true} name={[languageId, 'id']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[languageId, 'name']}
                        rules={[
                          {
                            required: true,
                            message: t('errors.requiredMessage'),
                          },
                          {
                            min: 2,
                            message: t('errors.minMessage'),
                          },
                        ]}
                      >
                        <Input addonBefore={toShowCode} />
                      </Form.Item>
                      <Form.Item
                        hidden={true}
                        name={[id, 'languageId']}
                        initialValue={id}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))
              }
            </Form.List>
          </Form.Item>
          <Form.Item
            label={t('organizationMenu.menuSeasons.fromDate')}
            name="fromDate"
          >
            <DatePicker locale={locale} disabled={formType === FormType.EDIT} />
          </Form.Item>
          <Form.Item
            label={t('organizationMenu.menuSeasons.toDate')}
            name="toDate"
          >
            <DatePicker locale={locale} disabled={formType === FormType.EDIT} />
          </Form.Item>
          {/* {!isEmpty(menus) ? (
            <Form.Item
              label={t('organizationMenu.types.menus')}
              name="measurementId"
            >
              <Select>
                {sortByName(localize(menus)).map((season) => {
                  return (
                    <Select.Option key={season.id} value={season.id}>
                      {season.data?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          ) : (
            <ThreeDots />
          )} */}
        </Form>
        <MenuSeasonMenus
          seasonMenus={seasonMenus}
          setSeasonMenus={setSeasonMenus}
          setChildForm={setChildForm}
        />
      </Modal>
    </>
  )
}

export default MenuSeasonForm
