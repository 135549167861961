import { ApiKeys } from '@app/constants/api-keys'
import { IOutsourcingCompany } from '@app/interfaces/organization-passport/outsourcing-company'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const outsourcingCompaniesApi =
  emptyOrganizationPassportApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getOutsourcingCompanies: build.query<
        IRtkResponse<IOutsourcingCompany[]>,
        URLSearchParams | string
      >({
        query: (args = '') => ({
          url: 'dictionary/api/v1/food-outsourcing-companies',
          method: 'get',
          params: {
            ...resolveTParams(args),
          },
        }),
        transformResponse: (response: IOutsourcingCompany[], meta) => {
          return { response, meta }
        },
        providesTags: (data) =>
          data
            ? [
                ...data.response.map(({ id }) => ({
                  type: ApiKeys.outsourcingCompany,
                  id,
                })),
                { type: ApiKeys.outsourcingCompany, id: 'LIST' },
              ]
            : [{ type: ApiKeys.outsourcingCompany, id: 'LIST' }],
      }),
      getOutsourcingCompany: build.query<IOutsourcingCompany, number>({
        query: (id) => ({
          url: `dictionary/api/v1/food-outsourcing-companies/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          { type: ApiKeys.outsourcingCompany, id },
        ],
      }),
      createOutsourcingCompany: build.mutation<
        IRtkResponse<IOutsourcingCompany>,
        IOutsourcingCompany
      >({
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/food-outsourcing-companies',
            data,
          }
        },
        transformResponse: (response: IOutsourcingCompany, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          {
            type: ApiKeys.outsourcingCompany,
            id: args.id,
          },
          { type: ApiKeys.outsourcingCompany, id: 'LIST' },
        ],
      }),
      updateOutsourcingCompany: build.mutation<
        IRtkResponse<IOutsourcingCompany>,
        IOutsourcingCompany
      >({
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/food-outsourcing-companies/${data.id}`,
          data,
        }),
        transformResponse: (response: IOutsourcingCompany, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.outsourcingCompany, id: args.id },
          { type: ApiKeys.outsourcingCompany, id: 'LIST' },
        ],
      }),
    }),
  })

const useStateGetOutsourcingCompanies =
  outsourcingCompaniesApi.endpoints.getOutsourcingCompanies.useQueryState
const useStateGetOutsourcingCompany =
  outsourcingCompaniesApi.endpoints.getOutsourcingCompany.useQueryState

export const {
  useGetOutsourcingCompaniesQuery,
  useLazyGetOutsourcingCompaniesQuery,
  useCreateOutsourcingCompanyMutation,
  useUpdateOutsourcingCompanyMutation,
  util,
  usePrefetch,
} = outsourcingCompaniesApi

export { useStateGetOutsourcingCompanies, useStateGetOutsourcingCompany }
