import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import organizationMenuRepository from '@app/api/repositories/organization-menu-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import { FormType } from '@app/constants/formType'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import Pagination from '@app/shared/pagination/pagination'
import {
  getMenuMeasurements,
  getMenuProduct,
  getMenuProducts,
  removeMenuProduct,
  toggleMenuRefresh,
} from '@app/store/actions/organization-menus-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Dropdown, Menu, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuProductForm from './components/menu-product-form'
import './menu-products.less'

const MenuProducts: React.FC = () => {
  const [t] = useTranslation()
  const { selectors } = useCreateSelector()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const { products, measurements, state, productsCount, refresh, formType } =
    useSelector(selectors.menus.menusState)
  const {
    searchParams,
    setSearchParams,
    requestFunction,
    handleTableChange,
    setPage,
    pageAndSize,
  } = usePagination(`page=0&size=10`, {
    page: 1,
    size: 10,
  })
  setDocumentTitle(t('routes.menu'))

  useEffect(() => {
    setSearchParams(new URLSearchParams('page=0&size=10'))
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getMenuProducts(
        { params: searchParams, source },
        t('organizationMenu.errors.products'),
      ),
    )
    if (isEmpty(measurements))
      dispatch(
        getMenuMeasurements(
          { params: new URLSearchParams('page=0&size=100') },
          t('organizationMenu.errors.measurements'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams, refresh])

  const addProduct = (values: any, cb: () => void) => {
    const newValues = {
      ...values,
      data: values.data.filter((item) => !isEmpty(item)),
    }
    const apiCall =
      formType === FormType.ADD
        ? organizationMenuRepository.addMenuProduct(newValues)
        : organizationMenuRepository.updateMenuProduct(values.id, newValues)
    apiCall
      .then((response) => {
        cb()
        dispatch(toggleMenuRefresh())
        openNotificationWithIcon(
          'success',
          t(
            `organizationMenu.products.${
              formType === FormType.EDIT ? 'successEdit' : 'successAdd'
            }`,
          ),
        )
      })
      .catch((error) =>
        openNotificationWithIcon(
          'error',
          t('organizationMenu.products.errorAdd'),
        ),
      )
  }

  const onProductEditHandler = (id: number) => {
    dispatch(
      getMenuProduct({ data: id }, t('organizationMenu.products.errorEdit')),
    )
  }

  const onProductRemoveHandler = (id: number) => {
    dispatch(
      removeMenuProduct(
        { data: id },
        t('organizationMenu.products.successRemove'),
        t('organizationMenu.products.errorRemove'),
      ),
    )
  }

  const permissions = [
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_MENU_ALL_EDIT,
    AUTHORITIES.ROLE_MENU_REGION_EDIT,
    AUTHORITIES.ROLE_MENU_DISTRICT_EDIT,
    AUTHORITIES.ROLE_MENU_CURRENT_EDIT,
  ]

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => (
        <Text>{(pageAndSize.page - 1) * pageAndSize.size + index + 1}</Text>
      ),
    },

    {
      title: t('organizationMenu.products.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1,
      },
    },
    {
      title: t('organizationMenu.products.calorie'),
      dataIndex: 'calorie',
      key: 'calorie',
      sorter: {
        compare: (a, b) => a.calorie - b.calorie,
        multiple: 2,
      },
    },
    {
      title: t('organizationMenu.products.carbohydrate'),
      dataIndex: 'carbohydrate',
      key: 'carbohydrate',
      sorter: {
        compare: (a, b) => a.carbohydrate - b.carbohydrate,
        multiple: 3,
      },
    },
    {
      title: t('organizationMenu.products.fat'),
      dataIndex: 'fat',
      key: 'fat',
      sorter: {
        compare: (a, b) => a.fat - b.fat,
        multiple: 4,
      },
    },
    {
      title: t('organizationMenu.products.measurement'),
      dataIndex: 'measurement',
      key: 'measurement',
      sorter: {
        compare: (a, b) => a.measurement.localeCompare(b.measurement),
        multiple: 5,
      },
    },
    {
      title: t('organizationMenu.products.protein'),
      dataIndex: 'protein',
      key: 'protein',
      sorter: {
        compare: (a, b) => a.protein - b.protein,
        multiple: 6,
      },
    },
    {
      title: t('organizationMenu.products.mxikCode'),
      dataIndex: 'mxikCode',
      key: 'mxikCode',
      sorter: {
        compare: (a, b) => a.mxikCode.localeCompare(b?.mxikCode),
        multiple: 7,
      },
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (record) =>
        rolesChecker(permissions) && record ? (
          <Dropdown
            trigger={['click']}
            placement={'bottomCenter'}
            overlay={() => (
              <Menu>
                <Menu.Item key="edit">
                  <Button
                    type="text"
                    onClick={() => onProductEditHandler(record.id)}
                  >
                    <EditOutlined /> {t('general.edit')}
                  </Button>
                </Menu.Item>
                <Menu.Item key="remove">
                  <Button
                    type="text"
                    onClick={() => onProductRemoveHandler(record.id)}
                  >
                    <DeleteOutlined /> {t('general.delete')}
                  </Button>
                </Menu.Item>
              </Menu>
            )}
          >
            <Button>
              {t('general.action')} <DownOutlined />
            </Button>
          </Dropdown>
        ) : null,
    },
  ]

  const data = !isEmpty(products)
    ? products.map((product) => {
        return {
          key: product.id,
          id: product.id,
          name: localizeData(product.data)?.name,
          calorie: product.calorie,
          carbohydrate: product.carbohydrate,
          fat: product.fat,
          measurement: localizeData(product.measurement.data)?.name,
          protein: product.protein,
          mxikCode: product.mxikCode,
        }
      })
    : []

  return (
    <div className="products">
      <div className="products__header">
        {rolesChecker(permissions) && (
          <MenuProductForm
            addProduct={addProduct}
            measurements={measurements}
          />
        )}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName="table-row"
        loading={state === LOADING}
        pagination={false}
        onChange={handleTableChange}
        footer={() => (
          <Pagination
            request={requestFunction}
            totalCount={productsCount}
            setPage={setPage}
            searchParams={searchParams}
          />
        )}
      />
    </div>
  )
}

export default MenuProducts
