import { ApiKeys } from '@app/constants/api-keys'
import { IOrganizationBuildingType } from '@app/interfaces/organization/organization-building-type'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import localforage from 'localforage'
import { emptyOrganizationApi } from '.'
import { IRtkResponse } from '../../types'

export const organizationBuildingTypeApi = emptyOrganizationApi.injectEndpoints(
  {
    overrideExisting: false,
    endpoints: (build) => ({
      getOrganizationBuildingTypes: build.query<
        IRtkResponse<IOrganizationBuildingType[]>,
        URLSearchParams | string
      >({
        query: (args = '') => ({
          url: 'dictionary/api/v1/organization-building-type',
          method: 'get',
          params: {
            ...resolveTParams(args),
          },
        }),
        transformResponse: (response: IOrganizationBuildingType[], meta) => {
          return { response, meta }
        },
        providesTags: (data) =>
          data
            ? [
                ...data.response.map(({ id }) => ({
                  type: ApiKeys.organizationBuildingType,
                  id,
                })),
                { type: ApiKeys.organizationBuildingType, id: 'LIST' },
              ]
            : [{ type: ApiKeys.organizationBuildingType, id: 'LIST' }],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            localforage.setItem(
              ApiKeys.organizationBuildingType,
              data?.response,
            )
          } catch (e) {
            console.error(e)
          }
        },
      }),
      getOrganizationBuildingType: build.query<
        IOrganizationBuildingType,
        number
      >({
        query: (id) => ({
          url: `dictionary/api/v1/organization-building-type/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          { type: ApiKeys.organizationBuildingType, id },
        ],
      }),
    }),
  },
)

export const {
  useGetOrganizationBuildingTypesQuery,
  useGetOrganizationBuildingTypeQuery,
} = organizationBuildingTypeApi
