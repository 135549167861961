import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEducationDegree,
  IEducationDegreeState,
} from '@app/interfaces/education/education-degree'
import {
  CREATE_EDUCATION_DEGREE,
  FETCH_EDUCATION_DEGREES_FAILURE,
  FETCH_EDUCATION_DEGREES_REQUEST,
  FETCH_EDUCATION_DEGREES_SUCCESS,
  FETCH_EDUCATION_DEGREE_FAILURE,
  FETCH_EDUCATION_DEGREE_REQUEST,
  FETCH_EDUCATION_DEGREE_SUCCESS,
  UPDATE_EDUCATION_DEGREE,
} from '@app/store/types/education-degree'

const initialState: IEducationDegreeState = {
  educationDegree: {} as IEducationDegree,
  educationDegrees: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const educationDegreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EDUCATION_DEGREES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_DEGREES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationDegrees: action.payload.educationDegrees,
        count: action.payload.count,
      }
    case FETCH_EDUCATION_DEGREES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationDegrees: [],
      }
    case FETCH_EDUCATION_DEGREE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_DEGREE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationDegree: action.payload,
      }
    case FETCH_EDUCATION_DEGREE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationDegree: {},
      }
    case CREATE_EDUCATION_DEGREE:
      return {
        ...state,
        educationDegrees: [...state.educationDegrees, action.payload],
      }
    case UPDATE_EDUCATION_DEGREE:
      const updatedEducationDegrees = state.educationDegrees.map(function (
        educationDegree,
        index,
      ) {
        if (action.payload.id === educationDegree.id) {
          return action.payload
        }
        return educationDegree
      })
      return { ...state, educationDegrees: updatedEducationDegrees }
    default:
      return state
  }
}
