import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  current: number
  prev: () => void
  form: any
  confirmLoading: boolean
}

const StepsButtons: React.FC<props> = ({
  prev,
  form,
  current,
  confirmLoading,
}) => {
  const [t, i18n] = useTranslation()
  return (
    <>
      <div>
        {current > 0 && (
          <Button
            disabled={confirmLoading}
            onClick={prev}
            style={{ marginRight: '10px' }}
            type={'default'}
          >
            {t('general.back')}
          </Button>
        )}
        <Button
          disabled={confirmLoading}
          onClick={form.submit}
          htmlType={'submit'}
          type={'primary'}
        >
          {t('general.next')}
        </Button>
      </div>
    </>
  )
}

export default StepsButtons
