import {
  IOrganizationTimesheetPupilActions,
  IOrganizationTimesheetEmployeeActions,
} from '@app/interfaces/organization-timesheet'
import {
  GET_ORGANIZATION_TIMESHEET_BY_EMPLOYEE,
  GET_ORGANIZATION_TIMESHEET_BY_PUPIL,
} from '@app/store/types/organization-timesheet'

export function getOrganizationTimesheetEmployee(
  organizationTimesheetEmployee,
): IOrganizationTimesheetEmployeeActions {
  return {
    type: GET_ORGANIZATION_TIMESHEET_BY_EMPLOYEE,
    payload: organizationTimesheetEmployee,
  }
}

export function getOrganizationTimesheetPupil(
  organizationTimesheetPupil,
): IOrganizationTimesheetPupilActions {
  return {
    type: GET_ORGANIZATION_TIMESHEET_BY_PUPIL,
    payload: organizationTimesheetPupil,
  }
}
