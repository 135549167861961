import { DownOutlined } from '@ant-design/icons'
import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import { IEmployeeRate } from '@app/interfaces/employee/employee-rate'
import { IEmployeeStatus } from '@app/interfaces/employee/employee-status'
import EmployeeChangeRateForm from '@app/pages/organizations/components/organization-employee/components/employee-actions/employee-change-rate-form/employee-change-rate-form'
import EmployeeDecreeForm from '@app/pages/organizations/components/organization-employee/components/employee-actions/employee-decree-form/employee-decree-form'
import EmployeeDismissForm from '@app/pages/organizations/components/organization-employee/components/employee-actions/employee-dismiss-form/employee-dismiss-form'
import EmployeeSickListForm from '@app/pages/organizations/components/organization-employee/components/employee-actions/employee-sick-list-form/employee-sick-list-form'
import EmployeeVacationForm from '@app/pages/organizations/components/organization-employee/components/employee-actions/employee-vacation-form/employee-vacation-form'
import EmployeeOrganizationFilter from '@app/pages/organizations/components/organization-employee/components/employees-organization-filter/employees-organization-filter'
import Pagination from '@app/shared/pagination/pagination'
import { useGetEmployeePositionsDictionaryQuery } from '@app/store/rtk/api/employee/employee-position-dictionary-api'
import { useGetOrganizationEmployeesQuery } from '@app/store/rtk/api/employee/organization-employee-api'
import { capitalizeName } from '@app/utils/capitalize/capitalize'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Dropdown, Menu, Table } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_EMPLOYEE_CURRENT_EDIT,
  AUTHORITIES.ROLE_EMPLOYEE_ALL_EDIT,
  AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_EDIT,
  AUTHORITIES.ROLE_EMPLOYEE_REGION_EDIT,
]

export const EMPLOYEE_STATUSES = 'WORKING,DECREE,VACATION,MEDICAL'

const EmployeesOrganizationTable: React.FC = () => {
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const { data: positions } = useGetEmployeePositionsDictionaryQuery('')
  const { data: rates } = useCache<IEmployeeRate>(ApiKeys.employeeRate)
  const { data: statuses } = useCache<IEmployeeStatus>(ApiKeys.employeeStatus)
  const { localizeData, localize } = useLocalization()
  const { searchParams, requestFunction, pageAndSize, setPage } = usePagination(
    `organizationId.equals=${params.id}&employeePositionStatusType.in=${EMPLOYEE_STATUSES}&page=0&size=10`,
  )
  const {
    data: employees,
    isLoading,
    isFetching,
    refetch,
  } = useGetOrganizationEmployeesQuery(searchParams.toString())
  setDocumentTitle(t('employee.title'))
  useEffect(() => {
    refetch()
  }, [searchParams])
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/organization/${params.id}/employees/employee/${record.employeeId}`}
        >
          {(pageAndSize.page || 0) * pageAndSize.size + index + 1}
        </Link>
      ),
    },
    {
      title: t('employee.position'),
      dataIndex: 'position',
      key: 'position',
      sorter: {
        compare: (a, b) => a.position?.localeCompare(b.position),
        multiple: 1,
      },
    },
    {
      title: t('employee.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName?.localeCompare(b?.fullName),
        multiple: 2,
      },
      render: (text, record) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/organization/${params.id}/employees/employee/${record.employeeId}`}
        >
          {text.toUpperCase()}
        </Link>
      ),
    },
    {
      title: t('employee.pin'),
      dataIndex: 'pin',
      key: 'pin',
    },
    {
      title: t('employee.rate'),
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: t('employee.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (record) =>
        rolesChecker(permissions) && record.employeeStatus ? (
          <Dropdown
            trigger={['click']}
            placement={'bottomCenter'}
            overlay={() => (
              <Menu>
                <Menu.Item key="dismiss">
                  <EmployeeDismissForm employee={record.employee} />
                </Menu.Item>
                <Menu.Item key="decree">
                  <EmployeeDecreeForm employee={record.employee} />
                </Menu.Item>
                <Menu.Item key="change-rate">
                  <EmployeeChangeRateForm
                    employee={record.employee}
                    _employeeRates={rates}
                  />
                </Menu.Item>
                <Menu.Item key="vacation">
                  <EmployeeVacationForm employee={record.employee} />
                </Menu.Item>
                <Menu.Item key="sick-list">
                  <EmployeeSickListForm employee={record.employee} />
                </Menu.Item>
              </Menu>
            )}
          >
            <Button>
              {t('general.action')} <DownOutlined />
            </Button>
          </Dropdown>
        ) : null,
    },
  ]
  const data = employees?.response?.length
    ? employees?.response?.map((employee, index) => {
        return {
          key: index,
          position: localizeData(employee.position.data)?.name,
          id: employee.id,
          fullName: capitalizeName(
            `${employee.employee.firstName} ${employee.employee?.lastName} ${
              employee.employee?.middleName || ''
            }`,
          ),
          pin: employee.employee?.pin,
          rate: localize(rates)?.find((rate) => rate.id === employee.rate.id)
            ?.data.name,
          status: localizeData(employee.status?.data)?.name,
          employee: employee,
          employeeId: employee.employee?.id,
          employeeStatus: employee.status?.type !== 'DISMISSED',
        }
      })
    : []

  return (
    <>
      <div className="header-form">
        <EmployeeOrganizationFilter
          statuses={statuses}
          positions={positions?.response}
          request={requestFunction}
          load={!isLoading}
          searchParams={searchParams}
        />
      </div>
      <Card title={t('employee.title')}>
        <Table
          loading={isFetching}
          pagination={false}
          dataSource={data}
          columns={columns}
          rowClassName="table-row"
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={employees?.meta['x-total-count'] ?? 0}
              setPage={setPage}
              searchParams={searchParams}
              isSuccess={!isFetching}
              isLegacy={false}
            />
          )}
        />
      </Card>
    </>
  )
}

export default EmployeesOrganizationTable
