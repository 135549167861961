import { FilterOutlined } from '@ant-design/icons'
import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import { AUTH_TOKEN } from '@app/constants/authToken'
import { IPathParams } from '@app/constants/path-params'
import useCache from '@app/hooks/useCache'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import useQuery from '@app/hooks/useQuery'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IOrganization } from '@app/interfaces/organization/organization'
import { IRegion } from '@app/interfaces/organization/region'
import { getOrganizations } from '@app/store/actions/organization-actions'
import { findById } from '@app/utils/find-by-id/find-by-id'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Form, FormInstance, Select, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

interface IQueryProps {
  query?: object
  display?: object
}

interface props {
  onShow: (query: string, queryData) => void
  form: FormInstance<any>
}

const OrganizationWorkforceFiltersForm: React.FC<props> = ({
  onShow,
  form,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [regionId, setRegionId] = useState<number>(0)
  const params: IPathParams = useParams()
  const { selectors } = useCreateSelector()
  const { localize } = useLocalization()
  const userInfo = useSelector(selectors.userInfo.userInfo)
  const { serializeQuery } = useQuery()
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts, isLoading } = useCache<IDistrict>(ApiKeys.district)

  const organizations: IOrganization[] = useSelector(
    selectors.organization.organizations,
  )
  useEffect(() => {
    if (userInfo.organizations.length) {
      if (
        regions &&
        userInfo.organizations[0]?.regionId &&
        rolesChecker([
          AUTHORITIES.ROLE_STAFF_TABLE_REGION_VIEW,
          AUTHORITIES.ROLE_STAFF_TABLE_DISTRICT_VIEW,
        ])
      ) {
        setRegionId(userInfo.organizations[0].regionId)
        form.setFieldsValue({
          regionId: userInfo.organizations[0].regionId,
        })
      }
      if (
        districts &&
        userInfo.organizations[0].districtId &&
        rolesChecker([AUTHORITIES.ROLE_STAFF_TABLE_DISTRICT_VIEW])
      ) {
        form.setFieldsValue({
          districtId: userInfo.organizations[0]?.districtId,
        })
        dispatch(
          getOrganizations(
            {
              params: `page=0&size=500&regionId.equals=${regionId}&districtId.equals=${userInfo.organizations[0]?.districtId}`,
            },
            t('organization.error'),
          ),
        )
      }
    }
  }, [regions, districts, userInfo])

  const onRegionChangeHandler = (value) => {
    setRegionId(value)
    form.setFieldsValue({ districtId: undefined })
    form.setFieldsValue({ organizationId: undefined })
  }

  const onDistrictChangeHandler = (value) => {
    dispatch(
      getOrganizations(
        {
          params: `page=0&size=500&regionId.equals=${regionId}&districtId.equals=${value}`,
        },
        t('organization.error'),
      ),
    )
    form.setFieldsValue({ organizationId: undefined })
  }

  const getRegionName = (regionId) => {
    return findById(regions, regionId)?.data.name
  }

  const findDistricts = (id) => {
    return isEmpty(districts)
      ? []
      : districts.filter((district) => district.regionId === id)
  }

  const onFinish = (values) => {
    const organization = values.organizationId
      ? organizations.find((org) => org.id === values.organizationId)
      : undefined
    const district = values.districtId
      ? localize(districts)?.find(
          (district) => district.id === values.districtId,
        ).data.name
      : undefined
    const region = values.regionId
      ? localize(regions)?.find((region) => region.id === values.regionId).data
          .name
      : undefined
    const newValues: IQueryProps = values.organizationId
      ? {
          query: {
            organizationId: values.organizationId,
          },
          display: {
            organization,
            region,
            district,
          },
        }
      : values.districtId
      ? {
          query: {
            districtId: values.districtId,
          },
          display: {
            region,
            district,
          },
        }
      : values.regionId
      ? {
          query: {
            regionId: values.regionId,
          },
          display: {
            region,
          },
        }
      : {}

    onShow(
      serializeQuery({
        query: newValues.query ?? '',
      }),
      newValues.display,
    )
  }

  const onDownloadClick = async () => {
    const fields = form.getFieldsValue()
    const query = params?.id
      ? new URLSearchParams(`organizationId=${params.id}`)
      : fields.organizationId
      ? new URLSearchParams(`organizationId=${fields.organizationId}`)
      : fields.districtId
      ? new URLSearchParams(`districtId=${fields.districtId}`)
      : fields.regionId
      ? new URLSearchParams(`regionId=${fields.regionId}`)
      : new URLSearchParams('')

    const token = localStorage.getItem(AUTH_TOKEN)
    const reader = await fetch(
      `${process.env.REACT_APP_HOST}emis-reports/api/v1/workforce/excel?${query}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-type': 'application/octet-stream',
          responseType: 'stream',
        },
      },
    ).then((res) => res.body)
    readAllChunks(reader).then((data) => {
      const fileURL = URL.createObjectURL(
        new Blob(data, {
          type: 'application/vnd.ms-excel;charset=UTF-8',
        }),
      )
      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', 'Штатное расписание.xls')
      document.body.appendChild(link)
      link.click()
    })
  }

  function readAllChunks(readableStream) {
    const reader = readableStream.getReader()
    const chunks: Uint8Array[] = []

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks
        }
        chunks.push(value)
        return pump()
      })
    }
    return pump()
  }

  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        {!params?.id && (
          <div className="attendance-monitoring__header-row">
            <Form.Item name={'regionId'} label={t('region.region')}>
              <Select
                showSearch={true}
                onChange={onRegionChangeHandler}
                disabled={
                  !rolesChecker([
                    AUTHORITIES.ADMIN,
                    AUTHORITIES.ROLE_STAFF_TABLE_ALL_VIEW,
                  ])
                }
              >
                {sortByName(localize(regions))?.map(
                  ({ data: { name }, id }) => {
                    return (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    )
                  },
                )}
              </Select>
            </Form.Item>
            <Form.Item name={'districtId'} label={t('district.district')}>
              <Select
                showSearch={true}
                onChange={onDistrictChangeHandler}
                disabled={
                  !rolesChecker([
                    AUTHORITIES.ADMIN,
                    AUTHORITIES.ROLE_STAFF_TABLE_ALL_VIEW,
                    AUTHORITIES.ROLE_STAFF_TABLE_REGION_VIEW,
                  ])
                }
              >
                {sortByName(localize(findDistricts(regionId)))
                  ?.filter((district) => {
                    return district.data.name !== getRegionName(regionId)
                  })
                  .map((district) => {
                    return (
                      <Select.Option key={district.id} value={district.id}>
                        {district.data.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item name={'organizationId'} label={t('organization.title')}>
              <Select
                showSearch={true}
                disabled={
                  !rolesChecker([
                    AUTHORITIES.ADMIN,
                    AUTHORITIES.ROLE_STAFF_TABLE_ALL_VIEW,
                    AUTHORITIES.ROLE_STAFF_TABLE_REGION_VIEW,
                    AUTHORITIES.ROLE_STAFF_TABLE_DISTRICT_VIEW,
                  ])
                }
              >
                {sortByName(organizations)?.map((organization) => {
                  return (
                    <Select.Option
                      key={organization.id}
                      value={organization.id}
                    >
                      {organization.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        )}
        <>
          {!params?.id && (
            <Button type="primary" htmlType="submit">
              <FilterOutlined />
              {Object.values(form.getFieldsValue()).some(Boolean)
                ? t('employeeWorkRateList.showScreen')
                : t('employeeWorkRateList.showAll')}
            </Button>
          )}
          <Button
            type="primary"
            style={{ marginLeft: 16 }}
            onClick={onDownloadClick}
          >
            {t('employeeWorkRateList.exportXml')}
          </Button>
        </>
      </Form>
    </Spin>
  )
}

export default OrganizationWorkforceFiltersForm
