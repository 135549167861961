import { IPathParams } from '@app/constants/path-params'
import { workList } from '@app/constants/work-list'
import useLocalization from '@app/hooks/useLocalization'
import { useGetWorkforcesQuery } from '@app/store/rtk/api/organization-passport/organization-workforce-api'
import { Card, Form, Space, Table, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import OrganizationWorkforceFiltersForm from './components/organization-workforce-filters-form'

const { Title } = Typography

const OrganizationWorkforce: React.FC = () => {
  const params: IPathParams = useParams()
  const [skip, setSkip] = useState(!params?.id)
  const [query, setQuery] = useState('')
  const [title, setTitle] = useState('')
  const { localizeData } = useLocalization()
  const [form] = Form.useForm()
  const { data: workforce, isFetching } = useGetWorkforcesQuery(
    params?.id ? `organizationId=${params?.id}` : query,
    { skip },
  )
  const [t] = useTranslation()

  const onShow = (query: string, queryData) => {
    setSkip(false)
    setQuery(query)
    const title = queryData?.organization
      ? `${t('employeeWorkRateList.title')} - ${t('region.region')}: ${
          queryData.region
        }, ${t('district.district')}: ${queryData.district}, ${t(
          'organization.organization',
        )}: ${queryData.organization.name}`
      : queryData?.district
      ? `${t('employeeWorkRateList.title')} - ${t('region.region')}: ${
          queryData.region
        }, ${t('district.district')}: ${queryData.district}`
      : queryData?.region
      ? `${t('employeeWorkRateList.title')} - ${t('region.region')}: ${
          queryData.region
        }`
      : `${t('employeeWorkRateList.title')}`
    setTitle(title)
  }

  const simpleData = useMemo(
    () =>
      workforce?.response.filter(
        (position) => position.employeePosition.gradeType === 'SIMPLE',
      ),
    [workforce],
  )
  const shortTermData = useMemo(() => {
    return workforce?.response.filter(
      (position) => position.employeePosition.gradeType === 'SHORT_TERM',
    )
  }, [workforce])

  const preparatoryData = useMemo(() => {
    return workforce?.response.filter(
      (position) => position.employeePosition.gradeType === 'PREPARATORY',
    )
  }, [workforce])

  const mobileData = useMemo(
    () =>
      workforce?.response.filter(
        (position) => position.employeePosition.gradeType === 'MOBILE',
      ),
    [workforce],
  )

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
      title: t('employeeWorkRateList.jobNames'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) =>
        t(`employeeWorkRateList.${workList[a.type]}`)?.localeCompare(
          t(`employeeWorkRateList.${workList[b.type]}`),
        ),
      render: (text, cell) => (
        <div>{localizeData(cell.employeePosition.data)?.name}</div>
      ),
    },
    {
      title: t('employeeWorkRateList.count'),
      dataIndex: 'workRate',
      key: 'workRate',
      sorter: (a, b) => a.workRate - b.workRate,
    },
  ]
  const dataSources = useMemo(() => {
    return [
      {
        dataSource: simpleData,
        title: t('employeeWorkRateList.tableOne'),
      },
      { dataSource: shortTermData, title: t('employeeWorkRateList.tableTwo') },
      {
        dataSource: preparatoryData,
        title: t('employeeWorkRateList.tableFour'),
      },
      {
        dataSource: mobileData,
        title: t('employeeWorkRateList.tableThree'),
      },
    ]
  }, [simpleData, shortTermData, preparatoryData, mobileData, t])

  return (
    <>
      <Card style={{ padding: 24, marginBottom: 24 }}>
        <OrganizationWorkforceFiltersForm onShow={onShow} form={form} />
      </Card>
      {!skip && (
        <Space wrap direction="vertical" size={16} style={{ width: '100%' }}>
          <Title level={2}>{title}</Title>
          {dataSources.map(({ dataSource, title }, idx) => (
            <Card key={`${title}-${idx}`} title={title}>
              <Table
                columns={columns}
                dataSource={dataSource}
                rowClassName="table-row"
                loading={isFetching}
                pagination={false}
              />
            </Card>
          ))}
        </Space>
      )}
    </>
  )
}

export default OrganizationWorkforce
