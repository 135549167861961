// import { IRepository } from "@api/repositories/i-repository";
import bbitApi from '@api/make-request'
import { IInstitutionType } from '@app/interfaces/organization-passport/institution-type'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import { IPathParamsArgs, IRepository, numberArgs } from './i-repository'

class InstitutionsTypeRepository implements IRepository<IInstitutionType> {
  create(data: IInstitutionType): Promise<AxiosResponse<IInstitutionType>> {
    return bbitApi.post('/dictionary/api/v1/institution-types', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IInstitutionType[]>> {
    return bbitApi.get('/dictionary/api/v1/institution-types', {
      cancelToken: args.source?.token,
      params: resolveParams(args),
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IInstitutionType>> {
    return bbitApi.get(`/dictionary/api/v1/institution-types/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IInstitutionType): Promise<AxiosResponse<IInstitutionType>> {
    return bbitApi.put(`/dictionary/api/v1/institution-types/${data.id}`, data)
  }
}

export default new InstitutionsTypeRepository()
