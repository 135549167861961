import React, { useState } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { useParams } from 'react-router-dom'
import UsersRepository from '@api/repositories/users-repository'
import { LockOutlined } from '@ant-design/icons'
import { IPathParams } from '@app/constants/path-params'

const ChangePassModal: React.FC = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const params: IPathParams = useParams()

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    setConfirmLoading(true)
    const userSubmitApi = UsersRepository.passwordUpdate
    await userSubmitApi(values)
      .then((response) => {
        if (response.status === 200) {
          setConfirmLoading(false)
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
    setConfirmLoading(false)
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'default'}>
        <LockOutlined /> {t('users.changePass')}
      </Button>

      <Modal
        open={visible}
        title={t('users.changePass')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={true}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            id: Number(params.userId),
          }}
          layout={'vertical'}
        >
          <Form.Item hidden={true} name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item label={t('users.password')} name={'password'}>
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default ChangePassModal
