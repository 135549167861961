export const GET_EDUCATION_GRADE = 'GET_EDUCATION_GRADE'
export const GET_EDUCATION_GRADES = 'GET_EDUCATION_GRADES'
export const CREATE_EDUCATION_GRADE = 'CREATE_EDUCATION_GRADE'
export const UPDATE_EDUCATION_GRADE = 'UPDATE_EDUCATION_GRADE'
export const DELETE_EDUCATION_GRADE = 'DELETE_EDUCATION_GRADE'
export const CLEAR_EDUCATION_GRADES = 'CLEAR_EDUCATION_GRADES'

export const FETCH_EDUCATION_GRADES_REQUEST = 'FETCH_EDUCATION_GRADES_REQUEST'
export const FETCH_EDUCATION_GRADES_SUCCESS = 'FETCH_EDUCATION_GRADES_SUCCESS'
export const FETCH_EDUCATION_GRADES_FAILURE = 'FETCH_EDUCATION_GRADES_FAILURE'

export const FETCH_EDUCATION_GRADE_REQUEST = 'FETCH_EDUCATION_GRADE_REQUEST'
export const FETCH_EDUCATION_GRADE_SUCCESS = 'FETCH_EDUCATION_GRADE_SUCCESS'
export const FETCH_EDUCATION_GRADE_FAILURE = 'FETCH_EDUCATION_GRADE_FAILURE'
