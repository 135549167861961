import { ApiKeys } from '@app/constants/api-keys'
import { IWaterSource } from '@app/interfaces/organization-passport/water-sources'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const waterSourcesApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getWaterSources: build.query<
      IRtkResponse<IWaterSource[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/drinking-water-sources',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IWaterSource[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.waterSource,
                id,
              })),
              { type: ApiKeys.waterSource, id: 'LIST' },
            ]
          : [{ type: ApiKeys.waterSource, id: 'LIST' }],
    }),
    getWaterSource: build.query<IWaterSource, number>({
      query: (id) => ({
        url: `dictionary/api/v1/drinking-water-sources/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.waterSource, id }],
    }),
    createWaterSource: build.mutation<IRtkResponse<IWaterSource>, IWaterSource>(
      {
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/drinking-water-sources',
            data,
          }
        },
        transformResponse: (response: IWaterSource, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          {
            type: ApiKeys.waterSource,
            id: args.id,
          },
          { type: ApiKeys.waterSource, id: 'LIST' },
        ],
      },
    ),
    updateWaterSource: build.mutation<IRtkResponse<IWaterSource>, IWaterSource>(
      {
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/drinking-water-sources/${data.id}`,
          data,
        }),
        transformResponse: (response: IWaterSource, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.waterSource, id: args.id },
          { type: ApiKeys.waterSource, id: 'LIST' },
        ],
      },
    ),
  }),
})

const useStateGetWaterSources =
  waterSourcesApi.endpoints.getWaterSources.useQueryState
const useStateGetWaterSource =
  waterSourcesApi.endpoints.getWaterSource.useQueryState

export const {
  useGetWaterSourcesQuery,
  useLazyGetWaterSourcesQuery,
  useCreateWaterSourceMutation,
  useUpdateWaterSourceMutation,
  util,
  usePrefetch,
} = waterSourcesApi

export { useStateGetWaterSources, useStateGetWaterSource }
