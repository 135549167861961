export const GET_INSTITUTION_SCHEDULE = 'GET_INSTITUTION_SCHEDULE'
export const GET_INSTITUTION_SCHEDULES = 'GET_INSTITUTION_SCHEDULES'
export const CREATE_INSTITUTION_SCHEDULE = 'CREATE_INSTITUTION_SCHEDULE'
export const UPDATE_INSTITUTION_SCHEDULE = 'UPDATE_INSTITUTION_SCHEDULE'
export const DELETE_INSTITUTION_SCHEDULE = 'DELETE_INSTITUTION_SCHEDULE'
export const CLEAR_INSTITUTION_SCHEDULE = 'CLEAR_INSTITUTION_SCHEDULE'

export const FETCH_INSTITUTION_SCHEDULES_REQUEST =
  'FETCH_INSTITUTION_SCHEDULES_REQUEST'
export const FETCH_INSTITUTION_SCHEDULES_SUCCESS =
  'FETCH_INSTITUTION_SCHEDULES_SUCCESS'
export const FETCH_INSTITUTION_SCHEDULES_FAILURE =
  'FETCH_INSTITUTION_SCHEDULES_FAILURE'

export const FETCH_INSTITUTION_SCHEDULE_REQUEST =
  'FETCH_INSTITUTION_SCHEDULE_REQUEST'
export const FETCH_INSTITUTION_SCHEDULE_SUCCESS =
  'FETCH_INSTITUTION_SCHEDULE_SUCCESS'
export const FETCH_INSTITUTION_SCHEDULE_FAILURE =
  'FETCH_INSTITUTION_SCHEDULE_FAILURE'
