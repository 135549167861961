import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationType,
  IOrganizationTypeState,
} from '@app/interfaces/organization/organization-type'
import {
  CLEAR_ORGANIZATION_TYPE,
  CREATE_ORGANIZATION_TYPE,
  DELETE_ORGANIZATION_TYPE,
  FETCH_ORGANIZATION_TYPES_FAILURE,
  FETCH_ORGANIZATION_TYPES_REQUEST,
  FETCH_ORGANIZATION_TYPES_SUCCESS,
  FETCH_ORGANIZATION_TYPE_FAILURE,
  FETCH_ORGANIZATION_TYPE_REQUEST,
  FETCH_ORGANIZATION_TYPE_SUCCESS,
  UPDATE_ORGANIZATION_TYPE,
} from '@app/store/types/organization-type'

const initialState: IOrganizationTypeState = {
  organizationTypes: [],
  organizationType: {} as IOrganizationType,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_TYPES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_TYPES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationTypes: action.payload.organizationTypes,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_TYPES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationTypes: [],
      }
    case FETCH_ORGANIZATION_TYPE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_TYPE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationType: action.payload,
      }
    case FETCH_ORGANIZATION_TYPE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationType: {},
      }
    case CLEAR_ORGANIZATION_TYPE:
      return { ...state, organizationTypes: [] }
    case CREATE_ORGANIZATION_TYPE:
      return {
        ...state,
        organizationTypes: [...state.organizationTypes, action.payload],
      }
    case UPDATE_ORGANIZATION_TYPE:
      const updatedOrganizationTypes = state.organizationTypes.map(function (
        organizationType,
      ) {
        if (action.payload.id === organizationType.id) {
          return action.payload
        }
        return organizationType
      })
      return { ...state, organizationTypes: updatedOrganizationTypes }
    case DELETE_ORGANIZATION_TYPE:
      const editedOrganizationTypes = state.organizationTypes.filter(function (
        organizationType,
      ) {
        if (action.payload.id === organizationType.id) {
          return false
        }
        return organizationType
      })
      return { ...state, organizationTypes: editedOrganizationTypes }
    default:
      return state
  }
}
