import { ApiKeys } from '@app/constants/api-keys'
import { IOrganizationContact } from '@app/interfaces/organization/organization-contact'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationApi } from '.'
import { IRtkResponse } from '../../types'

export const organizationContactApi = emptyOrganizationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOrganizationContacts: build.query<
      IRtkResponse<IOrganizationContact[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/organization-contact',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationContact[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.organizationContact,
                id,
              })),
              { type: ApiKeys.organizationContact, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organizationContact, id: 'LIST' }],
    }),
    getOrganizationContact: build.query<IOrganizationContact, number>({
      query: (id) => ({
        url: `dictionary/api/v1/organization-contact/by-organization/${id}`,
        method: 'get',
      }),
      providesTags: (result) => [
        { type: ApiKeys.organizationContact, id: result?.id },
      ],
    }),
    createOrganizationContact: build.mutation<
      IRtkResponse<IOrganizationContact>,
      Partial<IOrganizationContact> &
        Omit<IOrganizationContact, 'id'> &
        Pick<IOrganizationContact, 'organizationId'>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/organization-contact',
          data,
        }
      },
      transformResponse: (response: IOrganizationContact, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result) => [
        {
          type: ApiKeys.organizationContact,
          id: result?.response.organizationId,
        },
        { type: ApiKeys.organizationContact, id: 'LIST' },
      ],
    }),
    updateOrganizationContact: build.mutation<
      IRtkResponse<IOrganizationContact>,
      Partial<IOrganizationContact> &
        Pick<IOrganizationContact, 'id' | 'organizationId'>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/organization-contact/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganizationContact, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationContact, id: arg.id },
        { type: ApiKeys.organizationContact, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetOrganizationContactQuery =
  organizationContactApi.endpoints.getOrganizationContact.useQueryState
const useStateGetOrganizationContactsQuery =
  organizationContactApi.endpoints.getOrganizationContacts.useQueryState

export const {
  useGetOrganizationContactsQuery,
  useGetOrganizationContactQuery,
  useCreateOrganizationContactMutation,
  useUpdateOrganizationContactMutation,
  util,
  usePrefetch,
} = organizationContactApi

export {
  useStateGetOrganizationContactQuery,
  useStateGetOrganizationContactsQuery,
}
