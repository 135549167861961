import React from 'react'
import './threeDots.css'

interface props {
  style?: string
  height?: number
  padding?: number
}

const ThreeDots: React.FC<props> = ({ style, height, padding }) => {
  return (
    <div
      className="dot-wrapper"
      style={{
        height: `${height}px`,
        paddingTop: `${padding}px`,
        paddingBottom: `${padding}px`,
      }}
    >
      <div className={`${style}`} />
    </div>
  )
}

export default ThreeDots
