import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync'
import rootReducer from './root-reducer'
import { emptyEducationApi } from './rtk/api/education'
import { emptyEmployeeApi } from './rtk/api/employee'
import { languageApi } from './rtk/api/languages-api'
import { nationalityApi } from './rtk/api/nationality-api'
import { emptyOrganizationApi } from './rtk/api/organization'
import { organizationGrowthApi } from './rtk/api/organization-growth-api'
import { emptyOrganizationPassportApi } from './rtk/api/organization-passport'
import { organizationPupilApi } from './rtk/api/organization-pupil-api'
import { reasonsApi } from './rtk/api/reasons-api'
import { userInfoApi } from './rtk/api/user-info-api'

const middlewares = [
  createStateSyncMiddleware({}),
  languageApi.middleware,
  reasonsApi.middleware,
  userInfoApi.middleware,
  nationalityApi.middleware,
  organizationGrowthApi.middleware,
  organizationPupilApi.middleware,
  emptyEducationApi.middleware,
  emptyOrganizationApi.middleware,
  emptyOrganizationPassportApi.middleware,
  emptyEmployeeApi.middleware,
]

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
})

setupListeners(store.dispatch)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
initStateWithPrevTab(store)
