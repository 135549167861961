import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { IUserInfo } from '@app/interfaces/user-info'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'

interface props {
  path: string
  exact?: boolean
  component: React.FC
  roles?: string[]
  accessRoute?: string
  userInfo?: IUserInfo
}

const RouteGuard: React.FC<props> = ({
  component: Component,
  roles,
  userInfo,
  ...rest
}) => {
  const location = useLocation()
  // GUARD
  if (!userInfo?.id && location.pathname !== '/auth') {
    return <Navigate to={'/auth'} />
  }
  if (userInfo?.id && location.pathname === '/auth') {
    return <Navigate to={'/profile'} />
  }
  if (roles && userInfo?.id && !rolesChecker(roles)) {
    return <Navigate to={'/profile'} />
  }
  // GUARD
  return <Component {...rest} />
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo.userInfo,
  }
}

export default connect(mapStateToProps)(RouteGuard)
