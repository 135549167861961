import { SelectProps } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const PUPIL_FILTER_STATUSES = {
  APPROVED: 'APPROVED',
  DISMISS: 'DISMISS',
  QUASH: 'QUASH',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
}

export const usePupilStatuses = () => {
  const [t] = useTranslation()
  return useMemo(
    (): SelectProps['options'] =>
      Object.values(PUPIL_FILTER_STATUSES).map((status) => ({
        value: status,
        label: t(`pupils.status.${status}`),
      })),
    [t],
  )
}
