import { getForeignPupil } from '@api/rest/registry-office/get-pupil'
import { IRegistryPupil } from '@app/interfaces/pupil/pupils'
import StepsButtons from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/steps-buttons'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { removeFile } from '@app/store/actions/files-actions'
import {
  getMetricNumber,
  getPupilBirthdate,
} from '@app/store/actions/pupil-manual-add-actions'
import { getRegistryPupils } from '@app/store/actions/pupils-actions'
import { getPupilAge } from '@app/utils/get-pupil-age/get-pupil-age'
import { DatePicker, Form, Input } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  current: number
  next: () => void
  prev: () => void
  metricNumber: string
  metricSerial: string
  pupilBirthday: string
  setConfirmLoading: (data: boolean) => void
  loading: boolean
}

const FindForeignPupilForm: React.FC<props> = ({
  pupilBirthday,
  setConfirmLoading,
  prev,
  next,
  metricNumber,
  metricSerial,
  current,
  loading,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const onFinish = (values: any) => {
    dispatch(getMetricNumber(values.metric))
    dispatch(getPupilBirthdate(values['birthDate'].format(dateFormat)))
    const newValues = {
      ...values,
      birthDate: values['birthDate'].format(dateFormat),
    }
    setConfirmLoading(true)
    getForeignPupil(newValues)
      .then((response: AxiosResponse<IRegistryPupil>) => {
        if (response.data.study) {
          openNotificationWithIcon(
            'error',
            `${t('pupils.alreadyStudied')}${
              response.data.pupilEducation?.educationGroup?.id
            }`,
          )
          setConfirmLoading(false)
        } else {
          dispatch(removeFile())
          dispatch(getRegistryPupils(response.data))
          setConfirmLoading(false)
          next()
        }
      })
      .catch((error) => {
        openNotificationWithIcon('error', t('errors.foreignPupilNotFound'))
        setConfirmLoading(false)
        next()
      })
  }

  const dateFormat = 'DD.MM.YYYY'
  return (
    <div className="pupil-search-foreign">
      <Form
        form={form}
        className="pupil-search-foreign__form"
        initialValues={{
          metric: metricNumber,
          birthDate: pupilBirthday ? moment(pupilBirthday, dateFormat) : null,
        }}
        onFinish={onFinish}
        layout={'vertical'}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
            {
              max: 50,
              message: t('errors.max7Message'),
            },
          ]}
          name={'metric'}
          label={t('pupils.metric')}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              validator: async (_, date) => {
                if (getPupilAge(date) > 7) {
                  return Promise.reject(
                    new Error(t('pupils.form.errors.minAge')),
                  )
                }
              },
            },
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          label={t('pupils.birthDate')}
          name={'birthDate'}
        >
          <DatePicker format={dateFormat} locale={locale} />
        </Form.Item>
      </Form>
      <StepsButtons
        confirmLoading={loading}
        form={form}
        prev={prev}
        current={current}
      />
    </div>
  )
}

export default FindForeignPupilForm
