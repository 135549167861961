import { Result } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

function NotFound() {
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle="Извините, данная страница не существует"
        extra={<Link to={'/profile'}>Перейти в профиль</Link>}
      />
    </>
  )
}

export default NotFound
