// Districts

export const GET_DISTRICTS = 'GET_DISTRICTS'
export const GET_DISTRICTS_BY_REGIONID = 'GET_DISTRICTS_BY_REGIONID'
export const GET_DISTRICT = 'GET_DISTRICT'
export const CREATE_DISTRICT = 'CREATE_DISTRICT'
export const UPDATE_DISTRICT = 'UPDATE_DISTRICT'
export const DELETE_DISTRICT = 'DELETE_DISTRICT'
export const CLEAR_DISTRICTS = 'CLEAR_DISTRICTS'

export const FETCH_DISTRICTS_REQUEST = 'FETCH_DISTRICTS_REQUEST'
export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS'
export const FETCH_DISTRICTS_FAILURE = 'FETCH_DISTRICTS_FAILURE'

export const FETCH_DISTRICTS_REGIONID_REQUEST =
  'FETCH_DISTRICTS_REGIONID_REQUEST'
export const FETCH_DISTRICTS_REGIONID_SUCCESS =
  'FETCH_DISTRICTS_REGIONID_SUCCESS'
export const FETCH_DISTRICTS_REGIONID_FAILURE =
  'FETCH_DISTRICTS_REGIONID_FAILURE'

export const FETCH_DISTRICT_REQUEST = 'FETCH_DISTRICT_REQUEST'
export const FETCH_DISTRICT_SUCCESS = 'FETCH_DISTRICT_SUCCESS'
export const FETCH_DISTRICT_FAILURE = 'FETCH_DISTRICT_FAILURE'
