import { FormType } from '@app/constants/formType'
import { langs } from '@app/constants/languages'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { toggleMenuFormType } from '@app/store/actions/organization-menus-actions'
import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

interface props {
  addMealType: (values: any, cb: () => void) => void
}

const MenuMealTypesForm: React.FC<props> = ({ addMealType }) => {
  const [form] = Form.useForm()
  const { selectors } = useCreateSelector()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localize, localizeData } = useLocalization()
  const { mealType, formType } = useSelector(selectors.menus.menusState)
  const showModal = () => {
    dispatch(toggleMenuFormType(FormType.ADD))
  }

  const hideModal = () => {
    dispatch(toggleMenuFormType(FormType.NONE))
    form.resetFields()
  }

  useEffect(() => {
    if (formType === FormType.EDIT) {
      const newData = mealType.data.sort((a, b) => {
        return a.languageId - b.languageId
      })
      newData.unshift(undefined)
      form.setFieldsValue({
        id: mealType.id,
        calculationStrategy: mealType.calculationStrategy,
        data: newData,
      })
    }
  }, [mealType])

  const onFormSubmitHandler = (values: any) => {
    addMealType(values, hideModal)
  }

  return (
    <>
      <Button className={``} onClick={showModal} type={'primary'}>
        {t('general.add')}
      </Button>
      <Modal
        open={formType !== FormType.NONE}
        title={t('organizationMenu.mealTypes.add')}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <Button type={'primary'} onClick={form.submit}>
              {t('general.accept')}
            </Button>
            <Button
              type={'default'}
              onClick={hideModal}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Button>
          </React.Fragment>,
        ]}
      >
        <Form form={form} onFinish={onFormSubmitHandler} layout={'vertical'}>
          <Form.Item hidden={true} name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            label={t('general.name')}
          >
            <Form.List name="data">
              {() =>
                langs
                  .sort((a, b) => {
                    return a.languageId - b.languageId
                  })
                  .map(({ id, languageId, toShowCode }) => (
                    <React.Fragment key={id}>
                      <Form.Item hidden={true} name={[languageId, 'id']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[languageId, 'name']}
                        rules={[
                          {
                            required: true,
                            message: t('errors.requiredMessage'),
                          },
                          {
                            min: 2,
                            message: t('errors.minMessage'),
                          },
                        ]}
                      >
                        <Input addonBefore={toShowCode} />
                      </Form.Item>
                      <Form.Item
                        hidden={true}
                        name={[id, 'languageId']}
                        initialValue={id}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))
              }
            </Form.List>
          </Form.Item>
          <Form.Item
            label={t('organizationMenu.mealTypes.calculationStrategy')}
            name="calculationStrategy"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default MenuMealTypesForm
