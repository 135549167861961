import {
  CREATE_NATIONALITY,
  DELETE_NATIONALITY,
  GET_NATIONALITIES,
  GET_NATIONALITY,
  UPDATE_NATIONALITY,
  CLEAR_NATIONALITY,
} from '@app/store/types/nationality'
import { INationalityActions } from '@app/interfaces/nationality'

export function getNationality(nationality): INationalityActions {
  return {
    type: GET_NATIONALITY,
    payload: nationality,
  }
}

export function getNationalities(nationalities): INationalityActions {
  return {
    type: GET_NATIONALITIES,
    payload: nationalities,
  }
}

export function clearNationalities() {
  return {
    type: CLEAR_NATIONALITY,
    payload: null,
  }
}

export function updateNationality(nationality): INationalityActions {
  return {
    type: UPDATE_NATIONALITY,
    payload: nationality,
  }
}

export function createNationality(nationality): INationalityActions {
  return {
    type: CREATE_NATIONALITY,
    payload: nationality,
  }
}

export function deleteNationality(nationality): INationalityActions {
  return {
    type: DELETE_NATIONALITY,
    payload: nationality,
  }
}
