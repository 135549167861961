export const maleLine = [
  {
    age: 0,
    positiveMaleHeight: 50,
    positiveMaleWeight: 3.5,
    neutralMalePlusHeight: 54,
    neutralMalePlusWeight: 4.5,
    neutralMaleMinusHeight: 45,
    neutralMaleMinusWeight: 2.5,
    criticalMalePlusHeight: 58,
    criticalMalePlusWeight: 5,
    criticalMaleMinusHeight: 44,
    criticalMaleMinusWeight: 2,
    gender: 'MALE',
  },
  {
    age: 1,
    positiveMaleHeight: 76,
    positiveMaleWeight: 9.5,
    neutralMalePlusHeight: 80,
    neutralMalePlusWeight: 12,
    neutralMaleMinusHeight: 71,
    neutralMaleMinusWeight: 7.5,
    criticalMalePlusHeight: 82.5,
    criticalMalePlusWeight: 13.2,
    criticalMaleMinusHeight: 69,
    criticalMaleMinusWeight: 7,
    gender: 'MALE',
  },
  {
    age: 2,
    positiveMaleHeight: 87.5,
    positiveMaleWeight: 12.1,
    neutralMalePlusHeight: 94,
    neutralMalePlusWeight: 15,
    neutralMaleMinusHeight: 82,
    neutralMaleMinusWeight: 9.5,
    criticalMalePlusHeight: 97,
    criticalMalePlusWeight: 17,
    criticalMaleMinusHeight: 78,
    criticalMaleMinusWeight: 8.4,
    gender: 'MALE',
  },
  {
    age: 3,
    positiveMaleHeight: 92,
    positiveMaleWeight: 14.2,
    neutralMalePlusHeight: 103,
    neutralMalePlusWeight: 18.2,
    neutralMaleMinusHeight: 89,
    neutralMaleMinusWeight: 11.3,
    criticalMalePlusHeight: 107.5,
    criticalMalePlusWeight: 20.8,
    criticalMaleMinusHeight: 85,
    criticalMaleMinusWeight: 10,
    gender: 'MALE',
  },
  {
    age: 4,
    positiveMaleHeight: 103,
    positiveMaleWeight: 16.2,
    neutralMalePlusHeight: 112,
    neutralMalePlusWeight: 21,
    neutralMaleMinusHeight: 95,
    neutralMaleMinusWeight: 12.8,
    criticalMalePlusHeight: 116,
    criticalMalePlusWeight: 24,
    criticalMaleMinusHeight: 91,
    criticalMaleMinusWeight: 11.2,
    gender: 'MALE',
  },
  {
    age: 5,
    positiveMaleHeight: 110,
    positiveMaleWeight: 18.5,
    neutralMalePlusHeight: 119,
    neutralMalePlusWeight: 24.1,
    neutralMaleMinusHeight: 101,
    neutralMaleMinusWeight: 14.2,
    criticalMalePlusHeight: 124,
    criticalMalePlusWeight: 28,
    criticalMaleMinusHeight: 96,
    criticalMaleMinusWeight: 12.9,
    gender: 'MALE',
  },
  {
    age: 6,
    positiveMaleHeight: 116,
    positiveMaleWeight: 20.5,
    neutralMalePlusHeight: 126,
    neutralMalePlusWeight: 27,
    neutralMaleMinusHeight: 106,
    neutralMaleMinusWeight: 16,
    criticalMalePlusHeight: 131,
    criticalMalePlusWeight: 31.5,
    criticalMaleMinusHeight: 101,
    criticalMaleMinusWeight: 14,
    gender: 'MALE',
  },
  {
    age: 7,
    positiveMaleHeight: 122,
    positiveMaleWeight: 23,
    neutralMalePlusHeight: 132.5,
    neutralMalePlusWeight: 31,
    neutralMaleMinusHeight: 111,
    neutralMaleMinusWeight: 17.5,
    criticalMalePlusHeight: 137.5,
    criticalMalePlusWeight: 36,
    criticalMaleMinusHeight: 106,
    criticalMaleMinusWeight: 15.9,
    gender: 'MALE',
  },
  {
    age: 8,
    positiveMaleHeight: 128,
    positiveMaleWeight: 25.5,
    neutralMalePlusHeight: 139,
    neutralMalePlusWeight: 35,
    neutralMaleMinusHeight: 118,
    neutralMaleMinusWeight: 19.5,
    criticalMalePlusHeight: 150,
    criticalMalePlusWeight: 41.5,
    criticalMaleMinusHeight: 110,
    criticalMaleMinusWeight: 17.1,
    gender: 'MALE',
  },
]

export const femaleLine = [
  {
    age: 0,
    positiveFemaleHeight: 48,
    positiveFemaleWeight: 3,
    neutralFemalePlusHeight: 52.5,
    neutralFemalePlusWeight: 4,
    neutralFemaleMinusHeight: 45.5,
    neutralFemaleMinusWeight: 2.2,
    criticalFemalePlusHeight: 55,
    criticalFemalePlusWeight: 4.8,
    criticalFemaleMinusHeight: 44,
    criticalFemaleMinusWeight: 2,
    gender: 'FEMALE',
  },
  {
    age: 1,
    positiveFemaleHeight: 74,
    positiveFemaleWeight: 9,
    neutralFemalePlusHeight: 78,
    neutralFemalePlusWeight: 11.5,
    neutralFemaleMinusHeight: 69,
    neutralFemaleMinusWeight: 7,
    criticalFemalePlusHeight: 82,
    criticalFemalePlusWeight: 13,
    criticalFemaleMinusHeight: 66,
    criticalFemaleMinusWeight: 6.2,
    gender: 'FEMALE',
  },
  {
    age: 2,
    positiveFemaleHeight: 86,
    positiveFemaleWeight: 11.5,
    neutralFemalePlusHeight: 92.5,
    neutralFemalePlusWeight: 15,
    neutralFemaleMinusHeight: 80,
    neutralFemaleMinusWeight: 9,
    criticalFemalePlusHeight: 96,
    criticalFemalePlusWeight: 17,
    criticalFemaleMinusHeight: 77,
    criticalFemaleMinusWeight: 8,
    gender: 'FEMALE',
  },
  {
    age: 3,
    positiveFemaleHeight: 95,
    positiveFemaleWeight: 14,
    neutralFemalePlusHeight: 102.5,
    neutralFemalePlusWeight: 18,
    neutralFemaleMinusHeight: 87.5,
    neutralFemaleMinusWeight: 10.8,
    criticalFemalePlusHeight: 106,
    criticalFemalePlusWeight: 21,
    criticalFemaleMinusHeight: 84,
    criticalFemaleMinusWeight: 9.5,
    gender: 'FEMALE',
  },
  {
    age: 4,
    positiveFemaleHeight: 102.5,
    positiveFemaleWeight: 16,
    neutralFemalePlusHeight: 112,
    neutralFemalePlusWeight: 21.5,
    neutralFemaleMinusHeight: 94,
    neutralFemaleMinusWeight: 12.3,
    criticalFemalePlusHeight: 116,
    criticalFemalePlusWeight: 25,
    criticalFemaleMinusHeight: 90,
    criticalFemaleMinusWeight: 11,
    gender: 'FEMALE',
  },
  {
    age: 5,
    positiveFemaleHeight: 110,
    positiveFemaleWeight: 18.1,
    neutralFemalePlusHeight: 119,
    neutralFemalePlusWeight: 25,
    neutralFemaleMinusHeight: 100,
    neutralFemaleMinusWeight: 14,
    criticalFemalePlusHeight: 124,
    criticalFemalePlusWeight: 29.5,
    criticalFemaleMinusHeight: 95,
    criticalFemaleMinusWeight: 12,
    gender: 'FEMALE',
  },
  {
    age: 6,
    positiveFemaleHeight: 115,
    positiveFemaleWeight: 20,
    neutralFemalePlusHeight: 125,
    neutralFemalePlusWeight: 28,
    neutralFemaleMinusHeight: 105,
    neutralFemaleMinusWeight: 15.2,
    criticalFemalePlusHeight: 130,
    criticalFemalePlusWeight: 33.5,
    criticalFemaleMinusHeight: 100,
    criticalFemaleMinusWeight: 13.5,
    gender: 'FEMALE',
  },
  {
    age: 7,
    positiveFemaleHeight: 121,
    positiveFemaleWeight: 22.4,
    neutralFemalePlusHeight: 132,
    neutralFemalePlusWeight: 31.5,
    neutralFemaleMinusHeight: 110,
    neutralFemaleMinusWeight: 17,
    criticalFemalePlusHeight: 137,
    criticalFemalePlusWeight: 38.2,
    criticalFemaleMinusHeight: 105,
    criticalFemaleMinusWeight: 14.8,
    gender: 'FEMALE',
  },
  {
    age: 8,
    positiveFemaleHeight: 127,
    positiveFemaleWeight: 25,
    neutralFemalePlusHeight: 138,
    neutralFemalePlusWeight: 36,
    neutralFemaleMinusHeight: 115,
    neutralFemaleMinusWeight: 18.5,
    criticalFemalePlusHeight: 144,
    criticalFemalePlusWeight: 44,
    criticalFemaleMinusHeight: 109,
    criticalFemaleMinusWeight: 16.2,
    gender: 'FEMALE',
  },
]
