import React, { useEffect, useMemo } from 'react'
import { Pagination as AntdPagination } from 'antd'
import useQuery from '@app/hooks/useQuery'
interface props {
  request: (string) => void
  totalCount: string
  setPage?: (page: number, size: number) => void
  staticSize?: number
  hideSizeChanger?: boolean
  searchParams: URLSearchParams
  isSuccess?: boolean
  isLegacy?: boolean
}

export interface PageAndSize {
  page: number
  size: number
}

const Pagination: React.FC<props> = ({
  request,
  totalCount,
  setPage,
  staticSize,
  hideSizeChanger,
  searchParams,
  isSuccess,
  isLegacy = true,
}) => {
  const { serializeQuery } = useQuery()
  const getQueryParams = (key: string) => {
    const params = Object.fromEntries(searchParams.entries())
    return params[key]
  }
  const size = useMemo(() => Number(getQueryParams('size')), [searchParams])
  const page = useMemo(() => Number(getQueryParams('page')), [searchParams])

  useEffect(() => {
    if (size && setPage && !isLegacy && isSuccess) {
      setPage(page, size)
    }
  }, [isSuccess])

  const changePage = (page: number, pageSize?: number) =>
    handlePaginationPage(page, pageSize)

  const handlePaginationPage = (page: number, size?: number) => {
    request(
      serializeQuery({
        query: {
          page: page - 1,
          size: size,
        },
        searchParams: searchParams.toString(),
      }),
    )
    if (size && setPage && isLegacy) {
      setPage(page, size)
    }
  }
  return (
    <>
      <AntdPagination
        total={Number(totalCount)}
        defaultPageSize={staticSize ? staticSize : size ? size : 10}
        defaultCurrent={1}
        current={page ? page + 1 : 1}
        showSizeChanger={hideSizeChanger ? false : true}
        onChange={changePage}
      />
    </>
  )
}

export default Pagination
