import React from 'react'
import { useEffect } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './get-access-code.less'
import queryString from 'query-string'
import { loginViaEgov } from '@api/rest/userApi'
import { AUTH_TOKEN } from '@app/constants/authToken'
import axios from 'axios'
import { IUserInfoState } from '@app/interfaces/user-info'
import { connect, useDispatch } from 'react-redux'
import { getUserInfo } from '@app/store/actions/user-info-actions'
import { useNavigate } from 'react-router'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { useTranslation } from 'react-i18next'

interface props {
  _userInfoState: IUserInfoState
}

const GetAccessCode: React.FC<props> = ({ _userInfoState }) => {
  const { message, userInfo: userInfo } = _userInfoState
  const dispatch = useDispatch()
  const value = queryString.parse(window.location.search)
  const navigate = useNavigate()
  const code = value.code
  const [t] = useTranslation()

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (userInfo) navigate('/profile')
    if (message) openNotificationWithIcon('error', t('errors.corsError'))
    loginViaEgov(code)
      .then((response) => {
        if (response && response.data.access_token) {
          localStorage.setItem(AUTH_TOKEN, response.data.access_token)
          dispatch(getUserInfo())
        }
      })
      .catch((errors) => {
        openNotificationWithIcon('error', errors.response.data)
        navigate('/auth')
      })
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />
  return (
    <>
      <div className={'get-access-code__wrapper'}>
        <Spin indicator={antIcon} />
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _userInfoState: state.userInfo,
  }
}

export default connect(mapStateToProps)(GetAccessCode)
