import { ApiKeys } from '@app/constants/api-keys'
import { IFoodPreparingPlaces } from '@app/interfaces/organization-passport/food-preparing-places'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const foodPreparingPlacesApi =
  emptyOrganizationPassportApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getFoodPreparingPlaces: build.query<
        IRtkResponse<IFoodPreparingPlaces[]>,
        URLSearchParams | string
      >({
        query: (args = '') => ({
          url: 'dictionary/api/v1/food-preparing-places',
          method: 'get',
          params: {
            ...resolveTParams(args),
          },
        }),
        transformResponse: (response: IFoodPreparingPlaces[], meta) => {
          return { response, meta }
        },
        providesTags: (data) =>
          data
            ? [
                ...data.response.map(({ id }) => ({
                  type: ApiKeys.foodPreparingPlaces,
                  id,
                })),
                { type: ApiKeys.foodPreparingPlaces, id: 'LIST' },
              ]
            : [{ type: ApiKeys.foodPreparingPlaces, id: 'LIST' }],
      }),
      getFoodPreparingPlace: build.query<IFoodPreparingPlaces, number>({
        query: (id) => ({
          url: `dictionary/api/v1/food-preparing-places/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          { type: ApiKeys.foodPreparingPlaces, id },
        ],
      }),
      createFoodPreparingPlace: build.mutation<
        IRtkResponse<IFoodPreparingPlaces>,
        IFoodPreparingPlaces
      >({
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/food-preparing-places',
            data,
          }
        },
        transformResponse: (response: IFoodPreparingPlaces, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          {
            type: ApiKeys.foodPreparingPlaces,
            id: args.id,
          },
          { type: ApiKeys.foodPreparingPlaces, id: 'LIST' },
        ],
      }),
      updateFoodPreparingPlace: build.mutation<
        IRtkResponse<IFoodPreparingPlaces>,
        IFoodPreparingPlaces
      >({
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/food-preparing-places/${data.id}`,
          data,
        }),
        transformResponse: (response: IFoodPreparingPlaces, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.foodPreparingPlaces, id: args.id },
          { type: ApiKeys.foodPreparingPlaces, id: 'LIST' },
        ],
      }),
    }),
  })

const useStateGetFoodPreparingPlaces =
  foodPreparingPlacesApi.endpoints.getFoodPreparingPlaces.useQueryState
const useStateGetFoodPreparingPlace =
  foodPreparingPlacesApi.endpoints.getFoodPreparingPlace.useQueryState

export const {
  useGetFoodPreparingPlacesQuery,
  useLazyGetFoodPreparingPlacesQuery,
  useCreateFoodPreparingPlaceMutation,
  useUpdateFoodPreparingPlaceMutation,
  util,
  usePrefetch,
} = foodPreparingPlacesApi

export { useStateGetFoodPreparingPlaces, useStateGetFoodPreparingPlace }
