import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import { IOrganizationContact } from '@app/interfaces/organization/organization-contact'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  useCreateOrganizationContactMutation,
  useUpdateOrganizationContactMutation,
} from '@app/store/rtk/api/organization/organization-contact-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Spin } from 'antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
interface props {
  organizationContacts?: IOrganizationContact
}

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_SYSTEM,
  AUTHORITIES.SUSER,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_ALL_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_REGION_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_DISTRICT_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CONTACT_CURRENT_EDIT,
]
const OrganizationContactsForm: React.FC<props> = ({
  organizationContacts,
}) => {
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const params: IPathParams = useParams()
  const [visible, setVisible] = useState<boolean>(false)
  const [createContact, { isLoading: createLoading }] =
    useCreateOrganizationContactMutation()
  const [updateContact, { isLoading: updateLoading }] =
    useUpdateOrganizationContactMutation()
  const showDrawer = () => setVisible(true)
  const onClose = () => setVisible(false)
  const loading = useMemo(
    () => createLoading || updateLoading,
    [createLoading, updateLoading],
  )

  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      id: organizationContacts?.id ?? null,
    }
    const districtSubmitApi = organizationContacts?.id
      ? updateContact
      : createContact
    await districtSubmitApi(newValues)
      .unwrap()
      .then(() => {
        if (organizationContacts?.id) {
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!organizationContacts?.id) {
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        onClose()
      })
  }

  return (
    <>
      <Button onClick={showDrawer} type={'default'}>
        <EditOutlined />
        {t('general.edit')}
      </Button>
      <Drawer
        title={
          organizationContacts?.id
            ? t('organizationContacts.edit')
            : t('organizationContacts.add')
        }
        width={720}
        closeIcon={<CloseOutlined onClick={onClose} />}
        open={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={[
          <React.Fragment key={1}>
            {rolesChecker(permissions) && (
              <Button
                onClick={form.submit}
                disabled={loading}
                htmlType={'submit'}
                type="primary"
              >
                {organizationContacts?.id
                  ? t('general.edit')
                  : t('general.add')}
              </Button>
            )}
            <Button
              disabled={loading}
              onClick={onClose}
              style={{ marginLeft: 8 }}
            >
              {t('general.back')}
            </Button>
          </React.Fragment>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            initialValues={{
              ...organizationContacts,
              organizationId: Number(params.id),
            }}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item hidden={true} name={'organizationId'}>
              <Input />
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('organizationContacts.email')}
                  name={'email'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('organizationContacts.address')}
                  name={'address'}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('organizationContacts.postalCode')}
                  name={'postalCode'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('organizationContacts.site')}
                  name={'site'}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('organizationContacts.phone')}
                  name={'phone'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('organizationContacts.latitude')}
                  name={'latitude'}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('organizationContacts.longitude')}
                  name={'longitude'}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default OrganizationContactsForm
