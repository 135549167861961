import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { IUser, IUserState } from '@app/interfaces/users'
import {
  CLEAR_USER,
  CLEAR_USERS,
  COUNT_USERS,
  CREATE_USER,
  DELETE_USER,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  UPDATE_USER,
} from '@app/store/types/users'
import {
  FETCH_USERS_CLEANUP,
  FETCH_USER_CLEANUP,
  FETCH_USER_FAILURE,
} from './../types/users'

const initialState: IUserState = {
  users: [],
  user: {} as IUser,
  usersCount: 0,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNT_USERS: {
      return { ...state, usersCount: action.payload }
    }
    case CLEAR_USER:
      return { ...state, user: {}, state: IDLE }
    case CLEAR_USERS:
      return { ...state, users: [], state: IDLE }
    case CREATE_USER:
      return { ...state, users: [...state.users, action.payload] }
    case UPDATE_USER:
      const updatedUsers = state.users.map(function (user) {
        if (action.payload.id === user.id) {
          return action.payload
        }
        return user
      })
      return { ...state, users: updatedUsers }
    case DELETE_USER:
      const editedUsers = state.users.filter(function (user) {
        if (action.payload.id === user.id) {
          return false
        }
        return user
      })
      return { ...state, users: editedUsers }
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        users: action.payload.users,
        count: action.payload.count,
      }
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        users: [],
      }
    case FETCH_USERS_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_USER_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        user: action.payload,
      }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        user: {},
      }
    case FETCH_USER_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    default:
      return state
  }
}
