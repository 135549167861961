import React, { useState } from 'react'
import { Button, Form, Input, Modal, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { deleteHoliday } from '@app/store/actions/holiday-actions'
import HolidayRepository from '@app/api/repositories/holidays-repository'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { IHoliday } from '@app/interfaces/holidays'
import { DeleteOutlined } from '@ant-design/icons'
import Text from 'antd/es/typography/Text'

interface props {
  holiday: IHoliday
}

const HolidayRemove: React.FC<props> = ({ holiday }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async () => {
    setConfirmLoading(true)
    const holidayApi = HolidayRepository.remove

    await holidayApi(holiday.id)
      .then(() => {
        dispatch(deleteHoliday(holiday))
        setConfirmLoading(false)
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button
        style={{ marginLeft: '10px' }}
        danger
        onClick={showModal}
        type={'primary'}
      >
        <DeleteOutlined />
        {t('general.delete')}
      </Button>

      <Modal
        open={visible}
        title={t('holiday.delete')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              remove={true}
              confirm={confirmLoading}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              ...holiday,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Text strong>
                {t('holiday.deleteAccept', { name: holiday.note })}
              </Text>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default HolidayRemove
