export const CREATE_EMPLOYEE_EDUCATION = 'CREATE_EMPLOYEE_EDUCATION'
export const UPDATE_EMPLOYEE_EDUCATION = 'UPDATE_EMPLOYEE_EDUCATION'
export const DELETE_EMPLOYEE_EDUCATION = 'DELETE_EMPLOYEE_EDUCATION'
export const CLEAR_EMPLOYEE_EDUCATION = 'CLEAR_EMPLOYEE_EDUCATION'

export const FETCH_EMPLOYEE_EDUCATIONS_REQUEST =
  'FETCH_EMPLOYEE_EDUCATIONS_REQUEST'
export const FETCH_EMPLOYEE_EDUCATIONS_SUCCESS =
  'FETCH_EMPLOYEE_EDUCATIONS_SUCCESS'
export const FETCH_EMPLOYEE_EDUCATIONS_FAILURE =
  'FETCH_EMPLOYEE_EDUCATIONS_FAILURE'

export const FETCH_EMPLOYEE_EDUCATION_REQUEST =
  'FETCH_EMPLOYEE_EDUCATION_REQUEST'
export const FETCH_EMPLOYEE_EDUCATION_SUCCESS =
  'FETCH_EMPLOYEE_EDUCATION_SUCCESS'
export const FETCH_EMPLOYEE_EDUCATION_FAILURE =
  'FETCH_EMPLOYEE_EDUCATION_FAILURE'
