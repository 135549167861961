import { ApiKeys } from '@app/constants/api-keys'
import { IEducationDailySchedule } from '@app/interfaces/education/education-daily-schedule'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import localforage from 'localforage'
import { IRtkResponse } from '../../types'
import { emptyEducationApi } from './index'

export const educationDailyScheduleApi = emptyEducationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getEducationDailySchedules: build.query<
      IRtkResponse<IEducationDailySchedule[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'education/api/v1/education-group-daily-routine-dictionary',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IEducationDailySchedule[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.educationDailySchedule,
                id,
              })),
              { type: ApiKeys.educationDailySchedule, id: 'LIST' },
            ]
          : [{ type: ApiKeys.educationDailySchedule, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.educationDailySchedule, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getEducationDailySchedule: build.query<IEducationDailySchedule, number>({
      query: (id) => ({
        url: `education/api/v1/education-group-daily-routine-dictionary/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.educationDailySchedule, id },
      ],
    }),
  }),
})

export const {
  useGetEducationDailySchedulesQuery,
  useGetEducationDailyScheduleQuery,
} = educationDailyScheduleApi
