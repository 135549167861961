import useCreateSelector from '@app/hooks/useCreateSelector'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

interface IPathParams {
  id?: string
}

const fakeData = [{}]

const OrganizationRooms: React.FC = () => {
  const dispatch = useDispatch()
  const params: IPathParams = useParams()
  const { selectors } = useCreateSelector()
  const { educationGroupRooms } = useSelector(
    selectors.educationGroupRoom.educationGroupRoomState,
  )

  return <div>OrganizationRooms</div>
}

export default OrganizationRooms
