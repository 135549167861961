import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationMenuRepository from '@app/api/repositories/organization-menu-repository'
import { FormType } from '@app/constants/formType'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  CLEAR_PRODUCTS,
  FETCH_MENUS_FAIL,
  FETCH_MENUS_FULFILLED,
  FETCH_MENUS_REQUEST,
  FETCH_MENU_BY_PERIOD_FAIL,
  FETCH_MENU_BY_PERIOD_FULFILLED,
  FETCH_MENU_BY_PERIOD_REQUEST,
  FETCH_MENU_BY_PUPIL_EXTRA_FULFILLED,
  FETCH_MENU_BY_PUPIL_FAIL,
  FETCH_MENU_BY_PUPIL_FULFILLED,
  FETCH_MENU_BY_PUPIL_REQUEST,
  FETCH_MENU_CLEANUP,
  FETCH_MENU_FAIL,
  FETCH_MENU_FULFILLED,
  FETCH_MENU_MEALS_FAIL,
  FETCH_MENU_MEALS_FULFILLED,
  FETCH_MENU_MEALS_REQUEST,
  FETCH_MENU_MEAL_FAIL,
  FETCH_MENU_MEAL_FULFILLED,
  FETCH_MENU_MEAL_REQUEST,
  FETCH_MENU_MEAL_TYPES_FAIL,
  FETCH_MENU_MEAL_TYPES_FULFILLED,
  FETCH_MENU_MEAL_TYPES_REQUEST,
  FETCH_MENU_MEAL_TYPE_FAIL,
  FETCH_MENU_MEAL_TYPE_FULFILLED,
  FETCH_MENU_MEAL_TYPE_REQUEST,
  FETCH_MENU_MEASUREMENTS_FAIL,
  FETCH_MENU_MEASUREMENTS_REQUEST,
  FETCH_MENU_MEASUREMENTS_SUCCESS,
  FETCH_MENU_MEASUREMENT_FAIL,
  FETCH_MENU_MEASUREMENT_FULFILLED,
  FETCH_MENU_MEASUREMENT_REQUEST,
  FETCH_MENU_PRODUCTS_FAIL,
  FETCH_MENU_PRODUCTS_FULFILLED,
  FETCH_MENU_PRODUCTS_REQUEST,
  FETCH_MENU_PRODUCT_FAIL,
  FETCH_MENU_PRODUCT_FULFILLED,
  FETCH_MENU_PRODUCT_REQUEST,
  FETCH_MENU_REQUEST,
  FETCH_SEASONS_FAIL,
  FETCH_SEASONS_FULFILLED,
  FETCH_SEASONS_REQUEST,
  FETCH_SEASON_FAIL,
  FETCH_SEASON_FULFILLED,
  FETCH_SEASON_REQUEST,
  REMOVE_MENU_FAIL,
  REMOVE_MENU_FULFILLED,
  REMOVE_MENU_MEAL_FAIL,
  REMOVE_MENU_MEAL_FULFILLED,
  REMOVE_MENU_MEAL_REQUEST,
  REMOVE_MENU_MEAL_TYPE_FAIL,
  REMOVE_MENU_MEAL_TYPE_FULFILLED,
  REMOVE_MENU_MEAL_TYPE_REQUEST,
  REMOVE_MENU_MEASUREMENT_FAIL,
  REMOVE_MENU_MEASUREMENT_FULFILLED,
  REMOVE_MENU_MEASUREMENT_REQUEST,
  REMOVE_MENU_PRODUCT_FAIL,
  REMOVE_MENU_PRODUCT_FULFILLED,
  REMOVE_MENU_PRODUCT_REQUEST,
  REMOVE_MENU_REQUEST,
  TOGGLE_MENU_FORM_TYPE,
  TOGGLE_MENU_REFRESH,
} from '@app/store/types/organization-menus'
import axios from 'axios'
import {
  CLEAR_MENU_BY_PERIOD,
  CLEAR_MENU_BY_PUPIL,
} from './../types/organization-menus'

export const getMenuByPeriod =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuByPeriodRequest())
    await organizationMenuRepository
      .getMenuByPeriod(args)
      .then((response) => {
        dispatch(
          fetchMenuByPeriodSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuByPeriodFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuByPupil =
  (args: IPathParamsArgs, message: string, isExtra = false) =>
  async (dispatch) => {
    dispatch(fetchMenuByPupilRequest())
    await organizationMenuRepository
      .getMenuByPupil(args)
      .then((response) => {
        if (!isExtra) {
          dispatch(
            fetchMenuByPupilSuccess(
              response.data,
              response.headers['x-total-count'],
            ),
          )
        } else {
          dispatch(fetchMenuByPupilExtraSuccess(response.data))
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuByPupilFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getSeasons =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchSeasonsRequest())
    await organizationMenuRepository
      .getAllSeasons(args)
      .then((response) => {
        dispatch(
          fetchSeasonsSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchSeasonsFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getSeason =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchSeasonRequest())
    await organizationMenuRepository
      .getSeason(args)
      .then((response) => {
        dispatch(fetchSeasonSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchSeasonFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenus =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenusRequest())
    await organizationMenuRepository
      .getAllMenus(args)
      .then((response) => {
        dispatch(
          fetchMenusSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenusFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenu =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuRequest())
    await organizationMenuRepository
      .getMenu(args)
      .then((response) => {
        dispatch(fetchMenuSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const removeMenu =
  (args: numberArgs, success: string, message: string) => async (dispatch) => {
    dispatch(removeMenuRequest())
    await organizationMenuRepository
      .removeMenu(args)
      .then((response) => {
        openNotificationWithIcon('success', success)
        dispatch(removeMenuSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(removeMenuFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuMealTypes =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuMealTypesRequest())
    await organizationMenuRepository
      .getAllMealTypes(args)
      .then((response) => {
        dispatch(
          fetchMenuMealTypesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuMealTypesFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuMealType =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuMealTypeRequest())
    await organizationMenuRepository
      .getMealType(args)
      .then((response) => {
        dispatch(fetchMenuMealTypeSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuMealTypeFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const removeMenuMealType =
  (args: numberArgs, success: string, message: string) => async (dispatch) => {
    dispatch(removeMenuMealTypeRequest())
    await organizationMenuRepository
      .removeMealType(args)
      .then((response) => {
        openNotificationWithIcon('success', success)
        dispatch(removeMenuMealTypeSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(removeMenuMealTypeFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuMeals =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuMealsRequest())
    await organizationMenuRepository
      .getAllMeals(args)
      .then((response) => {
        dispatch(
          fetchMenuMealsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuMealsFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuMeal =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuMealRequest())
    await organizationMenuRepository
      .getMeal(args)
      .then((response) => {
        dispatch(fetchMenuMealSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuMealFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const removeMenuMeal =
  (args: numberArgs, success: string, message: string) => async (dispatch) => {
    dispatch(removeMenuMealRequest())
    await organizationMenuRepository
      .removeMeal(args)
      .then((response) => {
        openNotificationWithIcon('success', success)
        dispatch(removeMenuMealSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(removeMenuMealFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuProducts =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuProductsRequest())
    await organizationMenuRepository
      .getAllProducts(args)
      .then((response) => {
        dispatch(
          fetchMenuProductsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuProductsFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuProduct =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuProductRequest())
    await organizationMenuRepository
      .getProduct(args)
      .then((response) => {
        dispatch(fetchMenuProductSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuProductFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const removeMenuProduct =
  (args: numberArgs, success: string, message: string) => async (dispatch) => {
    dispatch(removeMenuProductRequest())
    await organizationMenuRepository
      .removeProduct(args)
      .then((response) => {
        openNotificationWithIcon('success', success)
        dispatch(removeMenuProductSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(removeMenuProductFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuMeasurements =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuMeasurementsRequest())
    await organizationMenuRepository
      .getAllMeasurements(args)
      .then((response) => {
        dispatch(
          fetchMenuMeasurementsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuMeasurementsFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const getMenuMeasurement =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchMenuMeasurementRequest())
    await organizationMenuRepository
      .getMeasurement(args)
      .then((response) => {
        dispatch(fetchMenuMeasurementSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMenuMeasurementFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

export const removeMenuMeasurement =
  (args: numberArgs, success: string, message: string) => async (dispatch) => {
    dispatch(removeMenuMeasurementRequest())
    await organizationMenuRepository
      .removeMeasurement(args)
      .then((response) => {
        openNotificationWithIcon('success', success)
        dispatch(removeMenuMeasurementSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(removeMenuMeasurementFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchMenuCleanup())
  }

const fetchMenuByPeriodRequest = () => {
  return {
    type: FETCH_MENU_BY_PERIOD_REQUEST,
  }
}

const fetchMenuByPeriodSuccess = (menuByPeriod, count) => {
  return {
    type: FETCH_MENU_BY_PERIOD_FULFILLED,
    payload: { menuByPeriod, count },
  }
}

const fetchMenuByPeriodFailure = (message, code) => {
  return {
    type: FETCH_MENU_BY_PERIOD_FAIL,
    payload: { message, code },
  }
}

const fetchMenuByPupilRequest = () => {
  return {
    type: FETCH_MENU_BY_PUPIL_REQUEST,
  }
}

const fetchMenuByPupilSuccess = (menuByPupil, count) => {
  return {
    type: FETCH_MENU_BY_PUPIL_FULFILLED,
    payload: { menuByPupil, count },
  }
}

const fetchMenuByPupilExtraSuccess = (extraMenu) => {
  return {
    type: FETCH_MENU_BY_PUPIL_EXTRA_FULFILLED,
    payload: extraMenu,
  }
}

const fetchMenuByPupilFailure = (message, code) => {
  return {
    type: FETCH_MENU_BY_PUPIL_FAIL,
    payload: { message, code },
  }
}

const fetchSeasonsRequest = () => {
  return {
    type: FETCH_SEASONS_REQUEST,
  }
}

const fetchSeasonsSuccess = (seasons, count) => {
  return {
    type: FETCH_SEASONS_FULFILLED,
    payload: { seasons, count },
  }
}

const fetchSeasonsFailure = (message, code) => {
  return {
    type: FETCH_SEASONS_FAIL,
    payload: { message, code },
  }
}

const fetchSeasonRequest = () => {
  return {
    type: FETCH_SEASON_REQUEST,
  }
}

const fetchSeasonSuccess = (season) => {
  return {
    type: FETCH_SEASON_FULFILLED,
    payload: { season },
  }
}

const fetchSeasonFailure = (message, code) => {
  return {
    type: FETCH_SEASON_FAIL,
    payload: { message, code },
  }
}

const fetchMenusRequest = () => {
  return {
    type: FETCH_MENUS_REQUEST,
  }
}

const fetchMenusSuccess = (menus, count) => {
  return {
    type: FETCH_MENUS_FULFILLED,
    payload: { menus, count },
  }
}

const fetchMenusFailure = (message, code) => {
  return {
    type: FETCH_MENUS_FAIL,
    payload: { message, code },
  }
}

const fetchMenuRequest = () => {
  return {
    type: FETCH_MENU_REQUEST,
  }
}

const fetchMenuSuccess = (menu) => {
  return {
    type: FETCH_MENU_FULFILLED,
    payload: { menu },
  }
}

const fetchMenuFailure = (message, code) => {
  return {
    type: FETCH_MENU_FAIL,
    payload: { message, code },
  }
}

const removeMenuRequest = () => {
  return {
    type: REMOVE_MENU_REQUEST,
  }
}

const removeMenuSuccess = (menu) => {
  return {
    type: REMOVE_MENU_FULFILLED,
    payload: { menu },
  }
}

const removeMenuFailure = (message, code) => {
  return {
    type: REMOVE_MENU_FAIL,
    payload: { message, code },
  }
}

const fetchMenuMealTypesRequest = () => {
  return {
    type: FETCH_MENU_MEAL_TYPES_REQUEST,
  }
}

const fetchMenuMealTypesSuccess = (mealTypes, count) => {
  return {
    type: FETCH_MENU_MEAL_TYPES_FULFILLED,
    payload: { mealTypes, count },
  }
}

const fetchMenuMealTypesFailure = (message, code) => {
  return {
    type: FETCH_MENU_MEAL_TYPES_FAIL,
    payload: { message, code },
  }
}

const fetchMenuMealTypeRequest = () => {
  return {
    type: FETCH_MENU_MEAL_TYPE_REQUEST,
  }
}

const fetchMenuMealTypeSuccess = (mealType) => {
  return {
    type: FETCH_MENU_MEAL_TYPE_FULFILLED,
    payload: { mealType },
  }
}

const fetchMenuMealTypeFailure = (message, code) => {
  return {
    type: FETCH_MENU_MEAL_TYPE_FAIL,
    payload: { message, code },
  }
}

const removeMenuMealTypeRequest = () => {
  return {
    type: REMOVE_MENU_MEAL_TYPE_REQUEST,
  }
}

const removeMenuMealTypeSuccess = (mealType) => {
  return {
    type: REMOVE_MENU_MEAL_TYPE_FULFILLED,
    payload: { mealType },
  }
}

const removeMenuMealTypeFailure = (message, code) => {
  return {
    type: REMOVE_MENU_MEAL_TYPE_FAIL,
    payload: { message, code },
  }
}

const fetchMenuMealsRequest = () => {
  return {
    type: FETCH_MENU_MEALS_REQUEST,
  }
}

const fetchMenuMealsSuccess = (meals, count) => {
  return {
    type: FETCH_MENU_MEALS_FULFILLED,
    payload: { meals, count },
  }
}

const fetchMenuMealsFailure = (message, code) => {
  return {
    type: FETCH_MENU_MEALS_FAIL,
    payload: { message, code },
  }
}

const fetchMenuMealRequest = () => {
  return {
    type: FETCH_MENU_MEAL_REQUEST,
  }
}

const fetchMenuMealSuccess = (meal) => {
  return {
    type: FETCH_MENU_MEAL_FULFILLED,
    payload: { meal },
  }
}

const fetchMenuMealFailure = (message, code) => {
  return {
    type: FETCH_MENU_MEAL_FAIL,
    payload: { message, code },
  }
}

const removeMenuMealRequest = () => {
  return {
    type: REMOVE_MENU_MEAL_REQUEST,
  }
}

const removeMenuMealSuccess = (meal) => {
  return {
    type: REMOVE_MENU_MEAL_FULFILLED,
    payload: { meal },
  }
}

const removeMenuMealFailure = (message, code) => {
  return {
    type: REMOVE_MENU_MEAL_FAIL,
    payload: { message, code },
  }
}

const fetchMenuProductsRequest = () => {
  return {
    type: FETCH_MENU_PRODUCTS_REQUEST,
  }
}

const fetchMenuProductsSuccess = (products, count) => {
  return {
    type: FETCH_MENU_PRODUCTS_FULFILLED,
    payload: { products, count },
  }
}

const fetchMenuProductsFailure = (message, code) => {
  return {
    type: FETCH_MENU_PRODUCTS_FAIL,
    payload: { message, code },
  }
}

const fetchMenuProductRequest = () => {
  return {
    type: FETCH_MENU_PRODUCT_REQUEST,
  }
}

const fetchMenuProductSuccess = (product) => {
  return {
    type: FETCH_MENU_PRODUCT_FULFILLED,
    payload: { product },
  }
}

const fetchMenuProductFailure = (message, code) => {
  return {
    type: FETCH_MENU_PRODUCT_FAIL,
    payload: { message, code },
  }
}

const removeMenuProductRequest = () => {
  return {
    type: REMOVE_MENU_PRODUCT_REQUEST,
  }
}

const removeMenuProductSuccess = (product) => {
  return {
    type: REMOVE_MENU_PRODUCT_FULFILLED,
    payload: { product },
  }
}

const removeMenuProductFailure = (message, code) => {
  return {
    type: REMOVE_MENU_PRODUCT_FAIL,
    payload: { message, code },
  }
}

const fetchMenuMeasurementsRequest = () => {
  return {
    type: FETCH_MENU_MEASUREMENTS_REQUEST,
  }
}

const fetchMenuMeasurementsSuccess = (measurements, count) => {
  return {
    type: FETCH_MENU_MEASUREMENTS_SUCCESS,
    payload: { measurements, count },
  }
}

const fetchMenuMeasurementsFailure = (message, code) => {
  return {
    type: FETCH_MENU_MEASUREMENTS_FAIL,
    payload: { message, code },
  }
}

const fetchMenuMeasurementRequest = () => {
  return {
    type: FETCH_MENU_MEASUREMENT_REQUEST,
  }
}

const fetchMenuMeasurementSuccess = (measurement) => {
  return {
    type: FETCH_MENU_MEASUREMENT_FULFILLED,
    payload: { measurement },
  }
}

const fetchMenuMeasurementFailure = (message, code) => {
  return {
    type: FETCH_MENU_MEASUREMENT_FAIL,
    payload: { message, code },
  }
}

const removeMenuMeasurementRequest = () => {
  return {
    type: REMOVE_MENU_MEASUREMENT_REQUEST,
  }
}

const removeMenuMeasurementSuccess = (measurement) => {
  return {
    type: REMOVE_MENU_MEASUREMENT_FULFILLED,
    payload: { measurement },
  }
}

const removeMenuMeasurementFailure = (message, code) => {
  return {
    type: REMOVE_MENU_MEASUREMENT_FAIL,
    payload: { message, code },
  }
}

export const toggleMenuFormType = (type: FormType) => {
  return {
    type: TOGGLE_MENU_FORM_TYPE,
    payload: type,
  }
}

export const toggleMenuRefresh = () => {
  return {
    type: TOGGLE_MENU_REFRESH,
  }
}

export const clearProducts = () => {
  return {
    type: CLEAR_PRODUCTS,
  }
}

export const clearMenuByPeriod = () => {
  return {
    type: CLEAR_MENU_BY_PERIOD,
  }
}

export const clearMenuByPupil = () => {
  return {
    type: CLEAR_MENU_BY_PUPIL,
  }
}

const fetchMenuCleanup = () => {
  return {
    type: FETCH_MENU_CLEANUP,
  }
}
