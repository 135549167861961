import EmployeeRepository from '@app/api/repositories/employee-repository'
import { DATE_FORMAT } from '@app/constants/date'
import {
  IEmployee,
  IOrganizationEmployee,
} from '@app/interfaces/employee/employee'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  refreshEmployee,
  updateEmployee,
} from '@app/store/actions/employee-actions'
import { Button, DatePicker, Form, Input, Modal, Spin } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  employee: IOrganizationEmployee
}

const EmployeeVacationForm: React.FC<props> = ({ employee }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      employeePositionId: employee.id ? employee.id : undefined,
      startDate: values['startDate'].format(DATE_FORMAT),
      endDate: values['endDate'].format(DATE_FORMAT),
    }
    setConfirmLoading(true)
    EmployeeRepository.vacation(newValues)
      .then((response: AxiosResponse<IEmployee>) => {
        dispatch(updateEmployee(response.data))
        dispatch(refreshEmployee())
        setConfirmLoading(false)
        openNotificationWithIcon('success', t('employee.vacationSuccess'))
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('employee.vacationError'))
      })
  }
  return (
    <React.Fragment>
      <Button onClick={showModal} type={'link'}>
        {t('employee.vacation')}
      </Button>

      <Modal
        open={visible}
        title={t('employee.vacation')}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ employeeId: employee.employee?.id }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'employeeId'}>
              <Input />
            </Form.Item>
            <Form.Item
              label={t('pupils.orderNo')}
              name={'orderNo'}
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
            >
              <Input disabled={confirmLoading} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'startDate'}
              label={t('employee.vacationStart')}
            >
              <DatePicker
                format={DATE_FORMAT}
                style={{ width: '100%' }}
                locale={locale}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'endDate'}
              label={t('employee.vacationEnd')}
            >
              <DatePicker
                format={DATE_FORMAT}
                style={{ width: '100%' }}
                locale={locale}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default EmployeeVacationForm
