export const GET_TODAYS_ORGANIZATION_MENUS = 'GET_TODAYS_ORGANIZATION_MENUS'
export const TOGGLE_MENU_FORM_TYPE = 'TOGGLE_MENU_FORM_TYPE'
export const TOGGLE_MENU_REFRESH = 'TOGGLE_MENU_REFRESH'
export const FETCH_MENU_CLEANUP = 'FETCH_MENU_CLEANUP'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const CLEAR_MENU_BY_PERIOD = 'CLEAR_MENU_BY_PERIOD'
export const CLEAR_MENU_BY_PUPIL = 'CLEAR_MENU_BY_PUPIL'

// Menu by period
export const FETCH_MENU_BY_PERIOD_REQUEST = 'FETCH_MENU_BY_PERIOD_REQUEST'
export const FETCH_MENU_BY_PERIOD_FULFILLED = 'FETCH_MENU_BY_PERIOD_FULFILLED'
export const FETCH_MENU_BY_PERIOD_FAIL = 'FETCH_MENU_BY_PERIOD_FAIL'

export const REMOVE_MENU_BY_PERIOD_REQUEST = 'REMOVE_MENU_BY_PERIOD_REQUEST'
export const REMOVE_MENU_BY_PERIOD_FULFILLED = 'REMOVE_MENU_BY_PERIOD_FULFILLED'
export const REMOVE_MENU_BY_PERIOD_FAIL = 'REMOVE_MENU_BY_PERIOD_FAIL'

// Menu by pupil
export const FETCH_MENU_BY_PUPIL_REQUEST = 'FETCH_MENU_BY_PUPIL_REQUEST'
export const FETCH_MENU_BY_PUPIL_FULFILLED = 'FETCH_MENU_BY_PUPIL_FULFILLED'
export const FETCH_MENU_BY_PUPIL_EXTRA_FULFILLED =
  'FETCH_MENU_BY_PUPIL_EXTRA_FULFILLED'
export const FETCH_MENU_BY_PUPIL_FAIL = 'FETCH_MENU_BY_PUPIL_FAIL'

export const REMOVE_MENU_BY_PUPIL_REQUEST = 'REMOVE_MENU_BY_PUPIL_REQUEST'
export const REMOVE_MENU_BY_PUPIL_FULFILLED = 'REMOVE_MENU_BY_PUPIL_FULFILLED'
export const REMOVE_MENU_BY_PUPIL_FAIL = 'REMOVE_MENU_BY_PUPIL_FAIL'

// Seasons
export const FETCH_SEASONS_REQUEST = 'FETCH_SEASONS_REQUEST'
export const FETCH_SEASONS_FULFILLED = 'FETCH_SEASONS_FULFILLED'
export const FETCH_SEASONS_FAIL = 'FETCH_SEASONS_FAIL'

export const FETCH_SEASON_REQUEST = 'FETCH_SEASON_REQUEST'
export const FETCH_SEASON_FULFILLED = 'FETCH_SEASON_FULFILLED'
export const FETCH_SEASON_FAIL = 'FETCH_SEASON_FAIL'

export const REMOVE_SEASON_REQUEST = 'REMOVE_SEASON_REQUEST'
export const REMOVE_SEASON_FULFILLED = 'REMOVE_SEASON_FULFILLED'
export const REMOVE_SEASON_FAIL = 'REMOVE_SEASON_FAIL'

// Meals
export const FETCH_MENUS_REQUEST = 'FETCH_MENUS_REQUEST'
export const FETCH_MENUS_FULFILLED = 'FETCH_MENUS_FULFILLED'
export const FETCH_MENUS_FAIL = 'FETCH_MENUS_FAIL'

export const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST'
export const FETCH_MENU_FULFILLED = 'FETCH_MENU_FULFILLED'
export const FETCH_MENU_FAIL = 'FETCH_MENU_FAIL'

export const REMOVE_MENU_REQUEST = 'REMOVE_MENU_REQUEST'
export const REMOVE_MENU_FULFILLED = 'REMOVE_MENU_FULFILLED'
export const REMOVE_MENU_FAIL = 'REMOVE_MENU_FAIL'

// Meal Types
export const FETCH_MENU_MEAL_TYPES_REQUEST = 'FETCH_MENU_MEAL_TYPES_REQUEST'
export const FETCH_MENU_MEAL_TYPES_FULFILLED = 'FETCH_MENU_MEAL_TYPES_FULFILLED'
export const FETCH_MENU_MEAL_TYPES_FAIL = 'FETCH_MENU_MEAL_TYPES_FAIL'

export const FETCH_MENU_MEAL_TYPE_REQUEST = 'FETCH_MENU_MEAL_TYPE_REQUEST'
export const FETCH_MENU_MEAL_TYPE_FULFILLED = 'FETCH_MENU_MEAL_TYPE_FULFILLED'
export const FETCH_MENU_MEAL_TYPE_FAIL = 'FETCH_MENU_MEAL_TYPE_FAIL'

export const REMOVE_MENU_MEAL_TYPE_REQUEST = 'REMOVE_MENU_MEAL_TYPE_REQUEST'
export const REMOVE_MENU_MEAL_TYPE_FULFILLED = 'REMOVE_MENU_MEAL_TYPE_FULFILLED'
export const REMOVE_MENU_MEAL_TYPE_FAIL = 'REMOVE_MENU_MEAL_TYPE_FAIL'

// Meals
export const FETCH_MENU_MEALS_REQUEST = 'FETCH_MENU_MEALS_REQUEST'
export const FETCH_MENU_MEALS_FULFILLED = 'FETCH_MENU_MEALS_FULFILLED'
export const FETCH_MENU_MEALS_FAIL = 'FETCH_MENU_MEALS_FAIL'

export const FETCH_MENU_MEAL_REQUEST = 'FETCH_MENU_MEAL_REQUEST'
export const FETCH_MENU_MEAL_FULFILLED = 'FETCH_MENU_MEAL_FULFILLED'
export const FETCH_MENU_MEAL_FAIL = 'FETCH_MENU_MEAL_FAIL'

export const REMOVE_MENU_MEAL_REQUEST = 'REMOVE_MENU_MEAL_REQUEST'
export const REMOVE_MENU_MEAL_FULFILLED = 'REMOVE_MENU_MEAL_FULFILLED'
export const REMOVE_MENU_MEAL_FAIL = 'REMOVE_MENU_MEAL_FAIL'

// Products
export const FETCH_MENU_PRODUCTS_REQUEST = 'FETCH_MENU_PRODUCTS_REQUEST'
export const FETCH_MENU_PRODUCTS_FULFILLED = 'FETCH_MENU_PRODUCTS_FULFILLED'
export const FETCH_MENU_PRODUCTS_FAIL = 'FETCH_MENU_PRODUCTS_FAIL'

export const FETCH_MENU_PRODUCT_REQUEST = 'FETCH_MENU_PRODUCT_REQUEST'
export const FETCH_MENU_PRODUCT_FULFILLED = 'FETCH_MENU_PRODUCT_FULFILLED'
export const FETCH_MENU_PRODUCT_FAIL = 'FETCH_MENU_PRODUCT_FAIL'

export const REMOVE_MENU_PRODUCT_REQUEST = 'REMOVE_MENU_PRODUCT_REQUEST'
export const REMOVE_MENU_PRODUCT_FULFILLED = 'REMOVE_MENU_PRODUCT_FULFILLED'
export const REMOVE_MENU_PRODUCT_FAIL = 'REMOVE_MENU_PRODUCT_FAIL'

// Measurements
export const FETCH_MENU_MEASUREMENTS_REQUEST = 'FETCH_MENU_MEASUREMENTS_REQUEST'
export const FETCH_MENU_MEASUREMENTS_SUCCESS = 'FETCH_MENU_MEASUREMENTS_SUCCESS'
export const FETCH_MENU_MEASUREMENTS_FAIL = 'FETCH_MENU_MEASUREMENTS_FAIL'

export const FETCH_MENU_MEASUREMENT_REQUEST = 'FETCH_MENU_MEASUREMENT_REQUEST'
export const FETCH_MENU_MEASUREMENT_FULFILLED =
  'FETCH_MENU_MEASUREMENT_FULFILLED'
export const FETCH_MENU_MEASUREMENT_FAIL = 'FETCH_MENU_MEASUREMENT_FAIL'

export const REMOVE_MENU_MEASUREMENT_REQUEST = 'REMOVE_MENU_MEASUREMENT_REQUEST'
export const REMOVE_MENU_MEASUREMENT_FULFILLED =
  'REMOVE_MENU_MEASUREMENT_FULFILLED'
export const REMOVE_MENU_MEASUREMENT_FAIL = 'REMOVE_MENU_MEASUREMENT_FAIL'
