export const FETCH_ORGANIZATION_SECTORS_REQUEST =
  'FETCH_ORGANIZATION_SECTORS_REQUEST'
export const FETCH_ORGANIZATION_SECTORS_SUCCESS =
  'FETCH_ORGANIZATION_SECTORS_SUCCESS'
export const FETCH_ORGANIZATION_SECTORS_FAILURE =
  'FETCH_ORGANIZATION_SECTORS_FAILURE'

export const FETCH_ORGANIZATION_SECTOR_REQUEST =
  'FETCH_ORGANIZATION_SECTOR_REQUEST'
export const FETCH_ORGANIZATION_SECTOR_SUCCESS =
  'FETCH_ORGANIZATION_SECTOR_SUCCESS'
export const FETCH_ORGANIZATION_SECTOR_FAILURE =
  'FETCH_ORGANIZATION_SECTOR_FAILURE'

export const FETCH_ORGANIZATION_SECTOR_CLEANUP =
  'FETCH_ORGANIZATION_SECTOR_CLEANUP'
export const CLEAR_ORGANIZATION_SECTORS = 'CLEAR_ORGANIZATION_SECTORS'
export const CLEAR_ORGANIZATION_SECTOR = 'CLEAR_ORGANIZATION_SECTOR'
export const REFRESH_ORGANIZATION_SECTOR = 'REFRESH_ORGANIZATION_SECTOR'
export const SET_CURRENT_ORGANIZATION_SECTOR_ID =
  'SET_CURRENT_ORGANIZATION_SECTOR_ID'
export const CLEAR_CURRENT_ORGANIZATION_SECTOR_ID =
  'CLEAR_CURRENT_ORGANIZATION_SECTOR_ID'
