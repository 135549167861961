import InstitutionSchedulesRepository from '@api/repositories/institution-schedules-repository'
import { IInstitutionSchedule } from '@app/interfaces/organization/institution-schedule'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  createInstitutionSchedule,
  updateInstitutionSchedule,
} from '@app/store/actions/institution-schedules-actions'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Switch,
} from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  institutionSchedule?: IInstitutionSchedule
  edit?: boolean
}

const InstitutionSchedulesForm: React.FC<props> = ({
  institutionSchedule,
  edit,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()

  const institutionScheduleTypes = [
    'THREE_HALF_HOURS',
    'NINE_HOURS',
    'TEN_HOURS',
    'TEN_HALF_HOURS',
    'TWELVE_HOURS',
    'TWENTY_FOUR_HOURS',
  ]

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues: IInstitutionSchedule = {
      id: values.id,
      sorting: values.sorting,
      status: values.status,
      data: languages,
      type: values.type,
    }
    setConfirmLoading(true)
    const institutonScheduleSubmitApi = institutionSchedule
      ? InstitutionSchedulesRepository.update
      : InstitutionSchedulesRepository.create

    await institutonScheduleSubmitApi(newValues)
      .then((response: AxiosResponse<IInstitutionSchedule>) => {
        if (institutionSchedule && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateInstitutionSchedule(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!institutionSchedule && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createInstitutionSchedule(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={
          edit
            ? t('institutionSchedule.editInstitutionSchedule')
            : t('institutionSchedule.addInstitutionSchedule')
        }
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: institutionSchedule?.id,
              sorting: institutionSchedule?.sorting || 0,
              status: edit ? institutionSchedule?.status : true,
              type: institutionSchedule?.type,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={institutionSchedule?.status === true}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -2147483648,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              label={t('institutionSchedule.types.type')}
              name={'type'}
            >
              <Select disabled={confirmLoading}>
                {institutionScheduleTypes.map(
                  (institutionScheduleType, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={institutionScheduleType}
                      >
                        {t(
                          `institutionSchedule.types.${institutionScheduleType}`,
                        )}
                      </Select.Option>
                    )
                  },
                )}
              </Select>
            </Form.Item>
            <FormList
              confirmLoading={confirmLoading}
              object={institutionSchedule}
            />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default InstitutionSchedulesForm
