import './manage-users-page.less'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

const ManageUsersPage: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/manage-users/users')
  }, [])
  return <></>
}

export default ManageUsersPage
