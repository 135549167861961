// ROLES
export const CLEAR_ALL_ROLES = 'CLEAR_ALL_ROLES'
export const CREATE_GROUP_ROLE = 'CREATE_GROUP_ROLE'
export const UPDATE_GROUP_ROLE = 'UPDATE_GROUP_ROLE'
export const REMOVE_GROUP_ROLE = 'REMOVE_GROUP_ROLE'
export const CLEAR_GROUP_ROLES = 'CLEAR_GROUP_ROLES'
export const CLEAR_GROUP_ROLE = 'CLEAR_GROUP_ROLE'

export const FETCH_GROUP_ROLES_REQUEST = 'FETCH_GROUP_ROLES_REQUEST'
export const FETCH_GROUP_ROLES_SUCCESS = 'FETCH_GROUP_ROLES_SUCCESS'
export const FETCH_GROUP_ROLES_FAILURE = 'FETCH_GROUP_ROLES_FAILURE'

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST'
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'

export const FETCH_GROUP_ROLE_REQUEST = 'FETCH_GROUP_ROLE_REQUEST'
export const FETCH_GROUP_ROLE_SUCCESS = 'FETCH_GROUP_ROLE_SUCCESS'
export const FETCH_GROUP_ROLE_FAILURE = 'FETCH_GROUP_ROLE_FAILURE'
