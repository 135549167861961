import { IEducationGrade } from '@app/interfaces/education/education-grade'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SelectGroupForm from './components/select-group-form/select-group-form'

interface props {
  _educationGrades: IEducationGrade[]
  pupil: IOrganizationPupils
}

const OrganizationChangeGroup: React.FC<props> = ({
  pupil,
  _educationGrades,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [t] = useTranslation()

  const showModal = () => {
    setVisible(true)
  }

  const hideModal = () => {
    setVisible(false)
  }

  return (
    <React.Fragment>
      <Button
        onClick={showModal}
        type="link"
        style={{ width: '100%', height: '100%' }}
      >
        {t('pupils.changeGroup')}
      </Button>
      <Modal
        open={visible}
        title={t('pupils.changeGroup')}
        destroyOnClose={true}
        onCancel={hideModal}
        footer={null}
        width="1000px"
      >
        <SelectGroupForm
          educationGrades={_educationGrades}
          pupil={pupil}
          onAccept={hideModal}
        />
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    _educationGrades: state.educationGrade.educationGrades,
    pupil: ownProps.pupil,
  }
}

export default connect(mapStateToProps)(OrganizationChangeGroup)
