import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { useGetEducationDailySchedulesQuery } from '@app/store/rtk/api/education/education-daily-schedule-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Col, Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  isOpen: boolean
  onChange?: (e: any) => any
  edit?: boolean
}

const EducationDailyScheduleItem: React.FC<props> = ({
  isOpen,
  onChange,
  edit,
}) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data } = useGetEducationDailySchedulesQuery('')

  return (
    <Col span={12}>
      <Form.Item
        label={t('educationDailySchedule.title')}
        name="dailyRoutine"
        rules={[
          {
            required: true,
            message: t('errors.requiredMessage'),
          },
        ]}
      >
        <Select
          onChange={onChange}
          disabled={
            !isOpen ||
            (edit &&
              !rolesChecker([AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER]))
          }
        >
          {sortByName(localize(data?.response))?.map((type) =>
            type.status ? (
              <Select.Option key={type.id} value={type.id}>
                {type.data?.name}
              </Select.Option>
            ) : null,
          )}
        </Select>
      </Form.Item>
    </Col>
  )
}

export default EducationDailyScheduleItem
