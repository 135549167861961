export const FETCH_INSTITUTION_SUBTYPES_REQUEST =
  'FETCH_INSTITUTION_SUBTYPES_REQUEST'
export const FETCH_INSTITUTION_SUBTYPES_SUCCESS =
  'FETCH_INSTITUTION_SUBTYPES_SUCCESS'
export const FETCH_INSTITUTION_SUBTYPES_FAILURE =
  'FETCH_INSTITUTION_SUBTYPES_FAILURE'

export const FETCH_INSTITUTION_SUBTYPE_REQUEST =
  'FETCH_INSTITUTION_SUBTYPE_REQUEST'
export const FETCH_INSTITUTION_SUBTYPE_SUCCESS =
  'FETCH_INSTITUTION_SUBTYPE_SUCCESS'
export const FETCH_INSTITUTION_SUBTYPE_FAILURE =
  'FETCH_INSTITUTION_SUBTYPE_FAILURE'

export const FETCH_INSTITUTION_SUBTYPE_CLEANUP =
  'FETCH_INSTITUTION_SUBTYPE_CLEANUP'
export const CLEAR_INSTITUTION_SUBTYPES = 'CLEAR_INSTITUTION_SUBTYPES'
export const CLEAR_INSTITUTION_SUBTYPE = 'CLEAR_INSTITUTION_SUBTYPE'
export const REFRESH_INSTITUTION_SUBTYPE = 'REFRESH_INSTITUTION_SUBTYPE'
