import {
  GET_ORGANIZATION_TIMESHEET_BY_PUPIL,
  GET_ORGANIZATION_TIMESHEET_BY_EMPLOYEE,
} from '@app/store/types/organization-timesheet'
import {
  IOrganizationTimesheetEmployee,
  IOrganizationTimesheetPupil,
  IOrganizationTimesheetState,
} from '@app/interfaces/organization-timesheet'

const initialState: IOrganizationTimesheetState = {
  organization_timesheet_employee: {} as IOrganizationTimesheetEmployee[],
  organization_timesheet_pupil: {} as IOrganizationTimesheetPupil[],
}

export const organizationTimesheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_TIMESHEET_BY_PUPIL:
      return { ...state, organization_timesheet_pupil: action.payload }
    case GET_ORGANIZATION_TIMESHEET_BY_EMPLOYEE:
      return { ...state, organization_timesheet_employee: action.payload }
    default:
      return state
  }
}
