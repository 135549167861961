import React from 'react'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './i18n'
import './index.less'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { store } from './store/store'
import { QueryClient, QueryClientProvider } from 'react-query'

export const history = createBrowserHistory()
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider locale={ruRU}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
)
serviceWorkerRegistration.register()
