import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { IPupilApplicationQueueState } from '@app/interfaces/pupil/pupil-application'
import {
  FETCH_QUEUE_APPLICATIONS_CLEANUP,
  FETCH_QUEUE_APPLICATIONS_FAILURE,
  FETCH_QUEUE_APPLICATIONS_REQUEST,
  FETCH_QUEUE_APPLICATIONS_SUCCESS,
} from '../types/application'

const initialState: IPupilApplicationQueueState = {
  queueApplications: [],
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUEUE_APPLICATIONS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_QUEUE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        queueApplications: action.payload.queueApplications,
        count: action.payload.count,
      }
    case FETCH_QUEUE_APPLICATIONS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        queueApplications: [],
      }
    case FETCH_QUEUE_APPLICATIONS_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    default:
      return state
  }
}
