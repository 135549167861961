import benefitTypeRepository from '@app/api/repositories/benefit-type-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import axios from 'axios'
import {
  CLEAR_BENEFIT_TYPE,
  CLEAR_BENEFIT_TYPES,
  CREATE_BENEFIT_TYPE,
  FETCH_BENEFIT_TYPES_FAILURE,
  FETCH_BENEFIT_TYPES_REQUEST,
  FETCH_BENEFIT_TYPES_SUCCESS,
  FETCH_BENEFIT_TYPE_CLEANUP,
  FETCH_BENEFIT_TYPE_FAILURE,
  FETCH_BENEFIT_TYPE_REQUEST,
  FETCH_BENEFIT_TYPE_SUCCESS,
  REFRESH_BENEFIT_TYPE,
  REMOVE_BENEFIT_TYPE,
  UPDATE_BENEFIT_TYPE,
} from '../types/benefit-type'

export const getBenefitTypes = (args: IPathParamsArgs) => async (dispatch) => {
  dispatch(fetchBenefitTypesRequest())
  await benefitTypeRepository
    .getAll({ params: args.params, source: args.source })
    .then((response) => {
      dispatch(
        fetchBenefitTypesSuccess(
          response.data,
          response.headers['x-total-count'],
        ),
      )
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        dispatch(fetchBenefitTypesFailure(error?.message, error?.code))
      }
    })
  fetchBenefitTypeCleanup()
}

export const getBenefitType = (args: numberArgs) => async (dispatch) => {
  dispatch(fetchBenefitTypeRequest())
  await benefitTypeRepository
    .getOne(args)
    .then((response) => {
      dispatch(fetchBenefitTypeSuccess(response.data))
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        dispatch(fetchBenefitTypeFailure(error?.message, error?.code))
      }
    })
  fetchBenefitTypeCleanup()
}

const fetchBenefitTypesRequest = () => {
  return {
    type: FETCH_BENEFIT_TYPES_REQUEST,
  }
}

const fetchBenefitTypesSuccess = (benefitTypes, count) => {
  return {
    type: FETCH_BENEFIT_TYPES_SUCCESS,
    payload: { benefitTypes, count },
  }
}

const fetchBenefitTypesFailure = (message, code) => {
  return {
    type: FETCH_BENEFIT_TYPES_FAILURE,
    payload: { message, code },
  }
}

const fetchBenefitTypeRequest = () => {
  return {
    type: FETCH_BENEFIT_TYPE_REQUEST,
  }
}

const fetchBenefitTypeSuccess = (benefitType) => {
  return {
    type: FETCH_BENEFIT_TYPE_SUCCESS,
    payload: benefitType,
  }
}

const fetchBenefitTypeFailure = (message, code) => {
  return {
    type: FETCH_BENEFIT_TYPE_FAILURE,
    payload: { message, code },
  }
}

const fetchBenefitTypeCleanup = () => {
  return {
    type: FETCH_BENEFIT_TYPE_CLEANUP,
  }
}

export function clearBenefitType() {
  return {
    type: CLEAR_BENEFIT_TYPE,
  }
}

export function clearBenefitTypes() {
  return {
    type: CLEAR_BENEFIT_TYPES,
  }
}

export function refreshBenefitType() {
  return {
    type: REFRESH_BENEFIT_TYPE,
  }
}

export function createBenefitType(benefitType) {
  return {
    type: CREATE_BENEFIT_TYPE,
    payload: benefitType,
  }
}

export function deleteBenefitType(benefitType) {
  return {
    type: REMOVE_BENEFIT_TYPE,
    payload: benefitType,
  }
}

export function updateBenefitType(benefitType) {
  return {
    type: UPDATE_BENEFIT_TYPE,
    payload: benefitType,
  }
}
