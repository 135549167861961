import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { useGetInstitutionSchedulesQuery } from '@app/store/rtk/api/organization/institution-schedule-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Col, Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  isOpen: boolean
  edit?: boolean
}

const InstitutionScheduleItem: React.FC<props> = ({ isOpen, edit }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data } = useGetInstitutionSchedulesQuery('')

  return (
    <Col span={12}>
      <Form.Item
        label={t('institutionSchedule.institutionSchedule')}
        rules={[
          {
            required: true,
            message: t('errors.requiredMessage'),
          },
        ]}
        name={'institutionScheduleId'}
      >
        <Select
          disabled={
            !isOpen ||
            (edit &&
              !rolesChecker([AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER]))
          }
        >
          {sortByName(localize(data?.response))?.map((schedule) =>
            schedule.status ? (
              <Select.Option key={schedule.id} value={schedule.id}>
                {schedule.data?.name}
              </Select.Option>
            ) : null,
          )}
        </Select>
      </Form.Item>
    </Col>
  )
}

export default InstitutionScheduleItem
