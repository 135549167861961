import EmployeeRepository from '@api/repositories/employee-repository'
import { IEmployee } from '@app/interfaces/employee/employee'
import { IFile } from '@app/interfaces/files'
import { eParams } from '@app/pages/organizations/components/organization-employee/components/employee-organization/components/employee-edit/employee-edit'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { updateEmployee } from '@app/store/actions/employee-actions'
import { Button, Card, Col, Form, Input, Radio, Row, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

interface props {
  _employee: IEmployee
  avatar: IFile
}

const EmployeeEditForm: React.FC<props> = ({ _employee, avatar }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t, i18n] = useTranslation()
  const params: eParams = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const genders = [
    {
      key: 'MALE',
      value: t('general.male'),
    },
    {
      key: 'FEMALE',
      value: t('general.female'),
    },
  ]

  const onFinish = async (values: any) => {
    setConfirmLoading(true)
    const newValues = {
      ...values,
      // fileId: avatar.id,
    }

    const userSubmitApi = EmployeeRepository.update
    await userSubmitApi(newValues).then(
      (response: AxiosResponse<IEmployee>) => {
        if (response.status === 200) {
          dispatch(updateEmployee(response.data))
          openNotificationWithIcon('success', t('success.updated'))
          setConfirmLoading(false)
          if (!confirmLoading) {
            navigate(
              `/organization/${params.id}/employees/employee/${response.data.id}`,
            )
          }
        }
      },
    )
    setConfirmLoading(false)
  }

  return (
    <React.Fragment>
      <Spin spinning={confirmLoading}>
        <Card
          extra={
            <>
              <Button
                type={'primary'}
                disabled={confirmLoading}
                onClick={form.submit}
              >
                {t('general.save')}
              </Button>
              <Link
                to={`/organization/${params.id}/employees/employee/${params.employeeId}`}
                className={'ant-btn ant-btn-default'}
                style={{ marginLeft: '10px' }}
              >
                {t('general.cancel')}
              </Link>{' '}
            </>
          }
          title={`${t('general.edit')} ${t('employee.employee')}а ${
            _employee.id
          }`}
        >
          <Form
            form={form}
            initialValues={{
              ..._employee,
            }}
            onFinish={onFinish}
            layout={'vertical'}
            className="avatar"
          >
            {/* <Form.Item label={t("users.avatar")} name={"firstName"}>
              <PhotoItem isLoading={setConfirmLoading} />
            </Form.Item> */}
            <Row gutter={8}>
              <Form.Item hidden={true} name={'id'}>
                <Input />
              </Form.Item>
              <Col span={8}>
                <Form.Item
                  label={t('employeeFields.firstName')}
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  name={'firstName'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.lastName')}
                  name={'lastName'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.middleName')}
                  name={'middleName'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.tin')}
                  name={'tin'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t('employeeFields.pin')} name={'pin'}>
                  <Input disabled={true} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.email')}
                  name={'email'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.phone')}
                  name={'phone'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.address')}
                  name={'address'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.passportNumber')}
                  name={'passportNumber'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.gender')}
                  name={'gender'}
                >
                  <Radio.Group>
                    {genders.map((gender, index) => (
                      <Radio key={index} value={gender.key}>
                        {gender.value}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type={'primary'}
                disabled={confirmLoading}
                htmlType={'submit'}
              >
                {t('general.save')}
              </Button>
              <Link
                to={`/organization/${params.id}/employees/employee/${params.employeeId}`}
                className={'ant-btn ant-btn-default'}
                style={{ marginLeft: '10px' }}
              >
                {t('general.cancel')}
              </Link>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </React.Fragment>
  )
}

export default EmployeeEditForm
