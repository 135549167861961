import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEducationGroupRoom,
  IEducationGroupRoomState,
} from '@app/interfaces/education/education-group-room'
import { FETCH_EDUCATION_GROUP_CLEANUP } from '@app/store/types/education-group'
import { CLEAR_EDUCATION_GROUP_ROOMS } from '../types/education-group-room'
import {
  CLEAR_EDUCATION_GROUP_ROOM,
  FETCH_EDUCATION_GROUP_ROOMS_FAILURE,
  FETCH_EDUCATION_GROUP_ROOMS_REQUEST,
  FETCH_EDUCATION_GROUP_ROOMS_SUCCESS,
  FETCH_EDUCATION_GROUP_ROOM_FAILURE,
  FETCH_EDUCATION_GROUP_ROOM_REQUEST,
  FETCH_EDUCATION_GROUP_ROOM_SUCCESS,
  REFRESH_EDUCATION_GROUP_ROOMS,
} from './../types/education-group-room'

const initialState: IEducationGroupRoomState = {
  educationGroupRooms: [],
  educationGroupRoom: {} as IEducationGroupRoom,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const educationGroupRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_EDUCATION_GROUP_ROOMS:
      return { ...state, educationGroupRooms: [], state: IDLE }
    case CLEAR_EDUCATION_GROUP_ROOM:
      return { ...state, educationGroupRoom: {}, state: IDLE }
    case REFRESH_EDUCATION_GROUP_ROOMS:
      return { ...state, refresh: !state.refresh }
    case FETCH_EDUCATION_GROUP_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_EDUCATION_GROUP_ROOMS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_GROUP_ROOMS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationGroupRooms: action.payload.educationGroupRooms,
        count: action.payload.count,
      }
    case FETCH_EDUCATION_GROUP_ROOMS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationGroupRooms: [],
      }
    case FETCH_EDUCATION_GROUP_ROOM_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_GROUP_ROOM_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationGroupRoom: action.payload,
      }
    case FETCH_EDUCATION_GROUP_ROOM_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationGroupRoom: {},
      }
    default:
      return state
  }
}
