import educationGradeRepository from '@app/api/repositories/education-grade-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import { IEducationGradeActions } from '@app/interfaces/education/education-grade'
import {
  CLEAR_EDUCATION_GRADES,
  CREATE_EDUCATION_GRADE,
  DELETE_EDUCATION_GRADE,
  FETCH_EDUCATION_GRADES_FAILURE,
  FETCH_EDUCATION_GRADES_REQUEST,
  FETCH_EDUCATION_GRADES_SUCCESS,
  FETCH_EDUCATION_GRADE_FAILURE,
  FETCH_EDUCATION_GRADE_REQUEST,
  FETCH_EDUCATION_GRADE_SUCCESS,
  UPDATE_EDUCATION_GRADE,
} from '@app/store/types/education-grade'
import axios from 'axios'

export const getEducationGrade =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchEducationGradeRequest())
    await educationGradeRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchEducationGradeSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEducationGradeFailure(error?.message, error?.code))
        }
      })
  }

export const getEducationGrades =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchEducationGradesRequest())
    await educationGradeRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchEducationGradesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEducationGradesFailure(error?.message, error?.code))
        }
      })
  }

const fetchEducationGradesRequest = (): IEducationGradeActions => {
  return {
    type: FETCH_EDUCATION_GRADES_REQUEST,
  }
}

const fetchEducationGradesSuccess = (
  educationGrades,
  count,
): IEducationGradeActions => {
  return {
    type: FETCH_EDUCATION_GRADES_SUCCESS,
    payload: { educationGrades, count },
  }
}

const fetchEducationGradesFailure = (message, code): IEducationGradeActions => {
  return {
    type: FETCH_EDUCATION_GRADES_FAILURE,
    payload: { message, code },
  }
}

const fetchEducationGradeRequest = (): IEducationGradeActions => {
  return {
    type: FETCH_EDUCATION_GRADE_REQUEST,
  }
}

const fetchEducationGradeSuccess = (educationGrade): IEducationGradeActions => {
  return {
    type: FETCH_EDUCATION_GRADE_SUCCESS,
    payload: educationGrade,
  }
}

const fetchEducationGradeFailure = (message, code): IEducationGradeActions => {
  return {
    type: FETCH_EDUCATION_GRADE_FAILURE,
    payload: { message, code },
  }
}

export function clearEducationGrades() {
  return {
    type: CLEAR_EDUCATION_GRADES,
    payload: null,
  }
}

export function createEducationGrade(educationGrade): IEducationGradeActions {
  return {
    type: CREATE_EDUCATION_GRADE,
    payload: educationGrade,
  }
}

export function updateEducationGrade(educationGrade): IEducationGradeActions {
  return {
    type: UPDATE_EDUCATION_GRADE,
    payload: educationGrade,
  }
}

export function deleteEducationGrade(educationGrade): IEducationGradeActions {
  return {
    type: DELETE_EDUCATION_GRADE,
    payload: educationGrade,
  }
}
