export const CREATE_EMPLOYEE_POSITION = 'CREATE_EMPLOYEE_POSITION'
export const UPDATE_EMPLOYEE_POSITION = 'UPDATE_EMPLOYEE_POSITION'
export const DELETE_EMPLOYEE_POSITION = 'DELETE_EMPLOYEE_POSITION'
export const CLEAR_EMPLOYEE_POSITION = 'CLEAR_EMPLOYEE_POSITION'

export const FETCH_EMPLOYEE_POSITIONS_REQUEST =
  'FETCH_EMPLOYEE_POSITIONS_REQUEST'
export const FETCH_EMPLOYEE_POSITIONS_SUCCESS =
  'FETCH_EMPLOYEE_POSITIONS_SUCCESS'
export const FETCH_EMPLOYEE_POSITIONS_FAILURE =
  'FETCH_EMPLOYEE_POSITIONS_FAILURE'

export const FETCH_EMPLOYEE_POSITION_REQUEST = 'FETCH_EMPLOYEE_POSITION_REQUEST'
export const FETCH_EMPLOYEE_POSITION_SUCCESS = 'FETCH_EMPLOYEE_POSITION_SUCCESS'
export const FETCH_EMPLOYEE_POSITION_FAILURE = 'FETCH_EMPLOYEE_POSITION_FAILURE'
