import bbitApi from '@api/make-request'
import {
  IRepository,
  numberArgs,
  IPathParamsArgs,
} from '@api/repositories/i-repository'
import { ILang } from '@app/interfaces/languages'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class LanguageRepository implements IRepository<ILang> {
  create(data: ILang): Promise<AxiosResponse<ILang>> {
    return bbitApi.post('/dictionary/api/v1/languages', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<ILang[]>> {
    return bbitApi.get('/dictionary/api/v1/languages', {
      cancelToken: args.source?.token,
      params: resolveParams(args),
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<ILang>> {
    return bbitApi.get(`/dictionary/api/v1/languages/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: ILang): Promise<AxiosResponse<ILang>> {
    return bbitApi.put(`/dictionary/api/v1/languages/${data.id}`, data)
  }
}

export default new LanguageRepository()
