import queryString from 'query-string'

interface queryParams {
  query: string | object
  value?: any
  searchParams?: string
}
const useQuery = () => {
  const serializeQuery = (params: queryParams) => {
    let qs = queryString.parse(params.searchParams || '')
    typeof params.query === 'string'
      ? (qs[params.query] = params.value)
      : (qs = Object.assign({}, qs, params.query))
    // const newSearchParams = Object.keys(qs)
    //   .filter((key) => qs[key])
    //   .map(
    //     (k) =>
    //       `${encodeURIComponent(k)}=${encodeURIComponent(qs[k] as string)}`,
    //   )
    //   .join('&')
    const removeEmptyFields = Object.fromEntries(
      Object.entries(qs).filter(([, v]) => v != null),
    )
    const newSearchParams = new URLSearchParams(
      JSON.parse(JSON.stringify(removeEmptyFields)),
    ).toString()

    return `${new URLSearchParams(newSearchParams)}`
  }

  return {
    serializeQuery,
  }
}

export default useQuery
