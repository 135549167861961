import useLocalization from '@app/hooks/useLocalization'
import { IWaterSource } from '@app/interfaces/organization-passport/water-sources'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  waterSources: IWaterSource[]
}

const WaterSourcesItem: React.FC<props> = ({ waterSources }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('drinkingWaterSource.title')}
        name={'drinkingWaterSource'}
      >
        <Select placeholder={t('institutionPlaceholders.drinkingWaterSource')}>
          {sortByName(localize(waterSources)).map((waterSource, index) => {
            if (waterSource.status) {
              return (
                <Select.Option key={index} value={waterSource.id!.toString()}>
                  {waterSource?.data?.name}
                </Select.Option>
              )
            }
            return null
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default WaterSourcesItem
