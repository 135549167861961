export const FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_REQUEST =
  'FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_REQUEST'
export const FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_SUCCESS =
  'FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_SUCCESS'
export const FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_FAILURE =
  'FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_FAILURE'

export const FETCH_ORGANIZATION_CONTRACT_SUBSIDY_REQUEST =
  'FETCH_ORGANIZATION_CONTRACT_SUBSIDY_REQUEST'
export const FETCH_ORGANIZATION_CONTRACT_SUBSIDY_SUCCESS =
  'FETCH_ORGANIZATION_CONTRACT_SUBSIDY_SUCCESS'
export const FETCH_ORGANIZATION_CONTRACT_SUBSIDY_FAILURE =
  'FETCH_ORGANIZATION_CONTRACT_SUBSIDY_FAILURE'

export const FETCH_ORGANIZATION_CONTRACT_SUBSIDY_CLEANUP =
  'FETCH_ORGANIZATION_CONTRACT_SUBSIDY_CLEANUP'
export const CLEAR_ORGANIZATION_CONTRACT_SUBSIDIES =
  'CLEAR_ORGANIZATION_CONTRACT_SUBSIDIES'
export const CLEAR_ORGANIZATION_CONTRACT_SUBSIDY =
  'CLEAR_ORGANIZATION_CONTRACT_SUBSIDY'
export const REFRESH_ORGANIZATION_CONTRACT_SUBSIDY =
  'REFRESH_ORGANIZATION_CONTRACT_SUBSIDY'
export const ORGANIZATION_CONTRACT_SUBSIDY_ADD_MODAL_SET =
  'ORGANIZATION_CONTRACT_SUBSIDY_ADD_MODAL_SET'
