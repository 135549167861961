import { ILang } from '@app/interfaces/languages'
import { Checkbox, Col, Descriptions, Row, Typography, Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  language: ILang | undefined
}

const ShowInfo: React.FC<props> = ({ language }) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [freeOfCharge, setFreeOfCharge] = useState<boolean>(false)
  const [agreement, setAgreement] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation()

  const freeOfChargeHandler = (e) => {
    setFreeOfCharge(e.target.checked)
  }

  const agreementAcceptHandler = (e) => {
    setAgreement(e.target.checked)
  }

  return (
    <div className="vertical_padding">
      <Row>
        <Col span={18} offset={3}>
          <div className="my_sm">
            <Typography.Title level={5}>
              {t('contracts.pupilInfo')}
            </Typography.Title>
          </div>
          <Descriptions size={'middle'} column={1} bordered>
            <Descriptions.Item label={t('contracts.fullName')}>
              Name
            </Descriptions.Item>
            <Descriptions.Item label={t('contracts.birthDate')}>
              Name
            </Descriptions.Item>
            <Descriptions.Item label={t('contracts.metricNo')}>
              Name
            </Descriptions.Item>
            <Descriptions.Item label={t('contracts.pin')}>
              Name
            </Descriptions.Item>
            <Descriptions.Item label={t('contracts.monthlyRate')}>
              Name
            </Descriptions.Item>
          </Descriptions>
          <div className="my_sm">
            <Typography.Title level={5}>
              {t('contracts.trusteeInfo')}
            </Typography.Title>
          </div>
          <Descriptions size={'middle'} column={1} bordered>
            <Descriptions.Item label={t('contracts.fullName')}>
              Name
            </Descriptions.Item>
            <Descriptions.Item label={t('contracts.passportNo')}>
              Name
            </Descriptions.Item>
            <Descriptions.Item label={t('contracts.address')}>
              Name
            </Descriptions.Item>
          </Descriptions>
          <Form.Item
            name={'freeOfCharge'}
            valuePropName="checked"
            initialValue={false}
          >
            <Row className="my_sm">
              <Checkbox
                onChange={freeOfChargeHandler}
                style={{ marginRight: '10px' }}
              >
                {t('contracts.freeOfCharge')}
              </Checkbox>
            </Row>
          </Form.Item>
          <Form.Item name={'message'}>
            <TextArea rows={6} disabled={!freeOfCharge} />
          </Form.Item>
          <Form.Item
            name={'agreement'}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              onChange={agreementAcceptHandler}
              style={{ marginRight: '10px' }}
            >
              {t('contracts.agreementAccept')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default ShowInfo
