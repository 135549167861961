import {
  clearWarehouseData,
  clearWarehouseInvoice,
} from '@app/store/actions/organization-warehouse-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import OrganizationWarehouseData from './components/organization-warehouse-data'
import OrganizationWarehouseFilters from './components/organization-warehouse-filters'
import OrganizationWarehouseInvoice from './components/organization-warehouse-invoice'
import './organization-warehouse.less'

interface IPathParams {
  id?: string
}

enum WarehouseType {
  DATA,
  INVOICE,
}

const OrganizationWarehouse: React.FC = () => {
  const [warehouseType, setWarehouseType] = useState<WarehouseType>(
    WarehouseType.INVOICE,
  )
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const params: IPathParams = useParams()
  const [organizationId, setOrganizationId] = useState<string>('')
  setDocumentTitle(t('routes.warehouse'))

  useEffect(() => {
    return () => {
      dispatch(clearWarehouseData())
      dispatch(clearWarehouseInvoice())
    }
  }, [])

  const getComponent = (type: WarehouseType) => {
    if (type === WarehouseType.DATA)
      return <OrganizationWarehouseData organizationId={organizationId} />
    if (type === WarehouseType.INVOICE)
      return <OrganizationWarehouseInvoice organizationId={organizationId} />
  }

  const onTypeClickHandler = (type: WarehouseType) => {
    setWarehouseType(type)
  }

  const titles = [
    {
      id: 1,
      title: t('organizationWarehouse.types.data'),
      type: WarehouseType.DATA,
    },
    {
      id: 2,
      title: t('organizationWarehouse.types.invoice'),
      type: WarehouseType.INVOICE,
    },
  ]

  return (
    <div className="warehouse">
      <div className="warehouse__header">
        {titles.map((title) => {
          return (
            <div
              key={title.id}
              className={`warehouse__header--btn ${
                warehouseType === title.type ? 'selected' : ''
              }`}
              onClick={() => onTypeClickHandler(title.type)}
            >
              {title.title}
            </div>
          )
        })}
      </div>
      {!params.id && (
        <div className="warehouse__filters">
          <OrganizationWarehouseFilters
            form={form}
            setOrganizationId={setOrganizationId}
          />
        </div>
      )}
      <div className="warehouse__header__content">
        {getComponent(warehouseType)}
      </div>
    </div>
  )
}

export default OrganizationWarehouse
