import { ApiKeys } from '@app/constants/api-keys'
import { IHeatingSystemSource } from '@app/interfaces/organization-passport/heating-system-source'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const heatingSystemSourcesApi =
  emptyOrganizationPassportApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getHeatingSystemSources: build.query<
        IRtkResponse<IHeatingSystemSource[]>,
        URLSearchParams | string
      >({
        query: (args = '') => ({
          url: 'dictionary/api/v1/heating-system-sources',
          method: 'get',
          params: {
            ...resolveTParams(args),
          },
        }),
        transformResponse: (response: IHeatingSystemSource[], meta) => {
          return { response, meta }
        },
        providesTags: (data) =>
          data
            ? [
                ...data.response.map(({ id }) => ({
                  type: ApiKeys.heatingSystemSource,
                  id,
                })),
                { type: ApiKeys.heatingSystemSource, id: 'LIST' },
              ]
            : [{ type: ApiKeys.heatingSystemSource, id: 'LIST' }],
      }),
      getHeatingSystemSource: build.query<IHeatingSystemSource, number>({
        query: (id) => ({
          url: `dictionary/api/v1/heating-system-sources/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          { type: ApiKeys.heatingSystemSource, id },
        ],
      }),
      createHeatingSystemSource: build.mutation<
        IRtkResponse<IHeatingSystemSource>,
        IHeatingSystemSource
      >({
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/heating-system-sources',
            data,
          }
        },
        transformResponse: (response: IHeatingSystemSource, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          {
            type: ApiKeys.heatingSystemSource,
            id: args.id,
          },
          { type: ApiKeys.heatingSystemSource, id: 'LIST' },
        ],
      }),
      updateHeatingSystemSource: build.mutation<
        IRtkResponse<IHeatingSystemSource>,
        IHeatingSystemSource
      >({
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/heating-system-sources/${data.id}`,
          data,
        }),
        transformResponse: (response: IHeatingSystemSource, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.heatingSystemSource, id: args.id },
          { type: ApiKeys.heatingSystemSource, id: 'LIST' },
        ],
      }),
    }),
  })

const useStateGetHeatingSystemSources =
  heatingSystemSourcesApi.endpoints.getHeatingSystemSources.useQueryState
const useStateGetHeatingSystemSource =
  heatingSystemSourcesApi.endpoints.getHeatingSystemSource.useQueryState

export const {
  useGetHeatingSystemSourcesQuery,
  useGetHeatingSystemSourceQuery,
  useCreateHeatingSystemSourceMutation,
  useUpdateHeatingSystemSourceMutation,
  util,
  usePrefetch,
} = heatingSystemSourcesApi

export { useStateGetHeatingSystemSources, useStateGetHeatingSystemSource }
