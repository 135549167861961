import {
  CLEAR_USER_INFO,
  FETCH_USER_INFO_CLEANUP,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
} from '@app/store/types/user-info'
import { IUserInfo, IUserInfoState } from '@app/interfaces/user-info'
import { IDLE, LOADING, SUCCEEDED, FAILED } from '@app/constants/redux-state'

const initialState: IUserInfoState = {
  userInfo: {} as IUserInfo,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_USER_INFO:
      return { ...state, userInfo: {} }
    case FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        userInfo: action.payload,
      }
    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        userInfo: {},
      }
    case FETCH_USER_INFO_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    default:
      return state
  }
}
