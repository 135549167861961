import { ApiKeys } from '@app/constants/api-keys'
import { IToiletCleanings } from '@app/interfaces/organization-passport/toilet-cleanings'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const toiletCleaningsApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getToiletCleanings: build.query<
      IRtkResponse<IToiletCleanings[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/toilet-cleanings',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IToiletCleanings[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.toiletCleaning,
                id,
              })),
              { type: ApiKeys.toiletCleaning, id: 'LIST' },
            ]
          : [{ type: ApiKeys.toiletCleaning, id: 'LIST' }],
    }),
    getToiletCleaning: build.query<IToiletCleanings, number>({
      query: (id) => ({
        url: `dictionary/api/v1/toilet-cleanings/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.toiletCleaning, id },
      ],
    }),
    createToiletCleaning: build.mutation<
      IRtkResponse<IToiletCleanings>,
      IToiletCleanings
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/toilet-cleanings',
          data,
        }
      },
      transformResponse: (response: IToiletCleanings, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.toiletCleaning,
          id: args.id,
        },
        { type: ApiKeys.toiletCleaning, id: 'LIST' },
      ],
    }),
    updateToiletCleaning: build.mutation<
      IRtkResponse<IToiletCleanings>,
      IToiletCleanings
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/toilet-cleanings/${data.id}`,
        data,
      }),
      transformResponse: (response: IToiletCleanings, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.toiletCleaning, id: args.id },
        { type: ApiKeys.toiletCleaning, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetToiletCleanings =
  toiletCleaningsApi.endpoints.getToiletCleanings.useQueryState
const useStateGetToiletCleaning =
  toiletCleaningsApi.endpoints.getToiletCleaning.useQueryState

export const {
  useGetToiletCleaningsQuery,
  useLazyGetToiletCleaningsQuery,
  useCreateToiletCleaningMutation,
  useUpdateToiletCleaningMutation,
  util,
  usePrefetch,
} = toiletCleaningsApi

export { useStateGetToiletCleanings, useStateGetToiletCleaning }
