import bbitApi from '@api/make-request'
import {
  IRepository,
  numberArgs,
  IPathParamsArgs,
} from '@api/repositories/i-repository'
import { IHoliday } from '@app/interfaces/holidays'
import { AxiosResponse } from 'axios'

class HolidaysRepository implements IRepository<IHoliday> {
  create(data: IHoliday): Promise<AxiosResponse<IHoliday>> {
    return bbitApi.post('/dictionary/api/v1/holidays', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IHoliday[]>> {
    return bbitApi.get('/dictionary/api/v1/holidays', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IHoliday>> {
    return bbitApi.get(`'/dictionary/api/v1/holidays'/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IHoliday): Promise<AxiosResponse<IHoliday>> {
    return bbitApi.put(`/dictionary/api/v1/holidays/${data.id}`, data)
  }
  remove(data: number): Promise<AxiosResponse<IHoliday>> {
    return bbitApi.delete(`/dictionary/api/v1/holidays/${data}`)
  }
}

export default new HolidaysRepository()
