import { IPathParamsArgs } from '@app/api/repositories/i-repository'
import organizationAttendanceMonitoringRepository from '@app/api/repositories/organization-attendance-monitoring-repository'
import axios from 'axios'
import {
  CLEAR_ATTENDANCE_MONITORING_DISTRICT,
  CLEAR_ATTENDANCE_MONITORING_GROUP,
  CLEAR_ATTENDANCE_MONITORING_ORG,
  CLEAR_ATTENDANCE_MONITORING_REGION,
  FETCH_ATTENDANCE_MONITORING_CLEANUP,
  FETCH_ATTENDANCE_MONITORING_DISTRICT_FAILED,
  FETCH_ATTENDANCE_MONITORING_DISTRICT_REQUEST,
  FETCH_ATTENDANCE_MONITORING_DISTRICT_SUCCESS,
  FETCH_ATTENDANCE_MONITORING_GROUP_FAILED,
  FETCH_ATTENDANCE_MONITORING_GROUP_REQUEST,
  FETCH_ATTENDANCE_MONITORING_GROUP_SUCCESS,
  FETCH_ATTENDANCE_MONITORING_ORG_FAILED,
  FETCH_ATTENDANCE_MONITORING_ORG_REQUEST,
  FETCH_ATTENDANCE_MONITORING_ORG_SUCCESS,
  FETCH_ATTENDANCE_MONITORING_REGION_FAILED,
  FETCH_ATTENDANCE_MONITORING_REGION_REQUEST,
  FETCH_ATTENDANCE_MONITORING_REGION_SUCCESS,
} from './../types/organization-attendance-monitoring'

export const getAttendanceMonitoringOrg =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchByOrganizationsRequest())
    await organizationAttendanceMonitoringRepository
      .getByOrganizations(args)
      .then((response) => {
        dispatch(
          fetchByOrganizationsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchByOrganizationsFailure(error?.message, error?.code))
        }
      })
    fetchStateCleanup()
  }

export const getAttendanceMonitoringGroup =
  (args: IPathParamsArgs, orgId: string, message) => async (dispatch) => {
    dispatch(fetchByGroupRequest())
    await organizationAttendanceMonitoringRepository
      .getByGroups({ params: args.params, source: args.source }, orgId)
      .then((response) => {
        dispatch(
          fetchByGroupSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchByGroupFailure(error?.message, error?.code))
        }
      })
    fetchStateCleanup()
  }

export const getAttendanceMonitoringRegions =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchByRegionsRequest())
    await organizationAttendanceMonitoringRepository
      .getByRegions({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchByRegionsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchByRegionsFailure(error?.message, error?.code))
        }
      })
    fetchStateCleanup()
  }

export const getAttendanceMonitoringDistricts =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchByDistrictsRequest())
    await organizationAttendanceMonitoringRepository
      .getByDistricts({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchByDistrictsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchByDistrictsFailure(error?.message, error?.code))
        }
      })
    fetchStateCleanup()
  }

const fetchByOrganizationsRequest = () => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_ORG_REQUEST,
  }
}

const fetchByOrganizationsSuccess = (monitoringByOrg, count) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_ORG_SUCCESS,
    payload: { monitoringByOrg, count },
  }
}

const fetchByOrganizationsFailure = (message, code) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_ORG_FAILED,
    payload: { message, code },
  }
}

const fetchByGroupRequest = () => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_GROUP_REQUEST,
  }
}

const fetchByGroupSuccess = (monitoringByGroup, count) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_GROUP_SUCCESS,
    payload: { monitoringByGroup, count },
  }
}

const fetchByGroupFailure = (message, code) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_GROUP_FAILED,
    payload: { message, code },
  }
}

const fetchByRegionsRequest = () => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_REGION_REQUEST,
  }
}

const fetchByRegionsSuccess = (monitoringByRegion, count) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_REGION_SUCCESS,
    payload: { monitoringByRegion, count },
  }
}

const fetchByRegionsFailure = (message, code) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_REGION_FAILED,
    payload: { message, code },
  }
}

const fetchByDistrictsRequest = () => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_DISTRICT_REQUEST,
  }
}

const fetchByDistrictsSuccess = (monitoringByDistrict, count) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_DISTRICT_SUCCESS,
    payload: { monitoringByDistrict, count },
  }
}

const fetchByDistrictsFailure = (message, code) => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_DISTRICT_FAILED,
    payload: { message, code },
  }
}

const fetchStateCleanup = () => {
  return {
    type: FETCH_ATTENDANCE_MONITORING_CLEANUP,
  }
}

export function clearAttendanceMonitoringOrg() {
  return {
    type: CLEAR_ATTENDANCE_MONITORING_ORG,
  }
}

export function clearAttendanceMonitoringGroup() {
  return {
    type: CLEAR_ATTENDANCE_MONITORING_GROUP,
  }
}

export function clearAttendanceMonitoringRegion() {
  return {
    type: CLEAR_ATTENDANCE_MONITORING_REGION,
  }
}

export function clearAttendanceMonitoringDistrict() {
  return {
    type: CLEAR_ATTENDANCE_MONITORING_DISTRICT,
  }
}
