import {
  CLEAR_DISTRICTS,
  CREATE_DISTRICT,
  DELETE_DISTRICT,
  FETCH_DISTRICTS_FAILURE,
  FETCH_DISTRICTS_REGIONID_FAILURE,
  FETCH_DISTRICTS_REGIONID_REQUEST,
  FETCH_DISTRICTS_REGIONID_SUCCESS,
  FETCH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICT_FAILURE,
  FETCH_DISTRICT_REQUEST,
  FETCH_DISTRICT_SUCCESS,
  UPDATE_DISTRICT,
} from '@app/store/types/districts'

import districtRepository from '@app/api/repositories/district-repository'
import educationGradeRepository from '@app/api/repositories/education-grade-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import { IDistrictActions } from '@app/interfaces/organization/districts'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import axios from 'axios'

export const getDistrict = (args: numberArgs, message) => async (dispatch) => {
  dispatch(fetchDistrictRequest())
  await districtRepository
    .getOne(args)
    .then((response) => {
      dispatch(fetchDistrictSuccess(response.data))
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        dispatch(fetchDistrictFailure(error?.message, error?.code))
      }
    })
}

export const getDistricts =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchDistrictsRequest())
    await districtRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchDistrictsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchDistrictsFailure(error?.message, error?.code))
        }
      })
  }

export const getDistrictsByRegion =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchDistrictsByRegionRequest())
    await educationGradeRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchDistrictsByRegionSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchDistrictsByRegionFailure(error?.message, error?.code))
          openNotificationWithIcon('error', message)
        }
      })
  }

const fetchDistrictsRequest = (): IDistrictActions => {
  return {
    type: FETCH_DISTRICTS_REQUEST,
  }
}

const fetchDistrictsSuccess = (districts, count): IDistrictActions => {
  return {
    type: FETCH_DISTRICTS_SUCCESS,
    payload: { districts, count },
  }
}

const fetchDistrictsFailure = (message, code): IDistrictActions => {
  return {
    type: FETCH_DISTRICTS_FAILURE,
    payload: { message, code },
  }
}

const fetchDistrictsByRegionRequest = (): IDistrictActions => {
  return {
    type: FETCH_DISTRICTS_REGIONID_REQUEST,
  }
}

const fetchDistrictsByRegionSuccess = (
  districtByRegionId,
  count,
): IDistrictActions => {
  return {
    type: FETCH_DISTRICTS_REGIONID_SUCCESS,
    payload: { districtByRegionId, count },
  }
}

const fetchDistrictsByRegionFailure = (message, code): IDistrictActions => {
  return {
    type: FETCH_DISTRICTS_REGIONID_FAILURE,
    payload: { message, code },
  }
}

const fetchDistrictRequest = (): IDistrictActions => {
  return {
    type: FETCH_DISTRICT_REQUEST,
  }
}

const fetchDistrictSuccess = (district): IDistrictActions => {
  return {
    type: FETCH_DISTRICT_SUCCESS,
    payload: district,
  }
}

const fetchDistrictFailure = (message, code): IDistrictActions => {
  return {
    type: FETCH_DISTRICT_FAILURE,
    payload: { message, code },
  }
}

export function clearDistricts() {
  return {
    type: CLEAR_DISTRICTS,
    payload: null,
  }
}

export function createDistrict(district): IDistrictActions {
  return {
    type: CREATE_DISTRICT,
    payload: district,
  }
}

export function updateDistrict(district): IDistrictActions {
  return {
    type: UPDATE_DISTRICT,
    payload: district,
  }
}

export function deleteDistrict(id): IDistrictActions {
  return {
    type: DELETE_DISTRICT,
    payload: id,
  }
}
