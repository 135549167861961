export const FETCH_ATTENDANCE_MONITORING_ORG_REQUEST =
  'FETCH_ATTENDANCE_MONITORING_ORG_REQUEST'
export const FETCH_ATTENDANCE_MONITORING_ORG_SUCCESS =
  'FETCH_ATTENDANCE_MONITORING_ORG_SUCCESS'
export const FETCH_ATTENDANCE_MONITORING_ORG_FAILED =
  'FETCH_ATTENDANCE_MONITORING_ORG_FAILED'

export const FETCH_ATTENDANCE_MONITORING_GROUP_REQUEST =
  'FETCH_ATTENDANCE_MONITORING_GROUP_REQUEST'
export const FETCH_ATTENDANCE_MONITORING_GROUP_SUCCESS =
  'FETCH_ATTENDANCE_MONITORING_GROUP_SUCCESS'
export const FETCH_ATTENDANCE_MONITORING_GROUP_FAILED =
  'FETCH_ATTENDANCE_MONITORING_GROUP_FAILED'

export const FETCH_ATTENDANCE_MONITORING_REGION_REQUEST =
  'FETCH_ATTENDANCE_MONITORING_REGION_REQUEST'
export const FETCH_ATTENDANCE_MONITORING_REGION_SUCCESS =
  'FETCH_ATTENDANCE_MONITORING_REGION_SUCCESS'
export const FETCH_ATTENDANCE_MONITORING_REGION_FAILED =
  'FETCH_ATTENDANCE_MONITORING_REGION_FAILED'

export const FETCH_ATTENDANCE_MONITORING_DISTRICT_REQUEST =
  'FETCH_ATTENDANCE_MONITORING_DISTRICT_REQUEST'
export const FETCH_ATTENDANCE_MONITORING_DISTRICT_SUCCESS =
  'FETCH_ATTENDANCE_MONITORING_DISTRICT_SUCCESS'
export const FETCH_ATTENDANCE_MONITORING_DISTRICT_FAILED =
  'FETCH_ATTENDANCE_MONITORING_DISTRICT_FAILED'

export const CLEAR_ATTENDANCE_MONITORING_ORG = 'CLEAR_ATTENDANCE_MONITORING_ORG'
export const CLEAR_ATTENDANCE_MONITORING_GROUP =
  'CLEAR_ATTENDANCE_MONITORING_GROUP'
export const CLEAR_ATTENDANCE_MONITORING_REGION =
  'CLEAR_ATTENDANCE_MONITORING_REGION'
export const CLEAR_ATTENDANCE_MONITORING_DISTRICT =
  'CLEAR_ATTENDANCE_MONITORING_DISTRICT'

export const FETCH_ATTENDANCE_MONITORING_CLEANUP =
  'FETCH_ATTENDANCE_MONITORING_CLEANUP'
