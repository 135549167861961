import {
  CLEAR_EMPLOYEE_RATE,
  CREATE_EMPLOYEE_RATE,
  DELETE_EMPLOYEE_RATE,
  FETCH_EMPLOYEE_RATES_FAILURE,
  FETCH_EMPLOYEE_RATES_REQUEST,
  FETCH_EMPLOYEE_RATES_SUCCESS,
  FETCH_EMPLOYEE_RATE_FAILURE,
  FETCH_EMPLOYEE_RATE_REQUEST,
  FETCH_EMPLOYEE_RATE_SUCCESS,
  UPDATE_EMPLOYEE_RATE,
} from '@app/store/types/employee-rate'

import employeeRateRepository from '@app/api/repositories/employee-rate-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import { IEmployeeRateActions } from '@app/interfaces/employee/employee-rate'
import axios from 'axios'

export const getEmployeeRate =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeeRateRequest())
    await employeeRateRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchEmployeeRateSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeeRateFailure(error?.message, error?.code))
        }
      })
  }

export const getEmployeeRates =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeeRatesRequest())
    await employeeRateRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchEmployeeRatesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeeRatesFailure(error?.message, error?.code))
        }
      })
  }

const fetchEmployeeRatesRequest = (): IEmployeeRateActions => {
  return {
    type: FETCH_EMPLOYEE_RATES_REQUEST,
  }
}

const fetchEmployeeRatesSuccess = (
  employeeRates,
  count,
): IEmployeeRateActions => {
  return {
    type: FETCH_EMPLOYEE_RATES_SUCCESS,
    payload: { employeeRates, count },
  }
}

const fetchEmployeeRatesFailure = (message, code): IEmployeeRateActions => {
  return {
    type: FETCH_EMPLOYEE_RATES_FAILURE,
    payload: { message, code },
  }
}

const fetchEmployeeRateRequest = (): IEmployeeRateActions => {
  return {
    type: FETCH_EMPLOYEE_RATE_REQUEST,
  }
}

const fetchEmployeeRateSuccess = (employeeRate): IEmployeeRateActions => {
  return {
    type: FETCH_EMPLOYEE_RATE_SUCCESS,
    payload: employeeRate,
  }
}

const fetchEmployeeRateFailure = (message, code): IEmployeeRateActions => {
  return {
    type: FETCH_EMPLOYEE_RATE_FAILURE,
    payload: { message, code },
  }
}

export function clearEmployeeRates() {
  return {
    type: CLEAR_EMPLOYEE_RATE,
    payload: null,
  }
}

export function createEmployeeRate(employeeRate): IEmployeeRateActions {
  return {
    type: CREATE_EMPLOYEE_RATE,
    payload: employeeRate,
  }
}

export function updateEmployeeRate(employeeRate): IEmployeeRateActions {
  return {
    type: UPDATE_EMPLOYEE_RATE,
    payload: employeeRate,
  }
}

export function deleteEmployeeRate(employeeRate): IEmployeeRateActions {
  return {
    type: DELETE_EMPLOYEE_RATE,
    payload: employeeRate,
  }
}
