import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationStatusRepository from '@app/api/repositories/organization-status-repository'
import { IOrganizationStatusActions } from '@app/interfaces/organization/organization-status'
import {
  CLEAR_ORGANIZATION_STATUS,
  CREATE_ORGANIZATION_STATUS,
  DELETE_ORGANIZATION_STATUS,
  FETCH_ORGANIZATION_STATUSES_FAILURE,
  FETCH_ORGANIZATION_STATUSES_REQUEST,
  FETCH_ORGANIZATION_STATUSES_SUCCESS,
  FETCH_ORGANIZATION_STATUS_FAILURE,
  FETCH_ORGANIZATION_STATUS_REQUEST,
  FETCH_ORGANIZATION_STATUS_SUCCESS,
  UPDATE_ORGANIZATION_STATUS,
} from '@app/store/types/organization-status'
import axios from 'axios'

export const getOrganizationStatus =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationStatusRequest())
    await organizationStatusRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationStatusSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationStatusFailure(error?.message, error?.code))
        }
      })
  }

export const getOrganizationStatuses =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationStatusesRequest())
    await organizationStatusRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchOrganizationStatusesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationStatusesFailure(error?.message, error?.code),
          )
        }
      })
  }

const fetchOrganizationStatusesRequest = (): IOrganizationStatusActions => {
  return {
    type: FETCH_ORGANIZATION_STATUSES_REQUEST,
  }
}

const fetchOrganizationStatusesSuccess = (
  organizationStatuses,
  count,
): IOrganizationStatusActions => {
  return {
    type: FETCH_ORGANIZATION_STATUSES_SUCCESS,
    payload: { organizationStatuses, count },
  }
}

const fetchOrganizationStatusesFailure = (
  message,
  code,
): IOrganizationStatusActions => {
  return {
    type: FETCH_ORGANIZATION_STATUSES_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationStatusRequest = (): IOrganizationStatusActions => {
  return {
    type: FETCH_ORGANIZATION_STATUS_REQUEST,
  }
}

const fetchOrganizationStatusSuccess = (
  organizationStatus,
): IOrganizationStatusActions => {
  return {
    type: FETCH_ORGANIZATION_STATUS_SUCCESS,
    payload: organizationStatus,
  }
}

const fetchOrganizationStatusFailure = (
  message,
  code,
): IOrganizationStatusActions => {
  return {
    type: FETCH_ORGANIZATION_STATUS_FAILURE,
    payload: { message, code },
  }
}

export function clearOrganizationStatus() {
  return {
    type: CLEAR_ORGANIZATION_STATUS,
    payload: null,
  }
}

export function updateOrganizationStatus(
  organizationStatus,
): IOrganizationStatusActions {
  return {
    type: UPDATE_ORGANIZATION_STATUS,
    payload: organizationStatus,
  }
}

export function createOrganizationStatus(
  organizationStatus,
): IOrganizationStatusActions {
  return {
    type: CREATE_ORGANIZATION_STATUS,
    payload: organizationStatus,
  }
}

export function deleteOrganizationStatus(
  organizationStatus,
): IOrganizationStatusActions {
  return {
    type: DELETE_ORGANIZATION_STATUS,
    payload: organizationStatus,
  }
}
