import bbitApi from '@api/make-request'
import NationalityRepository from '@api/repositories/nationality-repository'
import { getForeignPupil, getPupil } from '@api/rest/registry-office/get-pupil'
import PupilsRepository from '@app/api/repositories/pupils-repository'
import { host } from '@app/constants/env'
import { IFile } from '@app/interfaces/files'
import { INationality } from '@app/interfaces/nationality'
import { IRegistryPupil } from '@app/interfaces/pupil/pupils'
import PupilItem from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/found-pupil/components/pupil-item/pupil-item'
import PupilsParentItem from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/found-pupil/components/pupils-parent-item/pupils-parent-item'
import StepsButtons from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/steps-buttons'
import Loader from '@app/shared/loader/loader'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { preLoadFile } from '@app/store/actions/files-actions'
import { getNationalities } from '@app/store/actions/nationality-actions'
import {
  createPupil,
  getRegistryPupils,
  updatePupil,
} from '@app/store/actions/pupils-actions'
import { IManualPupilAddState } from '@app/store/reducers/pupil-manual-add-reducer'
import { Form } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  current: number
  prev: () => void
  next: () => void
  pupil: IRegistryPupil
  _nationalities: INationality[]
  citizenship: string
  pupilInfo: IManualPupilAddState
  setConfirmLoading: (data: boolean) => void
  avatar: IFile
  loading: boolean
}

const FoundPupil: React.FC<props> = ({
  current,
  prev,
  next,
  pupil,
  _nationalities,
  citizenship,
  pupilInfo,
  setConfirmLoading,
  avatar,
  loading,
}) => {
  const [form] = Form.useForm()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const pupilData = {
    metricSerial: pupilInfo.metricSerial,
    metricNumber: pupilInfo.metricNumber,
    birthDate: pupilInfo.birthDate,
  }
  const pupilNonResidentData = {
    metric: pupilInfo.metricNumber,
    birthDate: pupilInfo.birthDate,
  }
  const getPupilFunction = () => {
    if (citizenship === 'native') {
      getPupil(pupilData)
        .then((response) => {
          dispatch(getRegistryPupils(response.data))
          if (response.data?.pupil?.fileId) {
            bbitApi
              .get(`${host}/files/api/v1/files/${response.data.pupil.fileId}`)
              .then((response) => {
                dispatch(preLoadFile(response.data))
                setLoaded(true)
              })
              .catch(() => {
                setLoaded(true)
                openNotificationWithIcon('error', t('users.errorAvatar'))
              })
          } else {
            setLoaded(true)
          }
        })
        .catch(() => {
          openNotificationWithIcon('error', t('pupils.error'))
          setLoaded(true)
        })
    } else {
      getForeignPupil(pupilNonResidentData)
        .then((response) => {
          dispatch(getRegistryPupils(response.data))
          if (response.data?.pupil?.fileId) {
            bbitApi
              .get(`${host}/files/api/v1/files/${response.data.pupil.fileId}`)
              .then((response) => {
                dispatch(preLoadFile(response.data))
                setLoaded(true)
              })
              .catch(() => {
                setLoaded(true)
                openNotificationWithIcon('error', t('users.errorAvatar'))
              })
          } else {
            setLoaded(true)
          }
        })
        .catch(() => {
          openNotificationWithIcon('error', t('pupils.error'))
          prev()
        })
    }
  }

  useEffect(() => {
    setLoaded(false)
    const source = axios.CancelToken.source()
    NationalityRepository.getAll({ source })
      .then(function (response) {
        dispatch(getNationalities(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          openNotificationWithIcon('error', t('nationality.error'))
        }
      })
    getPupilFunction()
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  if (!loaded) {
    return <Loader />
  }

  const dateFormat = 'YYYY-MM-DD'
  const anotherDateFormat = 'DD.MM.YYYY'

  const onFinish = (values: any) => {
    const parents = values.parents.length
      ? values.parents.map((parent) => {
          return {
            ...parent,
            fullName: parent.fullName.toUpperCase(),
            resident: parent.resident ? parent.resident : false,
          }
        })
      : undefined

    const newValues = {
      ...values,
      fileId: avatar.id ? avatar.id : undefined,
      fullName: values.fullName.toUpperCase(),
      birthDate: values['birthDate'].format(dateFormat),
      resident: citizenship === 'native',
      parents: parents,
    }

    setConfirmLoading(true)
    const pupilsApi = pupil.pupil?.id
      ? PupilsRepository.updateFull
      : PupilsRepository.createFull
    pupilsApi(newValues)
      .then((response) => {
        if (response.status === 201) {
          getPupilFunction()
          dispatch(createPupil(response.data))
          openNotificationWithIcon('success', t('pupils.successAdd'))
          setConfirmLoading(false)
          next()
        }
        if (response.status === 200) {
          dispatch(updatePupil(response.data))
          openNotificationWithIcon('success', t('pupils.successEdit'))
          setConfirmLoading(false)
          next()
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('pupils.error'))
        setConfirmLoading(false)
      })
  }
  const metric = pupilInfo.metricSerial
    ? [pupilInfo.metricSerial, pupilInfo.metricNumber].join('№')
    : pupilInfo.metricNumber
  return (
    <div className="pupil-info">
      <Form
        form={form}
        initialValues={{
          id: pupil.pupil?.id || undefined,
          pin: pupil.child?.pin || pupil.pupil?.pin,
          fullName: pupil.child?.birthDate
            ? [
                pupil.child?.surname,
                pupil.child?.name,
                pupil.child?.patronymic,
              ].join(' ')
            : pupil.pupil?.fullName,
          gender: pupil.child?.gender || pupil.pupil?.gender,
          address: pupil.pupil?.address ? pupil.pupil.address : undefined,
          phone: pupil.pupil?.phone ? pupil.pupil.phone : undefined,
          birthDate: moment(pupilInfo.birthDate, anotherDateFormat),
          metric: metric,
          socialProtection: pupil.pupil?.socialProtection || false,
          benefit: false,
          governmentStake: false,
          parents: pupil.pupil?.parents?.length
            ? pupil.pupil.parents
            : undefined,
        }}
        onFinish={onFinish}
      >
        <PupilItem
          avatar={avatar}
          setLoading={setConfirmLoading}
          pupil={pupil}
          pupilInfo={pupilInfo}
          confirmLoading={loading}
        />
        <PupilsParentItem
          confirmLoading={loading}
          pupil={pupil}
          _nationalities={_nationalities}
        />
      </Form>
      <StepsButtons
        confirmLoading={loading}
        form={form}
        prev={prev}
        current={current}
      />
    </div>
  )
}

export default FoundPupil
