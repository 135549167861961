import {
  CLEAR_ORGANIZATION_ATTENDANCE_EMPLOYEE,
  CLEAR_ORGANIZATION_ATTENDANCE_PUPIL,
  GET_ORGANIZATION_ATTENDANCE,
  GET_ORGANIZATION_ATTENDANCE_BY_PUPIL,
} from '@app/store/types/organization-attendance'
import {
  IOrganizationAttendanceEmployee,
  IOrganizationAttendancePupil,
  IOrganizationAttendanceState,
} from '@app/interfaces/organization-attendance'

const initialState: IOrganizationAttendanceState = {
  organizationAttendanceEmployee: {} as IOrganizationAttendanceEmployee,
  organizationAttendancePupil: {} as IOrganizationAttendancePupil,
}

export const organizationAttendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_ATTENDANCE:
      return {
        ...state,
        organizationAttendanceEmployee: action.payload,
      }
    case GET_ORGANIZATION_ATTENDANCE_BY_PUPIL:
      return {
        ...state,
        organizationAttendancePupil: action.payload,
      }
    case CLEAR_ORGANIZATION_ATTENDANCE_EMPLOYEE:
      return {
        ...state,
        organizationAttendanceEmployee: {},
      }
    case CLEAR_ORGANIZATION_ATTENDANCE_PUPIL:
      return {
        ...state,
        organizationAttendancePupil: {},
      }
    default:
      return state
  }
}
