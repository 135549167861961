import bbitApi from '@app/api/make-request'
import EducationGroupsRepository from '@app/api/repositories/education-groups-repository'
import OrganizationPupilRepository, {
  IGroupChangeRequest,
} from '@app/api/repositories/organization-pupil-repository'
import useLocalization from '@app/hooks/useLocalization'
import { IEducationGrade } from '@app/interfaces/education/education-grade'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { refreshAction } from '@app/store/actions/refresh-page-action'
import { Button, Form, Input, Table, Typography } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

interface props {
  educationGrades: IEducationGrade[]
  pupil: IOrganizationPupils
  onAccept: () => void
}

interface params {
  id?: string
}

const SelectGroupForm: React.FC<props> = ({
  pupil,
  onAccept,
  educationGrades,
}) => {
  const [t] = useTranslation()
  const params: params = useParams()
  const [error, setError] = useState<string>('')
  const navigate = useNavigate()
  const [selectedGroupId, setSelectedGroupId] = useState<string>('')
  const [orderNo, setOrderNo] = useState<string>('')
  const dispatch = useDispatch()
  const { localize } = useLocalization()
  const { data: availablEeducationGroups, isLoading } = useQuery<
    IEducationGroup[]
  >(
    'getAvailablEeducationGroups',
    async () => {
      const { data } = await bbitApi.get(
        `education/api/v1/education-group/available?pupilId=${pupil?.pupilId}`,
      )
      return data.map((group) => {
        return {
          key: group.id,
          group: group.name,
          capacity: group.groupCapacity,
          grade: localize(educationGrades).find(
            (educationGrade) => educationGrade.id === group.educationGradeId,
          )?.data.name,
          childrenSeats: group.groupChildren,
          freeSeats: group.groupFreeSeats,
          select: group.id,
        }
      })
    },
    { enabled: !!pupil.pupilId && !!educationGrades, cacheTime: 0 },
  )

  const onGroupSelected = (e) => {
    setSelectedGroupId(e.target.value)
    setError('')
  }

  const onOrderChanged = (e) => {
    setOrderNo(e.target.value)
    setError('')
  }

  const onAcceptHandler = (e) => {
    e.preventDefault()
    if (selectedGroupId === '') {
      setError(t('pupils.changeGroups.noGroupSelectedError'))
      return
    }
    if (!orderNo) {
      setError(t('pupils.changeGroups.noOrderSelectedError'))
      return
    }
    const changeGroupRequest: IGroupChangeRequest = {
      pupilId: pupil.pupilId,
      educationGroupId: parseInt(selectedGroupId),
      orderNo: orderNo,
      note: 'note',
    }

    EducationGroupsRepository.getOne({ data: parseInt(selectedGroupId) })
      .then((group) => {
        if (group.data.groupFreeSeats <= 0) {
          openNotificationWithIcon(
            'success',
            t('pupils.changeGroups.noFreeSeatsError'),
          )
          return
        }
        dispatch(refreshAction())
        OrganizationPupilRepository.changeGroup(changeGroupRequest)
          .then((response) => {
            openNotificationWithIcon(
              'success',
              t('pupils.changeGroups.success'),
            )
            onAccept()
            dispatch(refreshAction())
            navigate(`/organization/${params.id}/pupils`)
          })
          .catch((error) => {
            openNotificationWithIcon('error', error.message)
          })
      })
      .catch((error) => {
        openNotificationWithIcon(
          'success',
          t('pupils.changeGroups.groupNotFoundError'),
        )
      })
  }

  const columns = [
    {
      title: t('pupils.changeGroups.educationGroup'),
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: t('pupils.changeGroups.capacity'),
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: t('pupils.changeGroups.educationGrade'),
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: t('pupils.changeGroups.children'),
      dataIndex: 'childrenSeats',
      key: 'childrenSeats',
    },
    {
      title: t('pupils.changeGroups.freeSeats'),
      dataIndex: 'freeSeats',
      key: 'freeSeats',
    },
    {
      title: t('pupils.changeGroups.select'),
      key: 'select',
      dataIndex: 'select',
      render: (text, record) => (
        <Input
          type="radio"
          value={record.select}
          name="groupId"
          onClick={onGroupSelected}></Input>
      ),
    },
  ]

  return (
    <div>
      <>
        <Typography.Title level={5}>
          {' '}
          {t('pupils.changeGroups.selectGroup')}
        </Typography.Title>
        <Table
          columns={columns}
          dataSource={availablEeducationGroups}
          pagination={false}
          rowClassName="table-row"
          loading={isLoading}
        />
        <Form>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            name={'orderNo'}
            label={t('pupils.changeGroups.orderNo')}
            style={{ marginTop: '20px' }}>
            <Input onChange={onOrderChanged} />
          </Form.Item>
        </Form>
        <Typography.Text
          type="danger"
          style={{ display: 'block', marginBottom: '20px' }}>
          {error}
        </Typography.Text>
        <Button onClick={onAcceptHandler}>{t('general.accept')}</Button>
      </>
    </div>
  )
}

export default SelectGroupForm
