import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganization,
  IOrganizationState,
} from '@app/interfaces/organization/organization'
import {
  CLEAR_ORGANIZATION,
  CLEAR_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATION_CLEANUP,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  REFRESH_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from '@app/store/types/organization'

const initialState: IOrganizationState = {
  organizations: [],
  organization: {} as IOrganization,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORGANIZATION:
      return { ...state, organization: {}, state: IDLE }
    case CLEAR_ORGANIZATIONS:
      return { ...state, organizations: [], state: IDLE }
    case REFRESH_ORGANIZATION:
      return { ...state, refresh: !state.refresh }
    case CREATE_ORGANIZATION:
      return {
        ...state,
        organizations: [...state.organizations, action.payload],
      }
    case UPDATE_ORGANIZATION:
      const updatedOrganizations = state.organizations.map(function (
        organization,
      ) {
        if (action.payload.id === organization.id) {
          return action.payload
        }
        return organization
      })
      return {
        ...state,
        organizations: updatedOrganizations,
        refresh: !state.refresh,
      }
    case DELETE_ORGANIZATION:
      const editedOrganizations = state.organizations.filter(function (
        organization,
      ) {
        if (action.payload.id === organization.id) {
          return false
        }
        return organization
      })
      return { ...state, organizations: editedOrganizations }
    case FETCH_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizations: action.payload.organizations,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizations: [],
      }
    case FETCH_ORGANIZATION_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_ORGANIZATION_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organization: action.payload,
      }
    case FETCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organization: {},
      }
    default:
      return state
  }
}
