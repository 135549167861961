import educationGroupsRepository from '@app/api/repositories/education-groups-repository'
import organizationPupilRepository from '@app/api/repositories/organization-pupil-repository'
import OrganizationTimesheetRepository from '@app/api/repositories/organization-timesheet-repository'
import { ApiKeys } from '@app/constants/api-keys'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import useXlsx, { borderType } from '@app/hooks/useXlsx'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import {
  IOrganizationTimesheetData,
  IOrganizationTimesheetEmployee,
  IOrganizationTimesheetPupil,
  IOrganizationTimesheetReason,
} from '@app/interfaces/organization-timesheet'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IInstitutionSchedule } from '@app/interfaces/organization/institution-schedule'
import { IRegion } from '@app/interfaces/organization/region'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import { IReason } from '@app/interfaces/reasons'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  getOrganizationTimesheetEmployee,
  getOrganizationTimesheetPupil,
} from '@app/store/actions/organization-timesheet-actions'
import { useGetOrganizationQuery } from '@app/store/rtk/api/organization/organization-api'
import { capitalizeName } from '@app/utils/capitalize/capitalize'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import {
  Button,
  Card,
  DatePicker,
  Form,
  Pagination,
  Popover,
  Row,
  Select,
  Table,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import axios from 'axios'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as XLSX from 'xlsx-js-style'
import './organization-timesheet.less'

interface Props {
  _organizationTimesheetEmployee: IOrganizationTimesheetEmployee[]
  _organizationTimesheetPupil: IOrganizationTimesheetPupil[]
}

interface Params {
  id?: string
}

enum TableType {
  Pupils,
  Employees,
  None,
}

export const monthNames = {
  ['01']: 'Январь',
  ['02']: 'Феварль',
  ['03']: 'Март',
  ['04']: 'Апрель',
  ['05']: 'Май',
  ['06']: 'Июнь',
  ['07']: 'Июль',
  ['08']: 'Август',
  ['09']: 'Сентябрь',
  ['10']: 'Октябрь',
  ['11']: 'Ноябрь',
  ['12']: 'Декабрь',
}

const OrganizationTimesheet: React.FC<Props> = ({
  _organizationTimesheetEmployee,
  _organizationTimesheetPupil,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const params: Params = useParams()
  const [form] = Form.useForm()
  const {
    createHeaderCell,
    addBlankStyledCellsForEmployee,
    formatDate,
    getMinMaxDays,
    border,
    blankBorderedCell,
    createHolidayCell,
    formatHours,
    setWidthForCells,
    createCell,
    createEmptyObjects,
    createUnderlineObjects,
    createEmptyStrings,
  } = useXlsx()
  setDocumentTitle(t('organizationTimesheet.title'))
  const [tableType, setTableType] = useState<TableType>(TableType.None)
  const [tableDate, setTableDate] = useState<moment.Moment>(moment())
  const [tableData, setTableData] = useState<any[]>([])
  const [tableLoading, setTableLoading] = useState<boolean>(true)
  const [showTable, setShowTable] = useState<boolean>(false)
  const [groups, setGroups] = useState<IEducationGroup[]>([])
  const [groupID, setGroupID] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<string>('0')
  const [tablePage, setTablePage] = useState<number>(1)
  const [tableSize, setTableSize] = useState<number>(10)
  const [allowAccess, setAllowAccess] = useState<boolean>(false)
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)
  const { localizeData, localize } = useLocalization()
  const { data: institutionSchedule } = useCache<IInstitutionSchedule>(
    ApiKeys.institutionSchedule,
  )
  const { data: reasons } = useCache<IReason>(ApiKeys.reasons)
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts } = useCache<IDistrict>(ApiKeys.district)
  const { data: organization } = useGetOrganizationQuery(Number(params?.id), {
    skip: !params?.id,
  })

  const onTablePageChange = (page, pageSize) => {
    setTablePage(page)
    setTableSize(pageSize)
  }

  useEffect(() => {
    if (allowAccess && showTable) onShowHandler()
  }, [tableSize, tablePage, tableDate, groupID])

  const onChangeTableDateHandler = (date: moment.Moment | null) => {
    setTableDate(date ?? moment())
  }

  const tableTypeHandler = (type) => {
    setAllowAccess(true)
    setShowTable(false)
    setTableType(type)
  }

  const onDownloadPupilHandler = async (byGroup: boolean) => {
    setPopoverVisible(false)
    const source = axios.CancelToken.source()
    const timesheetRequest = {
      id: Number(params.id),
      year: Number.parseInt(tableDate.format('YYYY')),
      month: Number.parseInt(tableDate.format('MM')),
      page: 0,
      size: 100,
    }

    if (tableType === TableType.Pupils) {
      if (byGroup) timesheetRequest['educationGroupId'] = groupID
      setTableLoading(true)
      const pupils = await organizationPupilRepository
        .getAll({
          params: `organizationId.equals=${params?.id}${
            byGroup ? `&educationGroupId.equals=${groupID}` : ''
          }&status.in=APPROVED&page=0&size=100&sort=id,desc`,
        })
        .then((res) => res.data)
      OrganizationTimesheetRepository.getPupilTimesheet(
        timesheetRequest,
        source,
      )
        .then((response) => {
          setTableLoading(false)
          generatePupilXlsx(response.data, pupils, byGroup ? true : false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setTableLoading(false)
            openNotificationWithIcon(
              'error',
              t('organizationAttendance.errorByPupil'),
            )
          }
        })
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }

  const onDownloadHandler = () => {
    if (tableType === TableType.Pupils) {
      setPopoverVisible(true)
    }

    const source = axios.CancelToken.source()
    const timesheetRequest = {
      id: Number(params.id),
      year: Number.parseInt(tableDate.format('YYYY')),
      month: Number.parseInt(tableDate.format('MM')),
      page: 0,
      size: 1000,
    }

    if (tableType === TableType.Employees) {
      setTableLoading(true)
      OrganizationTimesheetRepository.getEmployeeTimesheet(
        timesheetRequest,
        source,
      )
        .then((response) => {
          setTableLoading(false)
          generateEmployeeXlsx(response.data)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setTableLoading(false)
            openNotificationWithIcon(
              'error',
              t('organizationAttendance.errorByEmployee'),
            )
          }
        })
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }

  const onShowHandler = () => {
    setShowTable(true)
    tableType === TableType.Employees
      ? showEmployee()
      : tableType === TableType.Pupils && showPupils()
  }

  const generatePupilXlsx = (
    data: IOrganizationTimesheetPupil[],
    pupils: IOrganizationPupils[],
    byGroup: boolean,
  ) => {
    const endCol = new Date(
      Number(tableDate.format('yyyy')),
      Number(tableDate.format('MM')),
      0,
    ).getDate()
    const pCount = countPupil(data)

    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([])
    ws['!merges'] = [
      // Header top
      { s: { r: 0, c: 0 }, e: { r: 0, c: endCol + 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: endCol + 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: endCol + 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: endCol + 7 } },

      // Form No
      { s: { r: 5, c: 30 }, e: { r: 5, c: 34 } },

      // Header bottom
      { s: { r: 4, c: 2 }, e: { r: 4, c: 14 } },
      { s: { r: 5, c: 2 }, e: { r: 5, c: 14 } },
      { s: { r: 6, c: 2 }, e: { r: 6, c: 14 } },
      { s: { r: 7, c: 2 }, e: { r: 7, c: 14 } },
      { s: { r: 8, c: 2 }, e: { r: 8, c: 14 } },
      { s: { r: 9, c: 2 }, e: { r: 9, c: 14 } },

      // Table Header
      { s: { r: 10, c: 0 }, e: { r: 11, c: 0 } },
      { s: { r: 10, c: 1 }, e: { r: 11, c: 1 } },
      { s: { r: 10, c: 2 }, e: { r: 11, c: 2 } },
      { s: { r: 10, c: 3 }, e: { r: 11, c: 3 } },
      { s: { r: 10, c: 4 }, e: { r: 10, c: 4 + endCol - 1 } },
      { s: { r: 10, c: endCol + 6 }, e: { r: 11, c: endCol + 6 } },
      { s: { r: 10, c: endCol + 7 }, e: { r: 11, c: endCol + 7 } },

      // Attended days
      { s: { r: 10, c: endCol + 4 }, e: { r: 10, c: endCol + 5 } },

      // Footer Top
      {
        s: { r: 15 + pCount, c: 10 },
        e: { r: 15 + pCount, c: 13 },
      },
      {
        s: { r: 16 + pCount, c: 10 },
        e: { r: 16 + pCount, c: 13 },
      },

      {
        s: { r: 15 + pCount, c: 18 },
        e: { r: 15 + pCount, c: 21 },
      },
      {
        s: { r: 16 + pCount, c: 18 },
        e: { r: 16 + pCount, c: 21 },
      },

      // Footer Bottom
      {
        s: { r: 17 + pCount, c: 10 },
        e: { r: 17 + pCount, c: 13 },
      },
      {
        s: { r: 18 + pCount, c: 10 },
        e: { r: 18 + pCount, c: 13 },
      },
      {
        s: { r: 17 + pCount, c: 18 },
        e: { r: 17 + pCount, c: 21 },
      },
      {
        s: { r: 18 + pCount, c: 18 },
        e: { r: 18 + pCount, c: 21 },
      },

      // // Hints Middle Header
      // {
      //   s: { r: 16 + pCount, c: 4 },
      //   e: { r: 16 + pCount, c: 9 },
      // },

      // // Hints Middle Body
      // {
      //   s: { r: 17 + pCount, c: 4 },
      //   e: { r: 17 + pCount, c: 6 },
      // },
      // {
      //   s: { r: 18 + pCount, c: 4 },
      //   e: { r: 18 + pCount, c: 6 },
      // },
      // {
      //   s: { r: 19 + pCount, c: 4 },
      //   e: { r: 19 + pCount, c: 6 },
      // },
      // {
      //   s: { r: 20 + pCount, c: 4 },
      //   e: { r: 20 + pCount, c: 6 },
      // },

      // {
      //   s: { r: 17 + pCount, c: 7 },
      //   e: { r: 17 + pCount, c: 9 },
      // },
      // {
      //   s: { r: 18 + pCount, c: 7 },
      //   e: { r: 18 + pCount, c: 9 },
      // },
      // {
      //   s: { r: 19 + pCount, c: 7 },
      //   e: { r: 19 + pCount, c: 9 },
      // },
      // {
      //   s: { r: 20 + pCount, c: 7 },
      //   e: { r: 20 + pCount, c: 9 },
      // },

      // // Hints Right Header
      // {
      //   s: { r: 16 + pCount, c: 13 },
      //   e: { r: 16 + pCount, c: 18 },
      // },

      // // Hints Right Body
      // {
      //   s: { r: 17 + pCount, c: 13 },
      //   e: { r: 17 + pCount, c: 15 },
      // },
      // {
      //   s: { r: 18 + pCount, c: 13 },
      //   e: { r: 18 + pCount, c: 15 },
      // },
      // {
      //   s: { r: 19 + pCount, c: 13 },
      //   e: { r: 19 + pCount, c: 15 },
      // },
      // {
      //   s: { r: 20 + pCount, c: 13 },
      //   e: { r: 20 + pCount, c: 15 },
      // },

      // {
      //   s: { r: 17 + pCount, c: 16 },
      //   e: { r: 17 + pCount, c: 18 },
      // },
      // {
      //   s: { r: 18 + pCount, c: 16 },
      //   e: { r: 18 + pCount, c: 18 },
      // },
      // {
      //   s: { r: 19 + pCount, c: 16 },
      //   e: { r: 19 + pCount, c: 18 },
      // },
      // {
      //   s: { r: 20 + pCount, c: 16 },
      //   e: { r: 20 + pCount, c: 18 },
      // },

      // // Footer Top
      // {
      //   s: { r: 27 + pCount, c: 10 },
      //   e: { r: 27 + pCount, c: 13 },
      // },
      // {
      //   s: { r: 28 + pCount, c: 10 },
      //   e: { r: 28 + pCount, c: 13 },
      // },

      // {
      //   s: { r: 27 + pCount, c: 18 },
      //   e: { r: 27 + pCount, c: 21 },
      // },
      // {
      //   s: { r: 28 + pCount, c: 18 },
      //   e: { r: 28 + pCount, c: 21 },
      // },

      // // Footer Middle
      // {
      //   s: { r: 29 + pCount, c: 10 },
      //   e: { r: 29 + pCount, c: 13 },
      // },
      // {
      //   s: { r: 30 + pCount, c: 10 },
      //   e: { r: 30 + pCount, c: 13 },
      // },
      // {
      //   s: { r: 29 + pCount, c: 18 },
      //   e: { r: 29 + pCount, c: 21 },
      // },
      // {
      //   s: { r: 30 + pCount, c: 18 },
      //   e: { r: 30 + pCount, c: 21 },
      // },

      // // Footer Bottom
      // {
      //   s: { r: 31 + pCount, c: 10 },
      //   e: { r: 31 + pCount, c: 13 },
      // },
      // {
      //   s: { r: 32 + pCount, c: 10 },
      //   e: { r: 32 + pCount, c: 13 },
      // },
      // {
      //   s: { r: 31 + pCount, c: 18 },
      //   e: { r: 31 + pCount, c: 21 },
      // },
      // {
      //   s: { r: 32 + pCount, c: 18 },
      //   e: { r: 32 + pCount, c: 21 },
      // },
    ]

    ws['!cols'] = [
      { width: 5 },
      { width: 30 },
      { width: 6 },
      { width: 6 },
      ...setWidthForCells(endCol),
    ]

    ws['!rows'] = [
      ...createEmptyObjects(11),
      { hpt: 60 },
      ...createEmptyObjects(pCount),
      { hpt: 40 },
    ]
    const Header = [
      [
        // createCell({
        //   title: t('reports.pupilXlsx.headerTop1'),
        //   font: { bold: true, size: 16 },
        //   alignment: { horizontal: 'center' },
        // }),
      ],
      [
        createCell({
          title: t('reports.pupilXlsx.headerTop3'),
          font: { bold: true, size: 16 },
          alignment: { horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: `${monthNames[tableDate.format('MM')]} ${tableDate.format(
            'yyyy',
          )} ${t('reports.pupilXlsx.headerTop4')}`,
          alignment: { horizontal: 'center' },
        }),
      ],
      [
        // createCell({
        //   title: t('reports.pupilXlsx.headerTop2'),
        //   font: { bold: true, size: 16 },
        //   alignment: { horizontal: 'center' },
        // }),
      ],
      [
        '',
        createCell({
          title: t('reports.pupilXlsx.headerBottom1'),
        }),
        createCell({
          title: organization?.name,
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
        createCell({ title: t('reports.pupilXlsx.headerFormNo') }),
      ],
      [
        '',
        createCell({ title: t('reports.pupilXlsx.headerBottom2') }),
        createCell({
          title: `${
            localize(regions)?.find(
              (region) => region.id === organization?.region.id,
            )?.data.name
          } ${
            localize(districts)?.find(
              (district) => district.id === organization?.district.id,
            )?.data.name
          }`,
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
      [
        '',
        createCell({ title: t('reports.pupilXlsx.headerBottom3') }),
        createCell({
          title: localizeData(organization?.passport?.institutionType?.data)
            ?.name,
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
      [
        '',
        createCell({ title: t('reports.pupilXlsx.headerBottom4') }),
        createCell({
          title: 'окончательный',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
      [
        '',
        createCell({ title: t('reports.pupilXlsx.headerBottom5') }),
        createCell({
          title: byGroup
            ? groups.find((group) => group.id === groupID)?.name
            : '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
      [
        '',
        createCell({ title: t('reports.pupilXlsx.headerBottom6') }),
        createCell({
          title: localize(institutionSchedule)?.find(
            (schedule) =>
              schedule.id ===
              groups.find((group) => group.id === groupID)
                ?.institutionScheduleId,
          )?.data.name,
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
    ]

    const Heading = [
      [
        ...createHeaderCell('№'),
        ...createHeaderCell(t('reports.pupilXlsx.tableHeader1')),
        ...createHeaderCell(t('reports.pupilXlsx.tableHeader2')),
        ...createHeaderCell(t('reports.pupilXlsx.tableHeader3')),
        ...createHeaderCell(t('reports.pupilXlsx.tableHeader4')),
        ...addBlankStyledCellsForEmployee(endCol),
        ...createHeaderCell(t('reports.pupilXlsx.tableHeader5')),
        ...createHeaderCell(''),
        ...createHeaderCell(t('reports.pupilXlsx.tableHeader8')),
        ...createHeaderCell(t('reports.pupilXlsx.tableHeader9')),
      ],
      pushDaysToPupilHeader(endCol),
    ]

    const HintsLeft = [
      [
        createCell({
          title: 'Количество рабочих дней',
          borderType: borderType.CUSTOM,
          border: { left: true, top: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true, top: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'Списочный состав',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'По плану',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: '100%',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: '71%',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'Девочки',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'Мальчики',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'рус',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'узбек',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'козок',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'татар',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'корей',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
    ]

    const HintsMiddle = [
      [
        ...createHeaderCell('оплата'),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
      ],
      [
        createCell({
          title: 'дни',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: '100%',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: '71%',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'Освобожден',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
    ]

    const HintsRight = [
      [
        ...createHeaderCell('по факту'),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
      ],
      [
        createCell({
          title: 'дни',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: '100%',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: '71%',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: 'Освобожден',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
    ]

    const TableLastRow: any = []
    const TableInnerArray: any = [
      ...blankBorderedCell,
      createCell({
        title: t('reports.pupilXlsx.tableFooter'),
        borderType: borderType.CUSTOM,
        border: { bottom: true, top: true, left: true, right: true },
        alignment: { horizontal: 'true', vertical: 'true', wrapText: true },
      }),
      ...blankBorderedCell,
      ...blankBorderedCell,
    ]
    for (let i = 0; i < endCol; i++) {
      TableInnerArray.push(
        createCell({
          title: String(getTotalAbsentPupilByDay(data, i + 1)),
          borderType: borderType.CUSTOM,
          border: { bottom: true, top: true, left: true, right: true },
        }),
      )
    }
    TableInnerArray.push(...createHeaderCell(t('')))
    TableInnerArray.push(...createHeaderCell(t('')))
    TableInnerArray.push(...createHeaderCell(t('')))
    TableInnerArray.push(...createHeaderCell(t('')))
    TableLastRow.push(TableInnerArray)

    const Footer = [
      [
        createCell({
          title: t('reports.pupilXlsx.footer1'),
          font: { bold: true, size: 14 },
        }),
        ...createEmptyStrings(8),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createEmptyStrings(4),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
      ],
      [
        ...createEmptyStrings(9),
        createCell({
          title: t('reports.pupilXlsx.footerSign'),
          font: { size: 12, bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        ...createEmptyStrings(7),
        createCell({
          title: t('reports.pupilXlsx.footerFIO'),
          font: { size: 12, bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: t('reports.pupilXlsx.footer2'),
          font: { bold: true, size: 14 },
        }),
        ...createEmptyStrings(8),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createEmptyStrings(4),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
      ],
      [
        ...createEmptyStrings(9),
        createCell({
          title: t('reports.pupilXlsx.footerSign'),
          font: { size: 12, bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        ...createEmptyStrings(7),
        createCell({
          title: t('reports.pupilXlsx.footerFIO'),
          font: { size: 12, bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
    ]

    XLSX.utils.sheet_add_aoa(ws, Header)
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A11' })
    XLSX.utils.sheet_add_json(ws, changePupilsFields(data, pupils, endCol), {
      origin: 'A13',
      skipHeader: true,
    })
    // XLSX.utils.sheet_add_aoa(ws, HintsLeft, {
    //   origin: `B${14 + pCount}`,
    // })
    // XLSX.utils.sheet_add_aoa(ws, HintsMiddle, {
    //   origin: `E${17 + pCount}`,
    // })
    // XLSX.utils.sheet_add_aoa(ws, HintsRight, {
    //   origin: `N${17 + pCount}`,
    // })
    XLSX.utils.sheet_add_aoa(ws, TableLastRow, {
      origin: `A${pCount + 13}`,
    })
    XLSX.utils.sheet_add_aoa(ws, Footer, {
      origin: `B${16 + pCount}`,
    })
    XLSX.utils.book_append_sheet(wb, ws, 'table')
    XLSX.writeFile(
      wb,
      `Табель_воспитанники_${tableDate.format('yyyy-MM')}.xlsx`,
    )
  }

  const generateEmployeeXlsx = (data: IOrganizationTimesheetEmployee[]) => {
    const endCol = new Date(
      Number(tableDate.format('yyyy')),
      Number(tableDate.format('MM')),
      0,
    ).getDate()
    const eCount = countEmployees(data)

    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([])
    ws['!merges'] = [
      // Header top
      { s: { r: 0, c: 0 }, e: { r: 0, c: 36 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 36 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 36 } },

      // 421 Son Shakl
      { s: { r: 7, c: 18 }, e: { r: 7, c: 20 } },

      // Header bottom
      { s: { r: 5, c: 2 }, e: { r: 5, c: 17 } },
      { s: { r: 6, c: 2 }, e: { r: 6, c: 17 } },
      { s: { r: 7, c: 2 }, e: { r: 7, c: 17 } },
      { s: { r: 8, c: 2 }, e: { r: 8, c: 17 } },
      { s: { r: 9, c: 2 }, e: { r: 9, c: 17 } },
      { s: { r: 10, c: 2 }, e: { r: 10, c: 17 } },

      // Oydagi ish kunlari
      { s: { r: 12, c: 2 }, e: { r: 12, c: 4 } },

      // Table Header
      { s: { r: 14, c: 0 }, e: { r: 15, c: 0 } },
      { s: { r: 14, c: 1 }, e: { r: 15, c: 1 } },
      { s: { r: 14, c: 2 }, e: { r: 15, c: 2 } },
      { s: { r: 14, c: 3 }, e: { r: 15, c: 3 } },
      { s: { r: 14, c: 4 }, e: { r: 14, c: 4 + endCol - 1 } },

      // Jama ishga kelgan
      { s: { r: 14, c: endCol + 4 }, e: { r: 14, c: endCol + 5 } },

      // Хизмат сафарида бўлган кунлар сони
      { s: { r: 14, c: endCol + 6 }, e: { r: 15, c: endCol + 6 } },
      { s: { r: 14, c: endCol + 7 }, e: { r: 15, c: endCol + 7 } },

      // Hints Right 1st col
      {
        s: { r: 18 + eCount, c: 13 },
        e: { r: 18 + eCount, c: 20 },
      },
      {
        s: { r: 19 + eCount, c: 13 },
        e: { r: 19 + eCount, c: 20 },
      },
      {
        s: { r: 20 + eCount, c: 13 },
        e: { r: 20 + eCount, c: 20 },
      },
      {
        s: { r: 21 + eCount, c: 13 },
        e: { r: 21 + eCount, c: 20 },
      },
      {
        s: { r: 22 + eCount, c: 13 },
        e: { r: 22 + eCount, c: 20 },
      },
      {
        s: { r: 23 + eCount, c: 13 },
        e: { r: 23 + eCount, c: 20 },
      },
      {
        s: { r: 24 + eCount, c: 13 },
        e: { r: 24 + eCount, c: 20 },
      },
      // Hints right 2 col
      {
        s: { r: 18 + eCount, c: 21 },
        e: { r: 18 + eCount, c: 22 },
      },
      {
        s: { r: 19 + eCount, c: 21 },
        e: { r: 19 + eCount, c: 22 },
      },
      {
        s: { r: 20 + eCount, c: 21 },
        e: { r: 20 + eCount, c: 22 },
      },
      {
        s: { r: 21 + eCount, c: 21 },
        e: { r: 21 + eCount, c: 22 },
      },
      {
        s: { r: 22 + eCount, c: 21 },
        e: { r: 22 + eCount, c: 22 },
      },
      {
        s: { r: 23 + eCount, c: 21 },
        e: { r: 23 + eCount, c: 22 },
      },
      {
        s: { r: 24 + eCount, c: 21 },
        e: { r: 24 + eCount, c: 22 },
      },

      // Footer Top
      {
        s: { r: 29 + eCount, c: 5 },
        e: { r: 29 + eCount, c: 11 },
      },
      // Footer Bottom
      {
        s: { r: 30 + eCount, c: 15 },
        e: { r: 30 + eCount, c: 17 },
      },
      {
        s: { r: 30 + eCount, c: 21 },
        e: { r: 30 + eCount, c: 24 },
      },
    ]

    ws['!cols'] = [
      { width: 5 },
      { width: 30 },
      { width: 15 },
      { width: 10 },
      ...setWidthForCells(endCol),
      { width: 6 },
      { width: 6 },
      { width: 8 },
      { width: 8 },
    ]
    ws['!rows'] = [...createEmptyObjects(14), { hpt: 60 }]

    const Header = [
      [
        createCell({
          title: `${monthNames[tableDate.format('MM')]} ${t(
            'reports.employeeXlsx.headerTop1',
          )}`,
          font: { bold: true, size: 14 },
          alignment: { horizontal: 'center' },
        }),
      ],
      [createCell({})],
      [
        createCell({
          title: t('reports.employeeXlsx.headerTop2'),
          font: { bold: true, size: 16 },
          alignment: { horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: t('reports.employeeXlsx.headerTop3'),
          font: { bold: true, size: 18 },
          alignment: { horizontal: 'center' },
        }),
      ],
      [],
      [
        '',
        createCell({ title: t('reports.employeeXlsx.headerBottom1') }),
        createCell({
          title: organization?.name,
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
      [
        '',
        createCell({ title: t('reports.employeeXlsx.headerBottom2') }),
        createCell({
          title: `${
            localize(regions)?.find(
              (region) => region.id === organization?.region.id,
            )?.data.name
          } ${
            localize(districts)?.find(
              (district) => district.id === organization?.district.id,
            )?.data.name
          }`,
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
      [
        '',
        createCell({ title: t('reports.employeeXlsx.headerBottom3') }),
        createCell({
          title: localizeData(organization?.passport?.institutionType?.data)
            ?.name,
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(11),
        createCell({ title: t('reports.employeeXlsx.headerFormNo') }),
      ],
      [
        '',
        createCell({ title: t('reports.employeeXlsx.headerBottom4') }),
        createCell({
          title: 'окончательный',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createUnderlineObjects(11),
        ...createEmptyStrings(17),
      ],
      [''],
      [''],
      [],
      [
        ...createEmptyStrings(2),
        createCell({ title: t('reports.employeeXlsx.headerBottom7') }),
        ...createEmptyStrings(3),
        createCell({
          title: `${countWorkingDays(endCol)}`,
          borderType: borderType.ALL_ROUND,
        }),
      ],
    ]

    const Heading = [
      [
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader1')),
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader2')),
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader3')),
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader4')),
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader5')),
        ...addBlankStyledCellsForEmployee(endCol),
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader6')),
        ...blankBorderedCell,
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader9'), {
          textRotation: 90,
        }),
        ...createHeaderCell(t('reports.employeeXlsx.tableHeader10'), {
          textRotation: 90,
        }),
      ],
      pushDaysToEmployeeHeader(endCol),
    ]

    const HintsLeft = [
      [
        ...createHeaderCell(t('reports.employeeXlsx.hintsTitle')),
        ...createHeaderCell(t('reports.employeeXlsx.hintsCode')),
      ],
      [
        createCell({
          title: getReasonName(0) ? getReasonName(0) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: getReason(0) ? getReason(0)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: getReasonName(1) ? getReasonName(1) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: getReason(1) ? getReason(1)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: getReasonName(2) ? getReasonName(2) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: getReason(2) ? getReason(2)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: getReasonName(3) ? getReasonName(3) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: getReason(3) ? getReason(3)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: getReasonName(4) ? getReasonName(4) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        createCell({
          title: getReason(4) ? getReason(4)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
      [
        createCell({
          title: getReasonName(5) ? getReasonName(5) : '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: getReason(5) ? getReason(5)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
    ]
    const HintsRight = [
      [
        ...createHeaderCell(t('reports.employeeXlsx.hintsTitle')),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(''),
        ...createHeaderCell(t('reports.employeeXlsx.hintsCode')),
        ...createHeaderCell(''),
      ],
      [
        createCell({
          title: getReasonName(6) ? getReasonName(6) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        ...createEmptyStrings(7),
        createCell({
          title: getReason(6) ? getReason(6)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { right: true },
        }),
      ],
      [
        createCell({
          title: getReasonName(7) ? getReasonName(7) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        ...createEmptyStrings(7),
        createCell({
          title: getReason(7) ? getReason(7)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { right: true },
        }),
      ],
      [
        createCell({
          title: getReasonName(8) ? getReasonName(8) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        ...createEmptyStrings(7),
        createCell({
          title: getReason(8) ? getReason(8)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { right: true },
        }),
      ],
      [
        createCell({
          title: getReasonName(9) ? getReasonName(9) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        ...createEmptyStrings(7),
        createCell({
          title: getReason(9) ? getReason(9)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { right: true },
        }),
      ],
      [
        createCell({
          title: getReasonName(10) ? getReasonName(10) : '',
          borderType: borderType.CUSTOM,
          border: { left: true },
        }),
        ...createEmptyStrings(7),
        createCell({
          title: getReason(10) ? getReason(10)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { right: true },
        }),
      ],
      [
        createCell({
          title: getReasonName(11) ? getReasonName(11) : '',
          borderType: borderType.CUSTOM,
          border: { left: true, bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: getReason(11) ? getReason(11)?.shortName : '',
          borderType: borderType.CUSTOM,
          border: { left: true, right: true, bottom: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { right: true, bottom: true },
        }),
      ],
    ]

    const Footer = [
      [
        createCell({
          title: t('reports.employeeXlsx.footer'),
          font: { bold: true, size: 16 },
        }),
        ...createEmptyStrings(9),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        ...createEmptyStrings(3),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
        createCell({
          title: '',
          borderType: borderType.CUSTOM,
          border: { bottom: true },
        }),
      ],
      [
        ...createEmptyStrings(10),
        createCell({
          title: t('reports.employeeXlsx.footerSign'),
          font: { size: 14, bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
        ...createEmptyStrings(5),
        createCell({
          title: t('reports.employeeXlsx.footerFIO'),
          font: { size: 14, bold: true },
          alignment: { vertical: 'center', horizontal: 'center' },
        }),
      ],
    ]

    XLSX.utils.sheet_add_aoa(ws, Header)
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A15' })
    XLSX.utils.sheet_add_json(ws, changeEmployeeFields(data, endCol), {
      origin: 'A17',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, HintsLeft, {
      origin: `B${19 + eCount}`,
    })
    XLSX.utils.sheet_add_aoa(ws, HintsRight, {
      origin: `N${19 + eCount}`,
    })
    XLSX.utils.sheet_add_aoa(ws, Footer, {
      origin: `F${30 + eCount}`,
    })
    XLSX.utils.book_append_sheet(wb, ws, 'table')
    XLSX.writeFile(
      wb,
      `Табель_кадровый_состав_${tableDate.format('yyyy-MM')}.xlsx`,
    )
  }

  const pushDaysToEmployeeHeader = (number: number) => {
    const arr = [
      ...blankBorderedCell,
      ...blankBorderedCell,
      ...blankBorderedCell,
      ...blankBorderedCell,
    ]
    for (let i = 1; i <= number; i++) {
      arr.push(...createHeaderCell(String(i)))
    }

    arr.push(...createHeaderCell(t('reports.employeeXlsx.tableHeader7')))
    arr.push(...createHeaderCell(t('reports.employeeXlsx.tableHeader8')))
    arr.push(...blankBorderedCell)
    arr.push(...blankBorderedCell)
    return arr
  }

  const pushDaysToPupilHeader = (number: number) => {
    const arr = [
      ...blankBorderedCell,
      ...blankBorderedCell,
      ...blankBorderedCell,
      ...blankBorderedCell,
    ]
    for (let i = 1; i <= number; i++) {
      arr.push(...createHeaderCell(String(i)))
    }
    arr.push(...createHeaderCell(t('reports.pupilXlsx.tableHeader6')))
    arr.push(...createHeaderCell(t('reports.pupilXlsx.tableHeader7')))
    arr.push(...blankBorderedCell)
    arr.push(...blankBorderedCell)
    return arr
  }

  const isHoliday = (date: Date): boolean => {
    if (date.getDay() === 6 || date.getDay() === 0) return true
    return false
  }

  const formatDayOfWeekEmployee = (day: IOrganizationTimesheetData) => {
    if (isHoliday(new Date(day.workDate))) return createHolidayCell('В')
    if (day.reason) {
      if (day.reason.shortName === 'УП')
        return createCell({
          title: '0',
          alignment: {
            wrapText: true,
            horizontal: 'center',
            vertical: 'center',
          },
          borderType: borderType.ALL_ROUND,
          font: { size: 9 },
        })
      return createCell({
        title: day.reason.shortName,
        alignment: {
          wrapText: true,
          horizontal: 'center',
          vertical: 'center',
        },
        borderType: borderType.ALL_ROUND,
        font: { size: 9 },
      })
    }
    if (day.attended === 'ABSENT')
      return createCell({
        title: 'НБ',
        alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
        borderType: borderType.ALL_ROUND,
        font: { size: 9 },
      })
    return createCell({
      title: formatDate(day.hour),
      alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
      borderType: borderType.ALL_ROUND,
      font: { size: 9 },
    })
  }

  const formatDayOfWeekPupil = (day: IOrganizationTimesheetData) => {
    if (day.attended === 'ABSENT') {
      if (isHoliday(new Date(day.workDate))) return createHolidayCell('В')
      return createCell({
        title: 'НБ',
        alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
        borderType: borderType.ALL_ROUND,
      })
    }
    if (isHoliday(new Date(day.workDate))) return createHolidayCell('В')
    return createCell({
      title: '+',
      alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
      borderType: borderType.ALL_ROUND,
    })
  }

  const formatBlankDayOfWeek = (day) => {
    const date = new Date(
      Number(tableDate.format('yyyy')),
      Number(tableDate.format('MM')) - 1,
      day,
    )
    if (isHoliday(date)) {
      return createHolidayCell('В')
    }
    return createCell({
      title: '',
      alignment: {
        wrapText: true,
        horizontal: 'center',
        vertical: 'center',
      },
      borderType: borderType.ALL_ROUND,
    })
  }

  const countWorkingDays = (numberOfDays: number) => {
    let workingDays = 0
    for (let i = 1; i <= numberOfDays; i++) {
      if (
        !isHoliday(
          new Date(
            Number(tableDate.format('yyyy')),
            Number(tableDate.format('MM')) - 1,
            i,
          ),
        )
      )
        workingDays++
    }
    return workingDays
  }

  const getTotalAbsentPupilByDay = (
    data: IOrganizationTimesheetPupil[],
    day: number,
  ): number => {
    let total = 0
    data.forEach((pupil) => {
      const result = pupil.data.find(
        (date) =>
          date.workDate.split('-')[2].replace(/^0+/, '') === String(day),
      )
      result?.attended === 'ABSENT' &&
        !isHoliday(new Date(result.workDate)) &&
        total++
    })
    return total
  }

  const changeEmployeeFields = (
    data: IOrganizationTimesheetEmployee[],
    numberOfDays: number,
  ) => {
    const array: any[] = []
    const map = new Map()
    let index = 1
    data
      .sort((a, b) => a.fio.localeCompare(b.fio))
      .forEach((value) => {
        map.set(value.id, value)
      })
    map.forEach(({ fio, totalHours, totalDays, position, rate, data }) => {
      const jsonData = {}
      jsonData['т/р'] = createCell({
        title: String(index),
        alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Ходимнинг фамилияси, исми ва шарифи'] = createCell({
        title: capitalizeName(fio),
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Лавозими'] = createCell({
        title: localizeData(position?.data)?.name || '',
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Ставка'] = createCell({
        title: localizeData(rate?.data)?.name || '',
        borderType: borderType.ALL_ROUND,
      })

      let absentDays = 0
      for (let i = 1; i <= numberOfDays; i++) {
        const day = data.find(
          (day) => Number(day.workDate.split('-')[2].replace(/^0+/, '')) === i,
        )

        if (day) {
          if (day.attended === 'ABSENT' && !isHoliday(new Date(day.workDate)))
            absentDays++
          jsonData[`'${day.workDate.split('-')[2]}`] =
            formatDayOfWeekEmployee(day)
        } else {
          jsonData[`'${i}`] = formatBlankDayOfWeek(i)
        }
      }

      jsonData['Сони'] = createCell({
        title: String(totalDays),
        alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Соати'] = createCell({
        title: formatHours(totalHours) === '+' ? '0' : formatHours(totalHours),
        alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Хизмат сафарида бўлган кунлар сони'] = createCell({
        title: '',
        alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Ишга келмаган кунлари сони'] = createCell({
        title: `${absentDays}`,
        alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
        borderType: borderType.ALL_ROUND,
      })
      array.push(jsonData)
      index++
    })
    return array
  }

  const changePupilsFields = (
    data: IOrganizationTimesheetPupil[],
    pupils: IOrganizationPupils[],
    numberOfDays: number,
  ) => {
    const array: any[] = []
    const map = new Map()
    let index = 1
    data
      .sort((a, b) => a.fio.localeCompare(b.fio))
      .forEach((value) => {
        map.set(value.id, value)
      })
    map.forEach(({ fio, totalDays, educationGroupName, pinfl, data, id }) => {
      const jsonData = {}
      jsonData['№'] = createCell({
        title: String(index),
        alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['ФИО'] = createCell({
        title: capitalizeName(fio),
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Лицевой счет'] = createCell({
        title: '',
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Оплачиваемая ставка'] = createCell({
        title: pupils
          ? pupils.find((pupil) => pupil.pupilId === id)?.pupil
            ? pupils.find((pupil) => pupil.pupilId === id)?.pupil.benefit
              ? '100%'
              : '71%'
            : ''
          : '',
        borderType: borderType.ALL_ROUND,
      })
      // jsonData['Дни'] = createCell({
      //   title: '',
      //   borderType: borderType.ALL_ROUND,
      // })
      let absentDays = 0
      let presentDays = 0
      for (let i = 1; i <= numberOfDays; i++) {
        const day = data.find(
          (day) => Number(day.workDate.split('-')[2].replace(/^0+/, '')) === i,
        )
        if (!isEmpty(day)) {
          if (day.attended === 'ABSENT' && !isHoliday(new Date(day.workDate)))
            absentDays++
          if (day.attended === 'PRESENT') presentDays++
          jsonData[`'${day.workDate.split('-')[2]}`] = formatDayOfWeekPupil(day)
        } else {
          jsonData[`'${i}`] = formatBlankDayOfWeek(i)
        }
      }
      jsonData['Итого'] = createCell({
        title: String(absentDays),
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'center',
        },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Причина не посещения'] = createCell({
        title: '',
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'center',
        },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Оплачиваемые дни'] = createCell({
        title: String(presentDays),
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'center',
        },
        borderType: borderType.ALL_ROUND,
      })
      jsonData['Причины'] = createCell({
        title: '',
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'center',
        },
        borderType: borderType.ALL_ROUND,
      })

      array.push(jsonData)
      index++
    })
    return array
  }

  const getReasonName = (index: number): string | undefined => {
    return localizeData(reasons[index]?.data)?.name
  }

  const getReason = (index: number): IOrganizationTimesheetReason | null => {
    return index < reasons?.length ? reasons[index] : null
  }

  const countEmployees = (data: IOrganizationTimesheetEmployee[]) => {
    const map = new Map()
    data.forEach((value) => {
      map.set(value.id, value)
    })
    return map.size
  }

  const countPupil = (data: IOrganizationTimesheetPupil[]) => {
    const map = new Map()
    data.forEach((value) => {
      map.set(value.id, value)
    })
    return map.size
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(groups)) {
      educationGroupsRepository
        .getAll({
          params: `organizationId.equals=${params.id}&status.equals=OPEN`,
        })
        .then((res) => {
          setGroups(res.data)
          form.setFieldsValue({ group: res.data[0].id })
          setGroupID(res.data[0].id)
        })
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const showEmployee = () => {
    const source = axios.CancelToken.source()
    const timesheetRequest = {
      id: Number(params.id),
      year: Number.parseInt(tableDate.format('YYYY')),
      month: Number.parseInt(tableDate.format('MM')),
      page: tablePage - 1,
      size: tableSize,
    }
    if (tableType === TableType.Employees && allowAccess) {
      setTableLoading(true)
      OrganizationTimesheetRepository.getEmployeeTimesheet(
        timesheetRequest,
        source,
      )
        .then((response) => {
          const count = response.headers['x-total-count']
          setTotalCount(count)
          const result = response.data.map((employee) => {
            let minutes = 0
            employee.data.forEach((day, index) => {
              minutes += Number(day.hour.split(':')[1].replace(/^0+/, ''))
              if (index === employee.data.length - 1) {
                employee.data[index].leftMinutes = minutes
              }
            })
            return employee
          })
          dispatch(getOrganizationTimesheetEmployee(result))
          setTableLoading(false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setTableLoading(false)
            openNotificationWithIcon(
              'error',
              t('organizationAttendance.errorByEmployee'),
            )
          }
        })
    }
  }

  const showPupils = () => {
    const source = axios.CancelToken.source()
    const timesheetRequest = {
      id: Number(params.id),
      year: Number.parseInt(tableDate.format('YYYY')),
      month: Number.parseInt(tableDate.format('MM')),
      page: 0,
      size: 100,
      educationGroupId: groupID,
    }
    if (tableType === TableType.Pupils && allowAccess) {
      setTableLoading(true)
      OrganizationTimesheetRepository.getPupilTimesheet(
        timesheetRequest,
        source,
      )
        .then((response) => {
          dispatch(getOrganizationTimesheetPupil(response.data))
          setTableLoading(false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setTableLoading(false)
            openNotificationWithIcon(
              'error',
              t('organizationAttendance.errorByPupil'),
            )
          }
        })
    }
  }
  useEffect(() => {
    setTableData([])
    if (_organizationTimesheetPupil && _organizationTimesheetPupil.length > 0) {
      const data = _organizationTimesheetPupil?.map((i, index) => {
        return { ...i, key: index, ...i.data }
      })
      setTableData(data)
    }
  }, [_organizationTimesheetPupil])

  useEffect(() => {
    setTableData([])
    if (
      _organizationTimesheetEmployee &&
      _organizationTimesheetEmployee.length > 0
    ) {
      const data = _organizationTimesheetEmployee?.map((i, index) => {
        return { ...i, key: index, ...i.data }
      })
      setTableData(data)
    }
  }, [_organizationTimesheetEmployee])

  const columnsForEmployee = (employeeData) => {
    const initColumns = [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 5,
        render: (text, record, index) => (
          <>{(tablePage - 1) * tableSize + index + 1}</>
        ),
      },
      {
        title: t('organizationTimesheet.table.fio'),
        dataIndex: 'fio',
        key: 'fio',
        width: 220,
        fixed: 'left',
        defaultSortOrder: 'ascend',
        sorter: {
          compare: (a, b) => a.fio.localeCompare(b.fio),
          multiple: 1,
        },
        render: (fio) => (
          <p className="ellipsis-cell" style={{ width: 220 }}>
            {capitalizeName(fio)}
          </p>
        ),
      },
      {
        title: t('employee.position'),
        dataIndex: 'position',
        key: 'position',
        width: 160,
        sorter: {
          compare: (a, b) =>
            localizeData(a.position?.data)?.name.localeCompare(
              localizeData(b.position?.data)?.name || '',
            ),
          multiple: 2,
        },
        render: (position) => (
          <p className="ellipsis-cell" style={{ width: 160 }}>
            {localizeData(position?.data)?.name}
          </p>
        ),
      },
      {
        title: t('organizationTimesheet.table.rate'),
        dataIndex: 'rate',
        key: 'rate',
        width: 60,
        sorter: (a, b) => a.rate?.id.localeCompare(b.rate?.id),
        render: (rate) => <>{rate?.id === 20 ? '1' : '0.5'}</>,
      },
    ]
    const totalDays = {
      title: t('organizationTimesheet.table.totalDays'),
      dataIndex: 'totalDays',
      key: 'totalDays',
      width: 50,
      sorter: {
        compare: (a, b) => a.totalDays - b.totalDays,
        multiple: 3,
      },
    }
    const totalHours = {
      title: t('organizationTimesheet.table.totalHours'),
      dataIndex: 'totalHours',
      key: 'totalHours',
      width: 50,
      sorter: {
        compare: (a, b) =>
          formatHours(a.totalHours).localeCompare(formatHours(b.totalHours)),
        multiple: 4,
      },
      render: (cell) => {
        return formatHours(cell) === '+' ? 0 : formatHours(cell)
      },
    }

    const dayData = computeEmployeeDays(employeeData)
    return [...initColumns, totalHours, totalDays, ...dayData]
  }

  const columnsForPupil = (pupilData) => {
    const initColumns = [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        width: 5,
        fixed: 'left',
        render: (text, record, index) => (
          <>{(tablePage - 1) * tableSize + index + 1}</>
        ),
      },
      {
        title: t('organizationTimesheet.table.fio'),
        dataIndex: 'fio',
        key: 'fio',
        width: 200,
        fixed: 'left',
        defaultSortOrder: 'ascend',
        sorter: {
          compare: (a, b) => a.fio.localeCompare(b.fio),
          multiple: 1,
        },
        render: (fio) => (
          <p className="ellipsis-cell">{fio ? capitalizeName(fio) : ''}</p>
        ),
      },

      // {
      //   title: t('organizationTimesheet.table.group'),
      //   dataIndex: 'educationGroupName',
      //   key: 'educationGroupName',
      //   width: 200,
      //   sorter: (a, b) =>
      //     a.educationGroupName.localeCompare(b.educationGroupName),
      //   render: (educationGroupName) => (
      //     <p className="ellipsis-cell">
      //       {educationGroupName ? educationGroupName.toLowerCase() : ''}
      //     </p>
      //   ),
      // },
    ]
    const totalDays = {
      title: t('organizationTimesheet.table.totalDays'),
      dataIndex: 'totalDays',
      key: 'totalDays',
      width: 40,
      sorter: {
        compare: (a, b) => a.totalDays - b.totalDays,
        multiple: 2,
      },
    }

    const dayData = computePupilDays(pupilData)

    return [...initColumns, totalDays, ...dayData]
  }

  const computeEmployeeDays = (employeeData: any) => {
    const arr: any[] = []
    const currentDate = tableDate.clone().startOf('month')
    const daysCount = currentDate.daysInMonth()
    for (let i = 0; i < daysCount; i++) {
      // const day = employeeData[0].data.find(
      //   (day) =>
      //     Number(day.workDate.split('-')[2].replace(/^0+/, '')) === i + 1,
      // )
      // const leftMinutes = 0
      // employeeData[0]?.data.forEach((day) => {})
      const today = currentDate.format('yyyy-MM-DD')

      arr.push({
        title: (
          <div
            style={{
              textAlign: 'center',
              background:
                currentDate.day() === 0 || currentDate.day() === 6
                  ? '#ffc9bf73'
                  : '',
              lineHeight: '26px',
            }}
          >
            {getDayNames(currentDate.day())}
            <span
              style={{
                display: 'block',
                fontWeight: 'bold',
                lineHeight: '24px',
              }}
              color={'green'}
              key={today}
            >
              {today.split('-')[2]}
            </span>
          </div>
        ),
        dataIndex: i,
        key: i,
        width: 30,
        render: (cell, index) => {
          return {
            props: {
              style: {
                background: cell?.dayOfWeek
                  ? cell.dayOfWeek > 5
                    ? '#b4b4b434'
                    : currentDate.day() === 0 || currentDate.day() === 6
                    ? '#b4b4b434'
                    : ''
                  : '',
              },
            },
            children: (
              <p
                className={`${
                  cell?.attended
                    ? cell?.attended === 'ABSENT'
                      ? 'red-cell'
                      : 'green-cell'
                    : ''
                }`}
                key={index}
                style={{ fontSize: 10 }}
              >
                {cell?.attended
                  ? cell?.attended === 'ABSENT'
                    ? '-'
                    : formatHours(cell?.hour)
                  : ''}
              </p>
            ),
          }
        },
      })
      currentDate.add(1, 'd')
    }
    return arr
  }

  const computePupilDays = (pupilData: any) => {
    const arr: any[] = []
    const currentDate = tableDate.clone().startOf('month')
    const daysCount = currentDate.daysInMonth()
    for (let i = 0; i < daysCount; i++) {
      const day = pupilData[0].data.find(
        (day) =>
          Number(day.workDate.split('-')[2].replace(/^0+/, '')) === i + 1,
      )
      const today = currentDate.format('yyyy-MM-DD')

      arr.push({
        title: (
          <div
            style={{
              textAlign: 'center',
              background:
                currentDate.day() === 0 || currentDate.day() === 6
                  ? '#ffc9bf73'
                  : '',
              lineHeight: '26px',
            }}
          >
            {getDayNames(currentDate.day())}
            <span
              style={{
                display: 'block',
                fontWeight: 'bold',
                lineHeight: '24px',
              }}
              color={'green'}
              key={today}
            >
              {today.split('-')[2]}
            </span>
          </div>
        ),
        dataIndex: i,
        key: i,
        width: 25,
        render: (cell, index) => {
          return {
            props: {
              style: {
                background: cell?.dayOfWeek
                  ? cell.dayOfWeek > 5
                    ? '#b4b4b434'
                    : currentDate.day() === 0 || currentDate.day() === 6
                    ? '#b4b4b434'
                    : ''
                  : '',
              },
            },
            children: (
              <p
                className={`${
                  cell?.attended
                    ? cell?.attended === 'ABSENT'
                      ? 'red-cell'
                      : 'green-cell'
                    : ''
                }`}
                key={index}
              >
                {cell?.attended
                  ? cell?.attended === 'ABSENT'
                    ? '-'
                    : '+'
                  : ''}
              </p>
            ),
          }
        },
      })
      currentDate.add(1, 'd')
    }
    return arr
    // return employeeData[0].data.map((value, index) => {
    //   return {
    //     title: (
    //       <div
    //         style={{
    //           textAlign: 'center',
    //           background: value.dayOfWeek > 5 ? '#ffc9bf73' : '',
    //           lineHeight: '26px',
    //         }}
    //       >
    //         {getDayNames(value.dayOfWeek)}
    //         <span
    //           style={{
    //             display: 'block',
    //             fontWeight: 'bold',
    //             lineHeight: '24px',
    //           }}
    //           color={'green'}
    //           key={value.workDate}
    //         >
    //           {value.workDate.split('-')[2]}
    //         </span>
    //       </div>
    //     ),
    //     dataIndex: index,
    //     key: index,
    //     width: 25,
    //     render: (cell) => {
    //       return {
    //         props: {
    //           style: { background: cell?.dayOfWeek > 5 ? '#b4b4b434' : '' },
    //         },
    //         children: (
    //           <p
    //             className={`${
    //               cell?.attended === 'ABSENT' ? 'red-cell' : 'green-cell'
    //             }`}
    //             key={cell?.workDate}
    //           >
    //             {cell.attended === 'ABSENT' ? '-' : formatHours(cell?.hour)}
    //           </p>
    //         ),
    //       }
    //     },
    //   }
    // })
  }

  const getDayNames = (day) => {
    switch (day) {
      case 1:
        return t('organizationTimesheet.table.Mon')
      case 2:
        return t('organizationTimesheet.table.Tue')
      case 3:
        return t('organizationTimesheet.table.Wed')
      case 4:
        return t('organizationTimesheet.table.Thu')
      case 5:
        return t('organizationTimesheet.table.Fri')
      case 6:
        return t('organizationTimesheet.table.Sat')
      case 0:
        return t('organizationTimesheet.table.Sun')
      default:
        return '--'
    }
  }

  const onGroupChangeHandler = (number: string) => {
    setGroupID(Number(number))
  }

  const getTable = () => {
    let tData: any = []
    let tColumns: any = {}

    if (tableType) {
      tData = tableData as IOrganizationTimesheetEmployee[]
      tColumns = !isEmpty(tData) ? columnsForEmployee(tData) : []
    } else {
      tData = tableData as IOrganizationTimesheetPupil[]
      tColumns = !isEmpty(tData) ? columnsForPupil(tData) : []
    }

    return (
      <>
        {showTable && tData && (
          <Table
            dataSource={tData}
            loading={tableLoading}
            pagination={false}
            columns={tColumns}
            rowClassName="timesheet-table-row"
            scroll={{ x: 'max-content' }}
            size="small"
            bordered={true}
            className={`timesheet-table 
          ${
            tableType === TableType.Employees
              ? 'employee-timesheet-table'
              : 'pupil-timesheet-table'
          }`}
            style={{ margin: '0 24px 24px 24px' }}
            footer={() =>
              tData.length > 0 &&
              tableType === TableType.Employees && (
                <Row>
                  <Pagination
                    showSizeChanger
                    current={tablePage}
                    defaultPageSize={10}
                    onChange={onTablePageChange}
                    pageSize={tableSize}
                    total={Number(totalCount)}
                  />
                </Row>
              )
            }
          />
        )}
      </>
    )
  }

  return (
    <>
      <Card title={t('organization.timesheet')}>
        <div className="timesheet">
          <Form form={form} className="timesheet__form">
            <Button
              onClick={() => tableTypeHandler(TableType.Employees)}
              type={tableType === TableType.Employees ? 'primary' : 'default'}
            >
              {t('indicators.employee_staff')}
            </Button>
            <Button
              onClick={() => tableTypeHandler(TableType.Pupils)}
              type={tableType === TableType.Pupils ? 'primary' : 'default'}
            >
              {t('pupils.title')}
            </Button>
            <DatePicker
              onChange={onChangeTableDateHandler}
              disabled={!allowAccess}
              value={tableDate}
              picker="month"
              locale={locale}
              className="timesheet__date-picker"
            />
            {allowAccess && !showTable && (
              <Button
                onClick={onShowHandler}
                className="timesheet__show-btn"
                type="primary"
              >
                {t('general.display')}
              </Button>
            )}
            {tableType === TableType.Pupils && (
              <div className="timesheet__select">
                <p className="title">{t('educationGroup.educationGroup')}:</p>
                <Form.Item name="group" className="select">
                  <Select onChange={onGroupChangeHandler}>
                    {groups?.map((group) => {
                      return (
                        <Select.Option key={group.id} value={group.id}>
                          {group.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
            )}
            <div className="timesheet__download">
              {!tableLoading &&
              showTable &&
              allowAccess &&
              tableType === TableType.Pupils ? (
                <Popover
                  onVisibleChange={setPopoverVisible}
                  open={popoverVisible}
                  trigger="click"
                  content={
                    <>
                      <p
                        onClick={() => onDownloadPupilHandler(false)}
                        className="timesheet__popover-btn"
                      >
                        {t('organizationTimesheet.downloadAll')}
                      </p>
                      <p
                        className="timesheet__popover-btn"
                        onClick={() => {
                          onDownloadPupilHandler(true)
                        }}
                      >
                        {t('organizationTimesheet.downloadGroup')}
                      </p>
                    </>
                  }
                >
                  <Button onClick={onDownloadHandler} type="primary">
                    {t('general.download')}
                  </Button>
                </Popover>
              ) : (
                !tableLoading &&
                showTable &&
                allowAccess && (
                  <Button
                    onClick={onDownloadHandler}
                    type="primary"
                    style={{ marginRight: 16 }}
                  >
                    {t('general.download')}
                  </Button>
                )
              )}
            </div>
          </Form>
        </div>

        {getTable()}
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _organizationTimesheetEmployee:
      state.organizationTimesheet.organization_timesheet_employee,
    _organizationTimesheetPupil:
      state.organizationTimesheet.organization_timesheet_pupil,
  }
}

export default connect(mapStateToProps, null)(OrganizationTimesheet)
