import statsRepository from '@app/api/repositories/stats-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import { ISchedulerSettings } from '@app/interfaces/scheduler/scheduler-settings'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Checkbox, Spin } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  refresh: boolean
}

const SchedulerControl: React.FC<props> = ({ refresh }) => {
  const [settings, setSettings] = useState<ISchedulerSettings>()
  const [isRunning, setIsRunning] = useState<boolean>(false)
  const [expired, setExpired] = useState<boolean>(false)
  const [waiting, setWaiting] = useState<boolean>(false)
  const [checkDiff, setCheckDIff] = useState<boolean>(false)
  const [t, i18n] = useTranslation()
  const [loaded, setLoaded] = useState<boolean>(false)
  const permissions = [AUTHORITIES.SUSER]

  setDocumentTitle(t('scheduler.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    setLoaded(false)
    const getData = async () => {
      await statsRepository
        .getSettings()
        .then((response) => {
          setSettings(response.data)
          setExpired(response.data.expired)
          setWaiting(response.data.waiting)
          setCheckDIff(response.data.checkDiff)
          setLoaded(true)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            openNotificationWithIcon('error', t('scheduler.error'))
            setLoaded(true)
          }
        })
      await statsRepository.isRunning().then((response) => {
        if (response.data) {
          setIsRunning(true)
          return
        }
        setIsRunning(false)
      })
    }
    getData()
    return function cleanup() {
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  const onExpiredHandler = (value) => {
    setLoaded(false)
    statsRepository
      .updateExpired({ id: settings?.id, expired: value.target.checked })
      .then((response) => {
        setLoaded(true)
        setExpired((prev) => !prev)
      })
      .catch((error) => {
        setLoaded(true)
        openNotificationWithIcon('error', t('scheduler.expiredError'))
      })
  }

  const onWaitingHandler = (value) => {
    setLoaded(false)
    statsRepository
      .updateWaiting({ id: settings?.id, waiting: value.target.checked })
      .then((response) => {
        setLoaded(true)
        setWaiting((prev) => !prev)
      })
      .catch((error) => {
        setLoaded(true)
        openNotificationWithIcon('error', t('scheduler.waitingError'))
      })
  }

  const onCheckDiffHandler = (value) => {
    setLoaded(false)
    statsRepository
      .updateCheckDiff({ id: settings?.id, checkDiff: value.target.checked })
      .then((response) => {
        setLoaded(true)
        setCheckDIff((prev) => !prev)
      })
      .catch((error) => {
        setLoaded(true)
        openNotificationWithIcon('error', t('scheduler.checkDiffError'))
      })
  }

  const onLaunchHandler = () => {
    setLoaded(false)
    statsRepository
      .run()
      .then((response) => {
        if (response.data) {
          setIsRunning(true)
          setLoaded(true)
          openNotificationWithIcon('success', t('scheduler.launchSuccess'))
        } else {
          setLoaded(true)
          openNotificationWithIcon('warning', t('scheduler.launchError'))
        }
      })
      .catch((error) => {
        setLoaded(true)
        openNotificationWithIcon('warning', t('scheduler.launchError'))
      })
  }

  const onStopHandler = () => {
    setLoaded(false)
    statsRepository
      .stop()
      .then((response) => {
        if (response.data) {
          setIsRunning(false)
          setLoaded(true)
          openNotificationWithIcon('success', t('scheduler.stopSuccess'))
        } else {
          setLoaded(true)
          setIsRunning(false)
          openNotificationWithIcon('warning', t('scheduler.stopError'))
        }
      })
      .catch((error) => {
        setLoaded(true)
        openNotificationWithIcon('warning', t('scheduler.stopError'))
      })
  }
  return (
    <Spin spinning={!loaded}>
      {rolesChecker(permissions) ? (
        <div className="settings">
          <div className="settings__options">
            <Checkbox onChange={onExpiredHandler} checked={expired}>
              {t('scheduler.expired')}
            </Checkbox>
            <Checkbox onChange={onWaitingHandler} checked={waiting}>
              {t('scheduler.waiting')}
            </Checkbox>
            <Checkbox onChange={onCheckDiffHandler} checked={checkDiff}>
              {t('scheduler.checkDiff')}
            </Checkbox>
          </div>
          <div className="settings__btn">
            <Button
              type="primary"
              onClick={onLaunchHandler}
              disabled={isRunning}
            >
              {t('scheduler.launch')}
            </Button>
            <Button
              type="primary"
              onClick={onStopHandler}
              disabled={!isRunning}
            >
              {t('scheduler.stop')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="warning">
          <h2>{t('scheduler.permissionsError')}</h2>
        </div>
      )}
    </Spin>
  )
}

export default SchedulerControl
