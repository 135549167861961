import { IRepository } from '@api/repositories/i-repository'
import { ICamera } from '@app/interfaces/cameras'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import bbitApi from '../make-request'
import { numberArgs, IPathParamsArgs } from './i-repository'

class CamerasRepository implements IRepository<ICamera> {
  create(data: ICamera): Promise<AxiosResponse<ICamera>> {
    return bbitApi.post(`/attendance/api/v1/cameras`, data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<ICamera[]>> {
    return bbitApi.get('/attendance/api/v1/cameras/list', {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<ICamera>> {
    return bbitApi.get(`/attendance/api/v1/cameras/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: ICamera): Promise<AxiosResponse<ICamera>> {
    return bbitApi.put(`/attendance/api/v1/cameras/${data.id}`, data)
  }
}

export default new CamerasRepository()
