import { Result } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

function HaveNotAccess() {
  return (
    <>
      <Result
        status="403"
        title="403"
        subTitle="Извините, у вас нет доступа к данной странице"
        extra={<Link to={'/profile'}>Перейти в профиль</Link>}
      />
    </>
  )
}

export default HaveNotAccess
