import React from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { connect } from 'react-redux'
import useQuery from '@app/hooks/useQuery'

interface props {
  request: (string) => void
  load: boolean
}

const GroupPupilsFilterForm: React.FC<props> = ({ request, load }) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { serializeQuery } = useQuery()

  const onFinish = (values: any) => {
    const status =
      values.status === 'ACTIVE' ? 'QUASH,DISMISS,EXPIRED,CANCELED' : 'APPROVED'

    const newValues = {
      'id.equals': values.id,
      'pin.equals': values.pin,
      'status.in': status,
    }
    request(serializeQuery({ query: newValues }))
  }

  const onReset = (values: any) => {
    const status =
      values.status === 'ACTIVE' ? 'QUASH,DISMISS,EXPIRED,CANCELED' : 'APPROVED'
    const newValues = {
      'id.equals': values.id,
      'status.in': status,
    }
    request(serializeQuery({ query: newValues }))
    form.resetFields()
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      onReset={() => form.resetFields(['id', 'pin', 'status'])}
      className="header-form"
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label={'ID'} name={'id'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('general.status')} name={'status'}>
            <Select allowClear={true}>
              <Select.Option value={'APPROVED'}>
                {t('general.active')}
              </Select.Option>
              <Select.Option value={'ACTIVE'}>
                {t('general.inActive')}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div>
        <Button disabled={!load} type="default" htmlType="submit">
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          disabled={!load}
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onReset}
        >
          {t('general.reset')}
        </Button>
      </div>
    </Form>
  )
}

const mapStateToProps = (state) => {
  return {
    districtsById: state.districts.districtsByRegionId,
  }
}

export default connect(mapStateToProps)(GroupPupilsFilterForm)
