import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEmployeePosition,
  IEmployeePositionState,
} from '@app/interfaces/employee/employee-position'
import {
  CLEAR_EMPLOYEE_POSITION,
  CREATE_EMPLOYEE_POSITION,
  DELETE_EMPLOYEE_POSITION,
  FETCH_EMPLOYEE_POSITIONS_FAILURE,
  FETCH_EMPLOYEE_POSITIONS_REQUEST,
  FETCH_EMPLOYEE_POSITIONS_SUCCESS,
  FETCH_EMPLOYEE_POSITION_FAILURE,
  FETCH_EMPLOYEE_POSITION_REQUEST,
  FETCH_EMPLOYEE_POSITION_SUCCESS,
  UPDATE_EMPLOYEE_POSITION,
} from '@app/store/types/employee-position'

const initialState: IEmployeePositionState = {
  employeePosition: {} as IEmployeePosition,
  employeePositions: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const employeePositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_POSITIONS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_POSITIONS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeePositions: action.payload.employeePositions,
        count: action.payload.count,
      }
    case FETCH_EMPLOYEE_POSITIONS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeePositions: [],
      }
    case FETCH_EMPLOYEE_POSITION_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_POSITION_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeePosition: action.payload,
      }
    case FETCH_EMPLOYEE_POSITION_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeePosition: {},
      }
    case CLEAR_EMPLOYEE_POSITION:
      return { ...state, employeePositions: [] }
    case CREATE_EMPLOYEE_POSITION:
      return {
        ...state,
        employeePositions: [...state.employeePositions, action.payload],
      }
    case UPDATE_EMPLOYEE_POSITION:
      const updatedEmployeeEducations = state.employeePositions.map(function (
        employeePosition,
      ) {
        if (action.payload.id === employeePosition.id) {
          return action.payload
        }
        return employeePosition
      })
      return { ...state, employeePositions: updatedEmployeeEducations }
    case DELETE_EMPLOYEE_POSITION:
      const editedEmployeeEducations = state.employeePositions.filter(function (
        employeePosition,
      ) {
        if (action.payload.id === employeePosition.id) {
          return false
        }
        return employeePosition
      })
      return { ...state, employeePositions: editedEmployeeEducations }
    default:
      return state
  }
}
