import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import pupilsRepository from '@app/api/repositories/pupils-repository'
import { IPupilsActions } from '@app/interfaces/pupil/pupils'
import { getFile } from '@app/store/actions/files-actions'
import {
  CLEAR_PUPIL,
  CLEAR_PUPILS,
  CREATE_PUPIL,
  FETCH_PUPILS_FAILURE,
  FETCH_PUPILS_REQUEST,
  FETCH_PUPILS_SUCCESS,
  FETCH_PUPIL_CLEANUP,
  FETCH_PUPIL_FAILURE,
  FETCH_PUPIL_REQUEST,
  FETCH_PUPIL_SUCCESS,
  FETCH_REGISTRY_PUPIL_FAILURE,
  FETCH_REGISTRY_PUPIL_REQUEST,
  FETCH_REGISTRY_PUPIL_SUCCESS,
  GET_REGISTRY_PUPIL,
  UPDATE_PUPIL,
} from '@app/store/types/pupils'
import axios from 'axios'

export const getPupils =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchPupilsRequest())
    await pupilsRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchPupilsSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchPupilsFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchPupilCleanup())
  }

export const getPupil =
  (args: numberArgs, messages: { pupilError: string; fileError: string }) =>
  async (dispatch) => {
    dispatch(fetchPupilRequest())
    await pupilsRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchPupilSuccess(response.data))
        if (response.data.fileId) {
          dispatch(
            getFile(
              { data: response.data.fileId, source: args.source },
              messages.fileError,
            ),
          )
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchPupilFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchPupilCleanup())
  }

export function getRegistryPupils(pupils): IPupilsActions {
  return {
    type: GET_REGISTRY_PUPIL,
    payload: pupils,
  }
}

const fetchPupilsRequest = () => {
  return {
    type: FETCH_PUPILS_REQUEST,
  }
}

const fetchPupilsSuccess = (pupils, count) => {
  return {
    type: FETCH_PUPILS_SUCCESS,
    payload: { pupils, count },
  }
}

const fetchPupilsFailure = (message, code) => {
  return {
    type: FETCH_PUPILS_FAILURE,
    payload: { message, code },
  }
}

const fetchPupilRequest = () => {
  return {
    type: FETCH_PUPIL_REQUEST,
  }
}

const fetchPupilSuccess = (pupil) => {
  return {
    type: FETCH_PUPIL_SUCCESS,
    payload: pupil,
  }
}

const fetchPupilFailure = (message, code) => {
  return {
    type: FETCH_PUPIL_FAILURE,
    payload: { message, code },
  }
}

const fetchPupilCleanup = () => {
  return {
    type: FETCH_PUPIL_CLEANUP,
  }
}

const fetchRegistryPupilRequest = () => {
  return {
    type: FETCH_REGISTRY_PUPIL_REQUEST,
  }
}

const fetchRegistryPupilSuccess = (registryPupil) => {
  return {
    type: FETCH_REGISTRY_PUPIL_SUCCESS,
    payload: registryPupil,
  }
}

const fetchRegistryPupilFailure = (message, code) => {
  return {
    type: FETCH_REGISTRY_PUPIL_FAILURE,
    payload: { message, code },
  }
}

export function clearPupils() {
  return {
    type: CLEAR_PUPILS,
    payload: null,
  }
}

export function clearPupil() {
  return {
    type: CLEAR_PUPIL,
    payload: null,
  }
}

export function updatePupil(pupil): IPupilsActions {
  return {
    type: UPDATE_PUPIL,
    payload: pupil,
  }
}

export function createPupil(pupil): IPupilsActions {
  return {
    type: CREATE_PUPIL,
    payload: pupil,
  }
}
