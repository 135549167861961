import { Card } from 'antd'
import React from 'react'
import './furniture.less'

const Furniture: React.FC = () => {
  return (
    <Card>
      <div className="card">
        <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ46DvtyMfcT7FmF87OANc0CW38X06MEidDzeZ5HYNqztZjxXwqpMVBKAs-6zt1uf5EFDENjMDXYd0S/pubhtml?widget=true&headers=false" />
      </div>
    </Card>
  )
}

export default Furniture
