import { ApiKeys } from '@app/constants/api-keys'
import { IMedicalStaff } from '@app/interfaces/organization-passport/medical-staff'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const medicalStaffsApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getMedicalStaffs: build.query<
      IRtkResponse<IMedicalStaff[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/medical-staff-availabilitys',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IMedicalStaff[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.medicalStaff,
                id,
              })),
              { type: ApiKeys.medicalStaff, id: 'LIST' },
            ]
          : [{ type: ApiKeys.medicalStaff, id: 'LIST' }],
    }),
    getMedicalStaff: build.query<IMedicalStaff, number>({
      query: (id) => ({
        url: `dictionary/api/v1/medical-staff-availabilitys/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.medicalStaff, id }],
    }),
    createMedicalStaff: build.mutation<
      IRtkResponse<IMedicalStaff>,
      IMedicalStaff
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/medical-staff-availabilitys',
          data,
        }
      },
      transformResponse: (response: IMedicalStaff, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.medicalStaff,
          id: args.id,
        },
        { type: ApiKeys.medicalStaff, id: 'LIST' },
      ],
    }),
    updateMedicalStaff: build.mutation<
      IRtkResponse<IMedicalStaff>,
      IMedicalStaff
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/medical-staff-availabilitys/${data.id}`,
        data,
      }),
      transformResponse: (response: IMedicalStaff, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.medicalStaff, id: args.id },
        { type: ApiKeys.medicalStaff, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetMedicalStaffs =
  medicalStaffsApi.endpoints.getMedicalStaffs.useQueryState
const useStateGetMedicalStaff =
  medicalStaffsApi.endpoints.getMedicalStaff.useQueryState

export const {
  useGetMedicalStaffsQuery,
  useLazyGetMedicalStaffsQuery,
  useCreateMedicalStaffMutation,
  useUpdateMedicalStaffMutation,
  util,
  usePrefetch,
} = medicalStaffsApi

export { useStateGetMedicalStaffs, useStateGetMedicalStaff }
