import { ILang } from '@app/interfaces/languages'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { AUTHORITIES } from '@app/constants/authorities'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Button, Card, Steps } from 'antd'
import { Link, useParams } from 'react-router-dom'
import EnterPin from './components/enter-pin/enter-pin'
import ShowInfo from './components/show-info/show-info'
import { useForm } from 'antd/lib/form/Form'
import { Form } from 'antd'
import useLocalization from '@app/hooks/useLocalization'

interface props {
  languages: ILang[]
}

interface params {
  id?: string
}

const OrganizationContractsForm: React.FC<props> = ({ languages }) => {
  const [current, setCurrent] = React.useState(0)
  const [loaded, setLoaded] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation()
  const { findLanguage } = useLocalization()
  const language = findLanguage(languages)
  const params: params = useParams()
  const [form] = useForm()
  setDocumentTitle(t('contracts.add'))

  const permissions = [AUTHORITIES.ADMIN]

  const next = () => {
    form.submit()
    setCurrent((current) => current + 1)
  }

  const prev = () => {
    setCurrent((current) => current - 1)
  }

  const steps = [
    {
      title: t('contracts.enterPin'),
      content: <EnterPin language={language} />,
    },
    {
      title: t('contracts.info'),
      content: <ShowInfo language={language} />,
    },
  ]

  return (
    <div>
      {rolesChecker(permissions) ? (
        <Card title={t('contracts.addContract.title')}>
          <Steps current={current}>
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <Form form={form}>{steps[current].content}</Form>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <>
                <Button
                  type="primary"
                  onClick={() => next()}
                  style={{ marginRight: '10px' }}
                >
                  {t('general.next')}
                </Button>
                <Link
                  className={'ant-btn ant-btn-primary'}
                  to={`/organization/${params.id}/contracts/`}
                >
                  {t('general.close')}
                </Link>
              </>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={form.submit}>
                {t('general.add')}
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                {t('general.back')}
              </Button>
            )}
          </div>
        </Card>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    languages: state.langs.langs,
  }
}

export default connect(mapStateToProps, null)(OrganizationContractsForm)
