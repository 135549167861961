import { FilterOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import { IUserInfo } from '@app/interfaces/user-info'
import { RootState } from '@app/store/store'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import useQuery from '@app/hooks/useQuery'
import { Button, Col, Form, Input, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

interface props {
  request: (string) => void
  showTable: boolean
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
}

const PupilsSearchFilterForm: React.FC<props> = ({
  request,
  setShowTable,
  showTable,
}) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const userInfo: IUserInfo = useSelector(
    (state: RootState) => state.userInfo.userInfo,
  )
  const { serializeQuery } = useQuery()

  const onFinish = (values: any) => {
    !showTable && setShowTable(true)
    const newValues = {
      'metric.equals': values.metric,
      'pin.equals': values.pin,
      'fullName.equals': values.fullName,
    }
    if (
      !rolesChecker([
        AUTHORITIES.ADMIN,
        AUTHORITIES.ROLE_PUPIL_ALL_VIEW,
        AUTHORITIES.ROLE_PUPIL_REGION_VIEW,
      ]) &&
      rolesChecker([AUTHORITIES.ROLE_PUPIL_DISTRICT_VIEW])
    ) {
      newValues['regionId.equals'] = userInfo.organizations[0].regionId
    }
    request(serializeQuery({ query: newValues }))
  }

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label={t('pupils.pin')} name={'pin'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'metric'} label={t('pupils.metric')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'fullName'} label={t('pupils.fullName')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <div>
        <Button type="primary" htmlType="submit">
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onFinish}>
          {t('general.reset')}
        </Button>
      </div>
    </Form>
  )
}

export default PupilsSearchFilterForm
