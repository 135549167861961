import { notification } from 'antd'

export const openNotificationWithIcon = (type, description, message?) => {
  notification[type]({
    message: message,
    description: description ?? '',
    duration: 2,
    style: {},
  })
}
