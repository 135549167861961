import bbitApi from '@app/api/make-request'
import { AUTH_TOKEN } from '@app/constants/authToken'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { exportToXLSX } from '@app/utils/utils'
import { Card, Spin } from 'antd'
import axios from 'axios'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import './reports.less'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { AUTHORITIES } from '@app/constants/authorities'

interface TReport {
  data: Datum[]
  code: number
}

interface Datum {
  id: number
  createdBy: string
  createdDate: Date
  lastModifiedBy: string
  lastModifiedDate: Date
  order: number
  status: boolean
  name: string
  generateUrl: string
  generateRole: string
  downloadUrl: string
  downloadRole: string
}

const OrganizationReports: React.FC = () => {
  const [t] = useTranslation()
  setDocumentTitle(t('reports.title'))

  const { data, isLoading } = useQuery(
    'reports',
    async () => {
      const { data } = await bbitApi.get<TReport>(
        '/emis-reports/api/v1/reports/report-information',
      )
      return data.data
        .filter((r) => r.status === true)
        .sort((a, b) => a.order - b.order)
    },
    {},
  )

  const onDownLoad = useCallback(
    async (report: Datum) => {
      try {
        const token = localStorage.getItem(AUTH_TOKEN)
        const response = await axios.get(report.downloadUrl, {
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: 'Bearer ' + token,
          },
          responseType: 'blob',
        })
        exportToXLSX(response, report.name)
      } catch (error) {
        openNotificationWithIcon('error', t('reports.error'))
      }
    },
    [t],
  )

  const reportList = useMemo(() => {
    if (!data || data?.length === 0) return null
    return data
      .filter((report) =>
        rolesChecker([report.downloadRole, AUTHORITIES.ADMIN]),
      )
      .map((report) => (
        <div className="reports__buttons--item" key={report.id}>
          <p onClick={() => onDownLoad(report)}>{report.name}</p>
        </div>
      ))
  }, [data])

  return (
    <div>
      <Card className="reports" title={t('reports.title')}>
        <Spin spinning={isLoading} />
        <div className="reports__buttons">{reportList}</div>
      </Card>
    </div>
  )
}

export default OrganizationReports
