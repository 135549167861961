import React, { useEffect, useState } from 'react'
import './organization-attendance.less'

import { CalendarOutlined } from '@ant-design/icons'
import OrganizationAttendanceRepository from '@app/api/repositories/organization-attendance-repository'
import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import {
  IEmployeeAbsenceReason,
  IEmployeeAbsenceReasonCreatePayload,
  IEmployeeAbsenceReasonUpdatePayload,
  IOrganizationAttendanceByEmployee,
  IOrganizationAttendanceByPupil,
  IOrganizationAttendanceEmployee,
  IOrganizationAttendancePupil,
} from '@app/interfaces/organization-attendance'
import { IReason } from '@app/interfaces/reasons'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  clearOrganizationAttendanceEmployee,
  clearOrganizationAttendancePupil,
  getOrganizationAttendance,
  getOrganizationAttendanceByPupil,
} from '@app/store/actions/organization-attendance-actions'
import { capitalizeName } from '@app/utils/capitalize/capitalize'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Table,
  Tag,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import Text from 'antd/lib/typography/Text'
import axios, { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as XLSX from 'xlsx-js-style'

interface Props {
  _organizationAttendanceEmployee: IOrganizationAttendanceEmployee
  _organizationAttendancePupil: IOrganizationAttendancePupil
}

enum TableType {
  Pupils,
  Employees,
}

enum AttendedType {
  Present = 'PRESENT',
  Absent = 'ABSENT',
}

const OrganizationAttendance: React.FC<Props> = ({
  _organizationAttendanceEmployee,
  _organizationAttendancePupil,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const params: IPathParams = useParams()
  const { languageId } = useLocalization()
  const { localizeItem } = useLocalization()
  setDocumentTitle(t('organizationAttendance.title'))
  const [form] = Form.useForm()
  const [tableType, setTableType] = useState(TableType.Employees)
  const [tableDate, setTableDate] = useState<moment.Moment>(moment())
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const cellTimeFormat = 'HH:mm'
  const { data: reasons } = useCache<IReason>(ApiKeys.reasons)

  useEffect(() => {
    const source = axios.CancelToken.source()
    const attendanceRequest = {
      workDate: tableDate.format('YYYY-MM-DD'),
      id: Number(params.id),
    }
    if (tableType === TableType.Employees) {
      setTableLoading(true)
      OrganizationAttendanceRepository.getByEmployee(attendanceRequest, source)
        .then((response) => {
          const filtered = {
            ...response.data,
            data: response.data.data.filter(
              (employee) => !employee.fio.includes('null'),
            ),
          }
          dispatch(getOrganizationAttendance(filtered))
          setTableLoading(false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setTableLoading(false)
          }
        })
    } else {
      setTableLoading(true)
      OrganizationAttendanceRepository.getByPupil(attendanceRequest, source)
        .then((response) => {
          const data = response.data.data.map((item) => {
            return {
              ...item,
              totalPresent: item.pupils.filter(
                (pupil) => pupil.attended === 'PRESENT',
              ).length,
              totalAbsent: item.pupils.filter(
                (pupil) => pupil.attended !== 'PRESENT',
              ).length,
            }
          })

          dispatch(
            getOrganizationAttendanceByPupil({ ...response.data, data: data }),
          )
          setTableLoading(false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setTableLoading(false)
          }
        })
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [tableType, tableDate])

  useEffect(() => {
    form.resetFields()
  }, [_organizationAttendanceEmployee])

  const reasonsData = reasons?.map((reason) => {
    return {
      key: reason.id,
      id: reason.id,
      name: reason.data.find((lang) => lang.languageId === languageId)?.name,
      status: [reason.status],
      reason: reason,
      shortName: reason.shortName,
    }
  })

  const onChangeTableDateHandler = (date: moment.Moment | null) => {
    dispatch(clearOrganizationAttendanceEmployee())
    dispatch(clearOrganizationAttendancePupil())
    setTableDate(date ?? moment())
  }

  const tableTypeHandler = (type) => {
    dispatch(clearOrganizationAttendanceEmployee())
    dispatch(clearOrganizationAttendancePupil())
    setTableType(type)
  }

  const onDownloadHandler = () => {
    if (tableType === TableType.Employees) {
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(
        changeEmployeeFields(_organizationAttendanceEmployee.data),
      )
      XLSX.utils.book_append_sheet(wb, ws, 'table')
      XLSX.writeFile(
        wb,
        `Посещение_кадровый_состав${tableDate.format('yyyy-MM')}.xlsx`,
      )
      return
    }
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(
      changePupilsFields(_organizationAttendancePupil.data),
    )
    XLSX.utils.book_append_sheet(wb, ws, 'table')
    XLSX.writeFile(
      wb,
      `Посещение_воспитанники${tableDate.format('yyyy-MM')}.xlsx`,
    )
  }

  const changeEmployeeFields = (
    object: IOrganizationAttendanceByEmployee[],
  ) => {
    return object.map(
      ({
        fio,
        position,
        attended,
        reason,
        temperature,
        checkIn,
        checkOut,
        comment,
      }) => ({
        ФИО: fio,
        Должность: position?.data.find((lang) => lang.languageId === languageId)
          ?.name,
        Посещение:
          attended === AttendedType.Present
            ? attended
            : reason !== null
            ? localizeItem(reason as any)?.data.name
            : '',
        Температура: temperature,
        Время_Прихода: checkIn,
        Время_Ухода: checkOut,
        Комментарии: comment,
      }),
    )
  }

  const changePupilsFields = (object: IOrganizationAttendanceByPupil[]) => {
    return object.map(
      ({
        educationGroupName,
        employeeFIO,
        educationGroupPupilCount,
        educationGroupPupilMaleCount,
        educationGroupPupilFemaleCount,
      }) => ({
        Группа: educationGroupName,
        Воспитатель: employeeFIO,
        Общее_количество_детей: educationGroupPupilCount,
        Мальчики: educationGroupPupilMaleCount,
        Девочки: educationGroupPupilFemaleCount,
      }),
    )
  }
  const onSaveBtnClick = (values: any) => {
    const createResult: any[] = []
    const updateResult: any[] = []
    for (const [key, value] of Object.entries(values)) {
      if (value) {
        const employee = _organizationAttendanceEmployee.data.find(
          (employee) => employee.employeeId === Number(key.split(' ')[0]),
        )
        if (employee) {
          employee[`${key.split(' ')[1]}`] = value
          if (employee.id) {
            const updateEmployee = updateResult.find(
              (updateEmployee) =>
                updateEmployee.employeeId === employee?.employeeId,
            )
            updateEmployee
              ? (updateEmployee[`${key.split(' ')[1]}`] = value)
              : updateResult.push({ ...employee })
          } else {
            const createEmployee = createResult.find(
              (createEmployee) =>
                createEmployee.employeeId === employee?.employeeId,
            )
            createEmployee
              ? (createEmployee[`${key.split(' ')[1]}`] = value)
              : createResult.push({ ...employee })
          }
        }
      }
    }

    onSave({
      createPayload: createResult
        ? createResult.map((item) => {
            return {
              workDate: _organizationAttendanceEmployee.workDate,
              employeeId: item.employeeId,
              organizationId: Number(params.id!),
              reasonId: item.reasonId,
              comment: item.comment,
            }
          })
        : [],
      updatePayload: updateResult
        ? updateResult.map((item) => {
            return {
              attendanceId: item.id,
              reasonId: item.reasonId,
              comment: item.comment,
            }
          })
        : [],
    })
  }

  const onSave = async (args: {
    createPayload: IEmployeeAbsenceReasonCreatePayload[]
    updatePayload: IEmployeeAbsenceReasonUpdatePayload[]
  }) => {
    setTableLoading(true)
    !isEmpty(args.createPayload) &&
      (await OrganizationAttendanceRepository.createEmployeeAbsenceReason(
        args.createPayload,
      )
        .then((response: AxiosResponse<IEmployeeAbsenceReason>) => {
          if (response.status === 200) {
            openNotificationWithIcon('success', t('success.updated'))
          }
          setTableLoading(false)
        })
        .catch(() => {
          setTableLoading(false)
        }))

    !isEmpty(args.updatePayload) &&
      (await OrganizationAttendanceRepository.updateEmployeeAbsenceReason(
        args.updatePayload,
      )
        .then((response: AxiosResponse<IEmployeeAbsenceReason>) => {
          if (response.status === 200) {
            openNotificationWithIcon('success', t('success.updated'))
          }
          setTableLoading(false)
        })
        .catch(() => {
          setTableLoading(false)
        }))
  }

  const columnsForEmployee = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: t('employee.fullName'),
      dataIndex: 'fio',
      key: 'fio',
      defaultSortOrder: 'ascend',
      width: 200,
      sorter: {
        compare: (a, b) => a.fio?.localeCompare(b.fio),
        multiple: 1,
      },
      render: (fio) => (
        <p className="ellipsis-cell" style={{ width: 200 }}>
          {fio ? capitalizeName(fio) : ''}
        </p>
      ),
    },
    {
      title: t('employee.position'),
      dataIndex: 'position',
      key: 'position',
      width: 160,
      sorter: {
        compare: (a, b) =>
          a.position?.data
            .find((lang) => lang.languageId === languageId)
            ?.name.localeCompare(
              b.position.data.find((lang) => lang.languageId === languageId)
                ?.name,
            ),
        multiple: 2,
      },
      render: (position) => (
        <p className="ellipsis-cell" style={{ width: 160 }}>
          {position.data.find((lang) => lang.languageId === languageId)?.name}
        </p>
      ),
    },
    {
      title: t('organizationAttendance.table.attendance'),
      dataIndex: 'attended',
      key: 'attended',
      width: 160,
      sorter: (a, b) => a.attended?.localeCompare(b.attended),
      render: (text, record) => (
        <Form.Item
          name={`${record.key} reasonId`}
          initialValue={record.reason?.id}
          style={{ margin: 0, width: 160 }}
        >
          {text === AttendedType.Present ? (
            <p>{t('organizationAttendance.present')}</p>
          ) : (
            <Select
              disabled={
                !rolesChecker([
                  AUTHORITIES.ADMIN,
                  AUTHORITIES.ROLE_ATTENDANCE_REASON_ALL_EDIT,
                  AUTHORITIES.ROLE_ATTENDANCE_REASON_CURRENT_EDIT,
                  AUTHORITIES.ROLE_ATTENDANCE_REASON_REGION_EDIT,
                  AUTHORITIES.ROLE_ATTENDANCE_REASON_DISTRICT_EDIT,
                ])
              }
            >
              {reasonsData?.map((reason) => {
                return (
                  <Select.Option key={reason?.key} value={reason?.id}>
                    <Tag color={'green'} key={reason?.key}>
                      {reason?.shortName}
                    </Tag>{' '}
                    - {reason?.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      ),
    },
    {
      title: t('organizationAttendance.table.checkIn'),
      dataIndex: 'checkIn',
      key: 'checkIn',
      render: (text, record) =>
        record.checkIn ? (
          <Input
            type="text"
            disabled
            value={moment(record.checkIn).format(cellTimeFormat)}
          />
        ) : (
          <Input disabled value="Нет данных" />
        ),
    },
    {
      title: t('organizationAttendance.table.checkOut'),
      dataIndex: 'checkOut',
      key: 'checkOut',
      render: (text, record) =>
        record.checkOut ? (
          <Input
            type="text"
            disabled
            value={moment(record.checkOut).format(cellTimeFormat)}
          />
        ) : (
          <Input disabled value="Нет данных" />
        ),
    },
    {
      title: t('organizationAttendance.table.comment'),
      dataIndex: 'comment',
      key: 'comment',
      editable: true,
      render: (text, record) => {
        return (
          <Form.Item
            name={`${record.key} comment`}
            initialValue={record.comment}
            style={{ margin: 0 }}
          >
            <Input
              disabled={record.attended === AttendedType.Present}
              value={text}
            />
          </Form.Item>
        )
      },
    },
  ]

  const columnsForPupil = [
    {
      title: t('educationGroup.educationGroup'),
      dataIndex: 'educationGroupName',
      key: 'educationGroupName',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) =>
          a.educationGroupName?.localeCompare(b.educationGroupName),
        multiple: 1,
      },
    },
    {
      title: t('organizationAttendance.table.tutor'),
      dataIndex: 'employeeFIO',
      key: 'employeeFIO',
      sorter: {
        compare: (a, b) => a.employeeFIO?.localeCompare(b.employeeFIO),
        multiple: 2,
      },
    },
    {
      title: t('organizationAttendance.table.totalCount'),
      dataIndex: 'educationGroupPupilCount',
      key: 'educationGroupPupilCount',
      sorter: {
        compare: (a, b) =>
          a.educationGroupPupilCount - b.educationGroupPupilCount,
        multiple: 3,
      },
    },
    {
      title: t('organizationAttendance.present'),
      dataIndex: 'totalPresent',
      key: 'totalPresent',
      sorter: {
        compare: (a, b) => a.totalPresent - b.totalPresent,
        multiple: 4,
      },
    },
    {
      title: t('organizationAttendance.absent'),
      dataIndex: 'totalAbsent',
      key: 'totalAbsent',
      sorter: {
        compare: (a, b) => a.totalAbsent - b.totalAbsent,
        multiple: 5,
      },
    },
  ]

  const expandableConfig = () => {
    if (tableType === TableType.Pupils) return { expandedRowRender }
    return { expandRowByClick: false }
  }

  const expandedRowRender = (row) => {
    const extendedData = row.pupils.map((i) => {
      return { ...i, key: i.pupilId }
    })
    const extendedColumns = [
      {
        title: t('pupils.fullName'),
        dataIndex: 'fio',
        key: 'fio',
        sorter: (a, b) => a.fio?.localeCompare(b.fio),
        render: (fio) => (
          <p className="ellipsis-cell">{fio ? capitalizeName(fio) : ''}</p>
        ),
      },
      {
        title: t('organizationAttendance.table.attendance'),
        dataIndex: 'attended',
        key: 'attended',
        render: (text, record) => (
          <>
            {text === AttendedType.Present ? (
              <p>{t('organizationAttendance.present')}</p>
            ) : (
              <p>{t('organizationAttendance.absent')}</p>
            )}
          </>
        ),
      },
      // {
      //   title: t('organizationAttendance.table.temperature'),
      //   dataIndex: 'temperature',
      //   key: 'temperature',
      //   render: (text, record) => {
      //     return <Input style={{ width: '120px' }} disabled value={text} />
      //   },
      // },
      {
        title: t('organizationAttendance.table.checkIn'),
        dataIndex: 'checkIn',
        key: 'checkIn',
        render: (text, record) =>
          record.checkIn ? (
            <Input
              style={{ width: '120px' }}
              type="text"
              disabled
              value={moment(record.checkIn).format(cellTimeFormat)}
            />
          ) : (
            <Input style={{ width: '120px' }} disabled value="Нет данных" />
          ),
      },
      {
        title: t('organizationAttendance.table.checkOut'),
        dataIndex: 'checkOut',
        key: 'checkOut',
        render: (text, record) =>
          record.checkOut ? (
            <Input
              type="text"
              disabled
              value={moment(record.checkOut).format(cellTimeFormat)}
            />
          ) : (
            <Input disabled value="Нет данных" />
          ),
      },
    ]
    return (
      <Table
        className={'nested-table-attendance'}
        dataSource={extendedData}
        columns={extendedColumns}
        pagination={false}
      />
    )
  }

  const getTable = () => {
    let tData: any = []
    let tColumns: any = {}
    if (tableType === TableType.Employees) {
      tData = !isEmpty(_organizationAttendanceEmployee)
        ? _organizationAttendanceEmployee.data.map((employee, index) => {
            return { ...employee, key: employee.employeeId }
          })
        : []
      tColumns = !isEmpty(_organizationAttendanceEmployee)
        ? columnsForEmployee
        : []
    } else {
      tData = !isEmpty(_organizationAttendancePupil)
        ? _organizationAttendancePupil.data.map((pupil, index) => {
            return { ...pupil, key: pupil.educationGroupId }
          })
        : []
      tColumns = !isEmpty(_organizationAttendancePupil) ? columnsForPupil : []
    }

    return (
      <Table
        dataSource={tData}
        loading={tableLoading}
        pagination={false}
        columns={tColumns}
        expandable={expandableConfig()}
        className={`
        ${
          tableType === TableType.Employees
            ? 'employee-attendance-table'
            : 'pupil-attendance-table'
        }`}
        style={{ margin: '16px 24px' }}
      />
    )
  }

  return (
    <Form form={form} onFinish={onSaveBtnClick}>
      <Card title={t('organization.attendance')}>
        <Row justify="space-around" align="middle" style={{ margin: '0 24px' }}>
          <Col flex={90}>
            <Button
              onClick={() => tableTypeHandler(TableType.Employees)}
              style={{ margin: '0px 5px 5px 0' }}
              type={tableType === TableType.Employees ? 'primary' : 'default'}
            >
              {t('indicators.employee_staff')}
            </Button>
            <Button
              onClick={() => tableTypeHandler(TableType.Pupils)}
              style={{ margin: '0px 5px 5px 0' }}
              type={tableType === TableType.Pupils ? 'primary' : 'default'}
            >
              {t('pupils.title')}
            </Button>
          </Col>
          <Col flex={10}>
            <DatePicker
              onChange={onChangeTableDateHandler}
              value={tableDate}
              locale={locale}
              disabledDate={(d) => d.isAfter(moment())}
            />
            {tableType ? (
              <Button className={'attendance__save-btn'} htmlType="submit">
                <CalendarOutlined />
                {t('general.save')}
              </Button>
            ) : (
              ''
            )}
          </Col>
          <Col>
            {!tableLoading && (
              <Button onClick={onDownloadHandler}>
                {t('general.download')}
              </Button>
            )}
          </Col>
        </Row>
        {getTable()}
      </Card>
    </Form>
  )
}

const mapStateToProps = (state) => {
  return {
    _organizationAttendanceEmployee:
      state.organizationAttendance.organizationAttendanceEmployee,
    _organizationAttendancePupil:
      state.organizationAttendance.organizationAttendancePupil,
  }
}

export default connect(mapStateToProps, null)(OrganizationAttendance)
