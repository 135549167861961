import { AxiosResponse } from 'axios'

import {
  IOrganizationTimesheetEmployee,
  IOrganizationTimesheetPayload,
  IOrganizationTimesheetPupil,
} from '@app/interfaces/organization-timesheet'
import bbitApi from '../make-request'

class OrganizationTimesheetRepository {
  getEmployeeTimesheet(
    data: IOrganizationTimesheetPayload,
    source?,
  ): Promise<AxiosResponse<IOrganizationTimesheetEmployee[]>> {
    return bbitApi.get(
      `/attendance/api/v1/timesheet/employee/${data.id}?year=${data.year}&month=${data.month}&page=${data.page}&size=${data.size}`,
      { cancelToken: source?.token },
    )
  }

  getPupilTimesheet(
    data: IOrganizationTimesheetPayload,
    source?,
  ): Promise<AxiosResponse<IOrganizationTimesheetPupil[]>> {
    return bbitApi.get(
      `/attendance/api/v1/timesheet/pupil/${data.id}?month=${data.month}&year=${
        data.year
      }&page=${data.page}&size=${data.size}${
        data.educationGroupId
          ? `&educationGroupId=${data.educationGroupId}`
          : ''
      } `,
      { cancelToken: source?.token },
    )
  }
}

export default new OrganizationTimesheetRepository()
