import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { cloneDeep, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MenuBodyDay from './components/menu-body-day'
import MenuBodyItem from './components/menu-body-item'
import MenuBodyTotal from './components/menu-body-total'
import MenuHeaderFilters from './components/menu-header-filters'
import './menu-calendar-by-pupil.less'

const MenuCalendarByPupil: React.FC = () => {
  const { selectors } = useCreateSelector()
  const { localizeData } = useLocalization()
  const [t] = useTranslation()
  const menuByPupil = useSelector(selectors.menus.menuByPupil)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const onDayClickHandler = (date: string) => {
    const index = menuByPupil.findIndex((menu) => menu.workDate === date)
    setSelectedIndex(index)
  }

  return (
    <div className="menu-calendar-by-pupil">
      <div className="menu-calendar-by-pupil__header">
        <MenuHeaderFilters />
      </div>
      {!isEmpty(menuByPupil) && (
        <div className="menu-calendar-by-pupil__body">
          <div className="menu-calendar-by-pupil__body--left">
            {menuByPupil.map((day, index) => (
              <MenuBodyDay
                key={index}
                day={day.workDate}
                selected={index === selectedIndex}
                clickHandler={onDayClickHandler}
              />
            ))}
          </div>
          <div className="menu-calendar-by-pupil__body--right">
            <div className="header">
              <p className="header__count">
                {`${t('organizationMenu.calendar.pupilsCount')}: ${
                  menuByPupil[selectedIndex]?.pupilsCount
                }`}
              </p>
              <p className="header__menu-name">
                {`${t('organizationMenu.menus.name')}: ${
                  localizeData(menuByPupil[selectedIndex]?.dailyMenu.data)?.name
                }`}
              </p>
            </div>
            {cloneDeep(menuByPupil[selectedIndex])
              ?.dailyMenu?.content.sort((a, b) => a.mealType.id - b.mealType.id)
              .map((content, index) => {
                return (
                  <MenuBodyItem
                    menuContent={content}
                    workDate={menuByPupil[selectedIndex]?.workDate}
                    key={index}
                    pupilCount={menuByPupil[selectedIndex].pupilsCount}
                  />
                )
              })}
            <MenuBodyTotal menuByPupil={menuByPupil[selectedIndex]} />
          </div>
        </div>
      )}
    </div>
  )
}

export default MenuCalendarByPupil
