import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationPositionRepository from '@app/api/repositories/organization-position-repository'
import { IOrganizationPositionActions } from '@app/interfaces/employee/organization-position'
import {
  CLOSE_ORGANIZATION_POSITION,
  CREATE_ORGANIZATION_POSITION,
  FETCH_ORGANIZATION_POSITIONS_CLEANUP,
  FETCH_ORGANIZATION_POSITIONS_FAILURE,
  FETCH_ORGANIZATION_POSITIONS_REQUEST,
  FETCH_ORGANIZATION_POSITIONS_SUCCESS,
  FETCH_ORGANIZATION_POSITION_FAILURE,
  FETCH_ORGANIZATION_POSITION_REQUEST,
  FETCH_ORGANIZATION_POSITION_SUCCESS,
} from '@app/store/types/organization-position'
import axios from 'axios'

export const getOrganizationPosition =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationPositionRequest())
    await organizationPositionRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationPositionSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationPositionFailure(error?.message, error?.code),
          )
        }
      })
    dispatch(fetchOrganizationPositionsCleanup())
  }

export const getOrganizationPositions =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationPositionsRequest())
    await organizationPositionRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchOrganizationPositionsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationPositionsFailure(error?.message, error?.code),
          )
        }
      })
    dispatch(fetchOrganizationPositionsCleanup())
  }

const fetchOrganizationPositionsRequest = (): IOrganizationPositionActions => {
  return {
    type: FETCH_ORGANIZATION_POSITIONS_REQUEST,
  }
}

const fetchOrganizationPositionsSuccess = (
  organizationPositions,
  count,
): IOrganizationPositionActions => {
  return {
    type: FETCH_ORGANIZATION_POSITIONS_SUCCESS,
    payload: { organizationPositions, count },
  }
}

const fetchOrganizationPositionsFailure = (
  message,
  code,
): IOrganizationPositionActions => {
  return {
    type: FETCH_ORGANIZATION_POSITIONS_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationPositionsCleanup = (): IOrganizationPositionActions => {
  return {
    type: FETCH_ORGANIZATION_POSITIONS_CLEANUP,
  }
}

const fetchOrganizationPositionRequest = (): IOrganizationPositionActions => {
  return {
    type: FETCH_ORGANIZATION_POSITION_REQUEST,
  }
}

const fetchOrganizationPositionSuccess = (
  organizationPosition,
): IOrganizationPositionActions => {
  return {
    type: FETCH_ORGANIZATION_POSITION_SUCCESS,
    payload: organizationPosition,
  }
}

const fetchOrganizationPositionFailure = (
  message,
  code,
): IOrganizationPositionActions => {
  return {
    type: FETCH_ORGANIZATION_POSITION_FAILURE,
    payload: { message, code },
  }
}

export function createOrganizationPosition(
  organizationPosition,
): IOrganizationPositionActions {
  return {
    type: CREATE_ORGANIZATION_POSITION,
    payload: organizationPosition,
  }
}

export function closeOrganizationPosition(
  organizationPosition,
): IOrganizationPositionActions {
  return {
    type: CLOSE_ORGANIZATION_POSITION,
    payload: organizationPosition,
  }
}
