// REGIONS
export const GET_REGION = 'GET_REGION'
export const GET_REGIONS = 'GET_REGIONS'
export const CREATE_REGION = 'CREATE_REGION'
export const UPDATE_REGION = 'UPDATE_REGION'
export const DELETE_REGION = 'DELETE_REGION'
export const CLEAR_REGION = 'CLEAR_REGION'

export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST'
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS'
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS_FAILURE'

export const FETCH_REGION_REQUEST = 'FETCH_REGION_REQUEST'
export const FETCH_REGION_SUCCESS = 'FETCH_REGION_SUCCESS'
export const FETCH_REGION_FAILURE = 'FETCH_REGION_FAILURE'
