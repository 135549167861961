import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import {
  IPupilApplication,
  IPupilApplicationQueue,
} from '@app/interfaces/pupil/pupil-application'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class PupilApplicationRepository implements IRepository<IPupilApplication> {
  create(data: IPupilApplication): Promise<AxiosResponse<IPupilApplication>> {
    return bbitApi.post('/pupil/api/v1/pupil-applications', data)
  }

  getAllQueue(
    args: IPathParamsArgs,
    data: number,
  ): Promise<AxiosResponse<IPupilApplicationQueue[]>> {
    return bbitApi.get(`/pupil/api/v1/pupil-applications/protected/${data}`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IPupilApplication[]>> {
    return bbitApi.get(`/pupil/api/v1/pupil-applications`, {
      params: resolveParams(args),
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IPupilApplication>> {
    return bbitApi.get(`/pupil/api/v1/pupil-applications/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IPupilApplication): Promise<AxiosResponse<IPupilApplication>> {
    return bbitApi.put(`/pupil/api/v1/pupil-applications/${data.id}`)
  }

  partialUpdate(data: any): Promise<AxiosResponse<IPupilApplication>> {
    return bbitApi.patch(
      `${process.env.REACT_APP_HOST}/pupil/api/v1/pupil-applications/${data.id}`,
      data,
    )
  }

  cancel(data: number) {
    return bbitApi.patch(`/pupil/api/v1/pupil-applications/cancel/${data}`)
  }
}

export default new PupilApplicationRepository()
