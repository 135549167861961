import useLocalization from '@app/hooks/useLocalization'
import { ISolidWasteRemovings } from '@app/interfaces/organization-passport/solid-waste-removings'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  solidWasteRemovings: ISolidWasteRemovings[]
}

const SolidWasteRemovingsItem: React.FC<props> = ({ solidWasteRemovings }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('solidWasteRemoving.title')}
        name={'solidWasteRemoving'}
      >
        <Select>
          {sortByName(localize(solidWasteRemovings)).map(
            (solidWasteRemoving, index) => {
              if (solidWasteRemoving.status) {
                return (
                  <Select.Option
                    key={index}
                    value={solidWasteRemoving.id!.toString()}
                  >
                    {solidWasteRemoving?.data?.name}
                  </Select.Option>
                )
              }
              return null
            },
          )}
        </Select>
      </Form.Item>
    </>
  )
}

export default SolidWasteRemovingsItem
