import { AUTHORITIES } from '@app/constants/authorities'
import { INSTITUTION } from '@app/constants/organizationType'
import { IPathParams } from '@app/constants/path-params'
import { IOrganization } from '@app/interfaces/organization/organization'
import { IOrganizationType } from '@app/interfaces/organization/organization-type'
import InstitutionTypesItem from '@app/pages/organizations/components/organization-form/components/institution-types-item/institution-types-item'
import OrganizationLocalityItem from '@app/pages/organizations/components/organization-form/components/organization-localities-item/organization-localities-item'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Button, Form, Input, InputNumber, Spin } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import InstitutionSubtypeItem from '../institution-subtype-item/institution-subtype-item'
import OrganizationBuildingTypeItem from '../organization-building-type-item/organization-building-type-item'
import OrganizationScheduleItem from '../organization-schedule-item/organization-schedule-item'
import OrganizationSectorItem from '../organization-sector-item/organization-sector-item'
import OrganizationStatusesItem from '../organization-statuses-item/organization-statuses-item'
import RegionDistrictItem from '../region-district-item/region-district-item'

interface props {
  organization?: IOrganization
  params: IPathParams
  onFinish: any
  form: any
  confirmLoading: boolean
  type?: IOrganizationType
}

const MainOrganizationForm: React.FC<props> = ({
  organization,
  params,
  form,
  onFinish,
  confirmLoading,
  type,
}) => {
  const [t] = useTranslation()

  const defaultShow = !rolesChecker([
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
    AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
    AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
    AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
  ])

  const nameShow =
    !rolesChecker([AUTHORITIES.ROLE_ORGANIZATION_NAME_CHANGE]) && defaultShow

  const cadastralNumberShow =
    !rolesChecker([AUTHORITIES.ROLE_ORGANIZATION_CADASTRAL_NUMBER_CHANGE]) &&
    defaultShow

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Form
          className={'user-form'}
          form={form}
          style={{ padding: '24px' }}
          onFinish={onFinish}
          initialValues={{
            id: organization?.id,
            name: organization?.name,
            code: organization?.code,
            soato: organization?.soato,
            type: type,
            queuePercentage: organization?.passport?.queuePercentage,
            plannedCapacity: organization?.passport?.plannedCapacity,
            cadastralNumber: organization?.cadastralNumber,
          }}
          layout={'vertical'}>
          <Form.Item hidden={true} name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('general.name')}
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string' ? value.trim() : undefined,
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={'name'}>
            <Input disabled={nameShow} />
          </Form.Item>
          <RegionDistrictItem
            edit={!!params.edit}
            organization={organization}
          />
          <OrganizationLocalityItem
            organization={organization}
            disabled={defaultShow}
          />
          <Form.Item
            name={'soato'}
            label={t('institutionPassport.soato')}
            rules={[
              {
                required: true,
                type: 'number',
                message: t('errors.requiredMessage'),
              },
            ]}>
            <InputNumber style={{ width: '100%' }} disabled={defaultShow} />
          </Form.Item>
          <Form.Item
            label={t('institutionPassport.code')}
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string' ? value.trim() : undefined,
                required: true,
                message: t('errors.requiredMessage'),
              },
              {
                min: 8,
                message: t('errors.min8Message'),
              },
            ]}
            name={'code'}>
            <Input disabled={defaultShow} />
          </Form.Item>
          <OrganizationStatusesItem
            disabled={defaultShow}
            organization={organization}
          />
          {(params.addInstitution ||
            (params.edit && type?.id === INSTITUTION)) && (
            <>
              <OrganizationSectorItem organization={organization} form={form} />
              <InstitutionTypesItem
                organization={organization}
                disabled={params.edit !== undefined}
                form={form}
              />
              <InstitutionSubtypeItem
                organization={organization}
                disabled={params.edit !== undefined}
                form={form}
              />
              <OrganizationBuildingTypeItem organization={organization} />
              <OrganizationScheduleItem organization={organization} />
              <Form.Item
                label={`${t('institutionPassport.queuePercentage')}`}
                name={'queuePercentage'}
                rules={[
                  {
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                  {
                    type: 'number',
                    min: 0,
                    max: 100,
                    message: t('errors.min1Max100'),
                  },
                ]}>
                <InputNumber
                  disabled={defaultShow}
                  style={{ width: '100%' }}
                  parser={(value) => value?.replace('.', '') as any}
                />
              </Form.Item>
              <Form.Item
                label={`${t('institutionPassport.plannedCapacity')}`}
                name={'plannedCapacity'}
                rules={[
                  {
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                  {
                    type: 'number',
                    min: 0,
                    max: 1000,
                    message: t('errors.minMaxMessage', { min: 0, max: 1000 }),
                  },
                ]}>
                <InputNumber
                  disabled={defaultShow}
                  style={{ width: '100%' }}
                  parser={(value) => value?.replace('.', '') as any}
                />
              </Form.Item>
              <Form.Item
                label={`${t('institutionPassport.cadastralNumber')}`}
                name={'cadastralNumber'}>
                <Input
                  style={{ width: '100%' }}
                  disabled={cadastralNumberShow}
                />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button
              disabled={confirmLoading}
              type={'primary'}
              htmlType={'submit'}>
              {params.id ? t('general.save') : t('general.add')}
            </Button>
            <Link
              style={{ marginLeft: '10px' }}
              to={
                !params.edit
                  ? '/organizations'
                  : `/organization/${params.id}/page`
              }
              className={'ant-btn ant-btn-default'}
              type={'primary'}>
              {t('general.cancel')}
            </Link>
          </Form.Item>
        </Form>
      </Spin>
    </>
  )
}

export default MainOrganizationForm
