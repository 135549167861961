import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IEmployeePosition } from '@app/interfaces/employee/employee-position'
import { IRole, IUserGroupRoles } from '@app/interfaces/roles'
import EmployeePositionForm from '@app/pages/reference-book-page-details/employee-position/components/employee-position-form/employee-position-form'
import { getEmployeePositions } from '@app/store/actions/employee-position-actions'
import { getGroupRoles, getRoles } from '@app/store/actions/roles-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _employeePositions: IEmployeePosition[]
  _groupRoles: IUserGroupRoles[]
  _roles: IRole[]
  _state: string
}

const ReferenceBookEmployeePosition: React.FC<props> = ({
  _employeePositions,
  _groupRoles,
  _roles,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('employeePosition.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_roles))
      dispatch(getRoles({ source }, t('userRoles.rolesError')))
    if (isEmpty(_groupRoles))
      dispatch(getGroupRoles({ source }, t('userRoles.error')))
    if (isEmpty(_employeePositions))
      dispatch(getEmployeePositions({ source }, t('employeePosition.error')))
    return function cleanup() {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('employeePosition.employeePosition'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.sort - b.sort,
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
      sorter: (a, b) => Number(a.status[0]) - Number(b.status[0]),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <EmployeePositionForm
            groupRoles={_groupRoles}
            edit={true}
            employeePosition={record.employeePosition}
          />
        ) : null,
    },
  ]

  const data =
    _employeePositions && _employeePositions.length
      ? _employeePositions.map((employeePosition) => {
          return {
            key: employeePosition.id,
            id: employeePosition.id,
            name: localizeData(employeePosition?.data)?.name,
            status: [employeePosition.status],
            employeePosition: employeePosition,
            sort: employeePosition.sorting,
          }
        })
      : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <EmployeePositionForm groupRoles={_groupRoles} />
          ) : null
        }
        title={t('employeePosition.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _employeePositions: state.employeePosition.employeePositions,
    _groupRoles: state.roles.rolesGroups,
    _roles: state.roles.roles,
    _state: state.employeePosition.state,
  }
}

export default connect(mapStateToProps)(ReferenceBookEmployeePosition)
