import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import DistrictsForm from '@app/pages/reference-book-page-details/districts/components/districts-form'
import { getDistricts } from '@app/store/actions/districts-actions'
import { getRegions } from '@app/store/actions/regions-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import './districts.less'

interface props {
  _district: IDistrict[]
  _regions: IRegion[]
  _state: string
}

const ReferenceBookDistricts: React.FC<props> = ({
  _district,
  _regions,
  _state,
}) => {
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const [t] = useTranslation()
  setDocumentTitle(t('district.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_district))
      dispatch(getDistricts({ source }, t('district.error')))
    if (isEmpty(_regions)) dispatch(getRegions({ source }, t('region.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('region.region'),
      dataIndex: 'region',
      key: 'region',
      sorter: (a, b) => a.region?.localeCompare(b.region),
    },
    {
      title: t('district.district'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.sort - b.sort,
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
      sorter: (a, b) => Number(a.status[0]) - Number(b.status[0]),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => {
        if (
          rolesChecker([
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
            AUTHORITIES.ADMIN,
          ])
        ) {
          return <DistrictsForm edit={true} district={record.district} />
        }
        return null
      },
    },
  ]

  const data =
    _district && _district.length
      ? _district.map((district) => {
          return {
            key: district.id,
            id: district.id,
            name: localizeData(district?.data)?.name,
            status: [district.status],
            district: district,
            sort: district.sorting,
            region: localizeData(
              _regions.find((region) => region.id === district.regionId)?.data,
            )?.name,
          }
        })
      : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
            AUTHORITIES.ADMIN,
          ]) ? (
            <DistrictsForm />
          ) : null
        }
        title={t('district.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _state: state.districts.state,
    _district: state.districts.districts,
    _regions: state.regions.regions,
  }
}

export default connect(mapStateToProps)(ReferenceBookDistricts)
