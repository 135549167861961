import './lang-form.less'
import React, { useState } from 'react'
import { Button, Form, Input, InputNumber, Modal, Spin, Switch } from 'antd'
import { ILang } from '@app/interfaces/languages'
import { createLang, updateLang } from '@app/store/actions/language-actions'
import { AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import LanguageRepository from '@api/repositories/language-repository'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import ModalFooter from '@app/shared/modal-footer/modal-footer'

interface props {
  lang?: ILang
  edit?: boolean
}

const LangForm: React.FC<props> = ({ edit, lang }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t, i18n] = useTranslation()

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const newValues = {
      id: values.id,
      name: values.name,
      code: values.code,
      sorting: values.sorting,
      status: values.status,
    }
    setConfirmLoading(true)
    const languageSubmitApi = lang
      ? LanguageRepository.update
      : LanguageRepository.create

    await languageSubmitApi(newValues)
      .then((response: AxiosResponse<ILang>) => {
        if (lang && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateLang(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!lang && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createLang(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('language.changeLanguage') : t('language.addLanguage')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: null || lang?.id,
              sorting: 0,
              status: edit ? lang?.status : true,
              code: lang?.code || '',
              name: lang?.name || '',
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: 0,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label="status"
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={lang?.status === true}
              />
            </Form.Item>
            <Form.Item
              label={t('language.language')}
              name={'name'}
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
                {
                  min: 2,
                  message: t('errors.minMessage'),
                },
              ]}
            >
              <Input disabled={confirmLoading} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
                {
                  min: 2,
                  message: t('errors.minMessage'),
                },
              ]}
              label={'Код языка'}
              name={'code'}
            >
              <Input disabled={confirmLoading} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default LangForm
