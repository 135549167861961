import {
  ControlOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Tabs } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './admin-scheduler.less'
import SchedulerControl from './components/admin-scheduler-control'
import SchedulerHistory from './components/admin-scheduler-history'
import SchedulerInfo from './components/admin-scheduler-info'

const Scheduler: React.FC = () => {
  const [t] = useTranslation()
  const [infoTab, setInfoTab] = useState<boolean>(false)
  const { TabPane } = Tabs
  setDocumentTitle(t('scheduler.title'))
  return (
    <Card title={t('scheduler.title')} className="scheduler">
      <Tabs
        defaultActiveKey="1"
        onTabClick={() => {
          setInfoTab((prev) => !prev)
        }}
      >
        {rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PLANNER_HISTORY_VIEW,
        ]) && (
          <TabPane
            tab={
              <span>
                <HistoryOutlined /> {t('scheduler.tabs.history')}
              </span>
            }
            key="1"
          >
            <SchedulerHistory refresh={infoTab} />
          </TabPane>
        )}
        {rolesChecker([AUTHORITIES.ROLE_PLANNER_CURRENT_STATUS_VIEW]) && (
          <TabPane
            tab={
              <span>
                <InfoCircleOutlined /> {t('scheduler.tabs.info')}
              </span>
            }
            key="2"
          >
            <SchedulerInfo refresh={infoTab} />
          </TabPane>
        )}
        {rolesChecker([AUTHORITIES.ROLE_PLANNER_MANAGEMENT]) && (
          <TabPane
            forceRender={true}
            tab={
              <span>
                <ControlOutlined /> {t('scheduler.tabs.control')}
              </span>
            }
            key="3"
          >
            <SchedulerControl refresh={infoTab} />
          </TabPane>
        )}
      </Tabs>
    </Card>
  )
}

export default Scheduler
