import { DownOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import usePagination from '@app/hooks/usePagination'
import { ILang } from '@app/interfaces/languages'
import { IOrganization } from '@app/interfaces/organization/organization'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import Pagination from '@app/shared/pagination/pagination'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Dropdown, Menu, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

interface props {
  _organizations?: IOrganization[]
  languages: ILang[]
  _pupils: IOrganizationPupils[]
}

interface params {
  id?: string
}

const OrganizationContractsPage: React.FC<props> = ({
  _organizations,
  languages,
  _pupils,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState('')
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation()
  const params: params = useParams()
  const navigate = useNavigate()
  const {
    searchParams,
    requestFunction,
    pageAndSize,
    handleTableChange,
    setPage,
  } = usePagination(
    `organizationId.equals=${params.id}&orderNo.notEquals=null&status.in=APPROVED`,
    {
      page: 1,
      size: 10,
    },
  )
  setDocumentTitle(t('contracts.title'))

  useEffect(() => {
    // setLoaded(false);
    // dispatch(clearOrganizationPupils());
    const source = axios.CancelToken.source()
    // OrganizationPupilsRepository.getAll({
    //   params: searchParams,
    //   source,
    // })
    //   .then((response) => {
    //     const contracts = response.data.filter(
    //       (pupilEducation) =>
    //         pupilEducation.orderNo !== "NULL" && pupilEducation.orderNo !== null
    //     );
    //     setTotalCount(contracts.length.toString());
    //     dispatch(getOrganizationPupils(contracts));
    //     setLoaded(true);
    //   })
    //   .catch((error) => {
    //     if (!axios.isCancel(error)) {
    //       if (error.response.status === 404) {
    //         navigate("/404");
    //       }
    //       if (error.response.status === 500) {
    //         navigate("/500");
    //       }
    //       openNotificationWithIcon("error", t("pupils.error"));
    //       setLoaded(true);
    //     }
    //   });
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const permissions = [AUTHORITIES.ADMIN]

  const columns = [
    {
      title: t('contracts.number'),
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: t("contracts.contractNumber"),
    //   dataIndex: "contractNo",
    //   key: "contractNo",
    // },
    // {
    //   title: t("contracts.fullName"),
    //   dataIndex: "fullName",
    //   key: "fullName",
    // },
    // {
    //   title: t("contracts.contractDate"),
    //   dataIndex: "contractDate",
    //   key: "contractDate",
    // },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <Dropdown
          trigger={['click']}
          overlay={() => (
            <Menu>
              {rolesChecker(permissions) && record.status === 'APPROVED' && (
                <Menu.Item key="dismissPupil">
                  Add
                  {/* <OrganizationPupilsQueueDismiss pupil={record.pupil} /> */}
                </Menu.Item>
              )}
              {rolesChecker(permissions) && record.status === 'APPROVED' && (
                <Menu.Item key="changeGroup">
                  Delete
                  {/* <OrganizationChangeGroup pupil={record.pupil} /> */}
                </Menu.Item>
              )}
            </Menu>
          )}
        >
          <Button>
            {t('general.action')} <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ]

  const data = _pupils.length
    ? _pupils.map((data) => {
        return {
          id: data.id,
          key: data.id,
          number: data.id,
          status: data.status,
          pupil: data.pupil,
          //   contractNo: data.contractNo,
          //   fullName: data.fullName,
          //   contractDate: data.contractDate,
        }
      })
    : []

  return (
    <>
      <div className="site-card-border-less-wrapper">
        {rolesChecker(permissions) ? (
          <Card
            title={t('contracts.title')}
            extra={
              <>
                {rolesChecker(permissions) ? (
                  <Link
                    className={'ant-btn ant-btn-primary'}
                    to={`/organization/${params.id}/contracts/add`}
                  >
                    {t('contracts.add')}
                  </Link>
                ) : null}
              </>
            }
          >
            <Table
              columns={columns}
              dataSource={data}
              loading={!loaded}
              pagination={false}
              onChange={handleTableChange}
              rowClassName="table-row"
              footer={() => (
                <Pagination
                  request={requestFunction}
                  totalCount={totalCount}
                  searchParams={searchParams}
                />
              )}
            />
          </Card>
        ) : null}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _organizations: state.organizations.organizations,
    languages: state.langs.langs,
    _pupils: state.organizationPupils.pupils,
  }
}

export default connect(mapStateToProps)(OrganizationContractsPage)
