import { IPathParamsArgs } from '@app/api/repositories/i-repository'

export const resolveParams = (args: IPathParamsArgs) => {
  if (!args.params) return {}
  if (typeof args.params === 'string') {
    const params = Object.fromEntries([...new URLSearchParams(args.params)])
    return {
      ...params,
    }
  }
  const params = Object.fromEntries([...args.params])
  const page: string = params['page'] as string
  const size: string = params['size'] as string
  const sort: string =
    typeof args.params['sort'] === 'string' ? params['sort'] : 'id,desc'
  return {
    ...params,
    page: parseInt(page) - 1 === -1 ? 0 : parseInt(page) || 0,
    size: parseInt(size) || 10,
    sort: sort,
  }
}

export const resolveTParams = (args: URLSearchParams | string) => {
  if (!args) return {}
  if (typeof args === 'string') {
    const params = Object.fromEntries([...new URLSearchParams(args)])
    return {
      ...params,
    }
  }
  const params = Object.fromEntries([...args])
  const page: string = params['page'] as string
  const size: string = params['size'] as string
  const sort: string =
    typeof args['sort'] === 'string' ? params['sort'] : 'id,desc'
  return {
    ...params,
    page: parseInt(page) - 1 === -1 ? 0 : parseInt(page) - 1 || 0,
    size: parseInt(size) || 10,
    sort: sort,
  }
}
