import React from 'react'
import './user-profile.less'
import { IUserInfo } from '@app/interfaces/user-info'
import { connect } from 'react-redux'
import { Card, Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { ILang } from '@app/interfaces/languages'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Link } from 'react-router-dom'

interface props {
  _user: IUserInfo
  _languages: ILang[]
}

const Profile: React.FC<props> = ({ _user, _languages }) => {
  const [t] = useTranslation()
  setDocumentTitle(t('users.profile'))

  return (
    <>
      <Card title={_user.firstName}>
        <Descriptions size={'middle'} column={1} bordered>
          <Descriptions.Item label={t('users.id')}>
            {_user.id}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.username')}>
            {_user.username}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.firstName')}>
            {_user.firstName}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.lastName')}>
            {_user.lastName}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.middleName')}>
            {_user.middleName}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.email')}>
            {_user.email}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.phone')}>
            {_user.phone}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.organizations')}>
            {_user.organizations.map((organization) => (
              <Link
                key={organization.id}
                to={`/organization/${organization.organizationId}/page`}
              >
                {organization.organizationId}
              </Link>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.lang')}>
            {_languages.find((language) => language.code === _user.lang)?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.passportNumber')}>
            {_user.passportNumber}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.tin')}>
            {_user.tin}
          </Descriptions.Item>
          <Descriptions.Item label={t('users.pin')}>
            {_user.pin}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _user: state.userInfo.userInfo,
    _languages: state.langs.langs,
  }
}

export default connect(mapStateToProps)(Profile)
