import bbitApi from '@app/api/make-request'
import { IPupilQuarter } from '@app/interfaces/organization-growth'
import { useQuery } from 'react-query'

export const useGetAllQuarters = () => {
  const { data: allQuarters, ...rest } = useQuery(
    'getAllQuartal',
    async () => {
      const { data } = await bbitApi.get<IPupilQuarter[]>(
        '/pupil/api/v1/pupil-body-information-quarter',
      )
      return data
    },
    { initialData: [] },
  )
  return { allQuarters, ...rest }
}
