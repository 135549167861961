export const TWO_THREE = 1
export const THREE_FOUR = 2
export const FOUR_FIVE = 3
export const FIVE_SIX = 4
export const SIX_PLUS = 5
export const LOT_AGE = 6

export const YEARLY_TRAINING = 7
export const SHORT_TERM = 8
export const SPECIALIZED = 9
export const MOBILE = 10
