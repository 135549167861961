import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import organizationMenuRepository from '@app/api/repositories/organization-menu-repository'
import { AUTHORITIES } from '@app/constants/authorities'
import { FormType } from '@app/constants/formType'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import { IMenuContent } from '@app/interfaces/organization-menu'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import Pagination from '@app/shared/pagination/pagination'
import {
  getMenu,
  getMenuMeals,
  getMenuMealTypes,
  getMenus,
  removeMenu,
  toggleMenuRefresh,
} from '@app/store/actions/organization-menus-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Dropdown, Menu, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuForm from './components/menu-form'
import './menus.less'

interface PopupMenu {
  name: string
  content: IMenuContent[]
}

const Menus: React.FC = () => {
  const [t] = useTranslation()
  const { selectors } = useCreateSelector()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const [popupMenu, setPopupMenu] = useState<PopupMenu>()
  const [popupVisible, setPopupVisible] = useState<boolean>(false)
  const { state, menusCount, refresh, formType, menus, mealTypes, meals } =
    useSelector(selectors.menus.menusState)
  const {
    searchParams,
    setSearchParams,
    requestFunction,
    handleTableChange,
    setPage,
    pageAndSize,
  } = usePagination(`page=0&size=10`, {
    page: 1,
    size: 10,
  })
  setDocumentTitle(t('routes.menu'))

  useEffect(() => {
    setSearchParams(new URLSearchParams('page=0&size=10'))
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getMenus(
        { params: searchParams, source },
        t('organizationMenu.errors.menu'),
      ),
    )
    if (isEmpty(mealTypes))
      dispatch(
        getMenuMealTypes(
          { params: new URLSearchParams('page=0&size=1000'), source: source },
          t('organizationMenu.errors.mealTypes'),
        ),
      )
    if (isEmpty(meals))
      dispatch(
        getMenuMeals(
          { params: new URLSearchParams('page=0&size=1000'), source: source },
          t('organizationMenu.errors.meals'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams, refresh])

  const addMenu = (
    values: any,
    menuContent: IMenuContent[],
    cb: () => void,
  ) => {
    const newValues = {
      ...values,
      menuType: 'SIMPLE',
      data: values.data.filter((item) => !isEmpty(item)),
      simpleContent: menuContent.map((item) => {
        return {
          mealTypeId: item.mealType.id,
          id: item.id,
          meals: item.meals.map((meal) => meal.id),
        }
      }),
      complexContent: [],
    }
    const apiCall =
      formType === FormType.ADD
        ? organizationMenuRepository.addMenu(newValues)
        : organizationMenuRepository.updateMenu(values.id, newValues)
    apiCall
      .then((response) => {
        cb()
        dispatch(toggleMenuRefresh())
        openNotificationWithIcon(
          'success',
          t(
            `organizationMenu.menus.${
              formType === FormType.EDIT ? 'successEdit' : 'successAdd'
            }`,
          ),
        )
      })
      .catch((error) =>
        openNotificationWithIcon('error', t('organizationMenu.menus.errorAdd')),
      )
  }

  const onMenuEditHandler = (id: number) => {
    dispatch(getMenu({ data: id }, t('organizationMenu.menus.errorEdit')))
  }

  const onMenuRemoveHandler = (id: number) => {
    dispatch(
      removeMenu(
        { data: id },
        t('organizationMenu.menus.successRemove'),
        t('organizationMenu.menus.errorRemove'),
      ),
    )
  }

  const onMealMouseOverHandler = (menu, event) => {
    document.documentElement.style.setProperty(
      '--popup-x',
      `${event.pageX - 250}px`,
    )
    document.documentElement.style.setProperty(
      '--popup-y',
      `${event.pageY - 50}px`,
    )
    setPopupVisible(true)
    setPopupMenu({ ...menu, content: [...menu.content] })
  }

  const onMealMouseOutHandler = () => {
    setPopupVisible(false)
  }

  const permissions = [
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_MENU_ALL_EDIT,
    AUTHORITIES.ROLE_MENU_REGION_EDIT,
    AUTHORITIES.ROLE_MENU_DISTRICT_EDIT,
    AUTHORITIES.ROLE_MENU_CURRENT_EDIT,
  ]

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => (
        <Text>{(pageAndSize.page - 1) * pageAndSize.size + index + 1}</Text>
      ),
    },
    {
      title: t('organizationMenu.menus.name'),
      key: 'name',
      render: (record) => (
        <div
          className="menu-row"
          onMouseEnter={(event) => onMealMouseOverHandler(record, event)}
          onMouseLeave={onMealMouseOutHandler}
        >
          {record.name}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1,
      },
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (record) =>
        rolesChecker(permissions) && record ? (
          <Dropdown
            trigger={['click']}
            placement={'bottomCenter'}
            overlay={() => (
              <Menu>
                <Menu.Item key="edit">
                  <Button
                    type="text"
                    onClick={() => onMenuEditHandler(record.id)}
                  >
                    <EditOutlined /> {t('general.edit')}
                  </Button>
                </Menu.Item>
                <Menu.Item key="remove">
                  <Button
                    type="text"
                    onClick={() => onMenuRemoveHandler(record.id)}
                  >
                    <DeleteOutlined /> {t('general.delete')}
                  </Button>
                </Menu.Item>
              </Menu>
            )}
          >
            <Button>
              {t('general.action')} <DownOutlined />
            </Button>
          </Dropdown>
        ) : null,
    },
  ]

  const data = !isEmpty(menus)
    ? menus.map((menu) => {
        return {
          key: menu.id,
          id: menu.id,
          name: localizeData(menu.data)?.name,
          content: menu.simpleContent,
        }
      })
    : []

  return (
    <div className="menu">
      <div className="menu__header">
        {rolesChecker(permissions) && <MenuForm addMenu={addMenu} />}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName="table-row"
        loading={state === LOADING}
        pagination={false}
        onChange={handleTableChange}
        footer={() => (
          <Pagination
            request={requestFunction}
            totalCount={menusCount}
            setPage={setPage}
            searchParams={searchParams}
          />
        )}
      />
      <div className={`menu__popup ${popupVisible ? 'visible' : 'hidden'}`}>
        <p className="menu__popup--title">{`${popupMenu?.name}`}</p>
        {popupMenu?.content
          .sort((a, b) => {
            return a.mealType.data[0].id - b.mealType.data[0].id
          })
          .map((item) => {
            return (
              <div key={item.id} className="menu__popup--meal-types">
                <p className="title">
                  {localizeData(item.mealType.data)?.name}
                </p>
                {item.meals.map((meal, index) => {
                  return (
                    <div key={index} className="meals">
                      <p className="meals__title">
                        - {localizeData(meal.data)?.name} / {meal.weight}
                      </p>
                    </div>
                  )
                })}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Menus
