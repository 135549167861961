import {
  IWarehouseData,
  IWarehouseInvoice,
} from '@app/interfaces/organization-warehouse'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import bbitApi from '../make-request'
import { IPathParamsArgs } from './i-repository'

class OrganizationWarehouse {
  getWarehouseData(
    id: string,
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IWarehouseData[]>> {
    return bbitApi.get(`/attendance/api/v1/wh/organization/${id}/products`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getWarehouseInvoice(
    id: string,
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IWarehouseInvoice[]>> {
    return bbitApi.get(
      `/attendance/api/v1/wh/organization/${id}/product-details`,
      {
        params: resolveParams(args),
        cancelToken: args.source?.token,
      },
    )
  }

  addProductToWarehouse(data: any): Promise<AxiosResponse<IWarehouseInvoice>> {
    return bbitApi.post(`/attendance/api/v1/wh/organization/all-products`, data)
  }
}

export default new OrganizationWarehouse()
