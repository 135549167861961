import InstitutionRepository from '@api/repositories/institution-passport-repository'
import { DATE_FORMAT } from '@app/constants/date'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganizationPassport } from '@app/interfaces/organization-passport/organization-passport'
import { IPublicDictionaries } from '@app/interfaces/public-dictionaries'
import AvailableDrinkingWaterItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/available-drinking-water/available-drinking-water'
import ElectricitiesItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/electricities-item/electricities-item'
import FoodPreparingPlacesItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/food-preparing-place/food-preparing-place'
import HeatingSystemSourcesItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/heating-system-sources-item/heating-system-sources-item'
import HotWaterSourcesItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/hot-water-sources-item/hot-water-sources-item'
import InstitutionTypeItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/institution-type-item/institution-type-item'
import MedicalStaffItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/medical-staff-item/medical-staff-item'
import OwnershipsItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/ownerships-item/ownerships-item'
import SolidWasteRemovingsItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/solid-waste-removings-item/solid-waste-removings-item'
import StayDurationItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/stay-duration-item/stay-duration-item'
import ToiletCleaningsItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/toilet-cleanings-item/toilet-cleanings-item'
import ToiletLocationsItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/toilet-location-item/toilet-location-item'
import ToiletTypesItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/toilet-types-item/toilet-types-item'
import WaterSourcesItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/water-sources-item/water-sources-item'
import WaterTreatmentItem from '@app/pages/organizations/components/institution-passport/components/institution-passport-form/components/water-treatment/water-treatment'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { getInstitutionPassport } from '@app/store/actions/Institution-passport-actions'
import { getPublicDictionaries } from '@app/store/actions/public-dictionaries-actions'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
  Switch,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import axios, { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
interface props {
  _dictionaries: IPublicDictionaries
  _organizationPassport?: IOrganizationPassport
}

interface params {
  id?: string
  edit?: string
  add?: string
  passportId?: string
}

const InstitutionPassportForm: React.FC<props> = ({
  _organizationPassport,
  _dictionaries,
}) => {
  const dispatch = useDispatch()
  const params: params = useParams()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const { localize } = useLocalization()

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getPublicDictionaries(
        { source: source },
        t('institutionPassport.dictionariesError'),
      ),
    )
    if (params.edit) {
      if (
        isEmpty(_organizationPassport) ||
        _organizationPassport?.organization?.id !== Number(params.id)
      )
        dispatch(
          getInstitutionPassport(
            { data: Number(params.id), source },
            t('institutionPassport.error'),
          ),
        )
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  // TODO: fix this coused errors bugs
  const onFinish = async (values: any) => {
    if (isEmpty(_dictionaries)) return
    const ownership = localize(_dictionaries.ownership).find(
      (ownership) => ownership.data?.name === values.ownership,
    )?.id
    const institutionType = localize(_dictionaries.institutionType).find(
      (institutionType) =>
        institutionType.data?.name === values.institutionType,
    )?.id
    const childrenStayDuration = localize(
      _dictionaries.childrenStayDuration,
    ).find(
      (childrenStayDuration) =>
        childrenStayDuration.data?.name === values.childrenStayDuration,
    )?.id
    const medicalStaffAvailability = localize(
      _dictionaries.medicalStaffAvailability,
    ).find(
      (medicalStaffAvailability) =>
        medicalStaffAvailability.data?.name === values.medicalStaffAvailability,
    )?.id
    const drinkingWaterAvailable = localize(
      _dictionaries.drinkingWaterAvailable,
    ).find(
      (drinkingWaterAvailable) =>
        drinkingWaterAvailable.data?.name === values.drinkingWaterAvailable,
    )?.id
    const drinkingWaterTreatment = localize(
      _dictionaries.drinkingWaterTreatment,
    ).find(
      (drinkingWaterTreatment) =>
        drinkingWaterTreatment.data?.name === values.drinkingWaterTreatment,
    )?.id
    const toiletType = localize(_dictionaries.toiletType).find(
      (toiletType) => toiletType.data?.name === values.toiletType,
    )?.id
    const toiletLocation = localize(_dictionaries.toiletLocation).find(
      (toiletLocation) => toiletLocation.data?.name === values.toiletLocation,
    )?.id
    const toiletCleaning = localize(_dictionaries.toiletCleaning).find(
      (toiletCleaning) => toiletCleaning.data?.name === values.toiletCleaning,
    )?.id
    const solidWasteRemoving = localize(_dictionaries.solidWasteRemoving).find(
      (solidWasteRemoving) =>
        solidWasteRemoving.data?.name === values.solidWasteRemoving,
    )?.id
    const hotWaterSource = localize(_dictionaries.hotWaterSource).find(
      (hotWaterSource) => hotWaterSource.data?.name === values.hotWaterSource,
    )?.id
    const drinkingWaterSource = localize(
      _dictionaries.drinkingWaterSource,
    ).find(
      (drinkingWaterSource) =>
        drinkingWaterSource.data?.name === values.drinkingWaterSource,
    )?.id
    const heatingSystemSource = localize(
      _dictionaries.heatingSystemSource,
    ).find(
      (heatingSystemSource) =>
        heatingSystemSource.data?.name === values.heatingSystemSource,
    )?.id
    const electricity = localize(_dictionaries.electricity).find(
      (electricity) => electricity.data?.name === values.electricity,
    )?.id
    const foodPreparingPlace = localize(_dictionaries.foodPreparingPlace).find(
      (foodPreparingPlace) =>
        foodPreparingPlace.data?.name === values.foodPreparingPlace,
    )?.id

    const khokimiyatOrderDate = !values['khokimiyatOrderDate']
      ? undefined
      : values['khokimiyatOrderDate'].format(DATE_FORMAT)

    const newValues: IOrganizationPassport = {
      ...values,
      queuePercentage: values.queuePercentage,
      organization: {
        id: Number(params.id),
      },
      khokimiyatOrderDate: khokimiyatOrderDate,
      cadastralNumber: values.cadastralNumber,
      ownership: values.ownership
        ? {
            id: Number(values.ownership) || ownership,
          }
        : undefined,
      institutionType: values.institutionType
        ? {
            id: Number(values.institutionType) || institutionType,
          }
        : undefined,
      projectCapacity: values.projectCapacity,
      childrenStayDuration: values.childrenStayDuration
        ? {
            id: Number(values.childrenStayDuration) || childrenStayDuration,
          }
        : undefined,
      medicalStaffAvailability: values.medicalStaffAvailability
        ? {
            id:
              Number(values.medicalStaffAvailability) ||
              medicalStaffAvailability,
          }
        : undefined,
      cleanersNumber: values.cleanersNumber,
      drinkingWaterAvailable: values.drinkingWaterAvailable
        ? {
            id: Number(values.drinkingWaterAvailable) || drinkingWaterAvailable,
          }
        : undefined,
      drinkingWaterTreatment: values.drinkingWaterTreatment
        ? {
            id: Number(values.drinkingWaterTreatment) || drinkingWaterTreatment,
          }
        : undefined,
      toiletType: values.toiletType
        ? {
            id: Number(values.toiletType) || toiletType,
          }
        : undefined,
      toiletLocation: values.toiletLocation
        ? {
            id: Number(values.toiletLocation) || toiletLocation,
          }
        : undefined,
      toiletCleaning: values.toiletCleaning
        ? {
            id: Number(values.toiletCleaning) || toiletCleaning,
          }
        : undefined,
      solidWasteRemoving: values.solidWasteRemoving
        ? {
            id: Number(values.solidWasteRemoving) || solidWasteRemoving,
          }
        : undefined,
      hotWaterSource: values.hotWaterSource
        ? {
            id: Number(values.hotWaterSource) || hotWaterSource,
          }
        : undefined,
      drinkingWaterSource: values.drinkingWaterSource
        ? {
            id: Number(values.drinkingWaterSource) || drinkingWaterSource,
          }
        : undefined,
      heatingSystemSource: values.heatingSystemSource
        ? {
            id: Number(values.heatingSystemSource) || heatingSystemSource,
          }
        : undefined,
      electricity: values.electricity
        ? {
            id: Number(values.electricity) || electricity,
          }
        : undefined,
      foodPreparingPlace: values.foodPreparingPlace
        ? {
            id: Number(values.foodPreparingPlace) || foodPreparingPlace,
          }
        : undefined,
    }
    setConfirmLoading(true)
    const institutionsSubmitApi = params.edit
      ? InstitutionRepository.update
      : InstitutionRepository.create

    await institutionsSubmitApi(newValues)
      .then((response: AxiosResponse<IOrganizationPassport>) => {
        if (params.edit && response.status === 200) {
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.updated'))
          navigate(`/organization/${params.id}/passport`)
        }
        if (!params.edit && response.status === 201) {
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.created'))
          navigate(`/organization/${params.id}/passport`)
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('errors.error'))
        setConfirmLoading(false)
      })
  }
  // return <></>

  return (
    <Card
      extra={
        <>
          <Button
            disabled={confirmLoading}
            type="primary"
            onClick={form.submit}>
            {params.edit ? t('general.edit') : t('general.add')}
          </Button>
          <Link
            to={`/organization/${params.id}`}
            style={{ marginLeft: '10px' }}>
            {t('general.cancel')}
          </Link>
        </>
      }
      title={params.edit ? t('general.edit') : t('general.add')}>
      <Spin spinning={confirmLoading} className="padding-content">
        {!isEmpty(_dictionaries) && (
          <Form
            className="padding-content border-top"
            form={form}
            initialValues={{
              ownership: localize(_dictionaries.ownership)?.find(
                (dictionary) =>
                  dictionary?.id === _organizationPassport?.ownership?.id,
              )?.data?.name,
              institutionType: localize(_dictionaries.institutionType)?.find(
                (dictionary) =>
                  dictionary?.id === _organizationPassport?.institutionType?.id,
              )?.data?.name,
              childrenStayDuration: localize(
                _dictionaries.childrenStayDuration,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.childrenStayDuration?.id,
              )?.data?.name,
              medicalStaffAvailability: localize(
                _dictionaries.medicalStaffAvailability,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.medicalStaffAvailability?.id,
              )?.data?.name,
              drinkingWaterSource: localize(
                _dictionaries.drinkingWaterSource,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.drinkingWaterSource?.id,
              )?.data?.name,
              drinkingWaterAvailable: localize(
                _dictionaries.drinkingWaterAvailable,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.drinkingWaterAvailable?.id,
              )?.data?.name,
              drinkingWaterTreatment: localize(
                _dictionaries.drinkingWaterTreatment,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.drinkingWaterTreatment?.id,
              )?.data?.name,
              toiletType: localize(_dictionaries.toiletType)?.find(
                (dictionary) =>
                  dictionary?.id === _organizationPassport?.toiletType?.id,
              )?.data?.name,
              toiletLocation: localize(_dictionaries.toiletLocation)?.find(
                (dictionary) =>
                  dictionary?.id === _organizationPassport?.toiletLocation?.id,
              )?.data?.name,
              toiletCleaning: localize(_dictionaries.toiletCleaning)?.find(
                (dictionary) =>
                  dictionary?.id === _organizationPassport?.toiletCleaning?.id,
              )?.data?.name,
              hotWaterSource: localize(_dictionaries.hotWaterSource)?.find(
                (dictionary) =>
                  dictionary?.id === _organizationPassport?.hotWaterSource?.id,
              )?.data?.name,
              heatingSystemSource: localize(
                _dictionaries.heatingSystemSource,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.heatingSystemSource?.id,
              )?.data?.name,
              electricity: localize(_dictionaries.electricity)?.find(
                (dictionary) =>
                  dictionary?.id === _organizationPassport?.electricity?.id,
              )?.data?.name,
              foodPreparingPlace: localize(
                _dictionaries.foodPreparingPlace,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.foodPreparingPlace?.id,
              )?.data?.name,
              solidWasteRemoving: localize(
                _dictionaries.solidWasteRemoving,
              )?.find(
                (dictionary) =>
                  dictionary?.id ===
                  _organizationPassport?.solidWasteRemoving?.id,
              )?.data?.name,
            }}
            onFinish={onFinish}
            layout={'vertical'}>
            <Row gutter={8}>
              <Form.Item
                hidden={true}
                name="id"
                initialValue={params.passportId}>
                <InputNumber />
              </Form.Item>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.cadastralNumber')}
                  name={'cadastralNumber'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'outsourcingService'}
                  label={t('institutionPassport.outsourcingService')}
                  valuePropName="checked">
                  <Switch
                    defaultChecked={
                      _organizationPassport?.outsourcingService === true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.khokimiyatOrder')}
                  name={'khokimiyatOrder'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={
                    _organizationPassport?.khokimiyatOrderDate
                      ? moment(_organizationPassport?.khokimiyatOrderDate)
                      : undefined
                  }
                  label={t('institutionPassport.khokimiyatOrderDate')}
                  name={'khokimiyatOrderDate'}>
                  <DatePicker locale={locale} format={DATE_FORMAT} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.workSchedule')}
                  name={'workSchedule'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.openDate')}
                  name={'openDate'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <OwnershipsItem ownership={_dictionaries.ownership} />
              </Col>
              <Col span={12}>
                <InstitutionTypeItem
                  institutionType={_dictionaries.institutionType}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.queuePercentage')}
                  name={'queuePercentage'}
                  initialValue={_organizationPassport?.queuePercentage}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.queuePercentage')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.projectCapacity')}
                  name={'projectCapacity'}
                  initialValue={_organizationPassport?.projectCapacity}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.projectCapacity')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <StayDurationItem
                  stayDuration={_dictionaries.childrenStayDuration}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.totalBuildingsUsableArea')}
                  name={'totalBuildingsUsableArea'}
                  initialValue={
                    _organizationPassport?.totalBuildingsUsableArea
                  }>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.totalBuildingsUsableArea',
                    )}
                    min={1}
                    precision={2}
                    step={0.1}
                    max={100000}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={_organizationPassport?.bedroomArea}
                  label={t('institutionPassport.bedroomArea')}
                  name={'bedroomArea'}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.bedroomArea')}
                    min={1}
                    precision={2}
                    step={0.1}
                    max={100000}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.groupsNumber')}
                  initialValue={_organizationPassport?.groupsNumber}
                  name={'groupsNumber'}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.groupsNumber')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.totalEmployeesNumber')}
                  name={'totalEmployeesNumber'}
                  initialValue={_organizationPassport?.totalEmployeesNumber}>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.totalEmployeesNumber',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.womenEmployeesNumber')}
                  name={'womenEmployeesNumber'}
                  initialValue={_organizationPassport?.womenEmployeesNumber}>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.womenEmployeesNumber',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.menEmployeesNumber')}
                  name={'menEmployeesNumber'}
                  initialValue={_organizationPassport?.menEmployeesNumber}>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.menEmployeesNumber',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.totalChildrenNumber')}
                  name={'totalChildrenNumber'}
                  initialValue={_organizationPassport?.totalChildrenNumber}>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.totalChildrenNumber',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.girlsNumber')}
                  name={'girlsNumber'}
                  initialValue={_organizationPassport?.girlsNumber}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.girlsNumber')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.boysNumber')}
                  name={'boysNumber'}
                  initialValue={_organizationPassport?.boysNumber}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.boysNumber')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <MedicalStaffItem
                  medicalStaff={_dictionaries.medicalStaffAvailability}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.cleanersNumber')}
                  name={'cleanersNumber'}
                  initialValue={_organizationPassport?.cleanersNumber}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.cleanersNumber')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <WaterSourcesItem
                  waterSources={_dictionaries.drinkingWaterSource}
                />
              </Col>
              <Col span={12}>
                <AvailableDrinkingWaterItem
                  availableDrinkingWater={_dictionaries.drinkingWaterAvailable}
                />
              </Col>
              <Col span={12}>
                <WaterTreatmentItem
                  waterTreatment={_dictionaries.drinkingWaterTreatment}
                />
              </Col>
              <Col span={12}>
                <ToiletTypesItem toiletTypes={_dictionaries.toiletType} />
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.fullyUsableToilets')}
                  name={'fullyUsableToilets'}
                  initialValue={_organizationPassport?.fullyUsableToilets}>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.fullyUsableToilets',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <ToiletLocationsItem
                  toiletLocations={_dictionaries.toiletLocation}
                />
              </Col>
              <Col span={12}>
                <ToiletCleaningsItem
                  toiletCleanings={_dictionaries.toiletCleaning}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'toiletEquippedForReducedMobilityOrVisionImpairments'}
                  label={t(
                    'institutionPassport.toiletEquippedForReducedMobilityOrVisionImpairments',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.toiletEquippedForReducedMobilityOrVisionImpairments
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.toiletEquippedForReducedMobilityOrVisionImpairments ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'sinksWashbasinsAvailabilityForHandWashing'}
                  label={t(
                    'institutionPassport.sinksWashbasinsAvailabilityForHandWashing',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.sinksWashbasinsAvailabilityForHandWashing
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.sinksWashbasinsAvailabilityForHandWashing ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.handsWashingPointsWithWater')}
                  name={'handsWashingPointsWithWater'}
                  initialValue={
                    _organizationPassport?.handsWashingPointsWithWater
                  }>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.handsWashingPointsWithWater',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t(
                    'institutionPassport.handsWashingPointsWithWaterAndSoap',
                  )}
                  name={'handsWashingPointsWithWaterAndSoap'}
                  initialValue={
                    _organizationPassport?.handsWashingPointsWithWaterAndSoap
                  }>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={'sinkOrWashbasinEquippedForReducedMobilityImpairments'}
                  label={t(
                    'institutionPassport.sinkOrWashbasinEquippedForReducedMobilityImpairments',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.sinkOrWashbasinEquippedForReducedMobilityImpairments
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.sinkOrWashbasinEquippedForReducedMobilityImpairments ===
                      true
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <SolidWasteRemovingsItem
                  solidWasteRemovings={_dictionaries.solidWasteRemoving}
                />
              </Col>

              <Col span={12}>
                <HotWaterSourcesItem
                  hotWaterSources={_dictionaries.hotWaterSource}
                />
              </Col>

              <Col span={12}>
                <HeatingSystemSourcesItem
                  heatingSystemSources={_dictionaries.heatingSystemSource}
                />
              </Col>

              <Col span={12}>
                <ElectricitiesItem electricities={_dictionaries.electricity} />
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'gasAvailability'}
                  label={t('institutionPassport.gasAvailability')}
                  valuePropName="checked">
                  <Switch
                    defaultChecked={
                      _organizationPassport?.gasAvailability === true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'centralSewerage'}
                  initialValue={_organizationPassport?.centralSewerage}
                  label={t('institutionPassport.centralSewerage')}
                  valuePropName="checked">
                  <Switch
                    defaultChecked={
                      _organizationPassport?.centralSewerage === true
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <FoodPreparingPlacesItem
                  foodPreparingPlaces={_dictionaries.foodPreparingPlace}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'workingRefrigeratorAvailability'}
                  label={t(
                    'institutionPassport.workingRefrigeratorAvailability',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.workingRefrigeratorAvailability
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.workingRefrigeratorAvailability ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'workingSinkPresenceForWashingDishes'}
                  label={t(
                    'institutionPassport.workingSinkPresenceForWashingDishes',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.workingSinkPresenceForWashingDishes
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.workingSinkPresenceForWashingDishes ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'bathingRoomsNumber'}
                  label={t('institutionPassport.bathingRoomsNumber')}
                  initialValue={_organizationPassport?.bathingRoomsNumber}>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'separateRoomsOrBathTimeGirlsBoys'}
                  label={t(
                    'institutionPassport.separateRoomsOrBathTimeGirlsBoys',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.separateRoomsOrBathTimeGirlsBoys
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.separateRoomsOrBathTimeGirlsBoys ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'separateRoomsOrBathTimeStudentsStaff'}
                  label={t(
                    'institutionPassport.separateRoomsOrBathTimeStudentsStaff',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.separateRoomsOrBathTimeStudentsStaff
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.separateRoomsOrBathTimeStudentsStaff ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'separateFemalesAndMalesBathWithReducedMobility'}
                  label={t(
                    'institutionPassport.separateFemalesAndMalesBathWithReducedMobility',
                  )}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.separateFemalesAndMalesBathWithReducedMobility
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.separateFemalesAndMalesBathWithReducedMobility ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'bathingHotWaterAvailability'}
                  label={t('institutionPassport.bathingHotWaterAvailability')}
                  valuePropName="checked"
                  initialValue={
                    _organizationPassport?.bathingHotWaterAvailability
                  }>
                  <Switch
                    defaultChecked={
                      _organizationPassport?.bathingHotWaterAvailability ===
                      true
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t(
                    'institutionPassport.hoursWaterAvailabilityLastTwoWeek',
                  )}
                  initialValue={
                    _organizationPassport?.hoursWaterAvailabilityLastTwoWeek
                  }
                  name={'hoursWaterAvailabilityLastTwoWeek'}>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'internet'}
                  label={t('institutionPassport.internet')}
                  valuePropName="checked"
                  initialValue={_organizationPassport?.internet}>
                  <Switch
                    defaultChecked={_organizationPassport?.internet === true}
                  />
                </Form.Item>
              </Col>

              {/* New Fields */}
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.automaticWashingMachines')}
                  name={'automaticWashingMachines'}
                  initialValue={
                    _organizationPassport?.automaticWashingMachines
                  }>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.automaticWashingMachines',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.semiAutomaticWashingMachines')}
                  name={'semiAutomaticWashingMachines'}
                  initialValue={
                    _organizationPassport?.semiAutomaticWashingMachines
                  }>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.semiAutomaticWashingMachines',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.bowlerPotsNumber')}
                  name={'bowlerPotsNumber'}
                  initialValue={_organizationPassport?.bowlerPotsNumber}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.bowlerPotsNumber')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.smallBowlerPotsNumber')}
                  name={'smallBowlerPotsNumber'}
                  initialValue={_organizationPassport?.smallBowlerPotsNumber}>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.smallBowlerPotsNumber',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.ovensNumber')}
                  name={'ovensNumber'}
                  initialValue={_organizationPassport?.ovensNumber}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.ovensNumber')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.orchardsAndFlowerGardensArea')}
                  name={'orchardsAndFlowerGardensArea'}
                  initialValue={
                    _organizationPassport?.orchardsAndFlowerGardensArea
                  }>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.orchardsAndFlowerGardensArea',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.courtyardArea')}
                  name={'courtyardArea'}
                  initialValue={_organizationPassport?.courtyardArea}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.courtyardArea')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.officeCleaningArea')}
                  name={'officeCleaningArea'}
                  initialValue={_organizationPassport?.officeCleaningArea}>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.officeCleaningArea',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t(
                    'institutionPassport.waterSupplySewerageHeatingArea',
                  )}
                  name={'waterSupplySewerageHeatingArea'}
                  initialValue={
                    _organizationPassport?.waterSupplySewerageHeatingArea
                  }>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.waterSupplySewerageHeatingArea',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.lightingPoints')}
                  name={'lightingPoints'}
                  initialValue={_organizationPassport?.lightingPoints}>
                  <InputNumber
                    placeholder={t('institutionPlaceholders.lightingPoints')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('institutionPassport.separatedBuildingsNumber')}
                  name={'separatedBuildingsNumber'}
                  initialValue={
                    _organizationPassport?.separatedBuildingsNumber
                  }>
                  <InputNumber
                    placeholder={t(
                      'institutionPlaceholders.separatedBuildingsNumber',
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
              <Button
                disabled={confirmLoading}
                className={'institution-form__btn'}
                type="primary"
                htmlType="submit">
                {params.edit ? t('general.edit') : t('general.add')}
              </Button>
              <Link
                to={`/organization/${params.id}`}
                className={'ant-btn ant-btn-default'}
                style={{ marginLeft: '10px' }}>
                {t('general.cancel')}
              </Link>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    _dictionaries: state.dictionaries.allDictionaries,
    languages: state.langs.langs,
    _organizationPassport: state.organizationPassport.institution_passport,
  }
}

export default connect(mapStateToProps, null)(InstitutionPassportForm)
