import { referenceEditPermissions } from '@app/constants/reference-permission'
import useLocalization from '@app/hooks/useLocalization'
import ReasonsForm from '@app/pages/reference-book-page-details/reasons/components/reasons-form/reasons-form'
import { useGetReasonsQuery } from '@app/store/rtk/api/reasons-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ReferenceBookReasons: React.FC = () => {
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const { data: reasons, isFetching } = useGetReasonsQuery('')
  setDocumentTitle(t('reason.title'))

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('reason.reason'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker(referenceEditPermissions) ? (
          <ReasonsForm edit={true} reason={record.reason} />
        ) : null,
    },
  ]

  const data = reasons?.response?.length
    ? reasons.response.map((reason) => {
        return {
          key: reason.id,
          id: reason.id,
          name: localizeData(reason?.data)?.name,
          status: [reason.status],
          reason: reason,
        }
      })
    : []

  return (
    <>
      <Card
        title={t('reason.title')}
        extra={
          rolesChecker(referenceEditPermissions) ? (
            <>
              <ReasonsForm />
            </>
          ) : null
        }
      >
        <Table
          pagination={false}
          loading={isFetching}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

export default ReferenceBookReasons
