import EmployeeRepository from '@api/repositories/employee-repository'
import {
  IEmployee,
  IOrganizationEmployee,
} from '@app/interfaces/employee/employee'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  refreshEmployee,
  updateEmployee,
} from '@app/store/actions/employee-actions'
import { closeOrganizationPosition } from '@app/store/actions/organization-position-actions'
import { Button, Form, Input, Modal, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  employee: IOrganizationEmployee
}

const EmployeeDismissForm: React.FC<props> = ({ employee }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      employeePositionId: employee.id ? employee.id : undefined,
    }
    setConfirmLoading(true)
    EmployeeRepository.dismiss(newValues)
      .then((response: AxiosResponse<IEmployee>) => {
        dispatch(updateEmployee(response.data))
        dispatch(refreshEmployee())
        dispatch(closeOrganizationPosition(employee.organizationPosition))
        openNotificationWithIcon('success', t('employee.dismissed'))
        setConfirmLoading(false)
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('employee.dismissFailed'))
      })
  }

  function getActualDate() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return day + '.' + month + '.' + year
  }

  const date = getActualDate()
  return (
    <>
      <React.Fragment>
        <Button onClick={showModal} type={'link'}>
          {t('employee.dismiss')}
        </Button>

        <Modal
          open={visible}
          title={t('employee.dismiss')}
          onCancel={hideModal}
          footer={[
            <React.Fragment key={1}>
              <Button
                onClick={form.submit}
                type={'primary'}
                disabled={confirmLoading}
              >
                {t('employee.dismiss')}
              </Button>
              <Button
                onClick={hideModal}
                type={'default'}
                style={{ marginLeft: '10px' }}
                disabled={confirmLoading}
              >
                {t('general.cancel')}
              </Button>
            </React.Fragment>,
          ]}
        >
          <Spin spinning={confirmLoading}>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{ employeeId: employee.employee?.id }}
              layout={'vertical'}
            >
              <Form.Item hidden={true} name={'employeeId'}>
                <Input />
              </Form.Item>
              <Form.Item
                label={t('pupils.orderNo')}
                name={'orderNo'}
                rules={[
                  {
                    transform: (value) =>
                      typeof value === 'string' ? value.trim() : undefined,
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                ]}
              >
                <Input disabled={confirmLoading} />
              </Form.Item>
              <Form.Item
                initialValue={date}
                name={'orderDate'}
                label={t('employee.dismissDate')}
              >
                <Input disabled={true} />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    </>
  )
}

export default EmployeeDismissForm
