import { IPathParams } from '@app/constants/path-params'
import Loader from '@app/shared/loader/loader'
import { useGetOrganizationQuery } from '@app/store/rtk/api/organization/organization-api'
import { isEmpty } from 'lodash'
import React from 'react'
import { Outlet, useParams } from 'react-router'

const Organization: React.FC = () => {
  const params: IPathParams = useParams()
  const { data: organization } = useGetOrganizationQuery(Number(params.id))
  return <>{isEmpty(organization) ? <Loader /> : <Outlet />}</>
}

export default Organization
