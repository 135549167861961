import { FormType } from '@app/constants/formType'
import { langs } from '@app/constants/languages'
import useLocalization from '@app/hooks/useLocalization'
import { IMeasurement } from '@app/interfaces/organization-menu'
import ThreeDots from '@app/shared/loader/ThreeDots'
import { toggleMenuFormType } from '@app/store/actions/organization-menus-actions'
import { RootState } from '@app/store/store'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Form, Input, Modal, Select } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

interface props {
  addProduct: (values: any, cb: () => void) => void
  measurements: IMeasurement[]
}

const MenuProductForm: React.FC<props> = ({ addProduct, measurements }) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localize, localizeData } = useLocalization()
  const { product, formType } = useSelector(
    (state: RootState) => state.organizationMenus,
  )
  const showModal = () => {
    dispatch(toggleMenuFormType(FormType.ADD))
  }

  const hideModal = () => {
    dispatch(toggleMenuFormType(FormType.NONE))
    form.resetFields()
  }

  useEffect(() => {
    if (formType === FormType.EDIT) {
      const newData = product.data.sort((a, b) => {
        return a.languageId - b.languageId
      })
      newData.unshift(undefined)
      form.setFieldsValue({
        id: product.id,
        calorie: product.calorie,
        protein: product.protein,
        fat: product.fat,
        carbohydrate: product.carbohydrate,
        measurementId: product.measurement.id,
        mxikCode: product.mxikCode,
        data: newData,
      })
    }
  }, [product])

  const onFormSubmitHandler = (values: any) => {
    addProduct(values, hideModal)
  }

  return (
    <>
      <Button className={``} onClick={showModal} type={'primary'}>
        {t('general.add')}
      </Button>
      <Modal
        open={formType !== FormType.NONE}
        title={t('organizationMenu.products.add')}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <Button type={'primary'} onClick={form.submit}>
              {t('general.accept')}
            </Button>
            <Button
              type={'default'}
              onClick={hideModal}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Button>
          </React.Fragment>,
        ]}
      >
        <Form form={form} onFinish={onFormSubmitHandler} layout={'vertical'}>
          <Form.Item hidden={true} name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            label={t('general.name')}
          >
            <Form.List name="data">
              {() =>
                langs
                  .sort((a, b) => {
                    return a.languageId - b.languageId
                  })
                  .map(({ id, languageId, toShowCode }) => (
                    <React.Fragment key={id}>
                      <Form.Item hidden={true} name={[languageId, 'id']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[languageId, 'name']}
                        rules={[
                          {
                            required: true,
                            message: t('errors.requiredMessage'),
                          },
                          {
                            min: 2,
                            message: t('errors.minMessage'),
                          },
                        ]}
                      >
                        <Input addonBefore={toShowCode} />
                      </Form.Item>
                      <Form.Item
                        hidden={true}
                        name={[id, 'languageId']}
                        initialValue={id}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))
              }
            </Form.List>
          </Form.Item>
          <Form.Item
            label={t('organizationMenu.products.calorie')}
            name="calorie"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('organizationMenu.products.carbohydrate')}
            name="carbohydrate"
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('organizationMenu.products.fat')} name="fat">
            <Input />
          </Form.Item>
          <Form.Item
            label={t('organizationMenu.products.protein')}
            name="protein"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('organizationMenu.products.mxikCode')}
            name="mxikCode"
            rules={[
              {
                min: 17,
                max: 17,
                message: t('errors.minMaxMessage', { min: 17, max: 17 }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!isEmpty(measurements) ? (
            <Form.Item
              label={t('organizationMenu.products.measurement')}
              name="measurementId"
            >
              <Select>
                {sortByName(localize(measurements)).map((measurement) => {
                  return (
                    <Select.Option key={measurement.id} value={measurement.id}>
                      {measurement.data?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          ) : (
            <ThreeDots />
          )}
        </Form>
      </Modal>
    </>
  )
}

export default MenuProductForm
