import useLocalization from '@app/hooks/useLocalization'
import { IOrganizationPassportizationCreate } from '@app/interfaces/organization-passport/organization-passportization'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { useGetFoodPreparingPlacesQuery } from '@app/store/rtk/api/organization-passport/food-preparing-places-api'
import {
  useCreateOrganizationPassportizationMutation,
  useGetOrganizationPassportizationQuery,
  usePartialUpdateOrganizationPassportizationMutation,
} from '@app/store/rtk/api/organization-passport/organization-passportization-api'
import { useGetOutsourcingCompaniesQuery } from '@app/store/rtk/api/organization-passport/outsourcing-company-api'
import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
  Typography,
} from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { EFoodOrganizationPlaceType } from '../../organization-passportization'

interface params {
  id?: string
  edit?: string
  add?: string
  passportizationId?: string
}

const OrganizationPassportForm: React.FC = () => {
  const params: params = useParams()
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const [heating, setHeating] = useState(false)
  const [exploitation, setExploitation] = useState(false)
  const [outsourcing, setOutsourcing] = useState(false)
  const { localize } = useLocalization()
  const { data: passport, isFetching } = useGetOrganizationPassportizationQuery(
    Number(params?.id),
    { skip: !params?.edit },
  )
  const { data: outsourcingCompanies } = useGetOutsourcingCompaniesQuery('')
  const [create, { isSuccess: isCreateSuccess, isLoading: isCreateLoading }] =
    useCreateOrganizationPassportizationMutation()
  const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] =
    usePartialUpdateOrganizationPassportizationMutation()
  const success = useMemo(
    () => isCreateSuccess || isUpdateSuccess,
    [isCreateSuccess, isUpdateSuccess],
  )
  const confirmLoading = useMemo(
    () => isFetching || isCreateLoading || isUpdateLoading,
    [isFetching, isCreateLoading, isUpdateLoading],
  )
  const { data: foodPlaces } = useGetFoodPreparingPlacesQuery('')

  const onFinish = async (values: any) => {
    const newValues: IOrganizationPassportizationCreate = {
      ...values,
    }
    const submitApi = params.edit ? update : create
    submitApi({ ...newValues })
  }

  useEffect(() => {
    if (success) {
      openNotificationWithIcon('success', t('success.updated'))
      navigate(`/organization/${params.id}/passportization`)
    }
  }, [success])

  useEffect(() => {
    if (passport) {
      form.setFieldsValue({
        ...passport,
        foodPreparingPlaceId: passport.foodPreparingPlace.id,
        foodOutsourcingCompanyId: passport?.foodOutsourcingCompany?.id,
      })
      setHeating(passport.cityCentralHeatingNetwork)
      setExploitation(passport.availabilityExploitation)
      if (passport.cityCentralHeatingNetwork)
        form.setFieldsValue({
          ovensCount: undefined,
          boilerRoomsCount: undefined,
          smallBoilerRoomsCount: undefined,
        })
      if (passport.availabilityExploitation)
        form.setFieldsValue({
          lightingPointsCount: undefined,
          waterSupplySewerageHeatingArea: undefined,
        })
      return
    }
    if (!params?.edit)
      form.setFieldsValue({
        organizationId: Number(params?.id) ?? '',
        cityCentralHeatingNetwork: false,
        availabilityExploitation: false,
      })
  }, [passport])

  useEffect(() => {
    if (passport && params?.edit)
      setOutsourcing(
        passport.foodPreparingPlace.type ===
          EFoodOrganizationPlaceType.OUTSOURCING,
      )
  }, [passport])

  const onHeatingChange = (e) => {
    setHeating(e)
  }

  const onExploitationChange = (e) => {
    setExploitation(e)
  }
  const onFoodChange = (e) => {
    setOutsourcing(
      foodPlaces?.response.find(
        (place) => place.type === EFoodOrganizationPlaceType.OUTSOURCING,
      )?.id === e,
    )
  }

  return (
    <Card
      extra={
        <>
          <Button
            disabled={confirmLoading}
            type="primary"
            onClick={form.submit}
          >
            {params.edit ? t('general.edit') : t('general.add')}
          </Button>
          <Link
            to={`/organization/${params.id}/passportization`}
            style={{ marginLeft: '10px' }}
          >
            {t('general.cancel')}
          </Link>
        </>
      }
      title={params.edit ? t('general.edit') : t('general.add')}
    >
      <Spin spinning={confirmLoading} className="padding-content">
        <Form
          className="padding-content border-top"
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
        >
          <Row gutter={8}>
            <Form.Item hidden={true} name="id">
              <InputNumber />
            </Form.Item>
            <Form.Item hidden={true} name="organizationId">
              <InputNumber />
            </Form.Item>
            <Col span={24}>
              <Form.Item
                label={t('foodPreparingPlace.title')}
                required
                name="foodPreparingPlaceId"
              >
                <Select style={{ width: 200 }} onChange={onFoodChange}>
                  {localize(foodPlaces?.response)?.map(
                    ({ id, data: { name } }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            {outsourcing && (
              <Col span={24}>
                <Form.Item
                  label={t('passportization.outsourceCompany')}
                  required
                  name="foodOutsourcingCompanyId"
                >
                  <Select style={{ width: 200 }}>
                    {outsourcingCompanies?.response?.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                label={t('passportization.closePoolCount')}
                name="closePoolCount"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('passportization.physiotherapyRoomsCount')}
                name="physiotherapyRoomsCount"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('passportization.ophthalmicAndAuditoryRoomsCount')}
                name="ophthalmicAndAuditoryRoomsCount"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('passportization.organizationBranchCount')}
                name="organizationBranchCount"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Typography.Title level={5} style={{ margin: '8px 0' }}>
                {t('passportization.washingTitle')}
              </Typography.Title>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'automaticWashingMachineCount'}
                label={t('passportization.automaticWashingMachineCount')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'semiAutomaticWashingMachineCount'}
                label={t('passportization.semiAutomaticWashingMachineCount')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'handWashingCount'}
                label={t('passportization.handWashingCount')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'privateContractWashingCount'}
                label={t('passportization.privateContractWashingCount')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24} style={{ marginTop: 8 }}>
              <Typography.Title level={5}>
                {t('passportization.heatingTitle')}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('passportization.cityCentralHeatingNetwork')}
                name={'cityCentralHeatingNetwork'}
                valuePropName="checked"
              >
                <Switch
                  defaultChecked={passport?.cityCentralHeatingNetwork}
                  onChange={onHeatingChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'boilerRoomsCount'}
                label={t('passportization.boilerRoomsCount')}
              >
                <InputNumber style={{ width: '100%' }} disabled={heating} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'smallBoilerRoomsCount'}
                label={t('passportization.smallBoilerRoomsCount')}
              >
                <InputNumber style={{ width: '100%' }} disabled={heating} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'ovensCount'}
                label={t('passportization.ovensCount')}
              >
                <InputNumber style={{ width: '100%' }} disabled={heating} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Typography.Title level={5} style={{ margin: '8px 0' }}>
                {t('passportization.totalArea')}
              </Typography.Title>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'buildingsAndStructuresArea'}
                label={t('passportization.buildingsAndStructuresArea')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'flowerGardenArea'}
                label={t('passportization.flowerGardenArea')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'yardArea'}
                label={t('passportization.yardArea')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'officeSpaceCleanArea'}
                label={t('passportization.officeSpaceCleanArea')}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24} style={{ marginTop: 8 }}>
              <Typography.Title level={5}>
                {t('passportization.exploitationTitle')}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('passportization.availabilityExploitation')}
                name={'availabilityExploitation'}
                valuePropName="checked"
              >
                <Switch
                  defaultChecked={passport?.availabilityExploitation}
                  onChange={onExploitationChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'waterSupplySewerageHeatingArea'}
                label={t('passportization.waterSupplySewerageHeatingArea')}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  disabled={exploitation}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'lightingPointsCount'}
                label={t('passportization.lightingPointsCount')}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  disabled={exploitation}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
            <Button
              disabled={confirmLoading}
              className={'institution-form__btn'}
              type="primary"
              htmlType="submit"
            >
              {params.edit ? t('general.edit') : t('general.add')}
            </Button>
            <Link
              to={`/organization/${params.id}/passportization`}
              className={'ant-btn ant-btn-default'}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Link>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  )
}

export default OrganizationPassportForm
