import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IEducationDegree } from '@app/interfaces/education/education-degree'
import EducationDegreeForm from '@app/pages/reference-book-page-details/education-degree/components/education-degree-form'
import { getEducationDegrees } from '@app/store/actions/education-degree-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _educationDegrees: IEducationDegree[]
  _state: string
}

const ReferenceBookEducationDegrees: React.FC<props> = ({
  _educationDegrees,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('educationDegree.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_educationDegrees))
      dispatch(getEducationDegrees({ source }, t('educationDegree.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('educationDegree.educationDegree'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <EducationDegreeForm
            edit={true}
            educationDegree={record.educationDegree}
          />
        ) : null,
    },
  ]

  const data = !isEmpty(_educationDegrees)
    ? _educationDegrees.map((educationDegree) => {
        return {
          key: educationDegree.id,
          id: educationDegree.id,
          name: localizeData(educationDegree?.data)?.name,
          status: [educationDegree.status],
          educationDegree: educationDegree,
        }
      })
    : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <EducationDegreeForm />
          ) : null
        }
        title={t('educationDegree.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _state: state.educationDegrees.state,
    _educationDegrees: state.educationDegrees.educationDegrees,
  }
}

export default connect(mapStateToProps)(ReferenceBookEducationDegrees)
