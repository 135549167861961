import useLocalization from '@app/hooks/useLocalization'
import { IHeatingSystemSource } from '@app/interfaces/organization-passport/heating-system-source'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  heatingSystemSources: IHeatingSystemSource[]
}

const HeatingSystemSourcesItem: React.FC<props> = ({
  heatingSystemSources,
}) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('heatingSystemSource.heatingSystemSource')}
        name={'heatingSystemSource'}
      >
        <Select>
          {sortByName(localize(heatingSystemSources)).map(
            (heatingSystemSource, index) => {
              if (heatingSystemSource.status) {
                return (
                  <Select.Option
                    key={index}
                    value={heatingSystemSource.id!.toString()}
                  >
                    {heatingSystemSource?.data?.name}
                  </Select.Option>
                )
              }
              return null
            },
          )}
        </Select>
      </Form.Item>
    </>
  )
}

export default HeatingSystemSourcesItem
