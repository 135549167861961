import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import regionRepository from '@app/api/repositories/region-repository'
import { IRegionActions } from '@app/interfaces/organization/region'
import {
  CLEAR_REGION,
  CREATE_REGION,
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_REQUEST,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGION_FAILURE,
  FETCH_REGION_REQUEST,
  FETCH_REGION_SUCCESS,
  UPDATE_REGION,
} from '@app/store/types/regions'
import axios from 'axios'

export const getRegion = (args: numberArgs, message) => async (dispatch) => {
  dispatch(fetchRegionRequest())
  await regionRepository
    .getOne(args)
    .then((response) => {
      dispatch(fetchRegionSuccess(response.data))
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        dispatch(fetchRegionFailure(error?.message, error?.code))
      }
    })
}

export const getRegions =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchRegionsRequest())
    await regionRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchRegionsSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchRegionsFailure(error?.message, error?.code))
        }
      })
  }

const fetchRegionsRequest = (): IRegionActions => {
  return {
    type: FETCH_REGIONS_REQUEST,
  }
}

const fetchRegionsSuccess = (regions, count): IRegionActions => {
  return {
    type: FETCH_REGIONS_SUCCESS,
    payload: { regions, count },
  }
}

const fetchRegionsFailure = (message, code): IRegionActions => {
  return {
    type: FETCH_REGIONS_FAILURE,
    payload: { message, code },
  }
}

const fetchRegionRequest = (): IRegionActions => {
  return {
    type: FETCH_REGION_REQUEST,
  }
}

const fetchRegionSuccess = (region): IRegionActions => {
  return {
    type: FETCH_REGION_SUCCESS,
    payload: region,
  }
}

const fetchRegionFailure = (message, code): IRegionActions => {
  return {
    type: FETCH_REGION_FAILURE,
    payload: { message, code },
  }
}

export function clearRegion() {
  return {
    type: CLEAR_REGION,
    payload: null,
  }
}

export function updateRegion(updatedLang): IRegionActions {
  return {
    type: UPDATE_REGION,
    payload: updatedLang,
  }
}
export function createRegion(lang): IRegionActions {
  return {
    type: CREATE_REGION,
    payload: lang,
  }
}
