import employeeStatusRepository from '@app/api/repositories/employee-status-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import { IEmployeeStatusActions } from '@app/interfaces/employee/employee-status'
import {
  CLEAR_EMPLOYEE_STATUSES,
  CREATE_EMPLOYEE_STATUSES,
  DELETE_EMPLOYEE_STATUSES,
  FETCH_EMPLOYEE_STATUSES_FAILURE,
  FETCH_EMPLOYEE_STATUSES_REQUEST,
  FETCH_EMPLOYEE_STATUSES_SUCCESS,
  FETCH_EMPLOYEE_STATUS_FAILURE,
  FETCH_EMPLOYEE_STATUS_REQUEST,
  FETCH_EMPLOYEE_STATUS_SUCCESS,
  UPDATE_EMPLOYEE_STATUSES,
} from '@app/store/types/employee-status'
import axios from 'axios'

export const getEmployeeStatus =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeeStatusRequest())
    await employeeStatusRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchEmployeeStatusSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeeStatusFailure(error?.message, error?.code))
        }
      })
  }

export const getEmployeeStatuses =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchEmployeeStatusesRequest())
    await employeeStatusRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchEmployeeStatusesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeeStatusesFailure(error?.message, error?.code))
        }
      })
  }

const fetchEmployeeStatusesRequest = (): IEmployeeStatusActions => {
  return {
    type: FETCH_EMPLOYEE_STATUSES_REQUEST,
  }
}

const fetchEmployeeStatusesSuccess = (
  employeeStatuses,
  count,
): IEmployeeStatusActions => {
  return {
    type: FETCH_EMPLOYEE_STATUSES_SUCCESS,
    payload: { employeeStatuses, count },
  }
}

const fetchEmployeeStatusesFailure = (
  message,
  code,
): IEmployeeStatusActions => {
  return {
    type: FETCH_EMPLOYEE_STATUSES_FAILURE,
    payload: { message, code },
  }
}

const fetchEmployeeStatusRequest = (): IEmployeeStatusActions => {
  return {
    type: FETCH_EMPLOYEE_STATUS_REQUEST,
  }
}

const fetchEmployeeStatusSuccess = (employeeStatus): IEmployeeStatusActions => {
  return {
    type: FETCH_EMPLOYEE_STATUS_SUCCESS,
    payload: employeeStatus,
  }
}

const fetchEmployeeStatusFailure = (message, code): IEmployeeStatusActions => {
  return {
    type: FETCH_EMPLOYEE_STATUS_FAILURE,
    payload: { message, code },
  }
}

export function clearEmployeeStatuses() {
  return {
    type: CLEAR_EMPLOYEE_STATUSES,
    payload: null,
  }
}

export function createEmployeeStatus(employeeStatus): IEmployeeStatusActions {
  return {
    type: CREATE_EMPLOYEE_STATUSES,
    payload: employeeStatus,
  }
}

export function updateEmployeeStatus(employeeStatus): IEmployeeStatusActions {
  return {
    type: UPDATE_EMPLOYEE_STATUSES,
    payload: employeeStatus,
  }
}

export function deleteEmployeeStatus(employeeStatus): IEmployeeStatusActions {
  return {
    type: DELETE_EMPLOYEE_STATUSES,
    payload: employeeStatus,
  }
}
