import { AUTH_TOKEN } from '@app/constants/authToken'
import {
  IMealType,
  IMeasurement,
  IMenu,
  IMenuByPeriod,
  IMenuByPupil,
  IMenuSeason,
  IOrganizationMenuUpdateMenuMealsPayload,
  IProducts,
} from '@app/interfaces/organization-menu'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import { IMeals } from '../../interfaces/organization-menu'
import bbitApi from '../make-request'
import { IPathParamsArgs, numberArgs } from './i-repository'

class OrganizationMenuRepository {
  getAllSeasons(args: IPathParamsArgs): Promise<AxiosResponse<IMenuSeason[]>> {
    return bbitApi.get(`/menu/api/v1/periodicalMenus`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getSeason(args: numberArgs): Promise<AxiosResponse<IMenuSeason>> {
    return bbitApi.get(`/menu/api/v1/periodicalMenus/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  getAllMenus(args: IPathParamsArgs): Promise<AxiosResponse<IMenu[]>> {
    return bbitApi.get(`/menu/api/v1/dailyMenus`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getMenu(args: numberArgs): Promise<AxiosResponse<IMenu>> {
    return bbitApi.get(`/menu/api/v1/dailyMenus/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  removeMenu(args: numberArgs): Promise<AxiosResponse<any>> {
    return bbitApi.delete(`/menu/api/v1/dailyMenus/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  getAllMealTypes(args: IPathParamsArgs): Promise<AxiosResponse<IMealType[]>> {
    return bbitApi.get(`/menu/api/v1/mealTypes`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getMealType(args: numberArgs): Promise<AxiosResponse<IMealType>> {
    return bbitApi.get(`/menu/api/v1/mealTypes/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  removeMealType(args: numberArgs): Promise<AxiosResponse<any>> {
    return bbitApi.delete(`/menu/api/v1/mealTypes/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  getAllMeals(args: IPathParamsArgs): Promise<AxiosResponse<IMeals[]>> {
    return bbitApi.get(`/menu/api/v1/meals`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getMeal(args: numberArgs): Promise<AxiosResponse<IMeals>> {
    return bbitApi.get(`/menu/api/v1/meals/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  removeMeal(args: numberArgs): Promise<AxiosResponse<any>> {
    return bbitApi.delete(`/menu/api/v1/meals/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  getAllProducts(args: IPathParamsArgs): Promise<AxiosResponse<IProducts[]>> {
    return bbitApi.get(`/menu/api/v1/products`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getProduct(args: numberArgs): Promise<AxiosResponse<IProducts>> {
    return bbitApi.get(`/menu/api/v1/products/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  removeProduct(args: numberArgs): Promise<AxiosResponse<any>> {
    return bbitApi.delete(`/menu/api/v1/products/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  getAllMeasurements(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IMeasurement[]>> {
    return bbitApi.get(`/menu/api/v1/measurements`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getMeasurement(args: numberArgs): Promise<AxiosResponse<IMeasurement>> {
    return bbitApi.get(`/menu/api/v1/measurements/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  removeMeasurement(args: numberArgs): Promise<AxiosResponse<any>> {
    return bbitApi.delete(`/menu/api/v1/measurements/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  updateMenuMeals(
    menuId: number,
    data: IOrganizationMenuUpdateMenuMealsPayload,
  ): Promise<AxiosResponse<any>> {
    const token = localStorage.getItem(AUTH_TOKEN)
    return bbitApi.put(
      `https://api.bbit.uz/menu/api/v1/dailyMenus/${menuId}`,
      data,
    )
  }

  addSeason(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.post(`/menu/api/v1/periodicalMenus`, data)
  }

  updateSeason(id: number, data: any): Promise<AxiosResponse<any>> {
    return bbitApi.put(`/menu/api/v1/periodicalMenus/${id}`, data)
  }

  addMenu(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.post(`/menu/api/v1/dailyMenus`, data)
  }

  updateMenu(id: number, data: any): Promise<AxiosResponse<any>> {
    return bbitApi.put(`/menu/api/v1/dailyMenus/${id}`, data)
  }

  addMenuMealType(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.post(`/menu/api/v1/mealTypes`, data)
  }

  updateMenuMealType(id: number, data: any): Promise<AxiosResponse<any>> {
    return bbitApi.put(`/menu/api/v1/mealTypes/${id}`, data)
  }

  addMenuMeal(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.post(`/menu/api/v1/meals`, data)
  }

  updateMenuMeal(id: number, data: any): Promise<AxiosResponse<any>> {
    return bbitApi.put(`/menu/api/v1/meals/${id}`, data)
  }

  addMenuProduct(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.post(`/menu/api/v1/products`, data)
  }

  updateMenuProduct(id: number, data: any): Promise<AxiosResponse<any>> {
    return bbitApi.put(`/menu/api/v1/products/${id}`, data)
  }

  addMenuMeasurement(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.post(`/menu/api/v1/measurements`, data)
  }

  updateMenuMeasurement(id: number, data: any): Promise<AxiosResponse<any>> {
    return bbitApi.put(`/menu/api/v1/measurements/${id}`, data)
  }

  getMenuByPupil(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IMenuByPupil[]>> {
    return bbitApi.get(`/menu/api/v1/menuOfWorkDate/organization`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getMenuByPeriod(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IMenuByPeriod[]>> {
    return bbitApi.get(`/menu/api/v1/menuOfWorkDate`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }
}

export default new OrganizationMenuRepository()
