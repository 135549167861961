import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { ILang } from '@app/interfaces/languages'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { createApi } from '@reduxjs/toolkit/query/react'
import localforage from 'localforage'
import { IRtkResponse } from '../types'

export const languageApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'languageApi',
  tagTypes: [ApiKeys.language],
  endpoints: (build) => ({
    getLanguages: build.query<IRtkResponse<ILang[]>, URLSearchParams | string>({
      query: (args = '') => ({
        url: 'dictionary/api/v1/languages',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: ILang[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.language,
                id,
              })),
              { type: ApiKeys.language, id: 'LIST' },
            ]
          : [{ type: ApiKeys.language, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.language, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getLanguage: build.query<ILang, number>({
      query: (id) => ({
        url: `dictionary/api/v1/languages/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.language, id }],
    }),
  }),
})

export const { useGetLanguagesQuery, useGetLanguageQuery } = languageApi
