import { ApiKeys } from '@app/constants/api-keys'
import { IPathParams } from '@app/constants/path-params'
import useLocalization from '@app/hooks/useLocalization'
import { useGetEducationGroupQuery } from '@app/store/rtk/api/education/education-group-api'
import { useGetEmployeeQuery } from '@app/store/rtk/api/employee/employee-api'
import { formatString } from '@app/utils/format-string/format-string'
import { Card, Descriptions } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import GroupInfoDescription from './group-info-description'

const GroupInfo: React.FC = () => {
  const params: IPathParams = useParams()
  const [t] = useTranslation()
  const { localizeData, localizeLocationData, localizeWeeklyData } =
    useLocalization()
  const { data: group } = useGetEducationGroupQuery(Number(params?.groupId))
  const { data: employee } = useGetEmployeeQuery(Number(group?.employeeId), {
    skip: !group?.employeeId,
  })
  return (
    <>
      <Card title={`${group?.name}`} className="margin-bottom">
        <Descriptions size={'middle'} column={1} bordered>
          <Descriptions.Item label={'ID'}>
            {formatString(group?.id)}
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGroup.educationGroup')}>
            {formatString(group?.name)}
          </Descriptions.Item>
          <Descriptions.Item label={t('organization.organization')}>
            {group?.organizationId ? (
              <Link to={`/organization/${group?.organizationId}/page`}>
                {group?.organizationId}
              </Link>
            ) : (
              ''
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t('general.status')}>
            {group?.status === 'OPEN'
              ? t('general.active')
              : t('general.inActive')}
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGrade.educationGrade')}>
            <GroupInfoDescription
              apiKey={ApiKeys.educationGrade}
              id={group?.educationGradeId}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGroupType.title')}>
            {localizeData(group?.type?.data)?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGroupLocation.title')}>
            {localizeLocationData(group?.location?.data)?.location}
          </Descriptions.Item>
          <Descriptions.Item label={t('educationDailySchedule.title')}>
            {localizeData(group?.dailyRoutine?.data)?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('educationWeeklySchedule.title')}>
            {localizeWeeklyData(group?.weeklyWorkSchedule?.data)?.duration}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('institutionSchedule.institutionSchedule')}
          >
            <GroupInfoDescription
              apiKey={ApiKeys.institutionSchedule}
              id={group?.institutionScheduleId}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t('educationLanguage.educationLanguage')}>
            <GroupInfoDescription
              apiKey={ApiKeys.educationLanguage}
              id={group?.educationLanguageId}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGroup.tutor')}>
            <Link
              to={`/organization/${group?.organizationId}/employees/employee/${group?.employeeId}`}
            >
              {`${employee?.firstName || ''} ${employee?.lastName || ''}`}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGroup.groupCapacity')}>
            {formatString(group?.groupCapacity)}
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGroup.groupChildren')}>
            {formatString(group?.groupChildren)}
          </Descriptions.Item>
          <Descriptions.Item label={t('educationGroup.groupFreeSeats')}>
            {formatString(group?.groupFreeSeats)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  )
}

export default GroupInfo
