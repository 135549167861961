import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useUserManageRoles = () => {
  const [t] = useTranslation()
  const [userRoles] = useState([
    {
      id: 1,
      name: t('userRoles.manageReferences'),
      manage: 'manageReferences',
      roles: [
        { id: 10, name: 'ROLE_SETTING_DICTIONARY_VIEW' },
        { id: 11, name: 'ROLE_SETTING_DICTIONARY_EDIT' },
        { id: 12, name: 'ROLE_SETTING_DICTIONARY_DELETE' },
      ],
    },
    {
      id: 2,
      name: t('userRoles.manageOrganizations'),
      manage: 'manageOrganizations',
      roles: [
        { id: 13, name: 'ROLE_ORGANIZATION_ALL_VIEW' },
        { id: 14, name: 'ROLE_ORGANIZATION_REGION_VIEW' },
        { id: 15, name: 'ROLE_ORGANIZATION_DISTRICT_VIEW' },
        { id: 16, name: 'ROLE_ORGANIZATION_CURRENT_VIEW' },
        { id: 17, name: 'ROLE_ORGANIZATION_ALL_EDIT' },
        { id: 18, name: 'ROLE_ORGANIZATION_REGION_EDIT' },
        { id: 19, name: 'ROLE_ORGANIZATION_DISTRICT_EDIT' },
        { id: 20, name: 'ROLE_ORGANIZATION_CURRENT_EDIT' },
        { id: 78, name: 'ROLE_ORGANIZATION_CLASSIFICATOR_EDIT' },
        { id: 101, name: 'ROLE_ORGANIZATION_ALL_ADD' },
        { id: 102, name: 'ROLE_ORGANIZATION_EMISAPI_STATUS_CHANGE' },
        { id: 132, name: 'ROLE_ORGANIZATION_NAME_CHANGE' },
        { id: 133, name: 'ROLE_ORGANIZATION_CADASTRAL_NUMBER_CHANGE' },
        { id: 134, name: 'ROLE_ORGANIZATION_NEIGHBORHOOD_CHANGE' },
      ],
    },
    {
      id: 3,
      name: t('userRoles.manageEmployees'),
      manage: 'manageEmployees',
      roles: [
        { id: 21, name: 'ROLE_EMPLOYEE_ALL_VIEW' },
        { id: 22, name: 'ROLE_EMPLOYEE_REGION_VIEW' },
        { id: 23, name: 'ROLE_EMPLOYEE_DISTRICT_VIEW' },
        { id: 24, name: 'ROLE_EMPLOYEE_CURRENT_VIEW' },
        { id: 25, name: 'ROLE_EMPLOYEE_ALL_EDIT' },
        { id: 26, name: 'ROLE_EMPLOYEE_REGION_EDIT' },
        { id: 27, name: 'ROLE_EMPLOYEE_DISTRICT_EDIT' },
        { id: 28, name: 'ROLE_EMPLOYEE_CURRENT_EDIT' },
      ],
    },
    {
      id: 4,
      name: t('userRoles.managePupils'),
      manage: 'managePupils',
      roles: [
        { id: 29, name: 'ROLE_PUPIL_ALL_VIEW' },
        { id: 30, name: 'ROLE_PUPIL_REGION_VIEW' },
        { id: 31, name: 'ROLE_PUPIL_DISTRICT_VIEW' },
        { id: 32, name: 'ROLE_PUPIL_CURRENT_VIEW' },
        { id: 33, name: 'ROLE_PUPIL_ALL_EDIT' },
        { id: 34, name: 'ROLE_PUPIL_REGION_EDIT' },
        { id: 35, name: 'ROLE_PUPIL_DISTRICT_EDIT' },
        { id: 36, name: 'ROLE_PUPIL_CURRENT_EDIT' },
        { id: 37, name: 'ROLE_PUPIL_ALL_APPROVED' },
        { id: 38, name: 'ROLE_PUPIL_REGION_APPROVED' },
        { id: 39, name: 'ROLE_PUPIL_DISTRICT_APPROVED' },
        { id: 40, name: 'ROLE_PUPIL_CURRENT_APPROVED' },
        { id: 41, name: 'ROLE_PUPIL_ALL_DISMISS' },
        { id: 42, name: 'ROLE_PUPIL_REGION_DISMISS' },
        { id: 43, name: 'ROLE_PUPIL_DISTRICT_DISMISS' },
        { id: 44, name: 'ROLE_PUPIL_CURRENT_DISMISS' },
      ],
    },
    {
      id: 5,
      name: t('userRoles.manageGroups'),
      manage: 'manageGroups',
      roles: [
        { id: 45, name: 'ROLE_EDUCATION_GROUP_ALL_VIEW' },
        { id: 46, name: 'ROLE_EDUCATION_GROUP_REGION_VIEW' },
        { id: 47, name: 'ROLE_EDUCATION_GROUP_DISTRICT_VIEW' },
        { id: 48, name: 'ROLE_EDUCATION_GROUP_CURRENT_VIEW' },
        { id: 49, name: 'ROLE_EDUCATION_GROUP_ALL_EDIT' },
        { id: 50, name: 'ROLE_EDUCATION_GROUP_REGION_EDIT' },
        { id: 51, name: 'ROLE_EDUCATION_GROUP_DISTRICT_EDIT' },
        { id: 52, name: 'ROLE_EDUCATION_GROUP_CURRENT_EDIT' },
        { id: 87, name: 'ROLE_EDUCATION_GROUP_CLASSIFICATOR_EDIT' },
      ],
    },
    {
      id: 6,
      name: t('userRoles.manageReports'),
      manage: 'manageReports',
      roles: [
        { id: 54, name: 'RR_CHILD_COUNT_ON_QUEUE_BY_AGE' },
        { id: 55, name: 'RR_CHILD_COUNT_BY_AGE' },
        { id: 56, name: 'RR_CHILD_COUNT_BY_INSTITUTION' },
        { id: 69, name: 'RR_CHILD_COUNT_BY_GROUP' },
        {
          id: 103,
          name: 'RR_CONTRACT_N_CHILD_INFO_OF_PARTNERSHIP_ORGANIZATIONS',
        },
        { id: 104, name: 'RR_APPS_COUNT_ON_QUEUE_BY_CHILD_AGE' },
        { id: 118, name: 'RR_HEIGHT_WEIGHT_DATA_ENTRY_STATUS_BY_GROUPS' },
      ],
    },
    {
      id: 7,
      name: t('userRoles.manageAttendance'),
      manage: 'manageAttendance',
      roles: [
        { id: 57, name: 'ROLE_ATTENDANCE_ALL_VIEW' },
        { id: 58, name: 'ROLE_ATTENDANCE_REGION_VIEW' },
        { id: 59, name: 'ROLE_ATTENDANCE_DISTRICT_VIEW' },
        { id: 60, name: 'ROLE_ATTENDANCE_CURRENT_VIEW' },
      ],
    },
    {
      id: 8,
      name: t('userRoles.manageReasons'),
      manage: 'manageReasons',
      roles: [
        { id: 61, name: 'ROLE_ATTENDANCE_REASON_ALL_EDIT' },
        { id: 62, name: 'ROLE_ATTENDANCE_REASON_REGION_EDIT' },
        { id: 63, name: 'ROLE_ATTENDANCE_REASON_DISTRICT_EDIT' },
        { id: 64, name: 'ROLE_ATTENDANCE_REASON_CURRENT_EDIT' },
      ],
    },
    {
      id: 9,
      name: t('userRoles.manageMonitoring'),
      manage: 'manageMonitoring',
      roles: [
        { id: 65, name: 'ROLE_MONITOR_ATTENDANCE_ALL_VIEW' },
        { id: 66, name: 'ROLE_MONITOR_ATTENDANCE_REGION_VIEW' },
        { id: 67, name: 'ROLE_MONITOR_ATTENDANCE_DISTRICT_VIEW' },
        { id: 68, name: 'ROLE_MONITOR_ATTENDANCE_CURRENT_VIEW' },
      ],
    },
    {
      id: 10,
      name: t('userRoles.manageMenu'),
      manage: 'manageMenu',
      roles: [
        { id: 70, name: 'ROLE_MENU_ALL_VIEW' },
        { id: 71, name: 'ROLE_MENU_REGION_VIEW' },
        { id: 72, name: 'ROLE_MENU_DISTRICT_VIEW' },
        { id: 73, name: 'ROLE_MENU_CURRENT_VIEW' },
        { id: 74, name: 'ROLE_MENU_ALL_EDIT' },
        { id: 75, name: 'ROLE_MENU_REGION_EDIT' },
        { id: 76, name: 'ROLE_MENU_DISTRICT_EDIT' },
        { id: 77, name: 'ROLE_MENU_CURRENT_EDIT' },
      ],
    },
    {
      id: 11,
      name: t('userRoles.manageSubsidies'),
      manage: 'manageSubsidies',
      roles: [
        { id: 79, name: 'ROLE_CONTRACT_SUBSIDY_ALL_VIEW' },
        { id: 80, name: 'ROLE_CONTRACT_SUBSIDY_REGION_VIEW' },
        { id: 81, name: 'ROLE_CONTRACT_SUBSIDY_DISTRICT_VIEW' },
        { id: 82, name: 'ROLE_CONTRACT_SUBSIDY_CURRENT_VIEW' },
        { id: 83, name: 'ROLE_CONTRACT_SUBSIDY_ALL_EDIT' },
        { id: 84, name: 'ROLE_CONTRACT_SUBSIDY_REGION_EDIT' },
        { id: 85, name: 'ROLE_CONTRACT_SUBSIDY_DISTRICT_EDIT' },
        { id: 86, name: 'ROLE_CONTRACT_SUBSIDY_CURRENT_EDIT' },
      ],
    },
    {
      id: 12,
      name: t('userRoles.manageGrowth'),
      manage: 'manageGrowth',
      roles: [
        { id: 88, name: 'ROLE_HEIGHT_WEIGHT_ALL_VIEW' },
        { id: 89, name: 'ROLE_HEIGHT_WEIGHT_DISTRICT_VIEW' },
        { id: 90, name: 'ROLE_HEIGHT_WEIGHT_REGION_VIEW' },
        { id: 91, name: 'ROLE_HEIGHT_WEIGHT_CURRENT_VIEW' },
        { id: 92, name: 'ROLE_HEIGHT_WEIGHT_ALL_EDIT' },
        { id: 93, name: 'ROLE_HEIGHT_WEIGHT_REGION_EDIT' },
        { id: 94, name: 'ROLE_HEIGHT_WEIGHT_DISTRICT_EDIT' },
        { id: 95, name: 'ROLE_HEIGHT_WEIGHT_CURRENT_EDIT' },
        { id: 96, name: 'ROLE_HEIGHT_WEIGHT_STAT_ALL_VIEW' },
        { id: 97, name: 'ROLE_HEIGHT_WEIGHT_STAT_REGION_VIEW' },
        { id: 98, name: 'ROLE_HEIGHT_WEIGHT_STAT_DISTRICT_VIEW' },
        { id: 99, name: 'ROLE_HEIGHT_WEIGHT_STAT_CURRENT_VIEW' },
      ],
    },
    {
      id: 13,
      name: t('userRoles.manageFurniture'),
      manage: 'manageFurniture',
      roles: [{ id: 100, name: 'ROLE_FURNITURE_TEMP_VIEW' }],
    },
    {
      id: 14,
      name: t('userRoles.managePassportization'),
      manage: 'managePassportization',
      roles: [
        { id: 110, name: 'ROLE_PASSPORTIZATION_ALL_VIEW' },
        { id: 111, name: 'ROLE_PASSPORTIZATION_REGION_VIEW' },
        { id: 112, name: 'ROLE_PASSPORTIZATION_DISTRICT_VIEW' },
        { id: 113, name: 'ROLE_PASSPORTIZATION_CURRENT_VIEW' },
        { id: 114, name: 'ROLE_PASSPORTIZATION_ALL_EDIT' },
        { id: 115, name: 'ROLE_PASSPORTIZATION_REGION_EDIT' },
        { id: 116, name: 'ROLE_PASSPORTIZATION_DISTRICT_EDIT' },
        { id: 117, name: 'ROLE_PASSPORTIZATION_CURRENT_EDIT' },
      ],
    },
    {
      id: 15,
      name: t('userRoles.manageContacts'),
      manage: 'manageContacts',
      roles: [
        { id: 119, name: 'ROLE_ORGANIZATION_CONTACT_ALL_EDIT' },
        { id: 121, name: 'ROLE_ORGANIZATION_CONTACT_REGION_EDIT' },
        { id: 122, name: 'ROLE_ORGANIZATION_CONTACT_DISTRICT_EDIT' },
        { id: 120, name: 'ROLE_ORGANIZATION_CONTACT_CURRENT_EDIT' },
      ],
    },
    {
      id: 16,
      name: t('userRoles.manageStaff'),
      manage: 'manageStaff',
      roles: [
        { id: 123, name: 'ROLE_STAFF_TABLE_ALL_VIEW' },
        { id: 124, name: 'ROLE_STAFF_TABLE_REGION_VIEW' },
        { id: 125, name: 'ROLE_STAFF_TABLE_DISTRICT_VIEW' },
        { id: 126, name: 'ROLE_STAFF_TABLE_CURRENT_VIEW ' },
      ],
    },
    {
      id: 17,
      name: t('userRoles.manageAdmin'),
      manage: 'manageAdmin',
      roles: [
        { id: 127, name: 'ROLE_APPLICATION_ALL_CANCEL' },
        { id: 128, name: 'ROLE_PLANNER_HISTORY_VIEW' },
        { id: 129, name: 'ROLE_PLANNER_CURRENT_STATUS_VIEW' },
        { id: 130, name: 'ROLE_PLANNER_MANAGEMENT' },
        { id: 131, name: 'ROLE_APPLICATION_SEND' },
      ],
    },
    {
      id: 18,
      name: t('userRoles.manageDashboard'),
      manage: 'manageDashboard',
      roles: [{ id: 135, name: 'ROLE_DASH1_CURRENT_VIEW' }],
    },
  ])

  return userRoles
}

export default useUserManageRoles
