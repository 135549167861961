import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IInstitutionSchedule,
  IInstitutionScheduleState,
} from '@app/interfaces/organization/institution-schedule'
import {
  CLEAR_INSTITUTION_SCHEDULE,
  CREATE_INSTITUTION_SCHEDULE,
  DELETE_INSTITUTION_SCHEDULE,
  FETCH_INSTITUTION_SCHEDULES_FAILURE,
  FETCH_INSTITUTION_SCHEDULES_REQUEST,
  FETCH_INSTITUTION_SCHEDULES_SUCCESS,
  FETCH_INSTITUTION_SCHEDULE_FAILURE,
  FETCH_INSTITUTION_SCHEDULE_REQUEST,
  FETCH_INSTITUTION_SCHEDULE_SUCCESS,
  UPDATE_INSTITUTION_SCHEDULE,
} from '@app/store/types/institutions-schedule'

const initialState: IInstitutionScheduleState = {
  institutionSchedules: [],
  institutionSchedule: {} as IInstitutionSchedule,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const institutionSchedulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTITUTION_SCHEDULES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_INSTITUTION_SCHEDULES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        institutionSchedules: action.payload.institutionSchedules,
        count: action.payload.count,
      }
    case FETCH_INSTITUTION_SCHEDULES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        institutionSchedules: [],
      }
    case FETCH_INSTITUTION_SCHEDULE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_INSTITUTION_SCHEDULE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        institutionSchedule: action.payload,
      }
    case FETCH_INSTITUTION_SCHEDULE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        institutionSchedule: {},
      }
    case CLEAR_INSTITUTION_SCHEDULE:
      return { ...state, institutionSchedules: [] }
    case CREATE_INSTITUTION_SCHEDULE:
      return {
        ...state,
        institutionSchedules: [...state.institutionSchedules, action.payload],
      }
    case UPDATE_INSTITUTION_SCHEDULE:
      const updatedInstitutionSchedules = state.institutionSchedules.map(
        function (institutionSchedule) {
          if (action.payload.id === institutionSchedule.id) {
            return action.payload
          }
          return institutionSchedule
        },
      )
      return { ...state, institutionSchedules: updatedInstitutionSchedules }
    case DELETE_INSTITUTION_SCHEDULE:
      const editedInstitutionSchedules = state.institutionSchedules.filter(
        function (institutionSchedule) {
          if (action.payload.id === institutionSchedule.id) {
            return false
          }
          return institutionSchedule
        },
      )
      return { ...state, institutionSchedules: editedInstitutionSchedules }
    default:
      return state
  }
}
