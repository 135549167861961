import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEmployeeRate,
  IEmployeeRateState,
} from '@app/interfaces/employee/employee-rate'
import {
  CLEAR_EMPLOYEE_RATE,
  CREATE_EMPLOYEE_RATE,
  DELETE_EMPLOYEE_RATE,
  FETCH_EMPLOYEE_RATES_FAILURE,
  FETCH_EMPLOYEE_RATES_REQUEST,
  FETCH_EMPLOYEE_RATES_SUCCESS,
  FETCH_EMPLOYEE_RATE_FAILURE,
  FETCH_EMPLOYEE_RATE_REQUEST,
  FETCH_EMPLOYEE_RATE_SUCCESS,
  UPDATE_EMPLOYEE_RATE,
} from '@app/store/types/employee-rate'

const initialState: IEmployeeRateState = {
  employeeRate: {} as IEmployeeRate,
  employeeRates: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const employeeRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_RATES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_RATES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeeRates: action.payload.employeeRates,
        count: action.payload.count,
      }
    case FETCH_EMPLOYEE_RATES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeeRates: [],
      }
    case FETCH_EMPLOYEE_RATE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_RATE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeeRate: action.payload,
      }
    case FETCH_EMPLOYEE_RATE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeeRate: {},
      }
    case CLEAR_EMPLOYEE_RATE:
      return { ...state, employeeRates: [] }
    case CREATE_EMPLOYEE_RATE:
      return {
        ...state,
        employeeRates: [...state.employeeRates, action.payload],
      }
    case UPDATE_EMPLOYEE_RATE:
      const updatedEmployeeRates = state.employeeRates.map(function (
        employeeRate,
      ) {
        if (action.payload.id === employeeRate.id) {
          return action.payload
        }
        return employeeRate
      })
      return { ...state, employeeRates: updatedEmployeeRates }
    case DELETE_EMPLOYEE_RATE:
      const editedEmployeeRates = state.employeeRates.filter(function (
        employeeRate,
      ) {
        if (action.payload.id === employeeRate.id) {
          return false
        }
        return employeeRate
      })
      return { ...state, employeeRates: editedEmployeeRates }
    default:
      return state
  }
}
