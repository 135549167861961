import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IPupilCreateFull,
  numberArgs,
} from '@api/repositories/i-repository'
import { AUTH_TOKEN } from '@app/constants/authToken'
import { IPupil, IPupilsPhotoChange } from '@app/interfaces/pupil/pupils'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import axios, { AxiosResponse } from 'axios'

class PupilsRepository implements IPupilCreateFull<IPupil> {
  create(data: IPupil): Promise<AxiosResponse<IPupil>> {
    return bbitApi.post('/pupil/api/v1/pupils', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IPupil[]>> {
    return bbitApi.get(`/pupil/api/v1/pupils`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IPupil>> {
    return bbitApi.get(`/pupil/api/v1/pupils/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IPupil | IPupilsPhotoChange): Promise<AxiosResponse<IPupil>> {
    return bbitApi.put(`/pupil/api/v1/pupils/${data.id}`, data)
  }

  partialUpdate(data: any): Promise<AxiosResponse<IPupil>> {
    return bbitApi.patch(`pupil/api/v1/pupils/${data.id}`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/merge-patch+json',
      },
    })
  }

  createFull(data: IPupil): Promise<AxiosResponse<IPupil>> {
    return bbitApi.post('/pupil/api/v1/pupils/create-full', data)
  }

  removeParent(data: IPupil): Promise<AxiosResponse<IPupil>> {
    return bbitApi.put(`/pupil/api/v1/pupils/remove-parent/${data.id}`, {
      data: data,
    })
  }

  updateFull(data: IPupil): Promise<AxiosResponse<IPupil>> {
    return bbitApi.put(`/pupil/api/v1/pupils/update-full/${data.id}`, data)
  }
}

export default new PupilsRepository()
