export const ApiKeys = {
  organizationSchedule: 'organizationSchedule',
  organizationSector: 'organizationSector',
  educationGroupType: 'educationGroupType',
  educationDailySchedule: 'educationDailyRoutine',
  educationWeeklySchedule: 'educationWeeklySchedule',
  educationGroupLocation: 'educationGroupLocation',
  educationGrade: 'educationGrade',
  educationLanguage: 'educationLanguage',
  institutionSchedule: 'institutionSchedule',
  employeePosition: 'employeePosition',
  educationGroup: 'educationGroup',
  organizationsStatus: 'organizationStatus',
  organizationLocality: 'organizationLocality',
  organizationType: 'organizationType',
  institutionType: 'institutionType',
  institutionSubType: 'institutionSubType',
  organizationBuildingType: 'organizationBuildingType',
  region: 'region',
  district: 'district',
  neighborhood: 'neighborhood',
  organization: 'organization',
  userInfo: 'userInfo',
  organizationContact: 'organizationContact',
  language: 'language',
  organizationPassport: 'organizationPassport',
  employee: 'employee',
  employeeStatus: 'employeeStatus',
  employeeRate: 'employeeRate',
  employeePositionDictionary: 'employeePositionDictionary',
  organizationPosition: 'organizationPosition',
  nationality: 'nationality',
  organizationGrowth: 'organizationGrowth',
  organizationPupil: 'organizationPupil',
  drinkingWater: 'drinkingWater',
  electricity: 'electricity',
  foodPreparingPlaces: 'foodPreparingPlaces',
  heatingSystemSource: 'heatingSystemSource',
  hotWaterSource: 'hotWaterSource',
  medicalStaff: 'medicalStaff',
  ownership: 'ownership',
  solidWasteRemovings: 'solidWasteRemovings',
  stayDuration: 'stayDuration',
  toiletCleaning: 'toiletCleaning',
  toiletLocation: 'toiletLocation',
  toiletType: 'toiletType',
  waterCleaningMethod: 'waterCleaningMethod',
  waterSource: 'waterSource',
  organizationPassportization: 'organizationPassportization',
  reasons: 'reasons',
  outsourcingCompany: 'outsourcingCompany',
  workforce: 'workforce',
}
