import bbitApi from '@api/make-request'
import {
  IEmployeePositionRepository,
  IPathParamsArgs,
  numberArgs,
} from '@api/repositories/i-repository'
import { IEmployeePosition } from '@app/interfaces/employee/employee-position'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class EmployeePositionRepository
  implements IEmployeePositionRepository<IEmployeePosition>
{
  create(data: IEmployeePosition): Promise<AxiosResponse<IEmployeePosition>> {
    return bbitApi.post('/employee/api/v1/employee-position-dictionary', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEmployeePosition[]>> {
    return bbitApi.get('/employee/api/v1/employee-position-dictionary', {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEmployeePosition>> {
    return bbitApi.get(
      `/employee/api/v1/employee-position-dictionary/${args.data}`,
      { cancelToken: args.source?.token },
    )
  }

  update(data: IEmployeePosition): Promise<AxiosResponse<IEmployeePosition>> {
    return bbitApi.put(
      `/employee/api/v1/employee-position-dictionary/${data.id}`,
      data,
    )
  }

  getAllPublic(): Promise<AxiosResponse<IEmployeePosition[]>> {
    return bbitApi.get('/employee/api/v1/employee-position-dictionary/public')
  }
}

export default new EmployeePositionRepository()
