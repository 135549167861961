import useLocalization from '@app/hooks/useLocalization'
import { IToiletCleanings } from '@app/interfaces/organization-passport/toilet-cleanings'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  toiletCleanings: IToiletCleanings[]
}

const ToiletCleaningsItem: React.FC<props> = ({ toiletCleanings }) => {
  const [t, i18n] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item label={t('toiletCleaning.title')} name={'toiletCleaning'}>
        <Select placeholder={t('institutionPlaceholders.toiletCleaning')}>
          {sortByName(localize(toiletCleanings)).map(
            (toiletCleanings, index) => {
              if (toiletCleanings.status) {
                return (
                  <Select.Option
                    key={index}
                    value={toiletCleanings.id!.toString()}
                  >
                    {toiletCleanings?.data?.name}
                  </Select.Option>
                )
              }
              return null
            },
          )}
        </Select>
      </Form.Item>
    </>
  )
}

export default ToiletCleaningsItem
