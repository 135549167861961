import bbitApi from '@api/make-request'
import {
  IOrganizationRepository,
  IPathParamsArgs,
  numberArgs,
} from '@api/repositories/i-repository'
import { IOrganization } from '@app/interfaces/organization/organization'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class OrganizationRepository implements IOrganizationRepository<IOrganization> {
  create(data: IOrganization): Promise<AxiosResponse<IOrganization>> {
    return bbitApi.post('/dictionary/api/v1/organizations', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IOrganization[]>> {
    return bbitApi.get(`/dictionary/api/v1/organizations`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IOrganization>> {
    return bbitApi.get(`/dictionary/api/v1/organizations/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IOrganization): Promise<AxiosResponse<IOrganization>> {
    return bbitApi.put(`/dictionary/api/v1/organizations/${data.id}`, data)
  }

  createWithPassport(data: any): Promise<AxiosResponse<IOrganization>> {
    return bbitApi.post(
      '/dictionary/api/v1/organizations/create-organization-with-institution-passport',
      data,
    )
  }
}

export default new OrganizationRepository()
