import bbitApi from '@api/make-request'

export function getPupil(data) {
  return bbitApi.post('/pupil/api/v1/registry-office/get-pupil', data)
}
export function getForeignPupil(data) {
  return bbitApi.post(
    '/pupil/api/v1/registry-office/get-non-resident-pupil',
    data,
  )
}
