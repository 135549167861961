import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { langs } from '@app/constants/languages'

interface props {
  object: any
  confirmLoading: boolean
}

const FormList: React.FC<props> = ({ object, confirmLoading }) => {
  const [t] = useTranslation()
  return (
    <>
      <Form.Item
        className={'locale-item'}
        rules={[
          {
            required: true,
            message: t('errors.requiredMessage'),
          },
        ]}
        label={t('general.name')}
      >
        <Form.List name={'data'}>
          {() =>
            langs?.map(({ id, toShowCode }) => (
              <React.Fragment key={id}>
                <Form.Item
                  hidden={true}
                  name={[id, 'id']}
                  initialValue={
                    object?.data.find((lang) => lang.languageId === id)?.id
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[id, 'name']}
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                    {
                      min: 2,
                      message: t('errors.minMessage'),
                    },
                  ]}
                  initialValue={
                    object?.data.find((lang) => lang.languageId === id)?.name
                  }
                >
                  <Input disabled={confirmLoading} addonBefore={toShowCode} />
                </Form.Item>
                <Form.Item
                  hidden={true}
                  name={[id, 'languageId']}
                  initialValue={id}
                >
                  <Input />
                </Form.Item>
              </React.Fragment>
            ))
          }
        </Form.List>
      </Form.Item>
    </>
  )
}

export default FormList
