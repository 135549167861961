import {
  ADD,
  EDIT,
  READ,
  ADD_GOV_AGENCY,
  ADD_INSTITUTION,
} from '@app/store/types/form-state'

import { IFormActions } from '@app/interfaces/formState'

export function add(): IFormActions {
  return {
    type: ADD,
    payload: null,
  }
}

export function add_gov() {
  return {
    type: ADD_GOV_AGENCY,
    payload: null,
  }
}

export function add_institution() {
  return {
    type: ADD_INSTITUTION,
    payload: null,
  }
}

export function edit(): IFormActions {
  return {
    type: EDIT,
    payload: null,
  }
}

export function read(): IFormActions {
  return {
    type: READ,
    payload: null,
  }
}
