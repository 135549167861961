import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IInstitutionSubType,
  IInstitutionSubTypeState,
} from '@app/interfaces/organization/institution-subtype'
import {
  CLEAR_INSTITUTION_SUBTYPE,
  CLEAR_INSTITUTION_SUBTYPES,
  FETCH_INSTITUTION_SUBTYPES_FAILURE,
  FETCH_INSTITUTION_SUBTYPES_REQUEST,
  FETCH_INSTITUTION_SUBTYPES_SUCCESS,
  FETCH_INSTITUTION_SUBTYPE_CLEANUP,
  FETCH_INSTITUTION_SUBTYPE_FAILURE,
  FETCH_INSTITUTION_SUBTYPE_REQUEST,
  FETCH_INSTITUTION_SUBTYPE_SUCCESS,
  REFRESH_INSTITUTION_SUBTYPE,
} from '@app/store/types/institution-subtype'

const initialState: IInstitutionSubTypeState = {
  institutionSubTypes: [],
  institutionSubType: {} as IInstitutionSubType,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const institutionSubtypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_INSTITUTION_SUBTYPE:
      return { ...state, institutionSubType: {}, state: IDLE }
    case CLEAR_INSTITUTION_SUBTYPES:
      return { ...state, institutionSubTypes: [], state: IDLE }
    case REFRESH_INSTITUTION_SUBTYPE:
      return { ...state, refresh: !state.refresh }
    case FETCH_INSTITUTION_SUBTYPE_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_INSTITUTION_SUBTYPES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_INSTITUTION_SUBTYPES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        institutionSubTypes: action.payload.institutionSubTypes,
        count: action.payload.count,
      }
    case FETCH_INSTITUTION_SUBTYPES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        institutionSubTypes: [],
      }
    case FETCH_INSTITUTION_SUBTYPE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_INSTITUTION_SUBTYPE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        institutionSubType: action.payload,
      }
    case FETCH_INSTITUTION_SUBTYPE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        institutionSubType: {},
      }
    default:
      return state
  }
}
