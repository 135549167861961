import { PlusOutlined } from '@ant-design/icons'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { RootState } from '@app/store/store'
import { Modal, Upload } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './photo-item-manual.less'

interface props {
  fileList: UploadFile[]
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>
  mealName?: string
}

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = (error) => reject(error)
  })

const PhotoItemManual: React.FC<props> = ({
  fileList,
  setFileList,
  mealName,
}) => {
  const avatar = useSelector((state: RootState) => state.files.preUploadedPhoto)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [t] = useTranslation()
  const onChange = (file) => {
    setFileList(file.fileList)
    if (file.file.status === 'error') {
      openNotificationWithIcon('error', t('users.avatarError'))
    }
  }

  const beforeUpload = (file) => {
    return false
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewTitle(
      mealName ||
        file.name ||
        file.url.substring(file.url.lastIndexOf('/') + 1),
    )
    setPreviewVisible(true)
  }

  const onRemove = () => {
    setFileList([])
    return true
  }
  const handleCancel = () => setPreviewVisible(false)

  return (
    <div className="photo-item-manual">
      <p>{t('organizationMenu.menus.photoTitle')}</p>
      <Upload
        onChange={onChange}
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        listType={'picture-card'}
        fileList={fileList}
        onRemove={onRemove}
        maxCount={1}
      >
        {!fileList.length && (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{t('general.load')}</div>
          </div>
        )}
      </Upload>
      <Modal
        title={previewTitle}
        open={previewVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <img
          alt={avatar?.fileName}
          style={{ width: '100%' }}
          src={previewImage}
        />
      </Modal>
    </div>
  )
}

export default PhotoItemManual
