import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { IBenefitType, IBenefitTypeState } from '@app/interfaces/benefit-type'
import {
  CLEAR_BENEFIT_TYPE,
  CLEAR_BENEFIT_TYPES,
  CREATE_BENEFIT_TYPE,
  FETCH_BENEFIT_TYPES_FAILURE,
  FETCH_BENEFIT_TYPES_REQUEST,
  FETCH_BENEFIT_TYPES_SUCCESS,
  FETCH_BENEFIT_TYPE_CLEANUP,
  FETCH_BENEFIT_TYPE_FAILURE,
  FETCH_BENEFIT_TYPE_REQUEST,
  FETCH_BENEFIT_TYPE_SUCCESS,
  REFRESH_BENEFIT_TYPE,
  REMOVE_BENEFIT_TYPE,
  UPDATE_BENEFIT_TYPE,
} from '@app/store/types/benefit-type'

const initialState: IBenefitTypeState = {
  benefitTypes: [],
  benefitType: {} as IBenefitType,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const benefitTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_BENEFIT_TYPE:
      return { ...state, benefitType: {}, state: IDLE }
    case CLEAR_BENEFIT_TYPES:
      return { ...state, benefitTypes: [], state: IDLE }
    case REFRESH_BENEFIT_TYPE:
      return { ...state, refresh: !state.refresh }
    case CREATE_BENEFIT_TYPE:
      return { ...state, benefitTypes: [...state.benefitTypes, action.payload] }
    case UPDATE_BENEFIT_TYPE:
      const updatedBenefitTypes = state.benefitTypes.map(function (
        benefitType,
        index,
      ) {
        if (action.payload.id === benefitType.id) {
          return action.payload
        }
        return benefitType
      })
      return { ...state, benefitTypes: updatedBenefitTypes }
    case REMOVE_BENEFIT_TYPE:
      const editedBenefitTypes = state.benefitTypes.filter(function (
        benefitType,
      ) {
        if (action.payload.id === benefitType.id) {
          return false
        }
        return benefitType
      })
      return { ...state, benefitTypes: editedBenefitTypes }
    case FETCH_BENEFIT_TYPE_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_BENEFIT_TYPES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_BENEFIT_TYPES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        benefitTypes: action.payload.benefitTypes,
        count: action.payload.count,
      }
    case FETCH_BENEFIT_TYPES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        benefitTypes: [],
      }
    case FETCH_BENEFIT_TYPE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_BENEFIT_TYPE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        benefitType: action.payload,
      }
    case FETCH_BENEFIT_TYPE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        benefitType: {},
      }
    default:
      return state
  }
}
