import { PlusOutlined } from '@ant-design/icons'
import bbitApi from '@api/make-request'
import { AUTH_TOKEN } from '@app/constants/authToken'
import { host } from '@app/constants/env'
import { IFile } from '@app/interfaces/files'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { preLoadFile } from '@app/store/actions/files-actions'
import { message, Modal, Upload } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  isLoading: (state: boolean) => void
  avatar?: IFile
  upload?: boolean
  closeModal?: () => void
  onSubmit?: (id: number) => any
}

const PhotoItem: React.FC<props> = ({
  isLoading,
  avatar,
  closeModal,
  upload,
  onSubmit,
}) => {
  const [file, setFile] = useState(null)
  const [visible, setVisible] = useState<boolean>(false)
  const [t, i18n] = useTranslation()
  const token = localStorage.getItem(AUTH_TOKEN)
  const dispatch = useDispatch()
  const customRequest = (options: any) => {
    const data = new FormData()
    data.append('file', options.file)
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'content-type': 'multipart/form-data',
      },
    }
    bbitApi
      .post<AxiosResponse<IFile>>(options.action, data, config)
      .then((res: any) => {
        options.onSuccess(res.data, options.file)
        dispatch(preLoadFile(res.data))
        if (upload) {
          if (onSubmit) {
            onSubmit(res.data.id)
          }
          if (closeModal) {
            closeModal()
          }
        }
      })
      .catch((err: Error) => {
        isLoading(false)
        openNotificationWithIcon('error', t('users.avatarError'))
      })
  }

  const onChange = (info) => {
    setFile(info)
    if (info.file.status !== 'uploading') {
      isLoading(true)
    }
    if (info.file.status === 'done') {
      isLoading(false)
    }
    if (info.file.status === 'removed') {
      setFile(null)
      isLoading(false)
    } else if (info.file.status === 'error') {
      isLoading(false)
      openNotificationWithIcon('error', t('users.avatarError'))
    }
  }

  function beforeUpload(file) {
    isLoading(true)

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const handlePreview = () => setVisible(true)
  const onRemove = () => {
    isLoading(false)
    return true
  }

  return (
    <>
      <Upload
        onChange={onChange}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        listType={'picture-card'}
        onRemove={onRemove}
        maxCount={1}
        action={`${host}files/api/v1/files`}
      >
        {file === null ? (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{t('general.load')}</div>
          </div>
        ) : null}
      </Upload>
      <Modal
        title={t('users.avatar')}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <img
          alt={avatar?.fileName}
          style={{ width: '100%' }}
          src={`${host}/files/${avatar?.baseURL}/cropped-${avatar?.filePath}`}
        />
      </Modal>
    </>
  )
}

function mapStateToProps(state) {
  return {
    avatar: state.files.preUploadedPhoto,
  }
}

export default connect(mapStateToProps, null)(PhotoItem)
