import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationSectorRepository from '@app/api/repositories/organization-sector-repository'
import axios from 'axios'
import {
  CLEAR_CURRENT_ORGANIZATION_SECTOR_ID,
  CLEAR_ORGANIZATION_SECTOR,
  CLEAR_ORGANIZATION_SECTORS,
  FETCH_ORGANIZATION_SECTORS_FAILURE,
  FETCH_ORGANIZATION_SECTORS_REQUEST,
  FETCH_ORGANIZATION_SECTORS_SUCCESS,
  FETCH_ORGANIZATION_SECTOR_CLEANUP,
  FETCH_ORGANIZATION_SECTOR_FAILURE,
  FETCH_ORGANIZATION_SECTOR_REQUEST,
  FETCH_ORGANIZATION_SECTOR_SUCCESS,
  REFRESH_ORGANIZATION_SECTOR,
  SET_CURRENT_ORGANIZATION_SECTOR_ID,
} from '../types/organization-sector'

export const getOrganizationSectors =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationSectorsRequest())
    await organizationSectorRepository
      .getAll(args)
      .then((response) => {
        dispatch(
          fetchOrganizationSectorsSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationSectorsFailure(error?.message, error?.code))
        }
      })
    fetchOrganizationSectorCleanup()
  }

export const getOrganizationSector =
  (args: numberArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationSectorRequest())
    await organizationSectorRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationSectorSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationSectorFailure(error?.message, error?.code))
        }
      })
    fetchOrganizationSectorCleanup()
  }

const fetchOrganizationSectorsRequest = () => {
  return {
    type: FETCH_ORGANIZATION_SECTORS_REQUEST,
  }
}

const fetchOrganizationSectorsSuccess = (organizationSectors, count) => {
  return {
    type: FETCH_ORGANIZATION_SECTORS_SUCCESS,
    payload: { organizationSectors, count },
  }
}

const fetchOrganizationSectorsFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_SECTORS_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationSectorRequest = () => {
  return {
    type: FETCH_ORGANIZATION_SECTOR_REQUEST,
  }
}

const fetchOrganizationSectorSuccess = (organizationSector) => {
  return {
    type: FETCH_ORGANIZATION_SECTOR_SUCCESS,
    payload: organizationSector,
  }
}

const fetchOrganizationSectorFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_SECTOR_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationSectorCleanup = () => {
  return {
    type: FETCH_ORGANIZATION_SECTOR_CLEANUP,
  }
}

export function clearOrganizationSector() {
  return {
    type: CLEAR_ORGANIZATION_SECTOR,
  }
}

export function clearOrganizationSectors() {
  return {
    type: CLEAR_ORGANIZATION_SECTORS,
  }
}

export function refreshOrganizationSector() {
  return {
    type: REFRESH_ORGANIZATION_SECTOR,
  }
}

export function setCurrentOrganizationSectorId(id: number) {
  return {
    type: SET_CURRENT_ORGANIZATION_SECTOR_ID,
    payload: id,
  }
}

export function clearCurrentOrganizationSectorId() {
  return {
    type: CLEAR_CURRENT_ORGANIZATION_SECTOR_ID,
  }
}
