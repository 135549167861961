import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { TNeighborhoodActionType } from '@app/store/types/neighborhood'

const initialState = {
  neighborhoods: [],
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const neighborhoodReducer = (
  state: typeof initialState = initialState,
  action: {
    type: TNeighborhoodActionType
    payload: any
  },
) => {
  switch (action.type) {
    case 'GET_NEIGHBORHOODS_REQUEST':
      return {
        ...state,
        state: LOADING,
      }

    case 'GET_NEIGHBORHOODS_SUCCESS':
      return {
        ...state,
        state: SUCCEEDED,
        neighborhoods: action.payload,
      }
    case 'GET_NEIGHBORHOODS_FAILURE':
      return {
        message: action.payload.message,
        code: action.payload.code,
        state: FAILED,
      }
    default:
      return state
  }
}
