import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { IAttendanceMonitoringState } from './../../interfaces/organization-attendance-monitoring'
import {
  CLEAR_ATTENDANCE_MONITORING_DISTRICT,
  CLEAR_ATTENDANCE_MONITORING_GROUP,
  CLEAR_ATTENDANCE_MONITORING_ORG,
  CLEAR_ATTENDANCE_MONITORING_REGION,
  FETCH_ATTENDANCE_MONITORING_CLEANUP,
  FETCH_ATTENDANCE_MONITORING_DISTRICT_FAILED,
  FETCH_ATTENDANCE_MONITORING_DISTRICT_REQUEST,
  FETCH_ATTENDANCE_MONITORING_DISTRICT_SUCCESS,
  FETCH_ATTENDANCE_MONITORING_GROUP_FAILED,
  FETCH_ATTENDANCE_MONITORING_GROUP_REQUEST,
  FETCH_ATTENDANCE_MONITORING_GROUP_SUCCESS,
  FETCH_ATTENDANCE_MONITORING_ORG_FAILED,
  FETCH_ATTENDANCE_MONITORING_ORG_REQUEST,
  FETCH_ATTENDANCE_MONITORING_ORG_SUCCESS,
  FETCH_ATTENDANCE_MONITORING_REGION_FAILED,
  FETCH_ATTENDANCE_MONITORING_REGION_REQUEST,
  FETCH_ATTENDANCE_MONITORING_REGION_SUCCESS,
} from './../types/organization-attendance-monitoring'

const initialState: IAttendanceMonitoringState = {
  monitoringByOrg: [],
  monitoringByDistrict: [],
  monitoringByGroup: [],
  monitoringByRegion: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
  refresh: false,
}

export const organizationAttendanceMonitoringReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_MONITORING_ORG_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ATTENDANCE_MONITORING_ORG_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        monitoringByOrg: action.payload.monitoringByOrg,
        count: action.payload.count,
      }
    case FETCH_ATTENDANCE_MONITORING_ORG_FAILED:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        monitoringByOrg: [],
      }
    case FETCH_ATTENDANCE_MONITORING_GROUP_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ATTENDANCE_MONITORING_GROUP_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        monitoringByGroup: action.payload.monitoringByGroup,
        count: action.payload.count,
      }
    case FETCH_ATTENDANCE_MONITORING_GROUP_FAILED:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        monitoringByGroup: [],
      }
    case FETCH_ATTENDANCE_MONITORING_REGION_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ATTENDANCE_MONITORING_REGION_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        monitoringByRegion: action.payload.monitoringByRegion,
        count: action.payload.count,
      }
    case FETCH_ATTENDANCE_MONITORING_REGION_FAILED:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        monitoringByRegion: [],
      }
    case FETCH_ATTENDANCE_MONITORING_DISTRICT_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ATTENDANCE_MONITORING_DISTRICT_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        monitoringByDistrict: action.payload.monitoringByDistrict,
        count: action.payload.count,
      }
    case FETCH_ATTENDANCE_MONITORING_DISTRICT_FAILED:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        monitoringByDistrict: [],
      }
    case FETCH_ATTENDANCE_MONITORING_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case CLEAR_ATTENDANCE_MONITORING_ORG:
      return { ...state, monitoringByOrg: [], state: IDLE }
    case CLEAR_ATTENDANCE_MONITORING_GROUP:
      return { ...state, monitoringByGroup: [], state: IDLE }
    case CLEAR_ATTENDANCE_MONITORING_REGION:
      return { ...state, monitoringByRegion: [], state: IDLE }
    case CLEAR_ATTENDANCE_MONITORING_DISTRICT:
      return { ...state, monitoringByDistrict: [], state: IDLE }
    default:
      return state
  }
}
