import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationSchedule,
  IOrganizationScheduleState,
} from '@app/interfaces/organization/organization-schedule'
import {
  CLEAR_ORGANIZATION_SCHEDULE,
  CLEAR_ORGANIZATION_SCHEDULES,
  FETCH_ORGANIZATION_SCHEDULES_FAILURE,
  FETCH_ORGANIZATION_SCHEDULES_REQUEST,
  FETCH_ORGANIZATION_SCHEDULES_SUCCESS,
  FETCH_ORGANIZATION_SCHEDULE_CLEANUP,
  FETCH_ORGANIZATION_SCHEDULE_FAILURE,
  FETCH_ORGANIZATION_SCHEDULE_REQUEST,
  FETCH_ORGANIZATION_SCHEDULE_SUCCESS,
  REFRESH_ORGANIZATION_SCHEDULE,
} from '@app/store/types/organization-schedule'

const initialState: IOrganizationScheduleState = {
  organizationSchedules: [],
  organizationSchedule: {} as IOrganizationSchedule,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORGANIZATION_SCHEDULE:
      return { ...state, organizationSchedule: {}, state: IDLE }
    case CLEAR_ORGANIZATION_SCHEDULES:
      return { ...state, organizationSchedules: [], state: IDLE }
    case REFRESH_ORGANIZATION_SCHEDULE:
      return { ...state, refresh: !state.refresh }
    case FETCH_ORGANIZATION_SCHEDULE_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_ORGANIZATION_SCHEDULES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_SCHEDULES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationSchedules: action.payload.organizationSchedules,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_SCHEDULES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationSchedules: [],
      }
    case FETCH_ORGANIZATION_SCHEDULE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_SCHEDULE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationSchedule: action.payload,
      }
    case FETCH_ORGANIZATION_SCHEDULE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationSchedule: {},
      }
    default:
      return state
  }
}
