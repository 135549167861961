import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/uz'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  day: string
  selected: boolean
  clickHandler: (date: string) => void
}

const MenuBodyDay: React.FC<props> = ({ day, selected, clickHandler }) => {
  const [t, i18n] = useTranslation()
  const [refresh, setRefresh] = useState<boolean>(true)

  useEffect(() => {
    switch (i18n.language) {
      case 'ru':
        moment.locale('ru')
        setRefresh((prev) => !prev)
        break
      case 'uz':
        moment.locale('uz')
        setRefresh((prev) => !prev)
        break
      default:
        moment.locale('en')
        setRefresh((prev) => !prev)
    }
  }, [i18n.language])

  const formatDate = useCallback(
    (date: string) => {
      return moment(date).format('DD.MM.yyyy')
    },
    [refresh],
  )

  return (
    <div
      onClick={() => clickHandler(day)}
      className={`workDate ${selected ? 'selected' : 'none-selected'}`}
    >
      {formatDate(day)}
    </div>
  )
}

export default MenuBodyDay
