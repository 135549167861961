import { DownOutlined } from '@ant-design/icons'
import useLocalization from '@app/hooks/useLocalization'
import { IMenuContent } from '@app/interfaces/organization-menu'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MenuBodyItemImage from '../../menu-meal-image/menu-body-item-image'

interface props {
  menuContent: IMenuContent
}

const MenuBodyItem: React.FC<props> = ({ menuContent }) => {
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleExpanded = () => {
    setExpanded((prev) => !prev)
  }
  return (
    <div className="item">
      <div className="item__meal-type" onClick={toggleExpanded}>
        <p>{localizeData(menuContent.mealType.data)?.name}</p>
        <div
          className={`arrow ${expanded ? 'arrow-expanded' : 'arrow-collapsed'}`}
        >
          <DownOutlined />
        </div>
      </div>
      {menuContent.meals.map((meal, index) => {
        return (
          <div
            key={index}
            className={`item__meal-body ${
              expanded ? 'meal-expanded' : 'meal-collapsed'
            }`}
          >
            <div className="item__meal-top">
              <p className="title">{localizeData(meal.data)?.name}</p>
            </div>
            <div className="item__meal-bottom">
              <MenuBodyItemImage fileId={meal.fileId} />
              <div className="products">
                {meal.products.map((product, index) => {
                  return (
                    <div className="products__product" key={index}>
                      <p className="products__product--title">
                        {localizeData(product.data)?.name}
                      </p>
                      <p className="products__product--amount">
                        {product.amount} {t('general.gram')}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MenuBodyItem
