import { FilterOutlined } from '@ant-design/icons'
import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import { INSTITUTION } from '@app/constants/organizationType'
import useCache from '@app/hooks/useCache'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import useQuery from '@app/hooks/useQuery'
import { IInstitutionType } from '@app/interfaces/organization-passport/institution-type'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IInstitutionSubType } from '@app/interfaces/organization/institution-subtype'
import { IOrganizationType } from '@app/interfaces/organization/organization-type'
import { IRegion } from '@app/interfaces/organization/region'
import useRegionDestricts from '@app/shared/use-region-districts/use-region-districts'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

interface props {
  request: (string) => void
  searchParams: URLSearchParams
}

const OrganizationsFilterForm: React.FC<props> = ({
  request,
  searchParams,
}) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { serializeQuery } = useQuery()
  const { selectors } = useCreateSelector()
  const userInfo = useSelector(selectors.userInfo.userInfo)
  const { data: orgTypes } = useCache<IOrganizationType>(
    ApiKeys.organizationType,
  )
  const { data: instTypes } = useCache<IInstitutionType>(
    ApiKeys.institutionType,
  )
  const { data: instSubTypes } = useCache<IInstitutionSubType>(
    ApiKeys.institutionSubType,
  )
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts, isLoading } = useCache<IDistrict>(ApiKeys.district)
  const { District, Region, districtId, regionId, setRegionId } =
    useRegionDestricts()

  const statuses = [
    { label: t('general.active'), id: 1 },
    { label: t('general.inActive'), id: 2 },
  ]
  const emisApiStatuses = [
    { label: t('general.yes'), id: 1 },
    { label: t('general.no'), id: 2 },
    { label: t('general.all'), id: 3 },
  ]

  useEffect(() => {
    if (userInfo.organizations.length) {
      if (
        regions &&
        userInfo.organizations[0]?.regionId &&
        rolesChecker([
          AUTHORITIES.ROLE_REGION_IKT,
          AUTHORITIES.ROLE_DISTRICT_IKT,
        ])
      ) {
        setRegionId(userInfo.organizations[0].regionId)
        form.setFieldsValue({
          regionId: userInfo.organizations[0].regionId,
        })
      }
      if (
        districts &&
        userInfo.organizations[0].districtId &&
        rolesChecker([AUTHORITIES.ROLE_DISTRICT_IKT])
      ) {
        form.setFieldsValue({
          districtId: userInfo.organizations[0]?.districtId,
        })
      }
    }
  }, [regions, districts])

  const onFinish = (values: any) => {
    const newValues = {
      'name.contains': values.name,
      'code.contains': values.code,
      'regionId.equals': values.regionId,
      'districtId.equals': values.districtId,
      'typeId.equals': values.type,
      'institutionTypeId.equals': values.institutionType,
      'institutionSubTypeId.equals': values.institutionSubType,
      'statusId.equals': values.status,
      'emisApiSwitch.equals':
        values.emisApiStatus === 1
          ? true
          : values.emisApiStatus === 2
          ? false
          : undefined,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
  }
  const onReset = () => {
    const newValues = {
      'name.contains': null,
      'code.contains': null,
      'regionId.equals': null,
      'districtId.equals': null,
      'typeId.equals': null,
      'institutionTypeId.equals': null,
      'institutionSubTypeId.equals': null,
      'statusId.equals': null,
      'emisApiSwitch.equals': null,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
    form.resetFields()
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        onReset={() => form.resetFields()}>
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                },
              ]}
              name={'name'}
              label={t('general.name')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                },
              ]}
              name={'code'}
              label={t('users.tin')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label={t('organizationType.organizationType')}
              name={'type'}
              initialValue={INSTITUTION}>
              <Select allowClear={true}>
                {localize(orgTypes)?.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type?.data.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name={'regionId'} label={t('region.region')}>
              {Region}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name={'districtId'} label={t('district.district')}>
              {District}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={t('institutionType.institutionType')}
              name={'institutionType'}>
              <Select allowClear={true}>
                {localize(instTypes)
                  ?.filter((type) => type.status)
                  .map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.data?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label={t('institutionSubType.title')}
              name={'institutionSubType'}>
              <Select allowClear={true}>
                {localize(instSubTypes)
                  ?.filter((type) => type.status)
                  .map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.data?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label={t('general.status')}
              name={'status'}
              initialValue={1}>
              <Select allowClear={true}>
                {statuses.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label={t('scheduler.emisApiStatus')}
              name={'emisApiStatus'}
              style={{ paddingLeft: 10 }}
              initialValue={3}>
              <Select allowClear={true}>
                {emisApiStatuses.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div>
          <Button type="primary" htmlType="submit">
            <FilterOutlined />
            {t('general.filter')}
          </Button>
          <Button
            htmlType={'reset'}
            style={{ margin: '0 8px' }}
            onClick={onReset}>
            {t('general.reset')}
          </Button>
        </div>
      </Form>
    </Spin>
  )
}

export default OrganizationsFilterForm
