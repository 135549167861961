import bbitApi from '@app/api/make-request'
import { AUTHORITIES } from '@app/constants/authorities'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { INeighborhood } from '@app/interfaces/neighborhood/neighborhood.types'
import { IOrganization } from '@app/interfaces/organization/organization'
import ThreeDots from '@app/shared/loader/ThreeDots'
import { useGetDistrictsQuery } from '@app/store/rtk/api/organization/district-api'
import { useGetRegionsQuery } from '@app/store/rtk/api/organization/region-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

interface props {
  organization?: IOrganization
  edit: boolean
}

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_ORGANIZATION_ALL_ADD,
  AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
]

const RegionDistrictItem: React.FC<props> = ({ organization, edit }) => {
  const [t] = useTranslation()
  const [regionId, setRegionId] = useState<number>(0)
  const [districtId, setDistrictId] = useState<number>(
    organization?.district?.id || 0,
  )
  const { selectors } = useCreateSelector()
  const userInfo = useSelector(selectors.userInfo.userInfo)
  const { data: regions, isLoading: isRegionsLoading } = useGetRegionsQuery('')
  const { data: districts, isLoading: isDistrictsLoading } =
    useGetDistrictsQuery('')
  const { data: nHoods, isLoading: isNHoodLoading } = useQuery<INeighborhood[]>(
    ['getDistrict', districtId, regionId],
    async () => {
      const { data } = await bbitApi.get(`/dictionary/api/v1/neighborhood/`, {
        params: { 'districtId.equals': districtId, size: 10000 },
      })
      return data
    },
    { enabled: !!districtId && !!regionId },
  )
  const { localize } = useLocalization()
  const access = rolesChecker(permissions)
  const findDistricts = (id: number) => {
    if (districts?.response?.length) {
      return localize(districts?.response)?.filter(
        (district: { regionId: number }) => {
          return district.regionId === id
        },
      )
    }
    return []
  }

  const neighborhoodEnabled = useMemo(
    () =>
      !rolesChecker([AUTHORITIES.ROLE_ORGANIZATION_NEIGHBORHOOD_CHANGE]) &&
      !access,
    [rolesChecker, AUTHORITIES],
  )

  const filteredRegions = useMemo(
    () =>
      rolesChecker([AUTHORITIES.ADMIN, AUTHORITIES.ROLE_ORGANIZATION_ALL_ADD])
        ? regions?.response
        : regions?.response?.filter(
            (region) => region.id === userInfo?.organizations[0]?.regionId,
          ),
    [regions?.response],
  )

  const adminRegionId = useMemo(
    () =>
      edit
        ? organization?.region?.id
        : regions?.response?.length
        ? localize(regions.response)[0]?.id
        : 0,
    [regions],
  )

  const iktRegionId: any = edit
    ? organization?.region?.id
    : userInfo?.organizations[0]?.regionId

  const districtDefaultValue = useMemo(() => {
    return edit
      ? organization?.district?.id
      : rolesChecker([AUTHORITIES.ADMIN, AUTHORITIES.ROLE_ORGANIZATION_ALL_ADD])
      ? findDistricts(adminRegionId).at(0)?.id
      : findDistricts(iktRegionId)?.at(0)?.id
  }, [adminRegionId, iktRegionId, edit, organization])

  const currentRegion = rolesChecker([
    AUTHORITIES.ADMIN,
    AUTHORITIES.ROLE_ORGANIZATION_ALL_ADD,
  ])
    ? adminRegionId
    : iktRegionId

  useEffect(() => {
    setRegionId(currentRegion)
  }, [])

  const getRegionName = (regionId: number) => {
    return localize(regions?.response as any)?.find(
      (region: { id: number }) => region?.id === regionId,
    )?.data.name
  }

  const handleChange = (e: React.SetStateAction<number>) => {
    setRegionId(e)
  }
  return (
    <>
      {isRegionsLoading ? (
        <ThreeDots style="dot-pulse" height={86} padding={24} />
      ) : (
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name={'region'}
          label={t('region.region')}
          initialValue={edit && currentRegion}>
          <Select disabled={!access} showSearch={true} onChange={handleChange}>
            {sortByName(localize(filteredRegions as any)).map(
              ({ data: { name }, id }) => {
                return (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                )
              },
            )}
          </Select>
        </Form.Item>
      )}
      {isDistrictsLoading ? (
        <ThreeDots style="dot-pulse" height={86} padding={24} />
      ) : (
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
            {
              validator: (_, value) => {
                if (isNaN(value)) {
                  value = findDistricts(regionId)?.find(
                    (district: { data: { name: any } }) =>
                      district.data.name === value,
                  )?.id
                }
                if (
                  value &&
                  regionId ===
                    districts?.response?.find(
                      (district) => district?.id === value,
                    )?.regionId
                ) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(t('organization.regionIdsNotEqual')),
                )
              },
            },
          ]}
          name={'district'}
          label={t('district.district')}
          initialValue={edit && districtDefaultValue}>
          <Select
            disabled={!access}
            showSearch={true}
            onChange={(e) => setDistrictId(e)}>
            {sortByName(findDistricts(regionId))
              ?.filter((district: { data: { name: any } }) => {
                return district.data.name !== getRegionName(regionId)
              })
              .map((district) => {
                return (
                  <Select.Option key={district?.id} value={district?.id}>
                    {district.data.name}
                  </Select.Option>
                )
              })}
          </Select>
        </Form.Item>
      )}
      {isDistrictsLoading && isNHoodLoading ? (
        <ThreeDots style="dot-pulse" height={86} padding={24} />
      ) : (
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
            {
              validator: (_, value) => {
                if (nHoods?.map((item) => item?.id)?.includes(value)) {
                  return Promise.resolve()
                } else
                  return Promise.reject(
                    new Error(t('organization.regionIdsNotEqual')),
                  )
              },
            },
          ]}
          name={'neighborhood'}
          label={t('neighborhood.neighborhood')}
          initialValue={edit && organization?.neighborhood?.id}>
          <Select disabled={neighborhoodEnabled} showSearch={true}>
            {nHoods &&
              nHoods?.length > 0 &&
              localize(nHoods)?.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item?.data?.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

export default RegionDistrictItem
