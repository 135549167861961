import { IPathParams } from '@app/constants/path-params'
import { IGetOneEmployee } from '@app/interfaces/employee/employee'
import { IFileState } from '@app/interfaces/files'
import EmployeeInfo from '@app/pages/organizations/components/organization-employee/components/employee-organization/components/employee-info/employee-info'
import EmployeePositionsInfo from '@app/pages/organizations/components/organization-employee/components/employee-organization/components/employee-positions-info/employee-positions-info'
import { clearEmployee, getEmployee } from '@app/store/actions/employee-actions'
import { getFile, removeFile } from '@app/store/actions/files-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

interface props {
  _filesState: IFileState
  _employee: IGetOneEmployee
  refresh: boolean
}

const EmployeePage: React.FC<props> = ({ _filesState, _employee, refresh }) => {
  const { preUploadedPhoto, photo } = _filesState
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [imageUpdated, setImageUpdated] = useState<boolean>(false)
  const params: IPathParams = useParams()
  setDocumentTitle(t('employee.employee'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getEmployee(
        { data: Number(params.employeeId), source },
        t('employee.error'),
      ),
    )
    return () => {
      dispatch(clearEmployee())
      dispatch(removeFile())
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (_employee.fileId) {
      dispatch(
        getFile({ data: _employee.fileId, source }, t('users.errorAvatar')),
      )
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [_employee])

  return (
    <>
      <EmployeeInfo
        imageUpdated={imageUpdated}
        setImageUpdated={setImageUpdated}
        avatar={photo}
        preload={preUploadedPhoto}
      />
      {!isEmpty(_employee) && <EmployeePositionsInfo employee={_employee} />}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _filesState: state.files,
    _employee: state.organizationEmployee.employee,
    refresh: state.organizationEmployee.refresh,
  }
}

export default connect(mapStateToProps)(EmployeePage)
