import { UpOutlined } from '@ant-design/icons'
import { AUTH_TOKEN } from '@app/constants/authToken'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { LayoutMenu } from '@app/layout/components'
import Breadcrumbs from '@app/layout/components/breadcrumbs/breadcrumbs'
import Navbar from '@app/layout/components/navbar/navbar'
import { getUserInfo } from '@app/store/actions/user-info-actions'
import { BackTop, Layout } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router'
import './layout.less'

const { Header, Content, Footer } = Layout

const Layouts: React.FC = () => {
  const { selectors } = useCreateSelector()
  const userInfo = useSelector(selectors.userInfo.userInfo)
  const navigate = useNavigate()
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()
  const { setLanguage } = useLocalization()

  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n])

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(userInfo)) dispatch(getUserInfo(source))
    if (!localStorage.getItem(AUTH_TOKEN)) {
      navigate('/auth')
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  return (
    <React.Fragment>
      <div className="layout">
        <Layout>
          <Header className="header">
            <Navbar />
          </Header>
          <Layout
            style={{
              margin: 0,
              minHeight: `calc(100vh - 87px)`,
            }}>
            <LayoutMenu />
            <Layout style={{ position: 'relative' }}>
              <Breadcrumbs />
              <Content>{!isEmpty(userInfo) && <Outlet />}</Content>
            </Layout>
            <BackTop>
              <div className={'to-top'}>
                <UpOutlined />
              </div>
            </BackTop>
          </Layout>
        </Layout>
      </div>
    </React.Fragment>
  )
}

export default Layouts
