import { IPathParamsArgs } from '@app/api/repositories/i-repository'
import organizationWarehouseRepository from '@app/api/repositories/organization-warehouse-repository'
import axios from 'axios'
import {
  CLEAR_WAREHOUSE_DATA,
  CLEAR_WAREHOUSE_INVOICE,
  FETCH_WAREHOUSE_CLEANUP,
  FETCH_WAREHOUSE_DATA_FAILURE,
  FETCH_WAREHOUSE_DATA_REQUEST,
  FETCH_WAREHOUSE_DATA_SUCCESS,
  FETCH_WAREHOUSE_INVOICE_FAILURE,
  FETCH_WAREHOUSE_INVOICE_REQUEST,
  FETCH_WAREHOUSE_INVOICE_SUCCESS,
  TOGGLE_WAREHOUSE_ADD_INVOICE_MODAL,
} from '../types/organization-warehouse'

export const getWarehouseData =
  (id: string, args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchWarehouseDataRequest())
    await organizationWarehouseRepository
      .getWarehouseData(id, { params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchWarehouseDataSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchWarehouseDataFailure(error?.message, error?.code))
        }
      })
    fetchWarehouseCleanup()
  }

export const getWarehouseInvoice =
  (id: string, args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchWarehouseInvoiceRequest())
    await organizationWarehouseRepository
      .getWarehouseInvoice(id, { params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchWarehouseInvoiceSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchWarehouseInvoiceFailure(error?.message, error?.code))
        }
      })
    fetchWarehouseCleanup()
  }

const fetchWarehouseDataRequest = () => {
  return {
    type: FETCH_WAREHOUSE_DATA_REQUEST,
  }
}

const fetchWarehouseDataSuccess = (warehouseData, count) => {
  return {
    type: FETCH_WAREHOUSE_DATA_SUCCESS,
    payload: { warehouseData, count },
  }
}

const fetchWarehouseDataFailure = (message, code) => {
  return {
    type: FETCH_WAREHOUSE_DATA_FAILURE,
    payload: { message, code },
  }
}

const fetchWarehouseInvoiceRequest = () => {
  return {
    type: FETCH_WAREHOUSE_INVOICE_REQUEST,
  }
}

const fetchWarehouseInvoiceSuccess = (warehouseInvoice, count) => {
  return {
    type: FETCH_WAREHOUSE_INVOICE_SUCCESS,
    payload: { warehouseInvoice, count },
  }
}

const fetchWarehouseInvoiceFailure = (message, code) => {
  return {
    type: FETCH_WAREHOUSE_INVOICE_FAILURE,
    payload: { message, code },
  }
}

const fetchWarehouseCleanup = () => {
  return {
    type: FETCH_WAREHOUSE_CLEANUP,
  }
}

export function clearWarehouseData() {
  return {
    type: CLEAR_WAREHOUSE_DATA,
  }
}

export function clearWarehouseInvoice() {
  return {
    type: CLEAR_WAREHOUSE_INVOICE,
  }
}

export function toggleWarehouseAddInvoiceModal(toggle: boolean) {
  return {
    type: TOGGLE_WAREHOUSE_ADD_INVOICE_MODAL,
    payload: toggle,
  }
}
