import React from 'react'
import { Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { ILang } from '@app/interfaces/languages'
import { useParams } from 'react-router'
import { IPathParams } from '@app/constants/path-params'

interface props {
  activeLanguages: ILang[]
}

const GeneralItems: React.FC<props> = ({ activeLanguages }) => {
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  return (
    <>
      <Form.Item
        label={t('users.username')}
        name={'username'}
        rules={[
          {
            required: true,
            message: t('errors.requiredMessage'),
          },
          {
            min: 5,
            max: 255,
            message: t('users.nameMinMessage'),
          },
        ]}
      >
        <Input disabled={!!params.edit} />
      </Form.Item>
      {!params.edit ? (
        <Form.Item
          name={'password'}
          label={t('users.password')}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      ) : null}

      <Form.Item
        label={t('users.firstName')}
        name={'firstName'}
        rules={[
          {
            required: true,
            message: t('errors.requiredMessage'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={'lastName'} label={t('users.lastName')}>
        <Input />
      </Form.Item>
      <Form.Item
        name={'email'}
        label={t('users.email')}
        rules={[
          {
            type: 'email',
            message: t('errors.emailMessage'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={'phone'} label={t('users.phone')}>
        <Input />
      </Form.Item>
      <Form.Item name={'lang'} label={t('users.lang')}>
        <Select>
          {activeLanguages?.map((lang) => {
            return (
              <Select.Option key={lang.id} value={lang.code}>
                {lang.name}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name={'tin'} label={t('users.tin')}>
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            transform: (value) =>
              typeof value === 'string' ? value.trim() : undefined,
            required: true,
            message: t('errors.requiredMessage'),
          },
        ]}
        name={'pin'}
        label={t('users.pin')}
      >
        <Input />
      </Form.Item>
      <Form.Item name={'passportNumber'} label={t('users.passportNumber')}>
        <Input />
      </Form.Item>
      <Form.Item name={'idEgovUserId'} label={t('users.idEgovUserId')}>
        <Input />
      </Form.Item>
    </>
  )
}

export default GeneralItems
