import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IInstitutionType,
  IInstitutionTypeState,
} from '@app/interfaces/organization-passport/institution-type'
import {
  CLEAR_CURRENT_INSTITUTION_TYPE_ID,
  CLEAR_INSTITUTIONS_TYPE,
  CREATE_INSTITUTIONS_TYPE,
  DELETE_INSTITUTIONS_TYPE,
  FETCH_INSTITUTION_TYPES_FAILURE,
  FETCH_INSTITUTION_TYPES_REQUEST,
  FETCH_INSTITUTION_TYPES_SUCCESS,
  FETCH_INSTITUTION_TYPE_FAILURE,
  FETCH_INSTITUTION_TYPE_REQUEST,
  FETCH_INSTITUTION_TYPE_SUCCESS,
  SET_CURRENT_INSTITUTION_TYPE_ID,
  UPDATE_INSTITUTIONS_TYPE,
} from '@app/store/types/institutions-type'

const initialState: IInstitutionTypeState = {
  institutionsTypes: [],
  institutionsType: {} as IInstitutionType,
  currentInstitutionTypeId: 0,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const institutionsTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTITUTION_TYPES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_INSTITUTION_TYPES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        institutionsTypes: action.payload.institutionsTypes,
        count: action.payload.count,
      }
    case FETCH_INSTITUTION_TYPES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        institutionsTypes: [],
      }
    case FETCH_INSTITUTION_TYPE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_INSTITUTION_TYPE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        institutionsType: action.payload,
      }
    case FETCH_INSTITUTION_TYPE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        institutionsType: {},
      }
    case CLEAR_INSTITUTIONS_TYPE:
      return { ...state, institutionsTypes: [] }
    case CREATE_INSTITUTIONS_TYPE:
      return {
        ...state,
        institutionsTypes: [...state.institutionsTypes, action.payload],
      }
    case UPDATE_INSTITUTIONS_TYPE:
      const updatedInstitutionTypes = state.institutionsTypes.map(function (
        dooType,
      ) {
        if (action.payload.id === dooType.id) {
          return action.payload
        }
        return dooType
      })
      return { ...state, institutionsTypes: updatedInstitutionTypes }
    case DELETE_INSTITUTIONS_TYPE:
      const editedInstitutionTypes = state.institutionsTypes.filter(function (
        dooType,
      ) {
        if (action.payload.id === dooType.id) {
          return false
        }
        return dooType
      })
      return { ...state, institutionsTypes: editedInstitutionTypes }
    case SET_CURRENT_INSTITUTION_TYPE_ID:
      return { ...state, currentInstitutionTypeId: action.payload }
    case CLEAR_CURRENT_INSTITUTION_TYPE_ID:
      return { ...state, currentInstitutionTypeId: 0 }
    default:
      return state
  }
}
