import { IPathParamsArgs } from '@app/api/repositories/i-repository'
import languageRepository from '@app/api/repositories/language-repository'
import { ILangActions } from '@app/interfaces/languages'
import {
  CLEAR_LANG,
  CREATE_LANG,
  FETCH_LANGS_CLEANUP,
  FETCH_LANGS_FAILURE,
  FETCH_LANGS_REQUEST,
  FETCH_LANGS_SUCCESS,
  GET_LANG_ID,
  UPDATE_LANG,
} from '@app/store/types/languages'
import axios from 'axios'

export const getLangs = (args: IPathParamsArgs) => async (dispatch) => {
  dispatch(fetchLangsRequest())
  await languageRepository
    .getAll({ source: args.source })
    .then((response) => {
      dispatch(
        fetchLangsSuccess(response.data, response.headers['x-total-count']),
      )
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        dispatch(fetchLangsFailure(error?.message, error?.code))
      }
    })
  dispatch(fetchLangsCleanup())
}

export const getLangId = (id) => {
  return {
    type: GET_LANG_ID,
    payload: id,
  }
}

export function clearLangs() {
  return {
    type: CLEAR_LANG,
    payload: [],
  }
}

export function updateLang(updatedLang): ILangActions {
  return {
    type: UPDATE_LANG,
    payload: updatedLang,
  }
}
export function createLang(lang): ILangActions {
  return {
    type: CREATE_LANG,
    payload: lang,
  }
}

const fetchLangsRequest = (): ILangActions => {
  return {
    type: FETCH_LANGS_REQUEST,
  }
}

const fetchLangsSuccess = (langs, count): ILangActions => {
  return {
    type: FETCH_LANGS_SUCCESS,
    payload: { langs, count },
  }
}

const fetchLangsFailure = (message, code): ILangActions => {
  return {
    type: FETCH_LANGS_FAILURE,
    payload: { message, code },
  }
}

const fetchLangsCleanup = (): ILangActions => {
  return {
    type: FETCH_LANGS_CLEANUP,
  }
}
