import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IOrganizationType } from '@app/interfaces/organization/organization-type'
import { AxiosResponse } from 'axios'

class OrganizationTypeRepository implements IRepository<IOrganizationType> {
  create(data: IOrganizationType): Promise<AxiosResponse<IOrganizationType>> {
    return bbitApi.post('/dictionary/api/v1/organization-type', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IOrganizationType[]>> {
    return bbitApi.get('/dictionary/api/v1/organization-type', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IOrganizationType>> {
    return bbitApi.get(`/dictionary/api/v1/organization-type/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IOrganizationType): Promise<AxiosResponse<IOrganizationType>> {
    return bbitApi.put(`/dictionary/api/v1/organization-type/${data.id}`, data)
  }
}

export default new OrganizationTypeRepository()
