import organizationContractSubsidyRepository from '@app/api/repositories/organization-contract-subsidy-repository'
import useCreateSelector from '@app/hooks/useCreateSelector'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  refreshOrganizationContractSubsidy,
  setOrganizationContractSubsidyAddModal,
} from '@app/store/actions/organization-contract-subsidy-actions'
import { DatePicker, Form, Input, InputNumber, Modal } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

interface IPathParams {
  id?: string
}

const OrganizationContractSubsidyAddForm: React.FC = () => {
  const { selectors } = useCreateSelector()
  const dispatch = useDispatch()
  const params: IPathParams = useParams()
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const { addModal } = useSelector(
    selectors.organizationContractSubsidy.organizationContractSubsidyState,
  )
  useEffect(() => {
    if (addModal.edit) {
      form.setFieldsValue({
        ...addModal.contract,
        organizationId: params.id,
        contractStartDate: moment(addModal.contractStartDate),
        governmentCertDate: moment(addModal.governmentCertDate),
        cadastrDocDate: moment(addModal.cadastrDocDate),
      })
    } else {
      form.setFieldsValue({
        organizationId: params.id,
      })
    }
  }, [addModal.contract])

  const hide = () => {
    form.resetFields()
    dispatch(
      setOrganizationContractSubsidyAddModal({
        isOpen: false,
        edit: false,
        contract: undefined,
      }),
    )
  }

  const onFinish = (values) => {
    const newValues = {
      ...values,
    }
    const subsidyApi = addModal.edit
      ? organizationContractSubsidyRepository.update
      : organizationContractSubsidyRepository.create
    subsidyApi(newValues)
      .then((response) => {
        dispatch(refreshOrganizationContractSubsidy())
        openNotificationWithIcon('success', t('contractSubsidy.successAdd'))
        hide()
      })
      .catch((error) => {
        openNotificationWithIcon('error', t('contractSubsidy.errors.add'))
        hide()
      })
  }

  return (
    <Modal
      width={550}
      open={addModal.isOpen}
      onCancel={hide}
      title={t('general.add')}
      onOk={form.submit}
    >
      <Form
        form={form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
        onFinish={onFinish}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="organizationId">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('contractSubsidy.contractNo')}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name="contractNumber"
        >
          <Input style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          label={t('contractSubsidy.founderFullName')}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name="founderFullName"
        >
          <Input style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name="contractStartDate"
          label={t('contractSubsidy.startDate')}
        >
          <DatePicker locale={locale} style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          name="contractPeriod"
          label={t('contractSubsidy.period')}
          rules={[
            {
              required: true,
              type: 'number',
              message: t('errors.requiredMessage'),
            },
          ]}
        >
          <InputNumber style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name="governmentCertDate"
          label={t('contractSubsidy.asuRegistrationDate')}
        >
          <DatePicker locale={locale} style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          label={t('contractSubsidy.asuRegistrationNo')}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name="governmentCertNumber"
        >
          <Input style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name="cadastrDocDate"
          label={t('contractSubsidy.cadastrDocumentDate')}
        >
          <DatePicker locale={locale} style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          label={t('contractSubsidy.cadastrDocumentNo')}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          name="cadastrDocNumber"
        >
          <Input style={{ width: '200px' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OrganizationContractSubsidyAddForm
