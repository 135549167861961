import { IPathParams } from '@app/constants/path-params'
import { IFileState } from '@app/interfaces/files'
import { IOrganizationPupilsState } from '@app/interfaces/pupil/organization-pupils'
import OrganizationPupilInfo from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-page/components/organization-pupil-info'
import OrganizationPupilsParents from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-page/components/organization-pupils-parents'
import { removeFile } from '@app/store/actions/files-actions'
import {
  clearOrganizationPupil,
  getOrganizationPupil,
} from '@app/store/actions/organization-pupils-actions'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

interface props {
  _pupilsState: IOrganizationPupilsState
  _filesState: IFileState
}

const OrganizationPupilPage: React.FC<props> = ({
  _pupilsState,
  _filesState,
}) => {
  const params: IPathParams = useParams()
  const { pupil, refresh } = _pupilsState
  const { preUploadedPhoto, photo } = _filesState
  const [t] = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getOrganizationPupil(
        { data: Number(params.pupilId), source },
        { pupilError: t('pupils.error'), fileError: t('users.errorAvatar') },
      ),
    )
    return () => {
      dispatch(removeFile())
      dispatch(clearOrganizationPupil())
      source.cancel('Component got unmounted')
    }
  }, [refresh])

  return (
    <React.Fragment>
      {!isEmpty(pupil) && (
        <div>
          <OrganizationPupilInfo
            avatar={photo}
            preload={preUploadedPhoto}
            _pupil={pupil}
          />
          <OrganizationPupilsParents _pupils={pupil} />
        </div>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    _pupilsState: state.organizationPupils,
    _filesState: state.files,
  }
}

export default connect(mapStateToProps)(OrganizationPupilPage)
