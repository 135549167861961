import DooTypeRepository from '@api/repositories/institutions-type-repository'
import useLocalization from '@app/hooks/useLocalization'
import { IInstitutionType } from '@app/interfaces/organization-passport/institution-type'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  createInstitutionType,
  updateInstitutionType,
} from '@app/store/actions/institutions-types-actions'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Switch,
} from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  institutionType?: IInstitutionType
  institutionTypes?: IInstitutionType[]
  edit?: boolean
}

const InstitutionTypeForm: React.FC<props> = ({
  institutionType,
  institutionTypes,
  edit,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { localizeData } = useLocalization()

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues: IInstitutionType = {
      ...values,
      data: languages,
    }
    setConfirmLoading(true)
    const institutionTypeSubmitApi = institutionType
      ? DooTypeRepository.update
      : DooTypeRepository.create
    await institutionTypeSubmitApi(newValues)
      .then((response: AxiosResponse<IInstitutionType>) => {
        if (institutionType && response.status === 200) {
          dispatch(updateInstitutionType(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!institutionType && response.status === 201) {
          dispatch(createInstitutionType(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        openNotificationWithIcon('error', t('errors.error'))
      })
    setConfirmLoading(false)
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={
          edit
            ? t('institutionType.editInstitutionType')
            : t('institutionType.addInstitutionType')
        }
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            initialValues={{
              id: institutionType?.id,
              sorting: institutionType?.sorting || 0,
              status: edit ? institutionType?.status : true,
              type: institutionType?.type,
              types: institutionType?.type,
            }}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={institutionType?.status === true}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -2147483648,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label={t('institutionType.types.type')}
              name={'type'}
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string'
                      ? value.trim().toUpperCase()
                      : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
                {
                  validator: async (_, type) => {
                    if (type?.trim().length) {
                      if (!type?.match(/^[A-Z_]*$/)) {
                        return Promise.reject(new Error(t('errors.allCapital')))
                      }
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('institutionType.selectType')} name={'types'}>
              <Select disabled={confirmLoading}>
                {institutionTypes?.map((type, index) => {
                  return (
                    <Select.Option key={index} value={type.type}>
                      {localizeData(type.data)?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <FormList
              confirmLoading={confirmLoading}
              object={institutionType}
            />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default InstitutionTypeForm
