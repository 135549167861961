import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import ThreeDots from '@app/shared/loader/ThreeDots'
import { TCommonList } from '@app/store/types'
import React from 'react'

interface props {
  apiKey: string
  id?: number
}

const GroupInfoDescription: React.FC<props> = ({ apiKey, id }) => {
  const { localize } = useLocalization()
  const { data } = useCache<TCommonList>(apiKey)
  return (
    <>
      {!data ? (
        <ThreeDots style="dot-pulse" height={22} padding={0} />
      ) : (
        localize(data)?.find((item) => item.id === id)?.data.name
      )}
    </>
  )
}

export default GroupInfoDescription
