import bbitApi from '@api/make-request'
import {
  IRepository,
  numberArgs,
  IPathParamsArgs,
} from '@api/repositories/i-repository'
import { IBenefitType, IBenefitTypeUpdate } from '@app/interfaces/benefit-type'
import { AxiosResponse } from 'axios'
import {
  IBenefitTypeCreate,
  IBenefitTypePartialUpdate,
} from './../../interfaces/benefit-type'

class BenefitTypeRepository implements IRepository<IBenefitType> {
  create(data: IBenefitTypeCreate): Promise<AxiosResponse<IBenefitType>> {
    return bbitApi.post('/pupil/api/v1/pupil-application-benefit-type', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IBenefitType[]>> {
    return bbitApi.get('/pupil/api/v1/pupil-application-benefit-type', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IBenefitType>> {
    return bbitApi.get(
      `/pupil/api/v1/pupil-application-benefit-type/${args.data}`,
      {
        cancelToken: args.source?.token,
      },
    )
  }

  update(data: IBenefitTypeUpdate): Promise<AxiosResponse<IBenefitType>> {
    return bbitApi.put(
      `/pupil/api/v1/pupil-application-benefit-type/${data.id}`,
      data,
    )
  }

  partialUpdate(
    data: IBenefitTypePartialUpdate,
  ): Promise<AxiosResponse<IBenefitType>> {
    return bbitApi.put(
      `/pupil/api/v1/pupil-application-benefit-type/${data.id}`,
      data,
    )
  }
}

export default new BenefitTypeRepository()
