import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IEducationDegree } from '@app/interfaces/education/education-degree'
import { AxiosResponse } from 'axios'

class EducationDegreeRepository implements IRepository<IEducationDegree> {
  create(data: IEducationDegree): Promise<AxiosResponse<IEducationDegree>> {
    return bbitApi.post('/employee/api/v1/degree-education-dictionary', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEducationDegree[]>> {
    return bbitApi.get('/employee/api/v1/degree-education-dictionary', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEducationDegree>> {
    return bbitApi.get(
      `/employee/api/v1/degree-education-dictionary/${args.data}`,
      { cancelToken: args.source?.token },
    )
  }

  update(data: IEducationDegree): Promise<AxiosResponse<IEducationDegree>> {
    return bbitApi.put(
      `/employee/api/v1/degree-education-dictionary/${data.id}`,
      data,
    )
  }
}

export default new EducationDegreeRepository()
