import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IEducationGrade } from '@app/interfaces/education/education-grade'
import { AxiosResponse } from 'axios'

class EducationGradeRepository implements IRepository<IEducationGrade> {
  create(data: IEducationGrade): Promise<AxiosResponse<IEducationGrade>> {
    return bbitApi.post('/dictionary/api/v1/education-grade', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEducationGrade[]>> {
    return bbitApi.get('/dictionary/api/v1/education-grade', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEducationGrade>> {
    return bbitApi.get(`/dictionary/api/v1/education-grade/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IEducationGrade): Promise<AxiosResponse<IEducationGrade>> {
    return bbitApi.put(`/dictionary/api/v1/education-grade/${data.id}`, data)
  }
}

export default new EducationGradeRepository()
