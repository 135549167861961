import { ApiKeys } from '@app/constants/api-keys'
import { IWaterCleaningMethods } from '@app/interfaces/organization-passport/water-cleaning-methods'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const waterCleaningMethodsApi =
  emptyOrganizationPassportApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getWaterCleaningMethods: build.query<
        IRtkResponse<IWaterCleaningMethods[]>,
        URLSearchParams | string
      >({
        query: (args = '') => ({
          url: 'dictionary/api/v1/drinking-water-treatments',
          method: 'get',
          params: {
            ...resolveTParams(args),
          },
        }),
        transformResponse: (response: IWaterCleaningMethods[], meta) => {
          return { response, meta }
        },
        providesTags: (data) =>
          data
            ? [
                ...data.response.map(({ id }) => ({
                  type: ApiKeys.waterCleaningMethod,
                  id,
                })),
                { type: ApiKeys.waterCleaningMethod, id: 'LIST' },
              ]
            : [{ type: ApiKeys.waterCleaningMethod, id: 'LIST' }],
      }),
      getWaterCleaningMethod: build.query<IWaterCleaningMethods, number>({
        query: (id) => ({
          url: `dictionary/api/v1/drinking-water-treatments/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          { type: ApiKeys.waterCleaningMethod, id },
        ],
      }),
      createWaterCleaningMethod: build.mutation<
        IRtkResponse<IWaterCleaningMethods>,
        IWaterCleaningMethods
      >({
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/drinking-water-treatments',
            data,
          }
        },
        transformResponse: (response: IWaterCleaningMethods, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          {
            type: ApiKeys.waterCleaningMethod,
            id: args.id,
          },
          { type: ApiKeys.waterCleaningMethod, id: 'LIST' },
        ],
      }),
      updateWaterCleaningMethod: build.mutation<
        IRtkResponse<IWaterCleaningMethods>,
        IWaterCleaningMethods
      >({
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/drinking-water-treatments/${data.id}`,
          data,
        }),
        transformResponse: (response: IWaterCleaningMethods, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.waterCleaningMethod, id: args.id },
          { type: ApiKeys.waterCleaningMethod, id: 'LIST' },
        ],
      }),
    }),
  })

const useStateGetWaterCleaningMethods =
  waterCleaningMethodsApi.endpoints.getWaterCleaningMethods.useQueryState
const useStateGetWaterCleaningMethod =
  waterCleaningMethodsApi.endpoints.getWaterCleaningMethod.useQueryState

export const {
  useGetWaterCleaningMethodsQuery,
  useLazyGetWaterCleaningMethodsQuery,
  useCreateWaterCleaningMethodMutation,
  useUpdateWaterCleaningMethodMutation,
  util,
  usePrefetch,
} = waterCleaningMethodsApi

export { useStateGetWaterCleaningMethods, useStateGetWaterCleaningMethod }
