import { host } from '@app/constants/env'
import { FormType } from '@app/constants/formType'
import { langs } from '@app/constants/languages'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { IProducts } from '@app/interfaces/organization-menu'
import PhotoItemManual from '@app/shared/photo-item/photo-item-manual'
import { getFile } from '@app/store/actions/files-actions'
import { toggleMenuFormType } from '@app/store/actions/organization-menus-actions'
import { RootState } from '@app/store/store'
import { Button, Form, Input, Modal } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { FormInstance } from 'rc-field-form'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuMealProducts from './menu-meal-products'

interface props {
  addMeal: (
    values: any,
    mealProducts: IProducts[],
    fileList: UploadFile[],
    cb: () => void,
  ) => void
  loading: boolean
}

const MenuMealForm: React.FC<props> = ({ addMeal, loading }) => {
  const [form] = Form.useForm()
  const { selectors } = useCreateSelector()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const [childForm, setChildForm] = useState<FormInstance<any>>()
  const { meal, formType, products } = useSelector(selectors.menus.menusState)
  const photo = useSelector((state: RootState) => state.files.photo)
  const [mealProducts, setMealProducts] = useState<IProducts[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const showModal = () => {
    dispatch(toggleMenuFormType(FormType.ADD))
  }

  const hideModal = () => {
    dispatch(toggleMenuFormType(FormType.NONE))
    form.resetFields()
    setMealProducts([])
    childForm?.resetFields()
    setFileList([])
  }

  useEffect(() => {
    if (!isEmpty(photo))
      setFileList([
        {
          uid: photo.id,
          name: photo.name,
          url: `${host}/files${photo?.baseURL}/750x750-${photo?.filePath}`,
        },
      ])
  }, [photo])

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (formType === FormType.EDIT) {
      const newData = meal.data.sort((a, b) => {
        return a.languageId - b.languageId
      })
      newData.unshift(undefined)
      form.setFieldsValue({
        id: meal.id,
        fileId: meal.fileId,
        weight: meal.weight,
        data: newData,
        products: meal.products,
      })
      if (meal.fileId)
        dispatch(getFile({ data: meal.fileId, source }, t('users.errorAvatar')))
      setMealProducts(
        meal.products?.map((product) => ({
          ...product,
          id: product.productId,
        })),
      )
    }
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [meal])

  const mealName = useMemo(() => {
    const newData = meal?.data?.filter((item) => item !== undefined)
    return newData ? localizeData(newData)?.name : ''
  }, [meal])

  const onFormSubmitHandler = (values: any) => {
    addMeal(values, mealProducts, fileList, hideModal)
  }

  return (
    <>
      <Button className={``} onClick={showModal} type={'primary'}>
        {t('general.add')}
      </Button>
      <Modal
        open={formType !== FormType.NONE}
        title={t('organizationMenu.meals.add')}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <Button disabled={loading} type={'primary'} onClick={form.submit}>
              {t('general.accept')}
            </Button>
            <Button
              disabled={loading}
              type={'default'}
              onClick={hideModal}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Button>
          </React.Fragment>,
        ]}
      >
        <Form form={form} onFinish={onFormSubmitHandler} layout={'vertical'}>
          <Form.Item hidden={true} name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item hidden={true} name={'fileId'}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            label={<p style={{ fontWeight: 600 }}>{t('general.name')}</p>}
          >
            <Form.List name="data">
              {() =>
                langs
                  .sort((a, b) => {
                    return a.languageId - b.languageId
                  })
                  .map(({ id, languageId, toShowCode }) => (
                    <React.Fragment key={id}>
                      <Form.Item hidden={true} name={[languageId, 'id']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[languageId, 'name']}
                        rules={[
                          {
                            required: true,
                            message: t('errors.requiredMessage'),
                          },
                          {
                            min: 2,
                            message: t('errors.minMessage'),
                          },
                        ]}
                      >
                        <Input addonBefore={toShowCode} />
                      </Form.Item>
                      <Form.Item
                        hidden={true}
                        name={[id, 'languageId']}
                        initialValue={id}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))
              }
            </Form.List>
          </Form.Item>
          <PhotoItemManual
            fileList={fileList}
            setFileList={setFileList}
            mealName={mealName}
          />
          <Form.Item
            label={
              <p style={{ fontWeight: 600 }}>
                {t('organizationMenu.meals.weight')}
              </p>
            }
            name="weight"
          >
            <Input />
          </Form.Item>
        </Form>
        <MenuMealProducts
          products={products}
          mealProducts={mealProducts}
          setMealProducts={setMealProducts}
          setChildForm={setChildForm}
        />
      </Modal>
    </>
  )
}

export default MenuMealForm
