import { ApiKeys } from '@app/constants/api-keys'
import {
  IOrganizationPassportization,
  IOrganizationPassportizationCreate,
} from '@app/interfaces/organization-passport/organization-passportization'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from '../../types'

export const organizationPassportizationApi =
  emptyOrganizationPassportApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getOrganizationPassportizations: build.query<
        IRtkResponse<IOrganizationPassportization[]>,
        URLSearchParams | string
      >({
        query: (args = '') => ({
          url: 'dictionary/api/v1/institution-passportization',
          method: 'get',
          params: {
            ...resolveTParams(args),
          },
        }),
        transformResponse: (response: IOrganizationPassportization[], meta) => {
          return { response, meta }
        },
        providesTags: (data) =>
          data
            ? [
                ...data.response.map(({ id }) => ({
                  type: ApiKeys.organizationPassportization,
                  id,
                })),
                { type: ApiKeys.organizationPassportization, id: 'LIST' },
              ]
            : [{ type: ApiKeys.organizationPassportization, id: 'LIST' }],
      }),
      getOrganizationPassportization: build.query<
        IOrganizationPassportization,
        number
      >({
        query: (id) => ({
          url: `dictionary/api/v1/institution-passportization/by-organization/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          { type: ApiKeys.organizationPassportization, id },
          { type: ApiKeys.organizationPassportization, id: 'LIST' },
        ],
      }),
      createOrganizationPassportization: build.mutation<
        IRtkResponse<IOrganizationPassportization>,
        Omit<IOrganizationPassportizationCreate, 'id'>
      >({
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/institution-passportization',
            data,
          }
        },
        transformResponse: (response: IOrganizationPassportization, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result) => [
          {
            type: ApiKeys.organizationPassportization,
            id: result?.response.id,
          },
          { type: ApiKeys.organizationPassportization, id: 'LIST' },
        ],
      }),
      updateOrganizationPassportization: build.mutation<
        IRtkResponse<IOrganizationPassportization>,
        IOrganizationPassportizationCreate
      >({
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/institution-passportization/${data.id}`,
          data,
        }),
        transformResponse: (response: IOrganizationPassportization, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.organizationPassportization, id: args.id },
          { type: ApiKeys.organizationPassportization, id: 'LIST' },
        ],
      }),
      partialUpdateOrganizationPassportization: build.mutation<
        IRtkResponse<IOrganizationPassportization>,
        Partial<IOrganizationPassportizationCreate> &
          Pick<IOrganizationPassportizationCreate, 'id'> &
          Omit<IOrganizationPassportizationCreate, 'organizationId'>
      >({
        query: (data) => ({
          method: 'PATCH',
          url: `dictionary/api/v1/institution-passportization/${data.id}`,
          type: 'patch',
          data: {
            ...data,
            lightingPointsCount: data.availabilityExploitation
              ? 0
              : data.lightingPointsCount,
            waterSupplySewerageHeatingArea: data.availabilityExploitation
              ? 0
              : data.waterSupplySewerageHeatingArea,
            ovensCount: data.cityCentralHeatingNetwork ? 0 : data.ovensCount,
            smallBoilerRoomsCount: data.cityCentralHeatingNetwork
              ? 0
              : data.smallBoilerRoomsCount,
            boilerRoomsCount: data.cityCentralHeatingNetwork
              ? 0
              : data.boilerRoomsCount,
          },
        }),
        transformResponse: (response: IOrganizationPassportization, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.organizationPassportization, id: args.id },
          { type: ApiKeys.organizationPassportization, id: 'LIST' },
        ],
      }),
    }),
  })

const useStateGetOrganizationPassportizations =
  organizationPassportizationApi.endpoints.getOrganizationPassportizations
    .useQueryState
const useStateGetOrganizationPassportization =
  organizationPassportizationApi.endpoints.getOrganizationPassportization
    .useQueryState

export const {
  useGetOrganizationPassportizationsQuery,
  useGetOrganizationPassportizationQuery,
  useCreateOrganizationPassportizationMutation,
  useUpdateOrganizationPassportizationMutation,
  usePartialUpdateOrganizationPassportizationMutation,
  util,
  usePrefetch,
} = organizationPassportizationApi

export {
  useStateGetOrganizationPassportizations,
  useStateGetOrganizationPassportization,
}
