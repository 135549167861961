import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationLocalityRepository from '@app/api/repositories/organization-locality-repository'
import { IOrganizationLocalityActions } from '@app/interfaces/organization/organization-locality'
import {
  CLEAR_ORGANIZATION_LOCALITY,
  CREATE_ORGANIZATION_LOCALITY,
  DELETE_ORGANIZATION_LOCALITY,
  FETCH_ORGANIZATION_LOCALITIES_FAILURE,
  FETCH_ORGANIZATION_LOCALITIES_REQUEST,
  FETCH_ORGANIZATION_LOCALITIES_SUCCESS,
  FETCH_ORGANIZATION_LOCALITY_FAILURE,
  FETCH_ORGANIZATION_LOCALITY_REQUEST,
  FETCH_ORGANIZATION_LOCALITY_SUCCESS,
  UPDATE_ORGANIZATION_LOCALITY,
} from '@app/store/types/organization-locality'
import axios from 'axios'

export const getOrganizationLocality =
  (args: numberArgs) => async (dispatch, message) => {
    dispatch(fetchOrganizationLocalityRequest())
    await organizationLocalityRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationLocalitySuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationLocalityFailure(error?.message, error?.code),
          )
        }
      })
  }

export const getOrganizationLocalities =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationLocalitiesRequest())
    await organizationLocalityRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchOrganizationLocalitiesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationLocalitiesFailure(error?.message, error?.code),
          )
        }
      })
  }

const fetchOrganizationLocalitiesRequest = (): IOrganizationLocalityActions => {
  return {
    type: FETCH_ORGANIZATION_LOCALITIES_REQUEST,
  }
}

const fetchOrganizationLocalitiesSuccess = (
  organizationLocalities,
  count,
): IOrganizationLocalityActions => {
  return {
    type: FETCH_ORGANIZATION_LOCALITIES_SUCCESS,
    payload: { organizationLocalities, count },
  }
}

const fetchOrganizationLocalitiesFailure = (
  message,
  code,
): IOrganizationLocalityActions => {
  return {
    type: FETCH_ORGANIZATION_LOCALITIES_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationLocalityRequest = (): IOrganizationLocalityActions => {
  return {
    type: FETCH_ORGANIZATION_LOCALITY_REQUEST,
  }
}

const fetchOrganizationLocalitySuccess = (
  organizationLocality,
): IOrganizationLocalityActions => {
  return {
    type: FETCH_ORGANIZATION_LOCALITY_SUCCESS,
    payload: organizationLocality,
  }
}

const fetchOrganizationLocalityFailure = (
  message,
  code,
): IOrganizationLocalityActions => {
  return {
    type: FETCH_ORGANIZATION_LOCALITY_FAILURE,
    payload: { message, code },
  }
}

export function clearOrganizationLocalities() {
  return {
    type: CLEAR_ORGANIZATION_LOCALITY,
    payload: null,
  }
}

export function updateOrganizationLocality(
  organizationLocality,
): IOrganizationLocalityActions {
  return {
    type: UPDATE_ORGANIZATION_LOCALITY,
    payload: organizationLocality,
  }
}

export function createOrganizationLocality(
  organizationLocality,
): IOrganizationLocalityActions {
  return {
    type: CREATE_ORGANIZATION_LOCALITY,
    payload: organizationLocality,
  }
}

export function deleteOrganizationLocality(
  organizationLocality,
): IOrganizationLocalityActions {
  return {
    type: DELETE_ORGANIZATION_LOCALITY,
    payload: organizationLocality,
  }
}
