export const FETCH_BENEFIT_TYPES_REQUEST = 'FETCH_BENEFIT_TYPES_REQUEST'
export const FETCH_BENEFIT_TYPES_SUCCESS = 'FETCH_BENEFIT_TYPES_SUCCESS'
export const FETCH_BENEFIT_TYPES_FAILURE = 'FETCH_BENEFIT_TYPES_FAILURE'

export const FETCH_BENEFIT_TYPE_REQUEST = 'FETCH_BENEFIT_TYPE_REQUEST'
export const FETCH_BENEFIT_TYPE_SUCCESS = 'FETCH_BENEFIT_TYPE_SUCCESS'
export const FETCH_BENEFIT_TYPE_FAILURE = 'FETCH_BENEFIT_TYPE_FAILURE'

export const FETCH_BENEFIT_TYPE_CLEANUP = 'FETCH_BENEFIT_TYPE_CLEANUP'
export const CLEAR_BENEFIT_TYPES = 'CLEAR_BENEFIT_TYPES'
export const CLEAR_BENEFIT_TYPE = 'CLEAR_BENEFIT_TYPE'
export const REFRESH_BENEFIT_TYPE = 'REFRESH_BENEFIT_TYPE'

export const CREATE_BENEFIT_TYPE = 'CREATE_BENEFIT_TYPE'
export const UPDATE_BENEFIT_TYPE = 'UPDATE_BENEFIT_TYPE'
export const REMOVE_BENEFIT_TYPE = 'REMOVE_BENEFIT_TYPE'
