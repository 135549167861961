import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IEducationLanguage } from '@app/interfaces/education/education-language'
import EducationLangaugeForm from '@app/pages/reference-book-page-details/education-language/components/education-language-form/education-language-form'
import { getEducationLanguages } from '@app/store/actions/education-language-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _educationLanguages: IEducationLanguage[]
  _state: string
}

const ReferenceBookEducationLanguages: React.FC<props> = ({
  _educationLanguages,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('educationLanguage.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_educationLanguages))
      dispatch(getEducationLanguages({ source }, t('educationLanguage.error')))
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('educationLanguage.educationLanguage'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
      sorter: (a, b) => Number(a.status[0]) - Number(b.status[0]),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <EducationLangaugeForm
            edit={true}
            educationLanguage={record.educationLanguage}
          />
        ) : null,
    },
  ]

  const data = !isEmpty(_educationLanguages)
    ? _educationLanguages.map((educationLanguage) => {
        return {
          key: educationLanguage.id,
          id: educationLanguage.id,
          name: localizeData(educationLanguage?.data)?.name,
          status: [educationLanguage.status],
          educationLanguage: educationLanguage,
        }
      })
    : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <EducationLangaugeForm />
          ) : null
        }
        title={t('educationLanguage.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _educationLanguages: state.educationLanguage.educationLanguages,
    _state: state.educationLanguage.state,
  }
}

export default connect(mapStateToProps)(ReferenceBookEducationLanguages)
