import { DownOutlined } from '@ant-design/icons'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import {
  IMeals,
  IMenuContent,
  IProducts,
} from '@app/interfaces/organization-menu'
import { round } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MenuBodyItemImage from '../../menu-meal-image/menu-body-item-image'
interface props {
  menuContent: IMenuContent
  pupilCount: number
  workDate: string
}

const MenuBodyItem: React.FC<props> = ({
  menuContent,
  pupilCount,
  workDate,
}) => {
  const [t] = useTranslation()
  const { localizeData } = useLocalization()
  const [expanded, setExpanded] = useState<boolean>(false)
  const { selectors } = useCreateSelector()
  const { menuByPupil, extraMenu } = useSelector(selectors.menus.menusState)

  const toggleExpanded = () => {
    setExpanded((prev) => !prev)
  }

  const calculateMeal = (meal: IMeals) => {
    const isMonday = moment(workDate).day() === 1
    let result = 0
    meal.products.forEach((product) => {
      if (
        isMonday &&
        menuContent.mealType.calculationStrategy === 'YESTERDAY'
      ) {
        const now = moment(workDate)
        while ([0, 1, 6].includes(now.day())) now.subtract(1, 'days')
        const lastMenu = menuByPupil.find(
          (item) => item.workDate === now.format('yyyy-MM-DD'),
        )
        if (lastMenu) {
          result += product.amount * lastMenu.pupilsCount
          return
        }
        if (extraMenu?.length) {
          result += product.amount * extraMenu[0]?.pupilsCount
          return
        }
      }
      result += product.amount
    })
    return round(result, 2)
  }

  const calculateProduct = (product: IProducts): number => {
    const isMonday = moment(workDate).day() === 1
    if (isMonday && menuContent.mealType.calculationStrategy === 'YESTERDAY') {
      const now = moment(workDate)
      while ([0, 1, 6].includes(now.day())) now.subtract(1, 'days')
      const lastMenu = menuByPupil.find(
        (item) => item.workDate === now.format('yyyy-MM-DD'),
      )
      if (lastMenu) {
        return round(product.amount * lastMenu.pupilsCount, 2)
      }
      if (extraMenu?.length) {
        return round(product.amount * extraMenu[0].pupilsCount, 2)
      }
    }
    return round(product.amount, 2)
  }

  return (
    <div className="item">
      <div className="item__meal-type" onClick={toggleExpanded}>
        <p>{localizeData(menuContent.mealType.data)?.name}</p>
        <div
          className={`arrow ${expanded ? 'arrow-expanded' : 'arrow-collapsed'}`}
        >
          <DownOutlined />
        </div>
      </div>
      {menuContent.meals.map((meal, index) => {
        return (
          <div
            key={index}
            className={`item__meal-body ${
              expanded ? 'meal-expanded' : 'meal-collapsed'
            }`}
          >
            <div className="item__meal-top">
              <p className="title">{localizeData(meal.data)?.name}</p>
              <p className="amount">
                {calculateMeal(meal)} {t('general.gram')}
              </p>
            </div>
            <div className="item__meal-bottom">
              <MenuBodyItemImage fileId={meal.fileId} />
              <div className="products">
                {meal.products.map((product, index) => {
                  return (
                    <div className="products__product" key={index}>
                      <p className="products__product--title">
                        {localizeData(product.data)?.name}
                      </p>
                      <p className="products__product--amount">
                        {`${calculateProduct(product)} 
                        ${localizeData(product.measurement.data)?.name}`}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MenuBodyItem
