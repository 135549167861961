import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganization } from '@app/interfaces/organization/organization'
import ThreeDots from '@app/shared/loader/ThreeDots'
import { useGetOrganizationSchedulesQuery } from '@app/store/rtk/api/organization/organization-schedule-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  organization: IOrganization | undefined
}

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_ORGANIZATION_CLASSIFICATOR_EDIT,
]

const OrganizationScheduleItem: React.FC<props> = ({ organization }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data, isLoading } = useGetOrganizationSchedulesQuery('')

  return (
    <>
      {isLoading ? (
        <ThreeDots style="dot-pulse" height={86} padding={24} />
      ) : (
        <Form.Item
          label={t('organizationSchedule.title')}
          name={'organizationSchedule'}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          initialValue={
            localize(data?.response as any)?.find(
              (schedule) =>
                schedule.id === organization?.organizationSchedule?.id,
            )?.id
          }
        >
          <Select allowClear={true} disabled={!rolesChecker(permissions)}>
            {sortByName(localize(data?.response as any))?.map(
              (organizationSchedule) => {
                if (organizationSchedule.status) {
                  return (
                    <Select.Option
                      key={organizationSchedule.id}
                      value={organizationSchedule.id}
                    >
                      {organizationSchedule.data.name}
                    </Select.Option>
                  )
                }
                return null
              },
            )}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

export default OrganizationScheduleItem
