import { IMvdUser } from '@app/interfaces/mvd-user'
import axios, { AxiosResponse } from 'axios'
import { mvdArgs } from './i-repository'

const generateRequest = (
  pin: string,
  passport: string,
  languageId: string,
): string => {
  return `
    <x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:per="urn:megaware:/mediate/ips/PC/PersonDocInfoService/PersonDocInfoService.wsdl">
    <x:Header/>
    <x:Body>
        <per:CEPRequest>
            <per:AuthInfo>
                <per:userSessionId>?</per:userSessionId>
                <per:WS_ID></per:WS_ID>
                <per:LE_ID></per:LE_ID>
            </per:AuthInfo>
            <per:Data>&lt;?xml version=&quot;1.0&quot; encoding=&quot;utf-8&quot;?&gt; &lt;DataCEPRequest&gt; &lt;pinpp&gt;${pin}&lt;/pinpp&gt;&lt;document&gt;${passport}&lt;/document&gt; &lt;langId&gt;${languageId}&lt;/langId&gt;&lt;/DataCEPRequest&gt;</per:Data>
            <per:Signature></per:Signature>
            <per:PublicCert></per:PublicCert>
            <per:SignDate></per:SignDate>
        </per:CEPRequest>
    </x:Body>
    </x:Envelope>
`
}

class MvdRepository {
  getOne(args: mvdArgs): Promise<AxiosResponse<IMvdUser>> {
    return axios.post(
      `https://dct.mpe.uz/api/ips/PersonDocInfoService`,
      generateRequest(args.pin, args.passport, args.languageId),
      {
        cancelToken: args.source?.token,
        headers: {
          'Content-Type': 'text/xml; charset=utf-8',
          SOAPAction: '/mediate/ips/PC/PersonDocInfoService',
        },
      },
    )
  }
}
export default new MvdRepository()
