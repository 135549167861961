import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { useGetEducationGradesQuery } from '@app/store/rtk/api/education/education-grade-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Col, Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  isOpen: boolean
  onChange?: (e: any) => any
  edit?: boolean
}

const EducationGradeItem: React.FC<props> = ({ isOpen, onChange, edit }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data } = useGetEducationGradesQuery('')

  return (
    <Col span={12}>
      <Form.Item
        label={t('educationGrade.educationGrade')}
        name={'educationGradeId'}
        rules={[
          {
            required: true,
            message: t('errors.requiredMessage'),
          },
        ]}
      >
        <Select
          onChange={onChange}
          disabled={!isOpen || (edit && !rolesChecker([AUTHORITIES.ADMIN]))}
        >
          {sortByName(localize(data?.response))?.map((grade) =>
            grade.status ? (
              <Select.Option key={grade.id} value={grade.id}>
                {grade.data?.name}
              </Select.Option>
            ) : null,
          )}
        </Select>
      </Form.Item>
    </Col>
  )
}

export default EducationGradeItem
