import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEducationGrade,
  IEducationGradeState,
} from '@app/interfaces/education/education-grade'
import {
  CLEAR_EDUCATION_GRADES,
  CREATE_EDUCATION_GRADE,
  DELETE_EDUCATION_GRADE,
  FETCH_EDUCATION_GRADES_FAILURE,
  FETCH_EDUCATION_GRADES_REQUEST,
  FETCH_EDUCATION_GRADES_SUCCESS,
  FETCH_EDUCATION_GRADE_FAILURE,
  FETCH_EDUCATION_GRADE_REQUEST,
  FETCH_EDUCATION_GRADE_SUCCESS,
  UPDATE_EDUCATION_GRADE,
} from '@app/store/types/education-grade'

const initialState: IEducationGradeState = {
  educationGrade: {} as IEducationGrade,
  educationGrades: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const educationGradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EDUCATION_GRADES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_GRADES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationGrades: action.payload.educationGrades,
        count: action.payload.count,
      }
    case FETCH_EDUCATION_GRADES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationGrades: [],
      }
    case FETCH_EDUCATION_GRADE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_GRADE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationGrade: action.payload,
      }
    case FETCH_EDUCATION_GRADE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationGrade: {},
      }
    case CLEAR_EDUCATION_GRADES:
      return { ...state, educationGrades: [] }
    case CREATE_EDUCATION_GRADE:
      return {
        ...state,
        educationGrades: [...state.educationGrades, action.payload],
      }
    case UPDATE_EDUCATION_GRADE:
      const updatedEducationGrade = state.educationGrades.map(function (
        educationGrade,
        index,
      ) {
        if (action.payload.id === educationGrade.id) {
          return action.payload
        }
        return educationGrade
      })
      return { ...state, educationGrades: updatedEducationGrade }
    case DELETE_EDUCATION_GRADE:
      const editedEducationGrade = state.educationGrades.filter(function (
        educationGrade,
      ) {
        if (action.payload.id === educationGrade.id) {
          return false
        }
        return educationGrade
      })
      return { ...state, educationGrades: editedEducationGrade }
    default:
      return state
  }
}
