import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IEmployeeEducation } from '@app/interfaces/employee/employee-education'
import { AxiosResponse } from 'axios'

class EmployeeEducationRepository implements IRepository<IEmployeeEducation> {
  create(data: IEmployeeEducation): Promise<AxiosResponse<IEmployeeEducation>> {
    return bbitApi.post('/employee/api/v1/employee-education-dictionary', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEmployeeEducation[]>> {
    return bbitApi.get('/employee/api/v1/employee-education-dictionary', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEmployeeEducation>> {
    return bbitApi.get(
      `/employee/api/v1/employee-education-dictionary/${args.data}`,
      { cancelToken: args.source?.token },
    )
  }

  update(data: IEmployeeEducation): Promise<AxiosResponse<IEmployeeEducation>> {
    return bbitApi.put(
      `/employee/api/v1/employee-education-dictionary/${data.id}`,
      data,
    )
  }
}

export default new EmployeeEducationRepository()
