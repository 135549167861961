import { FilterOutlined } from '@ant-design/icons'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import usePagination from '@app/hooks/usePagination'
import useQuery from '@app/hooks/useQuery'
import { getDistricts } from '@app/store/actions/districts-actions'
import {
  clearOrganizations,
  getOrganizations,
} from '@app/store/actions/organization-actions'
import { getMenuByPupil } from '@app/store/actions/organization-menus-actions'
import { getRegions } from '@app/store/actions/regions-actions'
import { findById } from '@app/utils/find-by-id/find-by-id'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, DatePicker, Form, Select, Spin } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import axios from 'axios'
import { cloneDeep, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

interface IPathParams {
  id?: string
}

const MenuHeaderFilters: React.FC = () => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const params: IPathParams = useParams()
  const [regionId, setRegionId] = useState<number>(0)
  const { serializeQuery } = useQuery()
  const { RangePicker } = DatePicker
  const { lSelectors, selectors } = useCreateSelector()
  const { state } = useSelector(selectors.districts.districtsState)
  const regions = useSelector(lSelectors.regions.regions)
  const districts = useSelector(lSelectors.districts.districts)
  const organizations = useSelector(selectors.organization.organizations)
  const [tableDate, setTableDate] = useState<any>([moment(), moment()])
  const [orgDisabled, setOrgDisabled] = useState<boolean>(
    params?.id ? false : true,
  )
  const { searchParams, requestFunction, handleTableChange, setSearchParams } =
    usePagination(`page=0&size=100`, {
      page: 1,
      size: 100,
    })

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(regions))
      dispatch(getRegions({ source }, t('errors.dictionary.regions')))
    if (isEmpty(districts))
      dispatch(getDistricts({ source }, t('errors.dictionary.districts')))
    return () => {
      source.cancel('Component got unmounted')
      dispatch(clearOrganizations())
    }
  }, [])

  const onRegionChangeHandler = (value) => {
    setRegionId(value)
    form.setFieldsValue({ districtId: undefined })
    form.setFieldsValue({ organizationId: undefined })
  }

  const onDistrictChangeHandler = (value) => {
    dispatch(
      getOrganizations(
        {
          params: `page=0&size=500&regionId.equals=${regionId}&districtId.equals=${value}`,
        },
        t('organization.error'),
      ),
    )
    form.setFieldsValue({ organizationId: undefined })
  }

  const onOrganizationChangeHandler = (value) => {
    value ? setOrgDisabled(false) : setOrgDisabled(true)
  }

  const getRegionName = (regionId) => {
    return findById(regions, regionId)?.data.name
  }

  const findDistricts = (id) => {
    return isEmpty(districts)
      ? []
      : districts.filter((district) => district.regionId === id)
  }

  const onFinish = (values: any) => {
    const fromDate = cloneDeep(tableDate[0])
    const isWeekdayOrMonday = [0, 1, 6].includes(fromDate.day())
    if (isWeekdayOrMonday) {
      while ([0, 1, 6].includes(fromDate.day())) fromDate.subtract(1, 'days')
      dispatch(
        getMenuByPupil(
          {
            params: `organizationId=${
              params.id || form.getFieldValue('organizationId')
            }&workDateFrom=${fromDate?.format(
              'yyyy-MM-DD',
            )}&workDateTo=${fromDate?.format('yyyy-MM-DD')}`,
          },
          t('organizationMenu.calendar.errors.byPupil'),
          true,
        ),
      )
    }
    dispatch(
      getMenuByPupil(
        {
          params: `organizationId=${
            params.id || form.getFieldValue('organizationId')
          }&workDateFrom=${tableDate[0]?.format(
            'yyyy-MM-DD',
          )}&workDateTo=${tableDate[1]?.format('yyyy-MM-DD')}`,
        },
        t('organizationMenu.calendar.errors.byPupil'),
      ),
    )
  }
  const onReset = (values: any) => {
    const newValues = {
      regionId: null,
      districtId: null,
      id: null,
    }
    requestFunction(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
    form.resetFields()
  }

  return (
    <Spin spinning={state === LOADING}>
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        <div className="menu-calendar-by-pupil__header--form">
          <RangePicker
            onCalendarChange={setTableDate}
            value={tableDate}
            locale={locale}
          />
          {!params?.id && (
            <div className="filters">
              <Form.Item name={'regionId'}>
                <Select
                  placeholder={t('region.region')}
                  showSearch={true}
                  onChange={onRegionChangeHandler}
                >
                  {sortByName(regions).map(({ data: { name }, id }) => {
                    return (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item name={'districtId'}>
                <Select
                  showSearch={true}
                  placeholder={t('district.district')}
                  onChange={onDistrictChangeHandler}
                >
                  {sortByName(findDistricts(regionId))
                    .filter((district) => {
                      return district.data.name !== getRegionName(regionId)
                    })
                    .map((district) => {
                      return (
                        <Select.Option key={district.id} value={district.id}>
                          {district.data.name}
                        </Select.Option>
                      )
                    })}
                </Select>
              </Form.Item>
              <Form.Item name={'organizationId'}>
                <Select
                  showSearch={true}
                  placeholder={t('organization.title')}
                  onChange={onOrganizationChangeHandler}
                >
                  {sortByName(organizations)?.map((organization) => {
                    return (
                      <Select.Option
                        key={organization.id}
                        value={organization.id}
                      >
                        {organization.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </div>
          )}
        </div>
        <div className="menu-calendar-by-pupil__header--btn">
          <Button type="primary" htmlType="submit" disabled={orgDisabled}>
            <FilterOutlined />
            {t('general.filter')}
          </Button>
          <Button
            htmlType={'reset'}
            style={{ margin: '0 8px' }}
            onClick={onReset}
          >
            {t('general.reset')}
          </Button>
        </div>
      </Form>
    </Spin>
  )
}

export default MenuHeaderFilters
