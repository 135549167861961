import { AUTHORITIES } from '@app/constants/authorities'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganization } from '@app/interfaces/organization/organization'
import { setCurrentInstitutionTypeId } from '@app/store/actions/institutions-types-actions'
import { useGetInstitutionTypesQuery } from '@app/store/rtk/api/organization-passport/institution-type-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, FormInstance, Select } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import '../organization-form.less'
interface props {
  disabled: boolean
  organization?: IOrganization
  form: FormInstance<any>
}

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_ORGANIZATION_CLASSIFICATOR_EDIT,
]

const InstitutionTypesItem: React.FC<props> = ({
  disabled,
  organization,
  form,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { selectors } = useCreateSelector()
  const { currentOrganizationSectorID } = useSelector(
    selectors.organizationSector.organizationsSectorState,
  )
  const { data } = useGetInstitutionTypesQuery('')
  const { localize } = useLocalization()

  const filtered = useMemo(
    () =>
      data?.response?.filter(
        (type) => type.organizationSectorId === currentOrganizationSectorID,
      ),
    [currentOrganizationSectorID, data],
  )

  useEffect(() => {
    const id = filtered?.find(
      (type) => type.id === organization?.passport?.institutionType?.id,
    )?.id
    if (organization && id) {
      dispatch(setCurrentInstitutionTypeId(id))
      form.setFieldsValue({
        institutionType: filtered?.find(
          (type) => type.id === organization?.passport?.institutionType?.id,
        )?.id,
      })
    }
  }, [filtered])

  const onInstitutionTypeIdChangeHandler = (id: number) => {
    dispatch(setCurrentInstitutionTypeId(id))
    form.setFieldsValue({ institutionSubType: undefined })
  }

  return (
    <>
      {
        <Form.Item
          label={`${t('institutionType.institutionType')}`}
          name={'institutionType'}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
        >
          <Select
            allowClear={true}
            disabled={!rolesChecker(permissions) && disabled}
            onChange={onInstitutionTypeIdChangeHandler}
          >
            {sortByName(localize(filtered as any))?.map((institutionType) => {
              if (institutionType.status) {
                return (
                  <Select.Option
                    key={institutionType.id}
                    value={institutionType.id}
                  >
                    {institutionType.data.name}
                  </Select.Option>
                )
              }
              return null
            })}
          </Select>
        </Form.Item>
      }
    </>
  )
}

export default InstitutionTypesItem
