import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const usePseudoRoles = () => {
  const [t, i18n] = useTranslation()
  const [pseudoRoles, setPseudoRoles] = useState([
    {
      id: 1,
      name: t('userRoles.pseudoRoles'),
      roles: [
        { id: 7, name: `ROLE_REGION_IKT`, translate: t('userRoles.regionIKT') },
        {
          id: 8,
          name: `ROLE_DISTRICT_IKT`,
          translate: t('userRoles.districtIKT'),
        },
        { id: 6, name: `ROLE_MANAGER`, translate: t('userRoles.manager') },
        { id: 2, name: `ROLE_USER`, translate: t('userRoles.user') },
      ],
    },
  ])
  return pseudoRoles
}

export default usePseudoRoles
