import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IDistrict,
  IDistrictState,
} from '@app/interfaces/organization/districts'
import {
  CLEAR_DISTRICTS,
  CREATE_DISTRICT,
  DELETE_DISTRICT,
  FETCH_DISTRICTS_FAILURE,
  FETCH_DISTRICTS_REGIONID_FAILURE,
  FETCH_DISTRICTS_REGIONID_REQUEST,
  FETCH_DISTRICTS_REGIONID_SUCCESS,
  FETCH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICT_FAILURE,
  FETCH_DISTRICT_REQUEST,
  FETCH_DISTRICT_SUCCESS,
  UPDATE_DISTRICT,
} from '@app/store/types/districts'

const initialState: IDistrictState = {
  district: {} as IDistrict,
  districts: [],
  districtsByRegionId: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const districtReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISTRICTS_REGIONID_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_DISTRICTS_REGIONID_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        districtByRegionId: action.payload.districtByRegionId,
        count: action.payload.count,
      }
    case FETCH_DISTRICTS_REGIONID_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        districtByRegionId: [],
      }
    case FETCH_DISTRICTS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_DISTRICTS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        districts: action.payload.districts,
        count: action.payload.count,
      }
    case FETCH_DISTRICTS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        districts: [],
      }
    case FETCH_DISTRICT_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_DISTRICT_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        district: action.payload,
      }
    case FETCH_DISTRICT_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        district: {},
      }
    case CLEAR_DISTRICTS:
      return { ...state, districts: [] }
    case CREATE_DISTRICT:
      return { ...state, districts: [...state.districts, action.payload] }
    case UPDATE_DISTRICT:
      const updatedDistricts = state.districts.map(function (district, index) {
        if (action.payload.id === district.id) {
          return action.payload
        }
        return district
      })
      return { ...state, districts: updatedDistricts }
    case DELETE_DISTRICT:
      const editedDistricts = state.districts.filter(function (district) {
        if (action.payload.id === district.id) {
          return false
        }
        return district
      })
      return { ...state, districts: editedDistricts }
    default:
      return state
  }
}
