import { IEmployeeState } from '@app/interfaces/employee/employee'
import { IFile } from '@app/interfaces/files'
import EmployeeEditForm from '@app/pages/organizations/components/organization-employee/components/employee-organization/components/employee-edit/components/employee-edit-form/employee-edit-form'
import Loader from '@app/shared/loader/loader'
import { clearEmployee, getEmployee } from '@app/store/actions/employee-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

interface props {
  _employeesState: IEmployeeState
  avatar: IFile
}

export interface eParams {
  id?: string
  edit?: string
  employeeId?: string
}

const EmployeeEdit: React.FC<props> = ({ _employeesState, avatar }) => {
  const { employee } = _employeesState
  const [t] = useTranslation()
  const params: eParams = useParams()
  const dispatch = useDispatch()
  setDocumentTitle(t('employee.edit'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getEmployee(
        { data: Number(params.employeeId), source },
        t('employee.error'),
      ),
    )
    return () => {
      dispatch(clearEmployee())
      source.cancel('Component got unmounted')
    }
  }, [])

  return (
    <React.Fragment>
      {isEmpty(employee) ? (
        <Loader />
      ) : (
        <EmployeeEditForm avatar={avatar} _employee={employee} />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    _employeesState: state.organizationEmployee,
    avatar: state.files.preUploadedPhoto,
  }
}

export default connect(mapStateToProps)(EmployeeEdit)
