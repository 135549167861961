export const FETCH_WAREHOUSE_DATA_REQUEST = 'FETCH_WAREHOUSE_DATA_REQUEST'
export const FETCH_WAREHOUSE_DATA_SUCCESS = 'FETCH_WAREHOUSE_DATA_SUCCESS'
export const FETCH_WAREHOUSE_DATA_FAILURE = 'FETCH_WAREHOUSE_DATA_FAILURE'

export const FETCH_WAREHOUSE_INVOICE_REQUEST = 'FETCH_WAREHOUSE_INVOICE_REQUEST'
export const FETCH_WAREHOUSE_INVOICE_SUCCESS = 'FETCH_WAREHOUSE_INVOICE_SUCCESS'
export const FETCH_WAREHOUSE_INVOICE_FAILURE = 'FETCH_WAREHOUSE_INVOICE_FAILURE'

export const FETCH_WAREHOUSE_CLEANUP = 'FETCH_WAREHOUSE_CLEANUP'
export const CLEAR_WAREHOUSE_DATA = 'CLEAR_WAREHOUSE_DATA'
export const CLEAR_WAREHOUSE_INVOICE = 'CLEAR_WAREHOUSE_INVOICE'

export const TOGGLE_WAREHOUSE_ADD_INVOICE_MODAL =
  'TOGGLE_WAREHOUSE_ADD_INVOICE_MODAL'
