import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import CamerasRepository from '@app/api/repositories/cameras-repository'
import { ICamera } from '@app/interfaces/cameras'
import { IOrganization } from '@app/interfaces/organization/organization'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { createCamera, updateCamera } from '@app/store/actions/cameras-actions'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

interface props {
  camera?: ICamera
  edit?: boolean
  _organization: IOrganization
}

interface params {
  id?: string
}

const CameraForm: React.FC<props> = ({ camera, edit, _organization }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const params: params = useParams()
  const [visible, setVisible] = useState<boolean>(false)
  const dispatch = useDispatch()

  const showDrawer = () => setVisible(true)

  const onClose = () => setVisible(false)

  const cameraTypes = [
    {
      value: 'ENTRANCE',
      text: t('cameras.input'),
    },
    {
      value: 'EXIT',
      text: t('cameras.output'),
    },
  ]

  const cameraProtocols = [
    {
      value: 'http',
      text: t('cameras.http'),
    },
    {
      value: 'https',
      text: t('cameras.https'),
    },
  ]

  const onFinish = async (values: any) => {
    const createValues = {
      ...values,
      status: values.status ? 'ACTIVE' : 'STOPPED',
      organizationId: _organization?.id
        ? _organization.id
        : camera?.organizationId,
      regionId: _organization?.region?.id ? _organization.region.id : undefined,
      districtId: _organization?.district?.id
        ? _organization.district.id
        : undefined,
    }
    const editValues = {
      ...values,
      status: values.status ? 'ACTIVE' : 'STOPPED',
      organizationId: _organization?.id
        ? _organization.id
        : camera?.organizationId,
      regionId: camera?.regionId ? camera.regionId : undefined,
      districtId: camera?.districtId ? camera.districtId : undefined,
    }

    setConfirmLoading(true)
    const camerasApi = camera
      ? CamerasRepository.update
      : CamerasRepository.create

    await camerasApi(edit ? editValues : createValues)
      .then((response: AxiosResponse<ICamera>) => {
        if (camera && response.status === 200) {
          dispatch(updateCamera(response.data))
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.updated'))
          onClose()
        }
        if (!camera && response.status === 200) {
          dispatch(createCamera(response.data))
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.created'))
          onClose()
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('errors.corsError'))
        setConfirmLoading(false)
      })
  }

  return (
    <>
      <Button
        className={edit ? '' : 'institutions__add'}
        onClick={showDrawer}
        type={edit ? 'link' : 'primary'}
      >
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          <span>
            {' '}
            <PlusOutlined /> {t('general.add')}
          </span>
        )}
      </Button>
      <Drawer
        title={edit ? t('cameras.edit') : t('cameras.add')}
        width={720}
        open={visible}
        destroyOnClose={true}
        footer={[
          <React.Fragment key={1}>
            <Button
              onClick={form.submit}
              disabled={confirmLoading}
              htmlType={'submit'}
              type="primary"
            >
              {edit ? t('general.edit') : t('general.add')}
            </Button>
            <Button
              disabled={confirmLoading}
              onClick={onClose}
              style={{ marginLeft: 8 }}
            >
              {t('general.back')}
            </Button>
          </React.Fragment>,
        ]}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<CloseOutlined onClick={onClose} />}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{ position: 'relative' }}
            initialValues={{
              ...camera,
              status: edit ? camera?.status === 'ACTIVE' : true,
            }}
          >
            <Form.Item name={'id'} hidden={true}>
              <Input />
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={'remoteAddress'}
                  label={t('cameras.IP')}
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                    {
                      validator: async (_, fullName) => {
                        if (fullName?.trim().length) {
                          if (
                            !fullName?.match(
                              /^(?:(?:1\d?\d|[1-9]?\d|2[0-4]\d|25[0-5])\.){3}(?:1\d?\d|[1-9]?\d|2[0-4]\d|25[0-5])(?:[:]\d+)?$/,
                            )
                          ) {
                            return Promise.reject(
                              new Error(t('errors.ipRegularError')),
                            )
                          }
                        }
                      },
                    },
                  ]}
                >
                  <Input disabled={confirmLoading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'port'}
                  label={t('cameras.port')}
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                >
                  <Input disabled={confirmLoading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('cameras.type')}
                  name={'type'}
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                >
                  <Select allowClear={true}>
                    {cameraTypes.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {type.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('cameras.login')}
                  name={'username'}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                    {
                      min: 8,
                      max: 16,
                      message: t('errors.minMaxMessage', { min: 8, max: 16 }),
                    },
                  ]}
                  label={t('cameras.password')}
                  name={'password'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('general.status')}
                  name={'status'}
                  valuePropName={'checked'}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('cameras.protocol')}
                  name={'protocol'}
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                >
                  <Select allowClear={true}>
                    {cameraProtocols.map((protocol) => (
                      <Select.Option
                        key={protocol.value}
                        value={protocol.value}
                      >
                        {protocol.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default connect(null, null)(CameraForm)
