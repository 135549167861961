export const capitalize = (str: string) => {
  const lower = str.toLowerCase()
  return str.charAt(0).toUpperCase() + lower.slice(1)
}

export const capitalizeName = (fullName: string) => {
  let result = ''
  const parts = fullName.split(' ')
  parts.forEach((part, index) =>
    index !== parts.length - 1
      ? (result += capitalize(part) + ' ')
      : (result += capitalize(part)),
  )
  return result
}
