// pupils

export const GET_QUEUE_COUNT = 'GET_QUEUE_COUNT'
export const GET_ORGANIZATION_PUPILS = 'GET_ORGANIZATION_PUPILS'
export const GET_ORGANIZATION_PUPIL = 'GET_ORGANIZATION_PUPIL'
export const GET_ORGANIZATION_PUPILS_IN_QUEUE =
  'GET_ORGANIZATION_PUPILS_IN_QUEUE'
export const UPDATE_ORGANIZATION_PUPIL = 'UPDATE_ORGANIZATION_PUPIL'
export const DELETE_ORGANIZATION_PUPIL = 'DELETE_ORGANIZATION_PUPIL'
export const CLEAR_ORGANIZATION_PUPILS = 'CLEAR_ORGANIZATION_PUPILS'
export const CLEAR_ORGANIZATION_PUPIL = 'CLEAR_ORGANIZATION_PUPIL'
export const REFRESH_ORGANIZATION_PUPIL = 'REFRESH_ORGANIZATION_PUPIL'
export const REFRESH_ORGANIZATION_PUPIL_QUEUE =
  'REFRESH_ORGANIZATION_PUPIL_QUEUE'

export const FETCH_ORGANIZATION_PUPIL_REQUEST =
  'FETCH_ORGANIZATION_PUPIL_REQUEST'
export const FETCH_ORGANIZATION_PUPIL_SUCCESS =
  'FETCH_ORGANIZATION_PUPIL_SUCCESS'
export const FETCH_ORGANIZATION_PUPIL_FAILURE =
  'FETCH_ORGANIZATION_PUPIL_FAILURE'

export const FETCH_ORGANIZATION_PUPILS_REQUEST =
  'FETCH_ORGANIZATION_PUPILS_REQUEST'
export const FETCH_ORGANIZATION_PUPILS_SUCCESS =
  'FETCH_ORGANIZATION_PUPILS_SUCCESS'
export const FETCH_ORGANIZATION_PUPILS_FAILURE =
  'FETCH_ORGANIZATION_PUPILS_FAILURE'

export const FETCH_ORGANIZATION_PUPILS_FULL_REQUEST =
  'FETCH_ORGANIZATION_PUPILS_FULL_REQUEST'
export const FETCH_ORGANIZATION_PUPILS_FULL_SUCCESS =
  'FETCH_ORGANIZATION_PUPILS_FULL_SUCCESS'
export const FETCH_ORGANIZATION_PUPILS_FULL_FAILURE =
  'FETCH_ORGANIZATION_PUPILS_FULL_FAILURE'

export const FETCH_ORGANIZATION_PUPIL_QUEUE_REQUEST =
  'FETCH_ORGANIZATION_PUPIL_QUEUE_REQUEST'
export const FETCH_ORGANIZATION_PUPIL_QUEUE_SUCCESS =
  'FETCH_ORGANIZATION_PUPIL_QUEUE_SUCCESS'
export const FETCH_ORGANIZATION_PUPIL_QUEUE_FAILURE =
  'FETCH_ORGANIZATION_PUPIL_QUEUE_FAILURE'

export const FETCH_ORGANIZATION_PUPILS_QUEUE_REQUEST =
  'FETCH_ORGANIZATION_PUPILS_QUEUE_REQUEST'
export const FETCH_ORGANIZATION_PUPILS_QUEUE_SUCCESS =
  'FETCH_ORGANIZATION_PUPILS_QUEUE_SUCCESS'
export const FETCH_ORGANIZATION_PUPILS_QUEUE_FAILURE =
  'FETCH_ORGANIZATION_PUPILS_QUEUE_FAILURE'

export const FETCH_ORGANIZATION_PUPIL_CLEANUP =
  'FETCH_ORGANIZATION_PUPIL_CLEANUP'
