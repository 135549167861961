import bbitApi from '@app/api/make-request'
import mealFallback from '@app/assets/images/meal-fallback.png'
import { IFile } from '@app/interfaces/files'
import { Image } from 'antd'
import React, { useEffect, useState } from 'react'

interface props {
  fileId?: string
  width?: number
  height?: number
}

const MenuMealImage: React.FC<props> = ({
  fileId = '',
  width = 200,
  height = 200,
}) => {
  const [image, setImage] = useState<IFile | null>()
  useEffect(() => {
    if (fileId) {
      bbitApi
        .get(`${process.env.REACT_APP_HOST}files/api/v1/files/${fileId}`)
        .then((res) => setImage(res.data))
    } else setImage(null)
  }, [fileId])
  return (
    <Image
      width={width}
      height={height}
      src={
        image
          ? `${process.env.REACT_APP_HOST}files${image.baseURL}/750x750-${image.filePath}`
          : ''
      }
      fallback={mealFallback}
    />
  )
}

export default MenuMealImage
