import { ApiKeys } from '@app/constants/api-keys'
import {
  IOrganization,
  IOrganizationPatch,
} from '@app/interfaces/organization/organization'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationApi } from '.'
import { IRtkResponse } from '../../types'

export const organizationApi = emptyOrganizationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOrganizations: build.query<
      IRtkResponse<IOrganization[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/organizations',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganization[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.organization,
                id,
              })),
              { type: ApiKeys.organization, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organization, id: 'LIST' }],
    }),
    getOrganization: build.query<IOrganization, number>({
      query: (id) => ({
        url: `dictionary/api/v1/organizations/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.organization, id }],
    }),
    createOrganizationWithPassport: build.mutation<
      IRtkResponse<IOrganization>,
      Required<IOrganization>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/organizations/create-organization-with-institution-passport',
          data,
        }
      },
      transformResponse: (response: IOrganization, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.organization, id: 'LIST' }],
    }),
    createOrganization: build.mutation<
      IRtkResponse<IOrganization>,
      Required<IOrganization> & Omit<IOrganization, 'passport'>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/organizations',
          data,
        }
      },
      transformResponse: (response: IOrganization, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.organization, id: 'LIST' }],
    }),
    updateOrganization: build.mutation<
      IRtkResponse<IOrganization>,
      Required<IOrganization>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/organizations/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganization, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organization, id: arg.id },
      ],
    }),
    patchOrganization: build.mutation<
      IRtkResponse<IOrganization>,
      IOrganizationPatch
    >({
      query: (data) => ({
        method: 'PATCH',
        url: `dictionary/api/v1/organizations/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganization, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organization, id: arg.id },
      ],
    }),
  }),
})

const useStateGetOrganizationQuery =
  organizationApi.endpoints.getOrganization.useQueryState
const useStateGetOrganizationsQuery =
  organizationApi.endpoints.getOrganizations.useQueryState

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useCreateOrganizationWithPassportMutation,
  usePatchOrganizationMutation,
} = organizationApi

export { useStateGetOrganizationQuery, useStateGetOrganizationsQuery }
