import useLocalization from '@app/hooks/useLocalization'
import { IDrinkingWaterAvailableDuringYears } from '@app/interfaces/organization-passport/drinking-water-availables-during-years'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  availableDrinkingWater: IDrinkingWaterAvailableDuringYears[]
}

const AvailableDrinkingWaterItem: React.FC<props> = ({
  availableDrinkingWater,
}) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('drinkingWaterAvailable.title')}
        name={'drinkingWaterAvailable'}
      >
        <Select
          placeholder={t('institutionPlaceholders.drinkingWaterAvailable')}
        >
          {sortByName(localize(availableDrinkingWater)).map(
            (availableDrinkingWater, index) => {
              if (availableDrinkingWater.status) {
                return (
                  <Select.Option
                    key={index}
                    value={availableDrinkingWater?.id.toString()}
                  >
                    {availableDrinkingWater?.data?.name}
                  </Select.Option>
                )
              }
              return null
            },
          )}
        </Select>
      </Form.Item>
    </>
  )
}

export default AvailableDrinkingWaterItem
