import EmployeePositionRepository from '@app/api/repositories/employee-position-repository'
import usePseudoRoles from '@app/hooks/roles/pseudo-roles'
import useSuperRoles from '@app/hooks/roles/super-roles'
import useLocalization from '@app/hooks/useLocalization'
import { IEmployeePosition } from '@app/interfaces/employee/employee-position'
import { IRole, IUserGroupRoles } from '@app/interfaces/roles'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  createEmployeePosition,
  updateEmployeePosition,
} from '@app/store/actions/employee-position-actions'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  employeePosition?: IEmployeePosition
  edit?: boolean
  groupRoles: IUserGroupRoles[]
  _roles?: IRole[]
}

const EmployeePositionForm: React.FC<props> = ({
  employeePosition,
  edit,
  groupRoles,
  _roles,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { localize } = useLocalization()

  const newRoles = _roles?.map((role) => ({
    roleName: role.name,
  }))

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const rolesGroup = localize(groupRoles).find(
      (region) => region.data?.name === values.groupRoleId,
    )?.id
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues: IEmployeePosition = {
      id: values.id || null,
      sorting: values.sorting,
      status: values.status,
      mentor: values.mentor,
      data: languages,
      groupRoleId: Number(values.groupRoleId) || rolesGroup,
      accessRoles: newRoles!.filter((role) =>
        values.accessRoles.includes(role.roleName),
      ),
    }

    setConfirmLoading(true)
    const employeePositionSubmitApi = employeePosition
      ? EmployeePositionRepository.update
      : EmployeePositionRepository.create

    await employeePositionSubmitApi(newValues)
      .then((response: AxiosResponse<IEmployeePosition>) => {
        if (employeePosition && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateEmployeePosition(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!employeePosition && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createEmployeePosition(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('employeePosition.edit') : t('employeePosition.add')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: employeePosition?.id,
              sorting: employeePosition?.sorting || 0,
              mentor: edit ? employeePosition?.mentor : false,
              status: edit ? employeePosition?.status : true,
              groupRoleId: localize(groupRoles).find(
                (groupRole) => groupRole.id === employeePosition?.groupRoleId,
              )?.data?.name,
              accessRoles: employeePosition?.accessRoles.map(
                (role) => role.roleName,
              ),
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={employeePosition?.status === true}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -2147483648,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="accessRoles"
              label={t('employeePosition.permissions')}
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
            >
              <Checkbox.Group disabled={confirmLoading}>
                <Row gutter={8}>
                  <Col span={8}>
                    <Checkbox value={useSuperRoles()[0].roles[0].name}>
                      {useSuperRoles()[0].roles[0].name}
                    </Checkbox>
                  </Col>
                  {usePseudoRoles()[0].roles.map(({ name }) => (
                    <Col key={name} span={8}>
                      <Checkbox value={name}>{name}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'groupRoleId'}
              label={t('users.groupRoles')}
            >
              <Select disabled={confirmLoading}>
                {localize(groupRoles).map((groupRole, index) => {
                  return (
                    <Select.Option key={index} value={groupRole.id?.toString()}>
                      {groupRole.data?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              valuePropName="checked"
              name={'mentor'}
              label={t('employee.isMentor')}
            >
              <Switch />
            </Form.Item>
            <FormList
              confirmLoading={confirmLoading}
              object={employeePosition}
            />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    _roles: state.roles.roles,
  }
}

export default connect(mapStateToProps)(EmployeePositionForm)
