import { ApiKeys } from '@app/constants/api-keys'
import { IToiletLocation } from '@app/interfaces/organization-passport/toilet-location'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from './../../types'

export const toiletLocationsApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getToiletLocations: build.query<
      IRtkResponse<IToiletLocation[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/toilet-locations',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IToiletLocation[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.toiletLocation,
                id,
              })),
              { type: ApiKeys.toiletLocation, id: 'LIST' },
            ]
          : [{ type: ApiKeys.toiletLocation, id: 'LIST' }],
    }),
    getToiletLocation: build.query<IToiletLocation, number>({
      query: (id) => ({
        url: `dictionary/api/v1/toilet-locations/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.toiletLocation, id },
      ],
    }),
    createToiletLocation: build.mutation<
      IRtkResponse<IToiletLocation>,
      IToiletLocation
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/toilet-locations',
          data,
        }
      },
      transformResponse: (response: IToiletLocation, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.toiletLocation,
          id: args.id,
        },
        { type: ApiKeys.toiletLocation, id: 'LIST' },
      ],
    }),
    updateToiletLocation: build.mutation<
      IRtkResponse<IToiletLocation>,
      IToiletLocation
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/toilet-locations/${data.id}`,
        data,
      }),
      transformResponse: (response: IToiletLocation, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.toiletLocation, id: args.id },
        { type: ApiKeys.toiletLocation, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetToiletLocations =
  toiletLocationsApi.endpoints.getToiletLocations.useQueryState
const useStateGetToiletLocation =
  toiletLocationsApi.endpoints.getToiletLocation.useQueryState

export const {
  useGetToiletLocationsQuery,
  useLazyGetToiletLocationsQuery,
  useCreateToiletLocationMutation,
  useUpdateToiletLocationMutation,
  util,
  usePrefetch,
} = toiletLocationsApi

export { useStateGetToiletLocations, useStateGetToiletLocation }
