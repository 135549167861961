import {
  CLEAR_ALL,
  GET_CITIZENSHIP,
  GET_METRIC_NUMBER,
  GET_METRIC_SERIAL,
  GET_PUPIL_BIRTHDATE,
} from '@app/store/types/pupil-manual-add'

export function getCitizenship(citizenship: string) {
  return {
    type: GET_CITIZENSHIP,
    payload: citizenship,
  }
}

export function clearAllFields() {
  return {
    type: CLEAR_ALL,
    payload: null,
  }
}

export function getMetricNumber(metricNumber: string) {
  return {
    type: GET_METRIC_NUMBER,
    payload: metricNumber,
  }
}

export function getMetricSerial(metricSerial: string) {
  return {
    type: GET_METRIC_SERIAL,
    payload: metricSerial,
  }
}

export function getPupilBirthdate(pupilBirthdate: string) {
  return {
    type: GET_PUPIL_BIRTHDATE,
    payload: pupilBirthdate,
  }
}
