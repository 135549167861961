export const FETCH_ORGANIZATION_BUILDING_TYPES_REQUEST =
  'FETCH_ORGANIZATION_BUILDING_TYPES_REQUEST'
export const FETCH_ORGANIZATION_BUILDING_TYPES_SUCCESS =
  'FETCH_ORGANIZATION_BUILDING_TYPES_SUCCESS'
export const FETCH_ORGANIZATION_BUILDING_TYPES_FAILURE =
  'FETCH_ORGANIZATION_BUILDING_TYPES_FAILURE'

export const FETCH_ORGANIZATION_BUILDING_TYPE_REQUEST =
  'FETCH_ORGANIZATION_BUILDING_TYPE_REQUEST'
export const FETCH_ORGANIZATION_BUILDING_TYPE_SUCCESS =
  'FETCH_ORGANIZATION_BUILDING_TYPE_SUCCESS'
export const FETCH_ORGANIZATION_BUILDING_TYPE_FAILURE =
  'FETCH_ORGANIZATION_BUILDING_TYPE_FAILURE'

export const FETCH_ORGANIZATION_BUILDING_TYPE_CLEANUP =
  'FETCH_ORGANIZATION_BUILDING_TYPE_CLEANUP'
export const CLEAR_ORGANIZATION_BUILDING_TYPES =
  'CLEAR_ORGANIZATION_BUILDING_TYPES'
export const CLEAR_ORGANIZATION_BUILDING_TYPE =
  'CLEAR_ORGANIZATION_BUILDING_TYPE'
export const REFRESH_ORGANIZATION_BUILDING_TYPE =
  'REFRESH_ORGANIZATION_BUILDING_TYPE'
