import moment from 'moment'

export function getPupilAge(d) {
  const now = new Date()
  const addOne =
    now.getMonth() - moment(d).month() >= 0 &&
    now.getDate() - moment(d).date() >= 0
  const diff = now.getFullYear() - moment(d).year()
  return diff - 1 + (addOne ? 1 : 0)
}
