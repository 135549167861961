import { Card } from 'antd'
import React from 'react'
import './statistics.less'

const Statistics: React.FC = () => {
  return (
    <Card>
      <div className="card">
        {/* <div className="card__wrapper"> */}
        <iframe src="https://data.bbit.uz/public/dashboard/6138713d-91a4-4bfe-b5d6-5e77d4619482" />
        {/* </div> */}
      </div>
    </Card>
  )
}

export default Statistics
