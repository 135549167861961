import React, { useState } from 'react'
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  createHoliday,
  updateHoliday,
} from '@app/store/actions/holiday-actions'
import HolidayRepository from '@app/api/repositories/holidays-repository'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { IHoliday } from '@app/interfaces/holidays'
import { IMonth } from '@app/pages/reference-book-page-details/holidays/holidays'

interface props {
  holiday?: IHoliday
  edit?: boolean
  months: IMonth[]
}

const HolidaysForm: React.FC<props> = ({ holiday, edit, months }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()

  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const newValues: IHoliday = {
      ...values,
      month: values.month ? Number(values.month) : undefined,
    }

    setConfirmLoading(true)
    const holidayApi = holiday
      ? HolidayRepository.update
      : HolidayRepository.create

    await holidayApi(newValues)
      .then((response: AxiosResponse<IHoliday>) => {
        if (holiday && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateHoliday(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!holiday && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createHoliday(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('holiday.edit') : t('holiday.add')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              ...holiday,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              label={t('holiday.month')}
              name={'month'}
            >
              <Select>
                {months.map((month) => (
                  <Select.Option key={month.id} value={month.id}>
                    {month.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
                {
                  type: 'number',
                  min: 1,
                  max: 31,
                  message: t('errors.minMaxMessage', { min: 1, max: 31 }),
                },
              ]}
              label={t('holiday.day')}
              name={'day'}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
                {
                  min: 0,
                  max: 255,
                  message: t('errors.lengthMessage', { min: 0, max: 255 }),
                },
              ]}
              label={t('holiday.note')}
              name={'note'}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default HolidaysForm
