import UsersRepository from '@api/repositories/users-repository'
import { IPathParams } from '@app/constants/path-params'
import { positionRoles, pseudoRoles, roles } from '@app/constants/roles'
import useLocalization from '@app/hooks/useLocalization'
import { IFile } from '@app/interfaces/files'
import { ILang } from '@app/interfaces/languages'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import { IRole, IUserGroupRoles } from '@app/interfaces/roles'
import { IUser, IUserState } from '@app/interfaces/users'
import GeneralItems from '@app/pages/users-page/components/user-form/components/general-items/general-items'
import GroupRolesItem from '@app/pages/users-page/components/user-form/components/group-roles-item/group-roles-item'
import RolesItem from '@app/pages/users-page/components/user-form/components/roles-item/roles-items'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { getGroupRoles, getRoles } from '@app/store/actions/roles-actions'
import { clearUser, getUser } from '@app/store/actions/users-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Form, Input, Spin, Switch } from 'antd'
import axios, { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './user-form.less'

interface props {
  _usersState: IUserState
  _districts: IDistrict[]
  _regions: IRegion[]
  _roles: IRole[]
  _groupRoles: IUserGroupRoles[]
  _languages: ILang[]
  _avatar: IFile
}

const UserForm: React.FC<props> = ({
  _usersState,
  _roles,
  _districts,
  _regions,
  _groupRoles,
  _languages,
  _avatar,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const { user } = _usersState
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { localize, localizeGroupRoles } = useLocalization()
  setDocumentTitle(params.edit ? t('users.editUser') : t('users.addUser'))
  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_groupRoles))
      dispatch(getGroupRoles({ source }, t('userRoles.error')))
    if (isEmpty(_roles)) dispatch(getRoles({ source }, t('userRoles.error')))
    if (params.userId)
      dispatch(
        getUser({ data: Number(params.userId), source }, t('users.error')),
      )
    return () => {
      dispatch(clearUser())
      source.cancel('Component got unmounted')
    }
  }, [])

  const mergeArray = (...arr): string[] => {
    const result: string[] = []
    arr.forEach((role) => {
      if (typeof role === 'undefined') return
      if (typeof role === 'string') {
        result.push(role)
        return
      }
      role.forEach((role) => typeof role === 'string' && result.push(role))
    })
    return result
  }

  const setInitialValuesByIndex = (index: number) =>
    params.userId
      ? roles[index].roles
          .filter((role) =>
            user?.roles.map((role) => role.name).includes(role.name),
          )
          .map((role) => role.name)
      : undefined

  useEffect(() => {
    if (!isEmpty(user)) {
      form.setFieldsValue({
        ...user,
        id: user?.id ? user?.id : null,
        manageReferences: setInitialValuesByIndex(0),
        manageOrganizations: setInitialValuesByIndex(1),
        manageEmployees: setInitialValuesByIndex(2),
        managePupils: setInitialValuesByIndex(3),
        manageGroups: setInitialValuesByIndex(4),
        manageReports: setInitialValuesByIndex(5),
        manageAttendance: setInitialValuesByIndex(6),
        manageReasons: setInitialValuesByIndex(7),
        manageMonitoring: setInitialValuesByIndex(8),
        manageMenu: setInitialValuesByIndex(9),
        manageSubsidies: setInitialValuesByIndex(10),
        manageGrowth: setInitialValuesByIndex(11),
        manageFurniture: setInitialValuesByIndex(12),
        managePassportization: setInitialValuesByIndex(13),
        manageContacts: setInitialValuesByIndex(14),
        manageStaff: setInitialValuesByIndex(15),
        manageAdmin: setInitialValuesByIndex(16),
        manageDashboard: setInitialValuesByIndex(17),
        groupRoles: user?.groupRoles?.length
          ? localizeGroupRoles(_groupRoles).find(
              (groupRole) => groupRole.id === user?.groupRoles[0].id,
            )?.data?.name
          : undefined,
        pseudoRoles: user?.roles?.length
          ? pseudoRoles[0].roles.find((pseudoRole) =>
              user?.roles.find((role) => role.name === pseudoRole.name),
            )?.name
          : undefined,
        superRoles: params.userId
          ? positionRoles[0].roles
              .filter((superRole) =>
                user?.roles.map((role) => role.name).includes(superRole.name),
              )
              .map((role) => role.name)
          : undefined,
      })
    } else form.setFieldsValue({})
  }, [user])

  const activeLanguages = _languages?.filter((language) => {
    if (language.status) {
      return language
    }
    return false
  })

  const onFinish = async (values: any) => {
    const superRoles = mergeArray(values.superRoles)
    const pseudoRoles = mergeArray(values.pseudoRoles)
    const manageRoles = mergeArray(
      values.manageReferences,
      values.manageOrganizations,
      values.manageEmployees,
      values.managePupils,
      values.manageGroups,
      values.manageReports,
      values.manageAttendance,
      values.manageReasons,
      values.manageMonitoring,
      values.manageMenu,
      values.manageSubsidies,
      values.manageGrowth,
      values.manageFurniture,
      values.managePassportization,
      values.manageContacts,
      values.manageStaff,
      values.manageAdmin,
      values.manageDashboard,
    )
    const all = mergeArray(superRoles, pseudoRoles, manageRoles)
    const roles = all.includes('ROLE_ADMIN') ? superRoles : all
    const district = localize(_districts).find(
      (district) => district.data.name === values.districtId,
    )?.id
    const region = localize(_regions).find(
      (region) => region.data.name === values.regionId,
    )?.id
    const groupRole = localizeGroupRoles(_groupRoles).find(
      (role) => role.data.name === values.groupRoles,
    )?.id
    const newValues = {
      ...values,
      fileId: _avatar.id || null,
      superRoles: undefined,
      pseudoRoles: undefined,
      districtId: Number(values.districtId) || district,
      regionId: Number(values.regionId) || region,
      roles: _roles.filter((role) => roles.includes(role.name)),
      groupRoles: values.groupRoles
        ? [
            {
              id: Number(values.groupRoles) || Number(groupRole),
            },
          ]
        : undefined,
    }

    setConfirmLoading(true)
    const userSubmitApi = params.userId
      ? UsersRepository.update
      : UsersRepository.create

    await userSubmitApi(newValues)
      .then((response: AxiosResponse<IUser>) => {
        if (params.edit && response.status === 200) {
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.updated'))
          navigate(`/manage-users/users/user/${response.data.id}`)
        }
        if (!params.edit && response.status === 201) {
          setConfirmLoading(false)
          openNotificationWithIcon('success', t('success.created'))
          navigate(`/manage-users/users/user/${response.data.id}`)
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('errors.error'))
        setConfirmLoading(false)
      })
    setConfirmLoading(false)
  }

  return (
    <React.Fragment>
      <Card
        extra={
          <>
            <Button
              disabled={confirmLoading}
              type={'primary'}
              onClick={form.submit}
            >
              {params.edit ? t('general.edit') : t('general.add')}
            </Button>
            <Link
              to={
                params.edit
                  ? `/manage-users/users/user/${params.userId}`
                  : `/manage-users/users`
              }
              className={'ant-btn ant-btn-default'}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Link>
          </>
        }
        title={params.edit ? t('general.edit') : t('general.add')}
        bordered
      >
        <Spin spinning={confirmLoading}>
          <Form
            encType={'multipart'}
            className={'user-form'}
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            style={{ padding: '24px' }}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <GeneralItems activeLanguages={activeLanguages} />
            <GroupRolesItem groupRoles={_groupRoles} />
            <Form.Item
              name={'accountNonExpired'}
              label={t('users.accountNonExpired')}
              valuePropName="checked"
            >
              <Switch defaultChecked={user?.accountNonExpired === true} />
            </Form.Item>
            <Form.Item
              name={'accountNonLocked'}
              label={t('users.accountNonLocked')}
              valuePropName="checked"
            >
              <Switch defaultChecked={user?.accountNonLocked === true} />
            </Form.Item>
            <Form.Item
              name={'enabled'}
              label={t('users.enabled')}
              valuePropName="checked"
            >
              <Switch defaultChecked={user?.enabled === true} />
            </Form.Item>
            <RolesItem roles={user?.roles} />
            <Form.Item style={{ marginTop: '10px' }}>
              <Button
                disabled={confirmLoading}
                type={'primary'}
                htmlType={'submit'}
              >
                {params.edit ? t('general.edit') : t('general.add')}
              </Button>
              <Link
                to={
                  params.edit
                    ? `/manage-users/users/user/${params.userId}`
                    : `/manage-users/users`
                }
                className={'ant-btn ant-btn-default'}
                style={{ marginLeft: '10px' }}
              >
                {t('general.cancel')}
              </Link>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    _districts: state.districts.districts,
    _regions: state.regions.regions,
    _usersState: state.users,
    _roles: state.roles.roles,
    _groupRoles: state.roles.rolesGroups,
    _languages: state.langs.langs,
    _avatar: state.files.photo,
  }
}

export default connect(mapStateToProps)(UserForm)
