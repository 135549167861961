import bbitApi from '@api/make-request'
import { IUserInfo } from '@app/interfaces/user-info'
import { AxiosResponse, CancelTokenSource } from 'axios'

class UserInfoRepository {
  getOne(source?: CancelTokenSource): Promise<AxiosResponse<IUserInfo>> {
    return bbitApi.get('/api/v1/account/info', { cancelToken: source?.token })
  }
}

export default new UserInfoRepository()
