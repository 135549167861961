export const FETCH_EDUCATION_GROUP_ROOMS_REQUEST =
  'FETCH_EDUCATION_GROUP_ROOMS_REQUEST'
export const FETCH_EDUCATION_GROUP_ROOMS_SUCCESS =
  'FETCH_EDUCATION_GROUP_ROOMS_SUCCESS'
export const FETCH_EDUCATION_GROUP_ROOMS_FAILURE =
  'FETCH_EDUCATION_GROUP_ROOMS_FAILURE'

export const FETCH_EDUCATION_GROUP_ROOM_REQUEST =
  'FETCH_EDUCATION_GROUP_ROOM_REQUEST'
export const FETCH_EDUCATION_GROUP_ROOM_SUCCESS =
  'FETCH_EDUCATION_GROUP_ROOM_SUCCESS'
export const FETCH_EDUCATION_GROUP_ROOM_FAILURE =
  'FETCH_EDUCATION_GROUP_ROOM_FAILURE'

export const FETCH_EDUCATION_GROUP_ROOM_CLEANUP =
  'FETCH_EDUCATION_GROUP_ROOM_CLEANUP'
export const CLEAR_EDUCATION_GROUP_ROOMS = 'CLEAR_EDUCATION_GROUP_ROOMS'
export const CLEAR_EDUCATION_GROUP_ROOM = 'CLEAR_EDUCATION_GROUP_ROOM'
export const REFRESH_EDUCATION_GROUP_ROOMS = 'REFRESH_EDUCATION_GROUP_ROOMS'
