import {
  CREATE_HOLIDAY,
  DELETE_HOLIDAY,
  GET_HOLIDAY,
  GET_HOLIDAYS,
  UPDATE_HOLIDAY,
} from '@app/store/types/holiday'
import { IHolidayActions } from '@app/interfaces/holidays'

export function getHoliday(holiday): IHolidayActions {
  return {
    type: GET_HOLIDAY,
    payload: holiday,
  }
}

export function getHolidays(holidays): IHolidayActions {
  return {
    type: GET_HOLIDAYS,
    payload: holidays,
  }
}

export function createHoliday(holiday): IHolidayActions {
  return {
    type: CREATE_HOLIDAY,
    payload: holiday,
  }
}

export function updateHoliday(holiday): IHolidayActions {
  return {
    type: UPDATE_HOLIDAY,
    payload: holiday,
  }
}

export function deleteHoliday(holiday): IHolidayActions {
  return {
    type: DELETE_HOLIDAY,
    payload: holiday,
  }
}
