import bbitApi from '@api/make-request'
import {
  IOrganizationPassportRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import {
  IOrganizationCreateWithOrganization,
  IOrganizationPassport,
} from '@app/interfaces/organization-passport/organization-passport'
import { AxiosResponse } from 'axios'

export interface InstitutionPassportPartial {
  id: number | undefined
  organization:
    | {
        id: number | undefined
      }
    | undefined
  queuePercentage: number | undefined
  plannedCapacity: number | undefined
  institutionType: { id: number | undefined | null }
}
class InstitutionPassportRepository
  implements IOrganizationPassportRepository<IOrganizationPassport>
{
  create(
    data: IOrganizationPassport | IOrganizationCreateWithOrganization,
  ): Promise<AxiosResponse<IOrganizationPassport>> {
    return bbitApi.post('/dictionary/api/v1/institution-passport  ', data)
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IOrganizationPassport>> {
    return bbitApi.get(`/dictionary/api/v1/institution-passport/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(
    data: IOrganizationPassport,
  ): Promise<AxiosResponse<IOrganizationPassport>> {
    return bbitApi.put(
      `/dictionary/api/v1/institution-passport/${data.id}`,
      data,
    )
  }

  partialUpdate(
    data: InstitutionPassportPartial,
  ): Promise<AxiosResponse<InstitutionPassportPartial>> {
    return bbitApi.put(
      `/dictionary/api/v1/institution-passport/${data.id}`,
      data,
    )
  }

  getOneByOrganizationId(
    args: numberArgs,
  ): Promise<AxiosResponse<IOrganizationPassport>> {
    return bbitApi.get(
      `/dictionary/api/v1/institution-passport/by-organization/${args.data}`,
      { cancelToken: args.source?.token },
    )
  }
}

export default new InstitutionPassportRepository()
