import { ApiKeys } from '@app/constants/api-keys'
import { IOrganizationPosition } from '@app/interfaces/employee/organization-position'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import localforage from 'localforage'
import { emptyOrganizationApi } from '.'
import { IRtkResponse } from '../../types'

export const organizationPositionApi = emptyOrganizationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOrganizationPositions: build.query<
      IRtkResponse<IOrganizationPosition[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'employee/api/v1/organization-position',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationPosition[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.organizationPosition,
                id,
              })),
              { type: ApiKeys.organizationPosition, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organizationPosition, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.organizationPosition, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getOrganizationPosition: build.query<IOrganizationPosition, number>({
      query: (id) => ({
        url: `employee/api/v1/organization-position/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.organizationPosition, id },
      ],
    }),
    createOrganizationPosition: build.mutation<
      IRtkResponse<IOrganizationPosition>,
      Required<IOrganizationPosition>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'employee/api/v1/organization-position',
          data,
        }
      },
      transformResponse: (response: IOrganizationPosition, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.organizationPosition, id: 'LIST' }],
    }),
    updateOrganizationPosition: build.mutation<
      IRtkResponse<IOrganizationPosition>,
      Required<IOrganizationPosition>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/organization-position/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganizationPosition, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationPosition, id: arg.id },
      ],
    }),
    partialUpdateOrganizationPosition: build.mutation<
      IRtkResponse<IOrganizationPosition>,
      Subset<IOrganizationPosition>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/organization-position/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganizationPosition, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationPosition, id: arg.id },
      ],
    }),
  }),
})

const useStateGetOrganizationPositionQuery =
  organizationPositionApi.endpoints.getOrganizationPosition.useQueryState
const useStateGetOrganizationPositionsQuery =
  organizationPositionApi.endpoints.getOrganizationPositions.useQueryState

export const {
  useGetOrganizationPositionsQuery,
  useGetOrganizationPositionQuery,
  useCreateOrganizationPositionMutation,
  useUpdateOrganizationPositionMutation,
  usePartialUpdateOrganizationPositionMutation,
} = organizationPositionApi

export {
  useStateGetOrganizationPositionQuery,
  useStateGetOrganizationPositionsQuery,
}
