import { SliceKeys } from '@app/constants/slice-keys'
import { IOrganizationGrowthState } from '@app/interfaces/organization-growth'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IOrganizationGrowthState = {
  editModal: false,
}

export const OrganizationGrowthSlice = createSlice({
  name: SliceKeys.organizationGrowthSlice,
  initialState,
  reducers: {
    toggleEditModal(state, action) {
      state.editModal = action.payload
    },
  },
})

export const { toggleEditModal } = OrganizationGrowthSlice.actions
export default OrganizationGrowthSlice.reducer
