import { axiosBaseQuery } from '@app/api/make-request'
import {
  IGroupChangeRequest,
  IGroupChangeResponse,
} from '@app/api/repositories/organization-pupil-repository'
import { ApiKeys } from '@app/constants/api-keys'
import {
  IOrganizationPupils,
  IOrganizationPupilsDismiss,
  IOrganizationPupilsFull,
} from '@app/interfaces/pupil/organization-pupils'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IRtkResponse } from '../types'

export const organizationPupilApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'organizationPupilApi',
  tagTypes: [ApiKeys.organizationPupil],
  endpoints: (build) => ({
    getOrganizationPupils: build.query<
      IRtkResponse<IOrganizationPupils[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'education/api/v1/pupil-education',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationPupils[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.organizationPupil,
                id,
              })),
              { type: ApiKeys.organizationPupil, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organizationPupil, id: 'LIST' }],
    }),
    getOrganizationPupilsFull: build.query<
      IRtkResponse<IOrganizationPupilsFull[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'education/api/v1/pupil-education/with-organization',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationPupilsFull[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.organizationPupil,
                id: `${id}-full`,
              })),
              { type: ApiKeys.organizationPupil, id: 'LIST-FULL' },
            ]
          : [{ type: ApiKeys.organizationPupil, id: 'LIST-FULL' }],
    }),
    getOrganizationPupil: build.query<IOrganizationPupils, number>({
      query: (id) => ({
        url: `education/api/v1/pupil-education/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.organizationPupil, id },
      ],
    }),
    updateOrganizationPupil: build.mutation<
      IRtkResponse<IOrganizationPupils>,
      Required<IOrganizationPupils>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `education/api/v1/pupil-education/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganizationPupils, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationPupil, id: arg.id },
        { type: ApiKeys.organizationPupil, id: `${arg.id}-full` },
      ],
    }),
    approveOrganizationPupil: build.mutation<
      IRtkResponse<IOrganizationPupils>,
      number
    >({
      query: (id) => ({
        method: 'PUT',
        url: `education/api/v1/pupil-education/pupil-approved/${id}`,
      }),
      transformResponse: (response: IOrganizationPupils, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, id) => [
        { type: ApiKeys.organizationPupil, id },
        { type: ApiKeys.organizationPupil, id: `${id}-full` },
      ],
    }),
    quashOrganizationPupil: build.mutation<
      IRtkResponse<IOrganizationPupils>,
      number
    >({
      query: (id) => ({
        method: 'PUT',
        url: `education/api/v1/pupil-education/pupil-quashed/${id}`,
      }),
      transformResponse: (response: IOrganizationPupils, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, id) => [
        { type: ApiKeys.organizationPupil, id },
        { type: ApiKeys.organizationPupil, id: `${id}-full` },
      ],
    }),
    dismissOrganizationPupil: build.mutation<
      IRtkResponse<IOrganizationPupils>,
      IOrganizationPupilsDismiss
    >({
      query: (data) => ({
        method: 'PUT',
        url: `education/api/v1/pupil-education/pupil-dismiss/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganizationPupils, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: ApiKeys.organizationPupil, id },
        { type: ApiKeys.organizationPupil, id: `${id}-full` },
      ],
    }),
    changeGroup: build.mutation<
      IRtkResponse<IGroupChangeResponse>,
      IGroupChangeRequest
    >({
      query: (data) => ({
        method: 'PUT',
        url: `education/api/v1/pupil-education/change-group`,
        data,
      }),
      transformResponse: (response: IGroupChangeResponse, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationPupil, id: arg.pupilId },
        { type: ApiKeys.organizationPupil, id: `${arg.pupilId}-full` },
      ],
    }),
  }),
})

const useStateGetOrganizationPupilQuery =
  organizationPupilApi.endpoints.getOrganizationPupil.useQueryState
const useStateGetOrganizationPupilsQuery =
  organizationPupilApi.endpoints.getOrganizationPupils.useQueryState
const useStateGetOrganizationPupilsFullQuery =
  organizationPupilApi.endpoints.getOrganizationPupilsFull.useQueryState

export const {
  useGetOrganizationPupilQuery,
  useGetOrganizationPupilsQuery,
  useGetOrganizationPupilsFullQuery,
  useUpdateOrganizationPupilMutation,
  useChangeGroupMutation,
  useApproveOrganizationPupilMutation,
  useDismissOrganizationPupilMutation,
  useQuashOrganizationPupilMutation,
} = organizationPupilApi

export {
  useStateGetOrganizationPupilQuery,
  useStateGetOrganizationPupilsQuery,
  useStateGetOrganizationPupilsFullQuery,
}
