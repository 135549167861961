import educationLanguageRepository from '@app/api/repositories/education-language-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import {
  CLEAR_EDUCATION_LANGUAGE,
  CREATE_EDUCATION_LANGUAGE,
  DELETE_EDUCATION_LANGUAGE,
  FETCH_EDUCATION_LANGUAGES_FAILURE,
  FETCH_EDUCATION_LANGUAGES_REQUEST,
  FETCH_EDUCATION_LANGUAGES_SUCCESS,
  FETCH_EDUCATION_LANGUAGE_FAILURE,
  FETCH_EDUCATION_LANGUAGE_REQUEST,
  FETCH_EDUCATION_LANGUAGE_SUCCESS,
  UPDATE_EDUCATION_LANGUAGE,
} from '@app/store/types/education-language'
import axios from 'axios'
import { IEducationLanguageActions } from './../../interfaces/education/education-language'

export const getEducationLanguage =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchEducationLanguageRequest())
    await educationLanguageRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchEducationLanguageSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEducationLanguageFailure(error?.message, error?.code))
        }
      })
  }

export const getEducationLanguages =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchEducationLanguagesRequest())
    await educationLanguageRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchEducationLanguagesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEducationLanguagesFailure(error?.message, error?.code))
        }
      })
  }

const fetchEducationLanguagesRequest = (): IEducationLanguageActions => {
  return {
    type: FETCH_EDUCATION_LANGUAGES_REQUEST,
  }
}

const fetchEducationLanguagesSuccess = (
  educationLanguages,
  count,
): IEducationLanguageActions => {
  return {
    type: FETCH_EDUCATION_LANGUAGES_SUCCESS,
    payload: { educationLanguages, count },
  }
}

const fetchEducationLanguagesFailure = (
  message,
  code,
): IEducationLanguageActions => {
  return {
    type: FETCH_EDUCATION_LANGUAGES_FAILURE,
    payload: { message, code },
  }
}

const fetchEducationLanguageRequest = (): IEducationLanguageActions => {
  return {
    type: FETCH_EDUCATION_LANGUAGE_REQUEST,
  }
}

const fetchEducationLanguageSuccess = (
  educationLanguage,
): IEducationLanguageActions => {
  return {
    type: FETCH_EDUCATION_LANGUAGE_SUCCESS,
    payload: educationLanguage,
  }
}

const fetchEducationLanguageFailure = (
  message,
  code,
): IEducationLanguageActions => {
  return {
    type: FETCH_EDUCATION_LANGUAGE_FAILURE,
    payload: { message, code },
  }
}

export function clearEducationLanguages() {
  return {
    type: CLEAR_EDUCATION_LANGUAGE,
    payload: null,
  }
}

export function createEducationLanguages(
  educationLanguage,
): IEducationLanguageActions {
  return {
    type: CREATE_EDUCATION_LANGUAGE,
    payload: educationLanguage,
  }
}

export function deleteEducationLanguages(
  educationLanguage,
): IEducationLanguageActions {
  return {
    type: DELETE_EDUCATION_LANGUAGE,
    payload: educationLanguage,
  }
}

export function updateEducationLanguages(
  educationLanguage,
): IEducationLanguageActions {
  return {
    type: UPDATE_EDUCATION_LANGUAGE,
    payload: educationLanguage,
  }
}
