import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import { IInstitutionType } from '@app/interfaces/organization-passport/institution-type'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import { IOrganizationPupilsState } from '@app/interfaces/pupil/organization-pupils'
import Pagination from '@app/shared/pagination/pagination'
import { getDistricts } from '@app/store/actions/districts-actions'
import { getInstitutionTypes } from '@app/store/actions/institutions-types-actions'
import { getOrganizationPupilsFull } from '@app/store/actions/organization-pupils-actions'
import { getRegions } from '@app/store/actions/regions-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import PupilsSearchFilterForm from './components/pupils-search-filter-form'

interface props {
  _pupilsState: IOrganizationPupilsState
  _institutionTypes: IInstitutionType[]
  _regions: IRegion[]
  _districts: IDistrict[]
}

const PupilsSearch: React.FC<props> = ({
  _pupilsState,
  _institutionTypes,
  _regions,
  _districts,
}) => {
  const { state, count, pupilsFull } = _pupilsState
  const [showTable, setShowTable] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation()
  const { localize } = useLocalization()
  const { searchParams, requestFunction, handleTableChange } = usePagination(
    `page=0&size=10&status.in=APPROVED`,
    {
      page: 1,
      size: 10,
    },
  )
  setDocumentTitle(t('routes.pupilSearch'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_institutionTypes))
      dispatch(getInstitutionTypes({}, t('institutionType.error')))
    if (isEmpty(_districts)) dispatch(getDistricts({}, t('district.error')))
    if (isEmpty(_regions)) dispatch(getRegions({}, t('region.error')))
    if (showTable)
      dispatch(
        getOrganizationPupilsFull(
          { params: searchParams, source },
          t('pupils.error'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const data = !isEmpty(pupilsFull)
    ? pupilsFull.map((pupil) => {
        return {
          key: pupil.id,
          id: pupil.id,
          pupilId: pupil.pupil?.id,
          organizationId: pupil.organization?.id,
          organizationName: pupil.organization?.name,
          institutionType: localize(_institutionTypes).find(
            (type) =>
              type.id === pupil.organization?.passport?.institutionType?.id,
          )?.data.name,
          groupName: pupil.educationGroup?.name,
          region: localize(_regions).find(
            (region) => region.id === pupil.organization?.region.id,
          )?.data.name,
          district: localize(_districts).find(
            (district) => district.id === pupil.organization?.district.id,
          )?.data.name,
          groupId: pupil.educationGroup?.id,
          metric: pupil.pupil?.metric,
          fullName: pupil.pupil?.fullName,
          gender:
            pupil.pupil?.gender === 'MALE'
              ? t('pupils.male')
              : t('pupils.female'),
        }
      })
    : []

  const columns = [
    {
      title: t('pupils.region'),
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: t('pupils.district'),
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: t('pupils.doo'),
      dataIndex: 'organizationName',
      key: 'organizationName',
      render: (text, record) => (
        <Link
          key={record.organizationId}
          to={`/organization/${record.organizationId}/page`}
        >
          {record.organizationName}
        </Link>
      ),
    },
    {
      title: t('institutionType.institutionType'),
      dataIndex: 'institutionType',
      key: 'institutionType',
    },
    {
      title: t('pupils.pupilSearch.group'),
      dataIndex: 'groupName',
      key: 'groupName',
      render: (text, record) => (
        <Link
          key={record.groupId}
          to={`/organization/${record.organizationId}/groups/${record.groupId}`}
        >
          {record.groupName}
        </Link>
      ),
    },
    {
      title: t('pupils.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <Link
          className={'ant-btn, ant-btn-link'}
          to={`/pupils/${record.pupilId}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: t('pupils.metric'),
      dataIndex: 'metric',
      key: 'metric',
    },
  ]
  return (
    <>
      <div className="header-form">
        <PupilsSearchFilterForm
          request={requestFunction}
          setShowTable={setShowTable}
          showTable={showTable}
        />
      </div>
      {showTable && (
        <Card title={t('pupils.title')}>
          <Table
            columns={columns}
            dataSource={data}
            loading={state === LOADING}
            pagination={false}
            rowClassName="table-row"
            onChange={handleTableChange}
            footer={() => (
              <Pagination
                request={requestFunction}
                totalCount={count}
                searchParams={searchParams}
              />
            )}
          />
        </Card>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _pupilsState: state.organizationPupils,
    _institutionTypes: state.institutionTypes.institutionsTypes,
    _regions: state.regions.regions,
    _districts: state.districts.districts,
  }
}

export default connect(mapStateToProps)(PupilsSearch)
