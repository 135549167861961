import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IInstitutionType } from '@app/interfaces/organization-passport/institution-type'
import InstitutionTypeForm from '@app/pages/reference-book-page-details/institution-type/components/institution-type-form/institution-type-form'
import { getInstitutionTypes } from '@app/store/actions/institutions-types-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _state: string
  _institutionTypes: IInstitutionType[]
}

const ReferenceBookInstitutionsTypes: React.FC<props> = ({
  _institutionTypes,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('institutionType.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_institutionTypes))
      dispatch(getInstitutionTypes({ source }, t('institutionType.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('institutionType.institutionType'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <InstitutionTypeForm
            edit={true}
            institutionType={record.institutionType}
            institutionTypes={_institutionTypes}
          />
        ) : null,
    },
  ]

  const data = !isEmpty(_institutionTypes)
    ? _institutionTypes.map((institutionType) => {
        return {
          key: institutionType.id,
          id: institutionType.id,
          name: localizeData(institutionType?.data)?.name,
          status: [institutionType.status],
          institutionType: institutionType,
        }
      })
    : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <InstitutionTypeForm institutionTypes={_institutionTypes} />
          ) : null
        }
        title={t('institutionType.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _state: state.institutionTypes.state,
    _institutionTypes: state.institutionTypes.institutionsTypes,
  }
}

export default connect(mapStateToProps)(ReferenceBookInstitutionsTypes)
