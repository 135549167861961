import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { IMenuSeasonMenu } from '@app/interfaces/organization-menu'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Form, Select } from 'antd'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

interface props {
  seasonMenus: IMenuSeasonMenu[]
  setSeasonMenus: Dispatch<SetStateAction<IMenuSeasonMenu[]>>
  setChildForm: any
}

const MenuSeasonMenus: React.FC<props> = ({
  seasonMenus,
  setSeasonMenus,
  setChildForm,
}) => {
  const { selectors } = useCreateSelector()
  const menus = useSelector(selectors.menus.menus)
  const [t] = useTranslation()
  const { localize, localizeData } = useLocalization()
  const [form] = Form.useForm()

  const onAddMenuHandler = (values: any) => {
    const addMenu = menus.find((menu) => menu.id === values.id)
    if (seasonMenus.find((menu) => menu.menu.id === addMenu?.id)) return
    if (addMenu) {
      setSeasonMenus((prev) => [...prev, { menu: addMenu, queue: 0 }])
    }
  }

  const onRemoveMenuHandler = (menu: IMenuSeasonMenu) => {
    const newMenus = seasonMenus.filter((item) => item.menu.id !== menu.menu.id)
    setSeasonMenus((prev) => [...newMenus])
  }
  useEffect(() => {
    setChildForm(form)
  }, [])

  return (
    <div>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onAddMenuHandler}
        className="menu-seasons__add-menu"
      >
        <p className="title">{t('organizationMenu.menus.add')}</p>
        <Form.Item name="id">
          <Select
            style={{ width: '100%' }}
            showSearch
            placeholder={t('organizationMenu.menus.name')}
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option?.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {sortByName(localize(menus)).map((menu) => {
              return (
                <Select.Option key={menu.id} value={menu.id}>
                  {menu.data?.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" className="add-btn">
          {t('general.add')}
        </Button>
      </Form>
      <div className="menu-seasons__current">
        <p className="title">{t('organizationMenu.menuSeasons.current')}</p>
        {seasonMenus?.map((menu) => {
          return (
            <div className="item" key={menu.menu.id}>
              <p className="item__title">{`${
                localizeData(menu.menu.data)?.name
              }`}</p>
              <p
                className="item__minus"
                onClick={() => onRemoveMenuHandler(menu)}
              >
                -
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MenuSeasonMenus
