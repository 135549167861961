import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationSector,
  IOrganizationSectorState,
} from '@app/interfaces/organization/organization-sector'
import {
  CLEAR_CURRENT_ORGANIZATION_SECTOR_ID,
  CLEAR_ORGANIZATION_SECTOR,
  CLEAR_ORGANIZATION_SECTORS,
  FETCH_ORGANIZATION_SECTORS_FAILURE,
  FETCH_ORGANIZATION_SECTORS_REQUEST,
  FETCH_ORGANIZATION_SECTORS_SUCCESS,
  FETCH_ORGANIZATION_SECTOR_CLEANUP,
  FETCH_ORGANIZATION_SECTOR_FAILURE,
  FETCH_ORGANIZATION_SECTOR_REQUEST,
  FETCH_ORGANIZATION_SECTOR_SUCCESS,
  REFRESH_ORGANIZATION_SECTOR,
  SET_CURRENT_ORGANIZATION_SECTOR_ID,
} from '@app/store/types/organization-sector'

const initialState: IOrganizationSectorState = {
  organizationSectors: [],
  organizationSector: {} as IOrganizationSector,
  currentOrganizationSectorID: 0,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationSectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORGANIZATION_SECTOR:
      return { ...state, organizationSector: {}, state: IDLE }
    case CLEAR_ORGANIZATION_SECTORS:
      return { ...state, organizationSectors: [], state: IDLE }
    case REFRESH_ORGANIZATION_SECTOR:
      return { ...state, refresh: !state.refresh }
    case SET_CURRENT_ORGANIZATION_SECTOR_ID:
      return { ...state, currentOrganizationSectorID: action.payload }
    case CLEAR_CURRENT_ORGANIZATION_SECTOR_ID:
      return { ...state, currentOrganizationSectorID: 0 }
    case FETCH_ORGANIZATION_SECTOR_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_ORGANIZATION_SECTORS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_SECTORS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationSectors: action.payload.organizationSectors,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_SECTORS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationSectors: [],
      }
    case FETCH_ORGANIZATION_SECTOR_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_SECTOR_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationSector: action.payload,
      }
    case FETCH_ORGANIZATION_SECTOR_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationSector: {},
      }
    default:
      return state
  }
}
