import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEducationGroup,
  IEducationGroupState,
} from '@app/interfaces/education/education-groups'
import {
  CLEAR_EDUCATION_GROUPS,
  CREATE_EDUCATION_GROUP,
  DELETE_EDUCATION_GROUP,
  FETCH_EDUCATION_GROUPS_FAILURE,
  FETCH_EDUCATION_GROUPS_REQUEST,
  FETCH_EDUCATION_GROUPS_SUCCESS,
  FETCH_EDUCATION_GROUP_CLEANUP,
  FETCH_EDUCATION_GROUP_FAILURE,
  FETCH_EDUCATION_GROUP_REQUEST,
  FETCH_EDUCATION_GROUP_SUCCESS,
  REFRESH_EDUCATION_GROUPS,
  UPDATE_EDUCATION_GROUP,
} from '@app/store/types/education-group'

const initialState: IEducationGroupState = {
  educationGroups: [],
  educationGroup: {} as IEducationGroup,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const educationGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_EDUCATION_GROUPS:
      return { ...state, educationGroups: [], state: IDLE }
    case REFRESH_EDUCATION_GROUPS:
      return { ...state, refresh: !state.refresh }
    case CREATE_EDUCATION_GROUP:
      return {
        ...state,
        educationGroups: [...state.educationGroups, action.payload],
      }
    case UPDATE_EDUCATION_GROUP:
      const updatedEducationGroups = state.educationGroups.map(function (
        educationGroup,
      ) {
        if (action.payload.id === educationGroup.id) {
          return action.payload
        }
        return educationGroup
      })
      return { ...state, educationGroups: updatedEducationGroups }
    case DELETE_EDUCATION_GROUP:
      const editedEducationGroups = state.educationGroups.filter(function (
        educationGroup,
      ) {
        if (action.payload.id === educationGroup.id) {
          return false
        }
        return educationGroup
      })
      return { ...state, educationGroups: editedEducationGroups }
    case FETCH_EDUCATION_GROUPS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_GROUPS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationGroups: action.payload.educationGroups,
        count: action.payload.count,
      }
    case FETCH_EDUCATION_GROUPS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationGroups: [],
      }
    case FETCH_EDUCATION_GROUP_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_EDUCATION_GROUP_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EDUCATION_GROUP_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        educationGroup: action.payload,
      }
    case FETCH_EDUCATION_GROUP_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        educationGroup: {},
      }
    default:
      return state
  }
}
