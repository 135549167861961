import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IRegion } from '@app/interfaces/organization/region'
import { AxiosResponse } from 'axios'

class RegionRepository implements IRepository<IRegion> {
  create(data: IRegion): Promise<AxiosResponse<IRegion>> {
    return bbitApi.post('/dictionary/api/v1/regions', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IRegion[]>> {
    return bbitApi.get('/dictionary/api/v1/regions', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IRegion>> {
    return bbitApi.get(`/dictionary/api/v1/regions/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IRegion): Promise<AxiosResponse<IRegion>> {
    return bbitApi({
      method: 'PUT',
      url: `/dictionary/api/v1/regions/${data.id}`,
      data: data,
    })
  }
}

export default new RegionRepository()
