import { DownOutlined } from '@ant-design/icons'
import useLocalization from '@app/hooks/useLocalization'
import {
  IDailyMenu,
  IMenuByPupil,
  IProducts,
} from '@app/interfaces/organization-menu'
import { isEmpty, round } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  menuByPupil: IMenuByPupil
}

interface ITotalResult {
  product: IProducts
  total: number
}

const MenuBodyTotal: React.FC<props> = ({ menuByPupil }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [t] = useTranslation()
  const { localizeData } = useLocalization()

  const toggleExpanded = () => {
    setExpanded((prev) => !prev)
  }

  const calculateTotalProducts = (dailyMenu: IDailyMenu): ITotalResult[] => {
    const result = {}
    dailyMenu.content.forEach((content) =>
      content.meals.forEach((meal) =>
        meal.products.forEach((product) => {
          isEmpty(result[product.productId])
            ? (result[product.productId] = {
                product,
                total: product.amount,
                measurement: product.measurement,
              })
            : (result[product.productId].total += product.amount)
        }),
      ),
    )
    return Object.values(result)
  }

  return (
    <div className="total">
      <div className="total__label" onClick={toggleExpanded}>
        <p>{t('general.total')}</p>
        <div
          className={`arrow ${expanded ? 'arrow-expanded' : 'arrow-collapsed'}`}
        >
          <DownOutlined />
        </div>
      </div>
      <div
        className={`total__body ${
          expanded ? 'total-expanded' : 'total-collapsed'
        }`}
      >
        {calculateTotalProducts(menuByPupil.dailyMenu).map(
          ({ product, total }) => (
            <div className="total__product" key={product.id}>
              <p className="total__product--title">
                {localizeData(product.data)?.name}
              </p>
              <p className="total__product--amount">
                {round(total, 2)} {localizeData(product.measurement.data)?.name}
              </p>
            </div>
          ),
        )}
      </div>
    </div>
  )
}

export default MenuBodyTotal
