import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { MinusCircleOutlined } from '@ant-design/icons'
import Title from 'antd/es/typography/Title'

interface props {
  remove: (data: number) => void
  name: number
}

const RemoveParentModal: React.FC<props> = ({ remove, name }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [t, i18n] = useTranslation()
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  return (
    <>
      <React.Fragment>
        <MinusCircleOutlined onClick={showModal} />
        <Modal
          open={visible}
          title={t('employeePosition.closePosition')}
          onCancel={hideModal}
          footer={[
            <React.Fragment key={1}>
              <Button type={'primary'} onClick={() => remove(name)}>
                {t('employeePosition.acceptBtn')}
              </Button>
              <Button
                type={'default'}
                onClick={hideModal}
                style={{ marginLeft: '10px' }}
              >
                {t('general.cancel')}
              </Button>
            </React.Fragment>,
          ]}
        >
          <Title level={4}>{t('pupils.removeParentAccepting')}?</Title>
        </Modal>
      </React.Fragment>
    </>
  )
}

export default RemoveParentModal
