export const GET_ORGANIZATION_LOCALITY = 'GET_ORGANIZATION_LOCALITY'
export const GET_ORGANIZATION_LOCALITIES = 'GET_ORGANIZATION_LOCALITIES'
export const CREATE_ORGANIZATION_LOCALITY = 'CREATE_ORGANIZATION_LOCALITY'
export const UPDATE_ORGANIZATION_LOCALITY = 'UPDATE_ORGANIZATION_LOCALITY'
export const DELETE_ORGANIZATION_LOCALITY = 'DELETE_ORGANIZATION_LOCALITY'
export const CLEAR_ORGANIZATION_LOCALITY = 'CLEAR_ORGANIZATION_LOCALITY'

export const FETCH_ORGANIZATION_LOCALITIES_REQUEST =
  'FETCH_ORGANIZATION_LOCALITIES_REQUEST'
export const FETCH_ORGANIZATION_LOCALITIES_SUCCESS =
  'FETCH_ORGANIZATION_LOCALITIES_SUCCESS'
export const FETCH_ORGANIZATION_LOCALITIES_FAILURE =
  'FETCH_ORGANIZATION_LOCALITIES_FAILURE'

export const FETCH_ORGANIZATION_LOCALITY_REQUEST =
  'FETCH_ORGANIZATION_LOCALITY_REQUEST'
export const FETCH_ORGANIZATION_LOCALITY_SUCCESS =
  'FETCH_ORGANIZATION_LOCALITY_SUCCESS'
export const FETCH_ORGANIZATION_LOCALITY_FAILURE =
  'FETCH_ORGANIZATION_LOCALITY_FAILURE'
