import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IEmployeeStatus } from '@app/interfaces/employee/employee-status'
import EmployeeStatusForm from '@app/pages/reference-book-page-details/employee-status/components/employee-status-form/employee-status-form'
import { getEmployeeStatuses } from '@app/store/actions/employee-status-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _employeeStatuses: IEmployeeStatus[]
  _state: string
}

const ReferenceBookEmployeeStatus: React.FC<props> = ({
  _employeeStatuses,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('employeeStatus.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_employeeStatuses))
      dispatch(getEmployeeStatuses({ source }, t('employeeStatus.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('employeeStatus.employeeStatus'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <EmployeeStatusForm
            edit={true}
            employeeStatus={record.employeeStatus}
          />
        ) : null,
    },
  ]

  const data =
    _employeeStatuses && _employeeStatuses.length
      ? _employeeStatuses.map((employeeStatus) => {
          return {
            key: employeeStatus.id,
            id: employeeStatus.id,
            name: localizeData(employeeStatus?.data)?.name,
            status: [employeeStatus.status],
            employeeStatus: employeeStatus,
          }
        })
      : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <EmployeeStatusForm />
          ) : null
        }
        title={t('employeeStatus.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _employeeStatuses: state.employeeStatus.employeeStatuses,
    _state: state.employeeStatus.state,
  }
}

export default connect(mapStateToProps)(ReferenceBookEmployeeStatus)
