import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { IReason } from '@app/interfaces/reasons'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { createApi } from '@reduxjs/toolkit/query/react'
import localforage from 'localforage'
import { IRtkResponse } from '../types'

export const reasonsApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'reasonApi',
  tagTypes: [ApiKeys.reasons],
  endpoints: (build) => ({
    getReasons: build.query<IRtkResponse<IReason[]>, URLSearchParams | string>({
      query: (args = '') => ({
        url: 'attendance/api/v1/attendance/reasons',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IReason[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.reasons,
                id,
              })),
              { type: ApiKeys.reasons, id: 'LIST' },
            ]
          : [{ type: ApiKeys.reasons, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.reasons, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getReason: build.query<IReason, number>({
      query: (id) => ({
        url: `attendance/api/v1/attendance/reasons/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.reasons, id }],
    }),
    createReason: build.mutation<IRtkResponse<IReason>, IReason>({
      query: (data) => {
        return {
          method: 'POST',
          url: 'attendance/api/v1/attendance/reasons',
          data,
        }
      },
      transformResponse: (response: IReason, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        {
          type: ApiKeys.reasons,
          id: args.id,
        },
        { type: ApiKeys.reasons, id: 'LIST' },
      ],
    }),
    updateReason: build.mutation<IRtkResponse<IReason>, IReason>({
      query: (data) => ({
        method: 'PUT',
        url: `attendance/api/v1/attendance/reasons/${data.id}`,
        data,
      }),
      transformResponse: (response: IReason, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, args) => [
        { type: ApiKeys.reasons, id: args.id },
        { type: ApiKeys.reasons, id: 'LIST' },
      ],
    }),
  }),
})

const useStateGetReasons = reasonsApi.endpoints.getReasons.useQueryState
const useStateGetReason = reasonsApi.endpoints.getReason.useQueryState

export const {
  useGetReasonsQuery,
  useLazyGetReasonsQuery,
  useCreateReasonMutation,
  useUpdateReasonMutation,
  util,
  usePrefetch,
} = reasonsApi

export { useStateGetReasons, useStateGetReason }
