// users
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USERS = 'GET_USERS'
export const COUNT_USERS = 'COUNT_USERS'
export const DELETE_USER = 'DELETE_USER'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const CLEAR_USERS = 'CLEAR_USERS'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_USERS_CLEANUP = 'FETCH_USERS_CLEANUP'

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const FETCH_USER_CLEANUP = 'FETCH_USER_CLEANUP'
