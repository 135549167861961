import PupilsRepository from '@api/repositories/organization-pupil-repository'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { deleteOrganizationPupil } from '@app/store/actions/organization-pupils-actions'
import { Button, Form, Input, Modal, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  pupil: IOrganizationPupils
}

const OrganizationPupilsQueueAccept: React.FC<props> = ({ pupil }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
  }

  const onFinish = async (values: any) => {
    const newValues = {
      id: pupil.id,
      orderNo: values.orderNo,
      pinCode: values.pinCode,
    }
    setConfirmLoading(true)
    const userSubmitApi = PupilsRepository.approved
    await userSubmitApi(newValues)
      .then((response: AxiosResponse<IOrganizationPupils>) => {
        if (response.status === 200) {
          dispatch(deleteOrganizationPupil(response.data))
          openNotificationWithIcon('success', t('pupils.accepted'))
          setConfirmLoading(false)
        }
      })
      .catch(() => {
        setConfirmLoading(false)
      })
    if (!confirmLoading) {
      hideModal()
    }
    setConfirmLoading(false)
  }

  function getActualDate() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return day + '.' + month + '.' + year
  }

  const date = getActualDate()

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {t('general.accept')}
      </Button>

      <Modal
        open={visible}
        title={t('general.accept')}
        onCancel={hideModal}
        destroyOnClose={true}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{}}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'} initialValue={pupil.pupilId}>
              <Input />
            </Form.Item>
            <Form.Item
              label={t('pupils.orderNo')}
              name={'orderNo'}
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
            >
              <Input disabled={confirmLoading} />
            </Form.Item>
            <Form.Item
              initialValue={date}
              name={'orderDate'}
              label={t('pupils.orderDate')}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label={t('pupils.pinCode')}
              name={'pinCode'}
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
            >
              <Input disabled={confirmLoading} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default OrganizationPupilsQueueAccept
