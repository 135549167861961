import { ApiKeys } from '@app/constants/api-keys'
import { IOrganizationPassport } from '@app/interfaces/organization-passport/organization-passport'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { IRtkResponse } from '../../types'
import { emptyOrganizationApi } from '../organization'

export const organizationPassportApi = emptyOrganizationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOrganizationPassports: build.query<
      IRtkResponse<IOrganizationPassport[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/institution-passport',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationPassport[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.organizationPassport,
                id,
              })),
              { type: ApiKeys.organizationPassport, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organizationPassport, id: 'LIST' }],
    }),
    getOrganizationPassport: build.query<IOrganizationPassport, number>({
      query: (id) => ({
        url: `dictionary/api/v1/institution-passport/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.organizationPassport, id },
      ],
    }),
    createOrganizationPassport: build.mutation<
      IRtkResponse<IOrganizationPassport>,
      Required<IOrganizationPassport>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'dictionary/api/v1/institution-passport',
          data,
        }
      },
      transformResponse: (response: IOrganizationPassport, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.organizationPassport, id: 'LIST' }],
    }),
    updateOrganizationPassport: build.mutation<
      IRtkResponse<IOrganizationPassport>,
      Required<IOrganizationPassport>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/institution-passport/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganizationPassport, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationPassport, id: arg.id },
      ],
    }),
    partialUpdateOrganizationPassport: build.mutation<
      IRtkResponse<IOrganizationPassport>,
      Subset<IOrganizationPassport>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `dictionary/api/v1/institution-passport/${data.id}`,
        data,
      }),
      transformResponse: (response: IOrganizationPassport, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.organizationPassport, id: arg.id },
      ],
    }),
  }),
})

const useStateGetOrganizationPassportQuery =
  organizationPassportApi.endpoints.getOrganizationPassport.useQueryState
const useStateGetOrganizationPassportsQuery =
  organizationPassportApi.endpoints.getOrganizationPassports.useQueryState

export const {
  useGetOrganizationPassportsQuery,
  useGetOrganizationPassportQuery,
  useCreateOrganizationPassportMutation,
  useUpdateOrganizationPassportMutation,
  usePartialUpdateOrganizationPassportMutation,
} = organizationPassportApi

export {
  useStateGetOrganizationPassportQuery,
  useStateGetOrganizationPassportsQuery,
}
