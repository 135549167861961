import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import { IEducationLanguage } from '@app/interfaces/education/education-language'
import { AxiosResponse } from 'axios'

class EducationLanguageRepository implements IRepository<IEducationLanguage> {
  create(data: IEducationLanguage): Promise<AxiosResponse<IEducationLanguage>> {
    return bbitApi.post('/dictionary/api/v1/education-language', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEducationLanguage[]>> {
    return bbitApi.get('/dictionary/api/v1/education-language', {
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEducationLanguage>> {
    return bbitApi.get(`/dictionary/api/v1/education-language/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IEducationLanguage): Promise<AxiosResponse<IEducationLanguage>> {
    return bbitApi.put(`/dictionary/api/v1/education-language/${data.id}`, data)
  }
}

export default new EducationLanguageRepository()
