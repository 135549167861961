import useQuery from '@app/hooks/useQuery'
import { useState } from 'react'
import { PageAndSize } from '@app/shared/pagination/pagination'

const usePagination = (query?: string, pageSize?: PageAndSize) => {
  const { serializeQuery } = useQuery()
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(query),
  )
  const [pageAndSize, setPageAndSize] = useState<PageAndSize>(
    pageSize || {
      page: 0,
      size: 10,
    },
  )

  const requestFunction = (value: string) => {
    setSearchParams(new URLSearchParams(value))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sorterOrder = `${sorter.field},${
      sorter.order === 'ascend' ? 'asc' : 'desc'
    }`

    const sort = () => {
      if (sorter.order === undefined) {
        return null
      }
      return sorterOrder
    }
    requestFunction(
      serializeQuery({
        query: 'sort',
        value: sort(),
        searchParams: searchParams.toString(),
      }),
    )
  }

  const setPage = (page: number, size: number) => {
    setPageAndSize((prev) => ({
      ...prev,
      page: page,
      size: size,
    }))
  }

  return {
    searchParams,
    setSearchParams,
    requestFunction,
    pageAndSize,
    handleTableChange,
    setPage,
  }
}

export default usePagination
