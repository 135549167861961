import bbitApi from '@app/api/make-request'
import { IEmployeeRateMehnat } from '@app/interfaces/employee/employee-rate-mehnat'
import { AxiosResponse } from 'axios'

export interface IMehnatRepository<T> {
  getAll(pin?: string): Promise<AxiosResponse<T[]>>
}

class EmployeeRateMehnatRepository
  implements IMehnatRepository<IEmployeeRateMehnat>
{
  getAll(pin?: string): Promise<AxiosResponse<IEmployeeRateMehnat[]>> {
    return bbitApi.get(
      `/attendance/api/v1/test/labor/current-work/rate/info/${pin}`,
    )
  }
}

export default new EmployeeRateMehnatRepository()
