import React, { useCallback, useState } from 'react'
import { Checkbox, Popconfirm } from 'antd'

import { useTranslation } from 'react-i18next'
import bbitApi from '../../../../api/make-request'

type TProps = {
  id: number
  emisApiStatus: boolean
}

export const CheckboxAction: React.FC<TProps> = ({
  id,
  emisApiStatus,
}: TProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [confirmStatus, setConfirmStatus] = useState<boolean>(emisApiStatus)

  const onConfirm = useCallback(async () => {
    setLoading(true)
    try {
      await bbitApi.patch(`dictionary/api/v1/organizations/${id}`, {
        id,
        emisApiStatus: !confirmStatus,
      })
      setConfirmStatus(!confirmStatus)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [confirmStatus, id])

  return (
    <Popconfirm
      title={`${t('scheduler.emisApiStatus')}`}
      onConfirm={onConfirm}
      okText={`${t('general.yes')}`}
      cancelText={`${t('general.no')}`}
      placement="left">
      <Checkbox checked={confirmStatus} disabled={loading} />
    </Popconfirm>
  )
}
