export const GET_ORGANIZATION_TYPE = 'GET_ORGANIZATION_TYPE'
export const GET_ORGANIZATION_TYPES = 'GET_ORGANIZATION_TYPES'
export const CREATE_ORGANIZATION_TYPE = 'CREATE_ORGANIZATION_TYPE'
export const UPDATE_ORGANIZATION_TYPE = 'UPDATE_ORGANIZATION_TYPE'
export const DELETE_ORGANIZATION_TYPE = 'DELETE_ORGANIZATION_TYPE'
export const CLEAR_ORGANIZATION_TYPE = 'CLEAR_ORGANIZATION_TYPE'

export const FETCH_ORGANIZATION_TYPES_REQUEST =
  'FETCH_ORGANIZATION_TYPES_REQUEST'
export const FETCH_ORGANIZATION_TYPES_SUCCESS =
  'FETCH_ORGANIZATION_TYPES_SUCCESS'
export const FETCH_ORGANIZATION_TYPES_FAILURE =
  'FETCH_ORGANIZATION_TYPES_FAILURE'

export const FETCH_ORGANIZATION_TYPE_REQUEST = 'FETCH_ORGANIZATION_TYPE_REQUEST'
export const FETCH_ORGANIZATION_TYPE_SUCCESS = 'FETCH_ORGANIZATION_TYPE_SUCCESS'
export const FETCH_ORGANIZATION_TYPE_FAILURE = 'FETCH_ORGANIZATION_TYPE_FAILURE'
