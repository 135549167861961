import { ApiKeys } from '@app/constants/api-keys'
import { useGetEducationDailySchedulesQuery } from '@app/store/rtk/api/education/education-daily-schedule-api'
import { useGetEducationGradesQuery } from '@app/store/rtk/api/education/education-grade-api'
import { useGetEducationGroupLocationsQuery } from '@app/store/rtk/api/education/education-group-location-api'
import { useGetEducationGroupTypesQuery } from '@app/store/rtk/api/education/education-group-type-api'
import { useGetEducationLanguagesQuery } from '@app/store/rtk/api/education/education-language-api'
import { useGetEducationWeeklySchedulesQuery } from '@app/store/rtk/api/education/education-weekly-schedule-api'
import { useGetEmployeePositionsDictionaryQuery } from '@app/store/rtk/api/employee/employee-position-dictionary-api'
import { useGetEmployeeRatesQuery } from '@app/store/rtk/api/employee/employee-rate-api'
import { useGetEmployeeStatusesQuery } from '@app/store/rtk/api/employee/employee-status-api'
import { useGetNationalitiesQuery } from '@app/store/rtk/api/nationality-api'
import { useGetInstitutionTypesQuery } from '@app/store/rtk/api/organization-passport/institution-type-api'
import { useGetDistrictsQuery } from '@app/store/rtk/api/organization/district-api'
import { useGetInstitutionSchedulesQuery } from '@app/store/rtk/api/organization/institution-schedule-api'
import { useGetInstitutionSubTypesQuery } from '@app/store/rtk/api/organization/institution-subtype-api'
import { useGetOrganizationBuildingTypesQuery } from '@app/store/rtk/api/organization/organization-building-type-api'
import { useGetOrganizationLocalitiesQuery } from '@app/store/rtk/api/organization/organization-locality-api'
import { useGetOrganizationSchedulesQuery } from '@app/store/rtk/api/organization/organization-schedule-api'
import { useGetOrganizationSectorsQuery } from '@app/store/rtk/api/organization/organization-sector-api'
import { useGetOrganizationStatusesQuery } from '@app/store/rtk/api/organization/organization-statuses-api'
import { useGetOrganizationTypesQuery } from '@app/store/rtk/api/organization/organization-type-api'
import { useGetRegionsQuery } from '@app/store/rtk/api/organization/region-api'
import { useGetReasonsQuery } from '@app/store/rtk/api/reasons-api'
import localForage from 'localforage'
import { useEffect, useMemo, useState } from 'react'

const useCache = <T>(key: string) => {
  const [skip, setSkip] = useState<boolean>(true)
  const [cacheState, setCacheState] = useState<T[] | null>()
  const {
    data: result,
    isLoading,
    isSuccess,
    isError,
    error,
  } = getApi(key, skip)
  const data = useMemo(
    () => cacheState ?? (result?.response as T[]),
    [cacheState, result?.response],
  )

  function getApi(key: string, skip: boolean) {
    switch (key) {
      case ApiKeys.organizationType:
        return useGetOrganizationTypesQuery('', { skip })
      case ApiKeys.organizationLocality:
        return useGetOrganizationLocalitiesQuery('', { skip })
      case ApiKeys.organizationsStatus:
        return useGetOrganizationStatusesQuery('', { skip })
      case ApiKeys.organizationBuildingType:
        return useGetOrganizationBuildingTypesQuery('', { skip })
      case ApiKeys.organizationSector:
        return useGetOrganizationSectorsQuery('', { skip })
      case ApiKeys.organizationSchedule:
        return useGetOrganizationSchedulesQuery('', { skip })
      case ApiKeys.institutionType:
        return useGetInstitutionTypesQuery('', { skip })
      case ApiKeys.institutionSubType:
        return useGetInstitutionSubTypesQuery('', { skip })
      case ApiKeys.region:
        return useGetRegionsQuery('', { skip })
      case ApiKeys.district:
        return useGetDistrictsQuery('', { skip })
      case ApiKeys.educationGrade:
        return useGetEducationGradesQuery('', { skip })
      case ApiKeys.educationGroupType:
        return useGetEducationGroupTypesQuery('', { skip })
      case ApiKeys.educationGroupLocation:
        return useGetEducationGroupLocationsQuery('', { skip })
      case ApiKeys.educationDailySchedule:
        return useGetEducationDailySchedulesQuery('', { skip })
      case ApiKeys.educationWeeklySchedule:
        return useGetEducationWeeklySchedulesQuery('', { skip })
      case ApiKeys.educationLanguage:
        return useGetEducationLanguagesQuery('', { skip })
      case ApiKeys.institutionSchedule:
        return useGetInstitutionSchedulesQuery('', { skip })
      case ApiKeys.employeePositionDictionary:
        return useGetEmployeePositionsDictionaryQuery('', { skip })
      case ApiKeys.employeeRate:
        return useGetEmployeeRatesQuery('', { skip })
      case ApiKeys.employeeStatus:
        return useGetEmployeeStatusesQuery('', { skip })
      case ApiKeys.nationality:
        return useGetNationalitiesQuery('', { skip })
      case ApiKeys.reasons:
        return useGetReasonsQuery('', { skip })
      default:
        return useGetOrganizationTypesQuery('', { skip })
    }
  }

  useEffect(() => {
    const getData = async () => {
      const cache = await localForage.getItem<T[]>(key)
      cache ? setCacheState(cache) : setSkip(false)
    }
    getData()
  }, [])

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    skip,
  }
}

export default useCache
