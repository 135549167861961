import useLocalization from '@app/hooks/useLocalization'
import { IMedicalStaff } from '@app/interfaces/organization-passport/medical-staff'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  medicalStaff: IMedicalStaff[]
}

const MedicalStaffItem: React.FC<props> = ({ medicalStaff }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('medicalStaffAvailability.title')}
        name={'medicalStaffAvailability'}
      >
        <Select
          placeholder={t('institutionPlaceholders.medicalStaffAvailability')}
        >
          {sortByName(localize(medicalStaff)).map((medicalStaff, index) => {
            if (medicalStaff.status) {
              return (
                <Select.Option key={index} value={medicalStaff.id!.toString()}>
                  {medicalStaff?.data?.name}
                </Select.Option>
              )
            }
            return null
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default MedicalStaffItem
