import useLocalization from '@app/hooks/useLocalization'
import { IStayDuration } from '@app/interfaces/organization-passport/stay-duration'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  stayDuration: IStayDuration[]
}

const StayDurationItem: React.FC<props> = ({ stayDuration }) => {
  const [t, i18n] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item
        label={t('childrenStayDuration.title')}
        name={'childrenStayDuration'}
      >
        <Select placeholder={t('institutionPlaceholders.childrenStayDuration')}>
          {sortByName(localize(stayDuration)).map((stayDuration, index) => {
            if (stayDuration.status) {
              return (
                <Select.Option key={index} value={stayDuration.id!.toString()}>
                  {stayDuration?.data?.name}
                </Select.Option>
              )
            }
            return null
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default StayDurationItem
