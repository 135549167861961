import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MenuCalendarByPeriod from './components/menu-calendar-by-period/menu-calendar-by-period'
import MenuCalendarByPupil from './components/menu-calendar-by-pupil/menu-calendar-by-pupil'
import './menu-calendar.less'

enum MenuCalendarType {
  BY_PERIOD,
  BY_PUPIL,
}

const MenuCalendar: React.FC = () => {
  const [t] = useTranslation()
  setDocumentTitle(t('organizationMenu.calendar.title'))

  const [menuType, setMenuType] = useState<MenuCalendarType>(
    MenuCalendarType.BY_PUPIL,
  )

  const getComponent = (type: MenuCalendarType) => {
    if (type === MenuCalendarType.BY_PERIOD) return <MenuCalendarByPeriod />
    if (type === MenuCalendarType.BY_PUPIL) return <MenuCalendarByPupil />
  }

  const onTypeClickHandler = (type: MenuCalendarType) => {
    setMenuType(type)
  }

  const titles = [
    {
      id: 1,
      title: t('organizationMenu.calendar.types.byPeriod'),
      type: MenuCalendarType.BY_PERIOD,
    },
    {
      id: 2,
      title: t('organizationMenu.calendar.types.byPupil'),
      type: MenuCalendarType.BY_PUPIL,
    },
  ]

  return (
    <div className="menu-calendar">
      <div className="menu-calendar__header">
        {titles.map((title) => {
          return (
            <div
              key={title.id}
              className={`menu-calendar__header--btn ${
                menuType === title.type ? 'selected' : ''
              }`}
              onClick={() => onTypeClickHandler(title.type)}
            >
              {title.title}
            </div>
          )
        })}
      </div>
      <div className="menu-calendar__content">{getComponent(menuType)}</div>
    </div>
  )
}

export default MenuCalendar
