import { Button } from 'antd'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

type props = {
  title: string
  onClick?: MouseEventHandler<HTMLElement> | undefined
}

export const ButtonLinkTitle = ({ title, onClick }: props) => {
  const [t] = useTranslation()
  return (
    <Button type="link" onClick={onClick}>
      {t(title)}
    </Button>
  )
}
