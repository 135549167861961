import { DownOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import { LOADING } from '@app/constants/redux-state'
import usePagination from '@app/hooks/usePagination'
import { IOrganizationPupilsState } from '@app/interfaces/pupil/organization-pupils'
import OrganizationPupilsQueueDismiss from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-dismiss-form/organization-pupil-dismiss-form'
import OrganizationPupilsFilterForm from '@app/pages/organizations/components/organization-pupils/components/organization-pupils-filter-form/organization-pupils-filter-form'
import Pagination from '@app/shared/pagination/pagination'
import { getOrganizationPupils } from '@app/store/actions/organization-pupils-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Dropdown, Menu, Table, Tag } from 'antd'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { connect, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { PUPIL_FILTER_STATUSES } from '../../../../hooks/usePupilStatuses'
import OrganizationChangeGroup from './components/organization-pupil-changegroup-form/organization-pupil-changegroup-form'

interface props {
  _pupilsState: IOrganizationPupilsState
}

const dismissPermissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_PUPIL_ALL_DISMISS,
  AUTHORITIES.ROLE_PUPIL_CURRENT_DISMISS,
  AUTHORITIES.ROLE_PUPIL_REGION_DISMISS,
  AUTHORITIES.ROLE_PUPIL_DISTRICT_DISMISS,
]
const manualAddPermissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_PUPIL_ALL_EDIT,
  AUTHORITIES.ROLE_PUPIL_REGION_EDIT,
  AUTHORITIES.ROLE_PUPIL_DISTRICT_EDIT,
  AUTHORITIES.ROLE_PUPIL_CURRENT_EDIT,
]
const changeGroupPermissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_EDUCATION_GROUP_ALL_EDIT,
  AUTHORITIES.ROLE_EDUCATION_GROUP_REGION_EDIT,
  AUTHORITIES.ROLE_EDUCATION_GROUP_DISTRICT_EDIT,
  AUTHORITIES.ROLE_EDUCATION_GROUP_CURRENT_EDIT,
]

const OrganizationPupilsPage: React.FC<props> = ({ _pupilsState }) => {
  const { state, count, pupils } = _pupilsState
  const params: IPathParams = useParams()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const {
    searchParams,
    requestFunction,
    pageAndSize,
    handleTableChange,
    setPage,
  } = usePagination(
    `organizationId.equals=${params.id}&status.equals=${PUPIL_FILTER_STATUSES.APPROVED}`,
    {
      page: 1,
      size: 10,
    },
  )
  setDocumentTitle(t('pupils.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getOrganizationPupils(
        { params: searchParams, source },
        t('pupils.error'),
      ),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <Link
          className={'ant-btn, ant-btn-link'}
          to={`/organization/${params.id}/pupils/pupil/${record.id}`}
          state={{
            pupil: pupils.find((pupil) => pupil.id === Number(record.id)),
          }}>
          {(pageAndSize.page - 1) * pageAndSize.size + index + 1}
        </Link>
      ),
    },
    {
      title: t('pupils.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName?.localeCompare(b?.fullName),
        multiple: 1,
      },
      render: (text, record) => (
        <Link
          className={'ant-btn, ant-btn-link'}
          to={`/organization/${params.id}/pupils/pupil/${record.id}`}
          state={{
            pupil: pupils.find((pupil) => pupil.id === Number(record.id)),
          }}>
          {text.toUpperCase()}
        </Link>
      ),
    },
    {
      title: t('educationGroup.educationGroup'),
      dataIndex: 'groupName',
      key: 'groupName',
      sorter: {
        compare: (a, b) => a.groupName?.localeCompare(b?.groupName),
        multiple: 2,
      },
      render: (text, record) => (
        <Link
          className={'ant-btn, ant-btn-link'}
          to={`/organization/${params.id}/groups/${record.groupId}`}>
          {text}
        </Link>
      ),
    },
    {
      title: t('pupils.metric'),
      dataIndex: 'metric',
      key: 'metric',
    },
    {
      title: t('pupils.birthDate'),
      dataIndex: 'birthDate',
      key: 'birthDate',
      sorter: {
        compare: (a, b) => a.birthDate?.localeCompare(b?.birthDate),
        multiple: 3,
      },
      render: (text, record) => (
        <Moment format={'DD.MM.YYYY'}>{record.birthDate}</Moment>
      ),
    },
    {
      title: t('general.status'),
      key: 'status',
      dataIndex: 'status',
      render: (tags) => {
        return (
          <>
            {tags.map((tag) => {
              const color = tag === 'APPROVED' ? 'green' : 'volcano'
              return (
                <Tag color={color} key={tag}>
                  {t(`pupils.status.${tag}`)}
                </Tag>
              )
            })}
          </>
        )
      },
    },
    {
      title: t('pupils.gender'),
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <Dropdown
          trigger={['click']}
          overlay={() => (
            <Menu>
              {rolesChecker(dismissPermissions) &&
                record.pupil.status === 'APPROVED' && (
                  <Menu.Item key="dismissPupil">
                    <OrganizationPupilsQueueDismiss pupil={record.pupil} />
                  </Menu.Item>
                )}
              {rolesChecker(changeGroupPermissions) &&
                record.pupil.status === 'APPROVED' && (
                  <Menu.Item key="changeGroup">
                    <OrganizationChangeGroup pupil={record.pupil} />
                  </Menu.Item>
                )}
            </Menu>
          )}>
          <Button>
            {t('general.action')} <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ]

  const data =
    pupils && pupils.length
      ? pupils.map((pupil) => {
          return {
            key: pupil.id,
            id: pupil.id,
            metric: pupil.pupil?.metric,
            fullName: pupil.pupil?.fullName,
            birthDate: pupil.pupil?.birthDate,
            status: [pupil.status],
            gender:
              pupil.pupil?.gender === 'MALE'
                ? t('pupils.male')
                : t('pupils.female'),
            pupil: pupil,
            groupName: pupil.educationGroup.name,
            groupId: pupil.educationGroup.id,
          }
        })
      : []

  return (
    <>
      <div className="header-form">
        <OrganizationPupilsFilterForm
          load={state !== LOADING}
          request={requestFunction}
          searchParams={searchParams}
        />
      </div>
      <Card
        extra={
          <>
            {rolesChecker(manualAddPermissions) ? (
              <Link
                className={'ant-btn ant-btn-primary'}
                to={`/organization/${params.id}/pupils/manual-add`}>
                {t('pupils.add')}
              </Link>
            ) : null}
          </>
        }
        title={t('pupils.title')}>
        <Table
          columns={columns}
          dataSource={data}
          loading={state === LOADING}
          pagination={false}
          rowClassName="table-row"
          onChange={handleTableChange}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              setPage={setPage}
              searchParams={searchParams}
            />
          )}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _pupilsState: state.organizationPupils,
  }
}

export default connect(mapStateToProps, null)(OrganizationPupilsPage)
