import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MealTypes from './components/meal-types'
import MenuMeals from './components/menu-meals'
import MenuProducts from './components/menu-products'
import MenuSeasons from './components/menu-seasons'
import Menus from './components/menus'
import './menu-organizer.less'

enum MenuType {
  PRODUCTS,
  MEALS,
  // MEASUREMENTS,
  MEAL_TYPES,
  MENU,
  SEASON,
}

const MenuOrganizer: React.FC = () => {
  const [menuType, setMenuType] = useState<MenuType>(MenuType.SEASON)
  const [t] = useTranslation()
  setDocumentTitle(t('organizationMenu.organizer'))

  const getComponent = (type: MenuType) => {
    if (type === MenuType.PRODUCTS) return <MenuProducts />
    if (type === MenuType.MEALS) return <MenuMeals />
    if (type === MenuType.MEAL_TYPES) return <MealTypes />
    // if (type === MenuType.MEASUREMENTS) return <MenuMeasurements />
    if (type === MenuType.MENU) return <Menus />
    if (type === MenuType.SEASON) return <MenuSeasons />
  }

  const onTypeClickHandler = (type: MenuType) => {
    setMenuType(type)
  }

  const titles = [
    // {
    //   id: 1,
    //   title: t('organizationMenu.types.measurements'),
    //   type: MenuType.MEASUREMENTS,
    // },
    {
      id: 2,
      title: t('organizationMenu.types.products'),
      type: MenuType.PRODUCTS,
    },
    {
      id: 3,
      title: t('organizationMenu.types.meals'),
      type: MenuType.MEALS,
    },
    // {
    //   id: 4,
    //   title: t('organizationMenu.types.mealTypes'),
    //   type: MenuType.MEAL_TYPES,
    // },
    {
      id: 5,
      title: t('organizationMenu.types.menus'),
      type: MenuType.MENU,
    },
    {
      id: 6,
      title: t('organizationMenu.types.seasons'),
      type: MenuType.SEASON,
    },
  ]

  return (
    <div className="menu-organizer">
      <div className="menu-organizer__header">
        {titles.map((title) => {
          return (
            <div
              key={title.id}
              className={`menu-organizer__header--btn ${
                menuType === title.type ? 'selected' : ''
              }`}
              onClick={() => onTypeClickHandler(title.type)}
            >
              {title.title}
            </div>
          )
        })}
      </div>
      <div className="menu-organizer__content">{getComponent(menuType)}</div>
    </div>
  )
}

export default MenuOrganizer
