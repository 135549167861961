import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationContractSubsidyRepository from '@app/api/repositories/organization-contract-subsidy-repository'
import axios from 'axios'
import {
  CLEAR_ORGANIZATION_CONTRACT_SUBSIDIES,
  CLEAR_ORGANIZATION_CONTRACT_SUBSIDY,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_FAILURE,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_REQUEST,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_SUCCESS,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_CLEANUP,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_FAILURE,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_REQUEST,
  FETCH_ORGANIZATION_CONTRACT_SUBSIDY_SUCCESS,
  ORGANIZATION_CONTRACT_SUBSIDY_ADD_MODAL_SET,
  REFRESH_ORGANIZATION_CONTRACT_SUBSIDY,
} from '../types/organization-contract-subsidy'
import { IOrganizationContractSubsidyAddModalArgs } from './../../interfaces/organization-contract-subsidy'

export const getOrganizationContractSubsidies =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationContractSubsidiesRequest())
    await organizationContractSubsidyRepository
      .getAll(args)
      .then((response) => {
        dispatch(
          fetchOrganizationContractSubsidiesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationContractSubsidiesFailure(
              error.message,
              error.code,
            ),
          )
        }
      })
    fetchOrganizationContractSubsidyCleanup()
  }

export const getOrganizationContractSubsidy =
  (args: numberArgs, message) => async (dispatch) => {
    dispatch(fetchOrganizationContractSubsidyRequest())
    await organizationContractSubsidyRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationContractSubsidySuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationContractSubsidyFailure(
              error?.message,
              error?.code,
            ),
          )
        }
      })
    fetchOrganizationContractSubsidyCleanup()
  }

const fetchOrganizationContractSubsidiesRequest = () => {
  return {
    type: FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_REQUEST,
  }
}

const fetchOrganizationContractSubsidiesSuccess = (
  organizationContractSubsidies,
  count,
) => {
  return {
    type: FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_SUCCESS,
    payload: { organizationContractSubsidies, count },
  }
}

const fetchOrganizationContractSubsidiesFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_CONTRACT_SUBSIDIES_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationContractSubsidyRequest = () => {
  return {
    type: FETCH_ORGANIZATION_CONTRACT_SUBSIDY_REQUEST,
  }
}

const fetchOrganizationContractSubsidySuccess = (
  organizationContractSubsidy,
) => {
  return {
    type: FETCH_ORGANIZATION_CONTRACT_SUBSIDY_SUCCESS,
    payload: organizationContractSubsidy,
  }
}

const fetchOrganizationContractSubsidyFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_CONTRACT_SUBSIDY_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationContractSubsidyCleanup = () => {
  return {
    type: FETCH_ORGANIZATION_CONTRACT_SUBSIDY_CLEANUP,
  }
}

export function clearOrganizationContractSubsidy() {
  return {
    type: CLEAR_ORGANIZATION_CONTRACT_SUBSIDY,
  }
}

export function clearOrganizationContractSubsidies() {
  return {
    type: CLEAR_ORGANIZATION_CONTRACT_SUBSIDIES,
  }
}

export function refreshOrganizationContractSubsidy() {
  return {
    type: REFRESH_ORGANIZATION_CONTRACT_SUBSIDY,
  }
}

export function setOrganizationContractSubsidyAddModal(
  args: IOrganizationContractSubsidyAddModalArgs,
) {
  return {
    type: ORGANIZATION_CONTRACT_SUBSIDY_ADD_MODAL_SET,
    payload: args,
  }
}
