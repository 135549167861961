import { ApiKeys } from '@app/constants/api-keys'
import { IElectricity } from '@app/interfaces/organization-passport/electricity'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyOrganizationPassportApi } from '.'
import { IRtkResponse } from '../../types'

export const electricityApi = emptyOrganizationPassportApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getElectricities: build.query<
      IRtkResponse<IElectricity[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/electricitys',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IElectricity[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.electricity,
                id,
              })),
              { type: ApiKeys.electricity, id: 'LIST' },
            ]
          : [{ type: ApiKeys.electricity, id: 'LIST' }],
    }),
    getElectricity: build.query<IElectricity, number>({
      query: (id) => ({
        url: `dictionary/api/v1/electricitys/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [{ type: ApiKeys.electricity, id }],
    }),
    createElectricity: build.mutation<IRtkResponse<IElectricity>, IElectricity>(
      {
        query: (data) => {
          return {
            method: 'POST',
            url: 'dictionary/api/v1/electricitys',
            data,
          }
        },
        transformResponse: (response: IElectricity, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          {
            type: ApiKeys.electricity,
            id: args.id,
          },
          { type: ApiKeys.electricity, id: 'LIST' },
        ],
      },
    ),
    updateElectricity: build.mutation<IRtkResponse<IElectricity>, IElectricity>(
      {
        query: (data) => ({
          method: 'PUT',
          url: `dictionary/api/v1/electricitys/${data.id}`,
          data,
        }),
        transformResponse: (response: IElectricity, meta) => {
          return { response, meta }
        },
        invalidatesTags: (result, error, args) => [
          { type: ApiKeys.electricity, id: args.id },
          { type: ApiKeys.electricity, id: 'LIST' },
        ],
      },
    ),
  }),
})

const useStateGetElectricities =
  electricityApi.endpoints.getElectricities.useQueryState
const useStateGetElectricity =
  electricityApi.endpoints.getElectricity.useQueryState

export const {
  useGetElectricitiesQuery,
  useLazyGetElectricityQuery,
  useCreateElectricityMutation,
  useUpdateElectricityMutation,
  util,
  usePrefetch,
} = electricityApi

export { useStateGetElectricities, useStateGetElectricity }
