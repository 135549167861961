import { login, loginNoCapcha } from '@api/rest/userApi'
import { AUTHORITIES } from '@app/constants/authorities'
import { AUTH_TOKEN } from '@app/constants/authToken'
import { LOADING } from '@app/constants/redux-state'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import Loader from '@app/shared/loader/loader'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { getUserInfo } from '@app/store/actions/user-info-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import LogoLight from '@assets/images/Logosvg-light.svg'
import { Button, Form, Input, Select } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import './auth-page.less'
const isDevelopment = process.env.NODE_ENV === 'development' || 'stage'

const AuthPage: React.FC = () => {
  const { selectors } = useCreateSelector()
  const { message, userInfo, state } = useSelector(
    selectors.userInfo.userInfoState,
  )
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()
  const [isVerified, setIsVerified] = useState(isDevelopment)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { getFormattedCode } = useLocalization()
  const reRef = useRef<ReCAPTCHA>()
  setDocumentTitle(t('general.auth'))

  const langs = [
    {
      id: 1,
      title: t('actualLanguages.russian'),
      code: 'ru',
    },
    {
      id: 2,
      title: t('actualLanguages.english'),
      code: 'en',
    },
    {
      id: 3,
      title: t('actualLanguages.uzbek'),
      code: 'uz',
    },
  ]

  function handleChange(value) {
    i18n.changeLanguage(value)
  }

  const clientID = process.env.REACT_APP_CLIENT_ID
  const scope = process.env.REACT_APP_SCOPE
  const redirectURL =
    window.location.host === 'localhost:4560'
      ? `http://${window.location.host}/get-access-code`
      : `https://${window.location.host}/get-access-code`

  useEffect(() => {
    // if (process.env.NODE_ENV === "development") {
    //   localStorage.setItem(AUTH_TOKEN, process.env.REACT_APP_TOKEN!);
    // }
    if (localStorage.getItem(AUTH_TOKEN)) {
      if (rolesChecker([AUTHORITIES.ROLE_MANAGER])) {
        if (userInfo.organizations[0].organizationId)
          navigate(
            `/organization/${userInfo.organizations[0].organizationId}/manager`,
          )
        return
      }
      navigate('/profile')
      return
    }
    if (message) openNotificationWithIcon('error', t('errors.corsError'))
    if (!isEmpty(userInfo)) {
      if (rolesChecker([AUTHORITIES.ROLE_MANAGER])) {
        if (userInfo.organizations[0].organizationId) {
          navigate(
            `/organization/${userInfo.organizations[0].organizationId}/manager`,
          )
          return
        }
      }
      navigate('/profile')
    }
  }, [userInfo])

  const onFinish = async (values) => {
    setLoading(true)

    const newValues = {
      password: values.password,
      username: values.username,
      ...(isDevelopment ? {} : { captchaValue: reRef.current.getValue() }),
    }
    i18n.changeLanguage(values.language)
    async function auth() {
      await (isDevelopment ? loginNoCapcha(newValues) : login(newValues))
        .then((response) => {
          setLoading(false)
          localStorage.setItem(AUTH_TOKEN, response.data.access_token)
        })
        .catch((error) => {
          setIsVerified(false)
          reRef.current.reset()
          setLoading(false)
        })
      if (localStorage.getItem(AUTH_TOKEN)) {
        dispatch(getUserInfo())
      }
    }
    auth()
  }

  function recaptchaHandler() {
    setIsVerified(true)
  }

  return (
    <div className="auth">
      <div className="auth__bg" />
      <div className="auth__left">
        <div className="auth__left--mask" />
        <div className="auth__left--form">
          <Form onFinish={onFinish}>
            <Form.Item
              initialValue={getFormattedCode(i18n.language)}
              name={'language'}
              className="language">
              <Select
                onChange={handleChange}
                placeholder={getFormattedCode(i18n.language)}
                bordered={false}>
                {langs.map(function (lang) {
                  return (
                    <Select.Option key={lang.id} value={lang.code}>
                      {lang.code}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <h2>{t('general.auth')}</h2>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
                {
                  min: 5,
                  max: 180,
                  message: t('users.nameMinMessage'),
                },
              ]}
              name={'username'}>
              <Input placeholder={t('auth.login')} size={'large'} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
                {
                  min: 5,
                  max: 100,
                  message: t('users.passMinMessage'),
                },
              ]}
              name={'password'}>
              <Input.Password placeholder={t('auth.password')} size={'large'} />
            </Form.Item>
            {!isDevelopment && (
              <Form.Item className="recaptcha">
                <ReCAPTCHA
                  sitekey="6Lf95xgeAAAAAB_okhIIqVrue7s9vKhp1aEkW40u"
                  onChange={recaptchaHandler}
                  ref={reRef}
                />
              </Form.Item>
            )}
            <Form.Item>
              {loading || state === LOADING ? (
                <Loader />
              ) : (
                <Button
                  className="login"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={!isVerified}
                  block={true}>
                  {t('auth.enter')}
                </Button>
              )}
            </Form.Item>
            <Button
              className="login-egov"
              type={'link'}
              href={`https://sso.egov.uz/sso/oauth/Authorization.do?client_id=${clientID}&response_type=one_code&Scope=${scope}&state=testState&redirect_uri=${redirectURL}`}>
              {t('auth.egovEnter')}
            </Button>
            {/* <div>
            <OAuth2Login
              authorizationUrl="https://esi.uz/oauth2/authorize"
              responseType="code"
              popupWidth={1150}
              clientId={oAuth2EImzo.clientId}
              scope={oAuth2EImzo.scope}
              redirectUri={"https://emis.mdo.uz/eimzo.php"}
              onSuccess={onSuccess}
              onFailure={onFailure}
              buttonText={t("general.oAuth2E-imzo")}
              className={"ant-btn ant-btn-link oAuth-btn"}
            />
          </div> */}
          </Form>
        </div>
      </div>
      <div className="auth__right">
        <div className="auth__right--mask" />
        <img src={LogoLight} alt={''} />
        <h3 className="description">{t('auth.description')}</h3>
        <h4 className="footer">
          {`${new Date().getFullYear()} ©Bolalar Bog'chasi`}
        </h4>
      </div>
    </div>
  )
}

export default AuthPage
