import useLocalization from '@app/hooks/useLocalization'
import {
  IMeals,
  IMealType,
  IMenuContent,
} from '@app/interfaces/organization-menu'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Form, Select } from 'antd'
import React from 'react'
import { Dispatch } from 'react'
import { useEffect } from 'react'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import './menu-meal-type-item.less'

interface props {
  meals: IMeals[]
  mealTypes: IMealType[]
  menuContent: IMenuContent[]
  setMenuContent: Dispatch<SetStateAction<IMenuContent[]>>
  setChildForm: any
}

const MenuMealTypeItem: React.FC<props> = ({
  meals,
  mealTypes,
  menuContent,
  setMenuContent,
  setChildForm,
}) => {
  const [t] = useTranslation()
  const { localize, localizeData } = useLocalization()
  const [form] = Form.useForm()
  const onAddMenuContentHandler = (values: any) => {
    const addMealType = mealTypes.find((mealType) => mealType.id === values.id)
    if (menuContent.find((item) => item.mealType.id === addMealType?.id)) return
    if (addMealType) {
      setMenuContent((prev) => [...prev, { mealType: addMealType, meals: [] }])
    }
  }

  const onRemoveMenuContentHandler = (mealType: IMealType) => {
    const newProducts = menuContent.filter(
      (item) => item.mealType.id !== mealType.id,
    )
    setMenuContent((prev) => [...newProducts])
  }
  useEffect(() => {
    setChildForm(form)
  }, [])

  const onAddMealHandler = (mealTypeId: number) => {
    const menuContentItem = menuContent.find(
      (item) => item.mealType.id === mealTypeId,
    )
    if (!menuContentItem) return
    const mealId = form.getFieldValue(`mealId#${mealTypeId}`)
    if (!mealId) return
    const meal = meals.find((meal) => meal.id === mealId)
    if (!meal) return
    setMenuContent((prev) => [
      ...prev.map((item) => {
        if (item.mealType.id !== mealTypeId) return item
        if (item.meals.find((innerMeal) => innerMeal.id === meal.id))
          return item
        item.meals.push(meal)
        return item
      }),
    ])
  }

  const onRemoveMealHandler = (mealTypeId: number, meal: IMeals) => {
    setMenuContent((prev) => [
      ...prev.map((item) => {
        if (item.mealType.id === mealTypeId) {
          item.meals = item.meals.filter((mealItem) => mealItem.id !== meal.id)
        }
        return item
      }),
    ])
  }
  return (
    <div className="menu-content">
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onAddMenuContentHandler}
        className="menu-content__add"
      >
        <p className="title">{t('organizationMenu.mealTypes.add')}</p>
        <div className="form">
          <Form.Item name="id">
            <Select
              style={{ minWidth: '350px' }}
              showSearch
              placeholder={t('organizationMenu.mealTypes.name')}
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {sortByName(localize(mealTypes)).map((mealType) => {
                return (
                  <Select.Option key={mealType.id} value={mealType.id}>
                    {mealType.data?.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit" className="add-btn">
            {t('general.add')}
          </Button>
        </div>

        <div className="menu-content__current">
          <p className="title">{t('organizationMenu.mealTypes.current')}</p>
          {menuContent
            ?.sort((a, b) => {
              return a.mealType.data[0].id - b.mealType.data[0].id
            })
            .map((item) => {
              return (
                <div key={item.mealType.id}>
                  <div className="item">
                    <p className="item__title">{`${
                      localizeData(item.mealType.data)?.name
                    }`}</p>
                    <p
                      className="item__minus"
                      onClick={() => onRemoveMenuContentHandler(item.mealType)}
                    >
                      -
                    </p>
                  </div>
                  <div className="meals">
                    <div className="meals__form">
                      <Form.Item name={`mealId#${item.mealType.id}`}>
                        <Select
                          style={{ minWidth: '350px' }}
                          showSearch
                          placeholder={t('organizationMenu.meals.name')}
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            option?.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {sortByName(localize(meals)).map((meal) => {
                            return (
                              <Select.Option key={meal.id} value={meal.id}>
                                {meal.data?.name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                      <Button
                        type="default"
                        className="add-btn"
                        onClick={() => onAddMealHandler(item.mealType.id)}
                      >
                        {t('general.add')}
                      </Button>
                    </div>
                    {item.meals.map((meal) => {
                      return (
                        <div key={meal.id} className="meals__item">
                          <p className="meals__item--title">
                            {localizeData(meal.data)?.name} / {meal.weight}
                          </p>
                          <p
                            className="meals__item--minus"
                            onClick={() =>
                              onRemoveMealHandler(item.mealType.id, meal)
                            }
                          >
                            -
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
        </div>
      </Form>
    </div>
  )
}

export default MenuMealTypeItem
