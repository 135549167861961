import bbitApi from '@api/make-request'
import {
  IRepository,
  numberArgs,
  IPathParamsArgs,
} from '@api/repositories/i-repository'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import {
  IOrganizationContractSubsidy,
  IOrganizationContractSubsidyCreate,
  IOrganizationContractSubsidyPartialUpdate,
  IOrganizationContractSubsidyUpdate,
} from './../../interfaces/organization-contract-subsidy'

class OrganizationContractSubsidyRepository
  implements IRepository<IOrganizationContractSubsidy>
{
  create(
    data: IOrganizationContractSubsidyCreate,
  ): Promise<AxiosResponse<IOrganizationContractSubsidy>> {
    return bbitApi.post('/dictionary/api/v1/organization-subsidy', data)
  }

  getAll(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IOrganizationContractSubsidy[]>> {
    return bbitApi.get('/dictionary/api/v1/organization-subsidy', {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(
    args: numberArgs,
  ): Promise<AxiosResponse<IOrganizationContractSubsidy>> {
    return bbitApi.get(
      `/dictionary/api/v1/organization-subsidy/${args.data}`,
      {
        cancelToken: args.source?.token,
      },
    )
  }

  update(
    data: IOrganizationContractSubsidyUpdate,
  ): Promise<AxiosResponse<IOrganizationContractSubsidy>> {
    return bbitApi.put(
      `/dictionary/api/v1/organization-subsidy/${data.id}`,
      data,
    )
  }

  partialUpdate(
    data: IOrganizationContractSubsidyPartialUpdate,
  ): Promise<AxiosResponse<IOrganizationContractSubsidy>> {
    return bbitApi.put(
      `/dictionary/api/v1/organization-subsidy/${data.id}`,
      data,
    )
  }
}

export default new OrganizationContractSubsidyRepository()
