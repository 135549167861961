import { IPublicDictionaries } from '@app/interfaces/public-dictionaries'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import bbitApi from '../make-request'
import { IPathParamsArgs } from './i-repository'

class PublicDictionaryRepository {
  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IPublicDictionaries>> {
    return bbitApi.get('/dictionary/api/v1/public-dictionary', {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }
}

export default new PublicDictionaryRepository()
