import GroupInfo from '@app/pages/organizations/components/organization-groups/components/group-page/components/group-info/group-info'
import GroupPupils from '@app/pages/organizations/components/organization-groups/components/group-page/components/group-pupils/group-pupils'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import React from 'react'
import { useTranslation } from 'react-i18next'

const GroupPage: React.FC = () => {
  const [t] = useTranslation()
  setDocumentTitle(t('educationGroup.educationGroup'))

  return (
    <>
      <GroupInfo />
      <GroupPupils />
    </>
  )
}

export default GroupPage
