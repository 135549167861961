import { FormType } from '@app/constants/formType'
import { langs } from '@app/constants/languages'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { IMenuContent } from '@app/interfaces/organization-menu'
import { toggleMenuFormType } from '@app/store/actions/organization-menus-actions'
import { Button, Form, Input, Modal } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MenuMealTypeItem from './menu-meal-type-item'

interface props {
  addMenu: (values: any, menuContent: IMenuContent[], cb: () => void) => void
}

const MenuForm: React.FC<props> = ({ addMenu }) => {
  const [form] = Form.useForm()
  const { selectors } = useCreateSelector()
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const { localize, localizeData } = useLocalization()
  const [childForm, setChildForm] = useState<FormInstance<any>>()
  const [menuContent, setMenuContent] = useState<IMenuContent[]>([])
  const { menu, formType, meals, mealTypes } = useSelector(
    selectors.menus.menusState,
  )
  const showModal = () => {
    dispatch(toggleMenuFormType(FormType.ADD))
  }
  const hideModal = () => {
    dispatch(toggleMenuFormType(FormType.NONE))
    form.resetFields()
    setMenuContent([])
    childForm?.resetFields()
  }

  useEffect(() => {
    if (formType === FormType.EDIT) {
      const newData = menu.data
        .sort((a, b) => {
          return a.languageId - b.languageId
        })
        .filter((item) => item !== undefined)
      newData.unshift(undefined)
      form.setFieldsValue({
        id: menu.id,
        data: newData,
      })
      setMenuContent(
        menu.simpleContent.map((item) => ({
          ...item,
          mealType: { ...item.mealType },
          meals: [...item.meals],
          id: item.id,
        })),
      )
    }
  }, [menu])

  const onFormSubmitHandler = (values: any) => {
    addMenu(values, menuContent, hideModal)
  }

  return (
    <>
      <Button className={``} onClick={showModal} type={'primary'}>
        {t('general.add')}
      </Button>
      <Modal
        open={formType !== FormType.NONE}
        title={t('organizationMenu.menus.add')}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <Button type={'primary'} onClick={form.submit}>
              {t('general.accept')}
            </Button>
            <Button
              type={'default'}
              onClick={hideModal}
              style={{ marginLeft: '10px' }}
            >
              {t('general.cancel')}
            </Button>
          </React.Fragment>,
        ]}
      >
        <Form form={form} onFinish={onFormSubmitHandler} layout={'vertical'}>
          <Form.Item hidden={true} name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            label={t('general.name')}
          >
            <Form.List name="data">
              {() =>
                langs
                  .sort((a, b) => {
                    return a.languageId - b.languageId
                  })
                  .map(({ languageId, id, toShowCode }) => (
                    <React.Fragment key={languageId}>
                      <Form.Item hidden={true} name={[languageId, 'id']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[languageId, 'name']}
                        rules={[
                          {
                            required: true,
                            message: t('errors.requiredMessage'),
                          },
                          {
                            min: 2,
                            message: t('errors.minMessage'),
                          },
                        ]}
                      >
                        <Input addonBefore={toShowCode} />
                      </Form.Item>
                      <Form.Item
                        hidden={true}
                        name={[languageId, 'languageId']}
                        initialValue={languageId}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))
              }
            </Form.List>
          </Form.Item>
        </Form>
        <MenuMealTypeItem
          meals={meals}
          mealTypes={mealTypes}
          menuContent={menuContent}
          setMenuContent={setMenuContent}
          setChildForm={setChildForm}
        />
      </Modal>
    </>
  )
}

export default MenuForm
