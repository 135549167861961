export const UPDATE_EMPLOYEE_STATUSES = 'UPDATE_EMPLOYEE_STATUSES'
export const CREATE_EMPLOYEE_STATUSES = 'CREATE_EMPLOYEE_STATUSES'
export const DELETE_EMPLOYEE_STATUSES = 'DELETE_EMPLOYEE_STATUSES'
export const CLEAR_EMPLOYEE_STATUSES = 'CLEAR_EMPLOYEE_STATUSES'

export const FETCH_EMPLOYEE_STATUSES_REQUEST = 'FETCH_EMPLOYEE_STATUSES_REQUEST'
export const FETCH_EMPLOYEE_STATUSES_SUCCESS = 'FETCH_EMPLOYEE_STATUSES_SUCCESS'
export const FETCH_EMPLOYEE_STATUSES_FAILURE = 'FETCH_EMPLOYEE_STATUSES_FAILURE'

export const FETCH_EMPLOYEE_STATUS_REQUEST = 'FETCH_EMPLOYEE_STATUS_REQUEST'
export const FETCH_EMPLOYEE_STATUS_SUCCESS = 'FETCH_EMPLOYEE_STATUS_SUCCESS'
export const FETCH_EMPLOYEE_STATUS_FAILURE = 'FETCH_EMPLOYEE_STATUS_FAILURE'
