import { useGetOrganizationMentorEmployeesQuery } from '@app/store/rtk/api/employee/organization-employee-api'
import { Col, Form, Select } from 'antd'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

interface props {
  isOpen: boolean
}

interface IPathParams {
  id?: string
}

const TutorItem: React.FC<props> = ({ isOpen }) => {
  const [t] = useTranslation()
  const params: IPathParams = useParams()
  const { data, isLoading } = useGetOrganizationMentorEmployeesQuery(
    `organizationId.equals=${params.id}&employeePositionStatusType.equals=WORKING&employeePositionMentor.equals=true`,
  )

  const { educator, educatorAssistant } = useMemo(() => {
    const acc = {
      educator: [],
      educatorAssistant: [],
    }
    if (data) {
      return data.response.reduce((acc, res) => {
        if (res.position?.positionType === 'EDUCATOR') {
          acc.educator.push(res)
        } else if (res.position?.positionType === 'EDUCATOR_ASSISTANT') {
          acc.educatorAssistant.push(res)
        }
        return acc
      }, acc)
    }
    return acc
  }, [data])

  return (
    <>
      <Col span={12}>
        <Form.Item name={'employeeId'} label={t('educationGroup.tutor')}>
          <Select disabled={isLoading || !isOpen}>
            {educator?.map((employee, index) => (
              <Select.Option key={index} value={employee.employee.id}>
                {`${employee.employee.firstName} ${employee.employee.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={'educatorAssistantId'}
          label={t('educationGroup.educatorAssistant')}>
          <Select disabled={isLoading || !isOpen}>
            {educatorAssistant?.map((employee, index) => (
              <Select.Option key={index} value={employee.employee.id}>
                {`${employee.employee.firstName} ${employee.employee.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  )
}

export default TutorItem
