export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const GET_ORGANIZATION = 'GET_ORGANIZATION'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS'
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION'
export const REFRESH_ORGANIZATION = 'REFRESH_ORGANIZATION'

export const FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST'
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS'
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE'

export const FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST'
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS'
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE'

export const FETCH_ORGANIZATION_CLEANUP = 'FETCH_ORGANIZATION_CLEANUP'
