import educationDegreeRepository from '@app/api/repositories/education-degree-repository'
import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import { IEducationDegreeActions } from '@app/interfaces/education/education-degree'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  CREATE_EDUCATION_DEGREE,
  FETCH_EDUCATION_DEGREES_FAILURE,
  FETCH_EDUCATION_DEGREES_REQUEST,
  FETCH_EDUCATION_DEGREES_SUCCESS,
  FETCH_EDUCATION_DEGREE_FAILURE,
  FETCH_EDUCATION_DEGREE_REQUEST,
  FETCH_EDUCATION_DEGREE_SUCCESS,
  UPDATE_EDUCATION_DEGREE,
} from '@app/store/types/education-degree'
import axios from 'axios'

export const getEducationDegree =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchEducationDegreeRequest())
    await educationDegreeRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchEducationDegreeSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEducationDegreeFailure(error?.message, error?.code))
          openNotificationWithIcon('error', message)
        }
      })
  }

export const getEducationDegrees =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchEducationDegreesRequest())
    await educationDegreeRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchEducationDegreesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEducationDegreesFailure(error?.message, error?.code))
          openNotificationWithIcon('error', message)
        }
      })
  }

const fetchEducationDegreesRequest = (): IEducationDegreeActions => {
  return {
    type: FETCH_EDUCATION_DEGREES_REQUEST,
  }
}

const fetchEducationDegreesSuccess = (
  educationDegrees,
  count,
): IEducationDegreeActions => {
  return {
    type: FETCH_EDUCATION_DEGREES_SUCCESS,
    payload: { educationDegrees, count },
  }
}

const fetchEducationDegreesFailure = (
  message,
  code,
): IEducationDegreeActions => {
  return {
    type: FETCH_EDUCATION_DEGREES_FAILURE,
    payload: { message, code },
  }
}

const fetchEducationDegreeRequest = (): IEducationDegreeActions => {
  return {
    type: FETCH_EDUCATION_DEGREE_REQUEST,
  }
}

const fetchEducationDegreeSuccess = (
  educationDegree,
): IEducationDegreeActions => {
  return {
    type: FETCH_EDUCATION_DEGREE_SUCCESS,
    payload: educationDegree,
  }
}

const fetchEducationDegreeFailure = (
  message,
  code,
): IEducationDegreeActions => {
  return {
    type: FETCH_EDUCATION_DEGREE_FAILURE,
    payload: { message, code },
  }
}

export function updateEducationDegree(
  educationDegree,
): IEducationDegreeActions {
  return {
    type: UPDATE_EDUCATION_DEGREE,
    payload: educationDegree,
  }
}

export function createEducationDegree(
  educationDegree,
): IEducationDegreeActions {
  return {
    type: CREATE_EDUCATION_DEGREE,
    payload: educationDegree,
  }
}
