import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationLocality,
  IOrganizationLocalityState,
} from '@app/interfaces/organization/organization-locality'
import {
  CLEAR_ORGANIZATION_LOCALITY,
  CREATE_ORGANIZATION_LOCALITY,
  DELETE_ORGANIZATION_LOCALITY,
  FETCH_ORGANIZATION_LOCALITIES_FAILURE,
  FETCH_ORGANIZATION_LOCALITIES_REQUEST,
  FETCH_ORGANIZATION_LOCALITIES_SUCCESS,
  FETCH_ORGANIZATION_LOCALITY_FAILURE,
  FETCH_ORGANIZATION_LOCALITY_REQUEST,
  FETCH_ORGANIZATION_LOCALITY_SUCCESS,
  UPDATE_ORGANIZATION_LOCALITY,
} from '@app/store/types/organization-locality'

const initialState: IOrganizationLocalityState = {
  organizationLocalities: [],
  organizationLocality: {} as IOrganizationLocality,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationLocalityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_LOCALITIES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_LOCALITIES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationLocalities: action.payload.organizationLocalities,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_LOCALITIES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationLocalities: [],
      }
    case FETCH_ORGANIZATION_LOCALITY_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_LOCALITY_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationLocality: action.payload,
      }
    case FETCH_ORGANIZATION_LOCALITY_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationLocality: {},
      }
    case CLEAR_ORGANIZATION_LOCALITY:
      return { ...state, organizationLocalities: [] }
    case CREATE_ORGANIZATION_LOCALITY:
      return {
        ...state,
        organizationLocalities: [
          ...state.organizationLocalities,
          action.payload,
        ],
      }
    case UPDATE_ORGANIZATION_LOCALITY:
      const updatedOrganizationLocalities = state.organizationLocalities.map(
        function (organizationLocality) {
          if (action.payload.id === organizationLocality.id) {
            return action.payload
          }
          return organizationLocality
        },
      )
      return {
        ...state,
        organizationLocalities: updatedOrganizationLocalities,
      }
    case DELETE_ORGANIZATION_LOCALITY:
      const editedOrganizationLocalities = state.organizationLocalities.filter(
        function (organizationLocality) {
          if (action.payload.id === organizationLocality.id) {
            return false
          }
          return organizationLocality
        },
      )
      return { ...state, organizationLocalities: editedOrganizationLocalities }
    default:
      return state
  }
}
