import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationStatus,
  IOrganizationStatusState,
} from '@app/interfaces/organization/organization-status'
import {
  CLEAR_ORGANIZATION_STATUS,
  CREATE_ORGANIZATION_STATUS,
  DELETE_ORGANIZATION_STATUS,
  FETCH_ORGANIZATION_STATUSES_FAILURE,
  FETCH_ORGANIZATION_STATUSES_REQUEST,
  FETCH_ORGANIZATION_STATUSES_SUCCESS,
  FETCH_ORGANIZATION_STATUS_FAILURE,
  FETCH_ORGANIZATION_STATUS_REQUEST,
  FETCH_ORGANIZATION_STATUS_SUCCESS,
  UPDATE_ORGANIZATION_STATUS,
} from '@app/store/types/organization-status'

const initialState: IOrganizationStatusState = {
  organizationStatuses: [],
  organizationStatus: {} as IOrganizationStatus,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const organizationStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_STATUSES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_STATUSES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationStatuses: action.payload.organizationStatuses,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_STATUSES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationStatuses: [],
      }
    case FETCH_ORGANIZATION_STATUS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_STATUS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        organizationStatus: action.payload,
      }
    case FETCH_ORGANIZATION_STATUS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        organizationStatus: {},
      }
    case CLEAR_ORGANIZATION_STATUS:
      return { ...state, organizationStatuses: [] }
    case CREATE_ORGANIZATION_STATUS:
      return {
        ...state,
        organizationStatuses: [...state.organizationStatuses, action.payload],
      }
    case UPDATE_ORGANIZATION_STATUS:
      const updatedOrganizationStatuses = state.organizationStatuses.map(
        function (organizationStatus) {
          if (action.payload.id === organizationStatus.id) {
            return action.payload
          }
          return organizationStatus
        },
      )
      return { ...state, organizationStatuses: updatedOrganizationStatuses }
    case DELETE_ORGANIZATION_STATUS:
      const editedOrganizationStatuses = state.organizationStatuses.filter(
        function (organizationStatus) {
          if (action.payload.id === organizationStatus.id) {
            return false
          }
          return organizationStatus
        },
      )
      return { ...state, organizationStatuses: editedOrganizationStatuses }
    default:
      return state
  }
}
