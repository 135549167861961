import {
  IEmployeeAbsenceReason,
  IEmployeeAbsenceReasonCreatePayload,
  IEmployeeAbsenceReasonUpdatePayload,
  IOrganizationAttendanceEmployee,
  IOrganizationAttendanceGetPayload,
  IOrganizationAttendancePupil,
} from '@app/interfaces/organization-attendance'
import { AxiosResponse, CancelTokenSource } from 'axios'
import bbitApi from '../make-request'

class OrganizationAttendanceRepository {
  getByEmployee(
    data: IOrganizationAttendanceGetPayload,
    source?: CancelTokenSource,
  ): Promise<AxiosResponse<IOrganizationAttendanceEmployee>> {
    return bbitApi.get(
      `/attendance/api/v1/attendance/employee/organization/${data.id}?workDate=${data.workDate}`,
      { cancelToken: source?.token },
    )
  }

  getByPupil(
    data: IOrganizationAttendanceGetPayload,
    source?: CancelTokenSource,
  ): Promise<AxiosResponse<IOrganizationAttendancePupil>> {
    let eduGroupId = ''
    if (data.educationGroupId)
      eduGroupId = `&educationGroupId=${data.educationGroupId}`
    return bbitApi.get(
      `/attendance/api/v1/attendance/pupil/organization/${data.id}?workDate=${data.workDate}${eduGroupId}`,
      { cancelToken: source?.token },
    )
  }

  createEmployeeAbsenceReason(
    data: IEmployeeAbsenceReasonCreatePayload[],
  ): Promise<AxiosResponse<IEmployeeAbsenceReason>> {
    return bbitApi.post(
      `/attendance/api/v1/attendance/employee/absenceReason`,
      { employees: [...data] },
    )
  }

  updateEmployeeAbsenceReason(
    data: IEmployeeAbsenceReasonUpdatePayload[],
  ): Promise<AxiosResponse<IEmployeeAbsenceReason>> {
    return bbitApi.patch(
      `/attendance/api/v1/attendance/employee/absenceReason`,
      { employee: [...data] },
    )
  }
}

export default new OrganizationAttendanceRepository()
