import { ApiKeys } from '@app/constants/api-keys'
import { IOrganizationSector } from '@app/interfaces/organization/organization-sector'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import localforage from 'localforage'
import { emptyOrganizationApi } from '.'
import { IRtkResponse } from '../../types'

export const organizationSectorApi = emptyOrganizationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOrganizationSectors: build.query<
      IRtkResponse<IOrganizationSector[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'dictionary/api/v1/organization-sector',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationSector[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.organizationSector,
                id,
              })),
              { type: ApiKeys.organizationSector, id: 'LIST' },
            ]
          : [{ type: ApiKeys.organizationSector, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(ApiKeys.organizationSector, data?.response)
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getOrganizationSector: build.query<IOrganizationSector, number>({
      query: (id) => ({
        url: `dictionary/api/v1/organization-sector/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.organizationSector, id },
      ],
    }),
  }),
})

export const { useGetOrganizationSectorsQuery, useGetOrganizationSectorQuery } =
  organizationSectorApi
