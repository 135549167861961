import { axiosBaseQuery } from '@app/api/make-request'
import { ApiKeys } from '@app/constants/api-keys'
import { createApi } from '@reduxjs/toolkit/query/react'

export const emptyOrganizationPassportApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'organizationPassportApi',
  tagTypes: [
    ApiKeys.drinkingWater,
    ApiKeys.electricity,
    ApiKeys.foodPreparingPlaces,
    ApiKeys.heatingSystemSource,
    ApiKeys.hotWaterSource,
    ApiKeys.institutionType,
    ApiKeys.medicalStaff,
    ApiKeys.organizationPassport,
    ApiKeys.organizationPassportization,
    ApiKeys.ownership,
    ApiKeys.solidWasteRemovings,
    ApiKeys.stayDuration,
    ApiKeys.toiletCleaning,
    ApiKeys.toiletLocation,
    ApiKeys.toiletType,
    ApiKeys.waterCleaningMethod,
    ApiKeys.waterSource,
    ApiKeys.outsourcingCompany,
    ApiKeys.workforce,
  ],
  endpoints: () => ({}),
})
