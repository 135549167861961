import { ApiKeys } from '@app/constants/api-keys'
import { IEmployeePosition } from '@app/interfaces/employee/employee-position'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import localforage from 'localforage'
import { emptyEmployeeApi } from '.'
import { IRtkResponse } from '../../types'

export const employeePositionDictionaryApi = emptyEmployeeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getEmployeePositionsDictionary: build.query<
      IRtkResponse<IEmployeePosition[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'employee/api/v1/employee-position-dictionary',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IEmployeePosition[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.employeePositionDictionary,
                id,
              })),
              { type: ApiKeys.employeePositionDictionary, id: 'LIST' },
            ]
          : [{ type: ApiKeys.employeePositionDictionary, id: 'LIST' }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localforage.setItem(
            ApiKeys.employeePositionDictionary,
            data?.response,
          )
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getEmployeePositionDictionary: build.query<IEmployeePosition, number>({
      query: (id) => ({
        url: `employee/api/v1/employee-position-dictionary/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.employeePositionDictionary, id },
      ],
    }),
    createEmployeePositionDictionary: build.mutation<
      IRtkResponse<IEmployeePosition>,
      Required<IEmployeePosition>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'employee/api/v1/employee-position-dictionary',
          data,
        }
      },
      transformResponse: (response: IEmployeePosition, meta) => {
        return { response, meta }
      },
      invalidatesTags: [
        { type: ApiKeys.employeePositionDictionary, id: 'LIST' },
      ],
    }),
    updateEmployeePositionDictionary: build.mutation<
      IRtkResponse<IEmployeePosition>,
      Required<IEmployeePosition>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/employee-position-dictionary/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployeePosition, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employeePositionDictionary, id: arg.id },
      ],
    }),
    partialUpdateEmployeePositionDictionary: build.mutation<
      IRtkResponse<IEmployeePosition>,
      Subset<IEmployeePosition>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `employee/api/v1/employee-position-dictionary/${data.id}`,
        data,
      }),
      transformResponse: (response: IEmployeePosition, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.employeePositionDictionary, id: arg.id },
      ],
    }),
  }),
})

const useStateGetEmployeePositionQuery =
  employeePositionDictionaryApi.endpoints.getEmployeePositionDictionary
    .useQueryState
const useStateGetEmployeePositionsQuery =
  employeePositionDictionaryApi.endpoints.getEmployeePositionsDictionary
    .useQueryState

export const {
  useGetEmployeePositionDictionaryQuery,
  useGetEmployeePositionsDictionaryQuery,
  useCreateEmployeePositionDictionaryMutation,
  useUpdateEmployeePositionDictionaryMutation,
  usePartialUpdateEmployeePositionDictionaryMutation,
} = employeePositionDictionaryApi

export { useStateGetEmployeePositionQuery, useStateGetEmployeePositionsQuery }
