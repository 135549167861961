import { LOADING } from '@app/constants/redux-state'
import usePagination from '@app/hooks/usePagination'
import { IPupilsState } from '@app/interfaces/pupil/pupils'
import PupilsFilterForm from '@app/pages/pupils-page/components/pupils-filter-form/pupils-filter-form'
import Pagination from '@app/shared/pagination/pagination'
import { getPupils } from '@app/store/actions/pupils-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

interface props {
  _pupilsState: IPupilsState
}

const DEFAULT_QUERY = `page=0&size=10`

const PupilsPage: React.FC<props> = ({ _pupilsState }) => {
  const { state, count, pupils } = _pupilsState
  const [showTable, setShowTable] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { searchParams, requestFunction, pageAndSize, handleTableChange } =
    usePagination(DEFAULT_QUERY)
  setDocumentTitle(t('routes.pupils'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (showTable)
      dispatch(getPupils({ params: searchParams, source }, t('pupils.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Link className={'ant-btn, ant-btn-link'} to={`/pupils/${record.id}`}>
          {text}
        </Link>
      ),
      sorter: true,
    },
    {
      title: t('pupils.metric'),
      dataIndex: 'metric',
      key: 'metric',
    },
    {
      title: t('pupils.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <Link className={'ant-btn, ant-btn-link'} to={`/pupils/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: t('pupils.gender'),
      dataIndex: 'gender',
      key: 'gender',
    },
  ]

  const data = pupils.length
    ? pupils.map((pupil) => {
        return {
          key: pupil.id,
          id: pupil.id,
          metric: pupil.metric,
          fullName: pupil.fullName,
          gender:
            pupil.gender === 'MALE' ? t('pupils.male') : t('pupils.female'),
        }
      })
    : []

  return (
    <>
      <div className="header-form">
        <PupilsFilterForm
          request={requestFunction}
          setShowTable={setShowTable}
          showTable={showTable}
        />
      </div>
      {showTable && (
        <Card title={t('pupils.title')}>
          <Table
            columns={columns}
            dataSource={data}
            loading={state === LOADING}
            pagination={false}
            rowClassName="table-row"
            onChange={handleTableChange}
            footer={() => (
              <Pagination
                request={requestFunction}
                totalCount={count}
                searchParams={searchParams}
                isLegacy
              />
            )}
          />
        </Card>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _pupilsState: state.pupils,
  }
}

export default connect(mapStateToProps, null)(PupilsPage)
