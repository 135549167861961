import { AUTHORITIES } from '@app/constants/authorities'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganization } from '@app/interfaces/organization/organization'
import { useGetInstitutionSubTypesQuery } from '@app/store/rtk/api/organization/institution-subtype-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, FormInstance, Select } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

interface props {
  disabled: boolean
  organization?: IOrganization
  form: FormInstance<any>
}

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_ORGANIZATION_CLASSIFICATOR_EDIT,
]

const InstitutionSubTypeItem: React.FC<props> = ({
  disabled,
  organization,
  form,
}) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { selectors } = useCreateSelector()
  const { currentInstitutionTypeId } = useSelector(
    selectors.institutionTypes.institutionTypesState,
  )
  const { data } = useGetInstitutionSubTypesQuery('')

  const filtered = useMemo(
    () =>
      data?.response?.filter(
        (type) => type.institutionTypeId === currentInstitutionTypeId,
      ),
    [currentInstitutionTypeId, data],
  )

  useEffect(() => {
    if (organization) {
      form.setFieldsValue({
        institutionSubType: filtered?.find(
          (type) => type.id === organization?.institutionSubType?.id,
        )?.id,
      })
    }
  }, [filtered])

  return (
    <>
      {
        <Form.Item
          label={t('institutionSubType.title')}
          name={'institutionSubType'}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          initialValue={
            localize(filtered as any)?.find(
              (subType) => subType.id === organization?.institutionSubType?.id,
            )?.id
          }>
          <Select
            allowClear={true}
            disabled={!rolesChecker(permissions) && disabled}>
            {sortByName(localize(filtered as any))?.map((institutionSubType) => {
              if (institutionSubType.status) {
                return (
                  <Select.Option
                    key={institutionSubType.id}
                    value={institutionSubType.id}>
                    {institutionSubType.data.name}
                  </Select.Option>
                )
              }
              return null
            })}
          </Select>
        </Form.Item>
      }
    </>
  )
}

export default InstitutionSubTypeItem
