import useLocalization from '@app/hooks/useLocalization'
import { IElectricity } from '@app/interfaces/organization-passport/electricity'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  electricities: IElectricity[]
}

const ElectricitiesItem: React.FC<props> = ({ electricities }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  return (
    <>
      <Form.Item label={t('electricity.title')} name={'electricity'}>
        <Select>
          {sortByName(localize(electricities)).map((electricity, index) => {
            if (electricity.status) {
              return (
                <Select.Option key={index} value={electricity.id!.toString()}>
                  {electricity?.data?.name}
                </Select.Option>
              )
            }
            return null
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default ElectricitiesItem
