import { ApiKeys } from '@app/constants/api-keys'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import { useGetOrganizationsQuery } from '@app/store/rtk/api/organization/organization-api'
import { findById } from '@app/utils/find-by-id/find-by-id'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, FormInstance, Select, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

interface IProps {
  form: FormInstance<any>
  setOrganizationId: React.Dispatch<React.SetStateAction<string>>
}

const OrganizationWarehouseFilters: React.FC<IProps> = ({
  form,
  setOrganizationId,
}) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const [regionId, setRegionId] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams('')
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts, isLoading } = useCache<IDistrict>(ApiKeys.district)
  const { data: organizations } = useGetOrganizationsQuery(
    searchParams.toString(),
    { skip: !searchParams.has('districtId.equals') },
  )

  const onRegionChangeHandler = (value) => {
    setRegionId(value)
    form.setFieldsValue({ districtId: undefined })
    form.setFieldsValue({ organizationId: undefined })
  }

  const onDistrictChangeHandler = (value) => {
    setSearchParams(
      `page=0&size=500&regionId.equals=${regionId}&districtId.equals=${value}`,
    )
    form.setFieldsValue({ organizationId: undefined })
  }

  const onOrganizationChangeHandler = (value) => {
    form.getFieldValue('organizationId') &&
      setOrganizationId(form.getFieldValue('organizationId'))
  }

  const getRegionName = (regionId) => {
    return findById(regions, regionId)?.data.name
  }

  const findDistricts = (id) => {
    return isEmpty(districts)
      ? []
      : districts.filter((district) => district.regionId === id)
  }

  return (
    <Spin spinning={isLoading}>
      <Form form={form} layout={'vertical'}>
        <div className="warehouse__filters--row">
          <Form.Item name={'regionId'}>
            <Select
              placeholder={t('region.region')}
              showSearch={true}
              onChange={onRegionChangeHandler}
            >
              {sortByName(localize(regions))?.map(({ data: { name }, id }) => {
                return (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item name={'districtId'}>
            <Select
              showSearch={true}
              placeholder={t('district.district')}
              onChange={onDistrictChangeHandler}
            >
              {sortByName(localize(findDistricts(regionId)))
                ?.filter((district) => {
                  return district.data.name !== getRegionName(regionId)
                })
                .map((district) => {
                  return (
                    <Select.Option key={district.id} value={district.id}>
                      {district.data.name}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item name={'organizationId'}>
            <Select
              showSearch={true}
              placeholder={t('organization.title')}
              onChange={onOrganizationChangeHandler}
            >
              {organizations?.response?.map((organization) => {
                return (
                  <Select.Option key={organization.id} value={organization.id}>
                    {organization.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  )
}

export default OrganizationWarehouseFilters
