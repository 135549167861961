import { ApiKeys } from '@app/constants/api-keys'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import { Subset } from '@app/store/types'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyEducationApi } from '.'
import { IRtkResponse } from '../../types'

export const educationGroupApi = emptyEducationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getEducationGroups: build.query<
      IRtkResponse<IEducationGroup[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'education/api/v1/education-group',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IEducationGroup[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.educationGroup,
                id,
              })),
              { type: ApiKeys.educationGroup, id: 'LIST' },
            ]
          : [{ type: ApiKeys.educationGroup, id: 'LIST' }],
    }),
    getEducationGroup: build.query<IEducationGroup, number>({
      query: (id) => ({
        url: `education/api/v1/education-group/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.educationGroup, id },
      ],
    }),
    createEducationGroup: build.mutation<
      IRtkResponse<IEducationGroup>,
      Required<IEducationGroup>
    >({
      query: (data) => {
        return {
          method: 'POST',
          url: 'education/api/v1/education-group',
          data,
        }
      },
      transformResponse: (response: IEducationGroup, meta) => {
        return { response, meta }
      },
      invalidatesTags: [{ type: ApiKeys.educationGroup, id: 'LIST' }],
    }),
    updateEducationGroup: build.mutation<
      IRtkResponse<IEducationGroup>,
      Required<IEducationGroup>
    >({
      query: (data) => ({
        method: 'PUT',
        url: `education/api/v1/education-group/${data.id}`,
        data,
      }),
      transformResponse: (response: IEducationGroup, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.educationGroup, id: 'LIST' },
        { type: ApiKeys.educationGroup, id: arg.id },
      ],
    }),
    partialUpdateEducationGroup: build.mutation<
      IRtkResponse<IEducationGroup>,
      Subset<IEducationGroup>
    >({
      query: (data) => ({
        method: 'PATCH',
        url: `education/api/v1/education-group/${data.id}`,
        data,
      }),
      transformResponse: (response: IEducationGroup, meta) => {
        return { response, meta }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiKeys.educationGroup, id: 'LIST' },
        { type: ApiKeys.educationGroup, id: arg.id },
      ],
    }),
  }),
})

const useStateGetOrganizationPassportsQuery =
  educationGroupApi.endpoints.getEducationGroups.useQueryState
const useStateGetOrganizationPassportQuery =
  educationGroupApi.endpoints.getEducationGroup.useQueryState

export const {
  useGetEducationGroupsQuery,
  useGetEducationGroupQuery,
  useCreateEducationGroupMutation,
  useUpdateEducationGroupMutation,
  usePartialUpdateEducationGroupMutation,
} = educationGroupApi

export {
  useStateGetOrganizationPassportsQuery,
  useStateGetOrganizationPassportQuery,
}
