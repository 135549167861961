import {
  FETCH_PUBLIC_DICTIONARY_FAILURE,
  FETCH_PUBLIC_DICTIONARY_REQUEST,
  FETCH_PUBLIC_DICTIONARY_SUCCESS,
} from '@app/store/types/public-dictionary'
import {
  IPublicDictionariesState,
  IPublicDictionaries,
} from '@app/interfaces/public-dictionaries'
import { IDLE, LOADING, SUCCEEDED, FAILED } from '@app/constants/redux-state'

const initialState: IPublicDictionariesState = {
  allDictionaries: {} as IPublicDictionaries,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const publicDictionariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PUBLIC_DICTIONARY_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_PUBLIC_DICTIONARY_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        allDictionaries: action.payload.allDictionaries,
        count: action.payload.count,
      }
    case FETCH_PUBLIC_DICTIONARY_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        allDictionaries: [],
      }
    default:
      return state
  }
}
