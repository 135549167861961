import useLocalization from '@app/hooks/useLocalization'
import { INationality } from '@app/interfaces/nationality'
import { IParent } from '@app/interfaces/pupil/parent'
import RemoveParentModal from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/found-pupil/components/pupils-parent-item/components/remove-parent-modal/remove-parent-modal'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Descriptions, Form, Input, Radio, Select, Switch } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  name: number
  fieldKey: number
  remove: (name: number) => void
  confirmLoading: boolean
  _nationalities: INationality[]
  parent?: IParent
}

const PupilsParentFormItem: React.FC<props> = ({
  parent,
  fieldKey,
  name,
  remove,
  confirmLoading,
  _nationalities,
}) => {
  const { localize } = useLocalization()
  const { t } = useTranslation()
  const [isUzbek, setIsUzbek] = useState<boolean>(
    parent?.resident ? parent.resident : false,
  )

  const onSwitch = (e) => {
    setIsUzbek(e)
  }

  const affinity = [
    {
      key: 'PARENT',
      value: t('pupils.parent'),
    },
    {
      key: 'GUARDIAN',
      value: t('pupils.guardian'),
    },
  ]

  const genders = [
    {
      key: 'MALE',
      value: t('general.male'),
    },
    {
      key: 'FEMALE',
      value: t('general.female'),
    },
  ]

  return (
    <React.Fragment>
      <Form.Item hidden={true} name={[name, 'id']} fieldKey={[fieldKey, 'id']}>
        <Input />
      </Form.Item>
      <div className={'found-pupil__delete-parent'}>
        <RemoveParentModal remove={remove} name={name} />
      </div>
      <Descriptions
        className={'found-pupil'}
        size={'small'}
        column={1}
        bordered
      >
        <Descriptions.Item label={t('pupils.affinity')}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={[name, 'affinity']}
            fieldKey={[fieldKey, 'affinity']}
          >
            <Select disabled={confirmLoading} allowClear={true}>
              {affinity.map(({ key, value }) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.form.isUzbekCitizenship')}>
          <Form.Item
            name={[name, 'resident']}
            fieldKey={[fieldKey, 'resident']}
            valuePropName={'checked'}
          >
            <Switch disabled={confirmLoading} onChange={onSwitch} />
          </Form.Item>
        </Descriptions.Item>
        {isUzbek && (
          <Descriptions.Item label={t('pupils.pin')}>
            <Form.Item
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={[name, 'pin']}
              fieldKey={[fieldKey, 'pin']}
            >
              <Input disabled={confirmLoading} />
            </Form.Item>
          </Descriptions.Item>
        )}

        <Descriptions.Item label={t('pupils.fullName')}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string'
                    ? value.trim().toUpperCase()
                    : undefined,
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={[name, 'fullName']}
            fieldKey={[fieldKey, 'fullName']}
          >
            <Input
              style={{ textTransform: 'uppercase' }}
              disabled={confirmLoading}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('pupils.gender')}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            name={[name, 'gender']}
            fieldKey={[fieldKey, 'gender']}
          >
            <Radio.Group disabled={confirmLoading}>
              {genders.map((gender, index) => (
                <Radio key={index} value={gender.key}>
                  {gender.value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('parents.phone')}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string'
                    ? value.trim().toUpperCase()
                    : undefined,
                required: true,
                message: t('errors.requiredMessage'),
              },
              {
                validator: async (_, phone) => {
                  if (phone?.trim().length) {
                    if (!phone?.match(/^[0-9]*$/)) {
                      return Promise.reject(
                        new Error(t('errors.phoneRegularError')),
                      )
                    }
                  }
                },
              },
            ]}
            fieldKey={[fieldKey, 'phone']}
            name={[name, 'phone']}
          >
            <Input disabled={confirmLoading} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('nationality.title')}>
          <Form.Item
            name={[name, 'nationalityId']}
            fieldKey={[fieldKey, 'nationalityId']}
          >
            <Select disabled={confirmLoading} allowClear={true}>
              {sortByName(localize(_nationalities)).map((nationality) => {
                if (nationality.status) {
                  return (
                    <Select.Option key={nationality.id} value={nationality.id}>
                      {nationality.data?.name}
                    </Select.Option>
                  )
                }
                return null
              })}
            </Select>
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  )
}

export default PupilsParentFormItem
