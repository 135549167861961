import organizationWarehouseRepository from '@app/api/repositories/organization-warehouse-repository'
import useCreateSelector from '@app/hooks/useCreateSelector'
import useLocalization from '@app/hooks/useLocalization'
import { IProducts } from '@app/interfaces/organization-menu'
import { IWarehouseInvoiceProduct } from '@app/interfaces/organization-warehouse'
import Loader from '@app/shared/loader/loader'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { getMenuProducts } from '@app/store/actions/organization-menus-actions'
import { toggleWarehouseAddInvoiceModal } from '@app/store/actions/organization-warehouse-actions'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Form, Input, Modal, Select } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import './organization-warehouse-invoice-add-form.less'

interface props {
  organizationId?: string
}

interface IPathParams {
  id?: string
}

const OrganizationWarehouseInvoiceAddForm: FC<props> = ({ organizationId }) => {
  const { selectors } = useCreateSelector()
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const params: IPathParams = useParams()
  const { toggleAddInvoiceModal } = useSelector(
    selectors.warehouse.warehouseState,
  )
  const products = useSelector(selectors.menuProducts.menuProducts)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const { localize, localizeData } = useLocalization()
  const [addedProducts, setAddedProducts] = useState<
    IWarehouseInvoiceProduct[]
  >([])

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(products))
      dispatch(
        getMenuProducts(
          { params: new URLSearchParams('page=0&size=500'), source },
          t('organizationMenu.errors.products'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const hideModal = () => {
    dispatch(toggleWarehouseAddInvoiceModal(false))
    form.resetFields()
  }

  const onFormSubmitHandler = (values: any) => {
    if (!addedProducts.length) return
    setLoading(true)
    const body = {
      organizationId: params.id,
      invoiceNumber: values.invoiceNumber,
      products: addedProducts.map((product) => ({
        productId: product.product.id,
        quantity: product.quantity,
      })),
    }
    organizationWarehouseRepository
      .addProductToWarehouse(body)
      .then(() => {
        setLoading(false)
        openNotificationWithIcon(
          'success',
          t('organizationWarehouse.addSuccess'),
        )
        hideModal()
      })
      .catch(() => {
        hideModal()
        openNotificationWithIcon(
          'error',
          t('organizationWarehouse.errors.addError'),
        )
      })
  }

  const onAddProductHandler = () => {
    const values = form.getFieldsValue()
    if (!values.productId && !values.quantity) return
    if (
      addedProducts.find((product) => product.product.id === values.productId)
    )
      return
    setAddedProducts((prev) => [
      ...prev,
      {
        product: products.find((product) => product.id === values.productId),
        quantity: values.quantity,
      },
    ])
  }

  const onRemoveProductHandler = (product: IProducts) => {
    const newProducts = addedProducts.filter(
      (item) => item.product.id !== product.id,
    )
    setAddedProducts([...newProducts])
  }

  return (
    <div className="warehouse-invoice-add-form">
      <Modal
        open={toggleAddInvoiceModal}
        title={t('organizationWarehouse.invoiceModalTitle')}
        onCancel={hideModal}
        footer={
          loading ? (
            <Loader />
          ) : (
            [
              <React.Fragment key={1}>
                <Button type={'primary'} onClick={form.submit}>
                  {t('general.accept')}
                </Button>
                <Button
                  type={'default'}
                  onClick={hideModal}
                  style={{ marginLeft: '10px' }}>
                  {t('general.cancel')}
                </Button>
              </React.Fragment>,
            ]
          )
        }>
        <Form form={form} onFinish={onFormSubmitHandler} layout={'vertical'}>
          <Form.Item
            name="invoiceNumber"
            rules={[
              {
                required: true,
                message: t('errors.requiredMessage'),
              },
            ]}
            label={t('organizationWarehouse.invoiceNumber')}>
            <Input placeholder={t('organizationWarehouse.invoiceNumber')} />
          </Form.Item>
          <div className="warehouse-invoice-add-form__add">
            <p className="warehouse-invoice-add-form__add--label">
              {t('organizationMenu.products.add')}
            </p>
            <div className="warehouse-invoice-add-form__add--input">
              <Form.Item name="productId">
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  placeholder={t('organizationMenu.products.name')}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }>
                  {sortByName(localize(products)).map((product) => {
                    return (
                      <Select.Option key={product.id} value={product.id}>
                        {product.data?.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                ]}>
                <Input
                  placeholder={t('organizationWarehouse.quantityPlaceholder')}
                />
              </Form.Item>
            </div>
          </div>
          <Button type="primary" onClick={onAddProductHandler}>
            {t('general.add')}
          </Button>
          <div className="warehouse-invoice-products">
            <p className="warehouse-invoice-products__title">
              {t('organizationMenu.products.current')}
            </p>
            {addedProducts?.map((product) => {
              return (
                <div
                  className="warehouse-invoice-products__item"
                  key={product.product.id}>
                  <p className="warehouse-invoice-products__item--title">{`${
                    localizeData(product.product.data)?.name
                  } / ${product.quantity} ${t('general.gram')}`}</p>
                  <p
                    className="warehouse-invoice-products__item--minus"
                    onClick={() => onRemoveProductHandler(product.product)}>
                    -
                  </p>
                </div>
              )
            })}
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default OrganizationWarehouseInvoiceAddForm
