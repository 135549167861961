import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import { INeighborhood } from '@app/interfaces/neighborhood/neighborhood.types'
import { IDistrict } from '@app/interfaces/organization/districts'
import NeighborhoodFilterForm from '@app/pages/reference-book-page-details/neighborhood/components/neighborhood-filter-form'
import NeighborhoodForm from '@app/pages/reference-book-page-details/neighborhood/components/neighborhood-form'
import Pagination from '@app/shared/pagination/pagination'
import { getNeighborhoods } from '@app/store/actions/neighborhood-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _neighborhood: INeighborhood[]
  _state: string
}

const DEFAULT_QUERY = `page=0&size=10`

const ReferenceBookNeighborhood: React.FC<props> = ({
  _neighborhood,
  _state,
}) => {
  const { searchParams, requestFunction } = usePagination(DEFAULT_QUERY)
  const { t } = useTranslation()
  setDocumentTitle(t('district.title'))
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  const { data: _districts, isLoading } = useCache<IDistrict>(ApiKeys.district)

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(getNeighborhoods({ source, params: searchParams }))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const columns = useMemo(() => {
    return [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: t('neighborhood.title'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.sort - b.sort,
      },
      {
        title: t('general.status'),
        dataIndex: 'status',
        key: 'status',
        render: (value) => (
          <Tag color={value ? 'green' : 'volcano'}>
            {value ? t('general.active') : t('general.inActive')}
          </Tag>
        ),
      },
      {
        title: t('general.isRuralArea'),
        dataIndex: 'isRuralArea',
        key: 'isRuralArea',
        render: (value) => (
          <Tag>{value ? t('general.yes') : t('general.no')}</Tag>
        ),
      },
      {
        title: t('general.action'),
        key: 'action',
        render: (text, record) => {
          if (
            rolesChecker([
              AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
              AUTHORITIES.ADMIN,
            ])
          ) {
            return <NeighborhoodForm neighborhood={record} />
          }
          return null
        },
      },
    ]
  }, [t])

  const data = useMemo(() => {
    if (_neighborhood && _districts) {
      return _neighborhood
        .sort((a, b) => a.sorting - b.sorting)
        .map((n) => ({
          ...n,
          key: n.id,
          district: _districts.find((d) => d.id === n.districtId),
          name: localizeData(n.data)?.name,
        }))
    }
    return []
  }, [_neighborhood, _districts])

  return (
    <>
      <NeighborhoodFilterForm
        request={requestFunction}
        searchParams={searchParams}
      />
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
            AUTHORITIES.ADMIN,
          ]) ? (
            <NeighborhoodForm />
          ) : null
        }
        title={t('neighborhood.title')}>
        <Table
          pagination={false}
          loading={_state === LOADING || isLoading}
          dataSource={data}
          columns={columns}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={'100'}
              searchParams={searchParams}
              isLegacy={false}
            />
          )}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _state: state.neighborhood.state,
    _neighborhood: state.neighborhood?.neighborhoods,
  }
}

export default connect(mapStateToProps)(ReferenceBookNeighborhood)
