import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganization } from '@app/interfaces/organization/organization'
import ThreeDots from '@app/shared/loader/ThreeDots'
import { useGetOrganizationBuildingTypesQuery } from '@app/store/rtk/api/organization/organization-building-type-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  organization?: IOrganization
}

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_ORGANIZATION_CLASSIFICATOR_EDIT,
]

const OrganizationBuildingTypeItem: React.FC<props> = ({ organization }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data, isLoading } = useGetOrganizationBuildingTypesQuery('')

  return (
    <>
      {isLoading ? (
        <ThreeDots style="dot-pulse" height={86} padding={24} />
      ) : (
        <Form.Item
          label={t('organizationBuildingType.title')}
          name={'organizationBuildingType'}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          initialValue={
            localize(data?.response as any)?.find(
              (type) => type.id === organization?.organizationBuildingType?.id,
            )?.id
          }
        >
          <Select allowClear={true} disabled={!rolesChecker(permissions)}>
            {sortByName(localize(data?.response as any))?.map(
              (organizationBuildingType) => {
                if (organizationBuildingType.status) {
                  return (
                    <Select.Option
                      key={organizationBuildingType.id}
                      value={organizationBuildingType.id}
                    >
                      {organizationBuildingType.data.name}
                    </Select.Option>
                  )
                }
                return null
              },
            )}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

export default OrganizationBuildingTypeItem
