import bbitApi from '@app/api/make-request'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

type props = {
  onChange?: (
    value: any,
    option: DefaultOptionType | DefaultOptionType[],
  ) => void
}
export const SelectEducationGroups = ({ onChange }: props) => {
  const { id: organizationId } = useParams()
  const { data: educationGroups, isLoading: isEducationGroupsLoading } =
    useQuery(
      ['education/api/v1/education-group', organizationId],
      async () => {
        const { data } = await bbitApi.get<IEducationGroup[]>(
          'education/api/v1/education-group',
          {
            params: {
              page: 0,
              size: 100,
              'organizationId.equals': organizationId,
              'status.equals': 'OPEN',
            },
          },
        )
        return data
      },
      { enabled: !!organizationId },
    )

  return (
    <Select
      showSearch={true}
      disabled={isEducationGroupsLoading}
      onChange={onChange}>
      {educationGroups?.map((group) => (
        <Select.Option key={group.id} value={group.id}>
          {group.name}
        </Select.Option>
      ))}
    </Select>
  )
}
