export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const REFRESH_EMPLOYEE = 'REFRESH_EMPLOYEE'
export const COUNT_EMPLOYEES = 'COUNT_EMPLOYEES'
export const CLEAR_EMPLOYEE = 'CLEAR_EMPLOYEE'
export const CLEAR_EMPLOYEES_ALL = 'CLEAR_EMPLOYEES_ALL'
export const CLEAR_EMPLOYEES_MENTOR = 'CLEAR_EMPLOYEES_MENTOR'
export const CLEAR_EMPLOYEES_USER = 'CLEAR_EMPLOYEES_USER'
export const CLEAR_EMPLOYEES_MVD = 'CLEAR_EMPLOYEES_MVD'

export const FETCH_EMPLOYEES_ALL_REQUEST = 'FETCH_EMPLOYEES_ALL_REQUEST'
export const FETCH_EMPLOYEES_ALL_SUCCESS = 'FETCH_EMPLOYEES_ALL_SUCCESS'
export const FETCH_EMPLOYEES_ALL_FAILURE = 'FETCH_EMPLOYEES_ALL_FAILURE'

export const FETCH_EMPLOYEES_MENTOR_REQUEST = 'FETCH_EMPLOYEES_MENTOR_REQUEST'
export const FETCH_EMPLOYEES_MENTOR_SUCCESS = 'FETCH_EMPLOYEES_MENTOR_SUCCESS'
export const FETCH_EMPLOYEES_MENTOR_FAILURE = 'FETCH_EMPLOYEES_MENTOR_FAILURE'

export const FETCH_EMPLOYEE_REQUEST = 'FETCH_EMPLOYEE_REQUEST'
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS'
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE'
export const FETCH_EMPLOYEE_CLEANUP = 'FETCH_EMPLOYEE_CLEANUP'

export const FETCH_EMPLOYEE_USER_SUCCESS = 'FETCH_EMPLOYEE_USER_SUCCESS'
export const FETCH_EMPLOYEE_MVD_SUCCESS = 'FETCH_EMPLOYEE_MVD_SUCCESS'

export const UPDATE_EMPLOYEE_SHOW_PASSPORT = 'UPDATE_EMPLOYEE_SHOW_PASSPORT'

export const UPDATE_EMPLOYEE_TYPE = 'UPDATE_EMPLOYEE_TYPE'
