import {
  CREATE_CAMERAS,
  FETCH_CAMERAS_CLEANUP,
  FETCH_CAMERAS_FAILURE,
  FETCH_CAMERAS_REQUEST,
  FETCH_CAMERAS_SUCCESS,
  UPDATE_CAMERAS,
} from '@app/store/types/camera'

import camerasRepository from '@app/api/repositories/cameras-repository'
import { IPathParamsArgs } from '@app/api/repositories/i-repository'
import { ICameraAction } from '@app/interfaces/cameras'
import axios from 'axios'

export const getCameras =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchCamerasRequest())
    await camerasRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchCamerasSuccess(response.data, response.headers['x-total-count']),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchCamerasFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchCamerasCleanup())
  }

export function createCamera(camera): ICameraAction {
  return {
    type: CREATE_CAMERAS,
    payload: camera,
  }
}

export function updateCamera(camera): ICameraAction {
  return {
    type: UPDATE_CAMERAS,
    payload: camera,
  }
}

const fetchCamerasRequest = (): ICameraAction => {
  return {
    type: FETCH_CAMERAS_REQUEST,
  }
}

const fetchCamerasSuccess = (cameras, count): ICameraAction => {
  return {
    type: FETCH_CAMERAS_SUCCESS,
    payload: { cameras, count },
  }
}

const fetchCamerasFailure = (message, code): ICameraAction => {
  return {
    type: FETCH_CAMERAS_FAILURE,
    payload: { message, code },
  }
}

const fetchCamerasCleanup = (): ICameraAction => {
  return {
    type: FETCH_CAMERAS_CLEANUP,
  }
}
