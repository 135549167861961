import { Card, Descriptions, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { EditOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { ILang } from '@app/interfaces/languages'
import { clearUser, getUser } from '@app/store/actions/users-actions'
import { IUserState } from '@app/interfaces/users'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import ChangePassModal from '@app/pages/users-page/components/user-change-pass-modal/user-change-pass-modal'
import axios from 'axios'
import { isEmpty } from 'lodash'
import useLocalization from '@app/hooks/useLocalization'
import { IPathParams } from '@app/constants/path-params'

interface props {
  _usersState: IUserState
  languages: ILang[]
}

const UserPage: React.FC<props> = ({ languages, _usersState }) => {
  const { user } = _usersState
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const params: IPathParams = useParams()
  const { localize } = useLocalization()
  setDocumentTitle(t('users.user'))
  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(getUser({ data: Number(params.userId), source }, t('users.error')))
    return () => {
      dispatch(clearUser())
      source.cancel('Component got unmounted')
    }
  }, [])

  return (
    <>
      {!isEmpty(user) && (
        <Card
          title={`${t('users.userCard')} ${[user.firstName, user.lastName].join(
            ' ',
          )}`}
          extra={
            <>
              <ChangePassModal />
              <Link
                to={`/manage-users/users/user/${params.userId}/edit`}
                style={{ marginLeft: '10px' }}
                className={'ant-btn ant-btn-default'}
                type={'primary'}
              >
                <EditOutlined />
                {t('general.edit')}
              </Link>
            </>
          }
        >
          <Descriptions size={'middle'} column={1} bordered>
            <Descriptions.Item label={t('users.id')}>
              {user.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.username')}>
              {user.username}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.firstName')}>
              {user.firstName}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.lastName')}>
              {user.lastName}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.middleName')}>
              {user.middleName}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.email')}>
              {user.email}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.phone')}>
              {user.phone}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.organizations')}>
              {user.organizations.length
                ? user.organizations.map((organization, index) =>
                    index + 1 === user.organizations.length ? (
                      <Link
                        key={organization.id}
                        to={`/organization/${organization.organizationId}/page`}
                      >
                        {organization.organizationId}
                      </Link>
                    ) : (
                      <>
                        <Link
                          key={organization.id}
                          to={`/organization/${organization.organizationId}`}
                        >
                          {organization.organizationId}
                        </Link>{' '}
                        <Divider type={'vertical'} />
                      </>
                    ),
                  )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.lang')}>
              {languages.find((language) => language.code === user.lang)?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.passportNumber')}>
              {user.passportNumber}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.tin')}>
              {user.tin}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.pin')}>
              {user.pin}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.roles')}>
              {user.roles.map((role) => role.name).join(', ')}
            </Descriptions.Item>
            <Descriptions.Item label={t('users.groupRoles')}>
              {user.groupRoles.length
                ? localize(user.groupRoles).map((groupRole, index) =>
                    index + 1 === user.groupRoles.length ? (
                      <Link
                        key={groupRole.id}
                        to={`/manage-users/roles/${groupRole.id}/edit`}
                      >
                        {groupRole.data?.name}
                      </Link>
                    ) : (
                      <>
                        <Link
                          key={groupRole.id}
                          to={`/manage-users/roles/${groupRole.id}/edit`}
                        >
                          {groupRole.data?.name}
                        </Link>{' '}
                        <Divider type={'vertical'} />
                      </>
                    ),
                  )
                : ''}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    state: state.formState,
    languages: state.langs.langs,
    _usersState: state.users,
  }
}

export default connect(mapStateToProps, null)(UserPage)
