import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganization } from '@app/interfaces/organization/organization'
import ThreeDots from '@app/shared/loader/ThreeDots'
import { setCurrentOrganizationSectorId } from '@app/store/actions/organization-sector-actions'
import { useGetOrganizationSectorsQuery } from '@app/store/rtk/api/organization/organization-sector-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, FormInstance, Select } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  organization?: IOrganization
  form: FormInstance<any>
}

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_ORGANIZATION_CLASSIFICATOR_EDIT,
]

const OrganizationSectorItem: React.FC<props> = ({ organization, form }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess } = useGetOrganizationSectorsQuery('')

  useEffect(() => {
    const id = data?.response?.find(
      (sector) => sector.id === organization?.organizationSector?.id,
    )?.id
    if (organization && id) {
      dispatch(setCurrentOrganizationSectorId(id))
    }
  }, [isSuccess])

  const onSectorIdChangeHandler = (id: number) => {
    dispatch(setCurrentOrganizationSectorId(id))
    form.setFieldsValue({ institutionType: undefined })
    form.setFieldsValue({ institutionSubType: undefined })
  }

  return (
    <>
      {isLoading ? (
        <ThreeDots style="dot-pulse" height={86} padding={24} />
      ) : (
        <Form.Item
          label={t('organizationSector.title')}
          name={'organizationSector'}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          initialValue={
            data?.response?.find(
              (sector) => sector.id === organization?.organizationSector?.id,
            )?.id
          }
        >
          <Select
            allowClear={true}
            disabled={!rolesChecker(permissions)}
            onChange={onSectorIdChangeHandler}
          >
            {sortByName(localize(data?.response as any))?.map((organizationSector) => {
              if (organizationSector.status) {
                return (
                  <Select.Option
                    key={organizationSector.id}
                    value={organizationSector.id}
                  >
                    {organizationSector.data.name}
                  </Select.Option>
                )
              }
              return null
            })}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

export default OrganizationSectorItem
