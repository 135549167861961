interface languages {
  id: number
  code: string
  name: string
  toShowCode: string
  languageId: number
}

export const langs: languages[] = [
  {
    id: 1,
    code: 'ru',
    name: 'Русский',
    toShowCode: 'RU',
    languageId: 1,
  },
  {
    id: 2,
    code: 'uz',
    name: 'Uzbek',
    toShowCode: 'UZ',
    languageId: 2,
  },
  {
    id: 3,
    code: 'en',
    name: 'English',
    toShowCode: 'EN',
    languageId: 3,
  },
]
