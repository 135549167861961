import React, { useEffect } from 'react'
import { Card, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { IUserState } from '@app/interfaces/users'
import { getUsers } from '@app/store/actions/users-actions'
import { add, edit } from '@app/store/actions/form-state'
import Pagination from '@app/shared/pagination/pagination'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IUserGroupRoles } from '@app/interfaces/roles'
import UsersFilterForm from '@app/pages/users-page/components/users-filter-form/users-filter-form'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import axios from 'axios'
import { LOADING } from '@app/constants/redux-state'
import { isEmpty } from 'lodash'
import useLocalization from '@app/hooks/useLocalization'
import { getGroupRoles } from '@app/store/actions/roles-actions'
import usePagination from '@app/hooks/usePagination'

interface props {
  _usersStore: IUserState
  _groupRoles: IUserGroupRoles[]
}

const UsersPage: React.FC<props> = ({ _usersStore, _groupRoles }) => {
  const { state, count, users } = _usersStore
  const [t] = useTranslation()
  const { localizeUserData } = useLocalization()
  const dispatch = useDispatch()
  const { searchParams, requestFunction, handleTableChange } = usePagination(
    `page=0&size=10`,
    {
      page: 1,
      size: 10,
    },
  )
  setDocumentTitle(t('users.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_groupRoles))
      dispatch(getGroupRoles({ source }, t('userRoles.error')))
    dispatch(getUsers({ params: searchParams, source }, t('users.error')))
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text, record) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/manage-users/users/user/${record.id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: t('users.user'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/manage-users/users/user/${record.id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: t('users.username'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('users.groupRoles'),
      dataIndex: 'groupRoles',
      key: 'groupRoles',
      render: (text, record) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/manage-users/roles/${record.groupRoleId}/edit`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: t('users.pin'),
      dataIndex: 'pin',
      key: 'pin',
    },
    {
      title: t('users.tin'),
      dataIndex: 'tin',
      key: 'tin',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link
            onClick={edit}
            className={'ant-btn ant-btn-link'}
            to={`/manage-users/users/user/${record.id}/edit`}
          >
            <EditOutlined />
            <span>{t('general.edit')}</span>
          </Link>
        </Space>
      ),
    },
  ]

  const data =
    users && users.length
      ? users.map((user) => {
          return {
            key: user.id,
            id: user.id,
            name: user.firstName,
            username: user.username,
            groupRoles: localizeUserData(
              _groupRoles.find((role) => role.id === user.groupRoles[0]?.id)
                ?.data,
            )?.name,
            pin: user.pin,
            tin: user.tin,
            groupRoleId: user.groupRoles[0]?.id,
          }
        })
      : []

  return (
    <>
      <div className="header-form">
        <UsersFilterForm
          groupRoles={_groupRoles}
          load={state !== LOADING}
          request={requestFunction}
        />
      </div>
      <Card
        extra={
          <Link
            to={'/manage-users/users/add'}
            className={'institutions__add ant-btn ant-btn-primary'}
            onClick={add}
            type={'primary'}
          >
            <PlusOutlined />
            {t('general.add')}
          </Link>
        }
        title={t('users.title')}
      >
        <Table
          pagination={false}
          dataSource={data}
          loading={state === LOADING}
          columns={columns}
          rowClassName="table-row"
          onChange={handleTableChange}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              searchParams={searchParams}
            />
          )}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _groupRoles: state.roles.rolesGroups,
    _usersStore: state.users,
  }
}

export default connect(mapStateToProps)(UsersPage)
