import useLocalization from '@app/hooks/useLocalization'
import { IProducts } from '@app/interfaces/organization-menu'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Button, Col, Form, InputNumber, Row, Select } from 'antd'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './menu-meal-products.less'

interface props {
  products: IProducts[]
  mealProducts: IProducts[]
  setMealProducts: Dispatch<SetStateAction<IProducts[]>>
  setChildForm: any
}

const MenuMealProducts: React.FC<props> = ({
  products,
  mealProducts,
  setMealProducts,
  setChildForm,
}) => {
  const [t] = useTranslation()
  const { localize, localizeData } = useLocalization()
  const [form] = Form.useForm()

  const onAddProductHandler = (values: any) => {
    const addProduct = products.find((product) => product.id === values.id)
    if (mealProducts.find((product) => product.id === addProduct?.id)) return
    if (addProduct) {
      setMealProducts((prev) => [
        ...prev,
        { ...addProduct, amount: Number(values.amount) },
      ])
    }
  }

  const onRemoveProductHandler = (product: IProducts) => {
    const newProducts = mealProducts.filter((item) => item.id !== product.id)
    setMealProducts((prev) => [...newProducts])
  }

  useEffect(() => {
    setChildForm(form)
  }, [])

  return (
    <div className="menu-meal-products">
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onAddProductHandler}
        className="menu-meal-products__add"
      >
        <p className="title">{t('organizationMenu.products.add')}</p>
        <Row wrap gutter={8}>
          <Col span={12} style={{ padding: 0 }}>
            <Form.Item name="id">
              <Select
                style={{ width: '100%' }}
                showSearch
                placeholder={t('organizationMenu.products.name')}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {sortByName(localize(products)).map((product) => {
                  return (
                    <Select.Option key={product.id} value={product.id}>
                      {product.data?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: 0,
                  max: 1000,
                  message: t('errors.requiredMessage'),
                },
              ]}
            >
              <InputNumber
                placeholder={t('organizationMenu.products.amountPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" className="add-btn">
          {t('general.add')}
        </Button>
      </Form>
      <div className="menu-meal-products__current">
        <p className="title">{t('organizationMenu.products.current')}</p>
        {localize(mealProducts)?.map((product) => {
          return (
            <div className="item" key={product.id}>
              <p className="item__title">{`${product.data.name} / ${
                product.amount
              } ${localizeData(product?.measurement.data)?.name}`}</p>
              <p
                className="item__minus"
                onClick={() => onRemoveProductHandler(product)}
              >
                -
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MenuMealProducts
