import {
  CREATE_LANG,
  UPDATE_LANG,
  DELETE_LANG,
  CLEAR_LANG,
  FETCH_LANGS_REQUEST,
  FETCH_LANGS_CLEANUP,
  FETCH_LANGS_FAILURE,
  FETCH_LANGS_SUCCESS,
  GET_LANG_ID,
} from '@app/store/types/languages'
import { ILangState } from '@app/interfaces/languages'
import { LOADING, SUCCEEDED, FAILED, IDLE } from '@app/constants/redux-state'

const initialState: ILangState = {
  langs: [],
  languageId: -1,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LANG:
      return { ...state, langs: [] }
    case CREATE_LANG:
      return { ...state, langs: [...state.langs, action.payload] }
    case UPDATE_LANG:
      const editedLangs = state.langs.map(function (lang, index) {
        if (action.payload.id === lang.id) {
          return action.payload
        }
        return lang
      })
      return { ...state, langs: editedLangs }
    case DELETE_LANG:
      const updatedLangs = state.langs.filter(function (lang) {
        if (action.payload.id === lang.id) {
          return false
        }
        return lang
      })
      return { ...state, langs: updatedLangs }
    case GET_LANG_ID:
      return { ...state, languageId: action.payload }
    case FETCH_LANGS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_LANGS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        langs: action.payload.langs,
        count: action.payload.count,
      }
    case FETCH_LANGS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        langs: [],
      }
    case FETCH_LANGS_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    default:
      return state
  }
}
