import { IPathParams } from '@app/constants/path-params'
import { useGetEducationGroupsQuery } from '@app/store/rtk/api/education/education-group-api'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

interface IProps {
  setSearchParams: any
}

const OrganizationGrowthFilters: React.FC<IProps> = ({ setSearchParams }) => {
  const params: IPathParams = useParams()
  const [t] = useTranslation()
  const { data: groups } = useGetEducationGroupsQuery(
    `page=0&size=100&organizationId.equals=${params?.id}&status.equals=OPEN`,
  )

  const onChangeHandler = (value) => {
    setSearchParams(`page=0&size=200&educationGroupId=${value}`)
  }

  return (
    <Form layout="vertical">
      <Form.Item
        name={'educationGroupId'}
        label={t('educationGroup.educationGroup')}
      >
        <Select
          showSearch={true}
          style={{ width: 300 }}
          onChange={onChangeHandler}
        >
          {groups?.response?.map((group) => {
            return (
              <Select.Option key={group.id} value={group.id}>
                {group.name}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default OrganizationGrowthFilters
