import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import { IEducationGrade } from '@app/interfaces/education/education-grade'
import OrganizationsGroupsFilterForm from '@app/pages/organizations/components/organization-groups/components/groups-page-filter-form/groups-page-filter-form'
import GroupsPageForm from '@app/pages/organizations/components/organization-groups/components/groups-page-form/groups-page-form'
import Pagination from '@app/shared/pagination/pagination'
import { useGetEducationGroupsQuery } from '@app/store/rtk/api/education/education-group-api'
import { useGetOrganizationMentorEmployeesQuery } from '@app/store/rtk/api/employee/organization-employee-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_EDUCATION_GROUP_CURRENT_EDIT,
]

const EducationGroupsPage: React.FC = () => {
  const params: IPathParams = useParams()
  const { localize } = useLocalization()
  const [t] = useTranslation()
  const { data: grades } = useCache<IEducationGrade>(ApiKeys.educationGrade)
  const { data: employees } = useGetOrganizationMentorEmployeesQuery(
    `organizationId.equals=${params.id}&employeePositionStatusType.equals=WORKING&employeePositionMentor.equals=true&page=0&size=100`,
  )
  const {
    searchParams,
    requestFunction,
    pageAndSize,
    setPage,
    handleTableChange,
  } = usePagination(
    `organizationId.equals=${params.id}&status.equals=OPEN&page=0&size=10&sort=id,asc`,
  )
  const {
    data: groups,
    isFetching,
    isSuccess,
    refetch,
  } = useGetEducationGroupsQuery(searchParams.toString())
  setDocumentTitle(t('educationGroup.title'))

  useEffect(() => {
    refetch()
  }, [searchParams])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/organization/${params.id}/groups/${record.id}`}>
          {(pageAndSize.page || 0) * pageAndSize.size + index + 1}
        </Link>
      ),
    },
    {
      title: t('educationGroup.educationGroup'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/organization/${params.id}/groups/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: t('educationGrade.educationGrade'),
      dataIndex: 'educationGrade',
      key: 'educationGrade',
      sorter: {
        compare: (a, b) => a.educationGrade?.localeCompare(b.educationGrade),
        multiple: 1,
      },
    },
    {
      title: t('educationGroup.groupCapacity'),
      dataIndex: 'groupCapacity',
      key: 'groupCapacity',
      sorter: {
        compare: (a, b) => a.groupCapacity - b.groupCapacity,
        multiple: 2,
      },
    },
    {
      title: t('educationGroup.groupChildren'),
      dataIndex: 'groupChildren',
      key: 'groupChildren',
      sorter: {
        compare: (a, b) => a.groupChildren - b.groupChildren,
        multiple: 3,
      },
    },
    {
      title: t('educationGroup.groupFreeSeats'),
      dataIndex: 'groupFreeSeats',
      key: 'groupFreeSeats',
      sorter: {
        compare: (a, b) => a.groupFreeSeats - b.groupFreeSeats,
        multiple: 4,
      },
    },
    {
      title: t('educationGroup.employeeFullName'),
      dataIndex: 'employee',
      key: 'employee',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker(permissions) ? (
          <GroupsPageForm educationGroup={record.educationGroup} edit={true} />
        ) : null,
    },
  ]

  const data = groups?.response?.length
    ? groups.response.map((group) => {
        const employee = employees?.response?.find(
          (item) => item.employee.id === group.employeeId,
        )
        return {
          key: group.id,
          id: group.id,
          name: group.name,
          groupCapacity: group.groupCapacity,
          educationGrade: localize(grades)?.find(
            (educationGrade) => educationGrade.id === group.educationGradeId,
          )?.data.name,
          groupChildren: group.groupChildren,
          groupFreeSeats: group.groupFreeSeats,
          educationGroup: group,
          employee: `${employee?.employee?.firstName || ''} ${
            employee?.employee?.lastName || ''
          }`,
        }
      })
    : []
  return (
    <>
      <OrganizationsGroupsFilterForm
        request={requestFunction}
        load={isSuccess}
        searchParams={searchParams}
      />
      <Card extra={<GroupsPageForm />} title={t('educationGroup.title')}>
        <Table
          pagination={false}
          dataSource={data}
          loading={isFetching}
          columns={columns}
          rowClassName="table-row"
          onChange={handleTableChange}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={groups?.meta['x-total-count'] ?? 0}
              setPage={setPage}
              searchParams={searchParams}
              isSuccess={!isFetching}
              isLegacy={false}
            />
          )}
        />
      </Card>
    </>
  )
}

export default EducationGroupsPage
