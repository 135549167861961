import userInfoRepository from '@app/api/repositories/user-info-repository'
import { IUserInfoActions } from '@app/interfaces/user-info'
import {
  CLEAR_USER_INFO,
  FETCH_USER_INFO_CLEANUP,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
} from '@app/store/types/user-info'
import axios, { CancelTokenSource } from 'axios'

export const getUserInfo = (source?: CancelTokenSource) => async (dispatch) => {
  dispatch(fetchUserInfoRequest())
  await userInfoRepository
    .getOne(source)
    .then((response) => {
      dispatch(fetchUserInfoSuccess(response.data))
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        dispatch(fetchUserInfoFailure(error?.message, error?.code))
      }
    })
  dispatch(fetchUserInfoCleanup())
}

export function clearUserInfo() {
  return {
    type: CLEAR_USER_INFO,
    payload: {},
  }
}

const fetchUserInfoRequest = (): IUserInfoActions => {
  return {
    type: FETCH_USER_INFO_REQUEST,
  }
}

const fetchUserInfoSuccess = (userInfo): IUserInfoActions => {
  return {
    type: FETCH_USER_INFO_SUCCESS,
    payload: userInfo,
  }
}

const fetchUserInfoFailure = (message, code): IUserInfoActions => {
  return {
    type: FETCH_USER_INFO_FAILURE,
    payload: { message, code },
  }
}

const fetchUserInfoCleanup = (): IUserInfoActions => {
  return {
    type: FETCH_USER_INFO_CLEANUP,
  }
}
