import {
  GET_METRIC_SERIAL,
  GET_METRIC_NUMBER,
  GET_CITIZENSHIP,
  GET_PUPIL_BIRTHDATE,
  CLEAR_ALL,
} from '@app/store/types/pupil-manual-add'

export interface IManualPupilAddState {
  citizenship: string
  metricSerial: string
  metricNumber: string
  birthDate: string
}

const initialState: IManualPupilAddState = {
  birthDate: '',
  citizenship: '',
  metricNumber: '',
  metricSerial: '',
}

export const manualPupilAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL:
      return {
        ...state,
        citizenship: '',
        birthDate: '',
        metricSerial: '',
        metricNumber: '',
      }
    case GET_CITIZENSHIP:
      return { ...state, citizenship: action.payload }
    case GET_METRIC_SERIAL:
      return { ...state, metricSerial: action.payload }
    case GET_PUPIL_BIRTHDATE:
      return { ...state, birthDate: action.payload }
    case GET_METRIC_NUMBER:
      return { ...state, metricNumber: action.payload }
    default:
      return state
  }
}
