import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganizationStatus } from '@app/interfaces/organization/organization-status'
import OrganizationStatusForm from '@app/pages/reference-book-page-details/organization-status/components/organization-status-form/organization-status-form'
import { getOrganizationStatuses } from '@app/store/actions/organization-status-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _organizationStatuses: IOrganizationStatus[]
  _state: string
}

const ReferenceBookOrganizationStatus: React.FC<props> = ({
  _organizationStatuses,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('organizationStatus.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_organizationStatuses))
      dispatch(
        getOrganizationStatuses({ source }, t('organizationStatus.error')),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('organizationStatus.organizationStatus'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <OrganizationStatusForm
            edit={true}
            organizationStatus={record.organizationStatus}
          />
        ) : null,
    },
  ]

  const data = !isEmpty(_organizationStatuses)
    ? _organizationStatuses.map((organizationStatus) => {
        return {
          key: organizationStatus.id,
          id: organizationStatus.id,
          name: localizeData(organizationStatus?.data)?.name,
          status: [organizationStatus.status],
          organizationStatus: organizationStatus,
        }
      })
    : []

  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <OrganizationStatusForm />
          ) : null
        }
        title={t('organizationStatus.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _organizationStatuses: state.organizationStatuses.organizationStatuses,
    _state: state.organizationStatuses.state,
  }
}

export default connect(mapStateToProps)(ReferenceBookOrganizationStatus)
