import benefitTypeRepository from '@app/api/repositories/benefit-type-repository'
import { IBenefitType } from '@app/interfaces/benefit-type'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  createBenefitType,
  updateBenefitType,
} from '@app/store/actions/benefit-type-actions'
import { Button, Form, Input, InputNumber, Modal, Spin, Switch } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  benefitType?: IBenefitType
  edit?: boolean
}

const BenefitTypeForm: React.FC<props> = ({ edit, benefitType }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    const languages = values.data.filter(
      (language) => language && language.name,
    )
    const newValues: IBenefitType = {
      id: values.id,
      sorting: values.sorting,
      status: values.status,
      data: languages,
    }

    setConfirmLoading(true)
    const benefitTypeSubmitApi = benefitType
      ? benefitTypeRepository.update
      : benefitTypeRepository.create

    await benefitTypeSubmitApi(newValues)
      .then((response: AxiosResponse<IBenefitType>) => {
        if (benefitType && response.status === 200) {
          setConfirmLoading(false)
          dispatch(updateBenefitType(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.updated'))
        }
        if (!benefitType && response.status === 201) {
          setConfirmLoading(false)
          dispatch(createBenefitType(response.data))
          form.resetFields()
          openNotificationWithIcon('success', t('success.created'))
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {edit ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('pupils.editBenefitType') : t('pupils.addBenefitType')}
        confirmLoading={confirmLoading}
        onCancel={hideModal}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              edit={edit}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: benefitType?.id || null,
              sorting: benefitType?.sorting || 0,
              status: edit ? benefitType?.status : true,
            }}
            layout={'vertical'}
          >
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch
                disabled={confirmLoading}
                defaultChecked={benefitType?.status === true}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -2147483648,
                  max: 2147483648,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber
                disabled={confirmLoading}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <FormList confirmLoading={confirmLoading} object={benefitType} />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default BenefitTypeForm
