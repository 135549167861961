import { AUTHORITIES } from '@app/constants/authorities'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganizationLocality } from '@app/interfaces/organization/organization-locality'
import OrganizationLocalityForm from '@app/pages/reference-book-page-details/organization-locality/components/organization-locality-form/organization-locality-form'
import { getOrganizationLocalities } from '@app/store/actions/organization-locality-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table, Tag } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  _organizationLocality: IOrganizationLocality[]
  _state: string
}

const ReferenceBookOrganizationLocality: React.FC<props> = ({
  _organizationLocality,
  _state,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('organizationLocality.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_organizationLocality))
      dispatch(
        getOrganizationLocalities({ source }, t('organizationLocality.error')),
      )
    return function cleanup() {
      source.cancel('Component got unmounted')
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('organizationLocality.organizationLocality'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',

      render: (tags) => (
        <>
          {tags.map((tag) => {
            const color = tag === true ? 'green' : 'volcano'
            const text =
              color === 'green' ? t('general.active') : t('general.inActive')
            return (
              <Tag color={color} key={tag}>
                {text}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) =>
        rolesChecker([
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
        ]) ? (
          <OrganizationLocalityForm
            edit={true}
            organizationLocality={record.organizationLocality}
          />
        ) : null,
    },
  ]

  const data = !isEmpty(_organizationLocality)
    ? _organizationLocality.map((organizationLocality) => {
        return {
          key: organizationLocality.id,
          id: organizationLocality.id,
          name: localizeData(organizationLocality?.data)?.name,
          status: [organizationLocality.status],
          organizationLocality: organizationLocality,
        }
      })
    : []
  return (
    <>
      <Card
        extra={
          rolesChecker([
            AUTHORITIES.ADMIN,
            AUTHORITIES.ROLE_SETTING_DICTIONARY_EDIT,
          ]) ? (
            <OrganizationLocalityForm />
          ) : null
        }
        title={t('organizationLocality.title')}
      >
        <Table
          pagination={false}
          loading={_state === LOADING}
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    _organizationLocality: state.organizationLocality.organizationLocalities,
    _state: state.organizationLocality.state,
  }
}

export default connect(mapStateToProps)(ReferenceBookOrganizationLocality)
