import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEmployeeStatus,
  IEmployeeStatusState,
} from '@app/interfaces/employee/employee-status'
import {
  CLEAR_EMPLOYEE_STATUSES,
  CREATE_EMPLOYEE_STATUSES,
  DELETE_EMPLOYEE_STATUSES,
  FETCH_EMPLOYEE_STATUSES_FAILURE,
  FETCH_EMPLOYEE_STATUSES_REQUEST,
  FETCH_EMPLOYEE_STATUSES_SUCCESS,
  FETCH_EMPLOYEE_STATUS_FAILURE,
  FETCH_EMPLOYEE_STATUS_REQUEST,
  FETCH_EMPLOYEE_STATUS_SUCCESS,
  UPDATE_EMPLOYEE_STATUSES,
} from '@app/store/types/employee-status'

const initialState: IEmployeeStatusState = {
  employeeStatus: {} as IEmployeeStatus,
  employeeStatuses: [],
  state: IDLE,
  message: '',
  code: -1,
  count: '',
}

export const employeeStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_STATUSES_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_STATUSES_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeeStatuses: action.payload.employeeStatuses,
        count: action.payload.count,
      }
    case FETCH_EMPLOYEE_STATUSES_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeeStatuses: [],
      }
    case FETCH_EMPLOYEE_STATUS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_STATUS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeeStatus: action.payload,
      }
    case FETCH_EMPLOYEE_STATUS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeeStatus: {},
      }
    case CLEAR_EMPLOYEE_STATUSES:
      return { ...state, employeeStatuses: [] }
    case CREATE_EMPLOYEE_STATUSES:
      return {
        ...state,
        employeeStatuses: [...state.employeeStatuses, action.payload],
      }
    case UPDATE_EMPLOYEE_STATUSES:
      const updatedEmployeeStatuses = state.employeeStatuses.map(function (
        employeeStatus,
      ) {
        if (action.payload.id === employeeStatus.id) {
          return action.payload
        }
        return employeeStatus
      })
      return { ...state, employeeStatuses: updatedEmployeeStatuses }
    case DELETE_EMPLOYEE_STATUSES:
      const editedEmployeeStatuses = state.employeeStatuses.filter(function (
        employeeStatus,
      ) {
        if (action.payload.id === employeeStatus.id) {
          return false
        }
        return employeeStatus
      })
      return { ...state, employeeStatuses: editedEmployeeStatuses }
    default:
      return state
  }
}
